import { Loader2, Pencil } from "lucide-react";
import {
  useGetWireHandleOfOnboardingPatient,
  useSaveWireHandle,
} from "../../hooks";
import { OnboardPatientDetails } from "../../types";
import { useState } from "react";
import { Button } from "../../../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../../components/ui/dialog";
import { Input } from "../../../../../components/ui/input";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";

type WireUserNameProps = {
  onboardingPatient: OnboardPatientDetails;
};

export const WireUserName = ({ onboardingPatient }: WireUserNameProps) => {
  const { t } = useTranslation();

  const { data, isFetching } = useGetWireHandleOfOnboardingPatient(
    onboardingPatient.id
  );

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [wireHandle, setWireHandle] = useState(data?.wireHandle ?? "");
  const [showWireUserError, setShowWireUserError] = useState(false);

  const { mutate: saveWireHandle, isLoading: isSaving } = useSaveWireHandle(
    () => {
      toast(t("case-load-manager-onboarding-wire-handle-edit-success-title"), {
        description: t(
          "case-load-manager-onboarding-wire-handle-edit-success-desc"
        ),
      });
      setIsDialogOpen(false);
    },
    () => {
      toast(t("case-load-manager-onboarding-wire-handle-edit-failure-title"), {
        description: t(
          "case-load-manager-onboarding-wire-handle-edit-failure-desc"
        ),
      });

      setShowWireUserError(true);
      setTimeout(() => {
        setShowWireUserError(false);
      }, 4000);
    }
  );

  const renderWireHandle = () => {
    if (isFetching) {
      return <Loader2 className="w-4 h-4 animate-spin" />;
    }

    if (data?.wireHandle == null || data?.wireHandle == "") {
      return <p className="font-bold text-red-600">Not yet known</p>;
    }

    return <p>{data?.wireHandle}</p>;
  };

  return (
    <div className="w-full flex items-center space-x-4">
      <section className="flex items-center space-x-2">
        <p className="font-bold">Wire handle:</p>
        {renderWireHandle()}
      </section>

      {isFetching ? (
        <></>
      ) : (
        <section>
          <div
            className="w-4 h-4 text-foreground-muted hover:text-link cursor-pointer"
            onClick={() => setIsDialogOpen(true)}
          >
            <Pencil className="w-4 h-4" />
          </div>
        </section>
      )}

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {t(
                "case-load-manager-onboarding-missing-wire-handle-dialog-title"
              )}
            </DialogTitle>
            <DialogDescription>
              <p>
                {t(
                  "case-load-manager-onboarding-missing-wire-handle-dialog-desc"
                )}
              </p>
            </DialogDescription>

            <p className="pt-1 font-bold">Wire handle</p>
            <Input
              autoFocus
              value={wireHandle}
              onChange={(e) => setWireHandle(e.target.value)}
            />

            {showWireUserError && (
              <div className="bg-red-100 text-red-600 p-2 rounded my-1">
                <p className="font-bold">
                  {t(
                    "case-load-manager-onboarding-missing-wire-handle-error-title"
                  )}
                </p>
                <p className="text-sm">
                  {t(
                    "case-load-manager-onboarding-missing-wire-handle-error-desc"
                  )}
                </p>
              </div>
            )}

            {data?.userProvidedWireHandle && (
              <p className="pt-2 opacity-70">
                User provided wire handle: {data?.userProvidedWireHandle}
              </p>
            )}
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsDialogOpen(false)}>
              {t(
                "case-load-manager-onboarding-kop-model-send-confirmation-cancel"
              )}
            </Button>
            <Button
              className="flex items-center space-x-2 px-4"
              disabled={!wireHandle}
              onClick={async () =>
                await saveWireHandle({
                  id: onboardingPatient.id,
                  wireHandle,
                })
              }
            >
              {isSaving && <Loader2 className="w-4 h-4 animate-spin" />}
              <p>{t("case-load-manager-onboarding-wire-handle-save")}</p>
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};
