import moment from "moment";
import { useGetActivityLogForReferral } from "../hooks";
import { Skeleton } from "../../../components/ui/skeleton";

export function ActivityLogs({ referralId }: { referralId: string }) {
  const { data, isLoading } = useGetActivityLogForReferral(referralId);

  if (isLoading) {
    return (
      <div className="w-full space-y-6">
        <Skeleton className="rounded h-14 w-full" />
        <Skeleton className="rounded h-14 w-full" />
        <Skeleton className="rounded h-14 w-full" />
        <Skeleton className="rounded h-14 w-full" />
        <Skeleton className="rounded h-14 w-full" />
        <Skeleton className="rounded h-14 w-full" />
      </div>
    );
  }

  return (
    <div className="-mt-2 flex flex-col divide-y-2 divide-gray-50 dark:divide-slate-800">
      {Array.isArray(data) &&
        data
          .sort(
            (a, b) =>
              new Date(b.timeStamp).getTime() - new Date(a.timeStamp).getTime()
          )
          .map((log, index) => (
            <div key={index} className="py-3">
              <section>
                <p>
                  <span className="font-bold">{log.user}</span> •{" "}
                  <span>{moment(new Date(log.timeStamp)).fromNow()}</span>{" "}
                </p>
                <p className="text-sm">{log.activity}</p>
                {log.additionalInformation && (
                  <p className="text-xs italic">{log.additionalInformation}</p>
                )}
              </section>
            </div>
          ))}
    </div>
  );
}
