import { TableCell, TableRow } from "../../../../../../components/ui/table";

export const AggregatedTableRows = ({
  data,
}: {
  data: { psychologist: string; ratio: string }[];
}) => {
  return (
    <>
      {data.map((x) => (
        <TableRow key={x.psychologist}>
          <TableCell>{x.psychologist}</TableCell>
          <TableCell className="text-center">{x.ratio}</TableCell>
        </TableRow>
      ))}
    </>
  );
};
