import { AccountInfo, InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest } from "../pages/auth/msalConfig";
import { PublicClientApplication } from "@azure/msal-browser";

export const getAccessToken = async (instance: PublicClientApplication, account: AccountInfo | null) => {
  if (!account) {
    return null;
  }

  try {
    const response = await instance.acquireTokenSilent({
      ...loginRequest,
      account,
    });
    return response.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      // Fallback to interactive method
      const response = await instance.acquireTokenPopup(loginRequest);
      return response.accessToken;
    }
    throw error;
  }
};