import { createTheme } from "@mui/material";
import * as colors from "@mui/material/colors";
import { darkMode, lightMode } from "./palette";
import typography from "./typography";

const palette = localStorage.getItem("theme") === "dark" ? darkMode : lightMode;

const theme = createTheme({
  palette,
  typography,
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === "contained" &&
            ownerState.color === "primary" && {
              backgroundColor: ownerState.disabled
                ? `${
                    palette.mode === "light" ? colors.grey[100] : "#1e293b"
                  }!important`
                : `${palette.primary.main}!important`,
              boxShadow:
                "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)",
            }),
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.03)",
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: palette.text.secondary,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow:
            "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          ...typography.body1,
          borderBottom: `1px solid ${palette.divider}`,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor:
            palette.mode === "light" ? colors.grey[50] : "#0f172a",
        },
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        textDecorationLine: "none",
        "&$selected": {
          backgroundColor: palette.background.default,
        },
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      gutterBottom: {
        marginBottom: 8,
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        backgroundColor: (theme) => theme.palette.primary.main,
        fontSize: 14,
      },
    },
  },

  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
});

export default theme;
