import { useTranslation } from "react-i18next";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../../components/ui/table";
import { useGetCaseLoadDetailedDataOverview } from "../../hooks";
import { Skeleton } from "../../../../../components/ui/skeleton";

export const OverviewTable = ({ date }: { date: Date }) => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetCaseLoadDetailedDataOverview(date);

  const formatter = new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const getRowBackground = (value: number) => {
    if (value < 0.7) {
      return "bg-red-100 dark:bg-red-950 hover:bg-red-200 dark:hover:bg-red-900";
    }

    if (value < 0.9) {
      return "bg-amber-100 dark:bg-amber-950 hover:bg-amber-200 dark:hover:bg-amber-900";
    }

    return "bg-green-100 dark:bg-green-950 hover:bg-green-200 dark:hover:bg-green-900";
  };

  const Header = () => {
    return (
      <>
        <h2 className="text-2xl font-bold">Overview per psychologist</h2>
        <p>
          Find here each psyhologist's summarized progress for the current view.
        </p>
      </>
    );
  };

  if (isLoading) {
    return (
      <div>
        <Header />
        <Skeleton className="h-12 w-full rounded mt-6" />
        <Skeleton className="h-8 w-full rounded mt-1" />
        <Skeleton className="h-8 w-full rounded mt-1" />
        <Skeleton className="h-8 w-full rounded mt-1" />
      </div>
    );
  }

  return (
    <>
      <Header />
      <div className="mt-6">
        <Table limitHeight className="border">
          <TableHeader>
            <TableRow>
              <TableHead>
                {t(
                  "case-load-manager-admin-panel-kpi-overview-table-col-psychologist"
                )}
              </TableHead>
              <TableHead className="text-center">
                {t(
                  "case-load-manager-admin-panel-kpi-overview-table-col-completion-percent"
                )}
              </TableHead>
              <TableHead className="text-center">
                {t(
                  "case-load-manager-admin-panel-kpi-overview-table-col-number-of-checkins"
                )}
              </TableHead>
              <TableHead className="text-center">
                {t(
                  "case-load-manager-admin-panel-kpi-overview-table-col-number-of-chat-appointments"
                )}
              </TableHead>
              <TableHead className="text-center">
                {t(
                  "case-load-manager-admin-panel-kpi-overview-table-col-number-of-patients"
                )}
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {data!.map((x) => (
              <TableRow
                key={x.psychologist}
                className={getRowBackground(x.checkins / x.totalPatients)}
              >
                <TableCell className="py-1">{x.psychologist}</TableCell>
                <TableCell className="py-1 text-center">
                  {formatter.format(x.checkins / x.totalPatients)}
                </TableCell>
                <TableCell className="py-1 text-center">{x.checkins}</TableCell>
                <TableCell className="py-1 text-center flex justify-center items-center w-full">
                  {x.numberOfChatAppointmentsCreated < x.checkins ? (
                    <div className="h-6 w-6 rounded-full flex items-center justify-center bg-red-300 dark:bg-red-900 text-red-500 dark:text-red-600">
                      {x.numberOfChatAppointmentsCreated}
                    </div>
                  ) : (
                    <>{x.numberOfChatAppointmentsCreated}</>
                  )}
                </TableCell>
                <TableCell className="py-1 text-center">
                  {x.totalPatients}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
};
