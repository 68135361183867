import React from "react";
import { styled } from "@mui/system";
import { observer } from "mobx-react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { colors } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import questionListName from "../utils/constants/questionListName";
import QuestionListDetails from "./QuestionListDetails";
import { getQuestionnaireUrl } from "../utils/urlHelpers";
import useStores from "../useStores";

const StyledContent = styled(CardContent)(({ theme }) => ({
  padding: 0,
  backgroundColor: colors.grey[50],
}));

const StyledButtonIcon = styled(AddIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const SQ48 = () => {
  const { patientStore } = useStores();

  return (
    <Card>
      <CardHeader title="SQ48" />
      <Divider />
      <StyledContent>
        {patientStore.questionLists &&
          patientStore.questionLists
            .filter(
              (questionList) => questionList.name === questionListName.SQ48
            )
            .map((questionList) => (
              <div key={questionList.id}>
                <QuestionListDetails
                  questionList={questionList}
                  clipBoardValue={getQuestionnaireUrl(questionList)}
                >
                  Simpele SQ48 vragenlijst
                </QuestionListDetails>
              </div>
            ))}
      </StyledContent>
      <CardActions>
        <Button
          onClick={() => patientStore.createQuestionList(questionListName.SQ48)}
        >
          <StyledButtonIcon />
          Nieuwe SQ48
        </Button>
      </CardActions>
    </Card>
  );
};

export default observer(SQ48);
