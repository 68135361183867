import React, { useState, Fragment } from "react";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import { Formik } from "formik";
import * as Yup from "yup";
import { observer } from "mobx-react";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import { useSnackbar } from "notistack";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell/TableCell";
import { colors } from "@mui/material";
import moment from "moment";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import { styled } from "@mui/system";
import { trackPromise } from "react-promise-tracker";
import LockOpenIcon from "@mui/icons-material/LockOpenOutlined";
import DossierCompletionModal from "./DossierCompletionModal";
import areas from "../utils/constants/areas";
import Label from "./Label";
import useStores from "../useStores";
import Loader from "./Loader";
import DeleteDossier from "./DeleteDossier";

const StyledCardActions = styled(CardActions)(({ theme }) => ({
  flexDirection: "column",
  alignItems: "flex-start",
  "& > * + *": {
    marginLeft: "0 !important",
  },
}));

const StyledLockOpenIcon = styled(LockOpenIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const renderDossierStatus = (dossier) => {
  if (dossier.completedAt == null) {
    return <Label color={colors.grey[600]}>Open</Label>;
  }

  if (
    dossier.billing.closedAt != null ||
    dossier.billing.isClosedManually ||
    dossier.billing.$type === "ContractedHealthCareBilling"
  ) {
    return (
      <Label color={colors.green[600]}>Afgesloten, betaling voltooid</Label>
    );
  }

  return <Label color={colors.orange[600]}>Afgesloten, betaling pending</Label>;
};

const EditDossierEpd = () => {
  const { patientStore } = useStores();
  const { dossier } = patientStore;
  const { enqueueSnackbar } = useSnackbar();
  const [isDossierCompletionModalOpen, setIsDossierCompletionModalOpen] =
    useState(false);

  const handleDossierCompletion = (closingReason, freeTextClosingReason) => {
    setIsDossierCompletionModalOpen(false);
    trackPromise(
      patientStore.completeDossier(closingReason, freeTextClosingReason).then(
        () => {},
        (error) => {
          enqueueSnackbar(error.response.data, {
            variant: "error",
            autoHideDuration: 30000,
          });
        }
      ),
      areas.EDIT_DOSSIER_CARD
    );
  };

  return (
    <Loader area={areas.EDIT_DOSSIER_CARD}>
      <Card>
        <Formik
          enableReinitialize
          initialValues={{
            epdPatientId: dossier.epdPatientId,
            epdFileId: dossier.epdFileId
          }}
          onSubmit={(values, { setSubmitting }) => {
            patientStore.patchDossier(values).catch((error) => {
              enqueueSnackbar(error.response.data, { variant: "error" });
              setSubmitting(false);
            });
            // resetForm(); Don't reset form it gets filled again from the store using Formik enableReinitialize.
          }}
          validationSchema={Yup.object().shape({
            epdPatientId: Yup.number().integer().positive().required(),
            epdFileId: Yup.number().integer().positive().required(),
          })}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              dirty,
            } = props;
            return (
              <form autoComplete="off" noValidate>
                <CardHeader title="Dossier bewerken" />
                <Divider />

                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Status</TableCell>
                      <TableCell>{renderDossierStatus(dossier)}</TableCell>
                    </TableRow>
                    {dossier.completedAt ? (
                      <TableRow>
                        <TableCell>Afgesloten op</TableCell>
                        <TableCell>
                          {moment(dossier.completedAt).format(
                            "DD-MM-YYYY HH:mm"
                          )}
                        </TableCell>
                      </TableRow>
                    ) : null}
                    <TableRow>
                      <TableCell>Type zorg</TableCell>
                      <TableCell>
                        {dossier.isInsuredHealthCare ? (
                          <>
                            Verzekerde zorg
                            <br />
                            {dossier.billing.$type ===
                            "ContractedHealthCareBilling"
                              ? "Gecontracteerd"
                              : "Ongecontracteerd"}
                          </>
                        ) : (
                          "Onverzekerde zorg (B2C)"
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        required
                        disabled
                        label="Cliënt nr."
                        margin="dense"
                        name="epdId"
                        onChange={handleChange}
                        value={values.epdPatientId}
                        variant="outlined"
                        type="number"
                        onBlur={handleBlur}
                        error={errors.epdPatientId && touched.epdPatientId}
                        helperText={
                          errors.epdPatientId && touched.epdPatientId
                            ? errors.epdPatientId
                            : "Client nummer uit praktijkdata"
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Dossier nr."
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {values.epdPatientId}.
                            </InputAdornment>
                          ),
                        }}
                        margin="dense"
                        name="epdFileId"
                        onChange={handleChange}
                        required
                        disabled
                        value={values.epdFileId}
                        variant="outlined"
                        type="number"
                        onBlur={handleBlur}
                        error={errors.epdFileId && touched.epdFileId}
                        helperText={
                          errors.epdFileId && touched.epdFileId
                            ? errors.epdFileId
                            : "Dossier nummer uit praktijkdata"
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting || !dirty}
                    onClick={handleSubmit}
                  >
                    Opslaan
                  </Button>
                  <Button
                    type="button"
                    className="outline"
                    onClick={handleReset}
                    disabled={isSubmitting || !dirty}
                  >
                    Reset
                  </Button>
                </CardActions>

                <Divider />

                <StyledCardActions>
                  <Button
                    disabled={dossier.completedAt != null}
                    onClick={() => setIsDossierCompletionModalOpen(true)}
                  >
                    <StyledLockOpenIcon />
                    Dossier sluiten
                  </Button>

                  <DeleteDossier />
                  <DossierCompletionModal
                    open={isDossierCompletionModalOpen}
                    dossier={dossier}
                    onCompletion={handleDossierCompletion}
                    onClose={() => setIsDossierCompletionModalOpen(false)}
                  />
                </StyledCardActions>
              </form>
            );
          }}
        </Formik>
      </Card>
    </Loader>
  );
};

export default observer(EditDossierEpd);
