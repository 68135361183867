import { CircleCheck } from "lucide-react";

export const ActiveStatus = () => {
  return (
    <div className="w-full text-green-700 font-bold flex items-center space-x-2">
      <div className="h-4 w-4">
        <CircleCheck className="h-4 w-4" />
      </div>
      <p>Active</p>
    </div>
  );
};
