import { observer } from "mobx-react";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Divider,
  Card,
  CardHeader,
  CardContent,
  Button,
  colors,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import useStores from "../../useStores";
import Label from "../../shared/Label";
import dossierType from "../../utils/constants/dossierType";

const Dossiers = ({ patientId }) => {
  const { patientStore } = useStores();

  const navigate = useNavigate();

  return (
    <Card>
      <CardHeader
        title="Dossiers"
        action={
          <div>
            <Button
              color="primary"
              size="small"
              onClick={() =>
                navigate(`/patient/${patientId}/dossier-ggz/create`)
              }
            >
              <AddIcon />
              iPractice dossier
            </Button>
            <Button
              color="primary"
              size="small"
              onClick={() =>
                navigate(`/patient/${patientId}/dossier-transfer/create`)
              }
            >
              <AddIcon />
              Transfer dossier
            </Button>
          </div>
        }
      />
      <Divider />
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Dossier nr.</TableCell>
              <TableCell>Dossier type</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Aangemaakt op</TableCell>
              <TableCell align="right">Acties</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {patientStore.patient.dossiers.map((dossier) => (
              <TableRow key={dossier.id}>
                <TableCell>
                  {dossier.$type === dossierType.OPEN_UP
                    ? `OU-${dossier.id}`
                    : `${dossier.epdPatientId}.${dossier.epdFileId}`}
                </TableCell>
                <TableCell>{dossier.$type}</TableCell>
                <TableCell>
                  {dossier.completedAt ? (
                    <Label color={colors.grey[600]}>
                      Afgesloten op{" "}
                      {moment(dossier.completedAt).format("DD-MM-YYYY HH:mm")}
                    </Label>
                  ) : (
                    <Label color={colors.green[600]}>Open</Label>
                  )}
                </TableCell>
                <TableCell>
                  {moment(dossier.createdAt).format("DD-MM-YYYY")}
                </TableCell>
                <TableCell align="right">
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() =>
                      navigate(
                        `/patient/${patientId}/${dossier.$type}/${dossier.id}`
                      )
                    }
                  >
                    Openen
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default observer(Dossiers);
