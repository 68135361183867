import { WeekByWeekProgressChart } from "./weekByWeekProgressChart";
import { CurrentWeekProgressChart } from "./currentWeekProgressChart";

export const Charts = ({ date }: { date: Date }) => {
  return (
    <div className="w-full flex flex-col">
      <CurrentWeekProgressChart date={date} />
      <WeekByWeekProgressChart />
    </div>
  );
};
