import { ColumnDef } from "@tanstack/react-table";
import { ReferredPatient } from "../../../types";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { getSimpleFormattedDate } from "../../../../../utils/dateUtils";

export const DateOfReferralColName = "Referral Date";

export const DateOfReferralCol: ColumnDef<ReferredPatient> = {
  id: DateOfReferralColName,
  accessorKey: "referralDate",
  size: 175,
  minSize: 100,
  header: () => {
    return <p>Referral Date</p>;
  },
  cell: ({ row }) => {
    const referralDate = row.original.referralDate;

    if (!referralDate) {
      return <></>;
    }

    const dateAsMoment = moment(referralDate);
    const weekOfYear = dateAsMoment.week();

    return (
      <div>
        <p>{getSimpleFormattedDate(referralDate)}</p>
        <p className="text-xs">Week {weekOfYear}</p>
      </div>
    );
  },
};
