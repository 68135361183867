import { useState } from "react";
import { useDebounce } from "use-debounce";
import { Filters } from "./filters";
import { AppointmentReminderResults } from "./appointmentReminderResults";

export default function AppointmentRemindersToChatAdmin() {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);

  return (
    <main className="p-24">
      <div className="flex item-start justify-between w-full">
        <section>
          <h1 className="font-bold text-4xl">
            Appointment reminders for today
          </h1>

          <p className="pt-2">
            View and re-trigger client's appointment reminders, sent via chat.
          </p>
        </section>
      </div>

      <div className="mt-6">
        <Filters searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        <AppointmentReminderResults searchTerm={debouncedSearchTerm} />
      </div>
    </main>
  );
}
