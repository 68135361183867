import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  ComposedChart,
  Legend,
  Bar,
  Line,
} from "recharts";
import {
  useGetCaseLoadWeekByWeekProgressChart,
  useGetCaseLoadWeekByWeekProgressChartEmployeeFilters,
} from "../../hooks";
import { ceilToNearest100, ceilToNearest500 } from "../../utils";
import { Skeleton } from "../../../../../components/ui/skeleton";
import { useDarkMode } from "../../../../../theme/useDarkMode";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../components/ui/select";
import { useState } from "react";

export const WeekByWeekProgressChart = () => {
  const [selectedPsy, setselectedPsy] = useState("full-team");
  const { data, isLoading } = useGetCaseLoadWeekByWeekProgressChart(
    selectedPsy == "full-team" ? null : Number(selectedPsy)
  );

  const { data: employees, isLoading: isLoadingEmployees } =
    useGetCaseLoadWeekByWeekProgressChartEmployeeFilters();

  const [theme] = useDarkMode();

  // get the max value of 'totalPatients' from 'data':
  const getMaxTotalPatients = () =>
    data!.reduce((max, obj) => Math.max(max, obj.totalNumberOfPatients), 0);

  return (
    <div className="mt-16 border-t pt-16 max-h-[80vh] w-full">
      <div className="w-full flex items-center justify-between space-x-2 -mt-6 pb-4">
        <h2 className="w-2/3 text-2xl font-bold">
          Progress in the last 5 weeks
        </h2>

        <Select
          value={selectedPsy}
          disabled={isLoadingEmployees}
          onValueChange={(x) => {
            setselectedPsy(x);
          }}
        >
          <SelectTrigger className="w-1/3">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem value="full-team">Full team</SelectItem>
              {employees?.map((employee) => {
                return (
                  <SelectItem
                    key={employee.employeeEpdId.toString()}
                    value={employee.employeeEpdId.toString()}
                  >
                    {employee.name}
                  </SelectItem>
                );
              })}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>

      {isLoading ? (
        <Skeleton className="h-[50vh] w-full rounded" />
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <ComposedChart
            width={500}
            height={350}
            data={data!.map((d) => ({
              period: d.period,
              praktijkdataAppointments: d.praktijkdataAppointments,
              totalNumberOfExcludedPatients: d.totalNumberOfExcludedPatients,
              totalNumberOfSupportedPatients: d.totalNumberOfSupportedPatients,
              totalNumberOfPatients:
                d.totalNumberOfPatients -
                d.totalNumberOfSupportedPatients -
                d.totalNumberOfExcludedPatients,
            }))}
            margin={{
              top: 0,
              right: 10,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid
              strokeDasharray="3 3"
              stroke={theme === "light" ? "#cbd5e1" : "#1e293b"}
              fill={theme === "light" ? "white" : "#020617"}
            />
            <XAxis dataKey="period" />
            <YAxis
              type="number"
              domain={[
                0,
                selectedPsy == "full-team"
                  ? ceilToNearest500(getMaxTotalPatients())
                  : ceilToNearest100(getMaxTotalPatients()),
              ]}
              tickCount={
                selectedPsy == "full-team"
                  ? ceilToNearest500(getMaxTotalPatients()) / 500 + 1
                  : ceilToNearest100(getMaxTotalPatients()) / 100 + 1
              }
            />
            <Legend />
            {theme === "dark" ? (
              <Tooltip
                contentStyle={{ backgroundColor: "#020617" }}
                itemStyle={{ color: "#64748b" }}
                cursor={{ fill: "transparent" }}
              />
            ) : (
              <Tooltip cursor={{ fill: "transparent" }} />
            )}
            <Bar
              type="monotone"
              dataKey="totalNumberOfSupportedPatients"
              stackId="a"
              stroke="#16a34a"
              fill="#22c55e"
              name="Supported patients"
              opacity={0.7}
            />
            <Bar
              type="monotone"
              dataKey="totalNumberOfExcludedPatients"
              stackId="a"
              stroke="#dc2626"
              fill="#ef4444"
              name="Excluded patients"
              opacity={0.7}
            />
            <Bar
              type="monotone"
              dataKey="totalNumberOfPatients"
              stackId="a"
              stroke="#6b7280"
              fill="#9ca3af"
              name="Non-supported patients"
              opacity={0.3}
            />

            <Line
              type="monotone"
              dataKey="praktijkdataAppointments"
              name="Praktijkdata appointments"
              stroke={theme == "dark" ? "#f3f4f6" : "#4b5563"}
              strokeWidth={2}
            />
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};
