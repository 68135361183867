import { toast } from "sonner";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../../../components/ui/alert-dialog";
import { useGetiWelcomeAutomaticTemplate, useSendiWelcome } from "./hooks";
import useStores from "../../../../useStores";
import DossierTypes from "../../../../utils/constants/dossierType";
import { ReferralDetails } from "../../../customerRelationshipManager/types";
import { useSendiWelcomeViaCrm } from "../../../customerRelationshipManager/hooks";

export const ConfirmSendiWelcomeAlert = ({
  isOpen,
  setIsOpen,
  dossierId,
  dossierType,
  dossierHasReferralLetterAlready,
  crmReferral,
}: {
  isOpen: boolean;
  setIsOpen: (newVal: boolean) => void;
  dossierId: number;
  dossierType: "DossierGgz" | "DossierTransfer";
  dossierHasReferralLetterAlready: boolean;
  crmReferral: ReferralDetails | null;
}) => {
  const { onboardingStore } = useStores();

  const { data: templateToSend, isLoading: isLoadingTemplateToSend } =
    useGetiWelcomeAutomaticTemplate(dossierId);

  const onSuccess = () => {
    toast("E-mail verstuurd naar de patiënt", {
      description: `De iWelcome-e-mail is verstuurd naar de patiënt.`,
    });
    setIsOpen(false);

    setTimeout(async () => {
      if (dossierType == DossierTypes.GGZ) {
        await onboardingStore.fetchOnboarding(DossierTypes.GGZ, dossierId);
      } else if (dossierType == DossierTypes.TRANSFER) {
        await onboardingStore.fetchOnboarding(DossierTypes.TRANSFER, dossierId);
      }
    }, 1000);
  };

  const onError = () => {
    toast("E-mail could NOT be sent", {
      description: `Please try again later, or contact the IT team.`,
    });
  };

  const { mutate: sendEmailWithoutCrm, isLoading: isSendingEmailWithoutCrm } =
    useSendiWelcome(onSuccess);

  const { mutate: sendEmailViaCrm, isLoading: isSendingEmailViaCrm } =
    useSendiWelcomeViaCrm(onSuccess, onError);

  const isAboutToSendEmailWithReferralUploadSection = () => {
    if (templateToSend === undefined) return false;

    // there are some templates that inherently require a referral letter to be uploaded
    const isInherentlyRequiredReferralLetter = [
      "iWelcome Behandeling Referal Upload Part NL",
      "iWelcome Behandeling Referal Upload Part EN",
      "iWelcome ADHD-Behandeling Referal Upload NL",
      "iWelcome ADHD-Behandeling Referal Upload EN",
    ].includes(templateToSend.name);

    // we also have a special case for Menzis/VGZ --> this email template *always includes* the referral letter upload section, regardless if there is or is not a referral letter uploaded
    // just for the sake of bringing attention to MPs, we also want a warning here if there really isn't a referral present
    // this warning will have no effect on the actual sending of the email (again, it always includes the referral upload section)
    // while this does not make much sense, we were asked to do it this way
    const isVgzOrMenzisAdhdScreeningWithoutReferral =
      [
        "iWelcome ADHD-Screening (VGZ/Menzis) EN",
        "iWelcome ADHD-Screening (VGZ/Menzis) NL",
      ].includes(templateToSend.name) && !dossierHasReferralLetterAlready;

    return (
      isInherentlyRequiredReferralLetter ||
      isVgzOrMenzisAdhdScreeningWithoutReferral
    );
  };

  return (
    <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Weet u het zeker?</AlertDialogTitle>
          <AlertDialogDescription>
            <p>
              U staat op het punt om de iWelcome-e-mail automatisch te
              verzenden. Het sjabloon dat zal worden gebruikt, gebaseerd op de
              aanwezigheid van dit dossier en de verwijsbrief, is:
            </p>

            <p className="font-bold text-center py-4">
              {templateToSend?.name ?? "Even wachten, laden..."}
            </p>

            <p className={crmReferral !== null ? "visible pb-4" : "hidden"}>
              De e-mail zal worden verzonden naar:{" "}
              <span className="italic">{crmReferral?.email}</span>. Zorg ervoor
              dat het e-mailadres correct is voordat u de iWelcome-mail
              verstuurt.
            </p>

            {isAboutToSendEmailWithReferralUploadSection() && (
              <div className="rounded text-amber-600 px-4 py-2 mb-2 bg-amber-100">
                <p className="font-bold">Opmerking</p>
                <p className="pt-1 text-sm">
                  Je staat op het punt het iWelcome-sjabloon te versturen waarin
                  de gebruiker wordt gevraagd zijn of haar verwijsbrief te
                  uploaden. Als je de verwijsbrief bij de hand hebt, zorg er dan
                  voor dat je die eerst uploadt en probeer dan het
                  iWelcome-bericht te verzenden.
                </p>
              </div>
            )}
          </AlertDialogDescription>
        </AlertDialogHeader>
        {isLoadingTemplateToSend ? (
          <></>
        ) : (
          <AlertDialogFooter>
            <AlertDialogCancel
              className="w-1/2"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              Annuleren
            </AlertDialogCancel>
            <AlertDialogAction
              className="w-1/2"
              onClick={async (e) => {
                if (isSendingEmailWithoutCrm || isSendingEmailViaCrm) return;

                e.preventDefault(); // do not close on click immediately
                if (crmReferral === null) {
                  await sendEmailWithoutCrm({ dossierId });
                } else {
                  await sendEmailViaCrm({ referralId: crmReferral?.id });
                }
              }}
            >
              {isSendingEmailWithoutCrm || isSendingEmailViaCrm
                ? "Verzending..."
                : "Verzenden"}
            </AlertDialogAction>
          </AlertDialogFooter>
        )}
      </AlertDialogContent>
    </AlertDialog>
  );
};
