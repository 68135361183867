import { Mail, MapPin, Phone } from "lucide-react";
import { ReferralDetails } from "../types";
import { OthersViewingReferral } from "./othersViewingReferral/othersViewingReferral";
import { RejectReferralButton } from "./reject/rejectReferralButton";
import { ToggleArchiveReferralButton } from "./archive/toggleArchiveReferralButton";

export const DetailsHeader = ({ referral }: { referral: ReferralDetails }) => {
  const isArchived = referral.status === "Archived";

  return (
    <div className="w-full flex items-end justify-between">
      <section className="w-2/3">
        <div className="flex items-center space-x-2">
          <p className="text-4xl font-bold">
            {isArchived && "[Archived] "}
            {referral.firstName} {referral.lastName}
          </p>
        </div>
        <section className="pt-1 flex items-center space-x-6">
          {referral.location && (
            <div className="flex items-center space-x-1">
              <MapPin className="w-4 h-4" />
              <p>{referral.location?.name}</p>
            </div>
          )}

          <div className="flex items-center space-x-1">
            <Mail className="w-4 h-4" />
            <p>{referral.email}</p>
          </div>
          <div className="flex items-center space-x-1">
            <Phone className="w-4 h-4" />
            <p>{referral.mobilePhoneNumber}</p>
          </div>
        </section>

        <div className="flex items-center space-x-2">
          <ToggleArchiveReferralButton referral={referral} />
          <RejectReferralButton variant="header-button" referral={referral} />
        </div>
      </section>

      <section className="flex flex-col items-end">
        <OthersViewingReferral referralId={referral.id} />
      </section>
    </div>
  );
};
