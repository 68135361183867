import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../components/ui/popover";
import { Button } from "../../../../components/ui/button";
import { SlidersHorizontal } from "lucide-react";
import { Checkbox } from "../../../../components/ui/checkbox";
import { ColumnVisibilityPicker } from "../columns/columnVisibilityPicker";
import { ViewPreviousWeeksButton } from "../pastWeekData/viewPreviousWeekButton";
import { PageSizePicker } from "../../../../components/ui/page-size-picker";
import moment from "moment-timezone";
import { MainListPatient } from "../types";
import { Table } from "@tanstack/react-table";
import { useState } from "react";
import {
  getExcludeNoCheckinPatientsFromLocalStorage,
  getExcludeAlreadySupportedPatientsFromLocalStorage,
  getOnlyShowSoonOffboardingPatients,
} from "../utils";
import { refetchCaseLoadEntries } from "../hooks";
import { useTranslation } from "react-i18next";
import { useIsEnabled } from "../../../../feature-management/useIsEnabled";

type CustomizeButtonProps = {
  table: Table<MainListPatient>;
  pageSize: number;
  setPageSize: (newSize: number) => void;
  setSkip: (newSkip: number) => void;
  date: Date;
  onChangeDate: (newDate: Date) => void;
  onlyCaseLoadsWithAlert: boolean;
  setOnlyCaseLoadsWithAlert: (newVal: boolean) => void;
};

export const CustomizeButton = ({
  table,
  pageSize,
  setPageSize,
  setSkip,
  date,
  onChangeDate,
  onlyCaseLoadsWithAlert,
  setOnlyCaseLoadsWithAlert,
}: CustomizeButtonProps) => {
  const { timeZone: timeZoneOfUser } = Intl.DateTimeFormat().resolvedOptions();
  const { t } = useTranslation();

  const { data: isOpAutomationEnabled } = useIsEnabled(
    "EnableOnlinePsychologistOnAndOffBoardingAutomation"
  );

  const [excludeNoCheckinNeededPatients, setExcludeNoCheckinNeededPatients] =
    useState(getExcludeNoCheckinPatientsFromLocalStorage());

  const [excludeAlreadySupportedPatients, setExcludeAlreadySupportedPatients] =
    useState(getExcludeAlreadySupportedPatientsFromLocalStorage());

  const [onlyShowSoonOffboardingPatients, setOnlyShowSoonOffboardingPatients] =
    useState(getOnlyShowSoonOffboardingPatients());

  const numberOfFiltersInUse =
    // Number(onlyActiveCaseLoad) +
    Number(onlyCaseLoadsWithAlert) +
    Number(excludeNoCheckinNeededPatients) +
    Number(excludeAlreadySupportedPatients) +
    Number(onlyShowSoonOffboardingPatients);

  const onToggleOnlyCaseLoadsWithAlert = () => {
    const newValue = !onlyCaseLoadsWithAlert;
    setOnlyCaseLoadsWithAlert(newValue);
    localStorage.setItem(
      "only-show-case-load-with-alerts",
      newValue.toString()
    );

    refetchCaseLoadEntries();
  };

  const onToggleExcludeNoCheckInPatients = () => {
    const newValue = !excludeNoCheckinNeededPatients;
    setExcludeNoCheckinNeededPatients(newValue);
    localStorage.setItem("exclude-no-checkin-patients", newValue.toString());

    refetchCaseLoadEntries();
  };

  const onToggleExcludeAlreadySupportedPatients = () => {
    const newValue = !excludeAlreadySupportedPatients;
    setExcludeAlreadySupportedPatients(newValue);
    localStorage.setItem(
      "exclude-already-supported-patients",
      newValue.toString()
    );

    refetchCaseLoadEntries();
  };

  const onToggleOnlyShowSoonOffboardingPatients = () => {
    const newValue = !onlyShowSoonOffboardingPatients;
    setOnlyShowSoonOffboardingPatients(newValue);

    localStorage.setItem(
      "only-show-soon-offboarding-patients",
      newValue.toString()
    );

    refetchCaseLoadEntries();
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" className="flex items-center gap-2 px-6">
          <SlidersHorizontal className="w-4 h-4" />
          <p>{t("case-load-manager-customize-button-label")}</p>
          {numberOfFiltersInUse > 0 && (
            <div className="text-xs w-4 h-4 rounded-full bg-ipractice-blue text-center text-white">
              {numberOfFiltersInUse}
            </div>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-6 min-w-[24rem]" side="bottom" align="end">
        <div className="grid gap-4">
          <div className="space-y-2">
            <h4 className="font-bold text-lg leading-none">
              {t("case-load-manager-customization-popup-title")}
            </h4>
            <p className="text-sm text-muted-foreground">
              {t("case-load-manager-customization-popup-desc")}
            </p>
          </div>
          {/* 
            <div className="mt-4 flex flex-col space-y-4">
              <div className="grid gap-2">
                <div className="flex items-center space-x-2">
                  <Checkbox
                    id="onlyActive"
                    checked={onlyActiveCaseLoad}
                    onCheckedChange={() => {
                      onToggleOnlyActiveCaseLoad();
                      table.resetRowSelection();
                    }}
                  />
                  <div className="space-x-2 flex items-center">
                    <p>
                      {t(
                        "case-load-manager-customization-only-active-checkbox-label"
                      )}
                    </p>
                    <TooltipProvider delayDuration={100}>
                      <Tooltip>
                        <TooltipTrigger>
                          <HelpCircle className="h-4 w-4" />
                        </TooltipTrigger>
                        <TooltipContent
                          style={{ zIndex: 99999 }}
                          className="max-w-[24rem]"
                        >
                          <div
                            className="space-y-2"
                            dangerouslySetInnerHTML={{
                              __html: t(
                                "case-load-manager-customization-only-active-explanation-tooltip"
                              ),
                            }}
                          />
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                </div>
              </div>
            </div>
            */}

          <div className="flex flex-col space-y-4">
            <div className="grid gap-2">
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="onlyAlerts"
                  checked={onlyCaseLoadsWithAlert}
                  onCheckedChange={() => {
                    onToggleOnlyCaseLoadsWithAlert();
                    table.resetRowSelection();
                  }}
                />
                <p>
                  {t(
                    "case-load-manager-customization-only-alerts-checkbox-label"
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col space-y-4">
            <div className="grid gap-2">
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="excludeNoCheckin"
                  checked={excludeNoCheckinNeededPatients}
                  onCheckedChange={() => {
                    onToggleExcludeNoCheckInPatients();
                    table.resetRowSelection();
                  }}
                />
                <p>
                  {t(
                    "case-load-manager-customization-filter-exclude-no-checkin"
                  )}
                </p>
              </div>
            </div>
            <div className="grid gap-2">
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="excludeAlreadySupportedPatients"
                  checked={excludeAlreadySupportedPatients}
                  onCheckedChange={() => {
                    onToggleExcludeAlreadySupportedPatients();
                    table.resetRowSelection();
                  }}
                />
                <p>
                  {t(
                    "case-load-manager-customization-filter-exclude-already-supported-patients"
                  )}
                </p>
              </div>
            </div>

            {isOpAutomationEnabled && (
              <div className="grid gap-2">
                <div className="flex items-center space-x-2">
                  <Checkbox
                    id="onlyShowSoonOffboardingPatients"
                    checked={onlyShowSoonOffboardingPatients}
                    onCheckedChange={() => {
                      onToggleOnlyShowSoonOffboardingPatients();
                      table.resetRowSelection();
                    }}
                  />
                  <p>{t("case-load-manager-offboarding-filter")}</p>
                </div>
              </div>
            )}

            <div className="grid gap-2">
              <div className="grid grid-cols-3 items-center gap-4">
                <p>
                  {t("case-load-manager-customization-week-selector-label")}
                </p>
                <ViewPreviousWeeksButton
                  table={table}
                  selectedDate={date}
                  setSelectedDate={(newDate) => {
                    // calendar only uses the user's local timezone, so we need to convert it to UTC
                    // ..but, since we select only days, its always 00:00:00 for the time -> when converting to UTC, it may be a day off (eg. prev day, 23:00:00 between CET and UTC)
                    const timezonelessDate = moment(newDate)
                      .tz(timeZoneOfUser)
                      .tz("utc", true)
                      .toDate();

                    onChangeDate(newDate ? timezonelessDate : new Date());
                  }}
                />
              </div>
            </div>

            <div className="grid gap-2">
              <div className="grid grid-cols-3 items-center gap-4">
                <p>
                  {t(
                    "case-load-manager-customization-column-visibility-picker-label"
                  )}
                </p>
                <ColumnVisibilityPicker table={table} />
              </div>
            </div>
            <div className="grid gap-2">
              <div className="grid grid-cols-3 items-center gap-4">
                <p>
                  {t("case-load-manager-customization-page-size-picker-label")}
                </p>
                <PageSizePicker
                  pageSize={pageSize}
                  setPageSize={(newPageSize) => {
                    setSkip(0);
                    setPageSize(newPageSize);
                    table.resetRowSelection();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
