import Cookies from "universal-cookie";

const cookies = new Cookies();

export function setCookie(cookieName, value, path = '/') {
    cookies.set(cookieName, value, {
        path,
        secure: true,
        sameSite: "strict"
    });
}

export function getCookie(cookieName) {
    return cookies.get(cookieName);
}
