import { Loader2, X } from "lucide-react";
import { ChatConversationMember } from "../../streamChat/types";
import { useRemoveUserFromChat } from "../hooks";
import { toast } from "sonner";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";
import { useState } from "react";
import { getReadableRole } from "../utils";

export const UserOfChannel = ({
  channelId,
  user,
}: {
  channelId: string;
  user: ChatConversationMember;
}) => {
  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] =
    useState(false);

  const { mutate: removeUserFromChat, isLoading: isLoadingRemoveUserFromChat } =
    useRemoveUserFromChat(() => {
      toast("User removed from chat", {
        description: "The user has been removed from the chat",
      });
    });

  return (
    <li>
      <li className="p-2 rounded border flex items-center justify-between">
        <div>
          <p className="font-bold">{user.userName}</p>
          <p className="text-xs">{getReadableRole(user.role)}</p>
        </div>

        {user.role == "client" ? (
          <></>
        ) : (
          <div
            className="w-4 h-4 opacity-75 hover:opacity-100 cursor-pointer"
            onClick={() => setIsDeleteConfirmationDialogOpen(true)}
          >
            {isLoadingRemoveUserFromChat ? (
              <Loader2 className="w-4 h-4" />
            ) : (
              <X className="w-4 h-4" />
            )}
          </div>
        )}
      </li>
      <Dialog
        open={isDeleteConfirmationDialogOpen}
        onOpenChange={setIsDeleteConfirmationDialogOpen}
      >
        <DialogContent className="p-8 w-[420px]">
          <DialogHeader>
            <DialogTitle className="text-xl">
              Remove {user.userName}
            </DialogTitle>
            <DialogDescription>
              Are you sure you would like to remove {user.userName} from this
              chat?
            </DialogDescription>
          </DialogHeader>

          <div className="flex items-center justify-between w-full space-x-2 pt-2">
            <Button
              variant="outline"
              className="w-1/2"
              onClick={() => setIsDeleteConfirmationDialogOpen(false)}
            >
              No, cancel
            </Button>
            <Button
              variant="destructive"
              className="w-1/2"
              onClick={() => {
                removeUserFromChat({
                  channelId,
                  userId: user.userId,
                });
                setIsDeleteConfirmationDialogOpen(false);
              }}
            >
              Yes, remove user
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </li>
  );
};
