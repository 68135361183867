import moment from 'moment';

moment.updateLocale('nl', {
  week: {
    dow: 1, // Monday is the first day of the week
    doy: 4, // The week that contains Jan 4th is the first week of the year (ISO 8601)
  },
});

export const getSimpleFormattedDate = (input: Date | string) => {
  const inputAsDate = input instanceof Date ? input : new Date(input);

  return inputAsDate.toLocaleDateString('nl-NL', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

export const getSimpleFormattedDateTime = (input: Date | string) => {
  const inputAsDate = input instanceof Date ? input : new Date(input);

  return inputAsDate.toLocaleString('nl-NL', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });
};

export const getSimpleFormattedDateWithoutYear = (input: Date | string) => {
  const inputAsDate = input instanceof Date ? input : new Date(input);

  return inputAsDate.toLocaleString('nl-NL', {
    month: '2-digit',
    day: '2-digit',
  });
};

export const isDateInPastWeeks = (date: Date) => {
  const currentDate = new Date();
  const currentMoment = moment(currentDate);
  const selectedMoment = moment(date);

  // Get ISO week-year and week number for both dates
  const [currentWeekYear, currentWeek] = [currentMoment.isoWeekYear(), currentMoment.isoWeek()];
  const [selectedWeekYear, selectedWeek] = [selectedMoment.isoWeekYear(), selectedMoment.isoWeek()];

  // Compare week-years first, then weeks if in same week-year
  return (
    currentWeekYear > selectedWeekYear ||
    (currentWeekYear === selectedWeekYear && currentWeek > selectedWeek)
  );
};

export const isPeriodInPastWeeks = (period: { year: number; week: number }) => {
  const currentDate = new Date();
  const currentMoment = moment(currentDate);

  // Get ISO week-year and week number for current date
  const currentWeekYear = currentMoment.isoWeekYear();
  const currentWeek = currentMoment.isoWeek();

  // Compare week-years first, then weeks if in same week-year
  return (
    currentWeekYear > period.year || (currentWeekYear === period.year && currentWeek > period.week)
  );
};

export const isPeriodInFutureWeeks = (period: { year: number; week: number }) => {
  const currentDate = new Date();
  const currentMoment = moment(currentDate);

  // Get ISO week-year and week number for current date
  const currentWeekYear = currentMoment.isoWeekYear();
  const currentWeek = currentMoment.isoWeek();

  // Compare week-years first, then weeks if in same week-year
  return (
    currentWeekYear < period.year || (currentWeekYear === period.year && currentWeek < period.week)
  );
};

export const getCurrentMondayAndFriday = () => {
  let now = new Date();
  let day = now.getDay();
  let diffToMonday = now.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is Sunday
  let diffToFriday = diffToMonday + 4; // 4 days after Monday

  let monday = new Date(now.setDate(diffToMonday));
  monday.setHours(0, 0, 0, 0); // set time to 00:00:00.000

  let friday = new Date(now.setDate(diffToFriday));
  friday.setHours(23, 59, 59, 999); // set time to 23:59:59.999

  return {
    monday: moment(monday).utc(true).toDate(),
    friday: moment(friday).utc(true).toDate(),
  };
};

export const getNextMonday = () => {
  const today = new Date();
  const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)
  const daysUntilNextMonday = (8 - dayOfWeek) % 7 || 7;
  const nextMonday = new Date(today);
  nextMonday.setDate(today.getDate() + daysUntilNextMonday);
  nextMonday.setHours(0, 0, 0, 0); // reset time
  return moment(nextMonday).utc(true).toDate();
};
