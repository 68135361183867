import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { Button } from "../../../../components/ui/button";
import Calendar from "../../../calendar/Calendar";
import { ReferralDetails } from "../../types";
import { EventInfo } from "../../../calendar/CalendarEvent";
import { useScheduleIntake } from "../../hooks";
import { toast } from "sonner";

export const ScheduleIntakeViaCalendarDialog = ({
  referral,
  isDialogOpen,
  setIsDialogOpen,
}: {
  referral: ReferralDetails;
  isDialogOpen: boolean;
  setIsDialogOpen: (newValue: boolean) => void;
}) => {
  const [selectedTimeslot, setSelectedTimeslot] = useState<EventInfo | null>(
    null
  );

  const { mutate: scheduleIntake, isLoading: isSchedulingIntake } =
    useScheduleIntake(() => {
      setSelectedTimeslot(null);
      setIsDialogOpen(false);

      toast("Intake scheduled", {
        description: "The intake has been successfully scheduled.",
      });
    });

  const mapReferralLanguageToCalendarLanguage = () => {
    return referral.language!.slice(0, 2);
  };

  const mapReferralIntakeLocationToCalendarLocation = () => {
    if (referral.location?.name?.includes("Online")) return "Online";
    if (referral.location?.name?.includes("Amsterdam")) return "Amsterdam";

    return referral.location?.name;
  };

  return (
    <Dialog
      open={isDialogOpen}
      onOpenChange={(e) => {
        setIsDialogOpen(e);
      }}
    >
      <DialogContent className="p-8 min-w-[95vw]">
        <div className="max-h-[80vh] overflow-y-auto">
          <Calendar
            allowEventSelection
            onSelectTimeslot={setSelectedTimeslot}
            defaultFilters={{
              locations: referral.location
                ? [mapReferralIntakeLocationToCalendarLocation()]
                : [],
              languages: referral.language
                ? [mapReferralLanguageToCalendarLanguage()]
                : [],
            }}
          />
          {selectedTimeslot !== null ? (
            <Dialog open={true} onOpenChange={(e) => setSelectedTimeslot(null)}>
              <DialogContent className="w-[30vw]">
                <DialogHeader>
                  <DialogTitle>Schedule appointment?</DialogTitle>
                  <DialogDescription>
                    <p>Are you sure to schedule an appointment?</p>
                    <ul className="py-2 border-t mt-2">
                      <li>
                        Psychologist:{" "}
                        <span className="font-bold">
                          {selectedTimeslot!.event.extendedProps.employee.name}
                        </span>
                      </li>
                      <li>
                        Timeslot:{" "}
                        <span className="font-bold">
                          {selectedTimeslot!.timeText}
                        </span>
                      </li>
                    </ul>
                  </DialogDescription>
                </DialogHeader>
                <DialogFooter>
                  <Button
                    variant="outline"
                    className="w-1/2"
                    onClick={() => {
                      setSelectedTimeslot(null);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="w-1/2"
                    onClick={async (e) => {
                      e.preventDefault();

                      if (!isSchedulingIntake) {
                        await scheduleIntake({
                          referralId: referral.id,
                          selectedPsychologistEpdId:
                            selectedTimeslot!.event.extendedProps.employee
                              .epdId,
                          appointmentOn: selectedTimeslot!.event.start,
                        });
                      }
                    }}
                  >
                    {isSchedulingIntake ? "Scheduling..." : "Schedule"}
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          ) : (
            <></>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
