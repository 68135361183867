import { ColumnDef } from "@tanstack/react-table";
import { MainListPatient } from "../../types";
import { OffboardButton } from "../../../offboardingClients/offboardButton";

export const OffboardingColName = "Offboarding";

export const OffboardingCol: ColumnDef<MainListPatient> = {
  id: OffboardingColName,
  accessorKey: "isSoonToBeDischarged",
  size: 270,
  minSize: 120,
  header: () => {
    return <p>Offboarding</p>;
  },
  cell: ({ row: { original } }) => {
    return (
      <div className="py-2 px-4">
        <OffboardButton data={original} showLabel={false} />
      </div>
    );
  },
};
