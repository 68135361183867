import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../../components/ui/dialog";
import { Button } from "../../../../../components/ui/button";
import { Checkbox } from "../../../../../components/ui/checkbox";
import { useState } from "react";
import { NoCheckinReasonSelector } from "../../needsCheckin/noCheckinReasonSelector";
import { Textarea } from "../../../../../components/ui/textarea";
import { useMassUpdatePatientsRequireSupportState } from "../../hooks";
import { MainListPatient } from "../../types";
import { Loader2 } from "lucide-react";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";

type NeedsCheckinForMultiplePatientsDialogWrapperProps = {
  isOpen: boolean;
  close: () => void;
  children: any;
  selectedPatients: MainListPatient[];
};

export const NeedsCheckinForMultiplePatientsDialogWrapper = ({
  isOpen,
  close,
  children,
  selectedPatients,
}: NeedsCheckinForMultiplePatientsDialogWrapperProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(true);
  const [selectedJustificationReason, setSelectedJustificationReason] =
    useState<undefined | string>(undefined);

  const [justificationFreeText, setJustificationFreeText] =
    useState<string>("");

  const getJustificationForSave = () => {
    if (value) return undefined;

    if (selectedJustificationReason === "other") {
      return justificationFreeText;
    }

    return selectedJustificationReason;
  };

  const { mutate: save, isLoading: isSaving } =
    useMassUpdatePatientsRequireSupportState(() => {
      setSelectedJustificationReason(undefined);
      setJustificationFreeText("");
      close();

      toast(
        t(
          "case-load-manager-update-needs-checkin-for-multiple-patients-confirmation-toast-title"
        ),
        {
          description: t(
            "case-load-manager-update-needs-checkin-for-multiple-patients-confirmation-toast-desc"
          ),
        }
      );
    });

  const canSave = () => {
    if (value) return true;

    if (selectedJustificationReason === "other") {
      return !!justificationFreeText;
    }

    return !!selectedJustificationReason;
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onOpenChange={(newVal) => {
          if (!newVal) {
            setValue(true);
            close();
          }
        }}
      >
        <DialogContent className="sm:max-w-[500px]">
          <DialogHeader>
            <DialogTitle>
              {t(
                "case-load-manager-update-needs-checkin-for-multiple-patients-dialog-title"
              )}
            </DialogTitle>
            <DialogDescription>
              {t(
                "case-load-manager-update-needs-checkin-for-multiple-patients-dialog-desc"
              )}
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="flex items-center space-x-2">
              <Checkbox
                id="chattedWith"
                checked={value}
                onCheckedChange={() => {
                  setValue(!value);

                  if (value) {
                    setSelectedJustificationReason(undefined);
                    setJustificationFreeText("");
                  }
                }}
                disabled={isSaving}
              />
              <label
                htmlFor="chattedWith"
                className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                {t(
                  "case-load-manager-update-needs-checkin-for-multiple-patients-checkbox-label"
                )}
              </label>
            </div>
          </div>

          {!value && (
            <div>
              <p className="pb-2">
                {t(
                  "case-load-manager-update-needs-checkin-for-multiple-patients-justification-selector-label"
                )}
              </p>
              <NoCheckinReasonSelector
                multiplePatients
                selectedJustificationReason={selectedJustificationReason}
                setSelectedJustificationReason={setSelectedJustificationReason}
                isSaving={false}
              />

              {selectedJustificationReason === "other" && (
                <div className="mt-6">
                  <p>
                    {t(
                      "case-load-manager-patient-no-checkin-enter-justification-label"
                    )}
                  </p>

                  <Textarea
                    value={justificationFreeText}
                    onChange={(e) => setJustificationFreeText(e.target.value)}
                    disabled={isSaving}
                    className="pt-2 min-h-[80px]"
                  />
                </div>
              )}
            </div>
          )}
          <DialogFooter>
            <Button
              onClick={async () => {
                if (!isSaving) {
                  await save({
                    caseLoadEntryIds: selectedPatients.map((p) => p.id),
                    checkInNeeded: value,
                    justificationIfNo: getJustificationForSave(),
                  });
                }
              }}
              className="w-full"
              disabled={!canSave()}
            >
              {isSaving ? (
                <div className="flex items-center space-x-1">
                  <Loader2 className="w-4 h-4 animate-spin" />
                  <p>{t("case-load-manager-saving")}</p>
                </div>
              ) : (
                <p>{t("case-load-manager-save")}</p>
              )}
            </Button>
          </DialogFooter>
        </DialogContent>

        {children}
      </Dialog>
    </>
  );
};
