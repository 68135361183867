// React
import React from "react";
import ReactDOM from "react-dom/client";
import "./styles.css";

// Misc
import { configure } from "mobx";
import * as moment from "moment";
import "moment/locale/nl";

// Providers
import { ThemeProvider, StyledEngineProvider, CssBaseline } from "@mui/material";
import { queryClient } from "./react-query/provider";
import { QueryClientProvider } from "react-query";
import { SnackbarProvider } from "notistack";
import theme from "./theme";

// Initializations
import AxiosSetup from "./AxiosSetup";
import AuthorizedTranslatedRouter from "./Router";
import { Toaster } from "./components/ui/sonner";

import { msalConfig } from "./pages/auth/msalConfig";
import { MsalProvider } from "@azure/msal-react";
import {
  PublicClientApplication,
  EventType,
  IPublicClientApplication,
  EventPayload,
  EventMessage,
  AccountInfo,
} from "@azure/msal-browser";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { CustomNavigationClient } from "./pages/auth/NavigationClient";
import { authStore } from './stores/AuthStore';

configure({ enforceActions: "always" }); // https://mobx.js.org/best/actions.html

moment.locale(localStorage.getItem("preferred-lang") ?? "nl");

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    if (
      (event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
        event.eventType === EventType.SSO_SILENT_SUCCESS) &&
      (event.payload as AccountInfo)
    ) {
      msalInstance.setActiveAccount(event.payload as AccountInfo);
    }
  });

  type AppProps = {
    pca: IPublicClientApplication;
  };

  const App: React.FC<AppProps> = ({ pca }) => {
    return (
      <MsalProvider instance={pca}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider>
              <AxiosSetup />
              <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                  <AppWithRouter pca={pca} />
                </BrowserRouter>
              </QueryClientProvider>
              <Toaster />
            </SnackbarProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </MsalProvider>
    );
  };

  const AppWithRouter: React.FC<AppProps> = ({ pca }) => {
     const navigate = useNavigate();
     const navigationClient = new CustomNavigationClient(navigate);
     pca.setNavigationClient(navigationClient);

    return <AuthorizedTranslatedRouter />;
  };

  const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

  authStore.initialize().then(() => {
    root.render(
      <React.StrictMode>
        <App pca={msalInstance} />
      </React.StrictMode>
    );
  });
});