import { Button } from "./button";

export const PageSizePicker = ({
  pageSize,
  setPageSize,
}: {
  pageSize: number;
  setPageSize: (newSize: number) => void;
}) => {
  return (
    <div className="flex items-center justify-between w-full space-x-1">
      {[10, 20, 50, 100].map((size) => {
        return (
          <Button
            key={size}
            variant={size === pageSize ? "default" : "outline"}
            className="w-[3.15rem]"
            onClick={() => setPageSize(size)}
          >
            {size}
          </Button>
        );
      })}
    </div>
  );
};
