import { useState } from 'react';
import { Loader2 } from 'lucide-react';
import { PsychologistFilter } from '../psychologistFilter';
import { useGetLocationTags } from './hooks';
import { AlgorithmOutputTable } from './Table';
import { Explanations } from './Explanations';

export const AlgorithmOutput = () => {
  const [selectedPsyEpdId, setSelectedPsyEpdId] = useState('');
  const { data: calendarAlgoResultData, isFetching } = useGetLocationTags(selectedPsyEpdId);

  return (
    <div>
      <h1 className="text-xl font-bold">View the occupancy prediction algorithm's output</h1>
      <p>
        Every few minutes, this data gets automatically refreshed to the latest algorithm
        calculation's results.
      </p>

      <PsychologistFilter selectedPsy={selectedPsyEpdId} setSelectedPsy={setSelectedPsyEpdId} />

      {isFetching && (
        <div className="flex items-center space-x-2 p-4">
          <Loader2 className="w-4 h-4 animate-spin" />
          <p>Loading result...</p>
        </div>
      )}

      {calendarAlgoResultData?.data && <AlgorithmOutputTable data={calendarAlgoResultData!.data} />}
      {calendarAlgoResultData?.explanations && (
        <Explanations data={calendarAlgoResultData!.explanations} />
      )}
    </div>
  );
};
