import { useEffect, useState } from "react";

import {
  useGetLocationWithTeamTags,
  LocationWithTeamTag,
} from "../../../../shared/hooks/useGetLocationWithTeamTags";
import { LocationAndTeamFilter } from "../../../../shared/LocationAndTeamFilter";

type SchedulePreIntakeLocationAndTeamFilterProps = {
  isLoadingCaseLoads: boolean;
  defaultLocationFilter: string | null;
  defaultTeamFilter: number | null;
  selectedLocationAndTeam: LocationWithTeamTag | null;
  setSelectedLocationAndTeam: React.Dispatch<
    React.SetStateAction<LocationWithTeamTag | null>
  >;
};

export const SchedulePreIntakeLocationAndTeamFilter = ({
  isLoadingCaseLoads,
  defaultLocationFilter,
  defaultTeamFilter,
  selectedLocationAndTeam,
  setSelectedLocationAndTeam,
}: SchedulePreIntakeLocationAndTeamFilterProps) => {
  const {
    data: selectableLocationAndTeams,
    isLoading: isLoadingLocationWithTeamTags,
  } = useGetLocationWithTeamTags((data) => {
    setDefaultLocationAndTeam(data);
  });

  useEffect(() => {
    setDefaultLocationAndTeam(selectableLocationAndTeams ?? []);
  }, [defaultLocationFilter, defaultTeamFilter]);

  const setDefaultLocationAndTeam = (data: LocationWithTeamTag[]) => {
    if (defaultLocationFilter == null) return;

    if (defaultLocationFilter.startsWith("Amsterdam")) {
      if (defaultTeamFilter != null) {
        setSelectedLocationAndTeam(
          data.filter((d) => d.teamId == defaultTeamFilter)[0]
        );
      } else {
        setSelectedLocationAndTeam(
          data.filter((x) => x.locationName === "Amsterdam")[0]
        );
      }
    } else {
      const location = data.filter(
        (x) => x.locationName === defaultLocationFilter
      )[0];
      setSelectedLocationAndTeam(location);
    }
  };

  return (
    <div className="w-1/3">
      <LocationAndTeamFilter
        isLoading={isLoadingCaseLoads || isLoadingLocationWithTeamTags}
        selectableLocationAndTeams={selectableLocationAndTeams ?? []}
        selectedLocationAndTeam={selectedLocationAndTeam}
        setSelectedLocationAndTeam={setSelectedLocationAndTeam}
      />
    </div>
  );
};
