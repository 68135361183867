import { useState } from "react";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../../../components/ui/alert-dialog";
import { Button } from "../../../../components/ui/button";
import { toast } from "sonner";
import { useRecalculateEmployeeOccupancyPredictions } from "../../hooks";
export const ResyncCalendarAlgorithm = () => {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const { mutate, isLoading } = useRecalculateEmployeeOccupancyPredictions(
    () => {
      setIsConfirmDialogOpen(false);
      toast("Resynchronized", {
        description: "The data has been successfully resynchronized.",
      });
    }
  );

  return (
    <div className="mt-12 p-8 border-t-2 border-red-300 dark:border-red-950 bg-red-50 dark:bg-slate-900">
      <h1 className="font-bold text-xl">
        Re-synchronize occupancy and availability predictions
      </h1>
      <p>
        Re-synchronize all Praktijkdata appointments and rooster, then
        re-calculate the occupancy and availability data.
      </p>

      <AlertDialog
        open={isConfirmDialogOpen}
        onOpenChange={setIsConfirmDialogOpen}
      >
        <AlertDialogTrigger asChild>
          <Button variant="destructive" className="mt-6">
            Regenerate
          </Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription className="space-y-2">
              <p>
                The system will re-synchronize the appointments and rooster from
                Praktijkdata and then recalculate the occupancy and availability
                predictions for all employees. This will take a few minutes.
              </p>
              <p>If you are sure, please click the "Regenerate" button.</p>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <Button variant="destructive" onClick={async () => await mutate()}>
              {isLoading ? "Regenerating..." : "Regenerate"}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};
