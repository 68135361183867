import { useTranslation } from "react-i18next";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import { useGetMyChatToConsultationRoomAppointmentsRatio } from "../hooks";
import { OpToCrpPsychologistRatioType } from "../types";
import { ChevronsUpDown, Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import { OpToCrpPsychologistRatioAverage } from "./average";

export const OpToCrpPsychologistRatio = () => {
  const { t } = useTranslation();
  const [orderBy, setOrderBy] = useState<{
    by: "patient" | "chat-count" | "consultation-count" | "ratio";
    order: "asc" | "desc";
  }>({ by: "patient", order: "asc" });

  const [sortedData, setSortedData] = useState<OpToCrpPsychologistRatioType[]>(
    []
  );

  const { data, isLoading } = useGetMyChatToConsultationRoomAppointmentsRatio(
    (x) => setSortedData(x)
  );

  useEffect(() => {
    if (!data || data.length == 0) return;

    const result = data.toSorted((a, b) => {
      if (orderBy.by == "patient") {
        let comparison = a.patient.localeCompare(b.patient);
        return orderBy.order === "asc" ? comparison : -comparison;
      } else if (orderBy.by == "chat-count") {
        let comparison =
          a.numberOfChatAppointments - b.numberOfChatAppointments;
        return orderBy.order === "asc" ? comparison : -comparison;
      } else if (orderBy.by == "consultation-count") {
        let comparison =
          a.numberOfConsultationRoomAppointments -
          b.numberOfConsultationRoomAppointments;
        return orderBy.order === "asc" ? comparison : -comparison;
      } else {
        let comparison =
          a.numberOfChatAppointments / a.numberOfConsultationRoomAppointments -
          b.numberOfChatAppointments / b.numberOfConsultationRoomAppointments;

        // force divide by zeros to the start/end (depending on sorting direction)
        if (a.numberOfConsultationRoomAppointments == 0)
          return orderBy.order === "asc" ? -1 : 10000;

        if (b.numberOfConsultationRoomAppointments == 0)
          return orderBy.order === "asc" ? 10000 : -1;

        return orderBy.order === "asc" ? comparison : -comparison;
      }
    });

    setSortedData(result);
  }, [orderBy]);

  const renderTable = () => {
    if (isLoading) {
      return (
        <TableRow>
          <TableCell colSpan={4}>
            <div className="flex items-center space-x-1 py-2">
              <Loader2 className="w-4 h-4 animate-spin" />
              <p>{t("case-load-manager-loading")}</p>
            </div>
          </TableCell>
        </TableRow>
      );
    }

    if (data!.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={4}>
            <p>{t("case-load-manager-no-results-found")}</p>
          </TableCell>
        </TableRow>
      );
    }

    return (
      <>
        {sortedData.map((d) => {
          return (
            <TableRow>
              <TableCell>{d.patient}</TableCell>
              <TableCell className="text-center border-l">
                {d.numberOfChatAppointments}
              </TableCell>
              <TableCell className="text-center border-l">
                {d.numberOfConsultationRoomAppointments}
              </TableCell>
              <TableCell className="text-center border-l">
                {getDetailedRatio(
                  d,
                  t(
                    "case-load-manager-statistics-op-skp-ratio-no-cpr-appointments"
                  )
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };

  const SortIcon = ({
    by,
  }: {
    by: "patient" | "chat-count" | "consultation-count" | "ratio";
  }) => {
    return (
      <div className="h-4 w-4">
        <ChevronsUpDown
          className="h-4 w-4 cursor-pointer opacity-50 hover:opacity-100"
          onClick={() => {
            setOrderBy({
              by,
              order:
                orderBy.by == by && orderBy.order === "asc" ? "desc" : "asc",
            });
          }}
        />
      </div>
    );
  };

  return (
    <div>
      <div className="flex items-start justify-between space-x-4 divide-x">
        <div>
          <h1 className="text-2xl font-bold">
            {t("case-load-manager-statistics-op-skp-ratio-title")}
          </h1>
          <p>{t("case-load-manager-statistics-op-skp-ratio-desc")}</p>

          <p className="pt-2 font-medium">
            {t("case-load-manager-statistics-op-skp-ratio-target")}
          </p>
        </div>
        <OpToCrpPsychologistRatioAverage data={data} />
      </div>

      <div className="mt-12">
        <Table limitHeight className="border">
          <TableHeader>
            <TableRow>
              <TableHead className="w-1/4">
                <div className="flex items-center space-x-2 w-full">
                  <p>
                    {t("case-load-manager-statistics-op-skp-ratio-patient")}
                  </p>
                  <SortIcon by="patient" />
                </div>
              </TableHead>
              <TableHead className="text-center w-1/4">
                <div className="flex items-center justify-center space-x-2 w-full">
                  <p>
                    {t(
                      "case-load-manager-statistics-op-skp-ratio-chat-appointments"
                    )}
                  </p>
                  <SortIcon by="chat-count" />
                </div>
              </TableHead>
              <TableHead className="text-center w-1/4">
                <div className="flex items-center justify-center space-x-2 w-full">
                  <p>
                    {t(
                      "case-load-manager-statistics-op-skp-ratio-cpr-appointments"
                    )}
                  </p>
                  <SortIcon by="consultation-count" />
                </div>
              </TableHead>
              <TableHead className="text-center w-1/4">
                <div className="flex items-center justify-center space-x-2 w-full">
                  <p>
                    {t(
                      "case-load-manager-statistics-op-skp-ratio-label-for-ratio"
                    )}
                  </p>
                  <SortIcon by="ratio" />
                </div>
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>{renderTable()}</TableBody>
        </Table>
      </div>
    </div>
  );
};

export const getDetailedRatio = (
  data: OpToCrpPsychologistRatioType,
  placeHolderForNoConsultationRoomAppointments: string
) => {
  if (data.numberOfConsultationRoomAppointments === 0)
    return placeHolderForNoConsultationRoomAppointments;

  const result =
    data.numberOfChatAppointments / data.numberOfConsultationRoomAppointments;

  return result.toFixed(2);
};

export const getMainCount = (
  dataPerPsychologist: OpToCrpPsychologistRatioType[]
) => {
  const totalNumberOfChatAppointments = dataPerPsychologist.reduce(
    (acc, curr) => acc + curr.numberOfChatAppointments,
    0
  );

  const totalNumberOfRegularAppointments = dataPerPsychologist.reduce(
    (acc, curr) => acc + curr.numberOfConsultationRoomAppointments,
    0
  );

  const result =
    totalNumberOfChatAppointments == 0
      ? 0
      : totalNumberOfChatAppointments / totalNumberOfRegularAppointments;

  return result.toFixed(2);
};
