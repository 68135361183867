export function getPeriodDifference(startDate, endDate) {
    let periodLengthInDays = 14;
    
    let startDate1 = getStartDateOfPeriod(new Date(startDate.setHours(0, 0, 0, 0)));
    let startDate2 = getStartDateOfPeriod(new Date(endDate.setHours(0, 0, 0, 0)));

    let difference = Math.abs(startDate2 - startDate1) / (1000 * 3600 * 24);

    return Math.floor(Math.round(difference) / periodLengthInDays);
};
const getWeekNumber = (date) => {
    let d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    let yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    return Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
};

export function getStartDateOfPeriod(inputDate) {
    let date = new Date(inputDate.getTime());
    let dayOfWeek = date.getDay();
    let daysToMonday = (dayOfWeek === 0) ? 6 : dayOfWeek - 1;
    date.setDate(date.getDate() - daysToMonday);
    let weekNumber = getWeekNumber(date);
    if (weekNumber % 2 === 0) {
        date.setDate(date.getDate() - 7);
    }
    return date;
}
