import { ColumnDef } from "@tanstack/react-table";
import { MainListPatient } from "../../types";
import { useTranslation } from "react-i18next";
import { isPeriodInPastWeeks } from "../../../../../utils/dateUtils";
import { Comments } from "../../treatmentPlan/comments";
import { ViewPraktijkDataTreatmentPlan } from "../../treatmentPlan/viewTreatmentPlanButton";

export const TreatmentColName = "Treatment";

export const TreatmentCol: ColumnDef<MainListPatient> = {
  id: TreatmentColName,
  accessorKey: "treatment",
  size: 225,
  minSize: 100,
  header: () => {
    const { t } = useTranslation();
    return <p>{t("case-load-manager-column-treatment")}</p>;
  },
  cell: ({ getValue, row }) => {
    const isInPast = isPeriodInPastWeeks(row.original.period);

    if (isInPast)
      return (
        <p className="py-2 px-4">
          {(getValue() as string | undefined)?.toString() ?? ""}
        </p>
      );

    return (
      <div className="py-2 px-4">
        <Comments data={row.original} />
        <ViewPraktijkDataTreatmentPlan data={row.original} />
      </div>
    );
  },
};
