import {
  EmptySpotsAndTrajectoriesResult,
  TableDataPerPsychologist,
  WeekResult,
} from "../types";
import { TableCell, TableRow } from "../../../components/ui/table";

export const FlatTable = ({
  data,
  locations,
  psychologist,
}: {
  data: EmptySpotsAndTrajectoriesResult;
  locations: string[];
  psychologist: string;
}) => {
  let uniqueLocations =
    locations.length > 0
      ? new Set(locations)
      : new Set(data.period1.flatMap((x) => x.locations));

  const getData = (
    psyEpdId: number,
    data: WeekResult[],
    prop: keyof WeekResult
  ) => {
    const found = data.find((y) => y.epdIdOfPsychologist === psyEpdId);
    if (!found) return 0;
    return found[prop] as number;
  };

  const rows: TableDataPerPsychologist[] = [...uniqueLocations]
    .toSorted((a, b) => a.localeCompare(b))
    .flatMap((loc) => {
      const period1 = data.period1.filter((x) => x.locations.includes(loc));
      const period2 = data.period2.filter((x) => x.locations.includes(loc));
      const period3 = data.period3.filter((x) => x.locations.includes(loc));
      const period4 = data.period4.filter((x) => x.locations.includes(loc));
      const period5 = data.period5.filter((x) => x.locations.includes(loc));

      return period1
        .filter((x) => {
          return (
            psychologist === "" ||
            x.epdIdOfPsychologist.toString() === psychologist
          );
        })
        .toSorted((a, b) => a.psychologist.localeCompare(b.psychologist))
        .map((x) => {
          const epdId = x.epdIdOfPsychologist;

          return {
            location: loc,
            psychologist: x.psychologist,
            epdIdOfPsychologist: x.epdIdOfPsychologist,

            emptySpotsInAgendaPeriod1: x.emptySpotsInAgenda,
            emptyIntakeSpotsPeriod1: x.emptyIntakeSpots,

            emptySpotsInAgendaPeriod2: getData(
              epdId,
              period2,
              "emptySpotsInAgenda"
            ),
            emptyIntakeSpotsPeriod2: getData(
              epdId,
              period2,
              "emptyIntakeSpots"
            ),

            emptySpotsInAgendaPeriod3: getData(
              epdId,
              period3,
              "emptySpotsInAgenda"
            ),
            emptyIntakeSpotsPeriod3: getData(
              epdId,
              period3,
              "emptyIntakeSpots"
            ),

            emptySpotsInAgendaPeriod4: getData(
              epdId,
              period4,
              "emptySpotsInAgenda"
            ),
            emptyIntakeSpotsPeriod4: getData(
              epdId,
              period4,
              "emptyIntakeSpots"
            ),

            emptySpotsInAgendaPeriod5: getData(
              epdId,
              period5,
              "emptySpotsInAgenda"
            ),
            emptyIntakeSpotsPeriod5: getData(
              epdId,
              period5,
              "emptyIntakeSpots"
            ),
          };
        });
    });

  const columns: (keyof TableDataPerPsychologist)[] = [
    "emptySpotsInAgendaPeriod1",
    "emptyIntakeSpotsPeriod1",

    "emptySpotsInAgendaPeriod2",
    "emptyIntakeSpotsPeriod2",

    "emptySpotsInAgendaPeriod3",
    "emptyIntakeSpotsPeriod3",

    "emptySpotsInAgendaPeriod4",
    "emptyIntakeSpotsPeriod4",

    "emptySpotsInAgendaPeriod5",
    "emptyIntakeSpotsPeriod5",
  ];

  return (
    <>
      {rows.map((r) => {
        return (
          <TableRow>
            <TableCell className="min-w-[250px] sticky left-0 z-10 bg-slate-50">
              {r.location}
            </TableCell>
            <TableCell className="min-w-[250px] sticky left-[250px] z-10 bg-slate-50 border-r">
              {r.psychologist}
            </TableCell>

            <TableCell>
              {parseFloat(r.emptySpotsInAgendaPeriod1.toFixed(1))}
            </TableCell>
            <TableCell>
              {parseFloat(r.emptyIntakeSpotsPeriod1.toFixed(1))}
            </TableCell>
            <TableCell>
              {parseFloat(r.emptySpotsInAgendaPeriod2.toFixed(1))}
            </TableCell>
            <TableCell>
              {parseFloat(r.emptyIntakeSpotsPeriod2.toFixed(1))}
            </TableCell>
            <TableCell>
              {parseFloat(r.emptySpotsInAgendaPeriod3.toFixed(1))}
            </TableCell>
            <TableCell>
              {parseFloat(r.emptyIntakeSpotsPeriod3.toFixed(1))}
            </TableCell>
            <TableCell>
              {parseFloat(r.emptySpotsInAgendaPeriod4.toFixed(1))}
            </TableCell>
            <TableCell>
              {parseFloat(r.emptyIntakeSpotsPeriod4.toFixed(1))}
            </TableCell>
            <TableCell>
              {parseFloat(r.emptySpotsInAgendaPeriod5.toFixed(1))}
            </TableCell>
            <TableCell>
              {parseFloat(r.emptyIntakeSpotsPeriod5.toFixed(1))}
            </TableCell>

            <TableCell className="bg-slate-100 hover:bg-slate-200 border-l">
              {parseFloat(
                (
                  r.emptySpotsInAgendaPeriod1 +
                  r.emptySpotsInAgendaPeriod2 +
                  r.emptySpotsInAgendaPeriod3 +
                  r.emptySpotsInAgendaPeriod4 +
                  r.emptySpotsInAgendaPeriod5
                ).toFixed(1)
              )}
            </TableCell>
            <TableCell className="bg-slate-100 hover:bg-slate-200">
              {parseFloat(
                (
                  r.emptyIntakeSpotsPeriod1 +
                  r.emptyIntakeSpotsPeriod2 +
                  r.emptyIntakeSpotsPeriod3 +
                  r.emptyIntakeSpotsPeriod4 +
                  r.emptyIntakeSpotsPeriod5
                ).toFixed(1)
              )}
            </TableCell>
          </TableRow>
        );
      })}

      <TableRow className="bg-slate-100 hover:bg-slate-200">
        <TableCell className="min-w-[250px] sticky left-0 z-10 bg-slate-100 font-bold">
          Total
        </TableCell>
        <TableCell className="min-w-[250px] sticky left-[250px] z-10 bg-slate-100 border-r" />

        {columns.map((key) => {
          return (
            <TableCell>
              {parseFloat(
                rows
                  .map((r) => r[key] as number)
                  .reduce((acc, curr) => acc + curr, 0)
                  .toFixed(1)
              )}
            </TableCell>
          );
        })}
        {/* trailing cells at the end */}
        <TableCell />
        <TableCell />
      </TableRow>
    </>
  );
};
