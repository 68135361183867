export type SelectableEpdAppointmentType = {
  id: string;
  epdId: number;
  name: string;
};

export enum AppointmentConfigArea {
  PreIntake = "PreIntake",
  F2fIntake = "F2fIntake",
  RegularConsult = "RegularConsult",
  ChatConsult = "ChatConsult",
  VideoCall = "VideoCall",
}

export type CurrentAppointmentPerCaseLoadAreaConfig = {
  area: AppointmentConfigArea;
  appointmentType: SelectableEpdAppointmentType;
};

export type CurrentAppointmentPerDossierTypeConfig = {
  id: string;
  epdAppointmentType: {
    id: string;
    name: string;
    epdId: number;
  } | null;
  epdDossierType: {
    id: string;
    name: string;
    epdId: number;
  };
};

export type AutomaticBillHistory = {
  psychologist: string;
  patient: string;
  period: {
    year: number;
    week: number;
  };
  billCreatedOn: string;
  appointmentOn: string;
  createdBy: string;
};

export type CaseLoadDetailedDataOverview = {
  psychologist: string;
  numberOfChatAppointmentsCreated: number;
  totalPatients: number;
  checkins: number;
};

export type CaseLoadDetailedDataFull = {
  psychologist: string;
  patient: string;
  needsCheckin: boolean;
  hasCreatedChatAppointment: boolean;
  reasonForNoCheckin: string | null;
  hasChattedWith: boolean;
};
