import { useState } from "react";
import { FindDossier } from "../findDossier";
import { useDebounce } from "use-debounce";
import { Sq48ResultsOfDossier } from "./sq48ResultsOfDossier";

export default function SQ48ResultPdfsToChatAdmin() {
  const [epdPatientId, setEpdPatientId] = useState<number | null>(null);
  const [epdFileId, setEpdFileId] = useState<number>(1);
  const [debouncedEpdPatientId] = useDebounce(epdPatientId, 500);

  return (
    <main className="p-24">
      <div className="flex item-start justify-between w-full">
        <section>
          <h1 className="font-bold text-4xl">SQ48 Result PDFs to Chat</h1>

          <p className="pt-2">
            View and re-trigger SQ48 Result PDFs to be sent to clients via chat.
          </p>
        </section>
      </div>

      <div className="mt-6">
        <FindDossier
          epdPatientId={epdPatientId}
          setEpdPatientId={setEpdPatientId}
          epdFileId={epdFileId}
          setEpdFileId={setEpdFileId}
        />

        <Sq48ResultsOfDossier
          epdPatientId={debouncedEpdPatientId}
          epdFileId={epdFileId}
        />
      </div>
    </main>
  );
}
