import {
  UnsuccessfulyReachOutAttemptEmail,
  ReferralDetails,
} from "../../types";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectGroup,
  SelectValue,
} from "../../../../components/ui/select";
import { Info } from "lucide-react";

export const UnsuccessfulReachOutEmailSelector = ({
  referral,
  value,
  setValue,
}: {
  referral: ReferralDetails;
  value: UnsuccessfulyReachOutAttemptEmail;
  setValue: (newValue: UnsuccessfulyReachOutAttemptEmail) => void;
}) => {
  const readableNone = "None";
  const readableWillTryAgain = "Will try again to reach out";
  const readableWillNotTryAgain = "Will NOT try again to reach out";

  const getReadableValue = (value: UnsuccessfulyReachOutAttemptEmail) => {
    switch (value) {
      case UnsuccessfulyReachOutAttemptEmail.None:
        return readableNone;
      case UnsuccessfulyReachOutAttemptEmail.WillTryAgain:
        return readableWillTryAgain;
      case UnsuccessfulyReachOutAttemptEmail.WillNotTryAgain:
        return readableWillNotTryAgain;
      default:
        return "";
    }
  };

  const getValue = (readableValue: string) => {
    switch (readableValue) {
      case readableNone:
        return UnsuccessfulyReachOutAttemptEmail.None;
      case readableWillTryAgain:
        return UnsuccessfulyReachOutAttemptEmail.WillTryAgain;
      case readableWillNotTryAgain:
        return UnsuccessfulyReachOutAttemptEmail.WillNotTryAgain;
      default:
        return UnsuccessfulyReachOutAttemptEmail.None;
    }
  };

  return (
    <div className="space-y-2">
      <label className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 font-bold">
        Email to be sent to the patient
      </label>

      <Select
        value={getReadableValue(value)}
        onValueChange={(value) => {
          setValue(getValue(value));
        }}
        disabled={!referral.email}
      >
        <SelectTrigger
          className={`w-full ${
            referral.email ? "cursor-default" : "cursor-not-allowed"
          }`}
        >
          <SelectValue />
        </SelectTrigger>
        <SelectContent className="z-[99999999] max-h-[12rem] overflow-y-auto">
          <SelectGroup>
            {[readableNone, readableWillTryAgain, readableWillNotTryAgain].map(
              (x) => {
                return <SelectItem value={x}>{x}</SelectItem>;
              }
            )}
          </SelectGroup>
        </SelectContent>
      </Select>

      {!referral.email && (
        <div className="pt-2 flex items-center space-x-1 opacity-50">
          <div className="w-4 h-4">
            <Info className="w-4 h-4" />
          </div>
          <p>
            The client has no email address specified, therefore no emails can
            be sent.
          </p>
        </div>
      )}
    </div>
  );
};
