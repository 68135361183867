import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
  } from "../.././../../components/ui/hover-card";

export const RequiredField = () => (
    <HoverCard openDelay={200}>
  <HoverCardTrigger asChild>
    <span className="text-red-500"> *</span>
  </HoverCardTrigger>
  <HoverCardContent align="center" side="right" style={{ zIndex: 99999 }} className="w-50% text-red-500">
    {"Required Field"}
  </HoverCardContent>
</HoverCard>
);