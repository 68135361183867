import { Skeleton } from "../../components/ui/skeleton";

export const Loading = () => {
  return (
    <div className="py-12 flex space-x-4">
      <div className="w-[30%] space-y-1">
        <Skeleton className="w-full h-[30vh]" />
        <Skeleton className="w-full h-12" />
        <Skeleton className="w-full h-12" />
        <Skeleton className="w-full h-12" />
        <Skeleton className="w-full h-12" />
        <Skeleton className="w-full h-12" />
      </div>
      <div className="w-[70%]">
        <Skeleton className="w-full h-[90vh]" />
      </div>
    </div>
  );
};
