import { GetMonthlyInflowAggregationReportResponse } from "../../../../types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../../../components/ui/table";
import { Skeleton } from "../../../../../../components/ui/skeleton";

export const ReferralsPerInsurersPerMonthTable = ({
  data,
  isLoading,
}: {
  data: GetMonthlyInflowAggregationReportResponse[];
  isLoading: boolean;
}) => {
  const duplicatedAllInsurers = data.flatMap((d) =>
    d.data.map((e) => e.healthcareInsurerConcern)
  );
  const allInsurersSet = new Set(duplicatedAllInsurers);
  const allInsurers = [...allInsurersSet];

  const getInsurerCountsForEachMonth = (insurer: string) => {
    return data.map((d) => {
      const found = d.data.find((e) => e.healthcareInsurerConcern === insurer);

      if (found) {
        return (
          <TableCell key={d.period} className="py-1 min-w-[200px]">
            {found.count}
          </TableCell>
        );
      }

      return (
        <TableCell key={d.period} className="py-1 min-w-[200px]">
          0
        </TableCell>
      );
    });
  };

  if (isLoading)
    return (
      <div className="flex flex-col space-y-1">
        <Skeleton className="h-12 w-full rounded" />
        <Skeleton className="h-8 w-full rounded" />
        <Skeleton className="h-8 w-full rounded" />
        <Skeleton className="h-8 w-full rounded" />
        <Skeleton className="h-8 w-full rounded" />
        <Skeleton className="h-8 w-full rounded" />
        <Skeleton className="h-8 w-full rounded" />
      </div>
    );

  return (
    <div className="overflow-x-auto">
      <Table className="border min-w-[1200px] table-auto">
        <TableHeader>
          <TableRow>
            <TableHead className="min-w-[250px]"></TableHead>
            {data.map((d) => {
              return (
                <TableHead className="min-w-[120px]">{d.period}</TableHead>
              );
            })}
            <TableHead className="min-w-[120px] bg-slate-100 hover:bg-slate-100">
              Total
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {allInsurers
            .toSorted((a, b) => a.localeCompare(b))
            .map((d) => {
              return (
                <TableRow key={d}>
                  <TableCell className="py-1 min-w-[250px]">{d}</TableCell>
                  {getInsurerCountsForEachMonth(d)}
                  <TableCell className="py-1 min-w-[120px] bg-slate-100 hover:bg-slate-100 font-bold">
                    {data
                      .flatMap((month) =>
                        month.data
                          .filter((dd) => dd.healthcareInsurerConcern == d)
                          .map((dd) => dd.count)
                      )
                      .reduce((acc, curr) => acc + curr, 0)}
                  </TableCell>
                </TableRow>
              );
            })}
          <TableRow>
            <TableCell className="bg-slate-100 hover:bg-slate-100 font-bold">
              Total
            </TableCell>
            {data.map((d) => {
              return (
                <TableCell
                  key={d.period}
                  className="bg-slate-100 hover:bg-slate-100 font-bold"
                >
                  {d.data.reduce((acc, curr) => acc + curr.count, 0)}
                </TableCell>
              );
            })}
            <TableCell className="bg-slate-100 hover:bg-slate-100 font-bold">
              {data
                .map((d) => d.data.reduce((acc, curr) => acc + curr.count, 0))
                .reduce((acc, curr) => acc + curr, 0)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};
