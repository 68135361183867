import { ReferralDetails } from "../../types";
import { RegisterPatientRow } from "./registerPatientRow";
import { ScheduleIntakeRow } from "./scheduleIntakeRow";
import { SchedulePreIntakeRow } from "./schedulePreIntakeRow";
import { ApprovePatientRow } from "./approvePatientRow";
import { IWelcomeRow } from "./iWelcomeRow";
import { NameAndLanguage } from "../fields/nameAndLanguage";
import { PraktijkdataActionsRow } from "./praktijkdataActionsRow";

export const PraktjikdataSection = ({
  referral,
}: {
  referral: ReferralDetails;
}) => {
  if (
    referral.status !== "RegisterPatientAndIntake" &&
    referral.status !== "AwaitingPatientApproval" &&
    referral.status !== "AwaitingIWelcomeEmailSend" &&
    referral.status !== "Closed"
  ) {
    return <></>;
  }

  return (
    <div className="mb-14">
      <section className="flex flex-col space-y-5">
        <NameAndLanguage referral={referral} stepNumber={1} />
        <ScheduleIntakeRow referral={referral} stepNumber={2} />
        <SchedulePreIntakeRow referral={referral} stepNumber={3} />
        <RegisterPatientRow referral={referral} stepNumber={4} />
        <ApprovePatientRow referral={referral} stepNumber={5} />
        <IWelcomeRow referral={referral} stepNumber={6} />
        <PraktijkdataActionsRow referral={referral} stepNumber={7} />
      </section>
    </div>
  );
};
