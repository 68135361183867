import { Row } from "../../types";
import { TableCell } from "../../../../../components/ui/table";
import { AddDeviationButton } from "../../addDeviationButton";
import { getSimpleFormattedDate } from "../../../../../utils/dateUtils";
import { Trash2 } from "lucide-react";
import { useDeleteIntakeDeviation } from "../../hooks";

type FutureDeviationsCellProps = {
  data: Row;
};
export const FutureDeviationsCell = ({ data }: FutureDeviationsCellProps) => {
  const { mutate: deleteDeviation, isLoading: isDeletingDeviation } =
    useDeleteIntakeDeviation();

  if (data.status === "Active - not accepting new clients") {
    return <TableCell />;
  }

  return (
    <TableCell className="flex items-center justify-between">
      {data.futureWeeks.length == 0 ? (
        <>
          <div>
            <p>No deviation</p>
            <p className="opacity-80 text-xs">Standard 3 intake/day</p>
            <div className="pt-1">
              <AddDeviationButton
                row={data}
                hasIndefiniteIntakeAlready={false}
              />
            </div>
          </div>
        </>
      ) : (
        <ul>
          {data.futureWeeks.map((f) => {
            return (
              <li className="text-sm flex items-center space-x-2">
                <p>
                  <span className="font-medium">
                    {getSimpleFormattedDate(f.start)} -{" "}
                    {f.isIndefinite
                      ? "Indefinite"
                      : getSimpleFormattedDate(f.end!)}
                    :
                  </span>{" "}
                  {f.numberOfIntakes} intake(s)/day
                </p>
                <div className="h-4 w-4">
                  <Trash2
                    className="h-4 w-4 cursor-pointer text-red-400 dark:text-slate-400 hover:text-red-600 hover:dark:text-red-500"
                    onClick={async () => {
                      await deleteDeviation({
                        epdEmployeeId: data.epdEmployeeId,
                        startDate: f.start,
                        endDate: f.end,
                        isIndefinite: f.isIndefinite,
                      });
                    }}
                  />
                </div>
              </li>
            );
          })}
          <div className="pt-1">
            <AddDeviationButton
              hasIndefiniteIntakeAlready={data.futureWeeks.some(
                (f) => f.isIndefinite
              )}
              row={data}
            />
          </div>
        </ul>
      )}
    </TableCell>
  );
};
