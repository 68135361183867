import { action, makeObservable, observable, runInAction } from "mobx";
import axios from "axios";

class PatientsStore {
  patients = [];

  searchValue = "";

  page = 0;

  count = 0;

  constructor() {
    makeObservable(this, {
      patients: observable,
      searchValue: observable,
      count: observable,
      fetchPatients: action,
      setSearchValue: action,
      setPage: action,
    });
  }

  async fetchPatients() {
    this.patients = [];

    const res = await axios.get("patients", {
      params: { page: this.page, limit: 10, search: this.searchValue },
    });

    runInAction(() => {
      this.patients = res.data.data;
      this.count = res.data.pagination.total;
    });
  }

  async createPatient(values) {
    const res = await axios.post("patients", {
      firstName: values.firstName,
      lastName: values.lastName,
      bsn: values.bsn,
      email: values.email,
      languageCode: values.languageCode,
      mobilePhoneNumber: values.mobilePhoneNumber,
    });

    runInAction(() => {
      this.patients.unshift(res.data);
    });

    return res.data;
  }

  setSearchValue(searchValue) {
    this.searchValue = searchValue;
    this.page = 0;
    this.fetchPatients();
  }

  setPage(page) {
    this.page = page;
    this.fetchPatients();
  }
}

export default PatientsStore;
