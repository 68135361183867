import { CrmWorkingViewReportResult } from "../../../types";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartLegend,
} from "../../../../../components/ui/chart";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  XAxis,
  YAxis,
} from "recharts";
import { CustomTooltip } from "./chartTooltip";
import {
  colors,
  crmWorkingViewReportPerCityCounts,
  getReadableLabelFromDataKey,
  getParsedCityName,
} from "./utils";
import { CustomLegend } from "./chartLegend";

export const WorkingViewReportChart = ({
  data,
}: {
  data: CrmWorkingViewReportResult;
}) => {
  const generateChartConfig = (): Record<string, { label: string }> => {
    return data!.cities.reduce((config, city) => {
      const parsedCityName = getParsedCityName(city.name);

      config[parsedCityName] = {
        label: parsedCityName,
      };
      return config;
    }, {} as Record<string, { label: string }>);
  };

  const renderBarCharts = () => {
    return crmWorkingViewReportPerCityCounts.toReversed().map((c) => (
      <Bar
        dataKey={c}
        name={getReadableLabelFromDataKey(c)}
        stackId="a"
        fill={colors[c]}
        key={c}
      >
        <LabelList
          dataKey={c}
          position="inside"
          offset={8}
          className="fill-slate-800"
          fontSize={12}
          formatter={(x: number) => (x > 0 ? x : null)}
        />
      </Bar>
    ));
  };

  return (
    <ChartContainer config={generateChartConfig() satisfies ChartConfig}>
      <BarChart accessibilityLayer data={data.cities}>
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="name"
          tickFormatter={(value: string) => getParsedCityName(value)}
          tickLine={false}
          tickMargin={10}
          axisLine={false}
        />
        <YAxis
          tick={{ stroke: "#C7C7C7", strokeOpacity: 0.1, fontSize: 12 }}
          tickLine={true}
          type="number"
        />
        <ChartTooltip
          content={(x) => (
            <CustomTooltip
              label={x.label}
              active={x.active ?? false}
              payload={x.payload as any}
            />
          )}
        />
        <ChartLegend
          content={(x) => <CustomLegend payload={x.payload as any} />}
        />
        {renderBarCharts()}
      </BarChart>
    </ChartContainer>
  );
};
