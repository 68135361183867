import { ColumnDef } from "@tanstack/react-table";
import { MainListPatient } from "../../types";
import { SortableCell } from "../../../../../components/ui/table";
import { useTranslation } from "react-i18next";
import { SupportCheckboxes } from "../../supportPatient/supportCheckboxes";

export const HasChattedWithColName = "Status";

export const HasChattedWithCol: ColumnDef<MainListPatient> = {
  id: HasChattedWithColName,
  accessorKey: "hasChattedWith",
  size: 220,
  minSize: 10,
  header: ({ column }) => {
    const { t } = useTranslation();

    return (
      <SortableCell
        column={column}
        label={t("case-load-manager-column-status")}
      />
    );
  },
  cell: ({ row: { index, original }, table }) => {
    return (
      <div className="py-2 px-4">
        <SupportCheckboxes
          data={original}
          onBlur={() => {
            // @ts-expect-error sssh
            table.options.meta?.updateData(index, id, value);
          }}
        />
      </div>
    );
  },
};
