import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/system";

const TableRowPointer = styled(TableRow)(() => ({
  "&:hover": {
    cursor: "pointer",
  },
}));

export default TableRowPointer;
