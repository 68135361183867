import React, { useEffect } from "react";
import {
  CardHeader,
  Divider,
  CardContent,
  Grid,
  Button,
  InputAdornment,
  CardActions,
  TextField,
  FormControlLabel,
  Card,
  MenuItem,
  Switch,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { observer } from "mobx-react";
import { trackPromise } from "react-promise-tracker";
import areas from "../../utils/constants/areas";
import Loader from "../../shared/Loader";
import useStores from "../../useStores";
import Loading from "../../shared/Loading";
import dossierType from "../../utils/constants/dossierType";
import dossierSubType from "../../utils/constants/dossierSubType";
import healthCareInsurerType from "../../utils/constants/healthCareInsurerType";

const CreateDossierGgz = ({ patientId }) => {
  const { dossiersStore, patientStore } = useStores();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    patientStore.fetchPatient(patientId);
    return () => patientStore.reset();
  }, [patientStore, patientId]);

  if (!patientStore.patient) {
    return <Loading />;
  }

  const initialValues = {
    epdPatientId: "",
    epdFileId: 1,
    paymentPercentage: "100",
    isInsuredHealthCare: true,
    isContractedHealthCare: true,
    isPayedByThirdParty: false,
    paymentExceptions: false,
    bsn: patientStore.patient.bsn,
    typeOfCare: "InsuredHealthCare",
    healthCareInsurer: "VGZ",
  };

  const validationSchema = Yup.object().shape({
    epdPatientId: Yup.number().integer().positive().required(),
    epdFileId: Yup.number().integer().positive().required(),
    paymentPercentage: Yup.number().min(50).max(100).required(),
    isInsuredHealthCare: Yup.boolean().required(),
    isContractedHealthCare: Yup.boolean().required(),
    bsn: Yup.string()
      .matches(
        /^[0-9]{8,9}$/,
        "Het burger service nummer moet 8 of 9 nummers hebben."
      )
      .when("isInsuredHealthCare", {
        is: true,
        then: (schema) => schema.required("BSN is verplicht"),
        otherwise: (schema) => schema.nullable(),
      }),
  });

  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    values.patientId = patientId;
    trackPromise(
      dossiersStore.createDossierGgz(values).then(
        (createdDossier) => {
          enqueueSnackbar("Dossier aangemaakt", { variant: "success" });
          resetForm();
          navigate(
            `/patient/${patientId}/${dossierType.GGZ}/${createdDossier.id}`
          );
        },
        (error) => {
          enqueueSnackbar(error.response.data, { variant: "error" });
          setSubmitting(false);
        }
      ),
      areas.CREATE_DOSSIER_CARD
    );
  };

  return (
    <Loader area={areas.CREATE_DOSSIER_CARD}>
      <Card>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => {
            const isInsuredHealthCarePaymentMethodVisible =
              values.isInsuredHealthCare &&
              values.dossierSubType !== dossierSubType.ADHD;
            const isNonInsuredHealthCarePaymentMethodVisible =
              !values.isInsuredHealthCare;
            const isPaymentExceptionsSwitchVisible =
              values.isInsuredHealthCare && !values.isContractedHealthCare;
            const isPaymentPercentageVisible = values.paymentExceptions;
            const isBSNTextFieldVisible = values.isInsuredHealthCare;
            const isHealthcareProviderDropdownVisible =
              values.dossierSubType === dossierSubType.ADHD;

            return (
              <form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <CardHeader title="iPractice dossier aanmaken" />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        required
                        label="Cliënt nr."
                        margin="dense"
                        name="epdPatientId"
                        onChange={handleChange}
                        value={values.epdPatientId}
                        variant="outlined"
                        type="number"
                        onBlur={handleBlur}
                        error={errors.epdPatientId && touched.epdPatientId}
                        helperText={
                          errors.epdPatientId && touched.epdPatientId
                            ? errors.epdPatientId
                            : "Client nummer uit praktijkdata"
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Dossier nr."
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {values.epdPatientId}.
                            </InputAdornment>
                          ),
                        }}
                        margin="dense"
                        name="epdFileId"
                        onChange={handleChange}
                        required
                        value={values.epdFileId}
                        variant="outlined"
                        type="number"
                        onBlur={handleBlur}
                        error={errors.epdFileId && touched.epdFileId}
                        helperText={
                          errors.epdFileId && touched.epdFileId
                            ? errors.epdFileId
                            : "Dossier nummer uit praktijkdata"
                        }
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Type zorg"
                        select
                        value={values.typeOfCare}
                        onChange={(e) => {
                          const selectedTypeOfCare = e.target.value;
                          switch (selectedTypeOfCare) {
                            case "InsuredHealthCare":
                              values.isInsuredHealthCare = true;
                              values.isContractedHealthCare = true;
                              values.isPayedByThirdParty = false;
                              values.dossierSubType = dossierSubType.DEFAULT;
                              values.selectedInsurerType = "";
                              break;
                            case "UninsuredHealthCare":
                              values.isInsuredHealthCare = false;
                              values.isContractedHealthCare = false;
                              values.paymentExceptions = false;
                              values.paymentPercentage = "100";
                              values.dossierSubType = dossierSubType.DEFAULT;
                              values.selectedInsurerType = "";
                              break;
                            case "InsuredAdhd":
                              values.isInsuredHealthCare = true;
                              values.isContractedHealthCare = true;
                              values.isPayedByThirdParty = false;
                              values.dossierSubType = dossierSubType.ADHD;
                              values.selectedInsurerType =
                                healthCareInsurerType.VGZ;
                              break;
                            default:
                              break;
                          }
                          handleChange(e);
                        }}
                        margin="dense"
                        name="typeOfCare"
                        variant="outlined"
                        onBlur={handleBlur}
                      >
                        <MenuItem value="InsuredHealthCare">
                          Verzekerde zorg
                        </MenuItem>
                        <MenuItem value="UninsuredHealthCare">
                          Onverzekerde zorg (B2C)
                        </MenuItem>
                        <MenuItem value="InsuredAdhd">Verzekerde ADHD</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={6} />

                    {/* Insured Health Care Payment Method Dropdown */}
                    {isInsuredHealthCarePaymentMethodVisible && (
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Betaalwijze"
                          select
                          value={values.isContractedHealthCare}
                          onChange={handleChange}
                          margin="dense"
                          name="isContractedHealthCare"
                          variant="outlined"
                          onBlur={handleBlur}
                        >
                          <MenuItem value>Gecontracteerd</MenuItem>
                          <MenuItem value={false}>Ongecontracteerd</MenuItem>
                        </TextField>
                      </Grid>
                    )}

                    {/* Healthcare Provider Dropdown */}
                    {isHealthcareProviderDropdownVisible && (
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Zorgverzekeraar"
                          select
                          value={values.healthCareInsurer}
                          onChange={(e) => {
                            const selectedInsurerType = e.target.value;
                            switch (selectedInsurerType) {
                              case "VGZ":
                                values.selectedInsurerType =
                                  healthCareInsurerType.VGZ;
                                break;
                              case "CZ":
                                values.selectedInsurerType =
                                  healthCareInsurerType.CZ;
                                break;
                              case "ONVZ":
                                values.selectedInsurerType =
                                  healthCareInsurerType.ONVZ;
                                break;
                              case "Menzis":
                                values.selectedInsurerType =
                                  healthCareInsurerType.Menzis;
                                break;
                              default:
                                return;
                            }
                            handleChange(e);
                          }}
                          margin="dense"
                          name="healthCareInsurer"
                          variant="outlined"
                          onBlur={handleBlur}
                        >
                          <MenuItem value="VGZ">VGZ</MenuItem>
                          <MenuItem value="CZ">CZ</MenuItem>
                          <MenuItem value="ONVZ">ONVZ</MenuItem>
                          <MenuItem value="Menzis">Menzis</MenuItem>
                        </TextField>
                      </Grid>
                    )}

                    {/* Non-Insured Health Care Payment Method Dropdown */}
                    {isNonInsuredHealthCarePaymentMethodVisible && (
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Betaalwijze"
                          select
                          value={values.isPayedByThirdParty}
                          onChange={handleChange}
                          margin="dense"
                          name="isPayedByThirdParty"
                          variant="outlined"
                          onBlur={handleBlur}
                        >
                          <MenuItem value={false}>Betaalverzoeken</MenuItem>
                          <MenuItem value>Derde partij</MenuItem>
                        </TextField>
                      </Grid>
                    )}

                    {/* Payment Exceptions Switch */}
                    {isPaymentExceptionsSwitchVisible && (
                      <Grid item xs={6}>
                        <FormControlLabel
                          label="Betalingsuitzonderingen"
                          control={
                            <Switch
                              size="medium"
                              checked={values.paymentExceptions}
                              onChange={(e) => {
                                if (e.target.checked === false) {
                                  values.paymentPercentage = "100";
                                }
                                handleChange(e);
                              }}
                              name="paymentExceptions"
                            />
                          }
                        />
                      </Grid>
                    )}

                    {/* Payment Percentage Radio Buttons */}
                    {isPaymentPercentageVisible && (
                      <Grid item xs={6}>
                        {values.paymentExceptions && (
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              Te betalen percentage van iPractice tarief:
                            </FormLabel>
                            <RadioGroup
                              aria-label="paymentPercentage"
                              name="paymentPercentage"
                              onChange={handleChange}
                            >
                              <FormControlLabel
                                value="100"
                                control={<Radio />}
                                label="100%"
                              />
                              <FormControlLabel
                                value="85"
                                control={<Radio />}
                                label="85%"
                              />
                              <FormControlLabel
                                value="70"
                                control={<Radio />}
                                label="70%"
                              />
                            </RadioGroup>
                          </FormControl>
                        )}
                      </Grid>
                    )}

                    {/* BSN TextField */}
                    {isBSNTextFieldVisible && (
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          required={values.isInsuredHealthCare}
                          label="BSN"
                          margin="dense"
                          name="bsn"
                          onChange={handleChange}
                          value={values.bsn}
                          variant="outlined"
                          onBlur={handleBlur}
                          error={errors.bsn && touched.bsn}
                          helperText={errors.bsn && touched.bsn && errors.bsn}
                          InputLabelProps={{ shrink: values.bsn !== "" }}
                        />
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                  >
                    Aanmaken
                  </Button>
                </CardActions>
              </form>
            );
          }}
        </Formik>
      </Card>
    </Loader>
  );
};

export default observer(CreateDossierGgz);
