import { useState } from "react";
import { ReferralDetails } from "../../types";
import { Button } from "../../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { Archive, ArchiveRestore } from "lucide-react"; // Assuming Unarchive is the icon for unarchiving
import { useArchiveReferral } from "../../hooks"; // Assuming useUnarchiveReferral is the hook for unarchiving

export const ToggleArchiveReferralButton = ({
  referral,
}: {
  referral: ReferralDetails;
}) => {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const { mutate: toggleArchiveReferral, isLoading: isArchiving } =
    useArchiveReferral(() => {
      setIsConfirmationDialogOpen(false);
    });

  const isArchived = referral.status === "Archived";

  const handleArchiveToggle = async () => {
    toggleArchiveReferral({ referralId: referral.id });
  };

  if (
    referral.status === "Rejected" ||
    referral.status === "Closed"
  ) {
    return <></>;
  }

  return (
    <>
      <Button
        variant="outline"
        onClick={(e) => {
          e.preventDefault();
          setIsConfirmationDialogOpen(true);
        }}
        className="mt-4 flex items-center space-x-2"
      >
        {isArchived ? (
          <ArchiveRestore className="h-5 w-5" />
        ) : (
          <Archive className="h-5 w-5" />
        )}

        <p>{isArchived ? "Undo archival" : "Archive referral"}</p>
      </Button>
      <Dialog
        open={isConfirmationDialogOpen}
        onOpenChange={setIsConfirmationDialogOpen}
      >
        <DialogContent className="min-w-[40vw] p-8">
          <DialogHeader>
            <DialogTitle className="text-2xl">
              {isArchived ? "Unarchive Referral" : "Archive Referral"}
            </DialogTitle>
            <p>
              {isArchived
                ? "Are you sure you want to unarchive this referral?"
                : "Are you sure you want to archive this referral?"}
            </p>
            <DialogFooter className="space-x-2 pt-6">
              <Button
                variant="outline"
                className="w-1/2"
                onClick={() => setIsConfirmationDialogOpen(false)}
              >
                Cancel
              </Button>
              <Button
                className="w-1/2"
                variant="destructive"
                onClick={handleArchiveToggle}
              >
                {isArchiving
                  ? "Processing..."
                  : isArchived
                  ? "Unarchive Referral"
                  : "Archive Referral"}
              </Button>
            </DialogFooter>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </>
  );
};
