import { SendHorizontal } from "lucide-react";
import { Button } from "../../../../components/ui/button";
import { useState } from "react";
import { useSendQuestionnaireToPraktijkData } from "./hooks";
import { ConfirmSendToPraktijkDataDialog } from "./dialogs/confirmSend";
import { ErrorDialog } from "./dialogs/errorDialog";
import { toast } from "sonner";

export const SendToPraktijkDataButton = ({
  questionnaireId,
}: {
  questionnaireId: string;
}) => {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

  const { mutate, isLoading } = useSendQuestionnaireToPraktijkData(
    questionnaireId,
    () => {
      setIsConfirmationDialogOpen(false);
      toast("Questionnaire sent", {
        description: "The questionnaire has been sent to PraktijkData",
      });
    },
    () => {
      setIsConfirmationDialogOpen(false);
      setIsErrorDialogOpen(true);
    }
  );

  return (
    <>
      <Button
        className="flex items-center space-x-2"
        variant="outline"
        onClick={() => setIsConfirmationDialogOpen(true)}
      >
        <div className="w-4 h-4">
          <SendHorizontal className="w-4 h-4" />
        </div>
        <p>Send to PraktijkData</p>
      </Button>
      <ConfirmSendToPraktijkDataDialog
        isOpen={isConfirmationDialogOpen}
        isLoading={isLoading}
        onRetrigger={mutate}
        onCancel={() => setIsConfirmationDialogOpen(false)}
      />
      <ErrorDialog
        isOpen={isErrorDialogOpen}
        onClose={() => setIsErrorDialogOpen(false)}
      />
    </>
  );
};
