import {
  RegistrationStatusType,
  RejectionReasonIPractice,
  RejectionReasonClient,
} from "../../types";
import { getReadableReason as getReadableClientRejectionReason } from "./reasonsIClient";
import { getReadableReason as getReadableIPracticeRejectionReason } from "./reasonsIPractice";

export const getRejectionReason = (
  status: RegistrationStatusType,
  rejectedByClient: boolean | null,
  rejectionReasonClient: RejectionReasonClient | null,
  rejectionReasonIPractice: RejectionReasonIPractice | null,
  justificationForOtherRejectionReason: string | null
) => {
  if (status != "Rejected") return null;

  if (rejectedByClient)
    return getReadableClientRejectionReason(rejectionReasonClient!);

  if (rejectionReasonIPractice == "Other")
    return justificationForOtherRejectionReason;

  return getReadableIPracticeRejectionReason(rejectionReasonIPractice!);
};
