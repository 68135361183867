import React, { Fragment, useState } from "react";
import { styled } from "@mui/system";
import { observer } from "mobx-react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import moment from "moment";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import Button from "@mui/material/Button";
import * as colors from "@mui/material/colors";
import { Typography } from "@mui/material";
import useStores from "../../../useStores";
import DeactivationModal from "./DeactivationModal";
import Label from "../../../shared/Label";

const StyledCardContent = styled(CardContent)(() => ({
  padding: 0,
}));

const processBotsStatusColors = {
  active: colors.green[600],
  deactivated: colors.red[600],
};

const ProcessBots = () => {
  const { patientStore } = useStores();
  const { processBots, patient } = patientStore;
  const [botToDeactivate, setBotToDeactivate] = useState(null);

  const handleDeactivationModalClose = () => {
    setBotToDeactivate(null);
  };

  const handleDeactivation = (reason) => {
    patientStore.deactivateProcessBot(botToDeactivate.processBotType, reason);
    setBotToDeactivate(null);
  };

  const renderStatus = (bot) => {
    if (bot.deactivatedDateTime) {
      return (
        <>
          <div>
            <Label color={processBotsStatusColors.deactivated}>
              Gedeactiveerd
            </Label>
          </div>
          <div>{moment(bot.deactivatedDateTime).format("LLL")}</div>
          <div>{bot.deactivatedReason}</div>
        </>
      );
    }

    return <Label color={processBotsStatusColors.active}>Actief</Label>;
  };

  if (!patient.wireUser) {
    return (
      <Card>
        <CardHeader title="Process bots" />
        <Divider />
        <CardContent>
          <Typography variant="subtitle1">
            Selecteer eerst een wire handle voor deze cliënt.
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader title="Process bots" />
      <Divider />
      <StyledCardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Bot</TableCell>
              <TableCell>Planning</TableCell>
              <TableCell>Einddatum</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="right">Acties</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {processBots.map((processBot, index) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <TableRow key={index}>
                  <TableCell>{processBot.processBotType}</TableCell>
                  <TableCell>
                    {processBot.processBotType === "Reminder"
                      ? "Om 9:00 de dag voor consult"
                      : "1 uur na afronden consult"}
                  </TableCell>
                  <TableCell>Geen einddatum</TableCell>
                  <TableCell>{renderStatus(processBot)}</TableCell>
                  <TableCell align="right">
                    {processBot.deactivatedDateTime ? (
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() =>
                          patientStore.activateProcessBot(processBot)
                        }
                      >
                        Activeer
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => setBotToDeactivate(processBot)}
                      >
                        Deactiveer
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </StyledCardContent>
      <DeactivationModal
        open={!!botToDeactivate}
        onDeactivation={handleDeactivation}
        onClose={handleDeactivationModalClose}
      />
    </Card>
  );
};

export default observer(ProcessBots);
