import {
  HorizontalTabHeader,
  HorizontalTabContent,
  TabContext,
} from "../../../components/ui/horizontalTab";
import { CaseloadStatistics } from "../statistics/caseloadStatistics";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { OnboardPatients } from "../onboardingClients/onboardPatients";
import { useGetOnboardingPatients } from "../onboardingClients/hooks";
import { DefaultGenerics, StreamChat } from "stream-chat";
import { useGetChatCredentials } from "../../streamChat/hooks";
import { useIsEnabled } from "../../../feature-management/useIsEnabled";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { CaseLoadAlert } from "./types";
import { useNavigate } from "react-router-dom";
import { getCurrentMondayAndFriday } from "../../../utils/dateUtils";
import { CaseLoadOverview } from "./caseLoadOverview";

type TabsProps = {
  setAlerts: Dispatch<
    SetStateAction<
      {
        caseLoadEntryId: string;
        alerts: CaseLoadAlert[];
      }[]
    >
  >;
  setAbsence: Dispatch<
    SetStateAction<{
      startDate: string;
      endDate: string;
    } | null>
  >;
  date: Date;
  setDate: Dispatch<SetStateAction<Date>>;
  onlyCaseLoadsWithAlert: boolean;
  setOnlyCaseLoadsWithAlert: Dispatch<SetStateAction<boolean>>;
};

export const Tabs = ({
  setAlerts,
  setAbsence,
  date,
  setDate,
  onlyCaseLoadsWithAlert,
  setOnlyCaseLoadsWithAlert,
}: TabsProps) => {
  const { t } = useTranslation();
  const { setActiveTab } = useContext(TabContext)!;
  const searchParams = new URLSearchParams(location.search);

  const { data: isCaseLoadManagerWireReplacementEnabled } = useIsEnabled(
    "EnableCaseLoadManagerWireReplacement"
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (location.search.includes("viewAs=onboarding")) setActiveTab(1);
    else if (location.search.includes("viewAs=statistics")) setActiveTab(2);
    else setActiveTab(0);
  }, [location.search]);

  const { data: onboardingPatients } = useGetOnboardingPatients();
  const { monday } = getCurrentMondayAndFriday();

  const countOfOnboardingPatients =
    onboardingPatients?.data?.filter(
      (x) => !x.treatmentStartStandardMessageSent
    )?.length ?? 0;

  const onChangeDate = (newDate: Date) => {
    const parsedDate = moment(newDate).utc(true).toDate();

    if (parsedDate.getDate() == monday.getDate()) {
      searchParams.delete("period"); // cleaner url, if we delete, rather than put this week's monday there
    } else {
      searchParams.set("period", parsedDate.toISOString());
    }

    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });

    setDate(parsedDate);
  };

  const apiKey =
    process.env.REACT_APP_STREAM_CHAT_API_KEY ?? "should-not-happen";

  const { data: chatCredentials } = useGetChatCredentials(
    isCaseLoadManagerWireReplacementEnabled ?? false
  );

  const [chatClient, setChatClient] =
    useState<StreamChat<DefaultGenerics> | null>(null);

  const [couldNotLoadChat, setCouldNotLoadChat] = useState(false);

  useEffect(() => {
    const initChat = async () => {
      try {
        const client = StreamChat.getInstance(apiKey);

        await client.connectUser(
          { id: chatCredentials!.userId },
          chatCredentials!.token
        );
        setChatClient(client);
      } catch (error) {
        setCouldNotLoadChat(true);
      }
    };

    if (
      isCaseLoadManagerWireReplacementEnabled &&
      !chatClient &&
      chatCredentials?.token != null
    ) {
      initChat();
    }
  }, [chatCredentials, isCaseLoadManagerWireReplacementEnabled]);

  return (
    <div className="w-full mb-12">
      <div className="flex items-center ml-3">
        <HorizontalTabHeader
          index={0}
          onOverrideChangeTab={() => {
            navigate({
              pathname: location.pathname,
              search: "",
            });
          }}
        >
          {t("case-load-manager-my-case-load-tab-title")}
        </HorizontalTabHeader>
        <HorizontalTabHeader
          index={1}
          onOverrideChangeTab={() => {
            // reset date searchParam
            onChangeDate(new Date());

            navigate({
              pathname: location.pathname,
              search:
                "?" + new URLSearchParams({ viewAs: "onboarding" }).toString(),
            });
          }}
        >
          <>
            <p>{t("case-load-manager-onboarding-tab-title")}</p>

            <div
              className={`h-4 bg-ipractice-blue absolute top-0 right-[20px] text-xs text-white rounded-full flex items-center justify-center ${
                countOfOnboardingPatients && countOfOnboardingPatients > 0
                  ? "visible"
                  : "hidden"
              } ${countOfOnboardingPatients > 10 ? "w-5" : "w-4"}`}
            >
              {countOfOnboardingPatients}
            </div>
          </>
        </HorizontalTabHeader>

        <HorizontalTabHeader
          index={2}
          onOverrideChangeTab={() => {
            // reset date searchParam
            onChangeDate(new Date());
            navigate({
              pathname: location.pathname,
              search:
                "?" + new URLSearchParams({ viewAs: "statistics" }).toString(),
            });
          }}
        >
          {t("case-load-manager-statistics-tab-title")}
        </HorizontalTabHeader>
      </div>
      <HorizontalTabContent index={0}>
        <CaseLoadOverview
          date={date}
          onChangeDate={onChangeDate}
          setAbsence={setAbsence}
          setAlerts={setAlerts}
          onlyCaseLoadsWithAlert={onlyCaseLoadsWithAlert}
          setOnlyCaseLoadsWithAlert={setOnlyCaseLoadsWithAlert}
          chatClient={chatClient}
          couldNotLoadChat={couldNotLoadChat}
        />
      </HorizontalTabContent>
      <HorizontalTabContent index={1}>
        <OnboardPatients chatClient={chatClient} />
      </HorizontalTabContent>
      <HorizontalTabContent index={2}>
        <div className="p-4">
          <CaseloadStatistics />
        </div>
      </HorizontalTabContent>
    </div>
  );
};
