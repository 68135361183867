import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { SelectableEpdAppointmentType } from "../types";
import Input from "@mui/material/Input";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const AppointmentTypeSelector = ({
  selectedValue,
  selectableItems,
  onChange,
}: {
  selectedValue: SelectableEpdAppointmentType[];
  selectableItems: SelectableEpdAppointmentType[];
  onChange: (newValue: SelectableEpdAppointmentType[]) => void;
}) => {
  return (
    <Select
      multiple
      value={selectedValue.map((s) => s.epdId)}
      onChange={(e) => {
        const newValues = (e.target.value as number[]).map(
          (x) => selectableItems.find((si) => si.epdId == x)!
        );
        onChange(newValues);
      }}
      renderValue={(selected) => (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 0.5,
          }}
        >
          {selected
            .toSorted((a, b) => {
              if (a < b) return -1;
              if (a > b) return 1;
              return 0;
            })
            .map((x) => {
              const entry = selectableItems.find((si) => si.epdId == x)!;
              return (
                <Chip
                  className="bg-gray-100 p-0 m-0"
                  sx={{
                    fontSize: "10pt",
                    "& .MuiChip-label": {
                      padding: "0 12px",
                    },
                  }}
                  key={x}
                  component={() => (
                    <p className="text-muted-foreground bg-blue-50 border px-2 py-1 rounded-xl">
                      <span className="opacity-40 pr-2 text-xs">
                        ID: {entry.epdId}
                      </span>
                      {entry.name}
                    </p>
                  )}
                />
              );
            })}
        </Box>
      )}
      input={
        <Input
          sx={{
            padding: "4px",
            lineHeight: "unset",
            "&.MuiInput-underline:after": {
              borderBottom: "none",
            },
            "&.MuiInput-underline:before": {
              borderBottom: "none",
            },
            "&:hover.MuiInput-underline:after": {
              borderBottom: "none",
            },
            "&:hover.MuiInput-underline:before": {
              borderBottom: "none",
            },
          }}
        />
      }
      inputProps={{ "aria-label": "Without label" }}
      className="w-full mt-1 text-left bg-white rounded-lg border"
      MenuProps={MenuProps}
    >
      {selectableItems.map((appointmentType) => (
        <MenuItem
          key={appointmentType.epdId}
          value={appointmentType.epdId}
          sx={{ padding: 0, margin: 0 }}
        >
          <Checkbox
            checked={selectedValue.some(
              (s) => s.epdId == appointmentType.epdId
            )}
          />
          <ListItemText
            primary={`${appointmentType.epdId}: ${appointmentType.name}`}
          />
        </MenuItem>
      ))}
    </Select>
  );
};
