import ReactQuill from "react-quill";
import Quill from "quill";
import "react-quill/dist/quill.bubble.css";
import { cn } from "../../lib/utils";
import "./wysiwyg-editor.css";
import { useEffect, useRef } from "react";

export const WysiwygEditor = ({
  value,
  setValue,
  isDisabled,
  className,
  minHeight,
}: {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  isDisabled?: boolean;
  className?: string;
  minHeight?: string;
}) => {
  const editorRef = useRef<any>(null);

  const modules = {
    toolbar: [
      [
        { header: [1, 2, false] },
        "bold",
        "italic",
        "underline",
        {
          color: [
            // TailwindCSS colors between 400-950

            "#94a3b8",
            "#64748b",
            "#475569",
            "#334155",
            "#1e293b",
            "#0f172a",
            "#020617",

            "#a8a29e",
            "#78716c",
            "#57534e",
            "#44403c",
            "#292524",
            "#1c1917",
            "#0c0a09",

            "#f87171",
            "#ef4444",
            "#dc2626",
            "#b91c1c",
            "#991b1b",
            "#7f1d1d",
            "#450a0a",

            "#fbbf24",
            "#f59e0b",
            "#d97706",
            "#b45309",
            "#92400e",
            "#78350f",
            "#451a03",

            "#4ade80",
            "#22c55e",
            "#16a34a",
            "#15803d",
            "#166534",
            "#14532d",
            "#052e16",

            "#22d3ee",
            "#06b6d4",
            "#0891b2",
            "#0e7490",
            "#155e75",
            "#164e63",
            "#083344",

            "#60a5fa",
            "#3b82f6",
            "#2563eb",
            "#1d4ed8",
            "#1e40af",
            "#1e3a8a",
            "#172554",

            "#c084fc",
            "#a855f7",
            "#9333ea",
            "#7e22ce",
            "#6b21a8",
            "#581c87",
            "#3b0764",

            "#f472b6",
            "#ec4899",
            "#db2777",
            "#be185d",
            "#9d174d",
            "#831843",
            "#500724",
          ],
        },
        { list: "ordered" },
        { list: "bullet" },
      ],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "list",
    "bullet",
    "color",
  ];

  useEffect(() => {
    if (editorRef.current && minHeight) {
      editorRef.current.style.setProperty("--quill-min-height", minHeight);
    }
  }, [minHeight]);

  return (
    <ReactQuill
      className={cn(
        "border overflow-visible rounded p-1 text-base flex-1 min-h-fit",
        isDisabled ? "bg-slate-50 opacity-75" : "bg-white",
        className
      )}
      style={{
        // @ts-ignore: this is valid, custom CSS property
        "--quill-min-height": minHeight,
        minHeight: "var(--quill-min-height, 250px)",
      }}
      theme="bubble"
      value={value}
      onChange={setValue}
      modules={modules}
      formats={formats}
      readOnly={isDisabled}
    />
  );
};
