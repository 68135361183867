import { ColumnDef } from '@tanstack/react-table';
import { UnreadMessageEmailNotificationActivityLog } from '../types';
import { getSimpleFormattedDateTime } from '../../../../../utils/dateUtils';

export const columns: ColumnDef<UnreadMessageEmailNotificationActivityLog>[] = [
  {
    accessorKey: 'epdPatientId',
    header: 'Client Number',
    size: 120,
    cell: ({ getValue }) => {
      return <>{getValue()}</>;
    },
  },
  {
    accessorKey: 'clientEmail',
    header: 'Client Email',
    size: 200,
    cell: ({ getValue }) => {
      return <>{getValue()}</>;
    },
  },
  {
    header: 'Last unread message',
    size: 200,
    cell: ({ row }) => {
      return (
        <div>
          <p className="text-xs">
            {row.original.lastMessageSentBy}, on{' '}
            {getSimpleFormattedDateTime(row.original.lastMessageSentAt)}
          </p>
          <p>{row.original.lastMessageText.slice(0, 50)}...</p>
        </div>
      );
    },
  },
  {
    header: 'Notification sent at',
    accessorKey: 'notificationSentAt',
    size: 200,
    cell: ({ getValue }) => {
      return <>{getSimpleFormattedDateTime(getValue() as string)}</>;
    },
  },
];
