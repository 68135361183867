import { EpdDossierType, RegistrationStatusType } from "../../types";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../components/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../../../../components/ui/command";
import { useGetEpdDossierTypes, useUpdateDossierType } from "../../hooks";
import { CheckIcon, Loader2 } from "lucide-react";
import { useState } from "react";
import { Button } from "../../../../components/ui/button";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { ScrollArea } from "../../../../components/ui/scroll-area";
import { cn } from "../../utils";

export const DossierType = ({
  isReadOnly,
  referralId,
  referralStatus,
  value,
}: {
  isReadOnly: boolean;
  referralId: string;
  referralStatus: RegistrationStatusType;
  value: string | undefined;
}) => {
  const { data, isLoading } = useGetEpdDossierTypes();
  const [selectedValue, setSelectedValue] = useState(value);

  const { mutate: save, isLoading: isSaving } = useUpdateDossierType();
  const [isDossierTypeOpen, setIsDossierTypeOpen] = useState(false);

  const requiresAttention =
    referralStatus === "RegisterPatientAndIntake" && !selectedValue;

  return (
    <div>
      <p className="font-medium pb-1">Dossier Type</p>
      {isLoading ? (
        <div className="w-full border px-4 py-2 rounded-lg flex items-center space-x-2">
          <Loader2 className="w-4 h-4 animate-spin" />
          <p>Loading...</p>
        </div>
      ) : (
        <div className="flex flex-col">
          <Popover
            open={isDossierTypeOpen}
            onOpenChange={setIsDossierTypeOpen}
            modal={true}
          >
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                role="combobox"
                disabled={isReadOnly || isSaving}
                className={cn(
                  "w-full justify-between font-normal",
                  !selectedValue && "text-muted-foreground",
                  requiresAttention
                    ? "border-2 border-red-300 dark:border-red-900 bg-red-50 dark:bg-red-950 hover:bg-red-50 hover:dark:bg-red-950"
                    : ""
                )}
              >
                {selectedValue
                  ? data?.find((x) => x.id === selectedValue)?.name && (
                      <>
                        <div className="flex items-center">
                          {data?.find((x) => x.id === selectedValue)?.name}
                        </div>
                      </>
                    )
                  : "Select dossier type..."}
                <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
              </Button>
            </PopoverTrigger>
            <PopoverContent
              side="bottom"
              align="start"
              className="p-0 z-[99999999] w-[25vw] h-[30vh]"
            >
              <Command>
                <CommandInput placeholder="Search dossier type..." />
                <CommandEmpty>No dossier type found.</CommandEmpty>
                <CommandGroup className="max-h-[500px] overflow-y-auto">
                  {data
                    ?.toSorted((a, b) => a.name.localeCompare(b.name))
                    ?.map((x) => (
                      <CommandItem
                        value={x.name}
                        key={x.id}
                        onSelect={async () => {
                          setSelectedValue(x.id);
                          setIsDossierTypeOpen(false);

                          await save({ referralId, dossierTypeId: x.id });
                        }}
                      >
                        <CheckIcon
                          className={cn(
                            "mr-2 h-4 w-4",
                            x.id === selectedValue ? "opacity-100" : "opacity-0"
                          )}
                        />
                        {x.name}
                      </CommandItem>
                    ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
        </div>
      )}
    </div>
  );
};
