import { useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useGetUnreadCountOfConversations } from './hooks';
import { SearchTermFilter } from './filters/SearchTermFilter';
import { EpdPatientIdFilter } from './filters/EpdPatientIdFilter';
import { UnreadMessageRemindersTable } from './table/UnreadMessageRemindersTable';

export default function UnreadMessagesReminders() {
  const [skip, setSkip] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [epdPatientId, setEpdPatientId] = useState<number | null>(null);

  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  const [debouncedEpdPatientId] = useDebounce(epdPatientId, 500);

  const { isLoading, isError, data } = useGetUnreadCountOfConversations(
    debouncedSearchTerm,
    debouncedEpdPatientId,
    skip
  );

  return (
    <main className="p-24">
      <section>
        <h1 className="text-4xl font-bold">Unread Messages Reminders</h1>
        <p>
          See the list of "you have unread messages" email notifications sent to clients using the
          web version of MyIPractice.
        </p>
      </section>

      <section className="pt-6 flex items-center space-x-2">
        <SearchTermFilter
          value={searchTerm}
          onChange={(newValue) => {
            setSkip(0);
            setSearchTerm(newValue);
          }}
        />
        <EpdPatientIdFilter
          value={epdPatientId}
          onChange={(newValue) => {
            setSkip(0);
            setEpdPatientId(newValue);
          }}
        />
      </section>

      <section>
        <UnreadMessageRemindersTable
          isLoading={isLoading}
          isError={isError}
          data={data?.data || []}
          skip={skip}
          setSkip={setSkip}
          pagination={data?.pagination}
        />
      </section>
    </main>
  );
}
