import { Button } from "../../../../components/ui/button";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../../../components/ui/alert-dialog";
import { useRetriggerSq48Reminder } from "./hooks";
import { toast } from "sonner";

export const RetriggerReminder = ({ id }: { id: string }) => {
  const { mutate, isLoading } = useRetriggerSq48Reminder(
    id,
    () =>
      toast("Succesfully re-triggered", {
        description:
          "The reminder has been retriggered & sent to the client's chat",
      }),
    () =>
      toast("Something went wrong", {
        description:
          "The reminder could not be re-sent: please contact the dev team",
      })
  );

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant="outline">Retrigger the reminder</Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription className="space-y-2">
            <p>
              Retriggering the reminder will send the message to the client in
              chat.
            </p>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <Button onClick={async () => await mutate()}>
            {isLoading ? "Retriggering..." : "Retrigger"}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
