import { iWelcomeActivityLogEntry } from "./types";
import { MailWarning } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../../components/ui/tooltip";
import moment from "moment";

export const SuccessfullySentEmails = ({
  activityLogs,
}: {
  activityLogs: iWelcomeActivityLogEntry[];
}) => {
  return (
    <div className="pt-1 flex items-center space-x-1">
      {activityLogs.length == 0 ? (
        <p className="text-xs">Er zijn nog geen e-mails verstuurd</p>
      ) : (
        <TooltipProvider delayDuration={100}>
          <Tooltip>
            <TooltipTrigger>
              <div
                className="flex items-center space-x-1 cursor-help"
                onClick={() => {}}
              >
                <MailWarning className="w-4 h-4" />
                <p className="text-xs">
                  {activityLogs.length} e-mail(s) zijn al verstuurd
                </p>
              </div>
            </TooltipTrigger>
            <TooltipContent className="text-sm z-[999999]">
              <ul className="list-disc list-inside space-y-1">
                {activityLogs.map((log, index) => {
                  return (
                    <li key={index}>
                      Email met sjabloon '{log.template}' is verzonden door{" "}
                      {log.senderEmail} op{" "}
                      {moment(log.sentOn).format("DD-MM-YYYY HH:mm")}.
                    </li>
                  );
                })}
              </ul>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}
    </div>
  );
};
