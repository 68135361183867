import axios from 'axios';
import { useMutation, useQuery } from 'react-query';
import { ResultPerEmployee } from './types';

export const useGetLocationTags = (selectedPsyEpdId: string) => {
  const query = useQuery<ResultPerEmployee>(
    ['calendar-admin', 'output', selectedPsyEpdId],
    async () => {
      const res = await axios.get(`calendaradmin/algorithm-output/${selectedPsyEpdId}`);
      return res.data;
    },
    {
      enabled: selectedPsyEpdId !== '',
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};
