import React, { Fragment } from "react";
import { observer } from "mobx-react";
import StatusLabelBoolean from "./StatusLabelBoolean";

const QuestionListStatus = ({ questionList }) => {
    if (questionList === undefined) {
        return (
          <>
            Not found
          </>
        );
    }

    return (
      <StatusLabelBoolean boolean={questionList.finished} positive="Afgerond" negative="Open" />
    );
};

export default observer(QuestionListStatus);
