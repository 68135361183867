import { Button } from "../../../../components/ui/button";
import { Textarea } from "../../../../components/ui/textarea";
import { Checkbox } from "../../../../components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { useState } from "react";
import { useMarkNextConsultAsLast } from "../hooks";
import { toast } from "sonner";
import { Loader2 } from "lucide-react";
import { useTranslation } from "react-i18next";

type CreateOffboardingProps = {
  currentWeekCaseLoadEntryId: string;
  isOpen: boolean;
  setIsOpen: (newVal: boolean) => void;
};

export const CreateOffboarding = ({
  currentWeekCaseLoadEntryId,
  isOpen,
  setIsOpen,
}: CreateOffboardingProps) => {
  const [treatmentSummary, setTreatmentSummary] = useState("");
  const [
    sendRequestToGoogleReviewToPatient,
    setSendRequestToGoogleReviewToPatient,
  ] = useState(true);
  const { t } = useTranslation();

  const { mutate: save, isLoading: isSaving } = useMarkNextConsultAsLast(
    () => {
      toast(t("case-load-manager-offboarding-save-feedback-title"), {
        description: t("case-load-manager-offboarding-save-feedback-desc"),
      });
    },
    () => {
      toast("Something went wrong", {
        description:
          "The next consult could not be marked as the last. Please try again later, or contact the development team.",
      });
    }
  );

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(newVal) => {
        setIsOpen(false);
      }}
    >
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>
            {t("case-load-manager-offboarding-create-dialog-title")}
          </DialogTitle>
          <DialogDescription>
            {t("case-load-manager-offboarding-create-dialog-desc-1")}
          </DialogDescription>
          <p>{t("case-load-manager-offboarding-create-dialog-desc-2")}</p>
        </DialogHeader>

        <p className="pt-1 font-bold">
          {t(
            "case-load-manager-offboarding-create-dialog-treatment-summary-label"
          )}
        </p>
        <Textarea
          rows={4}
          value={treatmentSummary ?? ""}
          onChange={(e) => setTreatmentSummary(e.target.value)}
        />

        <div className="mt-2 flex items-center space-x-2">
          <Checkbox
            id="sendReviewLinks"
            checked={sendRequestToGoogleReviewToPatient}
            onCheckedChange={() =>
              setSendRequestToGoogleReviewToPatient(
                !sendRequestToGoogleReviewToPatient
              )
            }
            disabled={isSaving}
          />
          <label
            htmlFor="sendReviewLinks"
            className="leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            {t("case-load-manager-offboarding-create-dialog-review-checkbox")}
          </label>
        </div>

        <DialogFooter className="flex items-center space-x-2">
          <Button
            variant="outline"
            className="w-1/2"
            onClick={() => setIsOpen(false)}
          >
            {t("case-load-manager-offboarding-create-dialog-cancel")}
          </Button>
          <Button
            onClick={async () =>
              await save({
                currentWeekCaseLoadEntryId,
                treatmentSummary,
                sendRequestToGoogleReviewToPatient,
              })
            }
            className="w-1/2 flex items-center space-x-2"
            disabled={!treatmentSummary}
          >
            {isSaving && <Loader2 className="w-4 h-4 animate-spin" />}
            <p>{t("case-load-manager-offboarding-create-dialog-save")}</p>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
