import React, { useEffect, useState } from 'react';
import dossierType from './utils/constants/dossierType';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Main as Layout } from './layouts/Main/Main';
import configureI18n from './i18n';
import { useGetTranslations } from './shared/hooks/useGetTranslations';
import { ApplicationInitializing } from './shared/ApplicationInitializing';
import LoginPage from './pages/auth/LoginPage';
import WelcomePage from './pages/welcome/WelcomePage';
import PatientsPage from './pages/patients';
import PatientPage from './pages/patient';
import CreatePatientPage from './pages/createPatient';
import DossierGgzsPage from './pages/dossierGgzs';
import DossierTransfersPage from './pages/dossierTransfers';
import DossierOpenUpsPage from './pages/dossierOpenUps';
import CalendarPage from './pages/calendar';
import OnboardingPage from './pages/onboarding';
import CaseLoadManager from './pages/caseLoadManager/caseLoadManager';
import CustomerRelationshipManager from './pages/customerRelationshipManager';
import GermanClients from './pages/iPracticeGermany/clients';
import PreIntakeReport from './pages/preIntake/report';
import IntakeReport from './pages/intake/report';
import EmptyIntakeSpotsReport from './pages/emptySpotsIntakeReport/report';
import AdminPage from './pages/admin';
import UpdatePsychologistTagsPage from './pages/psychologists';
import ManageNews from './pages/admin/news/manageNews';
import CalendarAlgorithmAdmin from './pages/calendar/admin';
import CaseLoadManagerAdmin from './pages/caseLoadManager/admin';
import AkwaGgzReportGenerationAdmin from './pages/akwaGgzReport/admin';
import BigRegisterAdmin from './pages/bigRegisterReport';
import SendQuestionnairesToPraktijkdataAdmin from './pages/questionnaires/admin/sendToPraktijkData';
import SQ48ReminderToCompleteAdmin from './pages/questionnaires/admin/sq48ReminderToComplete';
import SQ48ResultPdfsToChatAdmin from './pages/questionnaires/admin/sq48ResultPdfToClientInChat';
import AppointmentRemindersToChatAdmin from './pages/questionnaires/admin/appointmentReminders';
import CohortStudyReportAdmin from './pages/questionnaires/admin/cohortStudyReport';
import CqiReportAdmin from './pages/questionnaires/admin/cqiReport';
import CrmConfigAdmin from './pages/customerRelationshipManager/admin/configuration';
import CrmStatisticsAdmin from './pages/customerRelationshipManager/admin/statistics';
import CrmUpcomingReminders from './pages/customerRelationshipManager/admin/upcomingReminders';
import UnauthorizedPage from './pages/errors/unauthorized';
import NotFoundPage from './pages/errors/notFound';
import DossierGgzPage from './pages/dossierGgz';
import CreateDossierGgzPage from './pages/createDossierGgz';
import CreateDossierTransferPage from './pages/createDossierTransfer';
import DossierTransferPage from './pages/dossierTransfer';
import DossierOpenUpPage from './pages/dossierOpenUp';
import { useMsal } from '@azure/msal-react';
import Chat from './pages/chat/index';
import { useGetAuthorizations } from './shared/hooks/useGetAuthorizations';
import UnreadMessagesReminders from './pages/chat/admin/unreadMessagesReminders/UnreadMessagesReminders';

export const AuthorizedTranslatedRouter: React.FC = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const [translationsLoaded, setTranslationsLoaded] = useState<boolean>(false);
  const { data: translations, isLoading: isLoadingTranslations } = useGetTranslations();
  const { data: authorization, isLoading: isLoadingAuthorizations } = useGetAuthorizations();

  useEffect(() => {
    const configureTranslations = async () => {
      if (!isLoadingTranslations && translations) {
        await configureI18n(translations);
        setTranslationsLoaded(true);
      }
    };

    configureTranslations();
  }, [isLoadingTranslations, translations]);

  if (isLoadingTranslations || !translationsLoaded || isLoadingAuthorizations) {
    return <ApplicationInitializing />;
  }

  if (!activeAccount) {
    return (
      <div
        style={{
          minHeight: '100vh',
          minWidth: '100vw',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LoginPage />
      </div>
    );
  }

  if (authorization?.roles.length === 0) {
    return <UnauthorizedPage />;
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <WelcomePage />
          </Layout>
        }
      />
      <Route
        path="/login"
        element={
          <Layout>
            <LoginPage />
          </Layout>
        }
      />
      <Route
        path="/patients"
        element={
          <Layout>
            <PatientsPage />
          </Layout>
        }
      />
      <Route
        path="/create-patient"
        element={
          <Layout>
            <CreatePatientPage />
          </Layout>
        }
      />
      <Route
        path="/dossiers-ggz"
        element={
          <Layout>
            <DossierGgzsPage />
          </Layout>
        }
      />
      <Route
        path="/dossiers-transfer"
        element={
          <Layout>
            <DossierTransfersPage />
          </Layout>
        }
      />
      <Route
        path="/dossiers-open-up-deprecated"
        element={
          <Layout>
            <DossierOpenUpsPage />
          </Layout>
        }
      />
      <Route
        path="/calendar"
        element={
          <Layout>
            <CalendarPage />
          </Layout>
        }
      />
      <Route
        path="/onboarding"
        element={
          <Layout>
            <OnboardingPage />
          </Layout>
        }
      />
      <Route
        path="/case-load"
        element={
          <Layout>
            <CaseLoadManager />
          </Layout>
        }
      />
      <Route
        path="/patient-referrals"
        element={
          <Layout>
            <CustomerRelationshipManager />
          </Layout>
        }
      />
      <Route
        path="/germany/clients"
        element={
          <Layout>
            <GermanClients />
          </Layout>
        }
      />
      <Route
        path="/reports/pre-intake"
        element={
          <Layout>
            <PreIntakeReport />
          </Layout>
        }
      />
      <Route
        path="/reports/empty-intake-spots"
        element={
          <Layout>
            <EmptyIntakeSpotsReport />
          </Layout>
        }
      />
      <Route
        path="/admin/users/roles"
        element={
          <Layout>
            <AdminPage />
          </Layout>
        }
      />
      <Route
        path="/admin/users/tags"
        element={
          <Layout>
            <UpdatePsychologistTagsPage />
          </Layout>
        }
      />
      <Route
        path="/admin/news"
        element={
          <Layout>
            <ManageNews />
          </Layout>
        }
      />
      <Route
        path="/admin/calendar-algorithm-admin"
        element={
          <Layout>
            <CalendarAlgorithmAdmin />
          </Layout>
        }
      />
      <Route
        path="/admin/case-load-manager"
        element={
          <Layout>
            <CaseLoadManagerAdmin />
          </Layout>
        }
      />
      <Route
        path="/admin/reports/akwa-ggz"
        element={
          <Layout>
            <AkwaGgzReportGenerationAdmin />
          </Layout>
        }
      />
      <Route
        path="/admin/reports/big-register"
        element={
          <Layout>
            <BigRegisterAdmin />
          </Layout>
        }
      />
      <Route
        path="/admin/reports/cohort-study"
        element={
          <Layout>
            <CohortStudyReportAdmin />
          </Layout>
        }
      />
      <Route
        path="/admin/reports/cqi"
        element={
          <Layout>
            <CqiReportAdmin />
          </Layout>
        }
      />
      <Route
        path="/admin/patient-referrals/config"
        element={
          <Layout>
            <CrmConfigAdmin />
          </Layout>
        }
      />
      <Route
        path="/reports/patient-referrals/statistics"
        element={
          <Layout>
            <CrmStatisticsAdmin />
          </Layout>
        }
      />
      <Route
        path="/admin/patient-referrals/upcoming-reminders"
        element={
          <Layout>
            <CrmUpcomingReminders />
          </Layout>
        }
      />
      <Route
        path="/admin/send-questionnaires-to-praktijkdata"
        element={
          <Layout>
            <SendQuestionnairesToPraktijkdataAdmin />
          </Layout>
        }
      />
      <Route
        path="/admin/sq48-reminders-to-complete"
        element={
          <Layout>
            <SQ48ReminderToCompleteAdmin />
          </Layout>
        }
      />
      <Route
        path="/admin/sq48-result-pdf-to-chat"
        element={
          <Layout>
            <SQ48ResultPdfsToChatAdmin />
          </Layout>
        }
      />
      <Route
        path="/admin/appointment-reminders"
        element={
          <Layout>
            <AppointmentRemindersToChatAdmin />
          </Layout>
        }
      />

      <Route
        path="/patient/:patientId/"
        element={
          <Layout>
            <PatientPage />
          </Layout>
        }
      />

      <Route
        path={`/patient/:patientId/${dossierType.GGZ}/:dossierId/`}
        element={
          <Layout>
            <DossierGgzPage />
          </Layout>
        }
      />
      <Route
        path={`/patient/:patientId/${dossierType.ADHD}/:dossierId/`}
        element={
          <Layout>
            <DossierGgzPage />
          </Layout>
        }
      />
      <Route
        path={`/patient/:patientId/${dossierType.GGZ}/:dossierId/:tab`}
        element={
          <Layout>
            <DossierGgzPage />
          </Layout>
        }
      />
      <Route
        path={`/patient/:patientId/${dossierType.ADHD}/:dossierId/:tab`}
        element={
          <Layout>
            <DossierGgzPage />
          </Layout>
        }
      />
      <Route
        path="/patient/:patientId/dossier-ggz/create"
        element={
          <Layout>
            <CreateDossierGgzPage />
          </Layout>
        }
      />

      <Route
        path="/patient/:patientId/dossier-transfer/create"
        element={
          <Layout>
            <CreateDossierTransferPage />
          </Layout>
        }
      />
      <Route
        path={`/patient/:patientId/${dossierType.TRANSFER}/:dossierId`}
        element={
          <Layout>
            <DossierTransferPage />
          </Layout>
        }
      />
      <Route
        path={`/patient/:patientId/${dossierType.OPEN_UP}-deprecated/:dossierId`}
        element={
          <Layout>
            <DossierOpenUpPage />
          </Layout>
        }
      />
      <Route
        path="/chat"
        element={
          <Layout>
            <Chat />
          </Layout>
        }
      />

      <Route
        path="/reports/intake"
        element={
          <Layout>
            <IntakeReport />
          </Layout>
        }
      />

      <Route
        path="/admin/my-ipractice/unread-messages-reminders"
        element={
          <Layout>
            <UnreadMessagesReminders />
          </Layout>
        }
      />

      <Route
        path="*"
        element={
          <Layout>
            <NotFoundPage />
          </Layout>
        }
      />
    </Routes>
  );
};

export default AuthorizedTranslatedRouter;
