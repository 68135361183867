import { useGetEpdLocations, useUpdateLocation } from "../../hooks";
import { EpdLocation, RegistrationStatusType } from "../../types";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import { useState } from "react";
import { Loader2 } from "lucide-react";

export const Location = ({
  isReadOnly,
  referralId,
  referralStatus,
  value,
}: {
  isReadOnly: boolean;
  referralId: string;
  referralStatus: RegistrationStatusType;
  value: EpdLocation | null;
}) => {
  const { data, isLoading } = useGetEpdLocations();
  const [selectedValue, setSelectedValue] = useState(value);

  const { mutate: save, isLoading: isSaving } = useUpdateLocation();

  const requiresAttention =
    referralStatus === "RegisterPatientAndIntake" && !selectedValue;

  return (
    <div>
      <p className="font-medium pb-1">Treatment Location</p>
      {isLoading ? (
        <div className="w-full border px-4 py-2 rounded-lg flex items-center space-x-2">
          <Loader2 className="w-4 h-4 animate-spin" />
          <p>Loading...</p>
        </div>
      ) : (
        <Select
          disabled={isReadOnly || isSaving}
          onValueChange={async (value) => {
            const selectedLocation =
              data!.find((location) => location.name === value) ?? null;

            setSelectedValue(selectedLocation);
            await save({ referralId, locationId: selectedLocation?.id });
          }}
          defaultValue={
            data!.find((location) => location.id === selectedValue?.id)?.name
          }
        >
          <SelectTrigger
            className={
              requiresAttention
                ? "border-2 border-red-300 dark:border-red-900 bg-red-50 dark:bg-red-950"
                : ""
            }
          >
            <SelectValue placeholder="Select a location..." />
          </SelectTrigger>
          <SelectContent>
            {data!
              .toSorted((a, b) => a.name.localeCompare(b.name))!
              .map((location: EpdLocation) => (
                <SelectItem value={location.name} key={location.id}>
                  {location.name}
                </SelectItem>
              ))}
          </SelectContent>
        </Select>
      )}
    </div>
  );
};
