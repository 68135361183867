class ReplaceOperation {
    op = "replace";

    path = "";

    value = "";

    constructor(path, value) {
        this.path = `/${path}`;
        this.value = value;
    }
}

export default (replacements) => {
    const arrayOfOperations = [];

    Object.keys(replacements).map((key) => (
        arrayOfOperations.push(new ReplaceOperation(key, replacements[key]))
    ));

    return arrayOfOperations;
};
