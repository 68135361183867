import { Loader2 } from 'lucide-react';
import { startTransition, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

type Props = {
  url: string;
};

export const YoutubePreview = ({ url }: Props) => {
  const [videoDetails, setVideoDetails] = useState<{
    description?: string;
    loading: boolean;
    thumbnail?: string;
    title?: string;
  }>({
    description: '...',
    loading: true,
    thumbnail: '',
    title: 'Loading...',
  });

  useEffect(() => {
    const getVideoDetails = async () => {
      //   try {
      //     const details = await scrapeYoutubeURL(url);

      //     setVideoDetails((videoDetails) => ({
      //       ...videoDetails,
      //       ...details,
      //       loading: false,
      //     }));
      //   } catch (error) {
      //     console.error(error);

      setVideoDetails((videoDetails) => ({
        ...videoDetails,
        loading: false,
      }));
      //   }
    };

    startTransition(() => {
      getVideoDetails();
    });
  }, [url]);

  return (
    <div className="h-auto">
      <div className="relative mb-2 h-[166px] w-auto overflow-hidden rounded">
        <ReactPlayer height={170} url={url} width={280} />
      </div>

      <div className="">
        <a
          className="text-openup-dark-blue font-semibold leading-4 antialiased"
          href={url}
          rel="noreferrer"
          target="_blank"
        >
          {videoDetails.loading ? (
            <Loader2 className="animate-spin w-4 h-4" />
          ) : (
            videoDetails.title || 'No title'
          )}
        </a>

        <p className="text-openup-dark-blue mb-auto line-clamp-5 text-xs leading-4 tracking-[0.5px] antialiased">
          {videoDetails.loading ? (
            <Loader2 className="animate-spin w-4 h-4" />
          ) : (
            videoDetails.description || 'No description'
          )}
        </p>

        <div className="mt-3 flex items-center text-sm sm:mt-1">
          <a className="text-openup-primary-blue text-xs" href={url}>
            {url}
          </a>
        </div>
      </div>
    </div>
  );
};
