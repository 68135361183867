import { XCircle } from "lucide-react";
import { useTranslation } from "react-i18next";

export const Error = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full p-20 bg-red-100 dark:bg-red-950 text-red-600 dark:text-red-300 flex flex-col items-center">
      <XCircle className="opacity-20 w-32 h-32" />
      <h1 className="text-2xl font-bold pt-4">
        {t("case-load-manager-something-went-wrong")}
      </h1>
      <p className="text-center">
        {t("case-load-manager-generic-error-desc")}
        <br />
        {t("case-load-manager-generic-error-try-again-later")}
      </p>
    </div>
  );
};
