import { Button } from "../../../../components/ui/button";
import {
  PhoneOutgoing,
  CalendarSearch,
  PhoneCall,
  PhoneMissed,
} from "lucide-react";
import { useState } from "react";
import { useAddNewReachOutEntry } from "../../hooks";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../../../components/ui/dialog";
import { Textarea } from "../../../../components/ui/textarea";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../components/ui/popover";
import { Calendar } from "../../../../components/ui/calendar";
import { Separator } from "../../../../components/ui/separator";
import moment from "moment";
import {
  ReferralDetails,
  UnsuccessfulyReachOutAttemptEmail,
} from "../../types";
import { ReachoutCounter } from "./reachoutCounter";
import { UnsuccessfulReachOutEmailSelector } from "./unsuccessfulReachOutEmailSelector";

export const AddNewReachOutAttempt = ({
  referral,
}: {
  referral: ReferralDetails;
}) => {
  const [newRemark, setNewRemark] = useState("");
  const [newDate, setNewDate] = useState(new Date());
  const [emailToBeSentToThePatient, setEmailToBeSentToThePatient] = useState(
    UnsuccessfulyReachOutAttemptEmail.None
  );

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { mutate: save, isLoading: isSaving } = useAddNewReachOutEntry(() => {
    setIsDialogOpen(false);

    // reset
    setNewRemark("");
    setNewDate(new Date());
  });

  const handleAddReachoutAttempt = async (isSuccess: boolean) => {
    if (isSaving) return;

    const data = {
      referralId: referral.id,
      reachOutAttempt: {
        date: newDate,
        isSuccess,
        note: newRemark,
        emailToBeSent: isSuccess // just to be super safe... make absolutely sure if its a success, we do NOT send an email
          ? UnsuccessfulyReachOutAttemptEmail.None
          : emailToBeSentToThePatient,
      },
    };

    await save(data);
  };

  return (
    <>
      <div className="flex flex-col items-center justify-between space-y-2">
        <div className="w-full">
          <div className="flex items-start space-x-2">
            <div className="flex items-center space-x-2">
              <div className="w-4 h-4">
                <PhoneOutgoing className="w-4 h-4" />
              </div>
              <p className="text-lg font-medium leading-none">
                Have you just tried calling the patient?
              </p>
            </div>
          </div>
          <Separator className="my-1" />
        </div>
        <div className="w-full flex space-x-2">
          <Button
            className="flex items-center space-x-2 w-1/2"
            onClick={async () => {
              await handleAddReachoutAttempt(true);
            }}
            variant="success"
          >
            <div className="w-4 h-4">
              <PhoneCall className="w-4 h-4" />
            </div>
            <p>{isSaving ? "Saving..." : "Reached the client"}</p>
          </Button>
          <Button
            className="flex items-center space-x-2 w-1/2"
            onClick={() => {
              setIsDialogOpen(true);

              if (referral.email) {
                setEmailToBeSentToThePatient(
                  UnsuccessfulyReachOutAttemptEmail.WillTryAgain
                );
              }
            }}
            variant="destructive"
          >
            <div className="w-4 h-4">
              <PhoneMissed className="w-4 h-4" />
            </div>
            <p>Couldn't reach client</p>
          </Button>
        </div>
        <ReachoutCounter referral={referral} />
      </div>

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="max-w-[60vh] p-8">
          <DialogHeader>
            <DialogTitle className="text-2xl">Details</DialogTitle>

            <main className="pt-4 flex flex-col space-y-4">
              <section className="space-y-1">
                <label htmlFor="reachout" className="font-medium">
                  When did you (try to) call the patient?
                </label>
                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      className="flex items-center gap-2 w-fit px-4"
                      variant="outline"
                    >
                      <CalendarSearch className="w-5 h-5" />
                      <p>{newDate.toISOString().slice(0, 10)}</p>
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent
                    className="z-[99999999]"
                    align="center"
                    side="bottom"
                  >
                    <Calendar
                      mode="single"
                      toDate={new Date()}
                      selected={newDate}
                      onSelect={(_, selectedDay) => {
                        const parsedDate = moment(selectedDay).utc(true);
                        setNewDate(parsedDate.toDate());
                      }}
                    />
                  </PopoverContent>
                </Popover>
              </section>

              <section className="space-y-1">
                <label htmlFor="remark" className="font-medium">
                  Remarks
                </label>
                <Textarea
                  id="remark"
                  className="flex-3"
                  value={newRemark}
                  onChange={(e) => setNewRemark(e.target.value)}
                  placeholder="Enter any (optional) remark from the conversation"
                  rows={3}
                />
              </section>

              <section className="grid gap-4 pt-1">
                <UnsuccessfulReachOutEmailSelector
                  referral={referral}
                  value={emailToBeSentToThePatient}
                  setValue={(x) => setEmailToBeSentToThePatient(x)}
                />
              </section>
            </main>
            <DialogFooter className="space-x-2 pt-6">
              <Button
                variant="outline"
                className="w-1/2"
                onClick={() => setIsDialogOpen(false)}
              >
                Cancel
              </Button>
              <Button
                className="w-1/2"
                onClick={async () => {
                  if (!isSaving) {
                    await handleAddReachoutAttempt(false);
                  }
                }}
              >
                {isSaving ? "Saving..." : "Save"}
              </Button>
            </DialogFooter>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </>
  );
};
