import { useContext } from "react";
import { NavBarOpenStateContext } from "./NavBarOpenStateContext";
import { ChevronLeft, ChevronRight } from "lucide-react";

export const ShowOrHideNavBar = () => {
  const { isNavBarOpen, setIsNavBarOpen } = useContext(NavBarOpenStateContext)!;

  return (
    <div>
      <div
        className="mt-6 w-8 h-8 ml-1 mb-1 flex items-center justify-center cursor-pointer dark:bg-slate-800 bg-blue-300/40 hover:bg-blue-300 dark:hover:bg-slate-700 rounded-full"
        onClick={() => {
          const newValue = !isNavBarOpen;
          setIsNavBarOpen(newValue);
          localStorage.setItem("isSidebarVisible", newValue.toString());
        }}
      >
        <ChevronLeft
          className={`w-4 h-4 ${isNavBarOpen ? "visible" : "hidden"}`}
        />
        <ChevronRight
          className={`w-4 h-4 ${isNavBarOpen ? "hidden" : "visible"}`}
        />
      </div>
    </div>
  );
};
