import { Pagination } from "../../../../shared/pagination";
import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { AppointmentReminder as AppointmentReminder } from "./types";
import { queryClient } from "../../../../react-query/provider";

export const useGetAppointmentReminders = (searchTerm: string, skip: number) => {
  const query = useQuery<{
    data: AppointmentReminder[];
    pagination: Pagination;
  }>(
    ["appointment-reminders", searchTerm, skip],
    async () => {
      const res = await axios.get(
        `adminappointments/appointment-reminders?searchTerm=${searchTerm}&skip=${skip}`
      );
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useRetriggerAppointmentReminder = (
  id: string,
  onSuccess: () => void,
  onError: () => void
) => {
  const mutation = useMutation({
    mutationFn: async () => {
      const res = await axios.post(
        `adminappointments/appointment-reminders/${id}/retrigger`,
        {}
      );
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["appointment-reminders"]);
      queryClient.refetchQueries(["appointment-reminders"]);
      onSuccess();
    },
    onError: onError,
  });

  return mutation;
};
