import { Button } from "../../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { Input } from "../../../../components/ui/input";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import axios from "axios";
import { AlertCircle, CircleAlert, Loader2 } from "lucide-react";

export const StartNewTreatmentDossierWarning = ({
  dossierId,
  patient,
  lastDossierEpdFileId,
  isWelcomeFlowFinished,
}: {
  dossierId: number;
  patient: {
    dossiers: {
      lookupGuid: string;
      id: number;
      patientId: string;
      epdPatientId: number;
      epdFileId: number;
      subType: string;
      healthCareInsurerType: string;
      succeedsPreviousDossier:
        | {
            lookupGuid: string;
          }
        | undefined;
    }[];
  };
  lastDossierEpdFileId: number;
  isWelcomeFlowFinished: boolean;
}) => {
  const navigate = useNavigate();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dossierNumber, setDossierNumber] = useState<number | undefined>(
    lastDossierEpdFileId + 1
  );

  const [dossier, setDossier] = useState<{
    lookupGuid: string;
    id: number;
    patientId: string;
    epdPatientId: number;
    epdFileId: number;
    subType: string;
    healthCareInsurerType: string;
  } | null>(null);

  useEffect(() => {
    const matches = patient.dossiers?.filter((d) => d.id == dossierId);

    if (matches.length == 1) {
      setDossier(matches[0]);
    }
  }, [patient.dossiers]);

  const { mutate: save, isLoading } = useMutation({
    mutationFn: async () => {
      const res = await axios.post(
        `patients/${dossier!.patientId}/dossiers/${
          dossier!.id
        }/create-new-treatment-dossier-from`,
        {
          newDossierId: dossierNumber,
        }
      );
      return res.data;
    },
    onSuccess: (res) => {
      onCloseDialog();

      navigate({
        pathname: `/patient/${dossier!.patientId}/DossierGgz/${
          res.value.id
        }/onboarding`,
      });
    },
  });

  const hasAlreadyStartedTreatmentWithADossier =
    dossier == null
      ? false
      : patient.dossiers.some(
          (d) => d.succeedsPreviousDossier?.lookupGuid === dossier!.lookupGuid
        );

  const isVisible =
    dossier != null &&
    dossier.subType === "SCREENING" &&
    ["CZ", "ONVZ"].includes(dossier.healthCareInsurerType) &&
    isWelcomeFlowFinished &&
    !hasAlreadyStartedTreatmentWithADossier;

  if (!isVisible) {
    return <></>;
  }

  const isInvalidDossierNumber = () => {
    // we gotta make sure we don't select a dossier number that already exists for the patient
    return dossierNumber != null && dossierNumber! <= lastDossierEpdFileId;
  };

  const onCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <div className="w-1/2 border-l-4 border-orange-300 bg-orange-100 p-6 flex items-start space-x-4 text-orange-500">
        <div className="h-7 w-7 pt-1">
          <CircleAlert className="h-7 w-7" />
        </div>
        <div>
          <h1 className="font-bold text-2xl">
            Behandel-dossier is klaar om aangemaakt te worden
          </h1>
          <p className="pt-1">
            Dit is een ADHD screeningsdossier, waarbij de iWelcome is voltooid.
            Om de behandeling te starten, klikt u op de knop 'Nieuw
            Behandelingsdossier' hieronder.
          </p>

          <Button
            className="text-muted-foreground mt-4"
            variant="outline"
            onClick={() => setIsDialogOpen(true)}
          >
            Nieuw Behandelingsdossier
          </Button>

          <Dialog
            open={isDialogOpen}
            onOpenChange={(newVal) => {
              if (newVal) {
                setIsDialogOpen(true);
              } else {
                onCloseDialog();
              }
            }}
          >
            <DialogContent className="min-w-[35vw]">
              <DialogHeader>
                <DialogTitle>Nieuw Behandelingsdossier</DialogTitle>
                <DialogDescription>
                  Gelieve de nummers van de klant en het nieuwe dossier te
                  verstrekken.
                </DialogDescription>
              </DialogHeader>
              <div className="grid gap-4 py-4">
                <div className="grid grid-cols-2 items-center gap-4">
                  <div className="grid w-full max-w-sm items-center gap-1.5">
                    <label htmlFor="clientNumber">Cliënt nr.</label>
                    <p className="font-bold">{dossier.epdPatientId}</p>
                  </div>
                  <div className="grid w-full max-w-sm items-center gap-1.5">
                    <label htmlFor="dossierNumber">Dossier nr.</label>
                    <Input
                      type="number"
                      id="dossierNumber"
                      placeholder="Dossier number"
                      autoFocus
                      value={dossierNumber}
                      onChange={(event) => {
                        setDossierNumber(Number(event.target.value));
                      }}
                    />
                  </div>
                </div>
              </div>
              {isInvalidDossierNumber() && (
                <div className="rounded text-amber-600 px-4 py-2 mb-2 bg-amber-100 flex items-center space-x-2 text-sm">
                  <div className="h-4 w-4">
                    <AlertCircle className="h-4 w-4" />
                  </div>
                  <p>
                    Zorg er alsjeblieft voor dat je geen dossiernummer opgeeft
                    dat al bestaat voor de patiënt.
                  </p>
                </div>
              )}

              <DialogFooter className="gap-2">
                <Button
                  variant="outline"
                  onClick={onCloseDialog}
                  className="w-1/2"
                >
                  Annuleren
                </Button>
                <Button
                  color="primary"
                  disabled={isInvalidDossierNumber()}
                  onClick={async () => {
                    if (isLoading) return;

                    await save();
                  }}
                  className="w-1/2 flex items-center space-x-2"
                >
                  {isLoading && <Loader2 className="w-4 h-4 animate-spin" />}
                  <p>Opslaan</p>
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </>
  );
};
