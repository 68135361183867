import axios from "axios";
import { action, makeObservable, observable, runInAction } from "mobx";
import patchDataFactory from "../utils/patchDataFactory";

class ConsultsStore {
  consults = [];

  constructor() {
    makeObservable(this, {
      createConsult: action,
      editConsult: action,
      fetchConsults: action,
      deleteConsult: action,
      consults: observable,
    });
  }

  async createConsult(consult, dossierId) {
    await axios
      .post(`dossiers/${dossierId}/consults`, {
        subject: consult.consultTitle,
        employeeId: consult.employeeId,
        startedAt: consult.date,
        finishedAt: consult.finishedAt,
        notes: consult.notes,
      })
      .then(() => this.fetchConsults(dossierId));
  }

  async fetchConsults(dossierId) {
    const res = await axios.get(`dossiers/${dossierId}/consults`);

    const content = [];

    res.data.forEach((consult) =>
      content.push({
        id: consult.id,
        title: consult.subject,
        employeeName: `${consult.employee.firstName} ${consult.employee.lastName}`,
        consultTitle: consult.subject,
        notes: consult.notes,
        date: consult.startedAt, // Date instead of startedAt for use in FullCalendar
        finishedAt: consult.finishedAt,
        employeeId: consult.employeeId,
        dossierId: consult.dossierId,
      })
    );

    runInAction(() => {
      this.consults = content;
    });
  }

  async editConsult(consult) {
    const replacements = {
      Subject: consult.consultTitle,
      EmployeeId: consult.employeeId,
      Notes: consult.notes,
      StartedAt: consult.date,
      FinishedAt: consult.finishedAt,
    };

    await axios
      .patch(`/consults/${consult.id}`, patchDataFactory(replacements))
      .then(() => this.fetchConsults(consult.dossierId));
  }

  async deleteConsult(dossierId, consultId) {
    await axios
      .delete(`/consults/${consultId}`)
      .then(() => this.fetchConsults(dossierId));
  }
}

export default ConsultsStore;
