import { AlertCircle, CheckCircle, X } from "lucide-react";
import { Button } from "../../../../components/ui/button";
import { useState } from "react";
import { useMediaQuery } from "@mui/material";
import { CaseLoadAlert } from "../types";
import { refetchCaseLoadEntries } from "../hooks";
import { useTranslation } from "react-i18next";
import { MarkAllAlertsAsResolvedButton } from "./markAllAlertsAsResolvedButton";

type AlertHeaderProps = {
  hasAbsence: boolean;
  alerts: {
    caseLoadEntryId: string;
    alerts: CaseLoadAlert[];
  }[];
  onlyCaseLoadsWithAlert: boolean;
  setOnlyCaseLoadsWithAlert: (newVal: boolean) => void;
};

export const AlertHeader = ({
  hasAbsence,
  alerts,
  onlyCaseLoadsWithAlert,
  setOnlyCaseLoadsWithAlert,
}: AlertHeaderProps) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery("(min-width:1200px)");

  const [shouldHide, setShouldHide] = useState(
    sessionStorage.getItem("hide-case-load-alerts") == "true"
  );

  // if the current psy is on absence, there's not much sense in showing the alert banner
  if (hasAbsence || shouldHide || alerts.every((x) => x.alerts.length === 0))
    return <></>;

  return (
    <div className="bg-red-600 dark:bg-slate-950 py-2 text-white dark:text-red-500 -mt-[3px] flex justify-between items-center -mx-14 px-14">
      <div className="flex items-center space-x-2">
        <div className="h-5 w-5">
          <AlertCircle className="h-5 w-5" />
        </div>
        <div>
          <p className="font-bold">
            {t("case-load-manager-alert-menu-title")}{" "}
            <span className="font-normal">
              {t("case-load-manager-alert-menu-desc")}
            </span>
          </p>
        </div>
      </div>
      <div className="flex space-x-2 items-center">
        {isDesktop ? (
          <>
            <Button
              variant="secondary"
              className="text-sm flex items-center space-x-2 text-red-800 dark:text-slate-400"
              onClick={() => {
                const newVal = !onlyCaseLoadsWithAlert;
                setOnlyCaseLoadsWithAlert(newVal);
                refetchCaseLoadEntries();
              }}
            >
              {onlyCaseLoadsWithAlert && <CheckCircle className="h-4 w-4" />}
              <p>
                {t(
                  "case-load-manager-alert-menu-show-only-rows-with-alerts-button"
                )}
              </p>
            </Button>

            <MarkAllAlertsAsResolvedButton
              alerts={alerts}
              setHideAlerts={setShouldHide}
              setOnlyCaseLoadsWithAlert={setOnlyCaseLoadsWithAlert}
            />
          </>
        ) : (
          <></>
        )}

        <div className="pl-4 dark:text-slate-400">
          <X
            className="h-4 w-4 cursor-pointer"
            onClick={() => {
              sessionStorage.setItem("hide-case-load-alerts", "true"); // hide 'alert banner'
              setShouldHide(true);
              refetchCaseLoadEntries();
            }}
          />
        </div>
      </div>
    </div>
  );
};
