import { Hourglass } from "lucide-react";
import { useTranslation } from "react-i18next";

type PastDataWarningProps = {
  backToPresent: () => void;
};

export const PastDataWarning = ({ backToPresent }: PastDataWarningProps) => {
  const { t } = useTranslation();

  return (
    <div className="w-full bg-blue-100 p-12 rounded flex items-center space-x-4">
      <div className="w-10 h-10">
        <Hourglass className="w-10 h-10" />
      </div>
      <section>
        <h1 className="text-2xl">
          {t("case-load-manager-past-data-warning-title")}
        </h1>
        <p>
          {t("case-load-manager-past-data-warning-desc")}{" "}
          <span
            className="underline hover:text-slate-700 cursor-pointer font-bold"
            onClick={backToPresent}
          >
            {t("case-load-manager-click-here")}
          </span>
          .
        </p>
      </section>
    </div>
  );
};
