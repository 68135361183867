import { Card, CardHeader, Divider, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { styled } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import Subscription from "./Subscription";
import useStores from "../../useStores";
import SubscriptionAddEditModal from "./SubscriptionAddEditModal";

const StyledCardRoot = styled(Card)(({ theme }) => ({
  padding: theme.spacing(0),
}));

const StyledActionButton = styled(Button)(() => ({
  flexDirection: "column",
  alignItems: "flex-start",
}));

const Subscriptions = () => {
  const { subscriptionStore, patientStore } = useStores();
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [openAddSubscriptionModal, setOpenAddSubscriptionModal] =
    useState(null);

  useEffect(() => {
    subscriptionStore.fetchSubscriptions(patientStore.dossier.id);
  }, [subscriptionStore, patientStore.dossier.id]);

  const handleSubscriptionEdit = (selected) => {
    setSelectedSubscription(selected);
  };

  const handleModalClose = () => {
    setSelectedSubscription(null);
    setOpenAddSubscriptionModal(false);
  };

  const sortByStartDate = (a, b) => {
    if (a.startDate > b.startDate) {
      return -1;
    }
    if (b.startDate > a.startDate) {
      return 1;
    }
    return 0;
  };

  return (
    <StyledCardRoot>
      <CardHeader
        title="Abonnementen"
        action={
          <StyledActionButton
            color="primary"
            size="small"
            onClick={() => setOpenAddSubscriptionModal(true)}
          >
            <AddIcon />
            Toevoegen
          </StyledActionButton>
        }
      />
      <Divider />
      {subscriptionStore.subscriptions
        .slice()
        .sort(sortByStartDate)
        .map((subscription) => (
          <Subscription
            key={subscription.id}
            subscription={subscription}
            onEdit={handleSubscriptionEdit}
          />
        ))}
      <SubscriptionAddEditModal
        subscription={selectedSubscription}
        isInEditMode={!!selectedSubscription}
        isInAddMode={openAddSubscriptionModal}
        onClose={handleModalClose}
      />
    </StyledCardRoot>
  );
};

export default observer(Subscriptions);
