import { useState } from "react";
import { Input } from "../../../../components/ui/input";
import { Button } from "../../../../components/ui/button";
import { Save, X } from "lucide-react";
import { useRenameFolder, useRenameSubFolder } from "../hooks";

type EditFolderNameProps = {
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  folderId: string;
  subFolderId?: string;
  currentName: string;
};

export const EditFolderName = ({
  setIsEditing,
  folderId,
  subFolderId,
  currentName,
}: EditFolderNameProps) => {
  const [editedName, setEditedName] = useState(currentName);

  const { mutate: renameFolder } = useRenameFolder();
  const { mutate: renameSubFolder } = useRenameSubFolder();

  return (
    <div className="w-full pt-1 flex items-center space-x-1">
      <Input
        autoFocus
        className="w-full"
        onClick={(e) => e.stopPropagation()}
        value={editedName}
        onChange={(e) => setEditedName(e.target.value)}
      />
      <Button
        className="py-0 px-2"
        variant="outline"
        onClick={(e) => {
          e.stopPropagation();
          setIsEditing(false);
        }}
      >
        <div className="h-4 w-4">
          <X className="h-4 w-4" />
        </div>
      </Button>
      <Button
        className="py-0 px-2"
        onClick={async (e) => {
          e.stopPropagation();

          if (editedName != null) {
            if (subFolderId != null) {
              await renameSubFolder({
                folderId,
                subFolderId,
                newName: editedName,
              });
            } else {
              await renameFolder({
                folderId,
                newName: editedName,
              });
            }

            setIsEditing(false);
          }
        }}
        disabled={editedName == null || editedName.length === 0}
      >
        <div className="h-4 w-4">
          <Save className="h-4 w-4" />
        </div>
      </Button>
    </div>
  );
};
