import React, { useState } from "react";
import clsx from "clsx";
import moment from "moment";
import { styled } from "@mui/system";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import useStores from "../../../useStores";
import CommunicationEntryModal from "./CommunicationEntryModal";
import communicationEntryTypes from "../../../utils/constants/communicationEntryTypes";
import Loading from "../../../shared/Loading";

const StyledCardContent = styled(CardContent)(() => ({
  padding: 0,
}));

const StyledDateTypography = styled(Typography)(() => ({
  whiteSpace: "nowrap",
}));

const StyledLinkTypography = styled(Typography)(() => ({
  cursor: "pointer",
}));

function CommunicationEntries({ className, ...rest }) {
  const { patientStore } = useStores();
  const [selectedCommunicationEntry, setSelectedCommunicationEntry] =
    useState(null);

  if (
    patientStore.patient === null ||
    typeof patientStore.patient === "undefined"
  ) {
    return <Loading />;
  }

  return (
    <Card {...rest}>
      <CardHeader title="Communicatie" />
      <Divider />
      <Divider />
      <StyledCardContent>
        <List disablePadding>
          {patientStore.patient.communicationEntries.map(
            (communicationEntry, i) => (
              <ListItem
                divider={
                  i < patientStore.patient.communicationEntries.length - 1
                }
                key={communicationEntry.id}
              >
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="h6">
                      {communicationEntry.description}
                    </Typography>
                  }
                  secondary={
                    <StyledLinkTypography
                      variant="body2"
                      onClick={() =>
                        setSelectedCommunicationEntry(communicationEntry)
                      }
                    >
                      bekijk{" "}
                      {communicationEntry.$type ===
                      communicationEntryTypes.EMAIL_COMMUNICATION_ENTRY
                        ? "email"
                        : "sms"}
                    </StyledLinkTypography>
                  }
                />
                <StyledDateTypography variant="body2">
                  {moment(communicationEntry.sentAt).fromNow()} |
                  {moment(communicationEntry.sentAt).format("DD-MM-YYYY HH:mm")}
                </StyledDateTypography>
              </ListItem>
            )
          )}
        </List>
      </StyledCardContent>
      <CommunicationEntryModal
        communicationEntry={selectedCommunicationEntry}
        open={!!selectedCommunicationEntry}
        onClose={() => setSelectedCommunicationEntry(null)}
      />
    </Card>
  );
}

export default CommunicationEntries;
