import React, { useState } from "react";
import { TableCell, TableRow } from "@mui/material";
import { observer } from "mobx-react";
import Button from "@mui/material/Button";
import { trackPromise } from "react-promise-tracker";
import areas from "../../utils/constants/areas";
import useStores from "../../useStores";
import UserSettingsModal from "./UserSettingsModal";

const UserTableRow = ({ user }) => {
  const [isUserSettingsModalOpen, setIsUserSettingsModalOpen] = useState(false);
  const { adminStore } = useStores();

  const handleSave = (settings) => {
    setIsUserSettingsModalOpen(false);
    const updateData = {
      roles: Array.from(settings.roles),
      wireUserId: settings.wireUserId,
      epdId: settings.epdId,
      bigRegistrationCode: settings.bigRegistrationCode,
    };
    trackPromise(
      adminStore.updateUser(user.id, updateData).then(adminStore.getAllUsers()),
      areas.ADMIN_TABLE
    );
  };

  return (
    <TableRow>
      <TableCell>{user.email}</TableCell>
      <TableCell>{user.roles.join(", ")}</TableCell>
      <TableCell align="right">
        <Button
          onClick={() => setIsUserSettingsModalOpen(true)}
          variant="outlined"
        >
          User Settings Aanpassen
        </Button>
        <UserSettingsModal
          open={isUserSettingsModalOpen}
          user={user}
          onSave={handleSave}
          onClose={() => setIsUserSettingsModalOpen(false)}
        />
      </TableCell>
    </TableRow>
  );
};

export default observer(UserTableRow);
