import axios from "axios";
import { useMutation } from "react-query";

export const useDownloadCohortStudyReport = (
  onSuccess: (data: any) => void
) => {
  const mutation = useMutation({
    mutationFn: async ({ epdPatientIds }: { epdPatientIds: number[] }) => {
      const res = await axios.post(
        `cohortstudyadmin/export`,
        { epdPatientIds },
        {
          responseType: "blob",
        }
      );
      return res.data;
    },
    onSuccess: onSuccess,
  });

  return mutation;
};
