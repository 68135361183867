import { ColumnDef } from "@tanstack/react-table";
import { MainListPatient } from "../../types";
import { useTranslation } from "react-i18next";

export const NumberOfRegularConsultsColName = "Number of Regular Consults";

export const NumberOfRegularConsultsCol: ColumnDef<MainListPatient> = {
  id: NumberOfRegularConsultsColName,
  accessorKey: "numberOfRegularConsults",
  size: 100,
  minSize: 10,
  header: () => {
    const { t } = useTranslation();
    return <p>{t("case-load-manager-column-number-of-regular-consults")}</p>;
  },
  cell: ({ getValue }) => {
    return (
      <p className="whitespace-pre-wrap break-all py-2 px-4">
        {getValue() as string}
      </p>
    );
  },
};
