import { BotMessageSquare } from 'lucide-react';
import { useIsEnabled } from '../../../feature-management/useIsEnabled';
import { Popover, PopoverTrigger, PopoverContent } from '../../../components/ui/popover';
import { ChatMessageList } from './chat/ChatMessageList';
import { ChatMessageContainer } from './chat/ChatMessageContainer';
import { ChatMessageBubble } from './chat/ChatMessageBubble';
import { ChatMessageMarkdown } from './chat/ChatMessageMarkdown';
import { aiMessagesToMessages, messageContentToString, messagesToAiMessages } from './utils';
import { useEffect, useRef, useState } from 'react';
import { SendMessageForm } from './sendMessage/SendMessageForm';
import { ChatHistoryButton } from './history/ChatHistoryButton';
import { useChat } from 'ai/react';
import { useMsal } from '@azure/msal-react';
import { getAccessToken } from '../../../utils/getAccessToken';
import { PublicClientApplication } from '@azure/msal-browser';

const DEFAULT_STARTER_QUESTIONS = [
  'Welke cognitieve herstructureringstechnieken zijn nuttig voor cliënten met negatieve denkpatronen?',
  'Welke strategieën zijn effectief voor het verbeteren van de therapietrouw bij cliënten?',
  'Wat zijn de best practices voor het behandelen van een depressie?',
  'Kun je uitleggen hoe exposure-therapie werkt bij angststoornissen?',
  'Kun je een effectieve ontspanningsoefening aanbevelen voor cliënten met stress?',
];

export const LearningAssistantButton = () => {
  const { data: isLearningAssistantEnabled } = useIsEnabled('EnableLearningAssistant');
  const isFirstRender = useRef(true);

  const [threadId, setThreadId] = useState<string | null>(null);

  const [accessToken, setAccessToken] = useState<string | null>(null);

  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const getLatestAccessToken = async () => {
    const token = await getAccessToken(instance as PublicClientApplication, activeAccount);
    setAccessToken(token);
  };

  useEffect(() => {
    getLatestAccessToken();
  }, [activeAccount]);

  const {
    messages: aiMessages,
    append,
    isLoading,
  } = useChat({
    api: 'https://ipractice-test.academy/api/v1/assistants/learning/completions',
    headers: {
      authorization: `Bearer ${localStorage.getItem('ipractice-academy-token') ?? 'unknown'}`,
    },
    // body: {
    //   threadId: threadId,
    // },
    initialMessages: [
      {
        content: 'Hello from assistant!',
        id: 'initial',
        role: 'assistant',
      },
      ...messagesToAiMessages([]),
    ],
  });

  const messages = aiMessagesToMessages(aiMessages);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    }

    return () => {
      isFirstRender.current = true;
    };
  }, []);

  if (!isLearningAssistantEnabled) {
    return <></>;
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <div className="absolute bottom-0 shadow-lg right-0 h-12 w-12 rounded-full bg-ipractice-blue text-white flex items-center justify-center cursor-pointer">
          <BotMessageSquare className="w-6 h-6" />
        </div>
      </PopoverTrigger>
      <PopoverContent
        className="z-[999999] bg-slate-50 p-6 min-w-[50vw] h-[85vh]"
        side="top"
        align="end"
      >
        <div className="flex flex-col h-full w-full justify-between">
          <div>
            <div className="flex items-center justify-between w-full pb-2 mb-2 border-b border-slate-200">
              <div className="flex items-center space-x-2">
                <BotMessageSquare className="w-6 h-6" />
                <h1 className="text-xl font-bold">Learning Assistant</h1>
              </div>

              <ChatHistoryButton setThreadId={setThreadId} />
            </div>
            <ChatMessageList
              threadId={threadId}
              handleSendQuestion={(text) =>
                append({
                  content: text,
                  role: 'user',
                })
              }
              isBeginningOfChat={(messages ?? []).length === 0}
              isStreaming={isLoading}
              pendingMessage={undefined}
              starterQuestions={DEFAULT_STARTER_QUESTIONS}
            >
              {(messages ?? []).map(({ message, metadata }, index) => {
                return (
                  <ChatMessageContainer
                    key={`chat-message-${index}`}
                    message={message}
                    metadata={metadata}
                  >
                    <ChatMessageBubble
                      copyableText={
                        index === (messages ?? []).length
                          ? undefined
                          : messageContentToString(message)
                      }
                      hasFeedback={message.messageFeedback && message.messageFeedback.length > 0}
                      messageType={message.role}
                      shouldAnimateIn={!isFirstRender && (message.role === 'user' || index === 0)}
                    >
                      <ChatMessageMarkdown>{messageContentToString(message)}</ChatMessageMarkdown>
                    </ChatMessageBubble>
                  </ChatMessageContainer>
                );
              })}
            </ChatMessageList>
          </div>

          <div className="flex-shrink-1 sticky bottom-0 z-20 flex w-full max-w-screen-lg items-center justify-center self-end text-slate-900 lg:mx-auto">
            <SendMessageForm
              handleSendQuestion={(text) => {
                append({
                  content: text,
                  role: 'user',
                });
              }}
              loading={isLoading}
            />
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
