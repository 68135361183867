import { GetWeeklyPatientReferralStatisticsQueryResponse } from "../../../../types";
import { Skeleton } from "../../../../../../components/ui/skeleton";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../../../../../components/ui/chart";
import { Label, Pie, PieChart } from "recharts";

const CurrentWeekReferralsChart = ({
  weeklyStatistics,
}: {
  weeklyStatistics: GetWeeklyPatientReferralStatisticsQueryResponse | undefined;
}) => {
  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#A569BD",
    "#F1948A",
    "#45B39D",
    "#5D6D7E",
    "#922B21",
    "#7D3C98",
  ];

  const statusCounts = sumStatusCounts(weeklyStatistics?.reportItems || []);
  const data = Object.keys(statusCounts)
    .map((key, index) => ({
      name: key,
      value: statusCounts[key],
      fill: COLORS[index % COLORS.length],
    }))
    .filter((entry) => entry.name !== "$type");

  function sumStatusCounts(reportItems: any[]): { [key: string]: number } {
    let sums: { [key: string]: number } = {};
    for (let item of reportItems) {
      if (item.statusCounts) {
        for (let status in item.statusCounts) {
          if (sums[status]) {
            sums[status] += item.statusCounts[status];
          } else {
            sums[status] = item.statusCounts[status];
          }
        }
      }
    }
    return sums;
  }

  if (!weeklyStatistics)
    return <Skeleton className="w-full h-[250px] rounded mt-2 bg-slate-100" />;

  return (
    <ChartContainer
      config={{
        ReachOut: {
          label: "Reach Out",
        },
        RegisterPatientAndIntake: {
          label: "Register patient & intakes",
        },
        AwaitingPatientApproval: {
          label: "Awaiting patient approval",
        },
      }}
      className="mx-auto aspect-square max-h-[250px]"
    >
      <PieChart>
        <ChartTooltip
          cursor={false}
          content={<ChartTooltipContent hideLabel />}
        />
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          innerRadius={60}
          strokeWidth={5}
        >
          <Label
            content={({ viewBox }) => {
              if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                return (
                  <text
                    x={viewBox.cx}
                    y={viewBox.cy}
                    textAnchor="middle"
                    dominantBaseline="middle"
                  >
                    <tspan
                      x={viewBox.cx}
                      y={viewBox.cy}
                      className="fill-foreground text-3xl font-bold"
                    >
                      {data.reduce((acc, entry) => acc + entry.value, 0)}
                    </tspan>
                    <tspan
                      x={viewBox.cx}
                      y={(viewBox.cy || 0) + 24}
                      className="fill-muted-foreground"
                    >
                      Total
                    </tspan>
                  </text>
                );
              }
            }}
          />
        </Pie>
      </PieChart>
    </ChartContainer>
  );
};

export default CurrentWeekReferralsChart;
