import React, { useEffect } from "react";
import { useNavigate, useParams, Navigate } from "react-router-dom";
import { Tabs, Divider, Tab, colors, Badge } from "@mui/material";
import { styled } from "@mui/system";
import { observer } from "mobx-react";
import Button from "@mui/material/Button";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import moment, { now } from "moment";
import useStores from "../../useStores";
import Loading from "../../shared/Loading";
import Header from "../../shared/Header";
import dossierType from "../../utils/constants/dossierType";
import OnboardingTab from "./onboardingTab";
import TreatmentTab from "./treatmentTab";
import FinanceTab from "./financeTab";
import DetailsTab from "./detailsTab";
import CopyToClipboardWithPopover from "../../shared/CopyToClipboardWithPopover";
import Alert from "./Alert";
import { getPraktijkdataDossierUrl } from "../../utils/urlHelpers";
import StyledRoot from "../../shared/StyledRootDiv";
import { StartNewTreatmentDossierWarning } from "./onboardingTab/adhdStartTreatment/startNewTreatmentDossierWarning";

const StyledTabs = styled(Tabs)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const StyledTab = styled(Tab)(({ theme, isActive }) => ({
  color: isActive ? colors.blue[600] : theme.palette.grey[500],
}));

const StyledDivider = styled(Divider)(() => ({
  backgroundColor: colors.grey[300],
}));

const StyledContentTab = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  marginLeft: 0,
}));

const StyledOpenInNewIcon = styled(OpenInNewIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-dot": {
    backgroundColor: colors.blue[600],
    width: 9,
    height: 9,
    borderRadius: "50%",
  },
}));

const StyledTabTextSpan = styled("span")(() => ({
  marginLeft: 10,
}));

const DossierGgzPage = () => {
  const { patientStore, onboardingStore } = useStores();
  const { patientId, dossierId, tab } = useParams();
  const navigate = useNavigate();
  const { patient } = patientStore;
  const { onboarding } = onboardingStore;

  const tabs = [
    {
      value: "onboarding",
      label: "Onboarding",
    },
    {
      value: "treatment",
      label: "In Behandeling",
    },
    {
      value: "finance",
      label: "Financiële afhandeling",
    },
    {
      value: "details",
      label: "Gegevens",
    },
  ];

  useEffect(() => {
    patientStore.fetchPatientInformation(patientId, parseInt(dossierId));
    onboardingStore.fetchOnboarding(dossierType.GGZ, dossierId);

    return function cleanup() {
      patientStore.reset();
      onboardingStore.reset();
    };
  }, [patientId, dossierId, patientStore, onboardingStore]);

  const handleTabsChange = (event, value) => {
    navigate(`/patient/${patientId}/${dossierType.GGZ}/${dossierId}/${value}`);
  };

  if (
    patientStore.patient == null ||
    typeof patientStore.patient === "undefined" ||
    patientStore.dossier == null ||
    onboardingStore.onboarding == null
  ) {
    return <Loading />;
  }

  const getTabValueForCurrentDossierState = () => {
    if (patientStore.dossier.completedAt) {
      return "finance";
    }

    if (
      onboardingStore.onboarding.intakeConsult === null ||
      moment(onboardingStore.onboarding.intakeConsult.start).isAfter(now())
    ) {
      return "onboarding";
    }

    return "treatment";
  };

  if (!tab) {
    return (
      <Navigate
        to={`/patient/${patientId}/${
          dossierType.GGZ
        }/${dossierId}/${getTabValueForCurrentDossierState()}`}
      />
    );
  }

  return (
    <StyledRoot>
      <Header
        pageTitle="cliënt iPractice"
        title={`${patientStore.patient.firstName} ${patientStore.patient.lastName} (${patientStore.fullEpdId})`}
      >
        <StyledButton
          size="small"
          href={getPraktijkdataDossierUrl(patientStore.dossier)}
          target="_blank"
          variant="contained"
          rel="noopener noreferrer"
        >
          <StyledOpenInNewIcon />
          Open in Praktijkdata
        </StyledButton>{" "}
        <br />
        Email:{" "}
        <CopyToClipboardWithPopover value={patientStore.patient.email}>
          {patientStore.patient.email}
        </CopyToClipboardWithPopover>{" "}
        <br />
        Taal: {patientStore.patient.language.languageCode}
      </Header>

      <StartNewTreatmentDossierWarning
        dossierId={dossierId}
        patient={patient}
        lastDossierEpdFileId={Math.max(
          ...patient.dossiers.map((x) => x.epdFileId)
        )}
        isWelcomeFlowFinished={onboarding.iWelcomeProgress.status == "Finished"}
      />

      {getTabValueForCurrentDossierState() === "treatment" &&
        patientStore.patient.wireUser === null && (
          <Alert message="Voeg Wire handle toe." />
        )}
      {getTabValueForCurrentDossierState() === "treatment" &&
        onboardingStore.onboarding.onboardingStatus !== "Completed" && (
          <Alert message="Onboarding niet afgerond, rond onboarding af." />
        )}

      <StyledTabs
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={tab}
        variant="scrollable"
      >
        {tabs.map((currentTab) => (
          <StyledTab
            key={currentTab.value}
            isActive={getTabValueForCurrentDossierState() === currentTab.value}
            label={
              getTabValueForCurrentDossierState() === currentTab.value ? (
                <span>
                  <StyledBadge
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    variant="dot"
                  />
                  <StyledTabTextSpan>{currentTab.label}</StyledTabTextSpan>
                </span>
              ) : (
                currentTab.label
              )
            }
            value={currentTab.value}
          />
        ))}
      </StyledTabs>
      <StyledDivider />
      <StyledContentTab>
        {tab === "onboarding" && <OnboardingTab />}
        {tab === "treatment" && <TreatmentTab />}
        {tab === "finance" && <FinanceTab />}
        {tab === "details" && <DetailsTab />}
      </StyledContentTab>
    </StyledRoot>
  );
};

export default observer(DossierGgzPage);