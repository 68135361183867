import { useGetUnsuccessfullySentiWelcomeAutomaticEmails } from "./hooks";
import { AlertCircle } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../../components/ui/tooltip";
import moment from "moment";

export const UnsuccessfullySentEmails = ({
  dossierId,
}: {
  dossierId: number;
}) => {
  const { data } = useGetUnsuccessfullySentiWelcomeAutomaticEmails(dossierId);

  if (data == undefined || data.length === 0) return <></>;

  return (
    <TooltipProvider delayDuration={100}>
      <Tooltip>
        <TooltipTrigger>
          <div
            className="flex items-center space-x-1 cursor-help w-full mt-2 rounded text-red-600"
            onClick={() => {}}
          >
            <AlertCircle className="w-4 h-4" />
            <p className="text-xs">
              Sommige e-mails konden niet worden bezorgd.
            </p>
          </div>
        </TooltipTrigger>
        <TooltipContent className="text-sm z-[999999]">
          <ul className="list-disc list-inside space-y-1">
            {data.map((log, index) => {
              return (
                <li key={index}>
                  Een e-mail werd op{" "}
                  {moment(log.emailSentOn).format("DD-MM-YYYY HH:mm")} verzonden
                  naar {log.recipientEmail}, maar is mislukt:{" "}
                  {log.failureReason}
                </li>
              );
            })}
          </ul>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
