import { StatisticsFacetedFilter } from "../statisticsFacetedFilter";
import { useGetEpdLocations } from "../../../hooks";
import { useState } from "react";
import { BacklogReportByStatusTable } from "./backlogReportByStatusTable";
import { BacklogReportByNextStepTable } from "./backlogReportByNextStepTable";
import { WorkingViewReport } from "../working-view-report/index";
import { NoChangeInDaysReport } from "../no-change-in-days-report/index";

export const BacklogReport = () => {
  const { data: epdLocations } = useGetEpdLocations();
  const [locationIds, setLocationIds] = useState<string[]>([]);

  return (
    <main>
      <section className="w-full flex items-center justify-between">
        <div className="w-1/2">
          <h1 className="text-2xl font-bold">
            Backlog report{" "}
            <span className="font-medium">
              - CRM / Patient Referral, by Status
            </span>
          </h1>
          <p>
            The number referrals in non-closed statuses, based on their Status
          </p>
        </div>

        <div className="w-[300px]">
          <StatisticsFacetedFilter
            title="Location"
            options={
              epdLocations?.map((d) => ({
                label: d.name,
                value: d.id,
              })) || []
            }
            filters={locationIds}
            setFilters={setLocationIds}
          />
        </div>
      </section>

      <BacklogReportByStatusTable locationIds={locationIds} />
      <BacklogReportByNextStepTable locationIds={locationIds} />

      <WorkingViewReport />

      <NoChangeInDaysReport />
    </main>
  );
};
