import React, { useEffect, useState } from "react";
import moment from "moment";
import { styled } from "@mui/system";
import {
  Card,
  IconButton,
  MenuItem,
  Modal,
  Divider,
  CardActions,
  Button,
  CardHeader,
  CardContent,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { Formik } from "formik";
import * as Yup from "yup";
import { observer } from "mobx-react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import useStores from "../../useStores";
import ConfirmDialog from "../../shared/ConfirmDialog";

const StyledCardRoot = styled(Card)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  outline: "none",
  boxShadow: theme.shadows[20],
  width: 700,
  maxHeight: "100%",
  overflowY: "auto",
  maxWidth: "100%",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const StyledDateTimePicker = styled(DateTimePicker)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const StyledCardActions = styled(CardActions)(() => ({
  justifyContent: "flex-end",
}));

const ConsultAddEditModal = ({
  consult,
  isInAddMode,
  isInEditMode,
  onClose,
  isFirstConsult,
}) => {
  const { consultsStore, employeesStore, patientStore } = useStores();
  const [confirmDeleteIsOpen, setConfirmDeleteIsOpen] = useState(false);
  const [confirmCloseIsOpen, setConfirmCloseIsOpen] = useState(false);

  useEffect(() => {
    employeesStore.fetchEmployees();
  }, [employeesStore]);

  const handleDelete = () => {
    setConfirmDeleteIsOpen(false);
    consultsStore.deleteConsult(patientStore.dossier.id, consult.id);
    onClose();
  };

  const close = () => {
    setConfirmCloseIsOpen(false);
    onClose();
  };

  if (!isInAddMode && !isInEditMode) {
    return null;
  }

  const getNotesTemplate = () => {
    return isFirstConsult
      ? "-Request for help/goal:\n-Issues (complaints/opportunities):\n-Circumstances:\n-Personal style (name at least 1 strength):"
      : "-Discussed during session:\n-Advice/tip/assignment:\n-Next steps agreed upon:\n-Other:";
  };

  return (
    <Modal
      onClose={() => setConfirmCloseIsOpen(true)}
      open={isInAddMode || isInEditMode}
    >
      <StyledCardRoot>
        <Formik
          initialValues={
            isInAddMode
              ? {
                  dosserId: patientStore.dossier.id,
                  consultTitle: "Online consult",
                  notes: getNotesTemplate(),
                  employeeId: "",
                  date: moment().toDate(),
                  finishedAt: moment().add(30, "minutes").toDate(),
                }
              : consult
          }
          onSubmit={(values) => {
            if (isInAddMode) {
              consultsStore.createConsult(values, patientStore.dossier.id);
            } else {
              consultsStore.editConsult(values);
            }
            onClose();
          }}
          validationSchema={Yup.object().shape({
            consultTitle: Yup.string().required(),
            employeeId: Yup.string().required(),
            notes: Yup.string().nullable(),
            date: Yup.date().required(),
            finishedAt: Yup.date().required().min(Yup.ref("date")),
          })}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              dirty,
              setFieldValue,
            } = props;
            return (
              <form autoComplete="off" noValidate>
                <CardHeader
                  title={isInAddMode ? "Nieuwe afspraak" : "Wijzig afspraak"}
                />
                <Divider />
                <CardContent>
                  <StyledTextField
                    autoFocus
                    fullWidth
                    label="Titel"
                    name="consultTitle"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.consultTitle}
                    variant="outlined"
                    error={errors.consultTitle && touched.consultTitle}
                    helperText={
                      errors.consultTitle &&
                      touched.consultTitle &&
                      errors.consultTitle
                    }
                  />
                  <StyledTextField
                    required
                    select
                    fullWidth
                    label="Behandelaar"
                    name="employeeId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.employeeId}
                    variant="outlined"
                    error={errors.employeeId && touched.employeeId}
                    helperText={
                      errors.employeeId &&
                      touched.employeeId &&
                      errors.employeeId
                    }
                  >
                    {employeesStore.employees.map((employee) => (
                      <MenuItem key={employee.id} value={employee.id}>
                        {`${employee.firstName} ${employee.lastName}`}
                      </MenuItem>
                    ))}
                  </StyledTextField>
                  <StyledTextField
                    fullWidth
                    label="Notes"
                    name="notes"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.notes}
                    variant="outlined"
                    multiline
                    error={errors.notes && touched.notes}
                    helperText={errors.notes && touched.notes && errors.notes}
                  />
                  <StyledDateTimePicker
                    required
                    ampm={false}
                    format="DD/MM/YYYY  HH:mm"
                    inputVariant="outlined"
                    fullWidth
                    name="date"
                    label="Starttijd"
                    onChange={(changedMoment) => {
                      setFieldValue("date", changedMoment.toDate());
                      setFieldValue(
                        "finishedAt",
                        changedMoment.clone().add(30, "minutes")
                      );
                    }}
                    onBlur={handleBlur}
                    value={values.date}
                    error={errors.date && touched.date}
                    helperText={errors.date && touched.date && errors.date}
                  />
                  <StyledDateTimePicker
                    required
                    ampm={false}
                    format="DD/MM/YYYY  HH:mm"
                    inputVariant="outlined"
                    fullWidth
                    name="finishedAt"
                    label="Eindtijd"
                    onChange={(changedMoment) => {
                      setFieldValue("finishedAt", changedMoment.toDate());
                    }}
                    onBlur={handleBlur}
                    value={values.finishedAt}
                    error={errors.finishedAt && touched.finishedAt}
                    helperText={
                      errors.finishedAt &&
                      touched.finishedAt &&
                      errors.finishedAt
                    }
                  />
                </CardContent>
                <Divider />
                <StyledCardActions>
                  {isInEditMode && (
                    <IconButton
                      edge="start"
                      onClick={() => setConfirmDeleteIsOpen(true)}
                      size="large"
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                  <ConfirmDialog
                    question="Weet je zeker dat je het consult wil verwijderen?"
                    isOpen={confirmDeleteIsOpen}
                    onConfirm={handleDelete}
                    onCancel={() => setConfirmDeleteIsOpen(false)}
                  />

                  <Button
                    onClick={() => setConfirmCloseIsOpen(true)}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    onClick={handleSubmit}
                    variant="contained"
                    disabled={isSubmitting || !dirty}
                  >
                    Opslaan
                  </Button>
                </StyledCardActions>
              </form>
            );
          }}
        </Formik>
        <ConfirmDialog
          question="Weet je zeker dat je dit scherm wil sluiten?"
          isOpen={confirmCloseIsOpen}
          onConfirm={close}
          onCancel={() => setConfirmCloseIsOpen(false)}
        />
      </StyledCardRoot>
    </Modal>
  );
};

export default observer(ConsultAddEditModal);
