import { AlertCircle } from "lucide-react";
import { useNavigate } from "react-router-dom";

export const NewTreatmentDossierInfo = ({
  dossier,
  patient,
}: {
  dossier: {
    lookupGuid: string;
  };
  patient: {
    id: number;
    dossiers: {
      id: number;
      succeedsPreviousDossier:
        | {
            lookupGuid: string;
          }
        | undefined;
    }[];
  };
}) => {
  const navigate = useNavigate();

  const succeededByDossiers = patient.dossiers.filter(
    (d) => d.succeedsPreviousDossier?.lookupGuid === dossier.lookupGuid
  );

  if (succeededByDossiers.length === 0) return <></>;

  return (
    <div className="text-green-700 dark:text-green-500 bg-green-100 dark:bg-green-950 p-4 border-b flex items-center space-x-4 text-sm">
      <div className="h-5 w-5">
        <AlertCircle className="h-5 w-5" />
      </div>
      <p>
        Dit ADHD-dossier heeft een daaropvolgend GGZ-dossier geopend.{" "}
        <span
          className="font-bold cursor-pointer hover:underline"
          onClick={() => {
            navigate({
              pathname: `/patient/${patient.id}/DossierGgz/${succeededByDossiers[0].id}/onboarding`,
            });
          }}
        >
          Klik hier
        </span>{" "}
        om het nieuwe dossier te bekijken.
      </p>
    </div>
  );
};
