import { FolderSync, Globe, Mail } from "lucide-react";
import { ReferralDetails, RegistrationStatusType } from "../types";
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
} from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";

export const RecommendedOtherPractices = ({
  referral,
}: {
  referral: ReferralDetails;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const allowedStatuses: RegistrationStatusType[] = [
    "New",
    "ReachOut",
    "Rejected",
  ];

  if (
    !(referral.recommendedTransferPractices?.length > 0) ||
    !allowedStatuses.includes(referral.status)
  ) {
    return <></>;
  }

  return (
    <div>
      <div className="mt-2 bg-blue-50 dark:bg-slate-900 text-blue-500 dark:text-muted-foreground rounded p-3 flex space-x-3 text-sm">
        <div className="h-5 w-5">
          <FolderSync className="h-5 w-5" />
        </div>
        <div>
          <p
            className="cursor-pointer hover:underline"
            onClick={() => setIsOpen(true)}
          >
            Patient to be transferred? View iPractice's AI recommendation for
            relevant practices
          </p>
        </div>
      </div>

      <Dialog
        open={isOpen}
        onOpenChange={(newValue) => {
          setIsOpen(newValue);
        }}
      >
        <DialogContent className="p-8 max-h-[75vh] overflow-y-auto min-w-[80vw]">
          <div className="grid grid-cols-2 gap-4">
            {referral.recommendedTransferPractices
              .toSorted((a, b) => b.score - a.score)
              .map((r) => {
                return (
                  <div className="bg-muted rounded-lg p-8" key={r.name}>
                    <h2 className="font-bold text-lg">
                      {r.name}
                      <span className="font-normal">
                        {r.city ? `, ${r.city}` : ``}
                      </span>
                    </h2>

                    <div
                      className={`flex items-center space-x-2 text-link ${
                        r.website ? "visible" : "hidden"
                      }`}
                    >
                      <div className="w-4 h-4">
                        <Globe className="w-4 h-4" />
                      </div>
                      <a
                        href={r.website}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="cursor-pointer hover:underline"
                      >
                        {r.website}
                      </a>
                    </div>
                    <div
                      className={`flex items-center space-x-2 ${
                        r.email ? "visible" : "hidden"
                      }`}
                    >
                      <div className="w-4 h-4">
                        <Mail className="w-4 h-4" />
                      </div>
                      <p>{r.email}</p>
                    </div>
                    <div
                      className={`text-xs pt-2 ${
                        r.therapyTypes?.length > 0 ? "visible" : "hidden"
                      }`}
                    >
                      <p>
                        <span className="font-bold">Therapy Types:</span>{" "}
                        {r.therapyTypes?.join(", ")}
                      </p>
                    </div>

                    <div className="text-xs pt-2">
                      <p>
                        <span className="font-bold">Why:</span> {r.reasoning}
                      </p>
                    </div>

                    <div className="text-xs pt-2">
                      {r.waitingList && (
                        <p>
                          <span className="font-bold">Waiting List:</span> {r.waitingList}
                        </p>
                      )}
                      {r.waitingListUrl && (
                        <p>
                          <span className="font-bold">Waiting List Url:</span> {r.waitingListUrl}
                        </p>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>

          <DialogFooter>
            <Button variant="outline" onClick={() => setIsOpen(false)}>
              Close
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};