import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { styled } from "@mui/system";
import {
  Divider,
  Collapse,
  Button,
  Typography,
  Grid,
  Switch,
  Select,
  MenuItem,
  colors,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import ClearIcon from "@mui/icons-material/Clear";
import tags from "../../utils/constants/tags/tags";
import useStores from "../../useStores";
import {
  getTimeslotsAfterApplyingAllTags,
  createTagObject,
  getEmployeesAfterApplyingAllTags,
} from "./tagHelpers";
import tagEnums from "../../utils/constants/tags/tagEnums";
import emdrEnum from "../../utils/constants/tags/emdrEnum";
import experienceEnum from "../../utils/constants/tags/experienceEnum";
import schemaTherapyEnum from "../../utils/constants/tags/schemaTherapyEnum";
import psychologistNameEnum from "../../utils/constants/psychologistNameEnum";
import statusEnum from "../../utils/constants/tags/statusEnum";
import { PsychologistFilter } from "./filters/psychologistFilter";

const StyledClearIcon = styled(ClearIcon)(() => ({
  color: colors.blueGrey[400],
}));

const StyledClearTypography = styled(Typography)(() => ({
  color: colors.blueGrey[400],
}));

const StyledTopDivider = styled(Divider)(() => ({
  marginTop: 8,
}));

const StyledBottomDivider = styled(Divider)(() => ({
  marginTop: 8,
}));

const StyledTopFilterGrid = styled(Grid)(() => ({
  marginBottom: 8,
  marginTop: 8,
}));

const CalendarFilter = ({
  defaultFilters,
  handleChangeEmployees,
  handleChangeLocations,
  getDate,
}) => {
  const { calendarStore } = useStores();
  const [filterExpanded, setFilterExpanded] = useState(false);

  const [selectedPsychologistId, setSelectedPsychologistId] = useState("");
  const [selectedLanguages, setSelectedLanguages] = useState(
    defaultFilters?.languages ?? []
  );
  const [selectedLocations, setSelectedLocations] = useState(
    defaultFilters?.locations ?? []
  );
  const [selectedTeams, setSelectedTeam] = useState([]);
  const [selectedGender, setSelectedGender] = useState([]);
  const [isEmdr, setIsEmdr] = useState(false);
  const [isExperience, setIsExperience] = useState(false);
  const [isSchemaTherapy, setIsSchemaTherapy] = useState(false);

  const [allAppliedTags, setAllAppliedTags] = useState([]);
  const [selectedPsychologist, setSelectedPsychologist] = useState(
    calendarStore.selectedEmployees
  );

  const applyAllTags = (appliedTags) => {
    calendarStore.setSelectedTimeslots(
      getTimeslotsAfterApplyingAllTags(
        calendarStore.timeslots,
        calendarStore.tags,
        appliedTags
      )
    );

    setSelectedPsychologist(
      getEmployeesAfterApplyingAllTags(
        calendarStore.employees,
        calendarStore.tags,
        appliedTags
      )
    );
  };

  const resetFilters = () => {
    setSelectedLanguages([]);
    setSelectedLocations([]);
    setSelectedTeam([]);
    setSelectedGender([]);

    setIsExperience(false);
    setIsEmdr(false);
    setIsSchemaTherapy(false);
    setSelectedPsychologistId(psychologistNameEnum.EMPTY);

    setAllAppliedTags([]);
    applyAllTags([]);

    // We are defaulting to only showing active and accepting psychologists by default,
    // without the option to change this
    onChangeArrayFilter(tagEnums.STATUS, statusEnum.ACTIVE_ACCEPTING_CLIENTS);
  };

  // allAppliedTags should always be the size of the number of tagTypes in tagTypesEnum
  const onChangeArrayFilter = async (tagType, selectedTags) => {
    const tagToApply = createTagObject(tagType, selectedTags);
    const appliedTagOfChosenType = allAppliedTags.find(
      (appliedTag) => appliedTag.type === tagType
    );

    // if tag already exists as applied, either modify it or remove it
    if (appliedTagOfChosenType) {
      // if the tag type is one of these, we only need to modify the tag array
      if (
        [
          tagEnums.LANGUAGE,
          tagEnums.LOCATION,
          tagEnums.TEAM,
          tagEnums.GENDER,
        ].includes(appliedTagOfChosenType.type)
      ) {
        const appliedTagsWithSelectedTagRemoved = allAppliedTags.filter(
          (appliedTag) => appliedTag.type !== appliedTagOfChosenType.type
        );
        await setAllAppliedTags([
          ...appliedTagsWithSelectedTagRemoved,
          tagToApply,
        ]);
        applyAllTags([...appliedTagsWithSelectedTagRemoved, tagToApply]);

        return;
      }

      // if the tag is one of these, it must be removed from applied tags
      if (
        [
          tagEnums.EMDR,
          tagEnums.EXPERIENCE,
          tagEnums.SCHEMA_THERAPY_EXPERIENCE,
        ].includes(appliedTagOfChosenType.type)
      ) {
        await setAllAppliedTags(
          allAppliedTags.filter(
            (appliedTag) => appliedTag.type !== appliedTagOfChosenType.type
          )
        );
        applyAllTags(
          allAppliedTags.filter(
            (appliedTag) => appliedTag.type !== appliedTagOfChosenType.type
          )
        );

        return;
      }
    }

    // if the tag doesnt exist as selected, we can apply it now
    await setAllAppliedTags([...allAppliedTags, tagToApply]);

    // apply all applied tags
    applyAllTags([...allAppliedTags, tagToApply]);
  };

  const applyActiveAcceptingFilter = async () => {
    if (
      calendarStore.timeslotsInitialised &&
      calendarStore.timeslots.length > 0
    ) {
      await onChangeArrayFilter(
        tagEnums.STATUS,
        statusEnum.ACTIVE_ACCEPTING_CLIENTS
      );
    }
  };

  // We are defaulting to only showing active and accepting psychologists by default,
  // without the option to change this
  // Remember to apply this tag when clearing all tags as well
  useEffect(() => {
    applyActiveAcceptingFilter();
  }, [calendarStore.timeslots]);

  useEffect(() => {
    if (
      selectedLanguages.length === 0 &&
      allAppliedTags.filter(
        (appliedTag) => appliedTag.type === tagEnums.LANGUAGE
      ).length === 0
    ) {
      return;
    }
    onChangeArrayFilter(tagEnums.LANGUAGE, selectedLanguages);
  }, [selectedLanguages]);

  useEffect(() => {
    handleChangeLocations(selectedLocations);

    // make sure to avoid filtering on tags for location -> we take the actual appointment's location in Calendar.js
    onChangeArrayFilter(tagEnums.LOCATION, []);

    // selectable psychologists depend on the location
    // if a psy from Amsterdam was selected, but the location filter is now Eindhoven, we'd introduce an inconsistent state
    // ...safer to reset the selected psychologist when locations change
    setSelectedPsychologistId(psychologistNameEnum.EMPTY);
  }, [selectedLocations]);

  useEffect(() => {
    if (
      selectedTeams.length === 0 &&
      allAppliedTags.filter((appliedTag) => appliedTag.type === tagEnums.TEAM)
        .length === 0
    ) {
      return;
    }
    onChangeArrayFilter(tagEnums.TEAM, selectedTeams);
  }, [selectedTeams]);

  useEffect(() => {
    if (
      selectedGender.length === 0 &&
      allAppliedTags.filter((appliedTag) => appliedTag.type === tagEnums.GENDER)
        .length === 0
    ) {
      return;
    }
    onChangeArrayFilter(tagEnums.GENDER, selectedGender);
  }, [selectedGender]);

  useEffect(() => {
    // if isEmdr is off and not an applied filter already
    if (
      !isEmdr &&
      allAppliedTags.filter((appliedTag) => appliedTag.type === tagEnums.EMDR)
        .length === 0
    ) {
      return;
    }
    onChangeArrayFilter(tagEnums.EMDR, isEmdr ? emdrEnum.YES : emdrEnum.NO);
  }, [isEmdr]);

  useEffect(() => {
    // if isExperience is off and not an applied filter already
    if (
      !isExperience &&
      allAppliedTags.filter(
        (appliedTag) => appliedTag.type === tagEnums.EXPERIENCE
      ).length === 0
    ) {
      return;
    }
    onChangeArrayFilter(
      tagEnums.EXPERIENCE,
      isExperience ? experienceEnum.GZ : experienceEnum.BASIS
    );
  }, [isExperience]);

  useEffect(() => {
    // if isSchemaTherapy is off and not an applied filter already
    if (
      !isSchemaTherapy &&
      allAppliedTags.filter(
        (appliedTag) => appliedTag.type === tagEnums.SCHEMA_THERAPY_EXPERIENCE
      ).length === 0
    ) {
      return;
    }
    onChangeArrayFilter(
      tagEnums.SCHEMA_THERAPY_EXPERIENCE,
      isSchemaTherapy ? schemaTherapyEnum.YES : schemaTherapyEnum.NO
    );
  }, [isSchemaTherapy]);

  useEffect(() => {
    handleChangeEmployees(selectedPsychologistId);
  }, [selectedPsychologistId]);

  return (
    <>
      <StyledTopDivider />
      <StyledTopFilterGrid
        container
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <Grid item xs={2}>
          <Typography align="right">{"Language"}</Typography>
        </Grid>
        <Grid item xs={10}>
          <Grid spacing={1} container>
            {tags.LANGUAGE.map((language) => {
              return (
                <Grid key={language} item row="true">
                  <Button
                    variant={
                      selectedLanguages.indexOf(language) === -1
                        ? "outlined"
                        : "contained"
                    }
                    onClick={() =>
                      selectedLanguages.indexOf(language) === -1
                        ? setSelectedLanguages([...selectedLanguages, language])
                        : setSelectedLanguages(
                            selectedLanguages.filter(
                              (selectedLanguage) =>
                                selectedLanguage !== language
                            )
                          )
                    }
                  >
                    {language}
                  </Button>
                </Grid>
              );
            })}

            {selectedLanguages.length > 0 ? (
              <Button
                onClick={() => {
                  setSelectedLanguages([]);
                }}
              >
                <StyledClearIcon />

                <StyledClearTypography variant="button">
                  {"Clear"}
                </StyledClearTypography>
              </Button>
            ) : null}
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Typography align="right">{"Location"}</Typography>
        </Grid>
        <Grid item xs={10}>
          <Grid spacing={1} container>
            {tags.LOCATION.map((location) => (
              <Grid key={location} item row="true">
                <Button
                  variant={
                    selectedLocations.indexOf(location) === -1
                      ? "outlined"
                      : "contained"
                  }
                  onClick={() =>
                    selectedLocations.indexOf(location) === -1
                      ? setSelectedLocations([...selectedLocations, location])
                      : setSelectedLocations(
                          selectedLocations.filter(
                            (selectedLocation) => selectedLocation !== location
                          )
                        )
                  }
                >
                  {location}
                </Button>
              </Grid>
            ))}
            {selectedLocations.length > 0 ? (
              <Button
                onClick={() => {
                  setSelectedLocations([]);
                }}
              >
                <StyledClearIcon />

                <StyledClearTypography variant="button">
                  {"Clear"}
                </StyledClearTypography>
              </Button>
            ) : null}
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Typography align="right">{"Psychologist"}</Typography>
        </Grid>
        <Grid item xs={10}>
          <PsychologistFilter
            selectedPsychologistId={selectedPsychologistId}
            setSelectedPsychologistId={setSelectedPsychologistId}
            selectedPsychologist={selectedPsychologist}
          />
        </Grid>
      </StyledTopFilterGrid>

      <Collapse in={filterExpanded} timeout="auto">
        <Grid container alignItems="center" justifyContent="center" spacing={2}>
          <Grid item xs={2}>
            <Typography align="right">{"Team"}</Typography>
          </Grid>
          <Grid item xs={10}>
            <Grid spacing={1} container>
              {tags.TEAM.map((team) => (
                <Grid key={team} item row="true">
                  <Button
                    variant={
                      selectedTeams.indexOf(team) === -1
                        ? "outlined"
                        : "contained"
                    }
                    onClick={() =>
                      selectedTeams.indexOf(team) === -1
                        ? setSelectedTeam([...selectedTeams, team])
                        : setSelectedTeam(
                            selectedTeams.filter(
                              (selectedLocation) => selectedLocation !== team
                            )
                          )
                    }
                  >
                    {team}
                  </Button>
                </Grid>
              ))}
              {selectedTeams.length > 0 ? (
                <Button
                  onClick={() => {
                    setSelectedTeam([]);
                  }}
                >
                  <StyledClearIcon />

                  <StyledClearTypography variant="button">
                    {"Clear"}
                  </StyledClearTypography>
                </Button>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Typography align="right">{"Gender"}</Typography>
          </Grid>
          <Grid item xs={10} container spacing={1}>
            {tags.GENDER.map((gender) => (
              <Grid key={gender} item row="true">
                <Button
                  variant={
                    selectedGender.indexOf(gender) === -1
                      ? "outlined"
                      : "contained"
                  }
                  onClick={() =>
                    selectedGender.indexOf(gender) === -1
                      ? setSelectedGender([...selectedGender, gender])
                      : setSelectedGender(
                          selectedGender.filter(
                            (selectedGender) => selectedGender !== gender
                          )
                        )
                  }
                >
                  {gender}
                </Button>
              </Grid>
            ))}
            {selectedGender.length > 0 ? (
              <Button
                onClick={() => {
                  setSelectedGender([]);
                }}
              >
                <StyledClearIcon />

                <StyledClearTypography variant="button">
                  {"Clear"}
                </StyledClearTypography>
              </Button>
            ) : null}
          </Grid>
          <Grid item xs={2}>
            <Typography align="right">{"GZ"}</Typography>
          </Grid>
          <Grid item xs={10}>
            <Switch
              checked={isExperience}
              onChange={() => setIsExperience(!isExperience)}
              name="GZ_Checkbox"
              inputProps={{ "aria-label": "GZ Checkbox" }}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography align="right">{"Schema Therapy"}</Typography>
          </Grid>
          <Grid item xs={10}>
            <Switch
              checked={isSchemaTherapy}
              onChange={() => setIsSchemaTherapy(!isSchemaTherapy)}
              name="SchemaTherapy_Checkbox"
              inputProps={{ "aria-label": "Schema Therapy Checkbox" }}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography align="right">{"EMDR"}</Typography>
          </Grid>
          <Grid item xs={10}>
            <Switch
              checked={isEmdr}
              onChange={() => setIsEmdr(!isEmdr)}
              name="EMDR_Checkbox"
              inputProps={{ "aria-label": "EMDR Checkbox" }}
            />
          </Grid>
        </Grid>
      </Collapse>

      <Grid container justifyContent="center">
        <Button onClick={() => setFilterExpanded(!filterExpanded)}>
          <FilterListIcon />

          {filterExpanded ? (
            <Typography variant="button">{"LESS FILTERS"}</Typography>
          ) : (
            <Typography variant="button">{"MORE FILTERS"}</Typography>
          )}
        </Button>
        {selectedLocations.length > 0 ||
        selectedGender.length > 0 ||
        selectedTeams.length > 0 ||
        selectedLanguages.length > 0 ||
        isEmdr === true ||
        isExperience === true ||
        isSchemaTherapy === true ||
        selectedPsychologistId !== "" ? (
          <Button
            onClick={() => {
              resetFilters();
            }}
          >
            <StyledClearIcon />

            <StyledClearTypography variant="button">
              {"Clear All"}
            </StyledClearTypography>
          </Button>
        ) : null}
      </Grid>
      <StyledBottomDivider />
    </>
  );
};

export default observer(CalendarFilter);
