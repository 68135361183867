import React from "react";
import { styled } from "@mui/system";
import { Card, Modal, Divider, CardContent } from "@mui/material";
import moment from "moment";
import communicationEntryTypes from "../../../utils/constants/communicationEntryTypes";

const StyledCard = styled(Card)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  outline: "none",
  boxShadow: theme.shadows[20],
  width: 700,
  maxHeight: "100%",
  overflowY: "auto",
  maxWidth: "100%",
}));

const CommunicationEntryModal = ({ communicationEntry, open, onClose }) => {
  if (!open) {
    return null;
  }

  const renderCard = (entry) => {
    switch (entry.$type) {
      case communicationEntryTypes.EMAIL_COMMUNICATION_ENTRY:
        return (
          <StyledCard>
            <CardContent>
              <div>
                Subject: {entry.description}
                <br />
                To: {entry.toEmailAddress}
                <br />
                SentAt: {moment(entry.sentAt).format("DD-MM-YYYY HH:mm")}
                <br />
              </div>
            </CardContent>
            <Divider />
            <CardContent>
              <div dangerouslySetInnerHTML={{ __html: entry.htmlBody }} />
            </CardContent>
          </StyledCard>
        );
      case communicationEntryTypes.SMS_COMMUNICATION_ENTRY:
        return (
          <StyledCard>
            <CardContent>
              <div>
                Subject: {entry.description}
                <br />
                To: {entry.toPhoneNumber}
                <br />
                SentAt: {moment(entry.sentAt).format("DD-MM-YYYY HH:mm")}
                <br />
              </div>
            </CardContent>
            <Divider />
            <CardContent>
              <div>{entry.content}</div>
            </CardContent>
          </StyledCard>
        );
      default:
        return <div>ERROR</div>;
    }
  };

  return (
    <Modal onClose={onClose} open={open}>
      {renderCard(communicationEntry)}
    </Modal>
  );
};

export default CommunicationEntryModal;
