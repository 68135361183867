import { useTranslation } from "react-i18next";
import { toast } from "sonner";
import { useResolveAllAlerts } from "../hooks";
import { Loader2 } from "lucide-react";
import { Button } from "../../../../components/ui/button";
import { CaseLoadAlert } from "../types";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { useState } from "react";

type MarkAllAlertsAsResolvedButtonProps = {
  alerts: {
    caseLoadEntryId: string;
    alerts: CaseLoadAlert[];
  }[];
  setHideAlerts: (newVal: boolean) => void;
  setOnlyCaseLoadsWithAlert: (newVal: boolean) => void;
};

export const MarkAllAlertsAsResolvedButton = ({
  alerts,
  setHideAlerts,
  setOnlyCaseLoadsWithAlert,
}: MarkAllAlertsAsResolvedButtonProps) => {
  const { t } = useTranslation();
  const [
    warningOnInactiveDossierAlertsDialogOpen,
    setWarningOnInactiveDossierAlertsDialogOpen,
  ] = useState(false);

  const hasInactiveDossierAlert = alerts.some((x) =>
    x.alerts.some((a) => a.code === "DossierNoLongerActive")
  );

  const { mutate: resolveAllAlerts, isLoading: isResolvingAllAlerts } =
    useResolveAllAlerts(() => {
      setWarningOnInactiveDossierAlertsDialogOpen(false);
      setOnlyCaseLoadsWithAlert(false);
      toast(t("case-load-manager-resolved-alerts-success-toast-title"), {
        description: t("case-load-manager-resolved-alerts-success-toast-desc"),
      });
      setHideAlerts(!hasInactiveDossierAlert); // if we have inactive dossiers, we should keep showing the fact that there are alerts present
    });

  return (
    <>
      <Button
        variant="secondary"
        className="text-sm text-red-800  dark:text-slate-400"
        onClick={async () => {
          if (hasInactiveDossierAlert) {
            setWarningOnInactiveDossierAlertsDialogOpen(true);
          } else {
            if (!isResolvingAllAlerts) {
              await resolveAllAlerts();
            }
          }
        }}
      >
        {isResolvingAllAlerts ? (
          <div className="flex items-center space-x-1">
            <Loader2 className="w-4 h-4 animate-spin" />
            <p>
              {t(
                "case-load-manager-alert-menu-marking-all-alerts-as-resolved-button"
              )}
            </p>
          </div>
        ) : (
          <p>
            {t(
              "case-load-manager-alert-menu-mark-all-alerts-as-resolved-button"
            )}
          </p>
        )}
      </Button>
      <Dialog
        open={warningOnInactiveDossierAlertsDialogOpen}
        onOpenChange={(newVal) => {
          setWarningOnInactiveDossierAlertsDialogOpen(newVal);
        }}
      >
        <DialogContent className="min-w-[35vw]">
          <DialogHeader>
            <DialogTitle>
              {t(
                "case-load-manager-alert-mark-all-alerts-as-resolved-but-has-inactive-dossiers-dialog-title"
              )}
            </DialogTitle>
            <DialogDescription>
              {t(
                "case-load-manager-alert-mark-all-alerts-as-resolved-but-has-inactive-dossiers-dialog-desc"
              )}
            </DialogDescription>
          </DialogHeader>

          <DialogFooter className="flex items-center space-x-2">
            <Button
              variant="outline"
              className="w-1/2"
              onClick={() => setWarningOnInactiveDossierAlertsDialogOpen(false)}
            >
              {t(
                "case-load-manager-alert-mark-all-alerts-as-resolved-but-has-inactive-dossiers-dialog-cancel-button"
              )}
            </Button>
            <Button
              variant="destructive"
              onClick={async () => {
                await resolveAllAlerts();
              }}
              className="w-1/2"
            >
              {isResolvingAllAlerts ? (
                <div className="flex items-center space-x-1">
                  <Loader2 className="w-4 h-4 animate-spin" />
                  <p>
                    {t(
                      "case-load-manager-alert-mark-all-alerts-as-resolved-but-has-inactive-dossiers-dialog-ok-button2"
                    )}
                  </p>
                </div>
              ) : (
                <p>
                  {t(
                    "case-load-manager-alert-mark-all-alerts-as-resolved-but-has-inactive-dossiers-dialog-ok-button"
                  )}
                </p>
              )}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
