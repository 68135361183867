import { CircleSlash2 } from "lucide-react";

export const UnFinishedIndicator = () => {
  return (
    <div className="inline-flex items-center justify-center p-1 rounded-lg bg-red-100 border border-red-300 text-red-500">
      <div className="w-4 h-4">
        <CircleSlash2 className="w-4 h-4" />
      </div>
    </div>
  );
};
