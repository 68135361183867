import { WysiwygEditor } from "../../../../../components/ui/wysiwyg-editor";
import { Textarea } from "../../../../../components/ui/textarea";
import { useIsEnabled } from "../../../../../feature-management/useIsEnabled";
import { useTranslation } from "react-i18next";

export const KopModelTextBoxes = ({
  isDisabled,
  complaints,
  circumstances,
  personalStyle,
  requestForHelp,
  myCommentsForDiagnosisAndTreatment,
  setComplains,
  setCircumstances,
  setPersonalStyle,
  setRequestForHelp,
  setMyCommentsForDiagnosisAndTreatment,
}: {
  isDisabled: boolean;
  complaints: string;
  circumstances: string;
  personalStyle: string;
  requestForHelp: string;
  myCommentsForDiagnosisAndTreatment: string;
  setComplains: React.Dispatch<React.SetStateAction<string>>;
  setCircumstances: React.Dispatch<React.SetStateAction<string>>;
  setPersonalStyle: React.Dispatch<React.SetStateAction<string>>;
  setRequestForHelp: React.Dispatch<React.SetStateAction<string>>;
  setMyCommentsForDiagnosisAndTreatment: React.Dispatch<
    React.SetStateAction<string>
  >;
}) => {
  const { data: isWysiwygEnabled } = useIsEnabled("EnableWysiwygKopModel");
  const { t } = useTranslation();

  return (
    <>
      <div className="mt-4 flex space-x-4 items-start">
        <div className="w-1/3">
          <p className="font-bold">
            {t("case-load-manager-onboarding-kop-model-caption-k")}
          </p>
          {isWysiwygEnabled ? (
            <WysiwygEditor
              isDisabled={isDisabled}
              value={complaints}
              setValue={setComplains}
              minHeight="250px"
            />
          ) : (
            <>
              {isDisabled ? (
                <p className="border rounded p-2 mt-1 min-h-[400px]">
                  {complaints}
                </p>
              ) : (
                <Textarea
                  className="border-1-4 mt-1 bg-gray-50/50 dark:bg-slate-800"
                  value={complaints}
                  onChange={(e) => setComplains(e.target.value)}
                  rows={20}
                />
              )}
            </>
          )}
        </div>
        <div className="w-1/3">
          <p className="font-bold">
            {t("case-load-manager-onboarding-kop-model-caption-o")}
          </p>
          {isWysiwygEnabled ? (
            <WysiwygEditor
              isDisabled={isDisabled}
              value={circumstances}
              setValue={setCircumstances}
              minHeight="250px"
            />
          ) : (
            <>
              {isDisabled ? (
                <p className="border rounded p-2 mt-1 min-h-[400px]">
                  {circumstances}
                </p>
              ) : (
                <Textarea
                  className="border-1-4 mt-1 bg-gray-50/50 dark:bg-slate-800"
                  value={circumstances}
                  onChange={(e) => setCircumstances(e.target.value)}
                  rows={20}
                />
              )}
            </>
          )}
        </div>

        <div className="w-1/3">
          <p className="font-bold">
            {t("case-load-manager-onboarding-kop-model-caption-p")}
          </p>
          {isWysiwygEnabled ? (
            <WysiwygEditor
              isDisabled={isDisabled}
              value={personalStyle}
              setValue={setPersonalStyle}
              minHeight="250px"
            />
          ) : (
            <>
              {isDisabled ? (
                <p className="border rounded p-2 min-h-[400px]">
                  {personalStyle}
                </p>
              ) : (
                <Textarea
                  className="border-1-4 mt-1 bg-gray-50/50"
                  value={personalStyle}
                  onChange={(e) => setPersonalStyle(e.target.value)}
                  rows={20}
                />
              )}
            </>
          )}
        </div>
      </div>

      <div className="mt-4">
        <p className="font-bold">
          {t("case-load-manager-onboarding-kop-model-caption-request-for-help")}
        </p>
        {isWysiwygEnabled ? (
          <WysiwygEditor
            isDisabled={isDisabled}
            value={requestForHelp}
            setValue={setRequestForHelp}
            minHeight="100px"
          />
        ) : (
          <>
            {isDisabled ? (
              <p className="border rounded p-2 mt-1 min-h-[80px]">
                {requestForHelp}
              </p>
            ) : (
              <Textarea
                className="border-1-4 mt-1 bg-gray-50/50 dark:bg-slate-800"
                value={requestForHelp}
                onChange={(e) => setRequestForHelp(e.target.value)}
                rows={3}
              />
            )}
          </>
        )}
      </div>

      <div className="mt-4">
        <p className="font-bold">
          {t("case-load-manager-onboarding-kop-model-caption-questions")}
        </p>
        {isWysiwygEnabled ? (
          <WysiwygEditor
            isDisabled={isDisabled}
            value={myCommentsForDiagnosisAndTreatment}
            setValue={setMyCommentsForDiagnosisAndTreatment}
            minHeight="100px"
          />
        ) : (
          <>
            {isDisabled ? (
              <p className="border rounded p-2 mt-1 min-h-[80px]">
                {myCommentsForDiagnosisAndTreatment}
              </p>
            ) : (
              <Textarea
                className="border-1-4 mt-1 bg-gray-50/50 dark:bg-slate-800"
                value={myCommentsForDiagnosisAndTreatment}
                onChange={(e) =>
                  setMyCommentsForDiagnosisAndTreatment(e.target.value)
                }
                rows={3}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};
