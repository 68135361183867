import { DefaultGenerics, StreamChat } from 'stream-chat';
import {
  HorizontalTabHeader,
  HorizontalTabContent,
  TabContext,
} from '../../../../components/ui/horizontalTab';
import { CaseloadChat } from '../../chat/chat';
import { useContext, useState } from 'react';
import { StreamMessage } from 'stream-chat-react';
import { StreamChatGenerics } from '../../../streamChat/types';
import { ResourceSuggestion } from './aiRecommendations/types';
import { AiRecommendations } from './aiRecommendations/aiRecommendations';
import { useIsEnabled } from '../../../../feature-management/useIsEnabled';
import { KopModel } from './kopModel/kopModel';
import { TreatmentDetails } from './treatmentDetails/treatmentDetails';

type TabsProps = {
  clientId: string;
  dossierLookupId: string;
  chatClient: StreamChat<DefaultGenerics> | null;
  couldNotLoadChat: boolean;
};

export const Tabs = ({ clientId, dossierLookupId, chatClient, couldNotLoadChat }: TabsProps) => {
  const { setActiveTab } = useContext(TabContext)!;

  const { data: isCaseLoadManagerAiChatAssistantEnabled } = useIsEnabled(
    'EnableCaseLoadManagerAiChatAssistant'
  );

  const { data: isCaseLoadManagerWireReplacementEnabled } = useIsEnabled(
    'EnableCaseLoadManagerWireReplacement'
  );

  const [defaultChatMessage, setDefaultChatMessage] = useState<
    StreamMessage<StreamChatGenerics> | undefined
  >(undefined);

  const onReplySuggestionSelected = (suggestion: string) => {
    setActiveTab(0);
    setDefaultChatMessage({
      id: '???',
      type: 'regular',
      text: suggestion,
    });
  };
  const onResourceSuggestionSelected = (resource: ResourceSuggestion) => {
    setActiveTab(0);
    setDefaultChatMessage({
      id: '???',
      type: 'regular',
      text: resource.description,
      attachments: [
        {
          // todo: when AI API is ready, use the AI provided attachment
          type: 'application/pdf',
          asset_url: 'https://pdfobject.com/pdf/sample.pdf',
          title: resource.title,
        },
      ],
    });
  };

  type TabDetails = {
    title: string;
    component: JSX.Element;
  };

  const chatTab: TabDetails = {
    title: 'Chat',
    component: (
      <CaseloadChat
        dossierLookupId={dossierLookupId}
        chatClient={chatClient}
        couldNotLoadChat={couldNotLoadChat}
        defaultMessage={defaultChatMessage}
      />
    ),
  };

  const chatAssistantTab: TabDetails = {
    title: 'Recommendations',
    component: (
      <AiRecommendations
        onReplySuggestionSelected={onReplySuggestionSelected}
        onResourceSuggestionSelected={onResourceSuggestionSelected}
      />
    ),
  };

  const kopModelTab: TabDetails = {
    title: 'KOP-model',
    component: <KopModel clientId={clientId} />,
  };

  const treatmentTab: TabDetails = {
    title: 'Treatment',
    component: <TreatmentDetails clientId={clientId} />,
  };

  const getTabsToDisplay = () => {
    return [
      ...(isCaseLoadManagerWireReplacementEnabled && chatClient ? [chatTab] : []),
      ...(isCaseLoadManagerAiChatAssistantEnabled ? [chatAssistantTab] : []),
      kopModelTab,
      treatmentTab,
    ];
  };

  return (
    <div className="w-full mb-12">
      <div className="flex items-center ml-3">
        {getTabsToDisplay().map((t, index) => {
          return (
            <HorizontalTabHeader key={index} index={index}>
              {t.title}
            </HorizontalTabHeader>
          );
        })}
      </div>

      {getTabsToDisplay().map((t, index) => {
        return (
          <HorizontalTabContent key={index} index={index}>
            {t.component}
          </HorizontalTabContent>
        );
      })}
    </div>
  );
};
