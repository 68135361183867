import axios from "axios";
import { useMutation } from "react-query";

export const useDownloadCqiReport = (onSuccess: (data: any) => void) => {
  const mutation = useMutation({
    mutationFn: async ({ fromDate }: { fromDate: string }) => {
      const res = await axios.post(
        `cqireportadmin/export`,
        { fromDate },
        {
          responseType: "blob",
        }
      );
      return res.data;
    },
    onSuccess: onSuccess,
  });

  return mutation;
};
