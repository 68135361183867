import { useGetLocationTags } from "../hooks";
import {
  MultiSelect,
  SelectableValue,
} from "../../../components/ui/multiselect";

export const LocationsFilter = ({
  selected,
  onSelectionChange,
}: {
  selected: SelectableValue[];
  onSelectionChange: React.Dispatch<React.SetStateAction<SelectableValue[]>>;
}) => {
  const { data, isLoading } = useGetLocationTags();

  const mappedData: SelectableValue[] = isLoading
    ? []
    : data!.map((d) => ({
        label: d,
        value: d,
      }));

  return (
    <div className="flex items-start space-x-2">
      <p className="pb-1 font-bold pt-2">Location(s):</p>
      <MultiSelect
        disabled={isLoading}
        selectableValues={mappedData}
        selected={selected}
        onSelectionChange={onSelectionChange}
        placeholder="Select locations..."
      />
    </div>
  );
};
