import { CircleHelp } from "lucide-react";

export const NotFoundStatus = () => {
  return (
    <div className="w-full text-orange-700 font-bold flex items-center space-x-2">
      <div className="h-4 w-4">
        <CircleHelp className="h-4 w-4" />
      </div>
      <p>Not found</p>
    </div>
  );
};
