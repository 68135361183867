import { action, makeObservable, observable, runInAction } from "mobx";
import axios from "axios";

class ScheduleDefinitionsStore {
  scheduleDefinitions = [];

  constructor() {
    makeObservable(this, {
      scheduleDefinitions: observable,
      fetchScheduleDefinitions: action,
    });
  }

  async fetchScheduleDefinitions() {
    const res = await axios.get("scheduleDefinitions");

    runInAction(() => {
      this.scheduleDefinitions = res.data;
    });
  }
}

export default ScheduleDefinitionsStore;
