import React from "react";
import { useMsal } from "@azure/msal-react";

const UnauthorizedPage = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutPopup({
      mainWindowRedirectUri: "/",
    });
  };

  return (
    <main className="min-h-screen w-full bg-gradient-to-tl dark:from-gray-950 dark:to-gray-900 from-gray-400 via-gray-200 via-20% to-gray-100 text-foreground-muted flex items-center justify-center relative isolate">
      <svg
        className="absolute -z-10 h-full w-full stroke-gray-300 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
            width={100}
            height={100}
            x="50%"
            y={-1}
            patternUnits="userSpaceOnUse"
          >
            <path d="M50 100V.5M.5 .5H100" fill="none"></path>
          </pattern>
        </defs>
        <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
          <path
            d="M-50.5 0h101v101h-101Z M349.5 0h101v101h-101Z M249.5 200h101v101h-101Z M-150.5 300h101v101h-101Z"
            strokeWidth={0}
          />
        </svg>
        <rect
          width="100%"
          height="100%"
          strokeWidth={0}
          fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
        />
      </svg>

      <div className="text-center flex flex-col items-center justify-center w-full">
        <h1 className="text-6xl font-bold">Welcome!</h1>
        <p className="pt-8 text-lg w-1/3">
          Thank you for signing up for iPractice! Your account is currently
          under review to ensure the appropriate roles are assigned.
        </p>
        <p className="pt-4 text-md w-1/2">
          An administrator will complete your access setup shortly. Once it's
          ready, simply refresh the page to access your Dashboard.
        </p>
        <p className="pt-8">
          In the meantime, if you have any questions, feel free to contact IT
          Support at{" "}
          <a
            href="mailto:techquestions@ipractice.nl"
            className="text-link hover:underline"
          >
            techquestions@ipractice.nl
          </a>
          , or{" "}
          <span
            onClick={handleLogout}
            className="text-link cursor-pointer hover:underline"
          >
            log out.
          </span>
        </p>
      </div>
    </main>
  );
};

export default UnauthorizedPage;
