import Button from "@mui/material/Button";
import PublishIcon from "@mui/icons-material/Publish";
import React from "react";
import { styled } from "@mui/system";
import { useSnackbar } from "notistack";
import { trackPromise } from "react-promise-tracker";
import areas from "../utils/constants/areas";
import { queryClient } from "../react-query/provider";

const StyledPublishIcon = styled(PublishIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const UploadReferralLetter = ({
  dossierType,
  dossierId,
  onboardingStore,
  patientStore,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const inputId = `contained-button-file${dossierId}`;

  const uploadDocument = (event) => {
    const data = new FormData();
    data.set("FormFile", event.target.files[0]);

    trackPromise(
      onboardingStore.uploadReferralLetter(dossierType, dossierId, data).then(
        () => {
          enqueueSnackbar("Verwijsbrief is geupload.", { variant: "success" });
          patientStore.fetchPatient(onboardingStore.onboarding.patient.id);
        },

        (error) => enqueueSnackbar(error.response.data, { variant: "error" })
      ),
      areas.ONBOARDING
    );
  };

  return (
    <div>
      <input
        style={{ display: "none" }}
        accept=".pdf,image/*"
        onChange={uploadDocument}
        id={inputId}
        type="file"
      />

      <label htmlFor={inputId}>
        <Button
          size="small"
          color="primary"
          variant="contained"
          component="span"
        >
          <StyledPublishIcon />
          Upload
        </Button>
      </label>
    </div>
  );
};

export default UploadReferralLetter;
