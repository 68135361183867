import { Pencil, Text, PenLine, Trash2, Info } from "lucide-react";
import { Button } from "../../../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../../components/ui/dropdown-menu";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../../components/ui/tooltip";
import { AddCommentsDialogWrapper } from "./addComments/addCommentsToMultiplePatients";
import { HasSupportedMultiplePatientsDialogWrapper } from "./hasSupported/hasSupportedMultiplePatientsDialogWrapper";
import { NeedsCheckinForMultiplePatientsDialogWrapper } from "./needsCheckIn/setNeedsCheckinForMultiplePatients";
import { RemoveMultiplePatientsDialogWrapper } from "./removePatients/removeMultiplePatientsDialogWrapper";
import { DialogTrigger } from "../../../../components/ui/dialog";
import { useState } from "react";
import { Table } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { MainListPatient } from "../types";

type MultiRowActionsButtonProps = {
  table: Table<MainListPatient>;
};

export const MultiRowActionsButton = ({
  table,
}: MultiRowActionsButtonProps) => {
  const { t } = useTranslation();

  const selectedPatients: MainListPatient[] = table
    .getSelectedRowModel()
    .rows.map((row) => row.original);

  const [isAddCommentsDialogOpen, setIsAddCommentsDialogOpen] = useState(false);

  const [isHasSupportedDialogOpen, setIsHasSupportedDialogOpen] =
    useState(false);

  const [
    needsCheckinForMultiplePatientsDialogOpen,
    setNeedsCheckinForMultiplePatientsDialogOpen,
  ] = useState(false);

  const [removePatientsDialogOpen, setRemovePatientsDialogOpen] =
    useState(false);

  if (selectedPatients.length === 0) return <></>;

  return (
    <>
      <AddCommentsDialogWrapper
        isOpen={isAddCommentsDialogOpen}
        close={() => {
          setIsAddCommentsDialogOpen(false);
        }}
        selectedPatients={selectedPatients}
      >
        <HasSupportedMultiplePatientsDialogWrapper
          isOpen={isHasSupportedDialogOpen}
          close={() => {
            setIsHasSupportedDialogOpen(false);
          }}
          selectedPatients={selectedPatients}
        >
          <NeedsCheckinForMultiplePatientsDialogWrapper
            isOpen={needsCheckinForMultiplePatientsDialogOpen}
            close={() => {
              setNeedsCheckinForMultiplePatientsDialogOpen(false);
            }}
            selectedPatients={selectedPatients}
          >
            <RemoveMultiplePatientsDialogWrapper
              isOpen={removePatientsDialogOpen}
              close={(success) => {
                if (success) {
                  table.resetRowSelection();
                }
                setRemovePatientsDialogOpen(false);
              }}
              selectedPatients={selectedPatients}
            >
              <DropdownMenu>
                <DropdownMenuTrigger>
                  <Button className="flex items-center gap-2 w-40">
                    <PenLine className="w-4 h-4" />
                    <p>{t("case-load-manager-multiple-row-actions-button")}</p>
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  className="p-1 w-[16rem]"
                  side="bottom"
                  align="end"
                >
                  <DropdownMenuGroup>
                    <DropdownMenuItem>
                      <div
                        className="flex gap-2 items-center cursor-pointer"
                        onClick={() =>
                          setNeedsCheckinForMultiplePatientsDialogOpen(true)
                        }
                      >
                        <Pencil className="h-4 w-4" />
                        <p>
                          {t(
                            "case-load-manager-multiple-row-actions-update-needs-checkin-label"
                          )}
                        </p>
                      </div>
                    </DropdownMenuItem>

                    <DropdownMenuItem>
                      <div
                        className="flex gap-2 items-center cursor-pointer"
                        onClick={() => setIsHasSupportedDialogOpen(true)}
                      >
                        <Pencil className="h-4 w-4" />
                        <p>
                          {t(
                            "case-load-manager-multiple-row-actions-update-chatted-with-label"
                          )}
                        </p>
                      </div>
                    </DropdownMenuItem>
                    <DropdownMenuItem>
                      <DialogTrigger asChild>
                        <div
                          className="flex gap-2 items-center cursor-pointer"
                          onClick={() => setIsAddCommentsDialogOpen(true)}
                        >
                          <Text className="h-4 w-4" />
                          <p>
                            {t(
                              "case-load-manager-multiple-row-actions-add-comments"
                            )}
                          </p>
                        </div>
                      </DialogTrigger>
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                  <DropdownMenuSeparator />
                  <DropdownMenuGroup>
                    <DropdownMenuItem>
                      <div
                        className={`flex justify-between items-center w-full ${
                          selectedPatients.every((x) => x.hasChattedWith)
                            ? "text-red-400"
                            : "text-red-600"
                        }`}
                        onClick={() => {
                          if (selectedPatients.every((x) => x.hasChattedWith))
                            return;
                          setRemovePatientsDialogOpen(true);
                        }}
                      >
                        <div className="flex items-center gap-2 cursor-pointer">
                          <Trash2 className="h-4 w-4" />
                          <p>
                            {t(
                              "case-load-manager-multiple-row-actions-remove-patients"
                            )}
                          </p>
                        </div>

                        {selectedPatients.every((x) => x.hasChattedWith) && (
                          <div className="h-4 w-4">
                            <TooltipProvider delayDuration={100}>
                              <Tooltip>
                                <TooltipTrigger>
                                  <Info className="h-4 w-4" />
                                </TooltipTrigger>
                                <TooltipContent className="w-72">
                                  {t(
                                    "case-load-manager-cannot-remove-multiple-patients-desc-tooltip"
                                  )}
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          </div>
                        )}
                      </div>
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>
            </RemoveMultiplePatientsDialogWrapper>
          </NeedsCheckinForMultiplePatientsDialogWrapper>
        </HasSupportedMultiplePatientsDialogWrapper>
      </AddCommentsDialogWrapper>
    </>
  );
};
