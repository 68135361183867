import { SlidersHorizontal } from "lucide-react";
import { WeekSelector } from "../../../../../../shared/WeekSelector";
import { Button } from "../../../../../../components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../../../components/ui/popover";
import {
  useGetEpdLocations,
  useGetHealthcareInsurers,
} from "../../../../hooks";
import { statuses } from "../../../../constants";
import { Cross2Icon } from "@radix-ui/react-icons";
import { StatisticsFacetedFilter } from "../../statisticsFacetedFilter";
import { HealthcareInsurerContractType } from "../../../../types";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../../components/ui/select";

export const StatisticsFilter = ({
  monday,
  viewType,
  setViewType,
  selectedDate,
  setSelectedDate,
  registrationStatuses,
  setRegistrationStatuses,
  locationIds,
  setLocationIds,
  healthcareInsurerIds,
  setHealthcareInsurerIds,
  contractTypes,
  setContractTypes,
}: {
  monday: Date;
  viewType: "week" | "month";
  setViewType: (newType: "week" | "month") => void;
  selectedDate: Date;
  setSelectedDate: (newDate: Date) => void;
  registrationStatuses: string[];
  setRegistrationStatuses: (newStatuses: string[]) => void;
  locationIds: string[];
  setLocationIds: (newIds: string[]) => void;
  healthcareInsurerIds: string[];
  setHealthcareInsurerIds: (newIds: string[]) => void;
  contractTypes: string[];
  setContractTypes: (newIds: string[]) => void;
}) => {
  const { data: epdLocations } = useGetEpdLocations();
  const { data: healthcareInsurers } = useGetHealthcareInsurers(true);

  const uniqueConcerns = healthcareInsurers
    ? healthcareInsurers
        .map((insurer) => insurer.concern)
        .filter((value, index, self) => self.indexOf(value) === index)
        .filter((value) => value.trim() !== "")
    : [];

  const numberOfFiltersInUse =
    locationIds.length +
    registrationStatuses.length +
    healthcareInsurerIds.length;

  return (
    <div className="w-full flex justify-end items-center space-x-2">
      <Popover>
        <PopoverTrigger asChild>
          <Button variant="outline" className="flex items-center gap-2 w-40">
            <SlidersHorizontal className="w-4 h-4" />
            <p>Customize</p>
            {numberOfFiltersInUse > 0 && (
              <div className="text-xs w-4 h-4 rounded-full bg-ipractice-blue text-center text-white">
                {numberOfFiltersInUse}
              </div>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-6 min-w-[24rem]" side="bottom" align="end">
          <div className="grid gap-4">
            <div className="space-y-2">
              <h4 className="font-bold text-lg leading-none">
                Customization & filters
              </h4>
              <p className="text-sm text-muted-foreground">
                Set filters and customize your view of the patient referrals
                statistics.
              </p>
            </div>

            <div>
              <div className="w-full flex flex-col items-center space-y-2">
                {numberOfFiltersInUse > 0 && (
                  <div className="flex justify-end w-full -mt-4">
                    <Button
                      variant="destructive"
                      onClick={() => {
                        setLocationIds([]);
                        setRegistrationStatuses([]);
                        setHealthcareInsurerIds([]);
                        // setContractFilter(HealthcareInsurerContractFilter.All);
                      }}
                      className="h-8 flex justify-end px-2 lg:px-3"
                    >
                      Reset Filters
                      <Cross2Icon className="h-4 w-4" />
                    </Button>
                  </div>
                )}

                <div className="w-full flex items-center justify-between space-x-2">
                  <div className="w-1/2">
                    <Select
                      disabled
                      value={viewType}
                      onValueChange={(x: "week" | "month") => {
                        setViewType(x);
                      }}
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent className="z-[9999999]">
                        <SelectGroup>
                          <SelectItem value="week">Week View</SelectItem>
                          <SelectItem value="month">Month View</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>

                  <div className="w-1/2">
                    <WeekSelector
                      thisMonday={monday}
                      selectedDate={selectedDate}
                      setSelectedDate={setSelectedDate}
                      fullWidth
                    />
                  </div>
                </div>

                <StatisticsFacetedFilter
                  title="Status"
                  options={statuses}
                  filters={registrationStatuses}
                  setFilters={setRegistrationStatuses}
                />
                <StatisticsFacetedFilter
                  title="Location"
                  options={
                    epdLocations?.map((d) => ({
                      label: d.name,
                      value: d.id,
                    })) || []
                  }
                  filters={locationIds}
                  setFilters={setLocationIds}
                />
                <StatisticsFacetedFilter
                  title="Contract Type"
                  options={Object.entries(HealthcareInsurerContractType).map(
                    ([value, label]) => ({ label, value })
                  )}
                  filters={contractTypes}
                  setFilters={setContractTypes}
                />
                <StatisticsFacetedFilter
                  title="Healthcare Insurer"
                  options={
                    uniqueConcerns
                      ?.toSorted((a, b) => a.localeCompare(b))
                      ?.map((d) => ({
                        label: d,
                        value: d,
                      })) || []
                  }
                  filters={healthcareInsurerIds}
                  setFilters={setHealthcareInsurerIds}
                />
              </div>
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};
