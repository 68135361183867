import { useGetBacklogStatisticsByStatusReport } from "../../hooks";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../../components/ui/table";
import { Skeleton } from "../../../../../components/ui/skeleton";

export const BacklogReportByStatusTable = ({
  locationIds,
}: {
  locationIds: string[];
}) => {
  const { data, isLoading } =
    useGetBacklogStatisticsByStatusReport(locationIds);

  if (isLoading) {
    return (
      <div className="mt-6">
        <Skeleton className="h-[450px] w-full rounded" />
      </div>
    );
  }

  return (
    <div className="mt-6 overflow-x-auto">
      <Table className="border min-w-[1200px] table-auto">
        <TableHeader>
          <TableRow>
            <TableHead>Location</TableHead>
            <TableHead className="w-[150px]">New</TableHead>
            <TableHead className="w-[150px]">Reach Out</TableHead>
            <TableHead className="w-[150px]">
              Register Patient & Intakes
            </TableHead>
            <TableHead className="w-[150px]">
              Awaiting Patient Approval
            </TableHead>
            <TableHead className="w-[150px]">
              Awaiting sending IWelcome
            </TableHead>
            <TableHead className="w-[150px] font-bold bg-slate-100 hover:bg-slate-100">
              Total
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data && data.length > 0 ? (
            <>
              {data.map((d) => {
                return (
                  <TableRow key={d.location}>
                    <TableCell>{d.location}</TableCell>
                    <TableCell className="w-[150px]">{d.countOfNew}</TableCell>
                    <TableCell className="w-[150px]">
                      {d.countOfReachOut}
                    </TableCell>
                    <TableCell className="w-[150px]">
                      {d.countOfRegisterPatientAndIntake}
                    </TableCell>
                    <TableCell className="w-[150px]">
                      {d.countOfAwaitingPatientApproval}
                    </TableCell>
                    <TableCell className="w-[150px]">
                      {d.countOfAwaitingIWelcomeEmailSend}
                    </TableCell>
                    <TableCell className="w-[150px] font-bold bg-slate-100 hover:bg-slate-100">
                      {d.countOfNew +
                        d.countOfReachOut +
                        d.countOfRegisterPatientAndIntake +
                        d.countOfAwaitingPatientApproval +
                        d.countOfAwaitingIWelcomeEmailSend}
                    </TableCell>
                  </TableRow>
                );
              })}

              <TableRow className="bg-slate-100 hover:bg-slate-100">
                <TableCell className="font-bold">Total</TableCell>
                <TableCell className="w-[150px] font-bold">
                  {data.reduce((a, b) => a + b.countOfNew, 0)}
                </TableCell>
                <TableCell className="w-[150px] font-bold">
                  {data.reduce((a, b) => a + b.countOfReachOut, 0)}
                </TableCell>
                <TableCell className="w-[150px] font-bold">
                  {data.reduce(
                    (a, b) => a + b.countOfRegisterPatientAndIntake,
                    0
                  )}
                </TableCell>
                <TableCell className="w-[150px] font-bold">
                  {data.reduce(
                    (a, b) => a + b.countOfAwaitingPatientApproval,
                    0
                  )}
                </TableCell>
                <TableCell className="w-[150px] font-bold">
                  {data.reduce(
                    (a, b) => a + b.countOfAwaitingIWelcomeEmailSend,
                    0
                  )}
                </TableCell>
                <TableCell className="w-[150px] font-bold">
                  {data.reduce(
                    (a, b) =>
                      a +
                      b.countOfNew +
                      b.countOfReachOut +
                      b.countOfRegisterPatientAndIntake +
                      b.countOfAwaitingPatientApproval +
                      b.countOfAwaitingIWelcomeEmailSend,
                    0
                  )}
                </TableCell>
              </TableRow>
            </>
          ) : (
            <TableRow>
              <TableCell colSpan={6} className="py-1">
                No data found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};
