import { Skeleton } from "../../../components/ui/skeleton";

export const DetailsLoadingSkeleton = () => {
  return (
    <div className="w-full min-h-[90vh]">
      <Skeleton className="h-12 w-1/4 rounded" />
      <div className="flex items-center space-x-4 mt-1">
        <Skeleton className="h-4 w-[120px] rounded" />
        <Skeleton className="h-4 w-[160px] rounded" />
        <Skeleton className="h-4 w-[120px] rounded" />
      </div>

      <Skeleton className="mt-6 h-[70px] py-4 px-12 -mx-[40px]" />

      <div className="mt-6 ml-10 flex items-center space-x-4">
        <Skeleton className="h-[30px] w-[150px]" />
        <Skeleton className="h-[30px] w-[150px]" />
        <Skeleton className="h-[30px] w-[150px]" />
      </div>

      <div className="flex items-center space-x-8 mt-4">
        <Skeleton className="h-[60vh] w-1/2" />
        <Skeleton className="h-[60vh] w-1/2" />
      </div>
    </div>
  );
};
