import {
  FormEvent,
  KeyboardEvent as ReactKeyboardEvent,
  Ref,
  SyntheticEvent,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import { cn } from '../../../../lib/utils';
import { useScreenSize } from '../../../../utils/useScreenSize';
import { CircleAlert, Send } from 'lucide-react';
import { Button } from '../../../../components/ui/button';

type Props = {
  handleSendQuestion: (text: string) => void;
  loading: boolean;
};

export const SendMessageForm = forwardRef(function SendMessageFormNoForwardRef(
  { handleSendQuestion, loading }: Props,
  ref: Ref<HTMLFormElement>
) {
  const [error, setError] = useState<string | null>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [value, setValue] = useState('');

  const { isMobile } = useScreenSize();

  const handleKeyDown = (e: ReactKeyboardEvent<HTMLTextAreaElement>) => {
    if (isMobile) {
      return;
    }

    // only send on enter key press
    // if shift + enter is pressed, add a new line
    if (e.key === 'Enter') {
      e.preventDefault();
      if (e.shiftKey || e.ctrlKey) {
        setValue((prev) => prev + '\n');
      } else {
        handleSubmit(e);
      }
    }
  };

  const handleSubmit = async (e: SyntheticEvent | FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);

    let formElement: HTMLFormElement;

    if (e.nativeEvent instanceof KeyboardEvent) {
      const inputElement = e.target as HTMLInputElement;
      formElement = inputElement.form as HTMLFormElement;
    } else {
      formElement = e.target as HTMLFormElement;
    }

    const formData = new FormData(formElement);
    const query = formData.get('query') as string;

    if (!query.trim()) {
      setError('Please enter a question.');
      return;
    }

    formElement.reset();
    setValue('');

    if (textareaRef.current) {
      textareaRef.current.rows = 1;
    }

    return handleSendQuestion(query);
  };

  const handleChangeTextAreaHeight = (value: string) => {
    if (textareaRef.current) {
      textareaRef.current.rows = 1; // Reset to 1 row to recalculate properly
      const newlineCount = (value.match(/\n/g) || []).length + 1; // Count newlines and add 1 for the current line
      const rows = Math.min(10, Math.max(1, newlineCount));
      textareaRef.current.rows = rows;
    }
  };

  useEffect(() => {
    setError(null);

    if (value) {
      handleChangeTextAreaHeight(value);
    }

    return () => {
      if (textareaRef.current) {
        textareaRef.current.rows = 1;
      }
    };
  }, [value, textareaRef]);

  return (
    <div className="w-full pt-3  border-t border-slate-200">
      <form
        className="flex w-full items-start justify-center space-x-2 bg-white lg:bg-transparent lg:p-0"
        onSubmit={handleSubmit}
        ref={ref}
      >
        <textarea
          autoFocus={true}
          className="relative py-1 px-2 w-full flex-grow resize-none rounded-lg min-h-[40px] border border-slate-200 bg-white text-sm leading-5 text-slate-900/90 outline-none placeholder:text-sm"
          disabled={loading}
          id="userInput"
          name="query"
          onChange={(e) => {
            setValue(e.target.value);
          }}
          onKeyDown={handleKeyDown}
          placeholder={loading ? 'Thinking...' : 'Type your question here...'}
          ref={textareaRef}
          value={value}
        />

        <Button
          disabled={loading || value.length === 0}
          type="submit"
          className="flex items-center justify-center"
        >
          <div className="w-5 h-5">
            <Send className="w-5 h-5 text-white" />
          </div>
        </Button>
      </form>

      <div className="mt-2 flex items-center space-x-2 text-slate-500">
        <div className="w-4 h-4">
          <CircleAlert className="w-4 h-4" />
        </div>
        <p className="text-sm">
          Please remember: <span className="font-medium">do NOT share client data</span> with the
          assistant.
        </p>
      </div>

      {error && (
        <div className="mx-2 my-1 flex items-center">
          <p className="text-sm text-red-600">{error}</p>
        </div>
      )}
    </div>
  );
});
