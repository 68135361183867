import { getSimpleFormattedDateTime } from '../../../utils/dateUtils';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../components/ui/table';
import { Loading } from './tableStates/loading';
import { Error } from './tableStates/error';
import { OnboardPatientMainListEntry } from './types';
import { KopStateColumn } from './columns/kopStateColumn';
import { WelcomeMessageColumn } from './columns/welcomeMessageColumn';
import { TreatmentStartStandardMessageColumn } from './columns/treatmentStartMessageColumn';
import { Sheet, SheetClose, SheetContent } from '../../../components/ui/sheet';
import { useState } from 'react';
import { OnboardingPatientDetail } from './details/onboardingPatientDetail';
import { useGetOnboardingPatients } from './hooks';
import { useTranslation } from 'react-i18next';
import { DefaultGenerics, StreamChat } from 'stream-chat';
import { useGetChatDetails } from '../../streamChat/hooks';
import { useIsEnabled } from '../../../feature-management/useIsEnabled';
import { RemoveClientColumn } from './columns/removeClientColumn';
import { RemoveClientDialog } from './removeClient/RemoveClientDialog';

type OnboardPatientsProps = {
  chatClient: StreamChat<DefaultGenerics> | null;
};

export const OnboardPatients = ({ chatClient }: OnboardPatientsProps) => {
  const { t } = useTranslation();

  const { data: isCaseLoadManagerWireReplacementEnabled } = useIsEnabled(
    'EnableCaseLoadManagerWireReplacement'
  );

  const [selectedPatientEntry, setSelectedPatientEntry] =
    useState<OnboardPatientMainListEntry | null>(null);

  const [clientToRemove, setClientToRemove] = useState<OnboardPatientMainListEntry | null>(null);

  const { data, isLoading, isError } = useGetOnboardingPatients();

  const dossierLookupGuids = data?.data?.map((d) => d.dossierLookupGuid) ?? [];

  const { data: chatDetails } = useGetChatDetails(
    isCaseLoadManagerWireReplacementEnabled ?? false,
    dossierLookupGuids
  );

  const onRowClick = (patient: OnboardPatientMainListEntry) => {
    setSelectedPatientEntry(patient);
  };

  const onCloseSheet = () => {
    const hasUnsavedChanges = sessionStorage.getItem('hasUnsavedKopModelChanges');

    if (hasUnsavedChanges) {
      const wantsToLeave = window.confirm(
        'You have unsaved changes. Are you sure you want to leave?'
      );

      if (!wantsToLeave) return;

      setSelectedPatientEntry(null);
      sessionStorage.removeItem('hasUnsavedKopModelChanges');
    }

    setSelectedPatientEntry(null);
  };

  const getNumberOfUnreadMessages = (row: OnboardPatientMainListEntry) => {
    const chatData = chatDetails?.filter((x) => x.dossierLookupGuid === row.dossierLookupGuid);

    if (chatData == null || chatData.length === 0) return 0;

    return chatData[0].numberOfUnreadMessages ?? 0;
  };

  return (
    <main>
      <Sheet
        open={selectedPatientEntry != null}
        onOpenChange={(newValue) => {
          if (!newValue) {
            onCloseSheet();
          }
        }}
      >
        <Table className="border rounded">
          <TableHeader>
            <TableRow>
              <TableHead>{t('case-load-manager-onboarding-col-patient')}</TableHead>
              <TableHead>{t('case-load-manager-onboarding-col-pre-intake')}</TableHead>
              <TableHead>{t('case-load-manager-onboarding-col-intake')}</TableHead>
              <TableHead>{t('case-load-manager-onboarding-col-kop-model')}</TableHead>
              <TableHead>{t('case-load-manager-onboarding-col-welcome-message')}</TableHead>
              <TableHead>
                {t('case-load-manager-onboarding-col-treatment-start-standard-message')}
              </TableHead>
              <TableHead />
            </TableRow>
          </TableHeader>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={7}>
                  <div className="w-full flex items-center justify-center space-x-1">
                    <Loading />
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {isError ? (
                  <Error />
                ) : (
                  <>
                    {data?.data?.length == 0 ? (
                      <TableRow>
                        <TableCell colSpan={7}>
                          <div className="w-full flex items-center justify-center space-x-1">
                            <p>No clients found to onboard.</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    ) : (
                      data?.data?.map((d) => {
                        return (
                          <TableRow key={d.id} className="cursor-pointer">
                            <TableCell onClick={() => onRowClick(d)}>
                              <div className="flex items-center space-x-3">
                                <>
                                  {isCaseLoadManagerWireReplacementEnabled ? (
                                    <>
                                      {getNumberOfUnreadMessages(d) > 0 ? (
                                        <div className="text-xs h-4 w-4 rounded-full bg-ipractice-blue text-white flex items-center justify-center">
                                          {getNumberOfUnreadMessages(d)}
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>

                                <p className="font-bold">
                                  {d.epdPatientId}.{d.epdFileId} {d.name}
                                </p>
                              </div>
                            </TableCell>
                            <TableCell onClick={() => onRowClick(d)}>
                              {getSimpleFormattedDateTime(d.preIntakeOn)}
                            </TableCell>
                            <TableCell onClick={() => onRowClick(d)}>
                              {d.intakeOn && getSimpleFormattedDateTime(d.intakeOn)}
                            </TableCell>
                            <TableCell onClick={() => onRowClick(d)}>
                              <KopStateColumn patient={d} />
                            </TableCell>
                            <TableCell onClick={() => onRowClick(d)}>
                              <WelcomeMessageColumn patient={d} />
                            </TableCell>
                            <TableCell onClick={() => onRowClick(d)}>
                              <TreatmentStartStandardMessageColumn patient={d} />
                            </TableCell>
                            <TableCell>
                              <RemoveClientColumn onClick={() => setClientToRemove(d)} />
                            </TableCell>
                          </TableRow>
                        );
                      })
                    )}
                  </>
                )}
              </>
            )}
          </TableBody>
        </Table>

        <SheetContent
          side={'right'}
          onInteractOutside={(e) => {
            e.preventDefault();
            onCloseSheet();
          }}
          className="min-w-[100vw] xl:min-w-[50vw] overflow-y-auto overflow-x-hidden p-8 flex items-start justify-center bg-white"
        >
          {selectedPatientEntry && (
            <OnboardingPatientDetail id={selectedPatientEntry.id} chatClient={chatClient} />
          )}
        </SheetContent>
        <SheetClose
          onClick={(e) => {
            e.preventDefault();
            onCloseSheet();
          }}
        />
      </Sheet>
      <RemoveClientDialog client={clientToRemove} setClient={setClientToRemove} />
    </main>
  );
};
