import { action, makeObservable, observable, runInAction } from "mobx";
import axios from "axios";

class DossiersStore {
  dossiers = [];

  searchValue = "";

  page = 0;

  count = 0;

  type = "";

  constructor() {
    makeObservable(this, {
      dossiers: observable,
      searchValue: observable,
      count: observable,
      fetchDossiers: action,
      setSearchValue: action,
      setPage: action,
      setType: action,
      deleteDossier: action,
    });
  }

  async fetchDossiers() {
    this.dossiers = [];

    const res = await axios.get("dossiers", {
      params: {
        type: this.type,
        page: this.page,
        limit: 10,
        search: this.searchValue,
      },
    });

    runInAction(() => {
      this.dossiers = res.data.data;
      this.count = res.data.pagination.total;
    });
  }

  async createDossierGgz(values) {
    const res = await axios.post(`patients/${values.patientId}/dossiersGgz`, {
      epdPatientId: values.epdPatientId,
      epdFileId: values.epdFileId,
      paymentPercentage: values.paymentPercentage,
      paymentAfterwards: values.paymentAfterwards,
      isContractedHealthCare: values.isContractedHealthCare,
      isInsuredHealthCare: values.isInsuredHealthCare,
      isPayedByThirdParty: values.isPayedByThirdParty,
      bsn: values.bsn,
      dossierSubType: values.dossierSubType,
      healthCareInsurerType: values.selectedInsurerType,
    });

    runInAction(() => {
      this.dossiers.unshift(res.data);
    });

    return res.data;
  }

  async createDossierTransfer(values) {
    const res = await axios.post(
      `patients/${values.patientId}/dossiersTransfer`,
      {
        epdPatientId: values.epdPatientId,
        epdFileId: values.epdFileId,
        bsn: values.bsn,
      }
    );

    runInAction(() => {
      this.dossiers.unshift(res.data);
    });

    return res.data;
  }

  async deleteDossier(dossierId) {
    return axios.delete(`dossiers/${dossierId}`);
  }

  async createDossierOpenUp(values) {
    const res = await axios.post(
      `patients/${values.patientId}/dossiersOpenUp`,
      {
        yearOfBirth: values.yearOfBirth,
        company: values.company,
        isAnonymousCare: values.isAnonymousCare,
      }
    );

    runInAction(() => {
      this.dossiers.unshift(res.data);
    });

    return res.data;
  }

  setType(type) {
    this.type = type;
    this.page = 0;
    this.fetchDossiers();
  }

  setSearchValue(searchValue) {
    this.searchValue = searchValue;
    this.page = 0;
    this.fetchDossiers();
  }

  setPage(page) {
    this.page = page;
    this.fetchDossiers();
  }
}

export default DossiersStore;
