type SectionProps = {
  title: string;
  content: string;
};

export const Section = ({ title, content }: SectionProps) => {
  return (
    <section className="py-3">
      <p className="font-bold">{title}</p>
      <p
        dangerouslySetInnerHTML={{
          __html: (content || "-").replace(/\n/g, "<br />"),
        }}
      />
    </section>
  );
};
