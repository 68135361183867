import { useState } from "react";
import { Checkbox } from "../../../../../components/ui/checkbox";
import { Button } from "../../../../../components/ui/button";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../../../../components/ui/dialog";

type UndoChattedWithConfirmationDialogProps = {
  open: boolean;
  onOpenChange: (newValue: boolean) => void;
  onConfirmed: (neverShowAgain: boolean) => void;
};

export const UndoChattedWithConfirmationDialog = ({
  open,
  onOpenChange,
  onConfirmed,
}: UndoChattedWithConfirmationDialogProps) => {
  const [neverShowAgain, setNeverShowAgain] = useState(true);
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onOpenChange={(newValue) => {
        onOpenChange(newValue);
      }}
    >
      <DialogContent className="p-8">
        <DialogHeader>
          <DialogTitle className="text-2xl">
            {t("case-load-manager-remove-chatted-with-confirm-dialog-title")}
          </DialogTitle>
          <DialogDescription>
            <p className="pt-2">
              {t("case-load-manager-remove-chatted-with-confirm-dialog-desc")}
            </p>

            <div className="pt-4 flex items-center space-x-2">
              <Checkbox
                id="neverShowAgain"
                checked={neverShowAgain}
                onCheckedChange={() => setNeverShowAgain(!neverShowAgain)}
              />
              <label
                htmlFor="neverShowAgain"
                className={`text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 ${
                  neverShowAgain && "font-bold"
                }`}
              >
                {t(
                  "case-load-manager-chatted-with-confirm-dialog-never-show-again-caption"
                )}
              </label>
            </div>
          </DialogDescription>
          <DialogFooter className="flex justify-center">
            <Button
              className="px-6"
              onClick={() => onConfirmed(neverShowAgain)}
            >
              {t("case-load-manager-ok")}
            </Button>
          </DialogFooter>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};
