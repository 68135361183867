import React, { useEffect } from "react";
import { Grid, Box } from "@mui/material";
import { observer } from "mobx-react";
import EditPatient from "../../shared/EditPatient";
import useStores from "../../useStores";
import SQ48 from "../../shared/SQ48";
import EditDossierOpenUp from "./EditDossierOpenUp";
import Note from "./Note";
import Consults from "./Consults";
import Subscriptions from "./Subscriptions";
import Loading from "../../shared/Loading";
import Header from "../../shared/Header";
import StyledRoot from "../../shared/StyledRootDiv";
import { useParams } from "react-router-dom";

const DossierOpenUpPage = () => {
  const { patientStore } = useStores();
  const { patientId, dossierId } = useParams();

  useEffect(() => {
    patientStore.fetchPatientInformation(patientId, parseInt(dossierId));

    return function cleanup() {
      patientStore.reset();
    };
  }, [patientId, dossierId, patientStore]);

  if (
    patientStore.patient == null ||
    typeof patientStore.patient === "undefined" ||
    patientStore.dossier == null
  ) {
    return <Loading />;
  }

  return (
    <StyledRoot>
      <Header
        pageTitle="OpenUpper"
        title={`${patientStore.patient.firstName} ${patientStore.patient.lastName} (OU-${dossierId})`}
      />
      <Grid container spacing={4}>
        <Grid item xs={6} xl={4}>
          <Box mb={2}>
            <EditPatient />
          </Box>
          <EditDossierOpenUp />
        </Grid>
        <Grid item xs={6} xl={4}>
          <SQ48 />
        </Grid>
        <Grid item xs={6} xl={4}>
          <Note />
        </Grid>
        <Grid item xs={6} xl={4}>
          <Consults />
        </Grid>
        <Grid item xs={6} xl={4}>
          <Subscriptions dossierId={patientStore.dossier.id} />
        </Grid>
      </Grid>
    </StyledRoot>
  );
};

export default observer(DossierOpenUpPage);