import { observer } from "mobx-react";
import { Button, colors, TableCell, TableRow } from "@mui/material";
import React, { useState } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useSnackbar } from "notistack";
import { styled } from "@mui/system";
import StatusLabelBoolean from "./StatusLabelBoolean";
import CopyToClipboard from "./CopyToClipboard";
import { getReferralLetterUrl } from "../utils/urlHelpers";
import useStores from "../useStores";
import UploadReferralLetter from "./UploadReferralLetter";
import Label from "./Label";
import ConfirmDialog from "./ConfirmDialog";
import { queryClient } from "../react-query/provider";
import { Info } from "lucide-react";

const StyledCheckCircleOutlineIcon = styled(CheckCircleOutlineIcon)(
  ({ theme }) => ({
    marginRight: theme.spacing(1),
  })
);

const StyledGetAppOutlinedIcon = styled(GetAppOutlinedIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const StyledFloatingButton = styled(Button)(({ theme }) => ({
  float: "right",
  marginLeft: theme.spacing(1),
}));

const StyledFloatingButtonDiv = styled("div")(({ theme }) => ({
  float: "right",
  marginLeft: theme.spacing(1),
}));

const ReferralLetterRow = () => {
  const { onboardingStore, patientStore } = useStores();
  const { onboarding } = onboardingStore;
  const { enqueueSnackbar } = useSnackbar();

  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);

  const renderReferralLetterInfo = () => {
    return (
      <div>
        <StatusLabelBoolean
          boolean={onboarding.isReferralLetterValidated}
          positive="Aanwezig"
          negative="Aanwezig, niet gelezen"
        />
        {!onboarding.isReferralLetterValidated && (
          <StyledFloatingButton
            size="small"
            color="primary"
            variant="contained"
            onClick={() => onboardingStore.validateReferralLetter()}
          >
            <StyledCheckCircleOutlineIcon />
            Gelezen
          </StyledFloatingButton>
        )}
        <StyledFloatingButton
          size="small"
          color="primary"
          variant="contained"
          onClick={() =>
            onboardingStore
              .downloadReferralLetter()
              .catch((reason) => enqueueSnackbar(reason, { variant: "error" }))
          }
        >
          <StyledGetAppOutlinedIcon />
          Download
        </StyledFloatingButton>
        <StyledFloatingButton
          size="small"
          variant="contained"
          onClick={() => setIsConfirmDeleteOpen(true)}
        >
          <DeleteOutlineIcon />
        </StyledFloatingButton>
      </div>
    );
  };

  const renderReferralLetterUpload = () => {
    return (
      <div>
        <Label color={colors.orange[600]}>Ontbreekt</Label>
        <CopyToClipboard value={getReferralLetterUrl(onboarding.lookupGuid)} />
        <StyledFloatingButtonDiv>
          <UploadReferralLetter
            dossierType={onboarding.dossierType}
            dossierId={onboarding.id}
            onboardingStore={onboardingStore}
            patientStore={patientStore}
          />
        </StyledFloatingButtonDiv>
      </div>
    );
  };

  const getReferralLetterInfo = () => {
    if (!onboarding.isReferralLetterRequired) {
      return "Verwijsbrief niet nodig (onverzekerde zorg)";
    }
    return onboarding.isReferralLetterPresent
      ? renderReferralLetterInfo()
      : renderReferralLetterUpload();
  };

  const getIsCzOrOnvzScreeningDossier = () => {
    const currentDossier = onboarding?.patient?.dossiers?.filter(
      (d) => d.id == onboarding.id
    );

    if (!currentDossier || currentDossier.length === 0) return false;

    return (
      currentDossier[0].subType === "SCREENING" &&
      (currentDossier[0].healthCareInsurerType === "CZ" ||
        currentDossier[0].healthCareInsurerType === "ONVZ")
    );
  };

  return (
    <TableRow>
      <TableCell>
        <p>Verwijsbrief</p>

        {getIsCzOrOnvzScreeningDossier() && (
          <div className="text-xs w-full mt-3 flex items-center space-x-1 text-ipractice-blue">
            <div className="w-4 h-4">
              <Info className="w-4 h-4" />
            </div>
            <p>Niet verplicht</p>
          </div>
        )}
      </TableCell>
      <TableCell>
        {getReferralLetterInfo()}

        <ConfirmDialog
          question={`Weet je zeker dat je de verwijsbrief van ${onboarding.patient.firstName} ${onboarding.patient.lastName} wil verwijderen? Dit kan niet ongedaan gemaakt worden. De verwijsbrief in praktijkdata wordt niet automatisch verwijderd.`}
          isOpen={isConfirmDeleteOpen}
          onConfirm={() => {
            onboardingStore
              .deleteReferralLetter()
              .catch((reason) => enqueueSnackbar(reason, { variant: "error" }))
              .then(() => patientStore.fetchPatient(onboarding.patient.id))
              .catch((reason) => enqueueSnackbar(reason, { variant: "error" }))
              .then(() => setIsConfirmDeleteOpen(false));
          }}
          onCancel={() => setIsConfirmDeleteOpen(false)}
        />
      </TableCell>
    </TableRow>
  );
};

export default observer(ReferralLetterRow);
