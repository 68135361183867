import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../../../components/ui/dialog";
import { Button } from "../../../../components/ui/button";
import { CheckCircle2, UserRoundPlus } from "lucide-react";
import { useRegisterReferralAsPatient } from "../../hooks";
import { toast } from "sonner";
import { ReferralDetails } from "../../types";
import { MedebehandelaarsWarning } from "./medebehandelaarsWarning";

export const RegisterPatientButton = ({
  referral,
  enabled,
  variant,
}: {
  referral: ReferralDetails;
  enabled: boolean;
  variant: "default" | "outline";
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { mutate: save, isLoading } = useRegisterReferralAsPatient(() => {
    toast("Praktijkdata Registration", {
      icon: <CheckCircle2 className="h-5 w-5 text-green-600" />,
      description: "Praktijkdata Registration has been created",
    });
  });

  const handleOpenDialog = () => {
    setIsOpen(true);
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
  };

  const handleConfirm = async () => {
    await save(
      {
        referralId: referral.id,
      },
      {
        onSettled: () => setIsOpen(false),
      }
    );
  };

  return (
    <>
      <Button
        onClick={handleOpenDialog}
        disabled={!enabled}
        className="px-4 flex items-center space-x-2"
        variant={variant}
      >
        <div className="h-4 w-4">
          <UserRoundPlus className="h-4 w-4" />
        </div>
        <p>Send Registration</p>
      </Button>
      <ConfirmCreateDossierDialog
        referral={referral}
        open={isOpen}
        onOpenChange={handleCloseDialog}
        onConfirmed={handleConfirm}
        isSaving={isLoading}
      />
    </>
  );
};

const ConfirmCreateDossierDialog = ({
  referral,
  open,
  onOpenChange,
  onConfirmed,
  isSaving,
}: {
  referral: ReferralDetails;
  open: boolean;
  onOpenChange: (newValue: boolean) => void;
  onConfirmed: () => void;
  isSaving: boolean;
}) => {
  return (
    <Dialog
      open={open}
      onOpenChange={(newValue) => {
        onOpenChange(newValue);
      }}
    >
      <DialogContent className="max-w-[60vh] p-8">
        <DialogHeader>
          <DialogTitle className="text-2xl">
            Praktijkdata Registration
          </DialogTitle>
          <DialogDescription>
            <div>
              <p>
                Once you send the patient to Praktijkdata, the patient will be
                converted to a dossier in Praktijkdata. After pressing 'Yes,
                create patient', please go to Praktijkdata → Cliënten →
                Aanmeldingen, and convert the patient to a dossier.
              </p>

              <div className="mt-4 p-4 rounded border bg-blue-50">
                <MedebehandelaarsWarning
                  intakeBy={referral.intakeByPsychologistName!}
                  preIntakeBy={referral.preIntakeByPsychologistName!}
                />
              </div>
            </div>
          </DialogDescription>
          <DialogFooter className="space-x-2 pt-6">
            <Button
              variant="outline"
              className="w-1/2"
              onClick={() => onOpenChange(false)}
            >
              No, cancel
            </Button>
            <Button
              className="w-1/2"
              onClick={async (e) => {
                e.preventDefault();
                await onConfirmed();
              }}
            >
              {isSaving ? "Creating patient..." : "Yes, create patient"}
            </Button>
          </DialogFooter>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};
