import { useGetSq48ResultsOfDossier } from "./hooks";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import { getSimpleFormattedDateTime } from "../../../../utils/dateUtils";
import { FinishedIndicator } from "../finishedIndicator";
import { UnFinishedIndicator } from "../unFinishedIndicator";
import { SendToChatDataButton } from "./sendToChatButton";

export const Sq48ResultsOfDossier = ({
  epdPatientId,
  epdFileId,
}: {
  epdPatientId: number | null;
  epdFileId: number;
}) => {
  const { data, isLoading, isError } = useGetSq48ResultsOfDossier(
    epdPatientId,
    epdFileId
  );

  if (!epdPatientId) {
    return <></>;
  }

  const renderTableBody = () => {
    if (isError) {
      return (
        <TableRow>
          <TableCell colSpan={4}>
            Something went wrong - if you are sure the Client number is correct,
            please ping the dev team with the Client number{" "}
          </TableCell>
        </TableRow>
      );
    }

    if (isLoading) {
      return (
        <TableRow>
          <TableCell colSpan={4}>Loading...</TableCell>
        </TableRow>
      );
    }

    if (!data || data.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={4}>No completed SQ48s found.</TableCell>
        </TableRow>
      );
    }

    return data.map((d) => {
      return (
        <TableRow
          key={d.questionnaireId}
          className={
            d.isAlreadyProcessed ? "bg-green-50/50 hover:bg-green-50" : ""
          }
        >
          <TableCell>
            {getSimpleFormattedDateTime(d.questionnaireFinishedOn!)}
          </TableCell>
          <TableCell>
            {d.isAlreadyProcessed ? (
              <FinishedIndicator />
            ) : (
              <UnFinishedIndicator />
            )}
          </TableCell>
          <TableCell className="flex justify-end">
            <SendToChatDataButton questionnaireId={d.questionnaireId} />
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <section>
      <Table className="mt-8 border rounded">
        <TableHeader>
          <TableRow>
            <TableHead>Finished On</TableHead>
            <TableHead>Has been sent already?</TableHead>
            <TableHead />
          </TableRow>
        </TableHeader>
        <TableBody>{renderTableBody()}</TableBody>
      </Table>
    </section>
  );
};
