export default {
  LANGUAGE: "Language",
  LOCATION: "Location",
  GENDER: "Gender",
  TEAM: "Team",
  EMDR: "EMDR",
  SCHEMA_THERAPY_EXPERIENCE: "Schema Therapy Experience",
  EXPERIENCE: "Experience",
  STATUS: "Status",
};
