import { useUpdateNextStep } from "../hooks";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import { NextStep, ReferralDetails, RegistrationStatusType } from "../types";
import { Separator } from "../../../components/ui/separator";

export const getReadableNextStep = (nextStep: NextStep) => {
  switch (nextStep) {
    case NextStep.DeterminePatientApplicability:
      return "Determine patient applicability; calendly or reject";
    case NextStep.ReachOut:
      return "Reach out";
    case NextStep.January:
      return "January";
    case NextStep.WaitForFeedbackReRegistration:
      return "Wait for feedback re-registration";
    case NextStep.WaitForFeedbackCaseDiscussion:
      return "Wait for feedback case discussion";
    case NextStep.WaitForMissingInformation:
      return "Wait for missing information";
    case NextStep.ReachOutAfterReRegistrationOrCaseDiscussion:
      return "Reach out after re-registration or case discussion";
    case NextStep.ReachOutAfterAdhdScreening:
      return "Reach out after ADHD screening";
    case NextStep.PendingShort:
      return "Pending short";
    case NextStep.PendingLong:
      return "Pending long";
    case NextStep.InsurerWaitList:
      return "Insurer wait list";
    case NextStep.Closed:
      return "Closed";
    default:
      return "Unknown";
  }
};

export const getAllNextSteps = (
  nextSteps?: NextStep[]
): { value: NextStep; text: string }[] => {
  const allNextSteps = [
    {
      value: NextStep.DeterminePatientApplicability,
      text: getReadableNextStep(NextStep.DeterminePatientApplicability),
    },
    { value: NextStep.ReachOut, text: getReadableNextStep(NextStep.ReachOut) },
    { value: NextStep.January, text: getReadableNextStep(NextStep.January) },
    {
      value: NextStep.WaitForFeedbackReRegistration,
      text: getReadableNextStep(NextStep.WaitForFeedbackReRegistration),
    },
    {
      value: NextStep.WaitForFeedbackCaseDiscussion,
      text: getReadableNextStep(NextStep.WaitForFeedbackCaseDiscussion),
    },
    {
      value: NextStep.WaitForMissingInformation,
      text: getReadableNextStep(NextStep.WaitForMissingInformation),
    },
    {
      value: NextStep.ReachOutAfterReRegistrationOrCaseDiscussion,
      text: getReadableNextStep(
        NextStep.ReachOutAfterReRegistrationOrCaseDiscussion
      ),
    },
    {
      value: NextStep.ReachOutAfterAdhdScreening,
      text: getReadableNextStep(NextStep.ReachOutAfterAdhdScreening),
    },

    {
      value: NextStep.PendingShort,
      text: getReadableNextStep(NextStep.PendingShort),
    },
    {
      value: NextStep.PendingLong,
      text: getReadableNextStep(NextStep.PendingLong),
    },
    {
      value: NextStep.InsurerWaitList,
      text: getReadableNextStep(NextStep.InsurerWaitList),
    },
    { value: NextStep.Closed, text: getReadableNextStep(NextStep.Closed) },
  ];

  if (!nextSteps) {
    return allNextSteps;
  }

  const nextStepsSet = new Set(nextSteps);
  return allNextSteps.filter((step) => nextStepsSet.has(step.value));
};

export const getCurrentNextSteps = (
  referralStatus: RegistrationStatusType
): { value: NextStep; text: string }[] => {
  const allNextSteps = getAllNextSteps();

  if (referralStatus == "New")
    return allNextSteps.filter(
      (x) => x.value == NextStep.DeterminePatientApplicability
    );

  if (
    [
      "ReachOut",
      "RegisterPatientAndIntake",
      "AwaitingPatientApproval",
      "AwaitingIWelcomeEmailSend",
    ].includes(referralStatus)
  )
    return allNextSteps.filter((x) =>
      [
        NextStep.ReachOut,
        NextStep.January,
        NextStep.WaitForFeedbackReRegistration,
        NextStep.WaitForFeedbackCaseDiscussion,
        NextStep.WaitForMissingInformation,
        NextStep.ReachOutAfterReRegistrationOrCaseDiscussion,
        NextStep.ReachOutAfterAdhdScreening,
        NextStep.PendingShort,
        NextStep.PendingLong,
        NextStep.InsurerWaitList,
      ].includes(x.value)
    );

  return allNextSteps.filter((x) => x.value == NextStep.Closed);
};

export const NextStepSelector = ({
  referralId,
  referralStatus,
  value,
}: {
  referralId: string;
  referralStatus: RegistrationStatusType;
  value: NextStep;
}) => {
  const { mutate: updateNextStep } = useUpdateNextStep();

  return (
    <Select
      onValueChange={async (value) => {
        await updateNextStep({
          referralId: referralId,
          nextStep: value as NextStep,
        });
      }}
      value={value}
    >
      <SelectTrigger
        className="mt-2 z-[999999999]"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <SelectValue
          placeholder="Select the next step..."
          onClick={(e) => e.stopPropagation()}
        />
      </SelectTrigger>
      <SelectContent className="z-[999999999] max-h-[20rem]">
        {getCurrentNextSteps(referralStatus).map((x) => (
          <SelectItem value={x.value.toString()}>{x.text}</SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export const NextStepDetails = ({
  referral,
  value,
}: {
  referral: ReferralDetails;
  value: NextStep;
}) => {
  return (
    <div className="mb-12">
      <p className="text-lg font-medium leading-none">Next step</p>
      <Separator className="my-1" />
      <div className="flex justify-between items-center mt-2">
        <NextStepSelector
          referralId={referral.id}
          referralStatus={referral.status}
          value={value}
        />
      </div>
    </div>
  );
};
