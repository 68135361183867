import {
  extractURLsFromString,
  extractYoutubeUrlFromString,
  messageContentToString,
} from '../utils';
import { Message, MessageMetadata } from '../types';
import { Fragment, ReactNode, memo, useId, useMemo, useState } from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../../../../components/ui/accordion';
import { Info } from 'lucide-react';
import { HorizontalScrollArea } from './HorizontalScrollArea';
import { TherapistCard } from './TherapistCard';
import { ContentCard } from './ContentCard';
import { YoutubePreview } from './YouTubePreview';
import { cn } from '../../../../lib/utils';

const IPRACTICE_THERAPISTS = [
  {
    bio: 'Simone das Dores is a Clinical Psychologist and Psychotherapist at iPractice. Her working method is problem- and strength-oriented and, most importantly, value oriented. The techniques she uses include cognitive behavioural therapy but she likes to look at the bigger picture, taking inspiration from philosophy. Thanks to her extensive experience of working in hospitals (ZMC, ZGV and OLVG), Simone is an expert in the connection between the body and the mind.',
    firstName: 'Simone',
    image:
      'https://ipractice.com/wp-content/uploads/2021/10/simone-das-dores-ipractice-psycholoog.png',
    languages: ['en', 'nl'],
    lastName: 'das Dores',
    sessionUrl: 'https://ipractice.com/team/psychologist/simone-das-dores/',
    tags: ['Anxiety', 'Depression', 'PTSD', 'Stress'],
  },
  {
    bio: 'Bregje has many years of experience of helping clients with all kinds of psychological issues including depression, various types of anxiety, burnout, unprocessed memories, self-image problems, perfectionism and personality disorders.',
    firstName: 'Bregje',
    image:
      'https://ipractice.com/wp-content/uploads/2021/10/bregje-brenninkmeijer-ipractice-psycholoog.png',
    languages: ['en', 'nl'],
    lastName: 'Brenninkmeijer',
    sessionUrl: 'https://ipractice.com/team/psychologist/bregje-brenninkmeijer/',
    tags: ['Anxiety', 'Depression', 'Burn-out'],
  },
  {
    bio: 'Carlos Hoogenboom is a Healthcare Psychologist at iPractice. Within the framework of a safe and relaxed working relationship, we first examine what your obstacles currently are. During the treatment process you will become more aware of your pitfalls and you will learn to make the most of your strengths and skills.',
    firstName: 'Carlos',
    image:
      'https://ipractice.com/wp-content/uploads/2021/10/carlos-hoogenboom-ipractice-psycholoog.png',
    languages: ['en', 'nl'],
    lastName: 'Hoogenboom',
    sessionUrl: 'https://ipractice.com/team/psychologist/carlos-hoogenboom/',
    tags: ['Burn-out', 'Depression', 'PTSD', 'Sleep problems'],
  },
];

type Props = {
  children: ReactNode;
  message: Message;
  metadata?: MessageMetadata;
};

export const ChatMessageContainer = memo(function ChatMessageContainerNoMemo({
  children,
  message,
  metadata,
}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const id = useId();

  const contentUrls = useMemo(() => {
    const urlsInMessage = extractURLsFromString(messageContentToString(message), 3);

    if (urlsInMessage.length < 3) {
      const urlsInPageDocs = (metadata?.embeddings || [])
        .flatMap((embedding) =>
          embedding.url
            ? extractURLsFromString(embedding.url)
            : extractURLsFromString(embedding.chunk)
        )
        .filter((url) => url && !urlsInMessage.includes(url));

      return [...urlsInMessage, ...urlsInPageDocs.slice(0, 3 - urlsInMessage.length)] as string[];
    }

    return urlsInMessage;
  }, [message, metadata]);

  const youtubeUrls = useMemo(() => {
    // first check if any of the sourceDocs contain a youtube url
    const youtubeUrls = (metadata?.embeddings || [])
      .map((embedding) => {
        return embedding.url
          ? extractYoutubeUrlFromString(embedding.url)
          : extractYoutubeUrlFromString(embedding.chunk);
      })
      .filter(Boolean) as string[];

    if (youtubeUrls.length > 0) {
      return youtubeUrls;
    }

    // lastly, also check if message body contains a youtube url and extract if so
    const youtubeUrlInMessage = extractYoutubeUrlFromString(messageContentToString(message));

    return [youtubeUrlInMessage];
  }, [message, metadata]);

  const therapistCards = IPRACTICE_THERAPISTS;

  return (
    <Fragment>
      {children}

      {/* {metadata?.wellbeingScore && <WellbeingScoreCard wellbeingScore={metadata?.wellbeingScore} />} */}

      {/* {isSevereMessage && (
        <Accordion
          className={cn('mb-2 pl-10', {
            'mt-1': messageContentToString(message) === '',
          })}
          collapsible
          type="single"
        >
          <AccordionItem className="border-b-0" value="item-1">
            <div className="border-openup-gray -mt-3 mb-2 w-full rounded-2xl rounded-tl-[4px] border bg-white p-1 text-sm md:w-fit md:max-w-[768px]">
              <AccordionTrigger className="w-full px-2 py-3" onClick={() => setIsOpen(!isOpen)}>
                <Info className="mr-2 h-4 w-4" />
                <span className="text-openup-dark-blue mr-1 font-semibold">
                  Want to speak with an expert?
                </span>
              </AccordionTrigger>
            </div>

            {isOpen && (
              <div key={`${id}-ac-1`}>
                <HorizontalScrollArea>
                  <AccordionContent className="flex flex-row flex-nowrap gap-3 p-1 [&[data-state=open]]:mb-4">
                    <TherapistCard {...therapistCards[0]} />

                    <TherapistCard delay={150} {...therapistCards[1]} />

                    <TherapistCard delay={200} {...therapistCards[2]} />
                  </AccordionContent>
                </HorizontalScrollArea>
              </div>
            )}
          </AccordionItem>
        </Accordion>
      )} */}

      {contentUrls.length > 0 && (
        <Accordion className="pl-10" collapsible type="single">
          <AccordionItem className="border-b-0" value="item-1">
            <div className="border-openup-gray hover:shadow-openup-slight -mt-3 mb-3 w-full rounded-2xl rounded-tl-[4px] border bg-white p-1 text-sm md:w-fit md:max-w-[768px]">
              <AccordionTrigger className="w-full px-2 py-3">
                <Info className="mr-2 h-4 w-4" />

                <span className="text-openup-dark-blue mr-1 font-semibold">
                  Read more about this topic?
                </span>
              </AccordionTrigger>
            </div>

            <HorizontalScrollArea>
              <AccordionContent className="[&[data-state=open]]:mb-4">
                <div className="mb-3 flex gap-3">
                  {contentUrls.map((url) => (
                    <ContentCard key={url} url={url} />
                  ))}
                </div>
              </AccordionContent>
            </HorizontalScrollArea>
          </AccordionItem>
        </Accordion>
      )}

      {youtubeUrls && youtubeUrls.length > 0 && (
        <div className="ml-10 mr-auto">
          <HorizontalScrollArea>
            <div className="mb-3 flex gap-3">
              {youtubeUrls.filter(Boolean).map((url) => (
                <YoutubePreview key={url} url={url!} />
              ))}
            </div>
          </HorizontalScrollArea>
        </div>
      )}
    </Fragment>
  );
});
