import { createContext } from "react";

interface NavBarOpenStateContextProps {
  isNavBarOpen: boolean;
  setIsNavBarOpen: (newValue: boolean) => void;
}

export const NavBarOpenStateContext = createContext<
  NavBarOpenStateContextProps | undefined
>(undefined);
