import React, { useState } from "react";
import { styled } from "@mui/system";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";

const StyledCardRoot = styled(Card)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  outline: "none",
  boxShadow: theme.shadows[20],
  width: 700,
  maxHeight: "100%",
  overflowY: "auto",
  maxWidth: "100%",
}));

const StyledCardContent = styled(CardContent)(() => ({
  padding: 0,
}));

const StyledCardActions = styled(CardActions)(() => ({
  justifyContent: "flex-end",
}));

function DeactivationModal({ open, onClose, onDeactivation }) {
  const [deactivationReason, setDeactivationReason] = useState("");

  const handleDeactivationReasonChange = (event) => {
    setDeactivationReason(event.target.value);
  };

  if (!open) {
    return null;
  }

  return (
    <Modal onClose={onClose} open={open}>
      <StyledCardRoot>
        <CardHeader title="Bot deactiveren" />
        <Divider />
        <StyledCardContent>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Reden</TableCell>
                <TableCell>
                  <TextField
                    fullWidth
                    select
                    margin="dense"
                    name="deactivationReason"
                    onChange={handleDeactivationReasonChange}
                    required
                    value={deactivationReason}
                    variant="outlined"
                  >
                    <MenuItem key={1} value="Cliënt wil minder bots ontvangen">
                      Cliënt wil minder bots ontvangen
                    </MenuItem>
                    <MenuItem key={2} value="Cliënt vindt deze bot vervelend">
                      Cliënt vindt deze bot vervelend
                    </MenuItem>
                    <MenuItem
                      key={3}
                      value="Cliënt wil geen bots meer ontvangen"
                    >
                      Cliënt wil geen bots meer ontvangen
                    </MenuItem>
                    <MenuItem key={4} value="Cliënt reageert niet op deze bot">
                      Cliënt reageert niet op deze bot
                    </MenuItem>
                    <MenuItem key={5} value="Anders">
                      Anders
                    </MenuItem>
                  </TextField>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </StyledCardContent>
        <Divider />
        <StyledCardActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            disabled={deactivationReason === ""}
            color="primary"
            onClick={() => onDeactivation(deactivationReason)}
            variant="contained"
          >
            Deactiveer bot
          </Button>
        </StyledCardActions>
      </StyledCardRoot>
    </Modal>
  );
}

export default DeactivationModal;
