import { SortingState } from "@tanstack/react-table";
import { PatientColName } from "../columns/tableColumns/patientCol";
import { CheckinNeededColName } from "../columns/tableColumns/checkinNeededCol";
import { HasChattedWithColName } from "../columns/tableColumns/hasChattedWithCol";
import { IntakeOnColName } from "../columns/tableColumns/intakeOnCol";
import { LastRegularConsultOnColName } from "../columns/tableColumns/lastRegularConsultOnCol";
import { NextRegularConsultOnColName } from "../columns/tableColumns/nextRegularConsultOnCol";
import { LastChatConsultOnColName } from "../columns/tableColumns/lastChatConsultOnCol";
import { CaseLoadSortingColumns } from "../types";

type GetSortingDataResult = {
  column: CaseLoadSortingColumns;
  isAsc: boolean;
};

// todo: improve this
export const getSortingData = (state: SortingState): GetSortingDataResult => {
  const defaultSorting = {
    column: CaseLoadSortingColumns.Patient,
    isAsc: true,
  };
  if (!state || state.length === 0) {
    return defaultSorting;
  }

  switch (state[0].id) {
    case PatientColName:
      return {
        column: CaseLoadSortingColumns.Patient,
        isAsc: !state[0].desc,
      };

    case HasChattedWithColName:
      return {
        column: CaseLoadSortingColumns.Status,
        isAsc: !state[0].desc,
      };

    case IntakeOnColName:
      return {
        column: CaseLoadSortingColumns.IntakeOn,
        isAsc: !state[0].desc,
      };

    case LastRegularConsultOnColName:
      return {
        column: CaseLoadSortingColumns.LastRegularConsultOn,
        isAsc: !state[0].desc,
      };

    case NextRegularConsultOnColName:
      return {
        column: CaseLoadSortingColumns.NextRegularConsultOn,
        isAsc: !state[0].desc,
      };

    case LastChatConsultOnColName:
      return {
        column: CaseLoadSortingColumns.LastChatConsultOn,
        isAsc: !state[0].desc,
      };

    case CheckinNeededColName:
      return {
        column: CaseLoadSortingColumns.CheckinNeeded,
        isAsc: !state[0].desc,
      };

    default:
      return defaultSorting;
  }
};
