import {
  HorizontalTab,
  HorizontalTabHeader,
  HorizontalTabContent,
} from '../../../components/ui/horizontalTab';
import { ResyncCalendarAlgorithm } from './resyncCalendarAlgorithm/ResyncCalendarAlgorithm';
import { AlgorithmOutput } from './algorithmOutput/algorithmOutput';
import EmptySpotsAndTrajectoriesReport from '../../emptySpotsIntakeReport/report';

const CalendarAlgorithmAdmin = () => {
  return (
    <main className="w-full mt-4 mb-20 pr-12">
      <div className="bg-background rounded-lg p-10 -mt-[2px] min-h-[75vh]">
        <HorizontalTab>
          <div className="w-full mb-12">
            <div className="flex items-center ml-3">
              <HorizontalTabHeader index={0}>Calendar Algorithm</HorizontalTabHeader>
              <HorizontalTabHeader index={1}>Empty spots & new trajectories</HorizontalTabHeader>
            </div>
            <HorizontalTabContent index={0}>
              <AlgorithmOutput />
              <ResyncCalendarAlgorithm />
            </HorizontalTabContent>
            <HorizontalTabContent index={1}>
              <EmptySpotsAndTrajectoriesReport />
            </HorizontalTabContent>
          </div>
        </HorizontalTab>
      </div>
    </main>
  );
};

export default CalendarAlgorithmAdmin;
