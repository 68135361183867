import { Ban, CircleCheckBig } from "lucide-react";

type OffboardingStepStatusProps = {
  label: string;
  isSuccess: boolean;
};

export const OffboardingStepStatus = ({
  label,
  isSuccess,
}: OffboardingStepStatusProps) => {
  if (isSuccess) {
    return (
      <div className="flex items-center space-x-2 text-xs">
        <div className="w-3 h-3">
          <CircleCheckBig className="w-3 h-3" />
        </div>
        <p>{label}</p>
      </div>
    );
  } else {
    return (
      <div className="flex items-center space-x-2 text-xs text-red-600">
        <div className="w-3 h-3">
          <Ban className="w-3 h-3" />
        </div>
        <p>{label}</p>
      </div>
    );
  }
};
