import { ColumnDef } from "@tanstack/react-table";
import { ReferredPatient } from "../../../types";
import { useTranslation } from "react-i18next";

export const AssignedPsychologistColName = "Assigned Psychologist";

export const AssignedPsychologistCol: ColumnDef<ReferredPatient> = {
  id: AssignedPsychologistColName,
  accessorKey: "assignedPsychologist",
  size: 150,
  minSize: 100,
  header: ({ column }) => {
    const { t } = useTranslation();

    return <p>{"Assigned Psychologist"}</p>;
  },
  cell: ({ row }) => {
    const name = row.original.assignedPsychologist?.name;
    const email = row.original.assignedPsychologist?.email;

    return (
      <div>
        <p>{name}</p>
        <p className="pt-px text-xs">{email}</p>
      </div>
    );
  },
};
