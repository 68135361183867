import axios from "axios";
import { useMutation } from "react-query";
import { PreIntakeReportPositionTrajectory } from "./types";

export const useSaveComment = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({
      psychologistEpdId,
      comment,
    }: {
      psychologistEpdId: number;
      comment: string;
    }) => {
      const res = await axios.post(
        `preintakeadmin/${psychologistEpdId}/comment`,
        {
          comment,
        }
      );
      return res.data;
    },
    onSuccess: () => {
      onSuccess();
    },
  });

  return mutation;
};

export const useSaveTotalWorkingHours = (onError: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({
      psychologistEpdId,
      totalWorkingHours,
    }: {
      psychologistEpdId: number;
      totalWorkingHours: number;
    }) => {
      const res = await axios.post(
        `preintakeadmin/${psychologistEpdId}/total-working-hours`,
        {
          totalWorkingHours,
        }
      );
      return res.data;
    },
    onError: () => {
      onError();
    },
  });

  return mutation;
};

export const useSaveWorkingHoursAsOnlinePsychologist = (
  onError: () => void
) => {
  const mutation = useMutation({
    mutationFn: async ({
      psychologistEpdId,
      workingHoursAsOnlinePsychologist,
    }: {
      psychologistEpdId: number;
      workingHoursAsOnlinePsychologist: number;
    }) => {
      const res = await axios.post(
        `preintakeadmin/${psychologistEpdId}/working-hours-as-online-psychologist`,
        {
          workingHoursAsOnlinePsychologist,
        }
      );
      return res.data;
    },
    onError: () => {
      onError();
    },
  });

  return mutation;
};

export const useSavePsychologistPositionTrajectory = (onError: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({
      psychologistEpdId,
      positionTrajectory,
    }: {
      psychologistEpdId: number;
      positionTrajectory: PreIntakeReportPositionTrajectory;
    }) => {
      const res = await axios.post(
        `preintakeadmin/${psychologistEpdId}/position-trajectory`,
        {
          positionTrajectory,
        }
      );
      return res.data;
    },
    onError: () => {
      onError();
    },
  });

  return mutation;
};

export const useSavePreIntakeTarget = (onError: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({
      psychologistEpdId,
      weekStartDate,
      target,
    }: {
      psychologistEpdId: number;
      weekStartDate: Date;
      target: number;
    }) => {
      const res = await axios.post(
        `preintakeadmin/${psychologistEpdId}/pre-intake-target`,
        {
          weekStartDate,
          target,
        }
      );
      return res.data;
    },
    onError: () => {
      onError();
    },
  });

  return mutation;
};
