import React, { Component } from 'react';
import './CopyToClipboard.scss';

class CopyToClipboard extends Component {
    constructor(props) {
        super(props);

        const { size } = props;

        this.myRef = React.createRef();

        this.state = {
            copying: false,
            copyTickVectorClass: '',
            copyButtonVectorClass: '',
            size: size || 20
        };
    }

    copyToClipboard() {
        const valueWithFormatting = this.myRef.current.innerHTML;

        function listener(e) {
            e.clipboardData.setData("text/html", valueWithFormatting);
            e.clipboardData.setData("text/plain", valueWithFormatting);
            e.preventDefault();
        }
        document.addEventListener("copy", listener);
        document.execCommand("copy");
        document.removeEventListener("copy", listener);

        // start tick transition
        this.setState({
            copying: true,
            copyTickVectorClass: 'copy-transition-in',
        });

        // start to transition original button back in
        setTimeout(() => {
            this.setState({
                copyTickVectorClass: '',
                copyButtonVectorClass: 'copy-transition-in',
            });
        }, 500);
        setTimeout(() => {
            this.setState({
                copying: false,
            });
        }, 600);

        // reset classes
        setTimeout(() => {
            this.setState({
                copyButtonVectorClass: '',
            });
        }, 950);
    }

    render() {
        const {
         copying, copyTickVectorClass, copyButtonVectorClass, size
        } = this.state;
        const { value } = this.props;

        return (
          <div
            style={{
                    height: `${size}px`,
                    width: `${size}px`
                }}
            onClick={() => this.copyToClipboard()}
            className="copy-to-clipboard"
          >
            {copying ? (
              <svg
                className={copyTickVectorClass}
                width={`${size - 2}px`}
                height={`${size - 2}px`}
                fill="#ffffff"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
              </svg>
                ) : (
                  <svg
                    className={copyButtonVectorClass}
                    width={`${size - 2}px`}
                    height={`${size - 2}px`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#ffffff"
                    viewBox="0 0 24 24"
                  >
                    <path fill="none" d="M0 0h24v24H0V0z" />
                    <path
                      d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm-1 4H8c-1.1 0-1.99.9-1.99 2L6 21c0 1.1.89 2 1.99 2H19c1.1 0 2-.9 2-2V11l-6-6zM8 21V7h6v5h5v9H8z"
                    />
                  </svg>
                )}
              <div style={{ display: 'none' }} ref={this.myRef} dangerouslySetInnerHTML={{ __html: value }} />
          </div>
        );
    }
}

export default CopyToClipboard;
