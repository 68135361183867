import { toast } from "sonner";
import { useGetPreIntakeReport, useSchedulePreIntake } from "../../hooks";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { Button } from "../../../../components/ui/button";
import { ReferralDetails } from "../../types";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../components/ui/popover";
import { useEffect, useState } from "react";
import {
  CalendarIcon,
  CalendarSearch,
  Check,
  ChevronsUpDown,
} from "lucide-react";
import { getSimpleFormattedDate } from "../../../../utils/dateUtils";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import { Calendar } from "../../../../components/ui/calendar";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../../../../components/ui/command";
import { cn } from "../../../../lib/utils";
import { SchedulePreIntakeViaCaseLoadData } from "./schedulePreIntakeViaCaseLoadData";

export const SchedulePreIntakeButton = ({
  referral,
  isDialogOpen,
  setIsDialogOpen,
}: {
  referral: ReferralDetails;
  isDialogOpen: boolean;
  setIsDialogOpen: (newValue: boolean) => void;
}) => {
  const getDefaultTime = () => {
    if (!referral.preIntakeOn) {
      return "09:00";
    }

    let date = new Date(referral.preIntakeOn);
    let hours = date.getHours().toString().padStart(2, "0");
    let minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const [selectedTime, setSelectedTime] = useState<string>(getDefaultTime());
  const [selectedDate, setSelectedDate] = useState<Date>(
    referral.preIntakeOn ?? new Date()
  );

  const [isDateSelectorOpen, setIsDateSelectorOpen] = useState(false);

  const [isPsychologistFilterOpen, setIsPsychologistFilterOpen] =
    useState(false);

  const [selectedPsychologistEpdId, setSelectedPsychologistEpdId] = useState<
    number | null
  >(referral?.preIntakeByPsychologistEpdId);

  const getDefaultLocationFilter = () => {
    if (referral.intakeByPsychologistLocations == null) {
      // use client's treatment location if there's no CPR
      return referral.location?.name ?? "";
    } else if (referral.intakeByPsychologistLocations.length == 1) {
      // some CPRs have more than 1 location, but some have only 1 -> if so, use that
      return referral.intakeByPsychologistLocations[0];
    } else if (referral.intakeByPsychologistLocations.length > 1) {
      // if we have more than 1 CPR locations, we first check if one matches to the client's treatment location
      // if so, we can use that safely, otherwise we'll just pick the first location assigned to the CPR
      if (referral.location?.name != null) {
        const matches = referral.intakeByPsychologistLocations.filter((x) =>
          x.includes(referral.location!.name)
        );
        if (matches.length == 1) {
          return matches[0];
        }
      }

      return referral.intakeByPsychologistLocations[0];
    }

    return null;
  };

  const getDefaultTeamFilter = () => {
    if (
      referral.intakeByPsychologistTeams == null ||
      referral.intakeByPsychologistTeams.length > 1
    ) {
      return null;
    }

    return referral.intakeByPsychologistTeams[0];
  };

  const onSetTime = (date: Date, hourAndMinutes: string): Date => {
    // add 'value' as hours to 'setSelectedDate':
    const splitValue = hourAndMinutes.split(":");
    const hours = parseInt(splitValue[0]);
    const minutes = parseInt(splitValue[1]);

    // we need to create a new Date object, because we can't mutate the state directly
    const newDate = new Date(date);
    newDate.setHours(hours);
    newDate.setMinutes(minutes);

    setSelectedDate(newDate);

    return newDate;
  };

  useEffect(() => {
    if (referral.preIntakeOn) {
      setSelectedDate(referral.preIntakeOn);
    } else {
      onSetTime(new Date(), "09:00");
    }
  }, []);

  const { data: caseLoads, isLoading: isLoadingCaseLoads } =
    useGetPreIntakeReport(() => {});

  const { mutate: schedulePreIntake, isLoading: isSchedulingPreIntake } =
    useSchedulePreIntake(() => {
      setIsDialogOpen(false);

      toast("Intake scheduled", {
        description: "The intake has been successfully scheduled.",
      });
    });

  const generateTimeSlots = (
    startTime: string,
    endTime: string,
    interval: number
  ) => {
    const [startHours, startMinutes] = startTime.split(":").map(Number);
    const [endHours, endMinutes] = endTime.split(":").map(Number);
    const times: string[] = [];

    let currentHours = startHours;
    let currentMinutes = startMinutes;

    while (
      currentHours < endHours ||
      (currentHours === endHours && currentMinutes <= endMinutes)
    ) {
      // format to"HH:MM"
      const formattedTime = `${currentHours
        .toString()
        .padStart(2, "0")}:${currentMinutes.toString().padStart(2, "0")}`;

      times.push(formattedTime);

      // increment to next hour's :00, or by the interval
      currentMinutes += interval;
      if (currentMinutes >= 60) {
        currentHours += Math.floor(currentMinutes / 60);
        currentMinutes %= 60;
      }
    }

    return times;
  };

  return (
    <>
      <Button
        className="flex items-center space-x-2 px-4 w-fit"
        onMouseDown={() => {
          setIsDialogOpen(true);
        }}
      >
        <div className="h-4 w-4">
          <CalendarSearch className="h-4 w-4" />
        </div>
        <p>
          {referral.preIntakeOn
            ? "Re-schedule pre-intake"
            : "Schedule pre-intake"}{" "}
        </p>
      </Button>

      <Dialog
        open={isDialogOpen}
        onOpenChange={(e) => {
          setIsDialogOpen(e);
        }}
      >
        <DialogContent className="min-w-[80vw]">
          <DialogHeader>
            <DialogTitle>Schedule pre-intake</DialogTitle>
            <DialogDescription>
              Please find an online psychologist and a slot to indicate when the
              pre-intake should be scheduled to.
            </DialogDescription>

            <section className="pt-2 flex space-x-2">
              <div className="w-1/2">
                <p className="font-medium pb-1">Online Psychologist</p>
                <Popover
                  open={isPsychologistFilterOpen}
                  onOpenChange={setIsPsychologistFilterOpen}
                >
                  <PopoverTrigger asChild>
                    <Button
                      autoFocus
                      disabled={isLoadingCaseLoads}
                      variant="outline"
                      className="w-full justify-between"
                    >
                      {selectedPsychologistEpdId ? (
                        <p>
                          {
                            caseLoads?.find(
                              (x) => x.epdId === selectedPsychologistEpdId
                            )?.name
                          }
                        </p>
                      ) : (
                        <p className="font-normal opacity-70">
                          Select a psychologist...
                        </p>
                      )}
                      <div className="w-4 h-4">
                        <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                      </div>
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent
                    align="start"
                    side="bottom"
                    className="w-[400px] p-0 z-[9999999]"
                  >
                    <Command
                      filter={(value, search) => {
                        // value contains each entry in the dropdown list -> we find the full model of it:
                        const currentPsy = caseLoads
                          ?.map((x) => ({ epdId: x.epdId, name: x.name }))
                          ?.filter((p) => p.epdId.toString() === value);

                        if (currentPsy?.length === 0) return 0;

                        return currentPsy![0].name
                          .toLowerCase()
                          .includes(search.toLowerCase())
                          ? 1
                          : 0;
                      }}
                    >
                      <CommandInput placeholder="Zoek een psycholoog..." />
                      <CommandEmpty>Geen psycholoog gevonden.</CommandEmpty>
                      <CommandGroup className="max-h-[24rem] overflow-y-auto">
                        {caseLoads
                          ?.map((x) => ({ epdId: x.epdId, name: x.name }))
                          ?.toSorted((a, b) => a.name.localeCompare(b.name))
                          ?.map((employee) => {
                            return (
                              <CommandItem
                                key={employee.epdId}
                                value={employee.epdId.toString()}
                                onSelect={(currentValue) => {
                                  setSelectedPsychologistEpdId(
                                    Number(currentValue)
                                  );
                                  setIsPsychologistFilterOpen(false);
                                }}
                              >
                                <Check
                                  className={cn(
                                    "mr-2 h-4 w-4",
                                    selectedPsychologistEpdId === employee.epdId
                                      ? "opacity-100"
                                      : "opacity-0"
                                  )}
                                />
                                <p>{employee.name}</p>
                              </CommandItem>
                            );
                          })}
                      </CommandGroup>
                    </Command>
                  </PopoverContent>
                </Popover>
              </div>

              <div className="w-1/4">
                <p className="font-medium pb-1">Date</p>
                <Popover
                  open={isDateSelectorOpen}
                  onOpenChange={setIsDateSelectorOpen}
                >
                  <PopoverTrigger asChild>
                    <Button
                      className="w-full flex justify-between"
                      variant="outline"
                    >
                      <p>{getSimpleFormattedDate(selectedDate)}</p>
                      <div className="h-4 w-4">
                        <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                      </div>
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="z-[999999]" align="start">
                    <Calendar
                      mode="single"
                      fromDate={new Date()}
                      toDate={undefined}
                      selected={selectedDate}
                      onSelect={(_, d) => {
                        onSetTime(d, selectedTime);
                        setIsDateSelectorOpen(false);
                      }}
                      initialFocus
                      disabled={(d) => {
                        // none in past
                        if (d < new Date()) return true;

                        // weekends are NOT to select
                        if (d.getDay() == 6 || d.getDay() == 0) return true;

                        // rest is enabled
                        return false;
                      }}
                    />
                  </PopoverContent>
                </Popover>
              </div>

              <div className="w-1/4">
                <p className="font-medium pb-1">Time</p>
                <Select
                  onValueChange={(value) => {
                    setSelectedTime(value);
                    onSetTime(selectedDate, value);
                  }}
                  defaultValue={selectedTime}
                >
                  <SelectTrigger className="font-bold">
                    <SelectValue placeholder="Select a timeslot..." />
                  </SelectTrigger>
                  <SelectContent className="z-[9999999]">
                    {generateTimeSlots("08:00", "18:00", 15).map((dt, id) => {
                      return (
                        <SelectItem value={dt} key={id}>
                          {dt}
                        </SelectItem>
                      );
                    })}
                  </SelectContent>
                </Select>
              </div>
            </section>

            <section className="max-w-[77vw]">
              <SchedulePreIntakeViaCaseLoadData
                setSelectedPsychologistEpdId={setSelectedPsychologistEpdId}
                defaultLocationFilter={getDefaultLocationFilter()}
                defaultTeamFilter={getDefaultTeamFilter()}
                asCard
              />
            </section>

            <div className="pt-4 flex justify-end">
              <Button
                className="w-fit px-4"
                disabled={isSchedulingPreIntake || !selectedPsychologistEpdId}
                onClick={async () => {
                  await schedulePreIntake({
                    referralId: referral.id,
                    psychologistEpdId: selectedPsychologistEpdId!,
                    appointmentOn: selectedDate,
                  });
                }}
              >
                {isSchedulingPreIntake
                  ? "Confirming pre-intake..."
                  : "Confirm pre-intake"}
              </Button>
            </div>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </>
  );
};
