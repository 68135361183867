import { Loader2 } from "lucide-react";
import { useGetCaseLoadQuickCardKpisTotalNumberOfPraktijkdataBills } from "../../hooks";

export const TotalNumberOfPraktijkdataBillsCard = ({
  date,
}: {
  date: Date;
}) => {
  const { data, isLoading } =
    useGetCaseLoadQuickCardKpisTotalNumberOfPraktijkdataBills(date);

  return (
    <div className="rounded-lg border p-4 inline-block w-1/5">
      <p className="text-xs">Number of Praktijkdata bills</p>
      <h1 className="font-bold text-2xl">
        {isLoading ? (
          <Loader2 className="w-6 h-6 mt-1 opacity-70 animate-spin" />
        ) : (
          data!
        )}
      </h1>
    </div>
  );
};
