import { UserAssignedTag, UserTags, EpdUser } from "../psychologists/types";

// todo: these 'helper functions' could be improved so much more:

export const createTagObject = (
  tagType: string,
  selectedTags: string[] | string | boolean
) => {
  if (Array.isArray(selectedTags)) {
    // values like language/team/location can take on multiple values
    // so they are in an array
    return {
      type: tagType,
      tags: selectedTags,
    };
  } else {
    // values like emdr/Experience are boolean values
    return {
      type: tagType,
      tags: [selectedTags],
    };
  }
};

export const getTimeslotsAfterApplyingAllTags = (
  allTimeslots: unknown[],
  allUserTags: UserTags[],
  allAppliedTags: {
    tags: string[];
    type: string;
  }[]
) => {
  const allAppliedTagsWithValues = allAppliedTags.filter(
    (tag) => tag.tags.length > 0
  );

  if (allAppliedTagsWithValues.length == 0) return allTimeslots;

  const matchingUserIds = allUserTags
    .filter((userTag) =>
      allAppliedTagsWithValues.every((appliedTag) =>
        isMatchingOnTag(userTag.assignedTags, appliedTag)
      )
    )
    .map((r) => r.employee.id);

  return allTimeslots.filter((timeslot: any) =>
    matchingUserIds.some((userId) => timeslot.employee?.id === userId)
  );
};

export const getEmployeesAfterApplyingAllTags = (
  allEmployees: {
    employeeId: string;
  }[],
  allUserTags: UserTags[],
  allAppliedTags: {
    tags: string[];
    type: string;
  }[]
) => {
  const allAppliedTagsWithValues = allAppliedTags.filter(
    (tag) => tag.tags.length > 0
  );

  if (allAppliedTagsWithValues.length == 0) return allEmployees;

  const matchingUserIds = allUserTags
    .filter((userTag) =>
      allAppliedTagsWithValues.every((appliedTag) =>
        isMatchingOnTag(userTag.assignedTags, appliedTag)
      )
    )
    .map((r) => r.employee.id);

  return allEmployees.filter((employee) =>
    matchingUserIds.some((userId) => employee?.employeeId === userId)
  );
};

const isMatchingOnTag = (
  userAssignedTags: UserAssignedTag[],
  tagToFilterFor: {
    tags: string[];
    type: string;
  }
) =>
  userAssignedTags.some(
    (r) =>
      r.tagType.name === tagToFilterFor.type &&
      tagToFilterFor.tags.includes(r.tag.name)
  );
