import React from "react";
import { Typography } from "@mui/material";
import StyledRoot from "./StyledRootDiv";

const Header = ({ pageTitle, title, children }) => {
  return (
    <div style={{ padding: "2rem 0" }}>
      <Typography component="h2" gutterBottom variant="overline">
        {pageTitle}
      </Typography>
      <Typography component="h1" variant="h3">
        {title}
      </Typography>
      <div>{children}</div>
    </div>
  );
};

export default Header;
