import { Button } from "../../../components/ui/button";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CreateOffboarding } from "./actions/createOffboarding";
import { UndoMarkAsOffboarding } from "./actions/undoOffboarding";
import { UpdateOffboarding } from "./actions/updateOffboarding";
import { MainListPatient } from "../clients/types";
import { OffboardingStepStatus } from "./stepStatus";

type OffboardButtonProps = {
  data: MainListPatient;
  showLabel: boolean;
};

export const OffboardButton = ({ data, showLabel }: OffboardButtonProps) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const numberOfRegularConsults = data?.numberOfRegularConsults;
  const isMarkedAsOffboarding = data?.offboardingData !== null;
  const hasAnythingBeenProcessed =
    data?.offboardingData?.hasSentLastWireMessageToPatient ||
    data?.offboardingData?.hasSentTreatmentSummaryToPraktijkData ||
    data?.offboardingData?.hasSentWireConversationExportToPraktijkdata;

  if (!data) return <></>;

  if (hasAnythingBeenProcessed) {
    return (
      <div>
        <p className="font-bold">
          {t("case-load-manager-offboarding-main-list-col-feedback-title")}
        </p>
        <div className="">
          <div className="space-y-1 mt-2">
            <OffboardingStepStatus
              label={t(
                "case-load-manager-offboarding-main-list-col-feedback-treatment-plan-to-pd"
              )}
              isSuccess={
                data.offboardingData!.hasSentTreatmentSummaryToPraktijkData
              }
            />
            <OffboardingStepStatus
              label={t(
                "case-load-manager-offboarding-main-list-col-feedback-wire-message"
              )}
              isSuccess={data.offboardingData!.hasSentLastWireMessageToPatient}
            />
            <OffboardingStepStatus
              label={t(
                "case-load-manager-offboarding-main-list-col-feedback-wire-export"
              )}
              isSuccess={
                data.offboardingData!
                  .hasSentWireConversationExportToPraktijkdata
              }
            />
          </div>
        </div>
      </div>
    );
  }

  if (isMarkedAsOffboarding) {
    return (
      <div className="flex flex-col">
        <div className="p-2 rounded-lg bg-green-100 text-green-600 inline-block">
          <p>{t("case-load-manager-offboarding-next-consult-is-last")}</p>
        </div>
        <UpdateOffboarding
          id={data.offboardingData!.id}
          originalTreatmentSummary={data.offboardingData!.treatmentSummary}
          originalSendRequestToGoogleReviewToPatient={
            data.offboardingData!.sendRequestToGoogleReviewToPatient
          }
        />
        <UndoMarkAsOffboarding id={data.offboardingData!.id} />
      </div>
    );
  }

  if (!data.isSoonToBeDischarged) {
    return <></>;
  }

  return (
    <div>
      <p className={showLabel ? "block whitespace-pre-wrap" : "hidden"}>
        {t("case-load-manager-offboarding-main-button-caption", {
          count: numberOfRegularConsults,
        })}
      </p>
      <Button
        onClick={() => {
          setIsOpen(true);
        }}
        className="mt-1 whitespace-pre-wrap"
      >
        {t("case-load-manager-offboarding-main-button-label")}
      </Button>

      <CreateOffboarding
        currentWeekCaseLoadEntryId={data.id}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </div>
  );
};
