import { ReferralDetails, RegistrationStatus } from "../../types";

export const ReferralProgressInfoCompletion = ({
  referral,
}: {
  referral: ReferralDetails;
}) => {
  if (referral.status !== RegistrationStatus.Closed) return <></>;

  return (
    <div className="bg-green-100 dark:bg-green-950 px-12 py-1 text-xs text-green-600 -mx-[40px] mb-6">
      This is a closed referral.
    </div>
  );
};
