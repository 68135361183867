import { CircleCheckBig } from "lucide-react";
import { Button } from "../../../../components/ui/button";
import { ReferralDetails } from "../../types";
import { useMarkAsOkayToReachOutTo } from "../../hooks";
import { toast } from "sonner";

export const SetPatientAsOkayToReachOutToButton = ({
  referral,
  sendCalendlyInvite,
}: {
  referral: ReferralDetails;
  sendCalendlyInvite: boolean;
}) => {
  const { mutate: save, isLoading } = useMarkAsOkayToReachOutTo(() => {
    toast("Patient updated", {
      description: "The patient has been marked as OK to reach out to",
    });
  });

  return (
    <Button
      className="w-full flex items-center space-x-2"
      variant="success"
      onClick={async () =>
        await save({ referralId: referral.id, sendCalendlyInvite })
      }
    >
      {isLoading ? (
        <p>Saving...</p>
      ) : (
        <>
          <div className="w-4 h-4">
            <CircleCheckBig className="w-4 h-4" />
          </div>
          <p>Patient can be contacted</p>
        </>
      )}
    </Button>
  );
};
