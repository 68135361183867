import { MailX } from "lucide-react";
import { OnboardPatientMainListEntry } from "../types";
import { useTranslation } from "react-i18next";
import { getDidIntakeHappen } from "../utils";

type WelcomeMessageColumnProps = {
  patient: OnboardPatientMainListEntry;
};

export const WelcomeMessageColumn = ({
  patient,
}: WelcomeMessageColumnProps) => {
  const { t } = useTranslation();

  if (patient.welcomeMessageSent) {
    return (
      <div className="inline-block px-3 py-1 rounded-full text-xs font-bold border bg-green-100 border-green-400 text-green-600">
        {t("case-load-manager-onboarding-col-welcome-message-sent")}
      </div>
    );
  } else if (getDidIntakeHappen(patient) && !patient.welcomeMessageSent) {
    return t("case-load-manager-onboarding-kop-model-status-not-relevant");
  } else {
    return (
      <div className="flex items-center space-x-2">
        <div className="w-4 h-4">
          <MailX className="w-4 h-4" />
        </div>
        <p>{t("case-load-manager-onboarding-col-welcome-message-not-sent")}</p>
      </div>
    );
  }
};
