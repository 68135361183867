import React from "react";
import { styled } from "@mui/system";
import Calendar from "./Calendar";

const StyledRoot = styled("div")(({ theme }) => ({
  padding: theme.spacing(4),
}));

const CalendarPage = () => {
  return (
    <StyledRoot>
      <Calendar />
    </StyledRoot>
  );
};

export default CalendarPage;
