import { Copy, SendIcon } from "lucide-react";
import { Button } from "../../../../components/ui/button";
import { Separator } from "../../../../components/ui/separator";
import { useSendCalendlyInvite } from "../../hooks";
import { toast } from "sonner";
import { ReferralDetails } from "../../types";
import { CalendlyStatus } from "./calendlyStatus";
import { AddNewReachOutAttempt } from "../reachOut/addNewReachOutAttempt";
import { CannotSendWithoutMandatoryInformationWarning } from "./cannotSendWithoutMandatoryInformationWarning";

export const CalendlyDetails = ({
  isReadOnly,
  referral,
}: {
  isReadOnly: boolean;
  referral: ReferralDetails;
}) => {
  const { mutate: save, isLoading: isSending } = useSendCalendlyInvite(() => {
    toast("Calendly invite sent", {
      description: "The Calendly link has been sent to the patient",
    });
  });

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(
      referral.calendlyInvite?.calendlyLinkUrl || ""
    );
    toast("Calendly", {
      description: "Link copied to clipboard",
    });
  };

  if (
    referral.status === "New" ||
    referral.status === "Rejected" ||
    referral.status === "AwaitingPatientApproval" ||
    referral.status === "AwaitingIWelcomeEmailSend" ||
    referral.status === "Closed"
  )
    return <></>;

  const canShowCalendlyLink =
    referral != null &&
    referral.healthCareInsurer?.id != null &&
    referral.location?.id != null;

  return (
    <div className="mb-10">
      <div className="flex items-center space-x-2 w-full pb-px mb-1">
        <p className="text-lg font-medium leading-none">Calendly invite</p>
        <CalendlyStatus calendlyInvite={referral.calendlyInvite!} />
      </div>
      <Separator className="my-1" />

      <CannotSendWithoutMandatoryInformationWarning referral={referral} />

      <div
        className={`flex justify-between items-center pt-1 ${
          canShowCalendlyLink ? "visible" : "hidden"
        }`}
      >
        <div className="flex items-center space-x-2 overflow-hidden break-all">
          <Button
            variant="outline"
            onClick={copyToClipboard}
            className="flex items-center justify-center w-10 h-10 p-0"
          >
            <Copy className="h-5 w-5" />
          </Button>

          <a
            className={
              referral.calendlyInvite?.calendlyLinkUrl &&
              "text-link cursor-pointer font-medium hover:underline pr-2"
            }
            href={referral.calendlyInvite?.calendlyLinkUrl ?? "#"}
            target="_blank"
            rel="noopener noreferrer"
          >
            {referral.calendlyInvite?.calendlyLinkUrl || ""}
          </a>
        </div>

        {(referral.status === "ReachOut" ||
          referral.status === "RegisterPatientAndIntake") && (
          <Button
            className={`${canShowCalendlyLink ? "visible" : "hidden"}`}
            onClick={async () => {
              if (!isSending) {
                await save({ referralId: referral.id });
              }
            }}
            disabled={isReadOnly || referral.healthCareInsurer == null}
          >
            <SendIcon className="h-5 w-5 mr-2" />
            {isSending ? (
              "Sending..."
            ) : (
              <>
                {referral.calendlyInvite.status === "NotSent"
                  ? "Send"
                  : "Send again"}
              </>
            )}
          </Button>
        )}
      </div>

      <div className="pt-12">
        <AddNewReachOutAttempt referral={referral} />
      </div>
    </div>
  );
};
