import { useGetAssignedPatient } from "../../../hooks";
import { DossierNoLongerActiveAlert } from "./dossierNoLongerActiveAlert";
import { MultipleAlerts } from "./multipleAlerts";
import { SingleAlert } from "./singleAlert";

type AlertsProps = {
  clientId: string;
};

export const Alerts = ({ clientId }: AlertsProps) => {
  const { data } = useGetAssignedPatient(clientId);

  if (!data) return <></>;

  const numberOfAlerts = data.alerts.filter(
    (x) => !x.hasMarkedAsCompleted
  ).length;

  if (numberOfAlerts === 0) return <></>;

  const hasDossierNoLongerActiveAlert = data.alerts.some(
    (a) => a.code === "DossierNoLongerActive"
  );

  if (hasDossierNoLongerActiveAlert) {
    // this is a "special alert" -> takes precedence over all other alerts
    return <DossierNoLongerActiveAlert data={data} />;
  }

  if (numberOfAlerts === 1) {
    return <SingleAlert data={data} />;
  }

  return <MultipleAlerts data={data} />;
};
