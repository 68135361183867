import { useEffect, useState } from "react";
import {
  ReferralDetails,
  RejectionReasonClient,
  RejectionReasonIPractice,
} from "../../types";
import { Button } from "../../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
} from "../../../../components/ui/dialog";
import {
  RadioGroup,
  RadioGroupItem,
} from "../../../../components/ui/radio-group";
import { ReasonsClient } from "./reasonsIClient";
import { ReasonsIPractice } from "./reasonsIPractice";
import { Lock, Ban } from "lucide-react";
import { useRejectReferral } from "../../hooks";

export const RejectReferralButton = ({
  variant,
  referral,
}: {
  variant: "main-decision" | "header-button";
  referral: ReferralDetails;
}) => {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const [rejectedBy, setRejectedBy] = useState<
    "iPractice" | "client" | undefined
  >(undefined);

  const [rejectionReasonFromClient, setRejectionReasonFromClient] = useState<
    RejectionReasonClient | undefined
  >(undefined);

  const [rejectionReasonFromIPractice, setRejectionReasonFromIPractice] =
    useState<RejectionReasonIPractice | undefined>(undefined);

  const [justification, setJustification] = useState<string | undefined>(
    undefined
  );

  const [sendStandardRejectionEmail, setSendStandardSendRejectionEmail] =
    useState(false);

  const [sendFullyCustomRejectionEmail, setSendFullyCustomRejectionEmail] =
    useState(false);

  const [rejectionEmailUserInput, setRejectionEmailUserInput] = useState<
    string | undefined
  >(undefined);

  const resetValues = () => {
    setRejectionReasonFromClient(undefined);
    setRejectionReasonFromIPractice(undefined);
    setJustification(undefined);
    setSendStandardSendRejectionEmail(false);
    setSendFullyCustomRejectionEmail(false);
    setRejectionEmailUserInput(undefined);
  };

  useEffect(() => {
    // when changing the selected value in the radio buttons, we should clear the reasons (so we don't accidentally store 2 rejection reasons at the same time)
    resetValues();

    if (rejectedBy === "iPractice") {
      setSendStandardSendRejectionEmail(true);
    }
  }, [rejectedBy]);

  const { mutate: reject, isLoading: isRejecting } = useRejectReferral(() => {
    resetValues();
    setRejectedBy(undefined);
    setIsConfirmationDialogOpen(false);
  });

  const isSaveDisabled = () => {
    if (!rejectedBy) return true;

    if (!rejectionReasonFromIPractice && !rejectionReasonFromClient)
      return true;

    if (
      rejectionReasonFromIPractice == RejectionReasonIPractice.Other &&
      !justification
    )
      return true;

    return false;
  };

  if (
    (variant == "header-button" && referral.status === "New") ||
    referral.status === "Rejected" ||
    referral.status === "AwaitingPatientApproval" ||
    referral.status === "AwaitingIWelcomeEmailSend" ||
    referral.status === "Closed" ||
    referral.status === "Cancelled" ||
    referral.status === "Archived"
  ) {
    return <></>;
  }

  return (
    <>
      <Button
        variant={variant === "header-button" ? "outline" : "destructive"}
        onClick={(e) => {
          e.preventDefault();
          setIsConfirmationDialogOpen(true);
        }}
        className={`flex items-center space-x-2  ${
          variant === "header-button" ? "mt-4" : "w-full"
        }`}
      >
        {variant === "header-button" ? (
          <>
            <Lock className="h-5 w-5" />
            <p>Close referral</p>
          </>
        ) : (
          <>
            <Ban className="h-4 w-4" />
            <p>Reject referral</p>
          </>
        )}
      </Button>
      <Dialog
        open={isConfirmationDialogOpen}
        onOpenChange={(newValue) => {
          resetValues();

          if (!newValue) setRejectedBy(undefined);

          setIsConfirmationDialogOpen(newValue);
        }}
      >
        <DialogContent className="min-w-[60vw] p-8">
          <DialogHeader>
            <DialogTitle className="text-2xl">Reject referral</DialogTitle>

            <p className="font-bold pt-4">The referral is being rejected by:</p>

            <RadioGroup
              onValueChange={(e: "iPractice" | "client") => setRejectedBy(e)}
              className="flex items-center space-x-4"
            >
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="client" id="client" />
                <label htmlFor="client" className="cursor-pointer">
                  Rejected by client
                </label>
              </div>

              <div className="flex items-center space-x-2">
                <RadioGroupItem value="iPractice" id="iPractice" />
                <label htmlFor="iPractice" className="cursor-pointer">
                  Rejected by iPractice
                </label>
              </div>
            </RadioGroup>

            {rejectedBy === "client" && (
              <ReasonsClient
                selectedReason={rejectionReasonFromClient}
                setSelectedReason={setRejectionReasonFromClient}
                justification={justification}
                setJustification={setJustification}
              />
            )}
            {rejectedBy === "iPractice" && (
              <ReasonsIPractice
                referralLanguage={referral.language ?? "nl-NL"}
                patientName={`${referral.firstName} ${referral.lastName}`}
                selectedReason={rejectionReasonFromIPractice}
                setSelectedReason={setRejectionReasonFromIPractice}
                justification={justification}
                setJustification={setJustification}
                sendStandardRejectionEmail={sendStandardRejectionEmail}
                setSendStandardRejectionEmail={
                  setSendStandardSendRejectionEmail
                }
                sendFullyCustomRejectionEmail={sendFullyCustomRejectionEmail}
                setSendFullyCustomRejectionEmail={
                  setSendFullyCustomRejectionEmail
                }
                rejectionEmailUserInput={rejectionEmailUserInput}
                setRejectionEmailUserInput={setRejectionEmailUserInput}
              />
            )}
            <DialogFooter className="space-x-2 pt-6">
              <Button
                variant="outline"
                className="w-1/2"
                onClick={() => {
                  resetValues();
                  setIsConfirmationDialogOpen(false);
                }}
              >
                Cancel, keep referral
              </Button>
              <Button
                className="w-1/2"
                variant="destructive"
                disabled={
                  isSaveDisabled() ||
                  isRejecting ||
                  (sendFullyCustomRejectionEmail && !rejectionEmailUserInput)
                }
                onClick={async () =>
                  await reject({
                    referralId: referral.id,
                    isRejectedByClient: rejectedBy === "client",
                    rejectionReasonClient: rejectionReasonFromClient,
                    rejectionReasonIPractice: rejectionReasonFromIPractice,
                    justification,
                    sendRejectionEmail: sendStandardRejectionEmail,
                    sendFullyCustomEmailTemplate: sendFullyCustomRejectionEmail,
                    userInput: rejectionEmailUserInput,
                  })
                }
              >
                {isRejecting ? "Rejecting referral..." : "Reject referral"}
              </Button>
            </DialogFooter>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </>
  );
};
