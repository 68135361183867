import { ReferralDetails } from "../types";
import { Relevance } from "./fields/relevance";
import { HowDidYouHearAboutUs } from "./fields/howDidYouHearAboutUs";
import { Separator } from "../../../components/ui/separator";
import { RejectReferralButton } from "./reject/rejectReferralButton";
import { SetPatientAsOkayToReachOutToButton } from "./reachOut/setPatientAsOkayToReachOutToButton";
import { HealthcareInsurer } from "./fields/healthcareInsurer";
import { Language } from "./fields/language";
import { Checkbox } from "../../../components/ui/checkbox";
import { useEffect, useState } from "react";
import { Location } from "./fields/location";
import {
  CannotSendWithoutMandatoryInformationWarning,
  allInformationPresentToSendCalendly,
} from "./calendly/cannotSendWithoutMandatoryInformationWarning";
import { RecommendedOtherPractices } from "./recommendedOtherPractices";

export const IntakeDetails = ({
  referralDetails,
}: {
  referralDetails: ReferralDetails | undefined;
}) => {
  const getDefaultShouldSendCalendlyInvite = (relevance: string) => {
    if (!referralDetails) return false;

    const allInformationPresent =
      allInformationPresentToSendCalendly(referralDetails);

    return allInformationPresent && ["High", "Medium"].includes(relevance);
  };

  const [sendCalendlyInviteImmediately, setSendCalendlyInviteImmediately] =
    useState(
      getDefaultShouldSendCalendlyInvite(
        referralDetails?.relevance ?? "Unspecified"
      )
    );

  useEffect(() => {
    setSendCalendlyInviteImmediately(
      getDefaultShouldSendCalendlyInvite(
        referralDetails?.relevance ?? "Unspecified"
      )
    );
  }, [
    referralDetails?.email,
    referralDetails?.healthCareInsurer?.id,
    referralDetails?.location?.id,
  ]);

  const onChangeRelevance = (newRelevance: string) => {
    setSendCalendlyInviteImmediately(
      getDefaultShouldSendCalendlyInvite(newRelevance)
    );
  };

  if (
    referralDetails == null ||
    referralDetails?.status === "AwaitingIWelcomeEmailSend" ||
    referralDetails?.status === "Rejected" ||
    referralDetails?.status === "Closed"
  ) {
    return <></>;
  }

  const isReadOnly = referralDetails?.status === "AwaitingPatientApproval";

  return (
    <div className="w-full">
      <>
        {referralDetails!.status === "New" && (
          <>
            <div className="w-full flex items-center justify-between">
              <p className="text-lg font-medium leading-none">
                {"Patient Applicability"}
              </p>
            </div>
            <Separator className="mt-1 mb-2" />
            <Relevance
              referral={referralDetails!}
              onChangeRelevance={onChangeRelevance}
            />
          </>
        )}

        <RecommendedOtherPractices referral={referralDetails!} />

        {!isReadOnly && referralDetails?.status === "New" && (
          <div className="pt-4">
            <p className="font-medium">Decision</p>
            <p className="text-xs pb-3">
              Based on the referral and the relevance of it to iPractice, please
              select whether to reject the referral, or continue the onboarding
              process:
            </p>

            <CannotSendWithoutMandatoryInformationWarning
              referral={referralDetails}
            />

            <div className="flex py-2 items-center space-x-1">
              <Checkbox
                id="sendCalendlyInviteImmediately"
                disabled={referralDetails.healthCareInsurer == null}
                checked={sendCalendlyInviteImmediately}
                onCheckedChange={() => {
                  setSendCalendlyInviteImmediately((prev) => !prev);
                }}
              />
              <label
                htmlFor="sendCalendlyInviteImmediately"
                className={
                  sendCalendlyInviteImmediately ? "font-bold" : "font-normal"
                }
              >
                Send calendly invite immediately
              </label>
            </div>

            <div className="flex w-full space-x-2 pt-1">
              <div className="w-1/2">
                <RejectReferralButton
                  variant="main-decision"
                  referral={referralDetails!}
                />
              </div>

              <div className="w-1/2">
                <SetPatientAsOkayToReachOutToButton
                  referral={referralDetails!}
                  sendCalendlyInvite={sendCalendlyInviteImmediately}
                />
              </div>
            </div>
          </div>
        )}

        <div className="w-full flex items-center justify-between mt-4">
          <p className="text-lg font-medium leading-none">{"Details"}</p>
        </div>
        <Separator className="mt-1 mb-2" />
        {true && (
          <>
            <section className="space-y-4">
              <div className="flex items-start justify-between space-x-4">
                <div className="w-3/4">
                  <Location
                    isReadOnly={false}
                    referralId={referralDetails.id}
                    value={referralDetails.location}
                    referralStatus={referralDetails.status}
                  />
                </div>

                <div className="w-1/4">
                  <Language
                    isReadOnly={isReadOnly}
                    referralId={referralDetails!.id}
                    value={referralDetails!.language}
                  />
                </div>
              </div>
              <HealthcareInsurer
                isReadOnly={isReadOnly}
                referralId={referralDetails!.id}
                value={referralDetails!.healthCareInsurer?.id}
                referralStatus={referralDetails.status}
              />

              <HowDidYouHearAboutUs
                isReadOnly={isReadOnly}
                referralId={referralDetails!.id}
                value={referralDetails!.howDidYouHearAboutUs}
              />

              {/* {referralDetails?.intakeOn && (
                <div className="flex space-x-4 w-full">
                  <div className="w-1/2">
                    <p className="font-medium pb-1">Intake On</p>
                    <p className="px-3 py-2 rounded-lg border cursor-not-allowed opacity-60">
                      {getSimpleFormattedDate(referralDetails.intakeOn)}
                    </p>
                  </div>

                  <div className="w-1/2">
                    <p className="font-medium pb-1">Intake With</p>
                    <p className="px-3 py-2 rounded-lg border cursor-not-allowed opacity-60">
                      {referralDetails.intakeByPsychologistName}
                    </p>
                  </div>
                </div>
              )} */}
            </section>
          </>
        )}
      </>
    </div>
  );
};
