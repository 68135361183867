import React from "react";
import { Grid } from "@mui/material";
import CreatePatient from "./CreatePatient";
import StyledRoot from "../../shared/StyledRootDiv";

const CreatePatientPage = () => {
  return (
    <StyledRoot>
      <Grid container justifyContent="center">
        <Grid item xs={6}>
          <CreatePatient />
        </Grid>
      </Grid>
    </StyledRoot>
  );
};

export default CreatePatientPage;
