import { DocumentTypeEnum } from "../types";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import { Paperclip, Save } from "lucide-react";
import { useForm } from "react-hook-form";
import { useUploadReferralLetter } from "../hooks";
import { toast } from "sonner";

export const UploadReferralLetter = ({
  referralId,
  hasAlreadyReferral,
  documentType,
  open,
  onOpenChange,
}: {
  referralId: string;
  hasAlreadyReferral: boolean;
  open: boolean;
  documentType: DocumentTypeEnum;
  onOpenChange: (newValue: boolean) => void;
}) => {
  const form = useForm<any>({
    defaultValues: {},
  });
  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const firstFile = files[0];
      form.setValue("file", firstFile);
    }
  };

  const { mutate: save, isLoading: isSaving } = useUploadReferralLetter(() => {
    onOpenChange(false);

    toast("Referral letter uploaded", {
      description: "The new referral letter has been uploaded successfully",
    });
  });

  function onSubmit() {
    save({
      referralId,
      referralLetter: form.getValues().file,
      documentType: documentType,
    });
  }

  return (
    <Dialog
      open={open}
      onOpenChange={(newValue) => {
        onOpenChange(newValue);
      }}
    >
      <div className="flex flex-end">
        <Button
          variant="outline"
          onClick={() => {
            onOpenChange(true);
          }}
        >
          <Paperclip className="w-6 h-6 mr-2" />
          <p>{hasAlreadyReferral ? "Replace" : "Upload"}</p>
        </Button>
      </div>
      <DialogContent className="max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Upload Referral Letter</DialogTitle>
          <DialogDescription>
            Select the file that you would like to upload.
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="referralLetter"
              render={({ field }) => (
                <FormItem className="mb-2">
                  <FormControl>
                    <Input
                      type="file"
                      {...field}
                      accept=".pdf, image/*"
                      className="cursor-pointer"
                      onChange={onFileChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter>
              <Button
                type="submit"
                className="px-4 mt-3"
                disabled={!form.getValues().file}
              >
                <Save className="w-4 h-4 mr-2" />
                <p>{isSaving ? "Saving..." : "Save"}</p>
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
