import { StreamVideoParticipant } from "@stream-io/video-react-sdk";

export const removeDuplicatesByUserId = (
  data: StreamVideoParticipant[]
): StreamVideoParticipant[] => {
  const uniqueObjects: StreamVideoParticipant[] = [];
  const seenIds: Set<string> = new Set();

  for (const obj of data) {
    const id = obj.userId;
    if (!seenIds.has(id)) {
      uniqueObjects.push(obj);
      seenIds.add(id);
    }
  }

  return uniqueObjects;
};
