import React, { useState } from "react";
import { styled } from "@mui/system";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Modal,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import roleNames from "../../utils/constants/roleNames";
import { EpdIdsSyncedFromHiBobWarning } from "./epdIdsSyncedFromHiBobWarning";

const StyledCardRoot = styled(Card)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  outline: "none",
  boxShadow: theme.shadows[20],
  width: 700,
  maxHeight: "100%",
  overflowY: "auto",
  maxWidth: "100%",
}));

const StyledWireUserIdField = styled(TextField)(() => ({
  width: "45%",
}));

const StyledEpdIdField = styled(TextField)(() => ({
  width: "45%",
  marginLeft: "10px",
}));

const StyledBigRegistrationCodeField = styled(TextField)(() => ({
  width: "45%",
}));

const StyledCardActions = styled(CardActions)(() => ({
  justifyContent: "flex-end",
}));

const uiText = {
  user_settings_title: "Gebruikersinstellingen",
  roles_section_title: "Rollen",
  other_details_title: "Other details",
  wire_user_id_label: "Wire User Id",
  wire_user_id_helper_text: "Voer een geldige Wire User Id in.",
  epd_id_label: "EPD Id",
  big_registration_code_label: "BIG Registration Code",
  save_button: "Opslaan",
  cancel_button: "Annuleren",
  invalid_wire_user_id_alert:
    "Ongeldig formaat voor Wire User Id. Voer een geldige Wire User Id in.",
};

function UserSettingsModal({ open, onClose, onSave, user }) {
  const [roles, setRoles] = useState(user.roles);
  const [wireUserId, setWireUserId] = useState(user.wireUserId || "");
  const [isValidUUID, setIsValidUUID] = useState(true);
  const [epdId, setEpdId] = useState(user.epdId || "");
  const [bigRegistrationCode, setBigRegistrationCode] = useState(
    user.bigRegistrationCode || ""
  );

  // Regular expression for UUID validation
  const uuidRegex = /^[0-9A-Fa-f]{8}(?:-[0-9A-Fa-f]{4}){3}-[0-9A-Fa-f]{12}$/;

  if (!open) {
    return null;
  }

  // Handlers for changes in form fields
  const handleRoleChange = (event) => {
    const updatedRoles = event.target.checked
      ? [...roles, event.target.name]
      : roles.filter((roleName) => roleName !== event.target.name);
    setRoles(updatedRoles);
  };

  const handleWireUserIdChange = (event) => {
    const value = event.target.value;
    setWireUserId(value);
    setIsValidUUID(uuidRegex.test(value) || value === "");
  };

  const handleEpdIdChange = (event) => {
    const value = event.target.value;
    setEpdId(value);
  };

  const handleBigRegistrationCodeChange = (event) => {
    const value = event.target.value;
    setBigRegistrationCode(value);
  };

  // Handler for saving the form
  const handleSave = () => {
    if (isValidUUID) {
      onSave({ roles, wireUserId, epdId: Number(epdId), bigRegistrationCode });
      onClose();
    } else {
      alert(uiText.invalid_wire_user_id_alert);
    }
  };

  // Handler for closing the modal
  const handleClose = () => {
    setRoles(user.roles);
    setWireUserId(user.wireUserId || "");
    setEpdId(user.epdId || "");
    setBigRegistrationCode(user.bigRegistrationCode || "");
    onClose();
  };

  // Render modal with form
  return (
    <Modal onClose={handleClose} open={open}>
      <StyledCardRoot>
        <form>
          <CardHeader title={uiText.user_settings_title} />
          <Divider />
          <CardContent>
            <SectionTitle title={uiText.roles_section_title} />
            <RoleSelectionForm roles={roles} onRoleChange={handleRoleChange} />
            <Divider />
            <SectionTitle title={uiText.other_details_title} />
            <div className="text-xs">
              <EpdIdsSyncedFromHiBobWarning />
            </div>

            <WireUserIdField
              wireUserId={wireUserId}
              onWireUserIdChange={handleWireUserIdChange}
              isValidUUID={isValidUUID}
            />
            <EpdIdField epdId={epdId} onEpdIdChange={handleEpdIdChange} />
            <BigRegistrationCodeField
              bigRegistrationCode={bigRegistrationCode}
              onBigRegistrationCodeChange={handleBigRegistrationCodeChange}
            />
          </CardContent>
          <StyledCardActions>
            <Button onClick={handleClose}>{uiText.cancel_button}</Button>
            <Button
              color="primary"
              onClick={handleSave}
              variant="contained"
              disabled={!isValidUUID}
            >
              {uiText.save_button}
            </Button>
          </StyledCardActions>
        </form>
      </StyledCardRoot>
    </Modal>
  );
}

// Additional component for section titles
const SectionTitle = ({ title }) => (
  <Typography variant="h6">{title}</Typography>
);

// Component for roles selection form
const RoleSelectionForm = ({ roles, onRoleChange }) => (
  <FormControl component="fieldset">
    <FormGroup>
      {Object.keys(roleNames).map((key) => (
        <FormControlLabel
          key={key}
          control={
            <Checkbox
              checked={roles.includes(roleNames[key])}
              onChange={onRoleChange}
              name={roleNames[key]}
            />
          }
          label={roleNames[key]}
        />
      ))}
    </FormGroup>
  </FormControl>
);

// Component for WireUserId input field
const WireUserIdField = ({ wireUserId, onWireUserIdChange, isValidUUID }) => {
  return (
    <StyledWireUserIdField
      label={uiText.wire_user_id_label}
      value={wireUserId}
      onChange={onWireUserIdChange}
      margin="normal"
      error={!isValidUUID}
      helperText={!isValidUUID ? uiText.wire_user_id_helper_text : ""}
    />
  );
};

const EpdIdField = ({ epdId, onEpdIdChange }) => {
  return (
    <StyledEpdIdField
      label={uiText.epd_id_label}
      value={epdId}
      disabled
      onChange={onEpdIdChange}
      margin="normal"
      type="number"
      inputProps={{
        pattern: "[0-9]*",
      }}
    />
  );
};

const BigRegistrationCodeField = ({
  bigRegistrationCode,
  onBigRegistrationCodeChange,
}) => {
  return (
    <StyledBigRegistrationCodeField
      label={uiText.big_registration_code_label}
      value={bigRegistrationCode}
      onChange={onBigRegistrationCodeChange}
      margin="normal"
    />
  );
};

export default UserSettingsModal;
