import React from "react";
import Grid from "@mui/material/Grid";
import DossierOpenUpsTable from "./DossierOpenUpsTable";
import DossiersSearch from "../../shared/DossiersSearch";
import StyledRoot from "../../shared/StyledRootDiv";

const DossierOpenUpsPage = () => {
  return (
    <StyledRoot>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <DossiersSearch />
          <DossierOpenUpsTable />
        </Grid>
      </Grid>
    </StyledRoot>
  );
};

export default DossierOpenUpsPage;
