import { ReferralDetails } from "../../types";
import { TriangleAlert } from "lucide-react";

export const allInformationPresentToSendCalendly = (
  referral: ReferralDetails
) =>
  referral?.healthCareInsurer?.id != null &&
  referral?.location?.id != null &&
  referral?.email != null &&
  referral?.email?.trim() != "";

export const CannotSendWithoutMandatoryInformationWarning = ({
  referral,
}: {
  referral: ReferralDetails;
}) => {
  if (allInformationPresentToSendCalendly(referral)) return <></>;

  return (
    <div className="mb-2 rounded p-2 flex items-center space-x-2 bg-slate-100 text-slate-600">
      <div className="h-5 w-5">
        <TriangleAlert className="h-5 w-5" />
      </div>
      <p>
        Please specify the{" "}
        <span className="font-bold">
          Email, Healthcare Insurer and Treatment Location
        </span>{" "}
        to be able to send Calendly invites.
      </p>
    </div>
  );
};
