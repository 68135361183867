import axios from "axios";
import { UseQueryOptions, useQuery } from "react-query";

export const useIsEnabled = (
  featureFlag: string,
  options?: Omit<
    UseQueryOptions<unknown, unknown, boolean, string[]>,
    "queryKey" | "queryFn"
  >
) => {
  const query = useQuery(
    ["feature-management", featureFlag],
    async () => {
      const res = await axios.get(`feature-management/${featureFlag}`);
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      ...options,
    }
  );

  return query;
};
