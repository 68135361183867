import React, { useState } from "react";
import { styled } from "@mui/system";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Modal,
  Table,
  TableBody,
  TableRow,
  TableCell,
  FormControlLabel,
  Checkbox,
  TextField,
  MenuItem,
  Button,
} from "@mui/material";

const StyledCardRoot = styled(Card)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  outline: "none",
  boxShadow: theme.shadows[20],
  width: 900,
  maxHeight: "100%",
  overflowY: "auto",
  maxWidth: "100%",
}));

const StyledCardContent = styled(CardContent)(() => ({
  padding: 0,
}));

const StyledCardActions = styled(CardActions)(() => ({
  justifyContent: "flex-end",
}));

function DossierCompletionModal({ open, onClose, onCompletion, dossier }) {

  const [selectedClosingReasonKey, setSelectedClosingReasonKey] = useState("");
  const [closingReasonError, setClosingReasonError] = useState(false);
  const [freeTextClosingReason, setFreeTextClosingReason] = useState("");

  const handleSelectedClosingReasonChange = (event) => {
    setSelectedClosingReasonKey(event.target.value);

    if (event.target.value !== "other") {
      setFreeTextClosingReason("");
    }
  };

  const handleCompletion = () => {
    if (!selectedClosingReasonKey) {
      setClosingReasonError(true);
      return;
    }
    onCompletion(selectedClosingReasonKey, freeTextClosingReason);
  };

  const closingReasons = {
    successful_treatment_no_follow_up_needed:
      "Behandeling was succesvol, geen verdere opvolging nodig",
    successful_treatment_follow_up_at_openup:
      "Behandeling was succesvol, opvolging bij OpenUp",
    successful_treatment_referred_for_different_help:
      "Behandeling was succesvol, doorverwezen naar gespecialiseerde zorg voor een andere hulpvraag",
    referred_to_specialized_care_for_same_help:
      "Doorverwezen naar gespecialiseerde zorg voor dezelfde hulpvraag",
    treatment_ended_by_client_initiative:
      "Cliënt heeft op eigen initiatief de behandeling beëindigd",
    treatment_stopped_due_to_client_lack_of_motivation:
      "Cliënt had geen motivatie voor behandeling, gezamenlijk besloten te stoppen",
    other: "Andere (geef details)",
  };

  const titles = {
    dossier_close: "Dossier sluiten",
    payment_via_insurance: "Betaling via zorgverzekeraar",
    percentage: "Percentage",
    pay_later: "Achteraf betalen",
    closing_reason: "Reden voor sluiten",
    note_on_closing:
      "Het sluiten van het dossier stopt de maandelijkse incasso's en plant indien nodig een laatste transactie in.",
    close: "Sluiten",
    close_dossier: "Dossier sluiten",
    select_closing_reason: "Selecteer een reden voor afsluiting",
    specify_the_reason: "Specificeer de reden",
  };

  if (!open) return null;

  return (
    <Modal onClose={onClose} open={open}>
      <StyledCardRoot>
        <form>
          <CardHeader title={titles.dossier_close} />
          <Divider />
          <StyledCardContent>
            <Table>
              <TableBody>
                {dossier.billing.$type === "ContractedHealthCareBilling" ? (
                  <TableRow>
                    <TableCell>{titles.payment_via_insurance}</TableCell>
                  </TableRow>
                ) : (
                  <>
                    <TableRow>
                      <TableCell>{titles.percentage}</TableCell>
                      <TableCell>
                        {dossier.billing.paymentPercentage}%
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{titles.pay_later}</TableCell>
                      <TableCell>
                        <FormControlLabel
                          label={titles.pay_later}
                          disabled
                          size="small"
                          control={
                            <Checkbox
                              checked={dossier.billing.paymentAfterwards}
                              name="paymentAfterwards"
                            />
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </>
                )}
                <TableRow>
                  <TableCell>{titles.closing_reason}</TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      select
                      margin="dense"
                      name="closingReason"
                      onChange={handleSelectedClosingReasonChange}
                      required
                      value={selectedClosingReasonKey}
                      variant="outlined"
                      label={titles.closing_reason}
                      error={closingReasonError}
                      helperText={
                        closingReasonError ? titles.select_closing_reason : ""
                      }
                    >
                      {Object.keys(closingReasons).map((key) => (
                        <MenuItem key={key} value={key}>
                          {closingReasons[key]}
                        </MenuItem>
                      ))}
                    </TextField>
                    {selectedClosingReasonKey === "other" && (
                      <TextField
                        fullWidth
                        margin="dense"
                        name="freeTextClosingReason"
                        onChange={(e) =>
                          setFreeTextClosingReason(e.target.value)
                        }
                        value={freeTextClosingReason}
                        variant="outlined"
                        label={titles.specify_the_reason}
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell> </TableCell>
                  <TableCell>{titles.note_on_closing}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </StyledCardContent>
          <Divider />
          <StyledCardActions>
            <Button onClick={onClose}>{titles.close}</Button>
            <Button
              disabled={!selectedClosingReasonKey}
              color="primary"
              onClick={handleCompletion}
              variant="contained"
            >
              {titles.close_dossier}
            </Button>
          </StyledCardActions>
        </form>
      </StyledCardRoot>
    </Modal>
  );
}

export default DossierCompletionModal;
