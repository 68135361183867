import CalendarStore from "../../stores/CalendarStore";
import useStores from "../../useStores";
import { getPeriodDifference } from "../../utils/predictionPeriodHelper";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";

export type EventInfo = {
  timeText: string;
  borderColor: "#f87171" | "#16a34a" | "#fbbf24" | "#2563eb";
  el: HTMLElement;
  event: {
    extendedProps: Record<string, any>;
    start: Date;
    end: Date;
  };
};

const renderPredictedOccupancy = (
  calendarStore: CalendarStore,
  eventInfo: EventInfo,
  date: Date
) => {
  const occupancy = calendarStore.getPrediction(
    eventInfo.event.extendedProps.employee.id,
    getPeriodDifference(new Date(), date)
  );
  return occupancy === undefined
    ? ""
    : `${occupancy.predictedOccupancy}% (${eventInfo.event.extendedProps.remainingAllowedIntakesOfDay})`;
};

export const CalendarEvent = ({
  allowEventSelection,
  eventInfo,
  date,
  onSelectTimeslot,
}: {
  allowEventSelection: boolean;
  eventInfo: EventInfo;
  date: Date;
  onSelectTimeslot: (data: EventInfo) => void;
}) => {
  const { calendarStore } = useStores();

  const getCustomStyles = () => {
    const base = "p-px";

    switch (eventInfo.borderColor) {
      case "#16a34a":
        return `${base} from-green-100/50 to-green-50/50`;
      case "#fbbf24":
        return `${base} from-yellow-100/50 to-yellow-50/50`;
      case "#f87171":
        return `${base} from-red-100/50 to-red-50/50`;
      case "#2563eb":
        return `${base} from-blue-100 to-blue-50`;
      default:
        return `${base} from-gray-50 to-white`;
    }
  };

  return (
    <>
      <div
        className={`relative text-gray-700 bg-gradient-to-tr ${getCustomStyles()} h-full w-full truncate leading-tight ${
          allowEventSelection ? "cursor-pointer" : "cursor-default"
        }`}
        onClick={() => {
          onSelectTimeslot(eventInfo);
        }}
      >
        <div className="text-left">
          <p>
            <span className="font-bold text-gray-900">
              {eventInfo.timeText}{" "}
            </span>
            {renderPredictedOccupancy(calendarStore, eventInfo, date)}
          </p>
          <p>{eventInfo.event.extendedProps.employee.name}</p>
          <p className="italic">
            {eventInfo.event.extendedProps.location.name}
          </p>
        </div>
      </div>
    </>
  );
};

export const addHoverCardToEvents = (
  calendarStore: CalendarStore,
  eventInfo: EventInfo,
  date: Date
) => {
  const supervisedIntakeWarning = eventInfo.event.extendedProps
    .isForSupervisedIntake
    ? `<p class="pt-2 underline">This is a supervised intake.</p>`
    : "";

  return tippy(eventInfo.el, {
    allowHTML: true,
    placement: "left-start",
    content: () => {
      const tooltipContent = document.createElement("div");
      tooltipContent.innerHTML = `
            <div class="p-2">
              <h3 class="font-bold text-[16px]">${
                eventInfo.timeText
              } <span class="font-normal text-[15px]">${renderPredictedOccupancy(
        calendarStore,
        eventInfo,
        date
      )}</span></h3>
              <p>${eventInfo.event.extendedProps.employee.name}</p>
              <p class="italic">${
                eventInfo.event.extendedProps.location.name
              }</p>

              ${supervisedIntakeWarning}
            </div>
          `;
      return tooltipContent;
    },
  });
};
