import { useTranslation } from "react-i18next";
import { getSimpleFormattedDate } from "../../../../utils/dateUtils";
import { UndoAbsence } from "./undoAbsence";

type AbsenceHeaderProps = {
  absence: {
    startDate: string;
    endDate: string;
  } | null;
};

export const AbsenceHeader = ({ absence }: AbsenceHeaderProps) => {
  const { t } = useTranslation();

  if (absence == null) return <></>;

  return (
    <div className="bg-amber-600 dark:bg-amber-950 py-2 text-white dark:text-amber-500 -mt-[3px] flex justify-between items-center -mx-14 px-14">
      <p>
        <span>
          {t("case-load-manager-absence-header-1", {
            startDate: getSimpleFormattedDate(absence.startDate),
            endDate: getSimpleFormattedDate(absence.endDate),
          })}
        </span>{" "}
        <span>{t("case-load-manager-absence-header-2")}</span> <UndoAbsence />
      </p>
    </div>
  );
};
