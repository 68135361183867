import { CaseLoadWeeklyProgress } from "../types";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../../components/ui/tooltip";

type NeedsSupportClientsBarProps = {
  needsSupportCount: number;
  needsSupportPercent: number;
  data: CaseLoadWeeklyProgress;
};

export const NeedsSupportClientsBar = ({
  needsSupportCount,
  needsSupportPercent,
  data,
}: NeedsSupportClientsBarProps) => {
  return (
    <>
      {needsSupportCount == 0 ? (
        <></>
      ) : (
        <div
          style={{ width: `${needsSupportPercent}%` }}
          className={`flex items-center justify-center text-xs h-6 bg-slate-200 ${
            data.supportedClientCount + data.excludedClientCount == 0
              ? "rounded"
              : needsSupportPercent > 0
              ? "rounded-tr rounded-br"
              : ""
          }`}
        >
          <TooltipProvider>
            <Tooltip delayDuration={100}>
              <TooltipTrigger asChild>
                <p className="text-slate-400 font-bold">{needsSupportCount}</p>
              </TooltipTrigger>
              <TooltipContent
                className="text-center bg-slate-900 text-slate-300 p-2"
                side="bottom"
                align="center"
              >
                Out of the {data.totalClientCount - data.excludedClientCount}{" "}
                clients, {needsSupportCount} need supporting this week.
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      )}
    </>
  );
};
