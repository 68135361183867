import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";
import { Plus } from "lucide-react";
import { PersonalInfo } from "../referralDetails/personalInfo";
import { useAddReferralManually } from "../hooks";
import { toast } from "sonner";

export const AddManualReferral = ({
  open,
  onOpenChange,
  isLoading,
  openDetailsSheet,
}: {
  open: boolean;
  onOpenChange: (newValue: boolean) => void;
  isLoading: boolean;
  openDetailsSheet: (id: string) => void;
}) => {
  return (
    <Dialog
      open={open}
      onOpenChange={(newValue) => {
        onOpenChange(newValue);
      }}
    >
      <Button
        disabled={isLoading}
        className="flex items-center gap-2 min-w-40"
        onMouseDown={() => {
          onOpenChange(true);
        }}
      >
        <Plus className="w-5 h-5" />
        <p>Add referral manually</p>
      </Button>
      <DialogContent className="max-h-[95vh] max-w-5xl overflow-y-auto p-4">
        <DialogHeader>
          <DialogTitle className="text-2xl">Add referral manually</DialogTitle>
        </DialogHeader>

        <PersonalInfo
          isManualReferralInput = {true}
          withLanguage
          withHealthcareInsurer
          saveHook={() =>
            useAddReferralManually((id) => {
              onOpenChange(false);

              toast("Patient referral saved", {
                description: "The patient referral has been added successfully",
              });

              openDetailsSheet(id);
            })
          }
          saveRequestMapper={(data) => {
            return { manualReferralDetails: data };
          }}
        />
      </DialogContent>
    </Dialog>
  );
};
