import { ColumnDef } from "@tanstack/react-table";
import { ReferredPatient } from "../../types";
import { PatientCol } from "./distinctCols/patientCol";
import { PatientContactInfoCol } from "./distinctCols/patientContactInfoCol";
import { RelevanceCol } from "./distinctCols/relevanceCol";
import { StatusCol } from "./distinctCols/statusCol";
import { NextStepCol } from "./distinctCols/nextStepCol";
import {
  ManualStatusCol,
  ManualStatusColName,
} from "./distinctCols/manualStatusCol";
import { CalendlyInviteCol as CalendlyInviteSentCol } from "./distinctCols/calendlyInviteSentCol";
import { IntakeOnCol } from "./distinctCols/intakeOnCol";
import { PreIntakeOnCol } from "./distinctCols/preIntakeOnCol";
import { LocationCol } from "./distinctCols/locationCol";
import { DateOfReferralCol } from "./distinctCols/referralDateCol";
import { LanguageSelectorCol } from "./distinctCols/languageSelectorCol";
import { AssignedPsychologistCol } from "./distinctCols/assignedPsychologitCol";
import { HealthCareInsurerCol } from "./distinctCols/healthcareInsurerCol";
import { ReferralSourceCol } from "./distinctCols/referralSourceCol";

export const getColumns = (isManualStatusColEnabled: boolean) => {
  const allColumns = [
    NextStepCol,
    StatusCol,
    CalendlyInviteSentCol,
    DateOfReferralCol,
    RelevanceCol,
    ReferralSourceCol,
    ManualStatusCol,
    PatientCol,
    LocationCol,
    HealthCareInsurerCol,
    LanguageSelectorCol,
    PatientContactInfoCol,
    PreIntakeOnCol,
    IntakeOnCol,
    AssignedPsychologistCol,
  ];

  if (isManualStatusColEnabled) {
    return allColumns;
  } else {
    return allColumns.filter((x) => x.id != ManualStatusColName);
  }
};
