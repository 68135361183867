export const EpdIdsSyncedFromHiBobWarning = () => {
  return (
    <p>
      Note: the EPD IDs are automatically synchronized from HiBob, once every
      day (2 AM). You may validate the synchronization's correctness here, but
      this field is no longer editable. If you see a discrepancy, please contact
      the dev team.
    </p>
  );
};
