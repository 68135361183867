import { ChevronDownIcon, ChevronUpIcon } from "lucide-react";

export const Wrapper = ({
  isCaseLoadDataExpanded,
  setIsCaseLoadDataExpanded,
  children,
}: {
  isCaseLoadDataExpanded: boolean;
  setIsCaseLoadDataExpanded: (newValue: boolean) => void;
  children: any;
}) => {
  return (
    <div>
      <div
        className="p-4 rounded flex items-center justify-between cursor-pointer border"
        onMouseDown={() => setIsCaseLoadDataExpanded(!isCaseLoadDataExpanded)}
      >
        <p className="font-bold">Caseload of Online Psychologists</p>

        <div className="w-5 h-5">
          {isCaseLoadDataExpanded ? (
            <ChevronUpIcon className="w-5 h-5" />
          ) : (
            <ChevronDownIcon className="w-5 h-5" />
          )}
        </div>
      </div>
      {children}
    </div>
  );
};
