import { Status } from "../status";
import { SavedGermanClient } from "../types";
import { Skeleton } from "../../../../components/ui/skeleton";
import { useState } from "react";
import { Button } from "../../../../components/ui/button";
import {
  useGetiPracticeGermanyClient,
  useSendiAgreeEmailToiPracticeGermanyClient,
  useUpdateiPracticeGermanyClient,
} from "../hooks";
import { Name } from "./fields/name";
import { Email } from "./fields/email";
import { Phone } from "./fields/phone";
import { Treatment } from "./fields/treatment";
import { Info, Save, SendHorizontal } from "lucide-react";
import { toast } from "sonner";

export const ClientDetails = ({ clientId }: { clientId: string }) => {
  const [mutableClient, setMutableClient] = useState<SavedGermanClient | null>(
    null
  );

  const { data: originalClient, isLoading } = useGetiPracticeGermanyClient(
    clientId,
    (client) => setMutableClient(client)
  );

  const { mutate: resendEmail, isLoading: isResendingEmail } =
    useSendiAgreeEmailToiPracticeGermanyClient(() => {
      toast("Email sent", {
        description: `Successfully sent the iAgree email to the client.`,
      });
    });

  const { mutate: update, isLoading: isUpdating } =
    useUpdateiPracticeGermanyClient(() => {
      toast("Client updated", {
        description: `Successfully updated the client.`,
      });
    });

  const canSave = () => {
    const hasChanges =
      mutableClient != null &&
      (mutableClient.name !== originalClient?.name ||
        mutableClient.email !== originalClient?.email ||
        mutableClient.phone !== originalClient?.phone ||
        mutableClient.price !== originalClient?.price ||
        mutableClient.numberOfSessions !== originalClient?.numberOfSessions);

    return (
      hasChanges &&
      mutableClient.name.length > 0 &&
      mutableClient.email.length > 0
    );
  };

  if (isLoading || !mutableClient) {
    return (
      <div>
        <Skeleton className="w-[70px] h-5 rounded-full" />

        <Skeleton className="mt-2 w-full h-20" />

        <Skeleton className="mt-8 w-[50px] h-4" />
        <Skeleton className="mt-1 w-full h-12" />

        <div className="flex items-center space-x-4 mt-4">
          <div className="w-1/2">
            <Skeleton className="w-[50px] h-4" />
            <Skeleton className="mt-1 w-full h-12" />
          </div>

          <div className="w-1/2">
            <Skeleton className="w-[50px] h-4" />
            <Skeleton className="mt-1 w-full h-12" />
          </div>
        </div>

        <div className="flex items-center space-x-4 mt-8">
          <div className="w-1/2">
            <Skeleton className="w-[50px] h-4" />
            <Skeleton className="mt-1 w-full h-12" />
          </div>

          <div className="w-1/2">
            <Skeleton className="w-[150px] h-4" />
            <Skeleton className="mt-1 w-full h-12" />
          </div>
        </div>

        <Skeleton className="mt-12 w-1/6 h-12" />
      </div>
    );
  }

  return (
    <section className="w-full text-left flex flex-col">
      <Status client={mutableClient} />

      {mutableClient.status !== "Accepted" && (
        <div className="border-y py-4 my-4 w-full flex justify-between">
          <section className="flex items-center space-x-2">
            <div className="w-4 h-4">
              <Info className="w-4 h-4" />
            </div>
            <p>
              The iAgree email has been sent {mutableClient.numberOfEmailsSent}{" "}
              time(s) to the client.
            </p>
          </section>
          <Button
            className="px-4 flex items-center space-x-2"
            onClick={async () => await resendEmail({ id: clientId })}
          >
            <div className="w-4 h-4">
              <SendHorizontal className="w-4 h-4" />
            </div>
            <p>
              {mutableClient.numberOfEmailsSent === 0
                ? isResendingEmail
                  ? "Sending iAgree email..."
                  : "Send iAgree email"
                : isResendingEmail
                ? "Re-sending iAgree email"
                : "Re-send iAgree email"}
            </p>
          </Button>
        </div>
      )}

      <Name
        value={mutableClient.name}
        setValue={(newValue) =>
          setMutableClient({ ...mutableClient, name: newValue })
        }
      />

      <div className="flex items-center space-x-8">
        <div className="w-1/2">
          <Email
            value={mutableClient.email}
            setValue={(newValue) =>
              setMutableClient({ ...mutableClient, email: newValue })
            }
          />
        </div>

        <div className="w-1/2">
          <Phone
            value={mutableClient.phone}
            setValue={(newValue) =>
              setMutableClient({ ...mutableClient, phone: newValue })
            }
          />
        </div>
      </div>

      <div className="my-6">
        <Treatment
          price={mutableClient.price}
          setPrice={(newValue) =>
            setMutableClient({ ...mutableClient, price: newValue })
          }
          numberOfSessions={mutableClient.numberOfSessions}
          isOngoingSessions={mutableClient.isOngoingSessions}
          setNumberOfSessions={(num, isOngoingSessions) =>
            setMutableClient({
              ...mutableClient,
              numberOfSessions: num,
              isOngoingSessions,
            })
          }
        />
      </div>

      <div className="mt-4 mb-12">
        <Button
          disabled={!canSave()}
          className="px-4 flex items-center space-x-2"
          onClick={async () =>
            update({
              id: clientId,
              name: mutableClient.name,
              email: mutableClient.email,
              phone: mutableClient.phone,
              price: mutableClient.price,
              numberOfSessions: mutableClient.numberOfSessions,
              isOngoingSessions: mutableClient.isOngoingSessions,
            })
          }
        >
          <div className="w-4 h-4">
            <Save className="w-4 h-4" />
          </div>
          <p>{isUpdating ? "Saving changes..." : "Save changes"}</p>
        </Button>
      </div>
    </section>
  );
};
