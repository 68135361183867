import { Call, StreamVideoClient } from "@stream-io/video-react-sdk";
import { Dispatch, SetStateAction, useCallback } from "react";
import { ChatConversationDetails } from "../types";

export const initiateRingCall = (
  chatDetails: ChatConversationDetails,
  client: StreamVideoClient | null,
  callId: string,
  setCall: Dispatch<SetStateAction<Call | null>>
) =>
  useCallback(() => {
    if (client == null) return;

    const myCall = client.call("default", callId);

    myCall
      .getOrCreate({
        ring: true,
        data: {
          settings_override: {
            ring: {
              auto_cancel_timeout_ms: 120000, // max 3 min (2 seems more reasonable) https://getstream.io/video/docs/api/calls/#RingSettingsRequest
              incoming_call_timeout_ms: 120000,
              missed_call_timeout_ms: 120000,
            },
          },
          members: chatDetails.members.map((m) => ({
            user_id: m.userId,
          })),
        },
      })
      .catch((err) => {
        console.error(`Failed to join the call`, err);
      });

    setCall(myCall);

    return () => {
      setCall(null);
      myCall.endCall();
    };
  }, [client]);
