import { ColumnDef } from "@tanstack/react-table";
import { ReferredPatient } from "../../../types";
import { useTranslation } from "react-i18next";
import { DutchFlag, GbFlag } from "../../../../../shared/Flags";
import { Language } from "../../../types";

export const LanguageSelectorColName = "Language";

export const LanguageSelectorCol: ColumnDef<ReferredPatient> = {
  id: LanguageSelectorColName,
  accessorKey: "language",
  minSize: 100,
  maxSize: 100,
  header: ({ column }) => {
    const { t } = useTranslation();

    return <p>{"Language"}</p>;
  },
  cell: ({ row }) => {
    const languages: Language[] = [
      { code: "nl-NL", flag: <DutchFlag /> },
      { code: "en-US", flag: <GbFlag /> },
    ];
    const selectedLanguage = row.original.language;

    const selectedLanguageFlag = languages.find(
      (language) => language.code === selectedLanguage
    )?.flag;

    return <div>{selectedLanguageFlag}</div>;
  },
};
