import { Textarea } from "../../../../components/ui/textarea";
import { Button } from "../../../../components/ui/button";
import { useState } from "react";
import { toast } from "sonner";
import { useDownloadCohortStudyReport } from "./hooks";

export default function CohortStudyReport() {
  const [clientNumbers, setClientNumbers] = useState("");

  const { mutate, isLoading } = useDownloadCohortStudyReport((data) => {
    const url = window.URL.createObjectURL(
      new Blob([data], { type: "text/csv" })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `cohort_study_report_${new Date().toLocaleString()}.csv`
    );
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    link.remove();

    toast("Data downloaded", {
      description: "The Cohort Study report has been downloaded.",
    });
  });

  const parsePatients = () => {
    return clientNumbers.split("\n").map((line) => {
      return parseInt(line.trim());
    });
  };

  return (
    <main className="p-24">
      <div className="flex item-start justify-between w-full">
        <section>
          <h1 className="font-bold text-4xl">Cohort Study Report</h1>
          <p className="pt-2">
            Export data for Cohort Study reporting purposes.
          </p>
        </section>
      </div>

      <div className="mt-6">
        <label htmlFor="clientNumbers" className="font-bold pb-2">
          Client numbers
        </label>
        <Textarea
          className="w-[500px] max-h-[32rem] overflow-y-auto"
          id="clientNumbers"
          placeholder="Paste the client numbers here, each in a separate row"
          value={clientNumbers}
          onChange={(e) => setClientNumbers(e.target.value)}
          rows={15}
        />

        <Button
          className="mt-4 px-4"
          disabled={clientNumbers.length == 0}
          onClick={async () =>
            await mutate({
              epdPatientIds: parsePatients(),
            })
          }
        >
          {isLoading ? "Downloading..." : "Download"}
        </Button>
      </div>
    </main>
  );
}
