import { createContext } from "react";
import { MainListPatient } from "./types";

interface CaseLoadManagerSelectedPatientContextProps {
  selectedPatientEntry: MainListPatient | null;
  setSelectedPatientEntry: (newValue: MainListPatient | null) => void;
}

export const CaseLoadManagerSelectedPatientContext = createContext<
  CaseLoadManagerSelectedPatientContextProps | undefined
>(undefined);
