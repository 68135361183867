import { messageContentToString } from '../utils';
import { ReactNode, useEffect, useRef } from 'react';
import { Message } from '../types';
import { ChatMessageBubble } from './ChatMessageBubble';
import { ChatMessageLoadingIndicator } from './ChatMessageLoadingIndicator';
import { ChatMessageMarkdown } from './ChatMessageMarkdown';

type ChatMessageListProps = {
  children: ReactNode;
  threadId: string | null;
  handleSendQuestion: (text: string) => void;
  isBeginningOfChat: boolean;
  isStreaming: boolean;
  pendingMessage?: string;
  starterQuestions: string[];
};

export function ChatMessageList({
  children,
  threadId,
  handleSendQuestion,
  isBeginningOfChat,
  isStreaming,
  pendingMessage,
  starterQuestions,
}: ChatMessageListProps) {
  const afterLastMessageRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollTimeout = setTimeout(() => {
      if (afterLastMessageRef.current) {
        afterLastMessageRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      }
    }, 100);

    return () => clearTimeout(scrollTimeout);
  }, [threadId]);

  const pendingMessageObject: Message | undefined = pendingMessage
    ? {
        content: [
          {
            text: {
              value: pendingMessage,
            },
            type: 'text',
          },
        ],
        createdAt: new Date(),
        role: 'assistant',
      }
    : undefined;

  return (
    <div className="mx-auto flex w-full max-w-screen-lg max-h-[44vh] xl:max-h-[63vh] overflow-y-auto flex-col p-2">
      {children}

      {pendingMessageObject && (
        <ChatMessageBubble messageType={pendingMessageObject.role} shouldAnimateIn={false}>
          <ChatMessageMarkdown>{messageContentToString(pendingMessageObject)}</ChatMessageMarkdown>
        </ChatMessageBubble>
      )}

      {isBeginningOfChat && starterQuestions.length > 0 && (
        <div className="text-openup-dark-blue pb-4">
          <p className="text-sm font-bold">Ask anything! For example:</p>

          <ul className="ml-4 mt-1 list-disc italic">
            {starterQuestions.map((question) => (
              <li className="leading-6" key={question}>
                <button
                  className="text-left opacity-75 hover:opacity-100 cursor-pointer"
                  onClick={() => {
                    handleSendQuestion(question);
                  }}
                >
                  {question}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {isStreaming && !Boolean(pendingMessage) && <ChatMessageLoadingIndicator />}
      <div ref={afterLastMessageRef} className="h-px" />
    </div>
  );
}
