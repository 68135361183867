import React from "react";
import PsychologistsTable from "./PsychologistsTable";

const UpdatePsychologistTagsPage = () => {
  return (
    <main className="px-40 py-16">
      <h1 className="text-4xl font-bold">Tag Administration</h1>
      <p className="pt-2 pb-4">
        Manage user tags like Location, Status and Specialities of the
        psychologists.
      </p>
      <PsychologistsTable />
    </main>
  );
};

export default UpdatePsychologistTagsPage;
