import { RefreshCcw } from "lucide-react";
import { ReferralDetails } from "../types";

export const TransferReferralWarning = ({
  referral,
}: {
  referral: ReferralDetails;
}) => {
  return (
    <div
      className={`${
        referral.isTransferReferral
          ? "visible bg-gradient-to-r from-slate-200 to-transparent p-4 -mt-8 -ml-10 mb-6 pl-10 flex items-center space-x-2"
          : "hidden"
      }`}
    >
      <div className="h-4 w-4">
        <RefreshCcw className="h-4 w-4" />
      </div>
      <h1 className="text-lg">Transfer Referral</h1>
    </div>
  );
};
