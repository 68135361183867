import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  ColumnSizingState,
  ColumnDef,
  VisibilityState,
} from "@tanstack/react-table";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  DraggableCol,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import {
  ViewType,
  ReferredPatient,
  HealthcareInsurerContractFilter,
  ViewTypes,
  getReadableViewType,
  TransferReferralFilter,
  NextStep,
} from "../types";
import { TablePagination } from "../../../components/ui/table-pagination";
import { Pagination } from "../../../shared/pagination";
import { TableHeader as Header } from "./header/tableHeader";
import { getColumns } from "./columns/columns";
import { Error } from "../tableStates/error";
import { Loading } from "../tableStates/loading";
import { Fetching } from "../tableStates/fetching";
import {
  getDefaultColumnSizingFromLocalStorage,
  getDefaultColumnVisibilityFromLocalStorage,
} from "../utils";
import { useTranslation } from "react-i18next";
import { HealthCareInsurerColName } from "../referralOverview/columns/distinctCols/healthcareInsurerCol";
import { StatusColName } from "../referralOverview/columns/distinctCols/statusCol";
import { NextStepColName } from "../referralOverview/columns/distinctCols/nextStepCol";
import { LocationColName } from "../referralOverview/columns/distinctCols/locationCol";
import { ManualStatusColName } from "./columns/distinctCols/manualStatusCol";
import { useIsEnabled } from "../../../feature-management/useIsEnabled";
import {
  HorizontalTab,
  HorizontalTabHeader,
  HorizontalTabContent,
  TabContext,
} from "../../../components/ui/horizontalTab";
import { ReferralsTab } from "./referralsTab";

interface ReferralsTableProps {
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  data: ReferredPatient[];
  onRowClick: (id: string) => void;
  sorting: SortingState;
  setSorting: Dispatch<SetStateAction<SortingState>>;
  pageSize: number;
  setPageSize: (newSize: number) => void;
  pagination: Pagination | undefined;
  skip: number;
  setSkip: (newSkip: number) => void;
  searchTerm: string;
  setSearchTerm: (newSearchTerm: string) => void;
  registrationStatuses: string[];
  setRegistrationStatuses: Dispatch<SetStateAction<string[]>>;
  nextSteps: string[];
  setNextSteps: Dispatch<SetStateAction<string[]>>;
  locationIds: string[];
  setLocationIds: Dispatch<SetStateAction<string[]>>;
  healthcareInsurerIds: string[];
  setHealthcareInsurerIds: Dispatch<SetStateAction<string[]>>;
  viewType: ViewType;
  setViewType: (newValue: ViewType) => void;
  transferFilter: TransferReferralFilter;
  setTransferFilter: Dispatch<SetStateAction<TransferReferralFilter>>;
  contractFilter: string;
  setContractFilter: Dispatch<SetStateAction<HealthcareInsurerContractFilter>>;
}

export function ReferralsTable({
  isLoading,
  isFetching,
  isError,
  data,
  onRowClick,
  sorting,
  setSorting,
  pageSize,
  setPageSize,
  pagination,
  skip,
  setSkip,
  searchTerm,
  setSearchTerm,
  registrationStatuses,
  setRegistrationStatuses,
  nextSteps,
  setNextSteps,
  locationIds,
  setLocationIds,
  healthcareInsurerIds,
  setHealthcareInsurerIds,
  viewType,
  setViewType,
  transferFilter,
  setTransferFilter,
  contractFilter,
  setContractFilter,
}: ReferralsTableProps) {
  const { t } = useTranslation();
  const [addNewPatientOpen, setAddNewPatientOpen] = useState(false);
  const [rowSelection, setRowSelection] = useState({});

  const [colSizing, setColSizing] = useState<ColumnSizingState>(
    getDefaultColumnSizingFromLocalStorage()
  );

  const { data: isManualStatusColEnabled } = useIsEnabled(
    "EnableShowTodayStatusInCrmWorkingView"
  );

  const columns = getColumns(isManualStatusColEnabled ?? false);

  const getDefaultMutableColumns = () => {
    const fromLocalStorage = localStorage.getItem("crm-column-order");

    if (!fromLocalStorage) {
      return viewType === ViewTypes.workingView
        ? columns
        : columns.filter((column) => column.id !== ManualStatusColName);
    }

    // in localstorage, we may have a list of sorted IDs
    // can't persist the whole column definition because it contains functions -> can't JSON stringify
    const parsedOrder = JSON.parse(fromLocalStorage) as string[];

    return columns
      .filter((c) => {
        // manual status column is only visible in the working view
        return viewType === ViewTypes.workingView
          ? true
          : c.id !== ManualStatusColName;
      })
      .toSorted(
        (a, b) => parsedOrder.indexOf(a.id!) - parsedOrder.indexOf(b.id!)
      );
  };

  const [mutableColumns, setMutableColumns] = useState(
    getDefaultMutableColumns()
  );

  const onSetMutableColumns = (newColumns: ColumnDef<ReferredPatient>[]) => {
    // we store only the sorted list of IDs in local storage
    localStorage.setItem(
      "crm-column-order",
      JSON.stringify(newColumns.map((n) => n.id))
    );
    setMutableColumns(newColumns);
  };

  useEffect(() => {
    setMutableColumns(getDefaultMutableColumns());
  }, [viewType, isManualStatusColEnabled]);

  useEffect(() => {
    localStorage.setItem("crm-column-sizing", JSON.stringify(colSizing));
  }, [colSizing]);

  const [colVisibility, setColVisibility] = useState<VisibilityState>(
    getDefaultColumnVisibilityFromLocalStorage()
  );

  useEffect(() => {
    localStorage.setItem(
      "crm-column-visibility",
      JSON.stringify(colVisibility)
    );
  }, [colVisibility]);

  const table = useReactTable({
    data,
    columns: mutableColumns,
    getCoreRowModel: getCoreRowModel(),
    onColumnVisibilityChange: setColVisibility,
    onColumnSizingChange: setColSizing,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    manualPagination: true,
    manualSorting: true,
    state: {
      columnVisibility: colVisibility,
      columnSizing: colSizing,
      rowSelection,
      sorting,
    },
  });

  useEffect(() => {
    const filtersToSave = [
      { id: StatusColName, value: registrationStatuses },
      { id: NextStepColName, value: nextSteps },
      { id: HealthCareInsurerColName, value: healthcareInsurerIds },
      { id: LocationColName, value: locationIds },
      {
        id: "TransferReferralFilter",
        value:
          transferFilter != null && transferFilter != TransferReferralFilter.All
            ? [transferFilter.toString()]
            : [],
      },
      {
        id: "IsContractedInsurer",
        value:
          contractFilter &&
          contractFilter != HealthcareInsurerContractFilter.All
            ? [contractFilter]
            : [],
      },
    ];

    localStorage.setItem("crm-filters", JSON.stringify(filtersToSave));
    table.setColumnFilters(filtersToSave);
  }, [
    registrationStatuses,
    nextSteps,
    healthcareInsurerIds,
    transferFilter,
    locationIds,
    contractFilter,
  ]);

  const renderNoResults = () => {
    if (viewType !== ViewTypes.fullView) {
      return (
        <TableRow>
          <TableCell colSpan={columns.length} className="py-6 px-4">
            <p className="text-lg">No referrals found.</p>
            <p className="pt-1">
              It looks like you are in the {getReadableViewType(viewType)}.{" "}
              <span
                className="text-link cursor-pointer hover:underline"
                onClick={() => setViewType(ViewTypes.fullView)}
              >
                Try switching to the {getReadableViewType(ViewTypes.fullView)}.
              </span>
            </p>
          </TableCell>
        </TableRow>
      );
    }

    return (
      <TableRow>
        <TableCell colSpan={columns.length} className="py-6 px-4">
          <p className="text-lg">No referrals found.</p>
        </TableCell>
      </TableRow>
    );
  };

  const nextStepsValues = [
    {
      title: "Reach out now",
      values: [
        NextStep.ReachOut,
        NextStep.ReachOutAfterReRegistrationOrCaseDiscussion,
        NextStep.ReachOutAfterAdhdScreening,
      ],
    },
    {
      title: "Pending client",
      values: [NextStep.PendingShort, NextStep.PendingLong],
    },
    { title: "January", values: [NextStep.January] },
    {
      title: "iPractice input needed",
      values: [
        NextStep.WaitForFeedbackReRegistration,
        NextStep.WaitForFeedbackCaseDiscussion,
        NextStep.WaitForMissingInformation,
      ],
    },
    { title: "Insurer waitlist", values: [NextStep.InsurerWaitList] },
    { title: "All", values: Object.values(NextStep) },
  ];

  const [possibleNextSteps, setPossibleNextSteps] = useState(
    nextStepsValues[0].values
  );

  useEffect(() => {
    if (viewType !== ViewTypes.workingView) {
      setNextSteps([]);
      setPossibleNextSteps(Object.values(NextStep));
    } else {
      setNextSteps(nextStepsValues[0].values);
      setPossibleNextSteps(nextStepsValues[0].values);
    }
  }, [viewType]);

  return (
    <div className="w-full mt-16 mb-20 pr-12">
      <Header
        isLoading={isLoading}
        table={table}
        openDetailsSheet={onRowClick}
        pageSize={pageSize}
        setPageSize={setPageSize}
        addNewPatientOpen={addNewPatientOpen}
        setAddNewPatientOpen={setAddNewPatientOpen}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        registrationStatuses={registrationStatuses}
        setRegistrationStatuses={setRegistrationStatuses}
        nextSteps={nextSteps}
        setNextSteps={setNextSteps}
        locationIds={locationIds}
        setLocationIds={setLocationIds}
        healthcareInsurerIds={healthcareInsurerIds}
        setHealthcareInsurerIds={setHealthcareInsurerIds}
        viewType={viewType}
        setViewType={setViewType}
        transferFilter={transferFilter}
        setTransferFilter={setTransferFilter}
        contractFilter={contractFilter}
        setContractFilter={setContractFilter}
        possibleNextSteps={possibleNextSteps}
      />
      {viewType !== ViewTypes.workingView ? (
        <div className="mt-8">
          <ReferralsTab
            isError={isError}
            isFetching={isFetching}
            isLoading={isLoading}
            onRowClick={onRowClick}
            mutableColumns={mutableColumns}
            onSetMutableColumns={onSetMutableColumns}
            renderNoResults={renderNoResults}
            table={table}
            pagination={pagination}
            pageSize={pageSize}
            skip={skip}
            setSkip={setSkip}
          />
        </div>
      ) : (
        <HorizontalTab>
          <div className="w-full mt-6">
            <div className="flex items-center ml-3">
              {nextStepsValues.map((stepGroup, index) => (
                <HorizontalTabHeader
                  key={index}
                  index={index}
                  onClick={() => {
                    setNextSteps(stepGroup.values);
                    setPossibleNextSteps(stepGroup.values);
                  }}
                >
                  <p>{stepGroup.title}</p>
                </HorizontalTabHeader>
              ))}
            </div>
            {nextStepsValues.map((_, index) => (
              <HorizontalTabContent key={index} index={index} className="!p-0">
                <ReferralsTab
                  isError={isError}
                  isFetching={isFetching}
                  isLoading={isLoading}
                  onRowClick={onRowClick}
                  mutableColumns={mutableColumns}
                  onSetMutableColumns={onSetMutableColumns}
                  renderNoResults={renderNoResults}
                  table={table}
                  pagination={pagination}
                  pageSize={pageSize}
                  skip={skip}
                  setSkip={setSkip}
                />
              </HorizontalTabContent>
            ))}
          </div>
        </HorizontalTab>
      )}
    </div>
  );
}
