import { Table } from "@tanstack/react-table";
import { ViewType, ViewTypes } from "../types";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import { Button } from "../../../components/ui/button";
import { Columns3 } from "lucide-react";
import { useTranslation } from "react-i18next";
import { CalendlyInviteColName } from "../referralOverview/columns/distinctCols/calendlyInviteSentCol";
import { IntakeOnColName } from "../referralOverview/columns/distinctCols/intakeOnCol";
import { PatientColName } from "../referralOverview/columns/distinctCols/patientCol";
import { PatientContactInfoColName } from "../referralOverview/columns/distinctCols/patientContactInfoCol";
import { LocationColName } from "../referralOverview/columns/distinctCols/locationCol";
import { HealthCareInsurerColName } from "../referralOverview/columns/distinctCols/healthcareInsurerCol";
import { ReferralSourceColName } from "../referralOverview/columns/distinctCols/referralSourceCol";
import { DateOfReferralColName } from "../referralOverview/columns/distinctCols/referralDateCol";
import { StatusColName } from "../referralOverview/columns/distinctCols/statusCol";
import { NextStepColName } from "../referralOverview/columns/distinctCols/nextStepCol";
import { ManualStatusColName } from "../referralOverview/columns/distinctCols/manualStatusCol";
import { AssignedPsychologistColName } from "../referralOverview/columns/distinctCols/assignedPsychologitCol";
import { LanguageSelectorColName } from "../referralOverview/columns/distinctCols/languageSelectorCol";
import { RelevanceColName } from "../referralOverview/columns/distinctCols/relevanceCol";
import { useIsEnabled } from "../../../feature-management/useIsEnabled";

export const ColumnVisibilityPicker = ({
  table,
  viewType,
}: {
  table: Table<any>;
  viewType: ViewType;
}) => {
  const { t } = useTranslation();

  const getTranslatedColumnName = (id: string) => {
    switch (id) {
      case PatientColName:
        return "Patient";
      case PatientContactInfoColName:
        return "Contact Info";
      case LocationColName:
        return "Location";
      case StatusColName:
        return "Status";
      case NextStepColName:
        return "Next Step";
      case ManualStatusColName:
        return t("crm-daily-activity-tracker-col-caption");
      case AssignedPsychologistColName:
        return "Assigned Psychologist";
      case DateOfReferralColName:
        return "Referral Date";
      case LanguageSelectorColName:
        return "Language";
      case RelevanceColName:
        return "Relevance";
      case HealthCareInsurerColName:
        return "Healthcare Insurer";
      case ReferralSourceColName:
        return "Referral Source";
      case CalendlyInviteColName:
        return "Calendly Status";
      case IntakeOnColName:
        return "Intake Date";
      default:
        return id;
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          className="ml-auto flex items-center gap-2 w-52"
        >
          <Columns3 className="w-5 h-5" />
          <p>
            {t(
              "case-load-manager-customization-column-visibility-picker-button"
            )}
          </p>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="center"
        side="left"
        className="max-h-[16rem] overflow-y-auto"
      >
        {table
          .getAllColumns()
          .filter((column) => {
            const defaultFilter = column.getCanHide();

            if (viewType === ViewTypes.workingView) {
              return defaultFilter;
            } else {
              // manual status column is only visible in the working view
              return defaultFilter && column.id !== ManualStatusColName;
            }
          })
          .map((column) => {
            return (
              <DropdownMenuCheckboxItem
                key={column.id}
                className="capitalize"
                checked={column.getIsVisible()}
                onCheckedChange={(value) => column.toggleVisibility(!!value)}
                onSelect={(e) => e.preventDefault()}
              >
                {getTranslatedColumnName(column.id)}
              </DropdownMenuCheckboxItem>
            );
          })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
