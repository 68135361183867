import React from "react";
import { styled } from "@mui/system";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import { observer } from "mobx-react";
import useStores from "../useStores";
import { useDebouncedCallback } from "use-debounce";
import { InputBase } from "@mui/material";

const StyledRoot = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  maxWidth: 480,
  padding: theme.spacing(2, 0),
}));

const StyledSearchPaper = styled(Paper)(({ theme }) => ({
  flexGrow: 1,
  height: 42,
  padding: theme.spacing(0, 2),
  display: "flex",
  alignItems: "center",
}));

const StyledSearchIcon = styled(SearchIcon)(
  ({ theme, isSearchValueEmpty }) => ({
    marginRight: theme.spacing(2),
    color: isSearchValueEmpty ? theme.palette.icon : theme.palette.primary.main,
  })
);

const StyledInputBase = styled(InputBase)(() => ({
  flexGrow: 1,
}));

const DossiersSearch = () => {
  const { dossiersStore } = useStores();

  const onSearchChange = useDebouncedCallback((searchValue) => {
    dossiersStore.setSearchValue(searchValue);
  }, 500);

  return (
    <StyledRoot>
      <StyledSearchPaper elevation={1}>
        <StyledSearchIcon
          isSearchValueEmpty={dossiersStore.searchValue === ""}
        />
        <StyledInputBase
          autoFocus
          onChange={(event) => onSearchChange(event.target.value)}
          placeholder="Zoek op naam, email of cliënt nummer..."
        />
      </StyledSearchPaper>
    </StyledRoot>
  );
};

export default observer(DossiersSearch);
