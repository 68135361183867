import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../../components/ui/table';
import { Row } from './Row';
import { Output } from './types';

export const AlgorithmOutputTable = ({ data }: { data: Output }) => {
  return (
    <Table className="mt-8 border rounded">
      <TableHeader>
        <TableRow>
          <TableHead className="w-1/4">Entry</TableHead>
          <TableHead>Period 1</TableHead>
          <TableHead>Period 2</TableHead>
          <TableHead>Period 3</TableHead>
          <TableHead>Period 4</TableHead>
          <TableHead>Period 5</TableHead>
          <TableHead>Period 6</TableHead>
          <TableHead>Period 7</TableHead>
          <TableHead>Period 8</TableHead>
          <TableHead>Period 9</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        <Row label="Working slots" values={data.workingSlots} />
        <Row label="Occupied slots" values={data.occupiedSlots} />
        <Row label="Predicted occupied slots" values={data.predictedOccupiedSlots} />
        <Row
          label="Predicted Occupancy current time block"
          values={data.predictedOccupancyCurrentTimeBlock}
        />
        <Row
          label="Predicted Occupancy until week 16"
          values={data.predictedOccupancyUntilWeek16}
        />
        <Row label="Available slots" values={data.availableSlots} />
        <Row
          label="Available slots, corrected for vacation"
          values={data.availableSlotsCorrectedForVacation}
        />
        <Row label="Trajectories" values={data.trajectories} />
        <Row label="Vacations" values={data.vacations} />
        <Row label="Alternative slots" values={data.alternativeSlots} />
        {data.dossiers?.map((dossier) => (
          <Row label={`Dossier ${dossier.dossierId}`} values={dossier.values} />
        ))}
      </TableBody>
    </Table>
  );
};
