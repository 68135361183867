import logo from '../../resources/full_logo_medium.webp';
import { Button } from '../../components/ui/button';
import { LockKeyholeOpen } from 'lucide-react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from './msalConfig';
import WelcomePage from '../../pages/welcome/WelcomePage';

export const LoginPage = () => {
  const { instance, inProgress } = useMsal();
  let activeAccount;

  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  const handleLoginPopup = () => {
    instance
      .loginPopup({
        ...loginRequest,
        redirectUri: '/redirect',
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="w-full">
      <AuthenticatedTemplate>
        <WelcomePage />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div className="min-w-screen flex items-center justify-between relative isolate">
          <svg
            className="absolute -z-10 h-full w-full stroke-gray-300 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                width={100}
                height={100}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M50 100V.5M.5 .5H100" fill="none"></path>
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-50.5 0h101v101h-101Z M349.5 0h101v101h-101Z M249.5 200h101v101h-101Z M-150.5 300h101v101h-101Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              width="100%"
              height="100%"
              strokeWidth={0}
              fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
            />
          </svg>

          <div className="w-full min-h-screen flex items-center justify-center">
            <div className="w-fit border rounded-lg p-12 flex items-center flex-col bg-white/70 shadow-xl">
              <img alt="Cover" className="block w-40 pb-10" src={logo} />

              <section className="flex flex-col text-center items-center">
                <h1 className="text-4xl font-bold tracking-tight text-gray-900">
                  Welcome to the Dashboard!
                </h1>
                <p className="pt-4">Please login with your @ipractice.nl account to continue.</p>

                <Button
                  className="flex items-center space-x-2 px-8 mt-10"
                  onClick={handleLoginPopup}
                >
                  <div className="w-4 h-4">
                    <LockKeyholeOpen className="w-4 h-4" />
                  </div>
                  <p>Login</p>
                </Button>
              </section>
            </div>
          </div>
        </div>
      </UnauthenticatedTemplate>
    </div>
  );
};
export default LoginPage;
