import { useUpdateRelevance } from "../../hooks";
import { PatientRelevance, ReferralDetails } from "../../types";
import { relevances } from "../../constants";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "../../../../components/ui/toggle-group";
import { AiReferralLetterSummaryAndRelevance } from "../aiReferralLetterSummaryAndRelevance";

export const Relevance = ({
  referral,
  onChangeRelevance,
}: {
  referral: ReferralDetails;
  onChangeRelevance: (value: string) => void;
}) => {
  const { mutate: save } = useUpdateRelevance();

  const getVariant = (value: PatientRelevance) => {
    switch (value) {
      case "Low":
        return "filledRed";

      case "Medium":
        return "filledYellow";

      case "High":
        return "filledGreen";

      default:
        return "filled";
    }
  };

  return (
    <div>
      <p className="font-medium pb-1">Relevance</p>
      <ToggleGroup
        type="single"
        defaultValue={referral.relevance}
        onValueChange={async (x) => {
          onChangeRelevance(x);
          await save({
            referralId: referral.id,
            relevance: x ? x : "Unspecified",
          });
        }}
      >
        {relevances
          .filter((x) => x.value !== "Unspecified")
          .map((relevance) => (
            <ToggleGroupItem
              key={relevance.value}
              value={relevance.value}
              variant={getVariant(relevance.value)}
            >
              <div className="flex items-center space-x-2">
                <relevance.icon className="w-4 h-4 mr-2" />
                {relevance.label}
              </div>
            </ToggleGroupItem>
          ))}
      </ToggleGroup>

      <AiReferralLetterSummaryAndRelevance referral={referral} showRelevance />
    </div>
  );
};
