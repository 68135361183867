import { PreIntakeReportPositionTrajectory } from "../preIntake/types";

export const ViewTypes = {
  fullView: "full-view",
  dagcoView: "dagco-view", // dagco is a Dutch acronym for "Dagelijkse Controle" -> these are users checking every day referral applicabilities (setting relevances on them)
  workingView: "working-view",
  calendlyView: "calendly-view",
} as const;

export type ViewType = (typeof ViewTypes)[keyof typeof ViewTypes];

export const getReadableViewType = (viewType: ViewType) => {
  switch (viewType) {
    case ViewTypes.fullView:
      return "Full View";
    case ViewTypes.dagcoView:
      return "Dagco View";
    case ViewTypes.workingView:
      return "Working View";
    case ViewTypes.calendlyView:
      return "Calendly View";
  }
};

export type ReferredPatient = {
  id: string;
  name: string;
  email: string;
  phoneNumber: string | null;
  dateOfBirth: Date;
  language: string;
  talkDeskId: string;
  epdPatientId: number | null;
  epdFileId: number | null;
  zorgDomeinReferralNumber: string;
  howDidYouHearAboutUs: string;
  isPatientInsured: boolean;
  status: RegistrationStatusType;
  lastStatusChangeOn: Date;
  lastActivityOn: Date;
  intakeOn?: string;
  referralDate?: Date;
  calendlyInvite: CalendlyInvite;
  remarks: string[];
  rejectedByClient: boolean | null;
  rejectionReasonIPractice: RejectionReasonIPractice | null;
  rejectionReasonClient: RejectionReasonClient | null;
  justificationForOtherRejectionReason: string | null;
  healthCareInsurer: HealthcareInsurer;
  location: EpdLocation;
  assignedPsychologist: AssignedPsychologist;
  relevance: PatientRelevance;
  proposedRelevance: PatientRelevance;
  isDuplicate: boolean;
  nextStep: NextStep;
  isTransferReferral: boolean;
  todayStatus: ManualDailyStatus | null;
};

export type ManualReferral = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhoneNumber: string;
  bsn: string;
  dateOfBirth: Date;
  zorgdomeinNumber: string;
  zorgdomeinProductCode: string;
  zorgDomeinCreationDate: Date;
  originalMessage: string;
  senderPractitioner: string;
  senderOrganization: string;
  senderOrganizationAgbCode: string;
  gender: string;
  notes: string | null;
  createdDossierId: string | null;
  referralNotes: Note;
  file?: File;
  referralLetter?: string;
  address: Address;
  healthCareInsurerId: string | null;
  language: string;
  intakeOn: Date | null;
  intakeByPsychologistId: string | null;
  intakeByPsychologistEpdId: number | null;
  intakeByPsychologistName: string | null;
  preIntakeOn: Date | null;
  preIntakeByPsychologistId: string | null;
  preIntakeByPsychologistEpdId: number | null;
  preIntakeByPsychologistName: string | null;
  howDidYouHearAboutUs: string;
};

export type UploadReferralLetterForm = {
  file?: File;
  documentType: string;
};

export type ReferralDetails = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  epdPatientId: number | null;
  epdFileId: number | null;
  patientId: string | null;
  dossierId: number | null;
  mobilePhoneNumber: string;
  bsn: string;
  dateOfBirth: Date;
  zorgdomeinNumber: string;
  zorgdomeinProductCode: string;
  zorgDomeinCreationDate: Date;
  originalMessage: string;
  senderPractitioner: string;
  senderOrganization: string;
  senderOrganizationAgbCode: string;
  gender: string;
  notes: string | null;
  status: RegistrationStatusType;
  lastStatusChangeOn: Date;
  lastActivityOn: Date;
  createdDossierId: string | null;
  howDidYouHearAboutUs: string;
  cancellationReason: string;
  talkDeskNumber: string;
  rejectedByClient: boolean | null;
  rejectionReasonIPractice: RejectionReasonIPractice | null;
  rejectionReasonClient: RejectionReasonClient | null;
  justificationForOtherRejectionReason: string | null;
  rejectionEmailSent: boolean | null;
  relevance: PatientRelevance;
  proposedRelevance: PatientRelevance;
  proposedRelevanceJustification: string;
  proposedRelevanceMissingInformation: string | null;
  recommendedTransferPractices: {
    email: string;
    name: string;
    website: string;
    type: string;
    reasoning: string;
    score: number;
    waitingList: string;
    waitingListUrl: string;
    agbCode: string;
    notes: string;
    hasCrisisTeam: boolean | null;
    hasPsychiatrist: boolean | null;
    hasDayTreatment: boolean | null;
    distance: number;
    isOnlineOnly: boolean | null;
    careDuration: string[];
    insuranceContracts: string[];
    therapyTypes: string[];
    treatmentLanguages: string[];
    treatmentTypes: string[];
    locationName: string;
    address: string;
    postalCode: string;
    city: string;
    latitude: number;
    longitude: number;
  }[];
  location: EpdLocation | null;
  dossierType: EpdDossierType | null;
  assignedPsychologist: AssignedPsychologist;
  language: "en-US" | "nl-NL" | null;
  referralNotes: Note;
  healthCareInsurer: HealthcareInsurer;
  calendlyInvite: CalendlyInvite;
  documents: DocumentMetadata[];
  milestones: Milestone[];
  address: Address;
  intakeOn: Date | null;
  intakeByPsychologistId: string | null;
  intakeByPsychologistEpdId: number | null;
  intakeByPsychologistName: string | null;
  intakeByPsychologistLocations: string[] | null;
  intakeByPsychologistTeams: number[] | null;
  intakeCouldNotBeScheduledInPraktijkData: boolean;
  intakePraktijkDataEpdId: number | null;
  preIntakeOn: Date | null;
  preIntakeByPsychologistId: string | null;
  preIntakeByPsychologistEpdId: number | null;
  preIntakeByPsychologistName: string | null;
  preIntakeCouldNotBeScheduledInPraktijkData: boolean;
  preIntakePraktijkDataEpdId: number | null;
  duplicatedPatientReferralEntryIds: string[];
  duplicatedPatientIds: string[];
  nextStep: NextStep;
  isTransferReferral: boolean;
};

type ZorgdomeinDocumentType = "Referral"; // Add other types as needed

export const RegistrationStatus = {
  New: "New",
  Closed: "Closed",
  ReachOut: "ReachOut",
  RegisterPatientAndIntake: "RegisterPatientAndIntake",
  AwaitingPatientApproval: "AwaitingPatientApproval",
  AwaitingIWelcomeEmailSend: "AwaitingIWelcomeEmailSend",
  Rejected: "Rejected",
  Cancelled: "Cancelled",
  Archived: "Archived",
} as const;

export type RegistrationStatusType =
  (typeof RegistrationStatus)[keyof typeof RegistrationStatus];

export const DocumentTypeKeys = {
  ReferralLetter: "ReferralLetter",
  ReferralAttachment: "ReferralAttachment",
} as const;

export type DocumentTypeEnum =
  (typeof DocumentTypeKeys)[keyof typeof DocumentTypeKeys];

export type DocumentMetadata = {
  id: string;
  fileName: string;
  description: string;
  documentType: DocumentTypeEnum;
};

export type CalendlyInvite = {
  calendlyInviteSent: boolean;
  calendlyLinkUrl: string;
  inviteSentDate: Date;
  eventDate: Date;
  status: CalendlyInviteStatusType;
};

export const CalendlyInviteStatus = {
  NotSent: "NotSent",
  InviteSent: "InviteSent",
  Created: "Created",
  Rescheduled: "Rescheduled",
  Cancelled: "Cancelled",
} as const;
export type CalendlyInviteStatusType =
  (typeof CalendlyInviteStatus)[keyof typeof CalendlyInviteStatus];

export type IntakeInformation = {
  rejectionReasonIPractice: RejectionReasonIPractice | null;
  rejectionReasonClient: RejectionReasonClient | null;
  relevance: string | null;
  location: EpdLocation | null;
  dossierType: EpdDossierType | null;
  talkDeskNumber: string;
  howDidYouHearAboutUs: string | null;
};

export type PersonalInformation = {
  id: string;
  zorgdomeinMessageId: string;
  zorgdomeinNumber: string;
  zorgdomeinProductCode: string;
  zorgDomeinCreationDate: Date;
  zorgdomeinDocumentType: ZorgdomeinDocumentType;
  firstName: string;
  lastName: string;
  senderPractitioner: string;
  senderOrganization: string;
  email: string;
  dateOfBirth: Date;
  mobilePhoneNumber: string;
  bsn: string;
  status: RegistrationStatusType;
  language: string;
  address: Address;
  healthCareInsurerId: string | null;
};

export type Note = {
  id?: string;
  date?: Date;
  content: string;
  matchingPsychologistName?: string;
};

export type ActivityLog = {
  activity: string;
  additionalInformation: string | null;
  user: string;
  timeStamp: Date;
};

export type Milestone = {
  additionalInformation: string;
  content: string;
  date: Date;
  id: string;
  recordedBy: string;
};

export enum ReferralTableSortingColumns {
  Patient,
  Dossier,
  Status,
  IntakeOn,
  PreIntakeOn,
  referralDate,
  LastRegularConsultOn,
  CheckinNeeded,
}

export type Language = {
  code: string;
  flag: JSX.Element;
};

export enum RejectionReasonIPractice {
  ClientTooLight = "ClientTooLight",
  InquiryTooHeavy = "InquiryTooHeavy",
  ComplaintTooHeavy = "ComplaintTooHeavy",
  Transfer = "Transfer",
  ReRegistration = "ReRegistration",
  DoubleReferral = "DoubleReferral",
  ReferToSpecializedMentalHealthCare = "ReferToSpecializedMentalHealthCare",
  RejectDueToDifferentProblematic = "RejectDueToDifferentProblematic",
  TooLight = "TooLight",
  NoMotivation = "NoMotivation",
  Other = "Other",
}

export enum RejectionReasonClient {
  CostsTooHigh = "CostsTooHigh",
  NoFurtherContactFromClient = "NoFurtherContactFromClient",
  IAmDoingWellAgain = "IAmDoingWellAgain",
  MethodDoesNotAppealToMe = "MethodDoesNotAppealToMe",
  WaitingTimeIsTooLong = "WaitingTimeIsTooLong",
  ClientWantsLongerTreatment = "ClientWantsLongerTreatment",
  RegistrationWithdrawn = "RegistrationWithdrawn",
  AlreadyInTreatmentElsewhere = "AlreadyInTreatmentElsewhere",
  SpeaksAnotherLanguage = "SpeaksAnotherLanguage",
  WrongReferral = "WrongReferral",
  ClientSeeksLongTermCare = "ClientSeeksLongTermCare",
  NonContractedCare = "NonContractedCare",
  FoundPlaceElsewhere = "FoundPlaceElsewhere",
  ClientCannotAffordDeductible = "ClientCannotAffordDeductible",
  ClientWantsToExploreOtherOptions = "ClientWantsToExploreOtherOptions",
  ClientWantsToConsiderTheCosts = "ClientWantsToConsiderTheCosts",
  BlendedCarePrefersMoreOneOnOnePsychologist = "BlendedCarePrefersMoreOneOnOnePsychologist",
  ClientDoesNotWantOnline = "ClientDoesNotWantOnline",
  PoorService = "PoorService",
  BlendedCarePracticalDoesNotAppealToMe = "BlendedCarePracticalDoesNotAppealToMe",
  ClientWantsToThinkAboutTheMethod = "ClientWantsToThinkAboutTheMethod",
  DoingWellAgainClientContactsUs = "DoingWellAgainClientContactsUs",
  ClientDoesNotWantToPayExtraCosts = "ClientDoesNotWantToPayExtraCosts",
  WantedDiagnosticAssessment = "WantedDiagnosticAssessment",
  WantsMedication = "WantsMedication",
}

export type EpdLocation = {
  id: string;
  epdLocationId: number;
  name: string;
};

export type EpdDossierType = {
  id: string;
  epdDossierTypeId: number;
  name: string;
};

export type EpdEmployee = {
  id: string;
  employeeId: string;
  epdId: number;
  name: string;
  periodNumber: number;
  predictedOccupancy: number;
  averagePredictedOccupancy: number;
  earliestOpenSlot: string;
};

export type PatientRelevance = "Unspecified" | "Low" | "Medium" | "High";

export type AssignedPsychologist = {
  id: string;
  name: string;
  email: string;
};

export type HealthcareInsurer = {
  id: string;
  name: string;
  isContracted: boolean;
  concern: string;
};

export type Address = {
  streetName: string;
  houseNumber: string;
  houseNumberAddition: string;
  postalCode: string;
  place: string;
  country: string;
};

export type CaseLoadProgressWithOccupancyForecast = {
  epdId: number;
  name: string;
  totalPatientCount: number;
  supportedPatientCount: number;
  excludedPatientCount: number;
  locationTags: string[];
  teamTags: string[];
  adminComments: string;
  totalWorkingHours: number;
  workingHoursAsOnlinePsychologist: number;
  positionTrajectory: PreIntakeReportPositionTrajectory;

  futurePreIntakesCount: {
    preIntakesThisWeek: number;
    preIntakesIn1Week: number;
    preIntakesIn2Weeks: number;
    preIntakesIn3Weeks: number;
    preIntakesIn4Weeks: number;
    preIntakesIn5Weeks: number;
    preIntakesIn6Weeks: number;
  };

  preIntakeTargets: {
    preIntakesThisWeek: number;
    preIntakesIn1Week: number;
    preIntakesIn2Weeks: number;
    preIntakesIn3Weeks: number;
    preIntakesIn4Weeks: number;
    preIntakesIn5Weeks: number;
    preIntakesIn6Weeks: number;
  };
};

export enum HealthcareInsurerContractFilter {
  Contracted = "Contracted",
  Uncontracted = "Uncontracted",
  All = "All",
}

export enum TransferReferralFilter {
  OnlyTransfer,
  OnlyNonTransfer,
  All,
}

export enum HealthcareInsurerContractType {
  ContractedWithCap = "ContractedWithCap",
  ContractedNoLimit = "ContractedNoLimit",
  Uncontracted = "Uncontracted",
  Other = "Other",
  Unknown = "Unknown",
}

export type GetWeeklyPatientReferralStatisticsQueryResponse = {
  date: Date | null;
  weeklyInflowOfReferrals: number;
  openClients: number;
  countByContractType: { [key in HealthcareInsurerContractType]?: number };
  reportItems: ReportItem[];
  csvReport: string;
};

export type GetMonthlyInflowAggregationReportResponse = {
  period: string;
  data: {
    healthcareInsurerConcern: string;
    count: number;
  }[];
};

export type ReportItem = {
  healthcareInsurerConcern: string | null;
  insurerContractType: HealthcareInsurerContractType | null;
  totalCount: number;
  statusCounts: { [key: string]: number };
};

export type CrmInflowByLocationQueryResponse = {
  reportItems: CrmInflowByLocationResponseItem[];
  csvReport: string;
  totalCountPerWeek: { [week: number]: number };
  totalCount: number;
};

export type CrmInflowByLocationResponseItem = {
  city: string;
  week: number;
  year: number;
  numberOfEntries: number;
};

export enum NextStep {
  DeterminePatientApplicability = "DeterminePatientApplicability",
  ReachOut = "ReachOut",
  WaitForFeedbackReRegistration = "WaitForFeedbackReRegistration",
  WaitForFeedbackCaseDiscussion = "WaitForFeedbackCaseDiscussion",
  WaitForMissingInformation = "WaitForMissingInformation",
  ReachOutAfterReRegistrationOrCaseDiscussion = "ReachOutAfterReRegistrationOrCaseDiscussion",
  PendingShort = "PendingShort",
  PendingLong = "PendingLong",
  InsurerWaitList = "InsurerWaitList",
  Closed = "Closed",
  January = "January",
  ReachOutAfterAdhdScreening = "ReachOutAfterAdhdScreening",
}

export type CrmBacklogByStatusFullReportResult = {
  location: string;
  countOfNew: number;
  countOfReachOut: number;
  countOfRegisterPatientAndIntake: number;
  countOfAwaitingPatientApproval: number;
  countOfAwaitingIWelcomeEmailSend: number;
};

export type CrmBacklogByNextStepFullReportResult = {
  location: string;
  countOfScheduledCalendlies: number;
  countOfDeterminePatientApplicability: number;
  countOfReachOut: number;
  countOfJanuary: number;
  countOfWaitForFeedbackReRegistration: number;
  countOfWaitForFeedbackCaseDiscussion: number;
  countOfWaitForMissingInformation: number;
  countOfReachOutAfterReRegistrationOrCaseDiscussion: number;
  countOfReachOutAfterAdhdScreening: number;
  countOfPendingShort: number;
  countOfPendingLong: number;
  countOfInsurerWaitList: number;
  countOfClosed: number;
};

export type CrmWorkingViewReportResult = {
  cities: {
    name: string;

    notYetCalledCount: number;
    calledAndActionNeededCount: number;
    calledButWithoutResponseCount: number;
    calledAndNoActionNeededCount: number;
    notCalledDueToNoActionNeedCount: number;
    closedFromWorkingViewCount: number;
    closedFromCalendlyViewCount: number;
    menzisCount: number;
    vgzCount: number;
  }[];
};

export type CrmWorkingViewReportPerCityCounts = Exclude<
  keyof CrmWorkingViewReportResult["cities"][0],
  "name"
>;

export type CrmNoChangeInDaysReportResult = {
  date: string;
  countOfZeroDays: number;
  countOfOneDay: number;
  countOfTwoToSevenDays: number;
  countOfEightToFourteenDays: number;
  countOfMoreThanFourteenDays: number;
};

export enum UnsuccessfulyReachOutAttemptEmail {
  None,
  WillTryAgain,
  WillNotTryAgain,
}

export enum ManualDailyStatus {
  NotCalledYet,
  CalledButWithoutResponse,
  CalledAndActionNeeded,
  NotCalledDueToNoActionNeed,
  CalledAndNoActionNeeded,
}
