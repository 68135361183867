import { Table } from "@tanstack/react-table";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "./pagination";
import { Pagination as PaginationType } from "../../shared/pagination";

export const TablePagination = ({
  pagination,
  pageSize,
  skip,
  setSkip,
  table,
}: {
  pagination: PaginationType | undefined;
  pageSize: number;
  skip: number;
  setSkip: (newSkip: number) => void;
  table: Table<any>;
}) => {
  if (pagination == undefined || pagination.limit >= pagination.total)
    return <></>;

  const renderPaginationItems = () => {
    const numberOfPages = Math.ceil(pagination.total / pagination.limit);
    const currentPage = pagination.currentPage;

    let displayPages: any[] = [1];

    if (currentPage > 3) {
      displayPages.push("...");
    }

    if (currentPage > 2) {
      displayPages.push(currentPage - 1);
    }

    if (currentPage > 1 && currentPage < numberOfPages) {
      displayPages.push(currentPage);
    }

    if (currentPage < numberOfPages - 1) {
      displayPages.push(currentPage + 1);
    }

    if (currentPage < numberOfPages - 2) {
      displayPages.push("...");
    }

    if (numberOfPages > 1) {
      displayPages.push(numberOfPages);
    }

    return (
      <>
        {displayPages.map((page, index) => (
          <PaginationItem key={index}>
            <PaginationLink
              className="cursor-pointer"
              isActive={page == currentPage}
              onClick={() => {
                if (typeof page === "number") {
                  table.resetRowSelection();
                  setSkip(pageSize * (page - 1));
                }
              }}
            >
              {page}
            </PaginationLink>
          </PaginationItem>
        ))}
      </>
    );
  };

  return (
    <Pagination>
      <PaginationContent className="mt-6">
        <PaginationItem
          className={
            skip - pageSize < 0 ? "cursor-not-allowed" : "cursor-pointer"
          }
        >
          <PaginationPrevious
            onClick={() => {
              if (skip - pageSize < 0) return;

              table.resetRowSelection();
              setSkip(skip - pageSize);
            }}
          />
        </PaginationItem>
        {renderPaginationItems()}
        <PaginationItem
          className={
            skip + pageSize > pagination.total
              ? "cursor-not-allowed"
              : "cursor-pointer"
          }
        >
          <PaginationNext
            onClick={() => {
              if (skip + pageSize > pagination.total) return;

              table.resetRowSelection();
              setSkip(skip + pageSize);
            }}
          />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  );
};
