import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

type ComparisonKey<T> = {
  [K in keyof T]: T[K] extends number | string ? K : never;
}[keyof T];

export const areArraysEqual = <T>(a: T[], b: T[], prop: ComparisonKey<T>) => {
  if (a.length !== b.length) {
    return false;
  }

  a.sort((x, y) => (x[prop] > y[prop] ? 1 : -1));
  b.sort((x, y) => (x[prop] > y[prop] ? 1 : -1));

  for (let i = 0; i < a.length; i++) {
    if (a[i][prop] !== b[i][prop]) {
      return false;
    }
  }

  return true;
};
