import React from "react";
import { styled } from "@mui/system";
import { Card, Typography, Avatar, colors } from "@mui/material";
import moment from "moment";
import { observer } from "mobx-react";
import EventIcon from "@mui/icons-material/Event";
import isConsultOnline from "../../../utils/consultHelper";
import Label from "../../../shared/Label";
import getOnboardingStatusColor from "../../../utils/getOnboardingStatusColor";
import useStores from "../../../useStores";

const StyledCardRoot = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const StyledStatus = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  textAlign: "right",
}));

const StyledStatusText = styled("div")(({ theme }) => ({
  marginRight: theme.spacing(2),
  textTransform: "lowercase",
}));

const StyledDetails = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
}));

const StyledLabel = styled(Label)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const StyledAvatar = styled(Avatar)(() => ({
  height: 48,
  width: 48,
}));

function Intake() {
  const { onboardingStore } = useStores();
  const { onboarding } = onboardingStore;
  const { intakeConsult } = onboarding;

  return (
    <StyledCardRoot>
      <div>
        <Typography component="h3" variant="overline">
          Intake datum
        </Typography>
        <StyledDetails>
          <Typography variant="h3">
            {intakeConsult
              ? moment(intakeConsult.start).format("dd L H:mm")
              : "onbekend"}
          </Typography>
          {intakeConsult && isConsultOnline(intakeConsult) && (
            <StyledLabel color={colors.grey[600]} variant="outlined">
              online
            </StyledLabel>
          )}
        </StyledDetails>
      </div>

      <StyledStatus>
        <StyledStatusText>
          <Typography component="h3" variant="overline">
            Onboarding status
          </Typography>
          <div>
            <Typography variant="h3">{onboarding.onboardingStatus}</Typography>
          </div>
        </StyledStatusText>

        <StyledAvatar
          style={{
            backgroundColor: getOnboardingStatusColor(
              onboarding.onboardingStatus
            ),
          }}
        >
          <EventIcon />
        </StyledAvatar>
      </StyledStatus>
    </StyledCardRoot>
  );
}

export default observer(Intake);
