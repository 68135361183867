import { Pencil } from "lucide-react";
import { BigRegistrationReport } from "../types";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../components/ui/popover";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import { useState } from "react";
import { toast } from "sonner";
import { useUpdateEmployeeBigRegistrationCode } from "../hooks";

export const BigRegistrationCodeColumn = ({
  data,
}: {
  data: BigRegistrationReport;
}) => {
  const [editableBigRegistrationCode, setEditableBigRegistrationCode] =
    useState(data.bigRegistrationCode);

  const [isEditPopoverOpen, setIsEditPopoverOpen] = useState(false);

  const onUpdateSuccess = () => {
    setIsEditPopoverOpen(false);

    toast("BIG Registration updated", {
      description:
        "Successfully updated the BIG Registration code of the user.",
    });
  };

  const onUpdateFailure = () => {
    toast("Failure", {
      description:
        "The system could not update the BIG Registration code of the user.",
    });
  };
  const { mutate, isLoading } = useUpdateEmployeeBigRegistrationCode(
    onUpdateSuccess,
    onUpdateFailure
  );

  return (
    <div className="flex items-center space-x-2">
      <p>{data.bigRegistrationCode ?? "N/A"}</p>
      <div className="h-4 w-4">
        <Popover open={isEditPopoverOpen} onOpenChange={setIsEditPopoverOpen}>
          <PopoverTrigger asChild>
            <Pencil className="h-4 w-4 cursor-pointer opacity-25 hover:opacity-100" />
          </PopoverTrigger>
          <PopoverContent className="p-6 w-80">
            <div className="space-y-4">
              <section>
                <p className="text-lg font-medium leading-none">
                  BIG Registration Code
                </p>
                <p className="text-sm opacity-70">{data.email}</p>
              </section>

              <Input
                id="bigRegistrationCode"
                placeholder="Enter the BIG Registration Code"
                autoFocus
                value={editableBigRegistrationCode}
                onChange={(event) => {
                  setEditableBigRegistrationCode(event.target.value);
                }}
              />

              <Button
                disabled={
                  editableBigRegistrationCode == data.bigRegistrationCode
                }
                className="w-full"
                onClick={async () => {
                  await mutate({
                    email: data.email,
                    code: editableBigRegistrationCode,
                  });
                }}
              >
                {isLoading ? "Saving..." : "Save"}
              </Button>
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
};
