import React from "react";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import { Formik } from "formik";
import * as Yup from "yup";
import { observer } from "mobx-react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";
import useStores from "../../useStores";

const StyledTextareaAutosize = styled(TextareaAutosize)(() => ({
  width: "100%",
}));

const Note = () => {
  const { patientStore } = useStores();
  const { dossier } = patientStore;

  return (
    <Card>
      <Formik
        enableReinitialize
        initialValues={{
          note: dossier.note ? dossier.note : "",
        }}
        onSubmit={(values) => {
          patientStore.patchDossier({
            note: values.note,
          });
          // resetForm(); Don't reset form it gets filled again from the store using Formik enableReinitialize.
        }}
        validationSchema={Yup.object().shape({
          note: Yup.string().required(),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            dirty,
          } = props;
          return (
            <form autoComplete="off" noValidate>
              <CardHeader title="Notities" />
              <Divider />
              <CardContent>
                <StyledTextareaAutosize
                  minRows={20}
                  onChange={handleChange}
                  name="note"
                  onBlur={handleBlur}
                  value={values.note}
                  error={errors.note && touched.note}
                />
              </CardContent>
              <Divider />
              <CardActions>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting || !dirty}
                  onClick={handleSubmit}
                >
                  Opslaan
                </Button>
                <Button
                  type="button"
                  className="outline"
                  onClick={handleReset}
                  disabled={isSubmitting || !dirty}
                >
                  Reset
                </Button>
              </CardActions>
            </form>
          );
        }}
      </Formik>
    </Card>
  );
};

export default observer(Note);
