import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../../components/ui/select";

export const PsychologistFilter = ({
  value,
  setValue,
  data,
}: {
  value: string;
  setValue: (newValue: string) => void;
  data: string[];
}) => {
  return (
    <Select
      value={value}
      onValueChange={(x) => {
        setValue(x);
      }}
    >
      <SelectTrigger className="w-1/3">
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectItem value="full-team">Full team</SelectItem>
          {data.map((employee) => {
            return (
              <SelectItem key={employee} value={employee}>
                {employee}
              </SelectItem>
            );
          })}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
