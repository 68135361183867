import { Fragment } from "react";
import { X, Check } from "lucide-react";
import { RegistrationStatus } from "../../types";

export const ReferralProgressStepper = ({
  allSteps,
  currentStep,
}: {
  allSteps: string[];
  currentStep: string;
}) => {
  const getReadableStepName = (stepName: string) => {
    switch (stepName) {
      case RegistrationStatus.New:
        return "New";

      case RegistrationStatus.ReachOut:
        return "Reach Out";

      case RegistrationStatus.RegisterPatientAndIntake:
      case RegistrationStatus.AwaitingPatientApproval:
        return "Register Patient & Intake";

      case RegistrationStatus.AwaitingIWelcomeEmailSend:
        return "Send iWelcome";

      case RegistrationStatus.Closed:
        return "Closed";

      default:
        return stepName;
    }
  };

  const steps = allSteps.map((a) => {
    return {
      name: getReadableStepName(a),
      isActive:
        // todo, terrible:
        currentStep === RegistrationStatus.AwaitingPatientApproval
          ? a === RegistrationStatus.RegisterPatientAndIntake
          : a === currentStep,
      isCompleted: currentStep === RegistrationStatus.Closed,
      isRejected: currentStep === RegistrationStatus.Rejected || currentStep === RegistrationStatus.Cancelled,
    };
  });

  let activeStepIndex = steps.findIndex((step) => step.isActive);

  if (currentStep == RegistrationStatus.Rejected) {
    activeStepIndex = steps.length;

    steps.push({
      name: RegistrationStatus.Rejected,
      isActive: false,
      isCompleted: false,
      isRejected: true,
    });
  }

  if (activeStepIndex > 0) {
    // mark all entries in 'steps' that come before activeStepIndex as completed:
    for (let i = 0; i < activeStepIndex; i++) {
      steps[i].isCompleted = true;
    }
  }

  const getBackgroundColor = () => {
    if (currentStep === RegistrationStatus.Closed)
      return "bg-green-50 dark:bg-slate-950";

      if (currentStep === RegistrationStatus.Rejected || currentStep === RegistrationStatus.Cancelled)
      return "bg-red-50 dark:bg-slate-950";

    return "bg-blue-50 dark:bg-slate-950";
  };

  const getSeparatorColor = () => {
    if (currentStep === RegistrationStatus.Closed)
      return "bg-green-300 dark:bg-slate-800";

      if (currentStep === RegistrationStatus.Rejected || currentStep === RegistrationStatus.Cancelled)
      return "bg-red-100 dark:bg-red-800";

    return "bg-blue-100 dark:bg-slate-800";
  };

  return (
    <>
      <div
        className={`mt-6 flex items-start space-x-2 lg:space-x-4 py-4 px-12 -mx-[40px] ${getBackgroundColor()}`}
      >
        {steps.map((step, index) => {
          return (
            <Fragment key={step.name}>
              <Step
                num={index + 1}
                isWholeProgressCompleted={
                  currentStep === RegistrationStatus.Closed
                }
                data={step}
              />
              {index + 1 < steps.length && (
                <div
                  className={`w-4 lg:w-8 mt-[16px] lg:mt-[16px] h-[2px] ${getSeparatorColor()}`}
                />
              )}
            </Fragment>
          );
        })}
      </div>
    </>
  );
};

const Step = ({
  num,
  isWholeProgressCompleted,
  data,
}: {
  num: number;
  isWholeProgressCompleted: boolean;
  data: {
    name: string;
    isActive: boolean;
    isCompleted: boolean;
    isRejected: boolean;
  };
}) => {
  const { name, isActive, isRejected, isCompleted } = data;

  const getStepIconStyle = () => {
    if (isWholeProgressCompleted)
      return "bg-green-200 dark:bg-green-950 font-normal text-green-500 dark:text-green-600";

    if (isRejected)
      return "bg-red-100 dark:bg-red-950 font-normal text-red-500 dark:text-red-600";

    if (isCompleted)
      return "bg-blue-100 dark:bg-slate-700 font-normal text-gray-400 dark:text-slate-950";

    if (isActive)
      return "bg-blue-200 dark:bg-slate-500 font-bold text-gray-500 dark:text-slate-900";

    return "bg-white dark:bg-slate-900 font-normal text-gray-400 dark:text-slate-600";
  };

  const getStepIndicator = () => {
    if (isRejected) return <X className="w-3 h-3" />;
    if (isCompleted) return <Check className="w-3 h-3" />;
    return <p className="text-sm">{num}</p>;
  };

  const getStepNameStyle = () => {
    const baseStyles =
      "text-xs hidden lg:block text-gray-400 dark:text-slate-600";

    if (isRejected) return `${baseStyles} font-normal`;

    if (isCompleted) return `${baseStyles} font-normal`;

    if (isActive)
      return `${baseStyles} font-bold text-gray-500 dark:text-slate-500`;

    return `${baseStyles} font-normal`;
  };

  return (
    <div className="flex items-center justify-center space-x-4">
      <div
        className={`rounded-full h-8 w-8 flex items-center justify-center text-sm ${getStepIconStyle()}`}
      >
        {getStepIndicator()}
      </div>
      <h1 className={getStepNameStyle()}>{name}</h1>
    </div>
  );
};
