import React, { Fragment, useState } from "react";
import { styled } from "@mui/system";
import { observer } from "mobx-react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import moment from "moment";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import Button from "@mui/material/Button";
import * as colors from "@mui/material/colors";
import AddIcon from "@mui/icons-material/Add";
import { Typography } from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import { displayLocalCronJob } from "../../../utils/cronParser";
import Label from "../../../shared/Label";
import useStores from "../../../useStores";
import SchedulePreviewModal from "./SchedulePreviewModal";
import ScheduleCreateModal from "./ScheduleCreateModal";
import ScheduleEditModal from "./ScheduleEditModal";
import DeactivationModal from "./DeactivationModal";

const StyledCardContent = styled(CardContent)(() => ({
  padding: 0,
}));

const StyledAddIcon = styled(AddIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const Schedules = () => {
  const { patientStore } = useStores();
  const { schedules, patient } = patientStore;
  const [scheduleToDeactivate, setScheduleToDeactivate] = useState(null);
  const [scheduleToEdit, setScheduleToEdit] = useState(null);
  const [isScheduleCreateModalOpen, setIsScheduleCreateModalOpen] =
    useState(false);
  const [scheduleDefinitionToPreview, setScheduleDefinitionToPreview] =
    useState(null);

  const handleDeactivation = (reason) => {
    patientStore.deactivateSchedule(scheduleToDeactivate.id, reason);
    setScheduleToDeactivate(null);
  };

  const renderStatus = (schedule) => {
    if (schedule.deactivatedDateTime) {
      return (
        <>
          <div>
            <Label color={colors.orange[600]}>Gedeactiveerd</Label>
          </div>
          <div>{moment(schedule.deactivatedDateTime).format("LLL")}</div>
          <div>{schedule.deactivatedReason}</div>
        </>
      );
    }

    if (new Date(schedule.endDateTime) < new Date()) {
      return <Label color={colors.green[600]}>Afgerond</Label>;
    }

    return <Label color={colors.green[600]}>Actief</Label>;
  };

  const isScheduleActive = (schedule) => {
    if (
      schedule.deactivatedDateTime ||
      new Date(schedule.endDateTime) < new Date()
    ) {
      return false;
    }

    return true;
  };

  if (!patient.wireUser) {
    return (
      <Card>
        <CardHeader
          title="Daily practice & education bots"
          action={
            <Button disabled color="primary" size="small">
              <StyledAddIcon />
              Toevoegen
            </Button>
          }
        />
        <Divider />
        <CardContent>
          <Typography variant="subtitle1">
            Selecteer eerst een wire handle voor deze cliënt.
          </Typography>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader
        title="Daily practice & education bots"
        action={
          <Button
            color="primary"
            size="small"
            onClick={() => setIsScheduleCreateModalOpen(true)}
          >
            <StyledAddIcon />
            Toevoegen
          </Button>
        }
      />
      <Divider />
      <StyledCardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Bot</TableCell>
              <TableCell>Planning</TableCell>
              <TableCell>Einddatum</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="right">Acties</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {schedules.map((schedule, index) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <TableRow key={index}>
                  <TableCell>{schedule.scheduleDefinition.name}</TableCell>
                  <TableCell>
                    {displayLocalCronJob(schedule.scheduleCronNotation)}
                  </TableCell>
                  <TableCell>
                    {schedule.endDateTime
                      ? moment(schedule.endDateTime).format("LL")
                      : "Geen einddatum"}
                  </TableCell>
                  <TableCell>{renderStatus(schedule)}</TableCell>
                  <TableCell align="right">
                    <ButtonGroup>
                      <Button
                        variant="outlined"
                        disabled={!isScheduleActive(schedule)}
                        color="primary"
                        size="small"
                        onClick={() =>
                          setScheduleDefinitionToPreview(
                            schedule.scheduleDefinition
                          )
                        }
                      >
                        Preview
                      </Button>
                      <Button
                        variant="outlined"
                        disabled={!isScheduleActive(schedule)}
                        color="primary"
                        size="small"
                        onClick={() => setScheduleToEdit(schedule)}
                      >
                        Bewerken
                      </Button>
                      <Button
                        variant="outlined"
                        disabled={!isScheduleActive(schedule)}
                        color="primary"
                        size="small"
                        onClick={() => setScheduleToDeactivate(schedule)}
                      >
                        Deactiveer
                      </Button>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </StyledCardContent>
      <ScheduleCreateModal
        open={isScheduleCreateModalOpen}
        onClose={() => setIsScheduleCreateModalOpen(false)}
      />
      <SchedulePreviewModal
        open={!!scheduleDefinitionToPreview}
        onClose={() => setScheduleDefinitionToPreview(null)}
        scheduleDefinition={scheduleDefinitionToPreview}
      />
      <DeactivationModal
        open={!!scheduleToDeactivate}
        onDeactivation={handleDeactivation}
        onClose={() => setScheduleToDeactivate(null)}
      />
      <ScheduleEditModal
        open={!!scheduleToEdit}
        onClose={() => setScheduleToEdit(null)}
        schedule={scheduleToEdit}
      />
    </Card>
  );
};

export default observer(Schedules);
