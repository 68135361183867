import { CircleCheckBig } from "lucide-react";

export const FinishedIndicator = () => {
  return (
    <div className="inline-flex items-center justify-center p-1 rounded-lg bg-green-100 border border-green-300 text-green-500">
      <div className="w-4 h-4">
        <CircleCheckBig className="w-4 h-4" />
      </div>
    </div>
  );
};
