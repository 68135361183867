import { RejectionReasonIPractice } from "../../types";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import { Textarea } from "../../../../components/ui/textarea";
import {
  RadioGroup,
  RadioGroupItem,
} from "../../../../components/ui/radio-group";
import { PreviewRejectionMail } from "./previewRejectionMail";

export const getReadableReason = (reason: RejectionReasonIPractice) => {
  switch (reason) {
    case RejectionReasonIPractice.ClientTooLight:
      return "Client is too light";
    case RejectionReasonIPractice.InquiryTooHeavy:
      return "Inquiry is too heavy";
    case RejectionReasonIPractice.ComplaintTooHeavy:
      return "Complaint is too heavy";
    case RejectionReasonIPractice.Transfer:
      return "Transfer";
    case RejectionReasonIPractice.ReRegistration:
      return "Re-registration";
    case RejectionReasonIPractice.DoubleReferral:
      return "Double referral";
    case RejectionReasonIPractice.ReferToSpecializedMentalHealthCare:
      return "Refer to specialized mental health care";
    case RejectionReasonIPractice.RejectDueToDifferentProblematic:
      return "Reject due to different problematic";
    case RejectionReasonIPractice.TooLight:
      return "Too light";
    case RejectionReasonIPractice.NoMotivation:
      return "No motivation";
    case RejectionReasonIPractice.Other:
      return "Other";
    default:
      return reason;
  }
};

export const ReasonsIPractice = ({
  referralLanguage,
  patientName,
  selectedReason,
  setSelectedReason,
  justification,
  setJustification,

  sendStandardRejectionEmail,
  setSendStandardRejectionEmail,
  sendFullyCustomRejectionEmail,
  setSendFullyCustomRejectionEmail,
  rejectionEmailUserInput,
  setRejectionEmailUserInput,
}: {
  referralLanguage: "en-US" | "nl-NL";
  patientName: string;
  selectedReason: RejectionReasonIPractice | undefined;
  setSelectedReason: (newReason: RejectionReasonIPractice) => void;
  justification: string | undefined;
  setJustification: (newJustification: string) => void;

  sendStandardRejectionEmail: boolean;
  setSendStandardRejectionEmail: (newValue: boolean) => void;
  sendFullyCustomRejectionEmail: boolean;
  setSendFullyCustomRejectionEmail: (newValue: boolean) => void;
  rejectionEmailUserInput: string | undefined;
  setRejectionEmailUserInput: (newValue: string | undefined) => void;
}) => {
  return (
    <div className="pt-4">
      <p className="font-bold pb-1">Rejection reason</p>
      <Select
        onValueChange={(value) => {
          setSelectedReason(value as RejectionReasonIPractice);
        }}
        defaultValue={selectedReason}
      >
        <SelectTrigger>
          <SelectValue placeholder="Select our rejection reason..." />
        </SelectTrigger>
        <SelectContent className="z-[999999999] max-h-[20rem] overflow-y-scroll">
          {Object.values(RejectionReasonIPractice).map((reason) => (
            <SelectItem value={reason} key={reason}>
              {getReadableReason(reason)}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      <div>
        <p className="font-bold pb-1 pt-4">Internal iPractice Justification</p>
        <Textarea
          value={justification}
          onChange={(e) => setJustification(e.target.value)}
          rows={2}
        />
      </div>

      {selectedReason && (
        <>
          <div className="flex items-start justify-between space-x-2 pt-4">
            <RadioGroup
              onValueChange={(e: "standard" | "custom" | "none") => {
                if (e == "standard") {
                  setSendStandardRejectionEmail(true);
                  setSendFullyCustomRejectionEmail(false);
                } else if (e == "custom") {
                  setSendStandardRejectionEmail(false);
                  setSendFullyCustomRejectionEmail(true);
                } else {
                  setSendStandardRejectionEmail(false);
                  setSendFullyCustomRejectionEmail(false);
                  setRejectionEmailUserInput(undefined);
                }
              }}
              className="flex flex-col"
            >
              <div className="flex items-center space-x-2">
                <RadioGroupItem
                  checked={sendStandardRejectionEmail}
                  value="standard"
                  id="standard"
                />
                <label htmlFor="standard" className="cursor-pointer">
                  Send standard rejection email to the patient
                </label>
              </div>

              <div className="flex items-center space-x-2">
                <RadioGroupItem
                  checked={sendFullyCustomRejectionEmail}
                  value="custom"
                  id="custom"
                />
                <label htmlFor="custom" className="cursor-pointer">
                  Send fully customized rejection email to the patient
                </label>
              </div>

              <div className="flex items-center space-x-2">
                <RadioGroupItem
                  checked={
                    !sendStandardRejectionEmail &&
                    !sendFullyCustomRejectionEmail
                  }
                  value="none"
                  id="none"
                />
                <label htmlFor="none" className="cursor-pointer">
                  Do NOT send any rejection email to the patient
                </label>
              </div>
            </RadioGroup>

            {(sendStandardRejectionEmail || sendFullyCustomRejectionEmail) && (
              <PreviewRejectionMail
                isCustom={sendFullyCustomRejectionEmail}
                referralLanguage={referralLanguage}
                patientName={patientName}
                comments={rejectionEmailUserInput}
              />
            )}
          </div>

          {(sendStandardRejectionEmail || sendFullyCustomRejectionEmail) && (
            <div>
              <p className="font-bold pb-1 pt-4">
                {sendStandardRejectionEmail &&
                  "Additional comments for the rejection email (optional)"}
                {sendFullyCustomRejectionEmail &&
                  "Specify the text of the email (mandatory)"}
              </p>
              <Textarea
                value={rejectionEmailUserInput || ""}
                onChange={(e) => setRejectionEmailUserInput(e.target.value)}
                rows={2}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
