import React from "react";
import moment from "moment";
import { styled } from "@mui/system";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import { observer } from "mobx-react";
import {
  getQuestionListResultsUrl,
  getQuestionnaireUrl,
} from "../utils/urlHelpers";
import CopyToClipboard from "./CopyToClipboard";
import CircularProgress from "./CircularProgress";
import QuestionListStatus from "./QuestionListStatus";
import { getIWelcomeUrl } from "../utils/urlHelpers";

const StyledCardHeader = styled(CardHeader)(() => ({
  paddingBottom: 0,
}));

const StyledCardContent = styled(CardContent)(() => ({
  padding: 0,
  "&:last-child": {
    paddingBottom: 0,
  },
}));

const StyledDescription = styled("div")(({ theme }) => ({
  padding: theme.spacing(2, 3, 1, 3),
}));

const StyledStatusTags = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 3, 2, 3),
  "& > * + *": {
    marginLeft: theme.spacing(1),
  },
}));

const StyledDetails = styled("div")(({ theme }) => ({
  padding: theme.spacing(2, 3),
}));

const QuestionListDetails = ({
  dossierId,
  questionList,
  children,
  clipBoardValue,
  hideNavigation,
  hideCopyToClipboard,
}) => {
  const getLastUpdatedQuestion = () => {
    return questionList.questions
      .slice()
      .sort((a, b) => new Date(b.lastUpdatedAt) - new Date(a.lastUpdatedAt))
      .find((question) => question.isAnswered === true);
  };

  const calculateQuestionListProgress = () => {
    if (questionList.finished) {
      return 100;
    }
    const { questions } = questionList;
    const lastQuestionNumber = questions[questions.length - 1].number;
    const lastAnsweredQuestion = questions
      .slice()
      .reverse()
      .find((question) => question.isAnswered === true);

    if (lastAnsweredQuestion === undefined) {
      return 0;
    }

    return (100 * lastAnsweredQuestion.number) / lastQuestionNumber;
  };

  return (
    <Card>
      <StyledCardHeader
        avatar={<CircularProgress value={calculateQuestionListProgress()} />}
        disableTypography
        subheader={
          <Typography variant="body2">
            Laatste activiteit:{" "}
            {getLastUpdatedQuestion()
              ? moment(getLastUpdatedQuestion().lastUpdatedAt).fromNow()
              : "geen"}
          </Typography>
        }
        title={
          <Typography variant="h5">{questionList.name} vragenlijst</Typography>
        }
      />

      <StyledCardContent>
        <StyledDescription>
          <Typography color="textSecondary" variant="subtitle2">
            {children}
          </Typography>
        </StyledDescription>
        <StyledStatusTags>
          <QuestionListStatus questionList={questionList} />
        </StyledStatusTags>
        <Divider />
        <StyledDetails>
          <Grid
            alignItems="center"
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography variant="h5">
                {moment(questionList.createdAt).format("DD-MM-YYYY HH:mm")}
              </Typography>
              <Typography variant="body2">Aangemaakt</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5">
                {questionList.finished
                  ? moment(questionList.finished).format("DD-MM-YYYY HH:mm")
                  : "-"}
              </Typography>
              <Typography variant="body2">Afgerond</Typography>
            </Grid>
            <Grid item>
              {!hideCopyToClipboard && (
                <CopyToClipboard value={clipBoardValue} />
              )}
              {!hideNavigation && (
                <>
                  {questionList.name === "SQ48-stand-alone" &&
                  questionList.finished ? (
                    <Button
                      size="small"
                      href={getQuestionListResultsUrl(questionList)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <OpenInNewIcon />
                      Bekijk resultaten
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      href={getIWelcomeUrl(dossierId)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <OpenInNewIcon />
                      Open vragenlijst
                    </Button>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </StyledDetails>
      </StyledCardContent>
    </Card>
  );
};

export default observer(QuestionListDetails);
