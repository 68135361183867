import { Input } from '../../../../../components/ui/input';

type SearchTermFilterProps = {
  value: string;
  onChange: (value: string) => void;
};

export const SearchTermFilter = ({ value, onChange }: SearchTermFilterProps) => {
  return (
    <div className="w-1/4">
      <p className="font-medium">Search</p>
      <Input
        placeholder="Search for clients, messages..."
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};
