import { ExternalLink } from "lucide-react";
import { OnboardPatientDetails } from "../../types";

type DossierLinksProps = {
  onboardingPatient: OnboardPatientDetails;
};

export const DossierLinks = ({ onboardingPatient }: DossierLinksProps) => {
  return (
    <div>
      <div className="block">
        <div
          className="inline-flex text-link cursor-pointer hover:underline items-center space-x-2"
          onClick={() =>
            window.open(
              `https://start.praktijkdata.nl/app/clienten/${onboardingPatient.epdPatientId}`,
              "_blank"
            )
          }
        >
          <div className="h-4 w-4">
            <ExternalLink className="w-4 h-4" />
          </div>
          <p>
            Open <span className="font-bold">PraktijkData</span> dossier
          </p>
        </div>
      </div>
      <div className="block pt-1">
        <div
          className="inline-flex text-link cursor-pointer hover:underline items-center space-x-2"
          onClick={() =>
            window.open(
              `/patient/${onboardingPatient.patientId}/DossierGgz/${onboardingPatient.dossierId}/treatment`,
              "_blank"
            )
          }
        >
          <div className="h-4 w-4">
            <ExternalLink className="w-4 h-4" />
          </div>
          <p>
            Open <span className="font-bold">Dashboard</span> dossier
          </p>
        </div>
      </div>
    </div>
  );
};
