import { RejectionReasonClient } from "../../types";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import { Textarea } from "../../../../components/ui/textarea";

export const getReadableReason = (reason: RejectionReasonClient) => {
  switch (reason) {
    case RejectionReasonClient.ClientWantsToConsiderTheCosts:
      return "Client wants to consider the costs";
    case RejectionReasonClient.CostsTooHigh:
      return "Cost is too high";
    case RejectionReasonClient.ClientCannotAffordDeductible:
      return "Client cannot afford deductible";
    case RejectionReasonClient.ClientDoesNotWantToPayExtraCosts:
      return "Client does not want to pay extra costs";
    case RejectionReasonClient.NoFurtherContactFromClient:
      return "No further contact from client";
    case RejectionReasonClient.IAmDoingWellAgain:
      return "Client is doing well again";
    case RejectionReasonClient.DoingWellAgainClientContactsUs:
      return "Doing well again client contacts us";
    case RejectionReasonClient.MethodDoesNotAppealToMe:
      return "Method does not appeal to client";
    case RejectionReasonClient.ClientWantsToThinkAboutTheMethod:
      return "Client wants to think about the method";
    case RejectionReasonClient.ClientDoesNotWantOnline:
      return "Client does not want online";
    case RejectionReasonClient.BlendedCarePrefersMoreOneOnOnePsychologist:
      return "Blended care prefers more one-on-one psychologist";
    case RejectionReasonClient.BlendedCarePracticalDoesNotAppealToMe:
      return "Blended capractical does not appeal to client";
    case RejectionReasonClient.WaitingTimeIsTooLong:
      return "Too long waiting time";
    case RejectionReasonClient.ClientWantsLongerTreatment:
      return "Client wants longer treatment";
    case RejectionReasonClient.RegistrationWithdrawn:
      return "Registration withdrawn";
    case RejectionReasonClient.AlreadyInTreatmentElsewhere:
      return "Already in treatment elsewhere";
    case RejectionReasonClient.FoundPlaceElsewhere:
      return "Found place elsewhere";
    case RejectionReasonClient.ClientWantsToExploreOtherOptions:
      return "Client wants to explore other options";
    case RejectionReasonClient.PoorService:
      return "Poor service";
    case RejectionReasonClient.SpeaksAnotherLanguage:
      return "Speaks another language";
    case RejectionReasonClient.WrongReferral:
      return "Wrong referral";
    case RejectionReasonClient.ClientSeeksLongTermCare:
      return "Client seeks long-term care";
    case RejectionReasonClient.NonContractedCare:
      return "Non-contracted care";
    case RejectionReasonClient.WantedDiagnosticAssessment:
      return "Wanted diagnostic assessment";
    case RejectionReasonClient.WantsMedication:
      return "Wants medication";
    default:
      return reason;
  }
};

export const ReasonsClient = ({
  selectedReason,
  setSelectedReason,
  justification,
  setJustification,
}: {
  selectedReason: RejectionReasonClient | undefined;
  setSelectedReason: (newReason: RejectionReasonClient) => void;
  justification: string | undefined;
  setJustification: (newJustification: string) => void;
}) => {
  return (
    <div className="pt-4">
      <p className="font-bold pb-1">Rejection reason</p>
      <Select
        onValueChange={(value) => {
          setSelectedReason(value as RejectionReasonClient);
        }}
        defaultValue={selectedReason}
      >
        <SelectTrigger>
          <SelectValue placeholder="Select the client's rejection reason..." />
        </SelectTrigger>
        <SelectContent className="z-[999999999] max-h-[20rem] overflow-y-scroll">
          {Object.values(RejectionReasonClient).map((reason) => (
            <SelectItem value={reason} key={reason}>
              {getReadableReason(reason)}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      <div>
        <p className="font-bold pb-1 pt-4">Internal iPractice Justification</p>
        <Textarea
          value={justification}
          onChange={(e) => setJustification(e.target.value)}
          rows={2}
        />
      </div>
    </div>
  );
};
