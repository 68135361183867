import { CaseLoadWeeklyProgress } from "../types";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../../components/ui/tooltip";

type SupportedClientsBarProps = {
  supportedPercent: number;
  data: CaseLoadWeeklyProgress;
};

export const SupportedClientsBar = ({
  supportedPercent,
  data,
}: SupportedClientsBarProps) => {
  return (
    <>
      {" "}
      {data.supportedClientCount == 0 ? (
        <></>
      ) : (
        <div
          style={{ width: `${supportedPercent}%` }}
          className={`flex items-center justify-center text-xs h-6 bg-green-500 ${
            supportedPercent == 100 ? "rounded" : "rounded-tr rounded-br"
          }`}
        >
          <TooltipProvider>
            <Tooltip delayDuration={100}>
              <TooltipTrigger asChild>
                <p className="text-green-700 font-bold">
                  {data.supportedClientCount}
                </p>
              </TooltipTrigger>
              <TooltipContent
                className="text-center bg-slate-900 text-slate-300 p-2"
                side="bottom"
                align="center"
              >
                This week, you have already supported{" "}
                {data.supportedClientCount} clients.
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      )}
    </>
  );
};
