import { Pagination } from "../../../../shared/pagination";
import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { Sq48RemindersToCompleteByClientLog } from "./types";
import { queryClient } from "../../../../react-query/provider";

export const useGetSq48Reminders = (searchTerm: string, skip: number) => {
  const query = useQuery<{
    data: Sq48RemindersToCompleteByClientLog[];
    pagination: Pagination;
  }>(
    ["sq48-reminders", "sq48-admin", searchTerm, skip],
    async () => {
      const res = await axios.get(
        `adminquestionlists/sq48-reminders?searchTerm=${searchTerm}&skip=${skip}`
      );
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useRetriggerSq48Reminder = (
  id: string,
  onSuccess: () => void,
  onError: () => void
) => {
  const mutation = useMutation({
    mutationFn: async () => {
      const res = await axios.post(
        `adminquestionlists/sq48-reminders/${id}/retrigger`,
        {}
      );
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["sq48-reminders", "sq48-admin"]);
      queryClient.refetchQueries(["sq48-reminders", "sq48-admin"]);
      onSuccess();
    },
    onError: onError,
  });

  return mutation;
};
