import { useGetPraktijkdataTreatmentInfo } from "../../hooks";
import { OnboardPatientDetails } from "../../types";
import { useTranslation } from "react-i18next";
import { Section } from "./section";

type PraktijkdataTreatmentInfoProps = {
  onboardingPatient: OnboardPatientDetails;
};

export const PraktijkdataTreatmentInfo = ({
  onboardingPatient,
}: PraktijkdataTreatmentInfoProps) => {
  const { isError, isLoading, data } = useGetPraktijkdataTreatmentInfo(
    onboardingPatient.epdPatientId,
    onboardingPatient.epdFileId
  );

  const { t } = useTranslation();

  return (
    <div>
      <p className="font-bold">
        {t("case-load-manager-onboarding-details-praktijkdata-summary")}{" "}
      </p>
      <div
        className={`mt-2 max-h-[30vh] overflow-y-scroll rounded-lg border p-4 space-y-2 opacity-80 ${
          isError ? "bg-red-50" : "bg-slate-100"
        }`}
      >
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <>
            {isError ? (
              <p>Could not fetch PraktijkData information.</p>
            ) : (
              <main className="divide-y-2">
                <div className="-mt-3">
                  <Section title="Anamnese" content={data!.anamnesis} />
                </div>
                <Section
                  title="Psychologische voorgeschiedenis"
                  content={data!.psychologicalHistory}
                />
                <Section title="Medicatie" content={data!.medicalComorbidity} />

                <Section title="Medication" content={data!.medication} />
                <Section
                  title="Middelengebruik"
                  content={data!.substanceUsage}
                />
                <Section
                  title="Sociale omgeving"
                  content={data!.socialEnvironment}
                />
                <Section title="Persoonlijkheid" content={data!.personality} />
                <Section title="Zorgvraag" content={data!.careRequest} />

                <Section
                  title="Behandeldoelen"
                  content={data!.treatmentGoals}
                />
              </main>
            )}
          </>
        )}
      </div>
    </div>
  );
};
