import { logChatPromiseExecution } from "stream-chat";
import {
  MessageInput,
  MessageInputProps,
  MessageList,
  StreamMessage,
  useChannelActionContext,
  Window,
} from "stream-chat-react";
import { VideoCallButton } from "../video/videocallButton";
import "./GlobalStyling.css";
import { ParticipantsButton } from "./participantsButton";
import { StreamChatGenerics, ChatConversationDetails } from "../types";

type ChannelInnerProps = {
  chatDetails: ChatConversationDetails;
  isFetchingChatDetails: boolean;
  toggleMobile: () => void;
  defaultMessage: StreamMessage<StreamChatGenerics> | undefined;
  hideControls?: boolean;
};

const ChannelInner = (props: ChannelInnerProps) => {
  const { sendMessage } = useChannelActionContext<StreamChatGenerics>();

  const overrideSubmitHandler: MessageInputProps<StreamChatGenerics>["overrideSubmitHandler"] =
    (message, _, ...restSendParams) => {
      let updatedMessage;

      if (message.attachments?.length && message.text?.startsWith("/giphy")) {
        const updatedText = message.text.replace("/giphy", "");
        updatedMessage = { ...message, text: updatedText };
      }

      if (sendMessage) {
        const newMessage = updatedMessage || message;
        const parentMessage = newMessage.parent;

        const messageToSend = {
          ...newMessage,
          parent: parentMessage
            ? {
                ...parentMessage,
                created_at: parentMessage.created_at?.toString(),
                pinned_at: parentMessage.pinned_at?.toString(),
                updated_at: parentMessage.updated_at?.toString(),
              }
            : undefined,
        };

        const sendMessagePromise = sendMessage(
          // @ts-expect-error todo: fix type
          messageToSend,
          ...restSendParams
        );
        logChatPromiseExecution(sendMessagePromise, "send message");
      }
    };

  return (
    <div className="w-full text-foreground">
      <Window>
        {props.hideControls ? (
          <></>
        ) : (
          <div className="flex items-center justify-end w-full pb-2 space-x-2">
            <ParticipantsButton
              isFetchingChatDetails={props.isFetchingChatDetails}
              chatDetails={props.chatDetails}
            />
            <VideoCallButton
              isFetchingChatDetails={props.isFetchingChatDetails}
              chatDetails={props.chatDetails}
            />
          </div>
        )}

        <div className="max-h-200px">
          <MessageList
            messageActions={["delete", "edit", "quote", "react", "markUnread"]}
          />
        </div>
        <MessageInput
          message={props.defaultMessage}
          focus
          overrideSubmitHandler={overrideSubmitHandler}
          audioRecordingEnabled
          asyncMessagesMultiSendEnabled
        />
      </Window>
    </div>
  );
};

export default ChannelInner;
