import { languages } from "../../constants";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectGroup,
  SelectValue,
} from "../../../../components/ui/select";
import { useState } from "react";
import { useUpdateLanguage } from "../../hooks";

export const Language = ({
  isReadOnly,
  referralId,
  value,
}: {
  isReadOnly: boolean;
  referralId: string;
  value: string | null;
}) => {
  const [selectedValue, setSelectedValue] = useState(value);

  const { mutate: save, isLoading: isSaving } = useUpdateLanguage();

  return (
    <div>
      <p className="font-medium pb-1">Language</p>

      <Select
        onValueChange={async (value) => {
          setSelectedValue(value);

          await save({ referralId, language: value });
        }}
        defaultValue={selectedValue || undefined}
        disabled={isReadOnly || isSaving}
      >
        <SelectTrigger>
          <SelectValue placeholder="Select a language..." />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {languages.map((language) => (
              <SelectItem key={language.code} value={language.code}>
                {language.flag}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
};
