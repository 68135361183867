import React from "react";
import { observer } from "mobx-react";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";
import { TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import moment from "moment";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import StatusLabelBoolean from "./StatusLabelBoolean";
import Loading from "./Loading";
import QuestionnaireRow from "./QuestionnaireRow";
import useStores from "../useStores";
import {
  getAgreeUrl,
  getBillingUrl,
  getIWelcomeUrl,
  getQuestionnaireUrl,
} from "../utils/urlHelpers";
import questionListName from "../utils/constants/questionListName";
import QuestionListStatus from "./QuestionListStatus";
import PayStatus from "./PayStatus";

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  paddingBottom: "0 !important",
  padding: theme.spacing(0),
}));

const StyledCardActions = styled(CardActions)(() => ({
  flexDirection: "row",
  alignItems: "flex-start",
}));

const StyledAddIcon = styled(AddIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const Questionnaires = ({ isTreatmentView = false }) => {
  const { t } = useTranslation();
  const { patientStore, onboardingStore } = useStores();
  const { questionLists, dossier, patient } = patientStore;
  const { onboarding } = onboardingStore;

  const basisGgzQuestionList = questionLists.find((ql) =>
    [
      questionListName.BASIS_GGZ_INTAKE_V1,
      questionListName.BASIS_GGZ_INTAKE_V2,
    ].includes(ql.name)
  );
  const cQIndexQuestionList = questionLists.find(
    (ql) => ql.name === questionListName.CQ_INDEX
  );
  const cQIndexV51QuestionList = questionLists.find(
    (ql) => ql.name === questionListName.CQ_INDEX_V51
  );

  const getIWelcomeClipboardValue = () => {
    const { languageCode } = patient.language; // we get the patient from the patientStore because his languageCode might change.

    return t("mail-content-welcome", {
      replace: {
        name: onboarding.patient.firstName,
        link: getIWelcomeUrl(onboarding.lookupGuid),
      },
      lng: languageCode.substr(0, languageCode.indexOf("-")),
    });
  };

  if (
    basisGgzQuestionList === undefined &&
    onboarding.iWelcomeProgress === undefined
  ) {
    return <Loading />;
  }

  function createData(
    name,
    link,
    statusComponent,
    inviteText,
    createdAt,
    finishedAt,
    communicationText,
    withBorder
  ) {
    return {
      name,
      link,
      statusComponent,
      inviteText,
      createdAt,
      finishedAt,
      communicationText,
      withBorder,
    };
  }

  function generateQuestionnaireRows() {
    const iAgreeAcceptedOn = onboarding.iWelcomeProgress.iAgreeAcceptedOn
      ? new Date(onboarding.iWelcomeProgress.iAgreeAcceptedOn)
      : null;
    var iPayAcceptedOn = onboarding.iWelcomeProgress.iPayAcceptedOn
      ? new Date(onboarding.iWelcomeProgress.iPayAcceptedOn)
      : null;

    const getiWelcomeCompletedOn = () => {
      if (basisGgzQuestionList?.finished != null) {
        return basisGgzQuestionList?.finished;
      }

      if (onboarding.iWelcomeProgress.status === "Finished") {
        return iAgreeAcceptedOn;
      }

      return null;
    };

    const rows = [
      createData(
        "iWelcome",
        getIWelcomeUrl(onboarding.lookupGuid),
        <StatusLabelBoolean
          boolean={onboarding.iWelcomeProgress.status === "Finished"}
          positive={onboarding.iWelcomeProgress.status}
          negative={onboarding.iWelcomeProgress.status}
        />,
        getIWelcomeClipboardValue(),
        onboarding.iWelcomeProgress.createdOn,
        getiWelcomeCompletedOn(),
        <>
          {dossier.welcomeReminders.map((welcomeReminder, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={i}>
              {welcomeReminder.type}:
              {moment(welcomeReminder.sentAt).format("DD-MM-YYYY HH:mm")}
            </div>
          ))}
        </>,
        isTreatmentView
      ),
    ];

    if (!isTreatmentView) {
      rows.push(
        createData(
          <span>&#8627; Praktijkovereenkomst</span>,
          getAgreeUrl(onboarding.lookupGuid),
          <StatusLabelBoolean
            boolean={dossier.userAgreements.generalTermsAndConditions}
            positive="bevestigd"
            negative="open"
          />,
          null,
          onboarding.iWelcomeProgress.createdOn,
          iAgreeAcceptedOn,
          "",
          false
        )
      );

      if (
        dossier.subType === "SCREENING" &&
        ["CZ", "ONVZ"].includes(dossier.healthCareInsurerType)
      ) {
        // skip iPay part for ADHD screening w/ CZ/ONVZ dossiers
      } else {
        rows.push(
          createData(
            <span>&#8627; Betalingsovereenkomst</span>,
            getBillingUrl(onboarding.lookupGuid),
            <PayStatus billing={dossier.billing} />,
            null,
            onboarding.iWelcomeProgress.createdOn,
            iPayAcceptedOn,
            "",
            false
          )
        );
      }

      if (basisGgzQuestionList !== undefined) {
        rows.push(
          createData(
            <span>&#8627; Intake Vragenlijst</span>,
            getQuestionnaireUrl(basisGgzQuestionList),
            <QuestionListStatus questionList={basisGgzQuestionList} />,
            null,
            basisGgzQuestionList.createdAt,
            basisGgzQuestionList.finished,
            "",
            false
          )
        );
      }
    }

    if (isTreatmentView) {
      patientStore.questionLists
        .filter((questionList) => questionList.name === questionListName.SQ48)
        .map((questionList) => {
          rows.push(
            createData(
              "SQ48",
              getQuestionnaireUrl(questionList),
              <QuestionListStatus questionList={questionList} />,
              getQuestionnaireUrl(questionList),
              questionList.createdAt,
              questionList.finished,
              "",
              true
            )
          );

          return rows;
        });

      if (cQIndexQuestionList) {
        rows.push(
          createData(
            "CQ-Index (old version)",
            getQuestionnaireUrl(cQIndexQuestionList),
            <QuestionListStatus questionList={cQIndexQuestionList} />,
            getQuestionnaireUrl(cQIndexQuestionList),
            cQIndexQuestionList.createdAt,
            cQIndexQuestionList.finished,
            "",
            true
          )
        );
      }

      if (cQIndexV51QuestionList) {
        rows.push(
          createData(
            "CQ-Index V5.1",
            getQuestionnaireUrl(cQIndexV51QuestionList),
            <QuestionListStatus questionList={cQIndexV51QuestionList} />,
            getQuestionnaireUrl(cQIndexV51QuestionList),
            cQIndexV51QuestionList.createdAt,
            cQIndexV51QuestionList.finished,
            "",
            true
          )
        );
      }

      patientStore.questionLists
        .filter(
          (questionList) => questionList.name === questionListName.SQ48_MHC_SF
        )
        .map((questionList) => {
          rows.push(
            createData(
              "SQ48 MHC-SF",
              getQuestionnaireUrl(questionList),
              <QuestionListStatus questionList={questionList} />,
              getQuestionnaireUrl(questionList),
              questionList.createdAt,
              questionList.finished,
              "",
              true
            )
          );

          return rows;
        });

      patientStore.questionLists
        .filter(
          (questionList) => questionList.name === questionListName.IFOLLOWUP
        )
        .map((questionList) => {
          rows.push(
            createData(
              "iFollowUp",
              getQuestionnaireUrl(questionList),
              <QuestionListStatus questionList={questionList} />,
              getQuestionnaireUrl(questionList),
              questionList.createdAt,
              questionList.finished,
              "",
              true
            )
          );

          return rows;
        });
    }

    return rows;
  }

  return (
    <Card>
      <CardHeader
        title={isTreatmentView ? "Vragenlijsten" : "iWelcome onderdelen"}
      />
      <Divider />
      <StyledCardContent>
        <TableContainer component={Paper}>
          <Table aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell>Naam</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">Uitnodiging tekst</TableCell>
                <TableCell align="right">Aangemaakt</TableCell>
                <TableCell align="right">Afgerond</TableCell>
                <TableCell align="right">Automatische communicatie</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {generateQuestionnaireRows().map((row, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <QuestionnaireRow key={index} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledCardContent>
      {isTreatmentView && (
        <StyledCardActions>
          <Button
            onClick={() =>
              patientStore.createQuestionList(questionListName.SQ48_MHC_SF)
            }
          >
            <StyledAddIcon />
            Nieuwe SQ48
          </Button>
          <Button
            onClick={() =>
              patientStore.createQuestionList(questionListName.IFOLLOWUP)
            }
          >
            <StyledAddIcon />
            Nieuwe iFollowUp
          </Button>
        </StyledCardActions>
      )}
    </Card>
  );
};

export default observer(Questionnaires);
