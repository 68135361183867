import { SearchTermInput } from "./searchTermInput";
import { isDateInPastWeeks } from "../../../../utils/dateUtils";
import { MainListPatient } from "../types";
import { Table } from "@tanstack/react-table";
import { AddClientButton } from "../addClient/addClientButton";
import { CustomizeButton } from "../customization/customizeButton";
import { MultiRowActionsButton } from "../multiRowActions/multiRowActionsButton";

type TableHeaderProps = {
  table: Table<MainListPatient>;
  pageSize: number;
  setPageSize: (newSize: number) => void;
  setSkip: (newSkip: number) => void;
  date: Date;
  onChangeDate: (newDate: Date) => void;
  addNewPatientOpen: boolean;
  setAddNewPatientOpen: (newOpen: boolean) => void;
  searchTerm: string;
  setSearchTerm: (newSearchTerm: string) => void;
  onlyCaseLoadsWithAlert: boolean;
  setOnlyCaseLoadsWithAlert: (newVal: boolean) => void;
};

export const TableHeader = ({
  table,
  pageSize,
  setPageSize,
  setSkip,
  date,
  onChangeDate,
  addNewPatientOpen,
  setAddNewPatientOpen,
  searchTerm,
  setSearchTerm,
  onlyCaseLoadsWithAlert,
  setOnlyCaseLoadsWithAlert,
}: TableHeaderProps) => {
  return (
    <div className="flex items-center justify-between">
      <div className="w-1/3">
        <SearchTermInput
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
      </div>

      <div className="flex gap-2">
        <CustomizeButton
          table={table}
          pageSize={pageSize}
          setPageSize={setPageSize}
          setSkip={setSkip}
          date={date}
          onChangeDate={onChangeDate}
          onlyCaseLoadsWithAlert={onlyCaseLoadsWithAlert}
          setOnlyCaseLoadsWithAlert={setOnlyCaseLoadsWithAlert}
        />
        <MultiRowActionsButton table={table} />
        <AddClientButton
          open={addNewPatientOpen}
          onOpenChange={setAddNewPatientOpen}
          isInPast={isDateInPastWeeks(date)}
        />
      </div>
    </div>
  );
};
