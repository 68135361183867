import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";

const allowedHeights = ["800px", "1200px", "1500px", "1750px"] as const;
export type CalendarAllowedHeight = (typeof allowedHeights)[number];

export const CalendarZoom = ({
  calendarHeight,
  onChangeCalendarHeight,
}: {
  calendarHeight: CalendarAllowedHeight;
  onChangeCalendarHeight: (newHeight: string) => void;
}) => {
  const mapHeightToReadableValue = (value: CalendarAllowedHeight) => {
    switch (value) {
      case "800px":
        return "75%";
      case "1200px":
        return "100%";
      case "1500px":
        return "120%";
      case "1750px":
        return "150%";
    }
  };

  return (
    <div className="flex items-center space-x-2">
      <p>Zoom:</p>
      <Select
        value={calendarHeight}
        onValueChange={(x: CalendarAllowedHeight) => {
          onChangeCalendarHeight(x);
        }}
      >
        <SelectTrigger className="w-[90px]">
          <SelectValue />
        </SelectTrigger>
        <SelectContent className="z-[9999999]">
          <SelectGroup>
            {allowedHeights.map((height) => {
              return (
                <SelectItem key={height} value={height}>
                  {mapHeightToReadableValue(height)}
                </SelectItem>
              );
            })}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
};
