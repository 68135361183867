import React, { useEffect, useState } from "react";
import moment from "moment";
import { styled } from "@mui/system";
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import { observer } from "mobx-react";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import useStores from "../../useStores";
import TableRowPointer from "../../shared/TableRowPointer";
import getInitials from "../../utils/getInitials";
import dossierType from "../../utils/constants/dossierType";
import getDossierIdentifier from "../../utils/getDossierIdentifier";

const StyledCardContent = styled(CardContent)(() => ({
  padding: 0,
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

const StyledCardActions = styled(CardActions)(() => ({
  justifyContent: "flex-end",
}));

const DossierOpenUpsTable = () => {
  const navigate = useNavigate();
  const { dossiersStore } = useStores();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    dossiersStore.fetchDossiers(dossierType.OPEN_UP);
  }, [dossiersStore]);

  const handlePageChange = (event, changedPage) => {
    setPage(changedPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  return (
    <Card>
      <StyledCardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Naam</TableCell>
              <TableCell>Dossier nr.</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Bedrijf</TableCell>
              <TableCell>Datum aangemaakt</TableCell>
              <TableCell>Datum afgerond</TableCell>
              <TableCell align="right">Acties</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dossiersStore.filteredDossiers
              .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
              .map((dossier) => (
                <TableRowPointer
                  hover
                  key={dossier.id}
                  onClick={() =>
                    navigate(
                      `/patient/${dossier.patient.id}/${dossierType.OPEN_UP}/${dossier.id}`
                    )
                  }
                >
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <StyledAvatar>
                        {getInitials(
                          `${dossier.patient.firstName} ${dossier.patient.lastName}`
                        )}
                      </StyledAvatar>
                      {`${dossier.patient.firstName} ${dossier.patient.lastName}`}
                    </div>
                  </TableCell>
                  <TableCell>{getDossierIdentifier(dossier)}</TableCell>
                  <TableCell>{dossier.patient.email}</TableCell>
                  <TableCell>{dossier.company}</TableCell>
                  <TableCell>
                    {moment(dossier.createdAt).format("LLL")}
                  </TableCell>
                  <TableCell>
                    {dossier.completedAt &&
                      moment(dossier.completedAt).format("LLL")}
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      color="primary"
                      component={Link}
                      size="small"
                      to={`/patient/${dossier.patient.id}/${dossierType.OPEN_UP}/${dossier.id}`}
                      variant="outlined"
                    >
                      Bekijk
                    </Button>
                  </TableCell>
                </TableRowPointer>
              ))}
          </TableBody>
        </Table>
      </StyledCardContent>
      <StyledCardActions>
        <TablePagination
          component="div"
          count={dossiersStore.dossiers.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </StyledCardActions>
    </Card>
  );
};

export default observer(DossierOpenUpsTable);
