import { Dialog, DialogContent, DialogTrigger } from '../../../../components/ui/dialog';
import { Button } from '../../../../components/ui/button';
import { useState } from 'react';
import { FeedbackForm } from './FeedbackForm';

type Props = {
  handleSubmitFeedback: (feedback: {
    note?: string;
    rating: number;
    reason: string;
  }) => Promise<void>;
  hasFeedback?: boolean;
};
export function FeedbackDialog({ handleSubmitFeedback, hasFeedback: hasFeedbackProp }: Props) {
  const [hasFeedback, setHasFeedback] = useState(hasFeedbackProp);

  const onFeedbackSubmit = async (feedback: { note?: string; rating: number; reason: string }) => {
    await handleSubmitFeedback(feedback);
    setHasFeedback(true);
  };

  return (
    <Dialog>
      <DialogTrigger disabled={hasFeedback}>
        <Button variant="ghost" className="p-2 h-fit">
          <p className="text-xs font-normal">Send Feedback</p>
        </Button>
      </DialogTrigger>

      <DialogContent className="flex-col">
        <h2 className="text-openup-dark-blue text-3xl font-bold leading-8 antialiased">Feedback</h2>

        <p className="text-openup-dark-blue">What did you think about this message?</p>

        <FeedbackForm handleSubmitFeedback={onFeedbackSubmit} />
      </DialogContent>
    </Dialog>
  );
}
