import { ColumnDef } from "@tanstack/react-table";
import { Sq48RemindersToCompleteByClientLog } from "./types";
import { getSimpleFormattedDateTime } from "../../../../utils/dateUtils";
import { RetriggerReminder } from "./retriggerReminder";

export const columns: ColumnDef<Sq48RemindersToCompleteByClientLog>[] = [
  {
    accessorKey: "clientNumber",
    header: "Client",
    size: 120,
    cell: ({ getValue }) => {
      return <>{getValue()}</>;
    },
  },
  {
    accessorKey: "numberOfReminder",
    header: "Reminder no.",
    size: 200,
    cell: ({ getValue }) => {
      const numberOfReminder = getValue() as number;

      if (numberOfReminder === 0)
        return (
          <div>
            <p>0</p>
            <p className="text-xs">(initial creation of questionnaire)</p>
          </div>
        );

      return <>{numberOfReminder}</>;
    },
  },
  {
    accessorKey: "contextDetails",
    header: "Info",
    size: 400,
    cell: ({ getValue }) => {
      return <>{getValue()}</>;
    },
  },
  {
    accessorKey: "sentOn",
    header: "Sent On",
    size: 200,
    cell: ({ getValue }) => {
      return <>{getSimpleFormattedDateTime(getValue() as Date)}</>;
    },
  },
  {
    accessorKey: "retrigger",
    header: "",
    size: 150,
    cell: ({ row }) => {
      return (
        <div className="flex justify-end">
          <RetriggerReminder id={row.original.id} />
        </div>
      );
    },
  },
];
