import { Switch } from "../../../../../../components/ui/switch";

export const PerPatientFilter = ({
  value,
  setValue,
}: {
  value: boolean;
  setValue: (neValue: boolean) => void;
}) => {
  return (
    <div className="flex items-center space-x-2">
      <Switch
        id="show-per-patient-data"
        checked={value}
        onCheckedChange={(x) => setValue(x)}
      />
      <label htmlFor="show-per-patient-data">Show all patients</label>
    </div>
  );
};
