import { CalendlyInvite, CalendlyInviteStatus, CalendlyInviteStatusType } from "../../types";
import { Badge } from "../../../../components/ui/badge";
import { Calendar } from "lucide-react";

export const CalendlyStatus = ({ calendlyInvite }: { calendlyInvite: CalendlyInvite }) => {
  var options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  };

  const getBadgeColor = (status: CalendlyInviteStatusType) => {
    switch (status) {
      case CalendlyInviteStatus.NotSent:
        return "hover:bg-gray-200 bg-gray-200 text-gray-800 dark:bg-gray-700 dark:text-gray-200";
      case CalendlyInviteStatus.Created:
      case CalendlyInviteStatus.Rescheduled:
        return "hover:bg-[#B2DFDB] bg-[#B2DFDB] text-teal-800 dark:bg-green-700 dark:text-green-100";
      case CalendlyInviteStatus.Cancelled:
        return "hover:bg-red-100 bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300";
      case CalendlyInviteStatus.InviteSent:
        return "hover:bg-amber-100 bg-amber-100 text-amber-800 dark:bg-amber-700 dark:text-amber-100";
      default:
        return "hover:bg-gray-200 bg-gray-200 text-gray-800 dark:bg-gray-700 dark:text-gray-200";
    }
  };

  const getReadableStatus = () => {
    switch (calendlyInvite!.status) {
      case CalendlyInviteStatus.NotSent:
        return "Not Sent";
      case CalendlyInviteStatus.Created:
        return "Created";
      case CalendlyInviteStatus.Rescheduled:
        return "Rescheduled";
      case CalendlyInviteStatus.Cancelled:
        return "Cancelled";
      case CalendlyInviteStatus.InviteSent:
        return "Invite Sent";
      default:
        return "Unknown";
    }
  };

  if (
    calendlyInvite.status === CalendlyInviteStatus.Created ||
    calendlyInvite.status === CalendlyInviteStatus.Rescheduled
  ) {
    return (
      <div>
        <div
          className={`${getBadgeColor(
            calendlyInvite.status
          )} inline-flex items-center font-medium rounded text-xs space-x-2 px-2 py-1`}
        >
          <Calendar className="w-4 h-4" />
          <p>
            {new Date(calendlyInvite.eventDate).toLocaleString("en-GB", options)}{" "}
            {getReadableStatus()}
          </p>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div
          className={`${getBadgeColor(
            calendlyInvite.status
          )} inline-flex items-center font-medium rounded text-xs space-x-2 px-2 py-1`}
        >
        <Calendar className="w-4 h-4 mr-1" />
        {getReadableStatus()}
      </div>
    </div>
  );
};
