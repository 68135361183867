import { InactiveDossierOfCaseLoadEntry } from "../../../alerts/inactiveDossierOfCaseLoadEntry";
import { CircleAlert } from "lucide-react";
import { PatientDetails } from "../../../types";
import { useContext } from "react";
import { CaseLoadManagerSelectedPatientContext } from "../../../selectedClientContext";

type DossierNoLongerActiveAlertProps = { data: PatientDetails };

export const DossierNoLongerActiveAlert = ({
  data,
}: DossierNoLongerActiveAlertProps) => {
  // after resolving this alert, the patient will be removed from the case load manager --> close sheet
  const { setSelectedPatientEntry } = useContext(
    CaseLoadManagerSelectedPatientContext
  )!;

  return (
    <div className="mt-2 p-2 border border-dashed rounded-lg border-red-400 text-red-600 bg-red-50">
      <div className="flex items-center space-x-2 w-full">
        <div className="w-4 h-4">
          <CircleAlert className="w-4 h-4" />
        </div>
        <div className="flex items-center w-full justify-between space-x-2">
          <InactiveDossierOfCaseLoadEntry
            caseLoadId={data.id}
            patientName={data.name}
            onRemoved={() => setSelectedPatientEntry(null)}
          />
        </div>
      </div>
    </div>
  );
};
