import React, { Fragment } from "react";
import { observer } from "mobx-react";
import { styled } from "@mui/system";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import useStores from "../../../useStores";
import StatusLabelBoolean from "../../../shared/StatusLabelBoolean";

const StyledCardContent = styled(CardContent)(() => ({
  padding: 0,
}));

const UserAgreements = () => {
  const { patientStore } = useStores();
  const { dossier } = patientStore;

  const renderAgreeLabel = (agree) => {
    return (
      <StatusLabelBoolean
        boolean={agree}
        positive="Akkoord"
        negative="Niet akkoord"
      />
    );
  };

  return (
    <Card>
      <CardHeader title="Praktijkovereenkomst" />
      <Divider />
      <StyledCardContent>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Praktijkovereenkomst Status</TableCell>
              <TableCell>
                <StatusLabelBoolean
                  boolean={dossier.userAgreements.generalTermsAndConditions}
                  positive="Bevestigd"
                  negative="Open"
                />
              </TableCell>
            </TableRow>
            {dossier.userAgreements.generalTermsAndConditions ? (
              <>
                <TableRow>
                  <TableCell>Onderzoek</TableCell>
                  <TableCell>
                    {renderAgreeLabel(dossier.userAgreements.research)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Delen huisarts</TableCell>
                  <TableCell>
                    {renderAgreeLabel(
                      dossier.userAgreements.shareGeneralPractitioner
                    )}
                  </TableCell>
                </TableRow>
              </>
            ) : null}
          </TableBody>
        </Table>
      </StyledCardContent>
    </Card>
  );
};

export default observer(UserAgreements);
