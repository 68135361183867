import React, { useState } from "react";
import { TableCell, TableRow } from "../../components/ui/table";
import { observer } from "mobx-react";
import Button from "@mui/material/Button";
import ChangeTagsModal from "./ChangeTagsModal";
import { EpdUser, UserTags } from "./types";
import { toast } from "sonner";

const PsychologistsTableRow = ({
  employee,
  employeeTags,
  saveTags,
}: {
  employee: EpdUser;
  employeeTags: UserTags;
  saveTags: any;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onTagsSave = (roles: unknown) => {
    setIsModalOpen(false);
    saveTags(employee.id, roles);

    toast("Tags updated", {
      description: "The tags of the user has been updated.",
    });
  };

  return (
    <TableRow>
      <TableCell>{employee.name}</TableCell>
      <TableCell>{employee.epdId}</TableCell>
      <TableCell className="flex justify-end">
        <Button onClick={() => setIsModalOpen(true)} variant="outlined">
          {"tags aanpassen"}
        </Button>
        <ChangeTagsModal
          open={isModalOpen}
          employee={employee}
          employeeTags={employeeTags}
          onSave={(tags: any) => onTagsSave(tags)}
          onClose={() => setIsModalOpen(false)}
        />
      </TableCell>
    </TableRow>
  );
};

export default observer(PsychologistsTableRow);
