import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import axios from "axios";
import patchDataFactory from "../utils/patchDataFactory";
import dossierType from "../utils/constants/dossierType";
import { queryClient } from "../react-query/provider";

class PatientStore {
  patient = {};

  questionLists = [];

  processBots = [];

  billing = [];

  schedules = [];

  selectedDossierId = null;

  constructor() {
    makeObservable(this, {
      patient: observable,
      questionLists: observable,
      processBots: observable,
      schedules: observable,
      billing: observable,
      selectedDossierId: observable,

      dossier: computed,
      fullEpdId: computed,

      deletePatient: action,
      createQuestionList: action,
      patchPatient: action,
      callWireConversationReport: action,
      fetchPatient: action,
      fetchProcessBots: action,
      fetchBilling: action,
      activateProcessBot: action,
      fetchSchedules: action,
      deactivateSchedule: action,
      createSchedule: action,
      editSchedule: action,
      patchDossier: action,
      putBilling: action,
      manuallyCloseBilling: action,
      reset: action,
    });
  }

  reset() {
    this.patient = {};
    this.questionLists = [];
    this.processBots = [];
    this.billing = [];
    this.schedules = [];
    this.selectedDossierId = null;
  }

  get dossier() {
    if (
      this.patient.dossiers &&
      this.patient.dossiers.length > 0 &&
      this.selectedDossierId !== null
    ) {
      const selectedDossier = this.patient.dossiers.find(
        (dossier) => dossier.id === this.selectedDossierId
      );

      if (selectedDossier) {
        return selectedDossier;
      }
    }

    return null;
  }

  async fetchBilling() {
    axios
      .get(`billings/${this.dossier.billing.lookupGuid}`)
      .then((res) => {
        runInAction(() => {
          this.billing = res.data;
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  get fullEpdId() {
    if (this.dossier == null) return null;

    if (this.dossier.epdFileIdBeforeZPM)
      return `${this.dossier.epdPatientId}.${this.dossier.epdFileId} & ${this.dossier.epdPatientId}.${this.dossier.epdFileIdBeforeZPM}`;

    return `${this.dossier.epdPatientId}.${this.dossier.epdFileId}`;
  }

  async fetchPatientInformation(patientId, optionalDossierId) {
    this.reset();
    await this.fetchPatient(patientId);

    if (optionalDossierId != null && !Number.isNaN(optionalDossierId)) {
      runInAction(() => {
        this.selectedDossierId = optionalDossierId;
      });
    }

    if (this.dossier === null) return;

    await this.fetchQuestionLists(optionalDossierId);

    if (
      this.dossier.$type === dossierType.GGZ ||
      this.dossier.$type === dossierType.ADHD
    ) {
      await this.fetchBilling();
      await this.fetchProcessBots();
      await this.fetchSchedules();
    }
  }

  async fetchQuestionLists(dossierId) {
    axios.get(`/adminquestionlists/for-dossier/${dossierId}`).then(
      action("fetchSuccess", (res) => {
        this.questionLists = res.data;
      })
    );
  }

  async fetchPatient(guid) {
    const res = await axios.get(`patients/${guid}`);

    runInAction(() => {
      this.patient = res.data;

      // properties on the patient may have changed, so we need to invalidate the iwelcome-automatic-email-template query
      this.refetchIWelcomeAutomaticEmailTemplate(res.data.dossiers);
    });
  }

  async createQuestionList(questionListName) {
    await axios
      .post("/adminquestionlists", {
        DossierId: this.dossier.id,
        QuestionListName: questionListName,
      })
      .then((res) => {
        runInAction(() => {
          this.questionLists.push(res.data);
        });
      });
  }

  async completeDossier(closingReason, freeTextClosingReason) {
    const requestBody = {
      ClosingReason: closingReason,
      FreeTextClosingReason: freeTextClosingReason,
    };

    const res = await axios.post(
      `patients/${this.patient.id}/dossiers/${this.dossier.id}/complete`,
      requestBody
    );

    runInAction(() => {
      this.patient = res.data;
    });

    return res.data;
  }

  async patchPatient(replacements) {
    const res = await axios.patch(
      `patients/${this.patient.id}`,
      patchDataFactory(replacements)
    );

    runInAction(() => {
      this.patient = res.data;

      // properties on the patient may have changed, so we need to invalidate the iwelcome-automatic-email-template query
      this.refetchIWelcomeAutomaticEmailTemplate(res.data.dossiers);
    });
  }

  async deletePatient() {
    const { id } = this.patient;

    this.reset();

    return axios.delete(`patients/${id}`);
  }

  async patchDossier(replacements) {
    await axios.patch(
      `patients/${this.patient.id}/dossiers/${this.dossier.id}`,
      patchDataFactory(replacements)
    );

    runInAction(() => {
      this.fetchPatient(this.patient.id);
    });
  }

  async callWireConversationReport() {
    return axios.post(`dossiers/${this.dossier.id}/wirereporting`);
  }

  async fetchProcessBots() {
    axios
      .get(`patient/${this.patient.id}/process-bots`)
      .then((res) => {
        runInAction(() => {
          this.processBots = res.data;
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async deactivateProcessBot(processBotType, reason) {
    axios
      .put(`patient/${this.patient.id}/process-bots/deactivate`, {
        processBotType,
        reason,
      })
      .then(() => this.fetchProcessBots())
      .catch((err) => {
        console.error(err);
      });
  }

  async activateProcessBot(processBot) {
    axios
      .put(`patient/${this.patient.id}/process-bots/activate`, {
        ProcessBotType: processBot.processBotType,
      })
      .then(() => this.fetchProcessBots())
      .catch((err) => {
        console.error(err);
      });
  }

  async fetchSchedules() {
    axios
      .get(`patient/${this.patient.id}/schedules`)
      .then((res) => {
        runInAction(() => {
          this.schedules = res.data.reverse();
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async deactivateSchedule(scheduleId, reason) {
    axios
      .patch(`patient/${this.patient.id}/schedules/${scheduleId}/deactivate`, {
        reason,
      })
      .then(() => this.fetchSchedules())
      .catch((err) => {
        console.error(err);
      });
  }

  async createSchedule(scheduleDefinitionId, cronNotation, endDateTime) {
    axios
      .post(`patient/${this.patient.id}/schedules`, {
        ScheduleDefinitionId: scheduleDefinitionId,
        ScheduleCronNotation: cronNotation.toString(),
        EndDateTime: endDateTime ? endDateTime.toISOString() : null,
      })
      .then(() => this.fetchSchedules())
      .catch((err) => {
        console.error(err);
      });
  }

  async editSchedule(scheduleId, cronNotation, endDateTime) {
    axios
      .patch(`patient/${this.patient.id}/schedules/${scheduleId}`, {
        ScheduleCronNotation: cronNotation.toString(),
        EndDateTime: endDateTime ? endDateTime.toISOString() : null,
      })
      .then(() => this.fetchSchedules())
      .catch((err) => {
        console.error(err);
      });
  }

  async putBilling(values) {
    await axios.put(`billings/${values.lookupGuid}`, {
      isContractedHealthCare: values.isContractedHealthCare,
      isInsuredHealthCare: values.isInsuredHealthCare,
      isPayedByThirdParty: values.isPayedByThirdParty,
      paymentPercentage: values.paymentPercentage,
      paymentAfterwards: values.paymentAfterwards,
      note: values.note,
    });

    runInAction(() => {
      this.fetchPatient(this.patient.id).then(() => this.fetchBilling());
    });
  }

  async manuallyCloseBilling() {
    axios
      .patch(`billings/${this.billing.lookupGuid}/manually-close-billing`)
      .then(() => this.fetchBilling());
  }

  refetchIWelcomeAutomaticEmailTemplate(dossiers) {
    for (let index = 0; index < dossiers.length; index++) {
      const dossierId = dossiers[index].id;
      const queryKey = ["iwelcome-automatic-email-template", dossierId];
      queryClient.invalidateQueries(queryKey);
    }
  }
}

export default PatientStore;
