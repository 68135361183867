import { useMutation, useQuery } from "react-query";
import axios from "axios";
import { UnsuccessfullySentEmail } from "./types";
import { queryClient } from "../../../../react-query/provider";

export const useGetiWelcomeAutomaticTemplate = (dossierId: number) => {
  return useQuery<{ id: string; name: string }>(
    ["iwelcome-automatic-email-template", dossierId],
    async () => {
      const res = await axios.get(
        `dossiers/${dossierId}/iwelcome-automatic-email-template`
      );
      return res.data;
    },
    {
      enabled: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useGetUnsuccessfullySentiWelcomeAutomaticEmails = (
  dossierId: number
) => {
  return useQuery<UnsuccessfullySentEmail[]>(
    ["unsuccessfully-sent-iwelcome-automatic-emails", dossierId],
    async () => {
      const res = await axios.get(
        `dossiers/${dossierId}/unsuccessfully-sent-iwelcome-automatic-emails`
      );
      return res.data;
    },
    {
      enabled: true,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const useSendiWelcome = (onSuccess: () => void) =>
  useMutation({
    mutationFn: async ({ dossierId }: { dossierId: number }) => {
      const res = await axios.post(
        `dossiers/${dossierId}/send-iwelcome-email`,
        {}
      );
      return res.data;
    },
    onSuccess: async () => {
      const queryKey1 = ["referral-manager", "view-details"];
      const queryKey2 = ["referral-manager", "activity-log"];

      queryClient.invalidateQueries(queryKey1);
      queryClient.refetchQueries(queryKey1);

      queryClient.invalidateQueries(queryKey2);
      queryClient.refetchQueries(queryKey2);

      await onSuccess();
    },
  });
