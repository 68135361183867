import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { Loader2 } from "lucide-react";
import { Button } from "../../../../components/ui/button";
import { Checkbox } from "../../../../components/ui/checkbox";
import { SavedGermanClient, UnsavedGermanClient } from "../types";
import { Name } from "./fields/name";
import { Email } from "./fields/email";
import { Phone } from "./fields/phone";
import { Treatment } from "./fields/treatment";
import { useState } from "react";
import { useCreateiPracticeGermanyClient } from "../hooks";

export const CreateDialog = ({
  isOpen,
  setIsOpen,
  onSave,
}: {
  isOpen: boolean;
  setIsOpen: (newValue: boolean) => void;
  onSave: (savedClient: SavedGermanClient) => void;
}) => {
  const getDefaultUnsavedClient = (): UnsavedGermanClient => {
    return {
      name: "",
      email: "",
      phone: "",
      status: "New",
      numberOfSessions: null,
      isOngoingSessions: false,
      price: null,
    };
  };

  const [unsavedClient, setUnsavedClient] = useState(getDefaultUnsavedClient());

  const canSave = () => {
    return unsavedClient.name.length > 0 && unsavedClient.email.length > 0;
  };

  const { mutate, isLoading } = useCreateiPracticeGermanyClient((data) => {
    setUnsavedClient(getDefaultUnsavedClient());
    onSave(data);
  });

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="min-w-[50vw]">
        <DialogHeader>
          <DialogTitle className="text-2xl font-bold">
            Neuen Kunden erstellen
          </DialogTitle>
        </DialogHeader>

        <section>
          <Name
            value={unsavedClient.name}
            setValue={(newValue) =>
              setUnsavedClient({ ...unsavedClient, name: newValue })
            }
          />

          <div className="flex items-center space-x-8">
            <div className="w-1/2">
              <Email
                value={unsavedClient.email}
                setValue={(newValue) =>
                  setUnsavedClient({ ...unsavedClient, email: newValue })
                }
              />
            </div>
            <div className="w-1/2">
              <Phone
                value={unsavedClient.phone}
                setValue={(newValue) =>
                  setUnsavedClient({ ...unsavedClient, phone: newValue })
                }
              />
            </div>
          </div>

          <Treatment
            price={unsavedClient.price}
            setPrice={(newValue) =>
              setUnsavedClient({ ...unsavedClient, price: newValue })
            }
            numberOfSessions={unsavedClient.numberOfSessions}
            isOngoingSessions={unsavedClient.isOngoingSessions}
            setNumberOfSessions={(num, isOngoingSessions) =>
              setUnsavedClient({
                ...unsavedClient,
                numberOfSessions: num,
                isOngoingSessions,
              })
            }
          />
        </section>

        <DialogFooter className="gap-2">
          <Button
            variant="outline"
            className="px-6"
            onClick={() => setIsOpen(false)}
          >
            Abbrechen
          </Button>

          <Button
            disabled={!canSave()}
            onClick={async (e) => {
              e.preventDefault();
              await mutate({
                name: unsavedClient.name,
                email: unsavedClient.email,
                phone: unsavedClient.phone,
                numberOfSessions: unsavedClient.numberOfSessions,
                isOngoingSessions: unsavedClient.isOngoingSessions,
                price: unsavedClient.price,
              });
            }}
            className="px-6 flex items-center space-x-2"
          >
            {isLoading && <Loader2 className="w-4 h-4 animate-spin" />}
            <p>Kunden erstellen</p>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
