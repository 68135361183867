import useStores from "../../useStores";
import roleNames from "../../utils/constants/roleNames";
import { useGetAuthorizations } from "../../shared/hooks/useGetAuthorizations";
import { SelectableValue } from "../../components/ui/multiselect";
import { useState } from "react";
import { FiltersButton } from "./filters";
import { EmptySlotsTable } from "./tables";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import { EmptySpotsAndTrajectoriesResult, WeekResult } from "./types";
import { Skeleton } from "../../components/ui/skeleton";
import { Button } from "../../components/ui/button";
import { Download } from "lucide-react";
import { toast } from "sonner";
import { useLocation } from "react-router-dom";
import { useIsEnabled } from "../../feature-management/useIsEnabled";

const EmptyIntakeSpotsReport = () => {
  const { data: authorization } = useGetAuthorizations();
  const location = useLocation();

  const showAsStandalonePage = location.pathname.includes(
    "reports/empty-intake-spots"
  );

  const { data: isCrmEnabledViaFeatureFlag } = useIsEnabled(
    "EnableCustomerRelationshipManagerApi"
  );

  const isEmptySpotsReportEnabled =
    authorization?.roles.includes(roleNames.ADMINISTRATOR) ||
    authorization?.roles.includes(roleNames.REPORT_MANAGER) ||
    authorization?.position == "Matching Psychologist" ||
    isCrmEnabledViaFeatureFlag;

  const [showEachPsychologist, setShowEachPsychologist] = useState(false);
  const [selectedPsychologist, setSelectedPsychologist] = useState("");
  const [selectedLocations, setSelectedLocations] = useState<SelectableValue[]>(
    []
  );

  const { data, isLoading } = useQuery<EmptySpotsAndTrajectoriesResult>(
    ["calendar-admin", "empty-spots-and-trajectories"],
    async () => {
      const res = await axios.get(`calendarreporting/empty-spots-and-trajectories`);
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: download, isLoading: isDownloading } = useMutation({
    mutationFn: async () => {
      let url = new URL(
        `${window.location.protocol}/${window.location.host}/api/calendarreporting/empty-spots-and-trajectories/export`
      );

      if (showEachPsychologist) {
        url.searchParams.append("showAllPsychologists", true.toString());
      }

      if (selectedPsychologist) {
        url.searchParams.append("psychologistEpdId", selectedPsychologist);
      }

      selectedLocations.forEach((loc) => {
        url.searchParams.append("locations", loc.value);
      });

      const res = await axios.post(
        url.toString(),
        {},
        {
          responseType: "blob",
        }
      );
      return res.data;
    },
    onSuccess: (data) => {
      const url = window.URL.createObjectURL(
        new Blob([data], { type: "text/csv" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `empty_spots_${new Date().toLocaleString()}.csv`
      );
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();

      toast("Downloaded", {
        description: "The data has been downloaded.",
      });
    },
  });

  const isLastWeekOfMonth = () => {
    const today = new Date();
    const totalDaysInMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    ).getDate();

    const lastDayOfWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + (6 - today.getDay())
    ).getDate();

    return lastDayOfWeek >= totalDaysInMonth - 6;
  };

  const getMonthToShow = () => {
    return isLastWeekOfMonth()
      ? (new Date().getMonth() + 1) % 12
      : new Date().getMonth() % 12;
  };

  const getReadableNextMonth = () => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return monthNames[getMonthToShow()];
  };

  const sumNextMonthsData = (sumBy: keyof WeekResult) => {
    let sum = 0;

    // get the next month (0-11, where 0 is January and 11 is December)
    const nextMonth = getMonthToShow();
    [
      ...data!.period1,
      ...data!.period2,
      ...data!.period3,
      ...data!.period4,
      ...data!.period5,
    ].forEach((item) => {
      const startOfWeek = new Date(item.startOfWeek);
      const endOfWeek = new Date(item.endOfWeek);

      const matchByLocation =
        selectedLocations.length == 0 ||
        selectedLocations.some((location) =>
          item.locations.includes(location.value)
        );

      if (
        matchByLocation &&
        (startOfWeek.getMonth() === nextMonth ||
          endOfWeek.getMonth() === nextMonth)
      ) {
        sum += item[sumBy] as number;
      }
    });

    return sum;
  };

  const getLastPeriodEmptyIntakeSpots = () => {
    const year = new Date().getFullYear();
    const nextMonth = getMonthToShow();

    const nextMonthStart = new Date(
      nextMonth == 0 ? year + 1 : year,
      nextMonth,
      1
    );
    const nextMonthEnd = new Date(
      nextMonth == 0 ? year + 1 : year,
      nextMonth + 1,
      0,
      23,
      59,
      59
    );

    const filteredPeriods = [
      data!.period1,
      data!.period2,
      data!.period3,
      data!.period4,
      data!.period5,
    ].filter((period) => {
      const start = new Date(period[0].startOfWeek);
      const end = new Date(period[0].endOfWeek);
      return (
        (start <= nextMonthEnd && start >= nextMonthStart) ||
        (end <= nextMonthEnd && end >= nextMonthStart) ||
        (start < nextMonthStart && end > nextMonthEnd)
      );
    });

    const sortedFilteredPeriods = filteredPeriods.sort(
      (a, b) =>
        new Date(a[0].startOfWeek).getTime() -
        new Date(b[0].endOfWeek).getTime()
    );

    const lastPeriod = sortedFilteredPeriods[sortedFilteredPeriods.length - 1];

    return lastPeriod
      .filter((item) => {
        return (
          selectedLocations.length == 0 ||
          selectedLocations.some((location) =>
            item.locations.includes(location.value)
          )
        );
      })
      .reduce((acc, item) => acc + item.emptyIntakeSpots, 0);
  };

  if (!isEmptySpotsReportEnabled) return <></>;

  return (
    <main className={showAsStandalonePage ? "p-24" : ""}>
      <section className="w-full flex justify-between items-center">
        <section>
          <h1
            className={`${
              showAsStandalonePage ? "text-4xl" : "text-xl"
            } font-bold`}
          >
            Number of empty spots & new trajectories
          </h1>
          <p>
            View the number of empty spots and number of new trajectories that
            could potentially start for the next 4 weeks.
          </p>
        </section>

        <FiltersButton
          showEachPsychologist={showEachPsychologist}
          setShowEachPsychologist={setShowEachPsychologist}
          locationsFilter={selectedLocations}
          setLocationsFilter={setSelectedLocations}
          psychologistFilter={selectedPsychologist}
          setPsychologistFilter={setSelectedPsychologist}
        />
      </section>

      <section>
        {data ? (
          <div className="flex items-center space-x-2">
            <div className="w-[270px] my-4 inline-block rounded border p-4">
              <h1 className="text-sm opacity-80">
                Total Empty Spots in {getReadableNextMonth()}
              </h1>
              <p className="text-2xl">
                {sumNextMonthsData("emptySpotsInAgenda").toFixed(1)}
              </p>
            </div>

            <div className="w-[270px] my-4 inline-block rounded border p-4">
              <h1 className="text-sm opacity-80">
                Total Empty Intake Spots in {getReadableNextMonth()}
              </h1>
              <p className="text-2xl">
                {sumNextMonthsData("emptyIntakeSpots").toFixed(1)}
              </p>
            </div>

            <div className="w-[300px] my-4 inline-block rounded border p-4">
              <h1 className="text-sm opacity-80">
                Last period Empty Intake Spots in {getReadableNextMonth()}
              </h1>
              <p className="text-2xl">
                {getLastPeriodEmptyIntakeSpots().toFixed(1)}
              </p>
            </div>
          </div>
        ) : (
          <div className="flex items-center space-x-2">
            <Skeleton className="w-[270px] h-[90px] my-4 rounded border" />
            <Skeleton className="w-[270px] h-[90px] my-4 rounded border" />
            <Skeleton className="w-[300px] h-[90px] my-4 rounded border" />
          </div>
        )}
      </section>

      <EmptySlotsTable
        isLoading={isLoading}
        data={data!}
        showEachPsychologist={showEachPsychologist}
        locations={selectedLocations.map((loc) => loc.value)}
        psychologist={selectedPsychologist}
      />

      <Button
        className="mt-4 px-6 flex items-center space-x-2"
        onClick={async () => await download()}
      >
        <div className="h-4 w-4">
          <Download className="h-4 w-4" />
        </div>
        <p>{isDownloading ? "Downloading CSV..." : "Download as CSV"}</p>
      </Button>
    </main>
  );
};

export default EmptyIntakeSpotsReport;
