import { Copy, SendHorizontal, SquareArrowOutUpRight } from "lucide-react";
import { toast } from "sonner";
import { useIsEnabled } from "../../../../../../feature-management/useIsEnabled";

type Props = {
  description?: string;
  onSuggestedResourceClick: () => void;
  title: string;
  url?: string;
};

export function ResourceSuggestionCard({
  description,
  title,
  url,
  onSuggestedResourceClick,
}: Props) {
  const { data: isCaseLoadManagerWireReplacementEnabled } = useIsEnabled(
    "EnableCaseLoadManagerWireReplacement"
  );

  return (
    <div className="border flex h-25 w-full min-w-fit p-3 flex-col rounded-lg bg-blue-50">
      <div className="flex items-center justify-between w-full">
        <a className="text line-clamp-2 max-w-[80%] break-words font-semibold leading-5 antialiased">
          {title}
        </a>
      </div>

      <p className="mb-auto line-clamp-3 max-w-[80%] text-xs leading-4 tracking-[0.5px] antialiased">
        {description}
      </p>

      <div className="pt-4 flex items-center space-x-6 text-xs text-link cursor-pointer">
        <div
          className="flex items-center space-x-1 hover:underline"
          onClick={async () => {
            await navigator.clipboard.writeText(url || "");
            toast("Link copied!", {
              description:
                "The link to the resource has been copied to your clipboard",
            });
          }}
        >
          <div className="w-4 h-4">
            <Copy className="w-4 h-4" />
          </div>
          <p>Copy link</p>
        </div>
        <div
          className="flex items-center space-x-1 hover:underline"
          onClick={() => {
            window.open(url, "_blank");
          }}
        >
          <div className="w-4 h-4">
            <SquareArrowOutUpRight className="w-4 h-4" />
          </div>
          <p>Open resource</p>
        </div>
        {isCaseLoadManagerWireReplacementEnabled ? (
          <div
            className="flex items-center space-x-1 hover:underline"
            onClick={() => onSuggestedResourceClick()}
          >
            <div className="w-4 h-4">
              <SendHorizontal className="w-4 h-4" />
            </div>
            <p>Use in Chat</p>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
