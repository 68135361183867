import { EllipsisVertical, Loader2, X } from "lucide-react";
import { useGetAssignedPatient } from "../../hooks";
import { DossierLinks } from "./dossierLinks";
import { RemovePatientButton } from "./removePatientButton";
import { Remarks } from "./remarks";
import { Alerts } from "./alerts/alerts";
import { NeedsCheckinCheckbox } from "../../needsCheckin/needsCheckinCheckbox";
import { SupportCheckboxes } from "../../supportPatient/supportCheckboxes";
import { Comments } from "../../psychologistComments/comments";
import { useTranslation } from "react-i18next";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../../components/ui/popover";

type HeaderProps = {
  clientId: string;
  onClose: () => void;
};

export const Header = ({ clientId, onClose }: HeaderProps) => {
  const { t } = useTranslation();
  const { data, isFetching } = useGetAssignedPatient(clientId);

  if (!data) return <></>;

  return (
    <main>
      <div>
        <div className="mb-4 pb-2 border-b flex items-center justify-between w-full">
          <div className="flex items-center space-x-4">
            <p className="text-lg">
              {data.epdPatientId}.{data.epdFileId}
            </p>
            <DossierLinks clientId={clientId} />
          </div>
          <section className="flex items-center space-x-4">
            <div className="w-5 h-5" onClick={() => onClose()}>
              <X className="w-5 h-5 cursor-pointer opacity-75 hover:opacity-100" />
            </div>
          </section>
        </div>
        <div className="w-full flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <h1 className="text-3xl font-bold">{data.name}</h1>
            <Popover>
              <PopoverTrigger style={{ zIndex: 99999 }} asChild>
                <div className="w-4 h-4 cursor-pointer">
                  <EllipsisVertical className="w-4 h-4" />
                </div>
              </PopoverTrigger>
              <PopoverContent
                style={{ zIndex: 99999 }}
                className="min-w-fit"
                align="start"
                side="bottom"
              >
                <RemovePatientButton clientId={clientId} onRemoval={onClose} />
              </PopoverContent>
            </Popover>
          </div>
          {isFetching && (
            <Loader2 className="w-4 h-4 opacity-70 animate-spin" />
          )}
        </div>
        <Remarks clientId={clientId} />
        <Alerts clientId={clientId} />
      </div>

      <div className="flex items-start justify-between space-x-6 pt-2 pb-4">
        <section className="w-1/2">
          <h2 className="font-bold pb-2">Status</h2>
          <div className="p-[18px] border rounded-lg space-y-3">
            <NeedsCheckinCheckbox data={data} onBlur={() => {}} />
            <SupportCheckboxes data={data} onBlur={() => {}} />
          </div>
        </section>

        <section className="w-1/2">
          <h2 className="font-bold pb-2">
            {t("case-load-manager-column-your-comments")}
          </h2>
          <Comments data={data} />
        </section>
      </div>
    </main>
  );
};
