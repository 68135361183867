import { Loader2 } from "lucide-react";
import * as colors from "@mui/material/colors";

const Loading = ({ withoutText, large }) => {
  return (
    <div className="w-full min-h-screen flex flex-col items-center justify-center text-muted-foreground space-y-2 font-normal bg-gray-200 dark:bg-slate-800 animate-pulse">
      {withoutText ? (
        <></>
      ) : (
        <>
          <Loader2 className="w-12 h-12 animate-spin" />
          <p style={{ fontSize: large ? "1.2rem" : "1rem" }}>Loading...</p>
        </>
      )}
    </div>
  );
};

export default Loading;
