import { useEffect, useState } from 'react';

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    height: 0,
    width: 0,
  });
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  useEffect(() => {
    if (typeof window === undefined) return;

    const handleResize = () => {
      setScreenSize({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };

    window.addEventListener('resize', handleResize);

    const userAgent = navigator.userAgent.toLowerCase();

    const isMobileDevice = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(userAgent);
    const isTabletDevice = /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent);

    setIsMobileDevice(isMobileDevice || isTabletDevice);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobile = screenSize.width < 768 && isMobileDevice;
  return { isMobile, isMobileDevice, screenSize };
};
