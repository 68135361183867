import { useGetAssignedPatient } from "../../hooks";
import { Comments } from "../../treatmentPlan/comments";
import { AiSummary } from "../../treatmentPlan/aiSummary";

type TreatmentDetailsProps = {
  clientId: string;
};

export const TreatmentDetails = ({ clientId }: TreatmentDetailsProps) => {
  const { data } = useGetAssignedPatient(clientId);

  if (!data) return <></>;

  return (
    <main className="w-full flex flex-col">
      <div className="flex items-start space-x-6">
        <div className="w-2/3">
          <AiSummary data={data} />
        </div>

        <div className="w-1/3">
          <div className="text-lg font-bold">Treatment Comments</div>
          <div className="mt-4">
            <Comments data={data} />
          </div>
        </div>
      </div>

      <div className="pt-6 mt-6 border-t w-full whitespace-pre-wrap">
        <h1 className="text-lg font-bold">Full Praktijkdata Plan</h1>
        <p>
          {data.epdTreatment.treatmentPlan
            ? data.epdTreatment.treatmentPlan
            : "No treatment plan found."}
        </p>
      </div>
    </main>
  );
};
