import { DNA } from "react-loader-spinner";
import { useDarkMode } from "../theme/useDarkMode";

export const ApplicationInitializing = () => {
  const [theme] = useDarkMode();

  return (
    <div className="w-full min-h-screen flex flex-col items-center justify-center text-muted-foreground space-y-2 bg-gradient-to-tl from-blue-100 to-white  dark:from-gray-950 dark:to-gray-900">
      <DNA
        visible
        height="110"
        width="110"
        ariaLabel="loading"
        wrapperStyle={{}}
      />

      <h1 className="mt-4 text-6xl text-ipractice-blue font-medium">
        iPractice
      </h1>
      <p className="uppercase text-xl text-gray-500 tracking-widest">
        Dashboard
      </p>
    </div>
  );
};
