const questionListName = {
  BASIS_GGZ_INTAKE_V1: "intake",
  BASIS_GGZ_INTAKE_V2: "intake-2",
  AGREE_DEPRECATED: "agree",
  AGREE_V2: "agree-v2",
  AGREE_V3: "agree-v3",
  SQ48: "SQ48-stand-alone",
  SQ48_MHC_SF: "SQ48-stand-alone-mhc",
  CQ_INDEX: "cq-index",
  CQ_INDEX_V51: "cq-index-v51",
  IFOLLOWUP: "ifollowup",
};

export default questionListName;
