import React from "react";
import { Grid } from "@mui/material";
import { observer } from "mobx-react";
import Onboarding from "./Onboarding";
import Intake from "./Intake";
import Questionnaires from "../../../shared/Questionnaires";

const OnboardingTab = () => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={6} xl={4}>
        <Intake />
      </Grid>
      <Grid item xs={6} xl={8} />

      <Grid item xs={6} xl={4}>
        <Onboarding />
      </Grid>
      <Grid item xs={6} xl={8} />

      <Grid item xs={12} xl={10}>
        <Questionnaires />
      </Grid>
    </Grid>
  );
};

export default observer(OnboardingTab);
