import { action, makeObservable, observable, runInAction } from "mobx";
import axios from "axios";
import patchDataFactory from "../utils/patchDataFactory";

class WireUsersStore {
  unassignedWireUsers = [];

  constructor() {
    makeObservable(this, {
      unassignedWireUsers: observable,
      fetchUnassignedWireUsers: action,
      patchWireUser: action,
    });
  }

  async fetchUnassignedWireUsers() {
    const res = await axios.get("wireusers");

    runInAction(() => {
      this.unassignedWireUsers = res.data;
    });
  }

  async patchWireUser(wireUserId, replacements) {
    await axios.patch(
      `wireusers/${wireUserId}`,
      patchDataFactory(replacements)
    );

    runInAction(() => {
      this.fetchUnassignedWireUsers();
    });
  }
}

export default WireUsersStore;
