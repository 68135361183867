import { Message, MessageWithMetadata, MessageMetadata } from './types';
import { Message as AiMessage } from 'ai';

export function messageContentToString(message: Message) {
  if (message.content?.length === 0) {
    return '';
  }

  return message.content
    .filter((content) => !content.type || (content.type === 'text' && content.text?.value))
    .map((content) => content.text!.value)
    .join('\n');
}

export function extractURLsFromString(text: string, limit?: number) {
  const urlRegex = /(https?:\/\/\S+)/g;
  const urls =
    text
      .match(urlRegex)
      ?.filter((url) => !url.includes('youtube') && !url.includes('youtu.be'))
      .map((url) => {
        // remove trailing ) if it exists
        if (url.endsWith(')')) {
          return url.slice(0, -1);
        }

        return url;
      }) ?? [];

  if (limit) {
    return urls.slice(0, limit);
  }

  return urls;
}

export function extractYoutubeUrlFromString(text: string) {
  const youtubeUrlRegex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?([\w-]{11})/;
  const youtubeUrl = text.match(youtubeUrlRegex)?.[0];

  if (youtubeUrl) {
    return youtubeUrl;
  }

  return null;
}

export function aiMessagesToMessages(
  messages: AiMessage[],
  metadata?: MessageMetadata[]
): MessageWithMetadata[] {
  return messages.map((message, index) => {
    const metadataFromMessage = message.data as MessageMetadata;
    const metadatasFromData = metadata?.filter((data) => data.index === index);

    // merge all metadata in metadatasFromData array
    const metadataFromData = metadatasFromData?.reduce(
      (acc, data) => ({ ...acc, ...data }),
      metadataFromMessage
    );

    // merge metadata from message and metadata from data
    const messageMetadata = {
      ...metadataFromMessage,
      ...metadataFromData,
    };

    return {
      message: {
        ...message,
        content: [
          {
            text: {
              value: message.content,
            },
          },
        ],
        createdAt: message.createdAt ?? new Date(),
      },
      metadata: messageMetadata,
    };
  });
}

export function messagesToAiMessages(messages: MessageWithMetadata[]): AiMessage[] {
  return messages.map(({ message, metadata }) => {
    return {
      ...message,
      content: message.content.map(({ text }) => text?.value ?? '').join(' '),
      createdAt: message.createdAt ?? new Date(),
      data: metadata,
      id: message.id ?? 'todo',
    };
  });
}
