import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { BigRegistrationReport } from "./types";
import { queryClient } from "../../react-query/provider";

const refetchBigRegistrationReport = () => {
  queryClient.invalidateQueries("big-registration-report");
  queryClient.refetchQueries("big-registration-report");
};

export const useGetBigRegistrationReport = (
  onSuccess: (data: BigRegistrationReport[]) => void
) => {
  const query = useQuery<BigRegistrationReport[]>(
    ["big-registration-report"],
    async () => {
      const res = await axios.get(`bigregister`);
      return res.data;
    },
    {
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onSuccess: onSuccess,
    }
  );

  return query;
};

export const useRetriggerBigRegistration = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async () => {
      const res = await axios.post(`bigregister/re-trigger`, {});
      return res.data;
    },
    onSuccess: () => {
      refetchBigRegistrationReport();
      onSuccess();
    },
  });

  return mutation;
};

export const useUpdateEmployeeBigRegistrationCode = (
  onSuccess: () => void,
  onError: () => void
) => {
  const mutation = useMutation({
    mutationFn: async ({ email, code }: { email: string; code: string }) => {
      const res = await axios.patch(
        `bigregister/${email}/big-registration-code`,
        { code }
      );
      return res.data;
    },
    onSuccess: () => {
      refetchBigRegistrationReport();
      onSuccess();
    },
    onError: () => {
      onError();
    },
  });

  return mutation;
};

export const useExportBigRegistrationToPdf = (
  onSuccess: (data: any) => void
) => {
  const mutation = useMutation({
    mutationFn: async () => {
      const res = await axios.post(
        `bigregister/generate-pdf`,
        {},
        {
          responseType: "blob",
        }
      );

      return res.data;
    },
    onSuccess: onSuccess,
  });

  return mutation;
};
