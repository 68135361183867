import React from "react";
import Button from "@mui/material/Button";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { observer } from "mobx-react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import ConfirmDialog from "./ConfirmDialog";
import useStores from "../useStores";
import getDossierIdentifier from "../utils/getDossierIdentifier";

const StyledDeleteOutlineIcon = styled(DeleteOutlineIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const DeleteDossier = () => {
  const [isDeleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const { patientStore, dossiersStore } = useStores();
  const { patient, dossier } = patientStore;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleDelete = () => {
    dossiersStore
      .deleteDossier(dossier.id)
      .then(() => {
        setDeleteModalOpen(false);

        enqueueSnackbar("Dossier verwijderd", { variant: "success" });

        navigate(`/patient/${patient.id}`);
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data, { variant: "error" });
      });
  };

  return (
    <>
      <Button onClick={() => setDeleteModalOpen(true)}>
        <StyledDeleteOutlineIcon />
        Verwijderen
      </Button>

      <ConfirmDialog
        question={`Weet je zeker dat je dossier ${getDossierIdentifier(
          dossier
        )} van cliënt ${patient.firstName} ${
          patient.lastName
        } wilt verwijderen?`}
        isOpen={isDeleteModalOpen}
        onConfirm={handleDelete}
        onCancel={() => setDeleteModalOpen(false)}
      />
    </>
  );
};

export default observer(DeleteDossier);
