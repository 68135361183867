import * as ScrollArea from '@radix-ui/react-scroll-area';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export function HorizontalScrollArea({ children }: Props) {
  return (
    <ScrollArea.Root>
      <ScrollArea.Viewport className="min-w-[110%]">{children}</ScrollArea.Viewport>

      <ScrollArea.Scrollbar orientation="horizontal">
        <ScrollArea.Thumb />

        <ScrollArea.ScrollAreaScrollbar />
      </ScrollArea.Scrollbar>
    </ScrollArea.Root>
  );
}
