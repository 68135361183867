import { MainListPatient } from "../types";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

type AiSummaryProps = {
  data: MainListPatient;
};

export const AiSummary = ({ data }: AiSummaryProps) => {
  const value = data?.epdTreatment?.aiTreatmentPlanSummary;

  return (
    <div>
      <h1 className="text-lg font-bold">AI Summary</h1>
      {value ? (
        <ReactMarkdown
          className="pt-4"
          remarkPlugins={[
            [
              remarkGfm,
              {
                tableCellPadding: true,
                tablePipeAlign: true,
                stringLength: true,
              },
            ],
          ]}
          components={{
            table: ({ node, ...props }) => (
              <table
                className="border-collapse border border-gray-300"
                {...props}
              />
            ),
            th: ({ node, ...props }) => (
              <th className="border border-gray-300 p-2" {...props} />
            ),
            td: ({ node, ...props }) => (
              <td className="border border-gray-300 p-2" {...props} />
            ),
          }}
        >
          {data?.epdTreatment?.aiTreatmentPlanSummary}
        </ReactMarkdown>
      ) : (
        <p>No summary found.</p>
      )}
    </div>
  );
};
