export const CustomLegend = ({
  payload,
}: {
  payload: {
    color: string;
    value: string;
  }[];
}) => {
  return (
    <div className="w-full flex items-center justify-center space-x-8 mt-8">
      {payload.map((p) => {
        return (
          <div key={p.value} className="flex items-center space-x-2">
            {/* tailwind's bg-[**magic value**] doesn't work here */}
            <div
              className="w-3 h-3 rounded-full"
              style={{ backgroundColor: p.color }}
            />
            <p>{p.value}</p>
          </div>
        );
      })}
    </div>
  );
};
