import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { Sq48ResultOfDossier } from "./types";
import { queryClient } from "../../../../react-query/provider";

export const useGetSq48ResultsOfDossier = (
  epdPatientId: number | null,
  epdFileId: number
) => {
  const query = useQuery<Sq48ResultOfDossier[]>(
    ["sq48-results-of-dossier", "sq48-admin", epdPatientId, epdFileId],
    async () => {
      const res = await axios.get(
        `adminquestionlists/${epdPatientId}/${epdFileId}/sq48-result-pdfs`
      );
      return res.data;
    },
    {
      enabled: epdPatientId !== null,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useSendQuestionnaireResultPdfToChat = (
  questionListId: string,
  onSuccess: () => void,
  onError: () => void
) => {
  const mutation = useMutation({
    mutationFn: async () => {
      const res = await axios.post(
        `adminquestionlists/${questionListId}/send-sq48-result-to-chat`,
        {}
      );
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["sq48-results-of-dossier", "sq48-admin"]);
      queryClient.refetchQueries(["sq48-results-of-dossier", "sq48-admin"]);
      onSuccess();
    },
    onError: onError,
  });

  return mutation;
};
