import { Button } from '../../../../components/ui/button';
import { Message } from '../types';
import { ReactNode, memo, useState } from 'react';
import { FeedbackDialog } from '../feedbackDialog/FeedbackDialog';
import styles from './chat-message-bubble.module.css';
import { IPracticeAvatar } from './iPracticeAvatar';
import { cn } from '../../../../lib/utils';
import { Check, Copy } from 'lucide-react';

type ChatMessageBubbleProps = {
  children: ReactNode;
  copyableText?: string;
  handleSubmitFeedback?: (feedback: {
    note?: string;
    rating: number;
    reason: string;
  }) => Promise<void>;
  hasFeedback?: boolean;
  messageType: Message['role'];
  shouldAnimateIn?: boolean;
  showAvatar?: boolean;
};

export const ChatMessageBubble = memo(function ChatMessageBubbleNoMemo({
  children,
  copyableText,
  handleSubmitFeedback,
  hasFeedback,
  messageType,
  shouldAnimateIn = true,
  showAvatar = true,
}: ChatMessageBubbleProps) {
  const initial = { opacity: 0.5, y: 20 };
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    if (!copyableText) return;
    navigator.clipboard.writeText(copyableText);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const isApiMessage = messageType === 'assistant' || messageType === 'system';
  const isUserMessage = messageType === 'user';

  if (copyableText === '') {
    return <div></div>;
  }

  return (
    <>
      <div
        className={cn('', {
          'flex flex-row': isApiMessage,
          'ml-auto': isUserMessage,
        })}
      >
        {isApiMessage && showAvatar && (
          <div className="mr-2 flex flex-col">
            <div className="relative flex-shrink-0 flex items-center justify-center overflow-hidden rounded-full bg-ipractice-blue/70 h-10 w-10">
              <IPracticeAvatar />
            </div>
          </div>
        )}

        <div
          className={cn('peer mb-4 rounded-2xl p-3 md:max-w-[550px]', {
            '-mt-3 ml-10': isApiMessage && !showAvatar,
            'bg-white pr-2': isApiMessage,
            'pr-auto bg-ipractice-blue text-white': isUserMessage,
            'text-openup-dark-blue border-openup-gray rounded-tl-sm border': isApiMessage,
            'w-fit rounded-br-sm text-white/90': isUserMessage,
          })}
        >
          <div
            className={cn(
              {
                'text-openup-dark-blue/90': isApiMessage,
              },
              styles.markdownanswer
            )}
          >
            {children}
          </div>

          {isApiMessage && (
            <div className="mt-2 -mb-2 pt-1 border-t border-slate-100 flex items-center gap-1">
              {copyableText !== '' && copyableText !== undefined && (
                <Button
                  variant="ghost"
                  className="p-2 h-fit flex items-center space-x-1"
                  disabled={isCopied}
                  onClick={handleCopy}
                >
                  {isCopied ? (
                    <Check className="animate-in h-4 w-4" />
                  ) : (
                    <Copy className="h-4 w-4" />
                  )}
                  <p className="text-xs font-normal">{isCopied ? 'Copied' : 'Copy'}</p>
                </Button>
              )}

              {typeof handleSubmitFeedback === 'function' && (
                <FeedbackDialog
                  handleSubmitFeedback={handleSubmitFeedback}
                  hasFeedback={hasFeedback}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
});
