import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { ParentFolderDetails } from "./types";
import { queryClient } from "../../../react-query/provider";

const refetchFolders = () => {
  queryClient.invalidateQueries(["my-chat", "folders"]);
  queryClient.refetchQueries(["my-chat", "folders"]);
};

export const useGetFolders = () => {
  const query = useQuery<ParentFolderDetails[]>(
    ["my-chat", "folders"],
    async () => {
      const res = await axios.get(`employeechatfolder`);
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useCreateFolder = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({
      name,
      channelIds,
    }: {
      name: string;
      channelIds: string[];
    }) => {
      const res = await axios.post(`employeechatfolder`, {
        name,
        channelIds,
      });
      return res.data;
    },
    onSuccess: () => {
      refetchFolders();
      onSuccess();
    },
  });

  return mutation;
};

export const useReorderFolders = () => {
  const mutation = useMutation({
    mutationFn: async ({ orderedIds }: { orderedIds: string[] }) => {
      const res = await axios.post(`employeechatfolder/reorder`, {
        orderedIds,
      });
      return res.data;
    },
    onSuccess: () => {
      refetchFolders();
    },
  });

  return mutation;
};

export const useRenameFolder = () => {
  const mutation = useMutation({
    mutationFn: async ({
      folderId,
      newName,
    }: {
      folderId: string;
      newName: string;
    }) => {
      const res = await axios.post(`employeechatfolder/${folderId}/rename`, {
        newName,
      });
      return res.data;
    },
    onSuccess: () => {
      refetchFolders();
    },
  });

  return mutation;
};

export const useAddConversationToFolder = () => {
  const mutation = useMutation({
    mutationFn: async ({
      folderId,
      channelId,
      draggedFromFolderId,
      draggedFromSubFolderId,
    }: {
      folderId: string;
      channelId: string;
      draggedFromFolderId: string | null;
      draggedFromSubFolderId: string | null;
    }) => {
      let url = new URL(
        `${window.location.protocol}//${window.location.host}/api/employeechatfolder/${folderId}/channels`
      );

      if (draggedFromFolderId)
        url.searchParams.append("draggedFromFolderId", draggedFromFolderId);

      if (draggedFromSubFolderId)
        url.searchParams.append(
          "draggedFromSubFolderId",
          draggedFromSubFolderId
        );

      const res = await axios.post(url.toString(), {
        channelId,
      });
      return res.data;
    },
    onSuccess: () => {
      refetchFolders();
    },
  });

  return mutation;
};

export const useRemoveConversationFromFolder = () => {
  const mutation = useMutation({
    mutationFn: async ({
      folderId,
      channelId,
    }: {
      folderId: string;
      channelId: string;
    }) => {
      const res = await axios.delete(
        `employeechatfolder/${folderId}/channels/${channelId}`
      );
      return res.data;
    },
    onSuccess: () => {
      refetchFolders();
    },
  });

  return mutation;
};

export const useCreateSubFolder = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({
      folderId,
      name,
      channelIds,
    }: {
      folderId: string;
      name: string;
      channelIds: string[];
    }) => {
      const res = await axios.post(
        `employeechatfolder/${folderId}/subfolders`,
        {
          name,
          channelIds,
        }
      );
      return res.data;
    },
    onSuccess: () => {
      refetchFolders();
      onSuccess();
    },
  });

  return mutation;
};

export const useRenameSubFolder = () => {
  const mutation = useMutation({
    mutationFn: async ({
      folderId,
      subFolderId,
      newName,
    }: {
      folderId: string;
      subFolderId: string;
      newName: string;
    }) => {
      const res = await axios.post(
        `employeechatfolder/${folderId}/subfolders/${subFolderId}/rename`,
        {
          newName,
        }
      );
      return res.data;
    },
    onSuccess: () => {
      refetchFolders();
    },
  });

  return mutation;
};

export const useAddConversationToSubFolder = () => {
  const mutation = useMutation({
    mutationFn: async ({
      folderId,
      subFolderId,
      channelId,
      draggedFromFolderId,
      draggedFromSubFolderId,
    }: {
      folderId: string;
      subFolderId: string;
      channelId: string;
      draggedFromFolderId: string | null;
      draggedFromSubFolderId: string | null;
    }) => {
      let url = new URL(
        `${window.location.protocol}//${window.location.host}/api/employeechatfolder/${folderId}/subfolders/${subFolderId}/channels`
      );

      if (draggedFromFolderId)
        url.searchParams.append("draggedFromFolderId", draggedFromFolderId);

      if (draggedFromSubFolderId)
        url.searchParams.append(
          "draggedFromSubFolderId",
          draggedFromSubFolderId
        );

      const res = await axios.post(url.toString(), {
        channelId,
      });
      return res.data;
    },
    onSuccess: () => {
      refetchFolders();
    },
  });

  return mutation;
};

export const useRemoveConversationFromSubFolder = () => {
  const mutation = useMutation({
    mutationFn: async ({
      folderId,
      subFolderId,
      channelId,
    }: {
      folderId: string;
      subFolderId: string;
      channelId: string;
    }) => {
      const res = await axios.delete(
        `employeechatfolder/${folderId}/subfolders/${subFolderId}/channels/${channelId}`
      );
      return res.data;
    },
    onSuccess: () => {
      refetchFolders();
    },
  });

  return mutation;
};

export const useDeleteSubFolder = () => {
  const mutation = useMutation({
    mutationFn: async ({
      folderId,
      subFolderId,
    }: {
      folderId: string;
      subFolderId: string;
    }) => {
      const res = await axios.delete(
        `employeechatfolder/${folderId}/subfolders/${subFolderId}`
      );
      return res.data;
    },
    onSuccess: () => {
      refetchFolders();
    },
  });

  return mutation;
};

export const useDeleteFolder = () => {
  const mutation = useMutation({
    mutationFn: async ({ folderId }: { folderId: string }) => {
      const res = await axios.delete(`employeechatfolder/${folderId}`);
      return res.data;
    },
    onSuccess: () => {
      refetchFolders();
    },
  });

  return mutation;
};
