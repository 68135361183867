import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { trackPromise } from "react-promise-tracker";
import useStores from "../../useStores";
import OnboardingOverview from "./OnboardingOverview";
import Loader from "../../shared/Loader";
import areas from "../../utils/constants/areas";
import Header from "../../shared/Header";
import Loading from "../../shared/Loading";
import StyledRoot from "../../shared/StyledRootDiv";

const OnboardingPage = () => {
  const { onboardingsStore } = useStores();

  useEffect(() => {
    trackPromise(onboardingsStore.fetchDossiers(), areas.ONBOARDING_OVERVIEW);
  }, [onboardingsStore]);

  if (!onboardingsStore.dossiers) {
    return <Loading />;
  }

  return (
    <div style={{ position: "relative" }}>
      <Loader area={areas.ONBOARDING_OVERVIEW}>
        <div style={{ position: "absolute", top: 0, left: "30px" }}>
          <Header pageTitle="onboarding" />
        </div>

        <OnboardingOverview />
      </Loader>
    </div>
  );
};

export default observer(OnboardingPage);
