const NotFoundPage = () => {
  return (
    <div className="w-full min-h-[20vw] flex items-center justify-center">
      <div className="text-center">
        <h1 className="text-4xl font-bold">Not found</h1>
        <p className="pt-2 text-lg">
          Sorry! It looks like the page you are looking for does not exist.
        </p>
      </div>
    </div>
  );
};

export default NotFoundPage;
