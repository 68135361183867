import React from "react";
import { Grid } from "@mui/material";
import CreateDossierTransfer from "./CreateDossierTransfer";
import StyledRoot from "../../shared/StyledRootDiv";
import { useParams } from "react-router-dom";

const CreateDossierTransferPage = () => {
  const { patientId } = useParams();

  return (
    <StyledRoot>
      <Grid container justifyContent="center">
        <Grid item xs={6}>
          <CreateDossierTransfer patientId={patientId} />
        </Grid>
      </Grid>
    </StyledRoot>
  );
};

export default CreateDossierTransferPage;