import { useIsEnabled } from "../../../feature-management/useIsEnabled";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { Button } from "../../../components/ui/button";
import { Loader2, Plus } from "lucide-react";
import { Status } from "./status";
import { SavedGermanClient } from "./types";
import { useEffect, useState } from "react";
import { Sheet, SheetClose, SheetContent } from "../../../components/ui/sheet";
import { ClientDetails } from "./details";
import { CreateDialog } from "./details/createDialog";
import { useGetiPracticeGermanyClients } from "./hooks";
import { useDebounce } from "use-debounce";
import { Input } from "../../../components/ui/input";
import { toast } from "sonner";
import { useNavigate, useLocation } from "react-router-dom";
import { OngoingText } from "./details/fields/treatment";

export default function iPracticeGermanyClientsPage() {
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  const [selectedClientId, setSelectedClientId] = useState<string | null>(null);

  const navigate = useNavigate();
  const location = useLocation();

  const {
    data: isGermanClientManagementEnabled,
    isLoading: isLoadingIsEnabled,
  } = useIsEnabled("EnableGermanyClientManagement");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const clientId = queryParams.get("id");

    if (clientId) {
      handleRowClick(clientId);
    }
  }, []);

  const handleRowClick = (id: string) => {
    setSelectedClientId(id);
    setIsSheetOpen(true);

    navigate({
      search: `?id=${id}`,
    });
  };

  const onCloseSheet = () => {
    // we remove the ?id=... searchParam when the Sheet closes
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete("id");

    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });

    setIsSheetOpen(false);
    setSelectedClientId(null);
  };

  const { data, isLoading } =
    useGetiPracticeGermanyClients(debouncedSearchTerm);

  if (isLoadingIsEnabled || !isGermanClientManagementEnabled) return <></>;

  const renderTableBody = () => {
    if (isLoading) {
      return (
        <TableRow>
          <TableCell colSpan={7}>
            <div className="p-4 flex items-center space-x-2">
              <Loader2 className="w-4 h-4 animate-spin" />
              <p>Loading...</p>
            </div>
          </TableCell>
        </TableRow>
      );
    }

    if (!data || data.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={7}>
            <p>No clients found.</p>
          </TableCell>
        </TableRow>
      );
    }

    return data!.map((d) => {
      return (
        <TableRow
          key={d.id}
          className="cursor-pointer"
          onClick={() => handleRowClick(d.id)}
        >
          <TableCell>{d.name}</TableCell>
          <TableCell>
            <Status client={d} />
          </TableCell>
          <TableCell>{d.email}</TableCell>
          <TableCell>{d.phone}</TableCell>
          <TableCell>
            {d.isOngoingSessions ? OngoingText : d.numberOfSessions}
          </TableCell>
          <TableCell>€ {d.price}</TableCell>
          <TableCell
            className="min-w-[125px] font-medium text-link cursor-pointer hover:underline"
            onClick={(e: any) => {
              e.stopPropagation();
              navigator.clipboard.writeText(d.iAgreeLink);

              toast("Link copied", {
                description: `Successfully copied ${d.name}'s iAgree link to you clipboard.`,
              });
            }}
          >
            Kopieren
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <main className="p-24">
      <div>
        <h1 className="text-4xl font-bold">iPractice Deutschland Kunden</h1>
        <h2 className="text-lg">
          Verwalten Sie Ihre Kunden und senden Sie iAgree-Dokumente.
        </h2>
      </div>
      <section className="mt-6 w-full flex items-start justify-between">
        <Input
          placeholder="Nach Kunden suchen..."
          className="w-1/2"
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
        />

        <Button
          className="px-6 flex items-center space-x-2"
          onClick={() => setIsCreateDialogOpen(true)}
        >
          <div className="w-4 h-4">
            <Plus className="w-4 h-4" />
          </div>
          <p>Neuen Kunden erstellen</p>
        </Button>
      </section>

      <Sheet
        open={isSheetOpen}
        onOpenChange={(newValue) => {
          if (newValue) {
            setIsSheetOpen(true);
          } else {
            onCloseSheet();
          }
        }}
      >
        <Table className="border mt-6">
          <TableHeader>
            <TableRow>
              <TableHead className="w-1/6">Name</TableHead>
              <TableHead className="w-1/6">Status</TableHead>
              <TableHead className="w-1/6">Email</TableHead>
              <TableHead className="w-1/6">Telefon</TableHead>
              <TableHead className="w-1/6">Anzahl der Sitzungen</TableHead>
              <TableHead className="w-1/6">Preis</TableHead>
              <TableHead className="w-[150px]">iAgree Link</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>{renderTableBody()}</TableBody>
        </Table>

        <SheetContent
          side={"right"}
          onInteractOutside={() => onCloseSheet()}
          className="min-w-[100vw] xl:min-w-[70vw] overflow-y-auto overflow-x-hidden p-8 flex items-start justify-center bg-gradient-to-tl from-gray-100 to-white dark:from-slate-950 dark:to-slate-900"
        >
          {selectedClientId && (
            <div className="w-full p-12">
              <ClientDetails clientId={selectedClientId} />
            </div>
          )}
        </SheetContent>
        <SheetClose onClick={() => onCloseSheet()} />
      </Sheet>

      <CreateDialog
        isOpen={isCreateDialogOpen}
        setIsOpen={setIsCreateDialogOpen}
        onSave={(savedClient) => {
          toast("Client created", {
            description: `Successfully created the client.`,
          });

          setIsCreateDialogOpen(false);
          handleRowClick(savedClient.id);
        }}
      />
    </main>
  );
}
