import { AccountInfo, InteractionRequiredAuthError } from "@azure/msal-browser";
import { msalInstance } from "../index";

export class AuthStore {
  activeAccount: AccountInfo | null = null;
  roles: string[] = [];
  isAuthenticated: boolean = false;

  constructor() {
  }

  async initialize() {
    await this.initializeAuth();
  }

  private async initializeAuth() {
    // Set active account if available
    const currentAccount = msalInstance.getActiveAccount();
    if (currentAccount) {
      this.setActiveAccount(currentAccount);
    } else if (msalInstance.getAllAccounts().length > 0) {
      this.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }

    // Listen for account changes
    msalInstance.addEventCallback((event) => {
      if (event.eventType === "msal:loginSuccess") {
        const account = event.payload as AccountInfo;
        this.setActiveAccount(account);
      }
    });
  }

  setActiveAccount(account: AccountInfo | null) {
    this.activeAccount = account;
    this.isAuthenticated = !!account;
    this.roles = account?.idTokenClaims?.roles || [];
    
    if (account) {
      msalInstance.setActiveAccount(account);
    }
  }

  async getAccessToken(): Promise<string> {
    if (!this.activeAccount) {
      throw new Error("No active account");
    }

    try {
      const response = await msalInstance.acquireTokenSilent({
        scopes: ["https://ipracticeacceptance.onmicrosoft.com/ipracticeapi/read"],
        account: this.activeAccount
      });
      return response.accessToken;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        const response = await msalInstance.acquireTokenPopup({
          scopes: ["https://ipracticeacceptance.onmicrosoft.com/ipracticeapi/read"],
          account: this.activeAccount
        });
        return response.accessToken;
      }
      throw error;
    }
  }
}

export const authStore = new AuthStore();
export default authStore;