import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import { HealthCareInsurerColName } from "./referralOverview/columns/distinctCols/healthcareInsurerCol";
import { StatusColName } from "./referralOverview/columns/distinctCols/statusCol";
import { NextStepColName } from "./referralOverview/columns/distinctCols/nextStepCol";
import { LocationColName } from "./referralOverview/columns/distinctCols/locationCol";
import {
  HealthcareInsurerContractFilter,
  TransferReferralFilter,
} from "./types";

export const getDefaultColumnVisibilityFromLocalStorage = () => {
  const fromLocalStorage = localStorage.getItem("crm-column-visibility");
  const result = !fromLocalStorage || fromLocalStorage == "undefined"
    ? { ReferralStatus: false } // possibility to hide a couple of columns by default
    : JSON.parse(fromLocalStorage);
  return result;
};

export const getDefaultPageSize = () => {
  const fromLocalStorage = localStorage.getItem("crm-page-size");
  return !fromLocalStorage || fromLocalStorage == "undefined"
    ? 10
    : Number(fromLocalStorage);
};

export const getDefaultColumnSizingFromLocalStorage = () => {
  const fromLocalStorage = localStorage.getItem("crm-column-sizing");

  if (!fromLocalStorage || fromLocalStorage == "undefined") {
    return {};
  }

  return JSON.parse(fromLocalStorage);
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const getDefaultHealthcareInsurerFilters = () => {
  const data = getSavedFilterById(HealthCareInsurerColName);
  if (data.length === 0) return [];
  return data[0].value;
};

export const getDefaultRegistrationStatusFilters = () => {
  const data = getSavedFilterById(StatusColName);
  if (data.length === 0) return [];
  return data[0].value;
};

export const getDefaultNextStepsFilters = () => {
  const data = getSavedFilterById(NextStepColName);
  if (data.length === 0) return [];
  return data[0].value;
};

export const getDefaultLocationFilters = () => {
  const data = getSavedFilterById(LocationColName);
  if (data.length === 0) return [];
  return data[0].value;
};

export const getDefaultTransferReferralFilter = (): TransferReferralFilter => {
  const data = getSavedFilterById("TransferReferralFilter");

  if (data.length === 0 || data[0].value.length == 0)
    return TransferReferralFilter.All;

  return data[0].value[0] as TransferReferralFilter;
};

export const getDefaultIsContractedInsurerFilter =
  (): HealthcareInsurerContractFilter => {
    const data = getSavedFilterById("IsContractedInsurer");

    if (data.length === 0 || data[0].value.length == 0)
      return HealthcareInsurerContractFilter.All;

    return data[0].value[0] as HealthcareInsurerContractFilter;
  };

const getSavedFilterById = (id: string) => {
  return JSON.parse(localStorage.getItem("crm-filters") ?? "[]").filter(
    (x: { id: string; value: string[] }) => x.id === id
  );
};
