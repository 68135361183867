import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { EmptySpotsAndTrajectoriesResult, WeekResult } from "../types";
import { AggregatedTable } from "./aggregatedTable";
import { FlatTable } from "./flatTable";

export const EmptySlotsTable = ({
  isLoading,
  data,
  showEachPsychologist,
  locations,
  psychologist,
}: {
  isLoading: boolean;
  data: EmptySpotsAndTrajectoriesResult;
  showEachPsychologist: boolean;
  locations: string[];
  psychologist: string;
}) => {
  const getWeekLabel = (start: string, end: string) => {
    const parsedStart = new Date(start);
    const parsedEnd = new Date(end);

    const startDay = parsedStart.getDate();
    const endDay = parsedEnd.getDate();

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthName = monthNames[parsedEnd.getMonth()];

    return `${startDay} - ${endDay} ${monthName}`;
  };

  const renderColumn = (
    label: string,
    data: WeekResult | null,
    type: "empty-spot" | "empty-intake-spot"
  ) => {
    return (
      <TableHead className="min-w-[180px]">
        <div>
          {data ? (
            <p className="text-xs font-normal">
              {label} ({getWeekLabel(data.startOfWeek, data.endOfWeek)})
            </p>
          ) : (
            <></>
          )}
          <p>
            {type == "empty-spot"
              ? "Empty spot in Agenda"
              : "Empty intake spot"}
          </p>
        </div>
      </TableHead>
    );
  };

  const renderTableBody = () => {
    if (isLoading) {
      return (
        <TableRow>
          <TableCell colSpan={12}>Loading...</TableCell>
        </TableRow>
      );
    }

    if (showEachPsychologist) {
      return (
        <FlatTable
          data={data}
          locations={locations}
          psychologist={psychologist}
        />
      );
    }

    return <AggregatedTable data={data} locations={locations} />;
  };

  return (
    <div className="overflow-x-auto">
      <Table className="mt-8 border min-w-[1200px] table-auto">
        <TableHeader className="border-b">
          <TableRow>
            <TableHead className="min-w-[250px] sticky left-0 z-10 border-r">
              City
            </TableHead>
            {showEachPsychologist ? (
              <TableHead className="min-w-[250px] sticky left-[250px] z-10 border-r">
                Psychologist
              </TableHead>
            ) : (
              <></>
            )}
            {renderColumn("Period 1", data?.period1[0], "empty-spot")}
            {renderColumn("Period 1", data?.period1[0], "empty-intake-spot")}
            {renderColumn("Period 2", data?.period2[0], "empty-spot")}
            {renderColumn("Period 2", data?.period2[0], "empty-intake-spot")}
            {renderColumn("Period 3", data?.period3[0], "empty-spot")}
            {renderColumn("Period 3", data?.period3[0], "empty-intake-spot")}
            {renderColumn("Period 4", data?.period4[0], "empty-spot")}
            {renderColumn("Period 4", data?.period4[0], "empty-intake-spot")}
            {renderColumn("Period 5", data?.period5[0], "empty-spot")}
            {renderColumn("Period 5", data?.period5[0], "empty-intake-spot")}
            <TableHead className="min-w-[220px] bg-slate-100 hover:bg-slate-200 border-l">
              Total empty spots
            </TableHead>
            <TableHead className="min-w-[220px] bg-slate-100 hover:bg-slate-200">
              Total empty intake spots
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>{renderTableBody()}</TableBody>
      </Table>
    </div>
  );
};
