import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import {
  Card,
  CardContent,
  CardActions,
  TextField,
  Button,
  IconButton,
  Divider,
  MenuItem,
  Modal,
  CardHeader,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { Formik } from "formik";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as Yup from "yup";
import useStores from "../../useStores";
import ConfirmDialog from "../../shared/ConfirmDialog";
import subscriptionTypes from "../../utils/constants/subscriptionTypes";

const StyledCardRoot = styled(Card)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  outline: "none",
  boxShadow: theme.shadows[20],
  width: 700,
  maxHeight: "100%",
  overflowY: "auto",
  maxWidth: "100%",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const StyledCardActions = styled(CardActions)(() => ({
  justifyContent: "flex-end",
}));

const SubscriptionAddEditModal = ({
  subscription,
  isInAddMode,
  isInEditMode,
  onClose,
}) => {
  const { subscriptionStore, employeesStore, patientStore } = useStores();
  const [confirmDeleteIsOpen, setConfirmDeleteIsOpen] = useState(false);

  useEffect(() => {
    employeesStore.fetchEmployees();
  }, [employeesStore]);

  const handleDelete = () => {
    setConfirmDeleteIsOpen(false);
    subscriptionStore.deleteSubscription(subscription);
    onClose();
  };

  return (
    <Modal onClose={onClose} open={isInAddMode || isInEditMode}>
      <StyledCardRoot>
        <Formik
          initialValues={
            isInAddMode
              ? {
                  dossierId: patientStore.dossier.id,
                  type: "",
                  employeeId: "",
                  startedAt: new Date(),
                  finishedAt: null,
                }
              : subscription
          }
          onSubmit={(values) => {
            if (isInAddMode) {
              subscriptionStore.createSubscription(values);
            } else {
              subscriptionStore.editSubscription(values);
            }
            onClose();
          }}
          validationSchema={Yup.object().shape({
            type: Yup.string().required(),
            employeeId: Yup.string().required(),
            startedAt: Yup.date().required(),
            finishedAt: Yup.date().nullable().min(Yup.ref("startedAt")),
          })}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              dirty,
              setFieldValue,
            } = props;
            return (
              <form autoComplete="off" noValidate>
                <CardHeader
                  title={isInAddMode ? "Nieuw abonnement" : "Wijzig abonnement"}
                />
                <Divider />
                <CardContent>
                  <StyledTextField
                    required
                    select
                    fullWidth
                    name="type"
                    label="Type"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.type}
                    variant="outlined"
                    error={errors.type && touched.type}
                    helperText={errors.type && touched.type && errors.type}
                  >
                    {Object.values(subscriptionTypes).map(
                      (subscriptionType) => (
                        <MenuItem
                          key={subscriptionType.key}
                          value={subscriptionType.key}
                        >
                          {subscriptionType.label}
                        </MenuItem>
                      )
                    )}
                  </StyledTextField>
                  <StyledTextField
                    required
                    select
                    fullWidth
                    name="employeeId"
                    label="Behandelaar"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.employeeId}
                    variant="outlined"
                    error={errors.employeeId && touched.employeeId}
                    helperText={
                      errors.employeeId &&
                      touched.employeeId &&
                      errors.employeeId
                    }
                  >
                    {employeesStore.employees.map((employee) => (
                      <MenuItem
                        key={employee.id}
                        value={employee.id}
                      >{`${employee.firstName} ${employee.lastName}`}</MenuItem>
                    ))}
                  </StyledTextField>
                  <StyledDatePicker
                    required
                    format="DD/MM/YYYY"
                    inputVariant="outlined"
                    fullWidth
                    name="startedAt"
                    label="Start"
                    onChange={(moment) => {
                      setFieldValue("startedAt", moment.toDate());
                    }}
                    onBlur={handleBlur}
                    value={values.startedAt}
                    error={errors.startedAt && touched.startedAt}
                    helperText={
                      errors.startedAt && touched.startedAt && errors.startedAt
                    }
                  />
                  {values.finishedAt != null && (
                    <StyledDatePicker
                      format="DD/MM/YYYY"
                      inputVariant="outlined"
                      fullWidth
                      name="finishedAt"
                      label="Afgerond"
                      onChange={(moment) => {
                        setFieldValue("finishedAt", moment.toDate());
                      }}
                      onBlur={handleBlur}
                      value={values.finishedAt}
                      error={errors.finishedAt && touched.finishedAt}
                      helperText={
                        errors.finishedAt &&
                        touched.finishedAt &&
                        errors.finishedAt
                      }
                    />
                  )}
                </CardContent>
                <Divider />
                <StyledCardActions>
                  {isInEditMode && (
                    <IconButton
                      edge="start"
                      onClick={() => setConfirmDeleteIsOpen(true)}
                      size="large"
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                  <ConfirmDialog
                    question="Weet je zeker dat je het abonnement wilt verwijderen?"
                    isOpen={confirmDeleteIsOpen}
                    onConfirm={handleDelete}
                    onCancel={() => setConfirmDeleteIsOpen(false)}
                  />
                  <Button onClick={onClose} disabled={isSubmitting}>
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    onClick={handleSubmit}
                    variant="contained"
                    disabled={isSubmitting || !dirty}
                  >
                    Opslaan
                  </Button>
                </StyledCardActions>
              </form>
            );
          }}
        </Formik>
      </StyledCardRoot>
    </Modal>
  );
};

export default SubscriptionAddEditModal;
