import { useContext } from "react";
import { NavBarOpenStateContext } from "./NavBarOpenStateContext";

export const NavBarSeparator = () => {
  const { isNavBarOpen } = useContext(NavBarOpenStateContext)!;

  if (isNavBarOpen)
    return <hr className="h-px dark:border-slate-800 border-blue-200 my-4" />;

  return (
    <hr className="h-px dark:border-slate-800 border-blue-200 my-2 -ml-12" />
  );
};
