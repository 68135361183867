import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../components/ui/popover";
import { Button } from "../../../../components/ui/button";
import { useState } from "react";
import { getSimpleFormattedDateTime } from "../../../../utils/dateUtils";
import axios from "axios";
import { useQuery } from "react-query";
import { getSimpleFormattedDate } from "../../../../utils/dateUtils";
import { CalendarIcon } from "lucide-react";
import { Calendar } from "../../../../components/ui/calendar";
import moment from "moment";

export type UpcomingAppointmentReminderData = {
  startTime: string;
  epdPatientId: number;
  epdFileId: number;
  patientName: string;
  psychologistName: string;
};

export default function UpcomingReminders() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isDateSelectorOpen, setIsDateSelectorOpen] = useState(false);

  const { data, isLoading } = useQuery<{
    preIntakes: UpcomingAppointmentReminderData[];
    intakes: UpcomingAppointmentReminderData[];
  }>(
    ["crm-admin", "upcoming-reminders", selectedDate],
    async () => {
      const res = await axios.get(
        `customerrelationshipmanageradmin/upcoming-appointment-reminders?currentTime=${selectedDate.toISOString()}`
      );
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  const renderTableBody = () => {
    if (isLoading || !data) {
      return (
        <TableRow>
          <TableCell colSpan={5}>Loading data...</TableCell>
        </TableRow>
      );
    }

    if (data.intakes.length === 0 && data.preIntakes.length === 0) {
      return (
        <TableRow>
          <TableCell colSpan={5}>No upcoming reminders</TableCell>
        </TableRow>
      );
    }

    return [
      ...data!.preIntakes.map((x) => ({ type: "Pre-Intake", ...x })),
      ...data!.intakes.map((x) => ({ type: "Intake", ...x })),
    ]
      .toSorted((a, b) =>
        new Date(a.startTime) > new Date(b.startTime) ? 1 : -1
      )
      .map((reminder, index) => {
        return (
          <TableRow key={index}>
            <TableCell>{reminder.type}</TableCell>
            <TableCell>
              {getSimpleFormattedDateTime(reminder.startTime)}
            </TableCell>
            <TableCell>
              {reminder.epdPatientId}.{reminder.epdFileId}
            </TableCell>
            <TableCell>{reminder.patientName}</TableCell>
            <TableCell>{reminder.psychologistName}</TableCell>
          </TableRow>
        );
      });
  };

  return (
    <div className="p-24">
      <h1 className="text-4xl font-bold">
        Upcoming Appointment Reminders from CRM
      </h1>
      <p className="pt-1">
        View the upcoming appointment reminders the CRM is about to send in the
        following days.
      </p>

      <section className="mt-6">
        <p className="font-bold">Date</p>
        <p className="text-xs">
          Select a date to see which reminders will be sent on that day.
        </p>

        <Popover open={isDateSelectorOpen} onOpenChange={setIsDateSelectorOpen}>
          <PopoverTrigger asChild>
            <Button
              className="mt-2 px-4 flex space-x-2 justify-between"
              variant="outline"
            >
              <p>{getSimpleFormattedDate(selectedDate)}</p>
              <div className="h-4 w-4">
                <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
              </div>
            </Button>
          </PopoverTrigger>
          <PopoverContent className="z-[999999]" align="start">
            <Calendar
              mode="single"
              fromDate={new Date()}
              toDate={undefined}
              selected={selectedDate}
              onSelect={(_, d) => {
                setSelectedDate(moment(d).utc(true).toDate());
                setIsDateSelectorOpen(false);
              }}
              initialFocus
              disabled={(d) => {
                // none in past
                if (d < new Date()) return true;

                // weekends are NOT to select
                if (d.getDay() == 6 || d.getDay() == 0) return true;

                // rest is enabled
                return false;
              }}
            />
          </PopoverContent>
        </Popover>
      </section>

      <div className="overflow-x-auto mt-8">
        <Table className="border min-w-[1200px] table-auto">
          <TableHeader className="border-b">
            <TableRow>
              <TableHead>Reminder Type</TableHead>
              <TableHead>Appointment On</TableHead>
              <TableHead>Dossier</TableHead>
              <TableHead>Patient</TableHead>
              <TableHead>Psychologist</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>{renderTableBody()}</TableBody>
        </Table>
      </div>
    </div>
  );
}
