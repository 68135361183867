import React from 'react';
import {
 Button, Dialog, DialogActions, DialogTitle
} from '@mui/material';

const ConfirmDialog = ({
 question, isOpen, onConfirm, onCancel
}) => {
    return (
      <Dialog
        open={isOpen}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">{question}</DialogTitle>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            Terug
          </Button>
          <Button onClick={onConfirm} color="primary" autoFocus>
            Ja
          </Button>
        </DialogActions>
      </Dialog>
    );
};

export default ConfirmDialog;
