import { Row } from "../../types";
import { TableCell } from "../../../../../components/ui/table";
import { Textarea } from "../../../../../components/ui/textarea";
import { Button } from "../../../../../components/ui/button";
import { useAddIntakeDeviationComment } from "../../hooks";
import { Loader2, Save, Undo } from "lucide-react";
import { useState } from "react";

type CommentsCellProps = {
  data: Row;
};

export const CommentsCell = ({ data }: CommentsCellProps) => {
  const [modifiedComment, setModifiedComment] = useState<string | undefined>(
    data.comments
  );
  let hasChanged = (data.comments ?? "") !== (modifiedComment ?? "");

  const { mutate: addComment, isLoading: isAddingComment } =
    useAddIntakeDeviationComment();

  return (
    <TableCell>
      <Textarea
        value={modifiedComment ?? ""}
        onChange={(e) => setModifiedComment(e.target.value)}
        disabled={isAddingComment}
      />
      {hasChanged && (
        <div className="flex gap-2">
          <Button
            className="py-0 w-full flex items-center gap-2 text-sm h-8 mt-2"
            disabled={!hasChanged}
            onClick={async () => {
              await addComment({
                epdEmployeeId: data.epdEmployeeId,
                comment: modifiedComment ?? "",
              });
            }}
          >
            {isAddingComment ? (
              <>
                <Loader2 className="w-4 h-4 animate-spin" />
                <p>Saving...</p>
              </>
            ) : (
              <>
                <Save className="w-4 h-4" />
                <p>Save</p>
              </>
            )}
          </Button>
          <Button
            variant="outline"
            className="py-0 w-full flex items-center gap-2 text-sm h-8 mt-2"
            disabled={!hasChanged}
            onClick={() => setModifiedComment(data.comments)}
          >
            <Undo className="w-4 h-4" />
            <p>Undo</p>
          </Button>
        </div>
      )}
    </TableCell>
  );
};
