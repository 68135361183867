import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { ChatConversationDetails, UserToAddToChannel } from "./types";
import { queryClient } from "../../react-query/provider";

const refetchChatQueries = () => {
  const queryKeys = [
    ["chat", "chat-details"],
    ["chat", "users-to-add-to-chat"],
    ["my-chat", "details"],
  ];

  queryKeys.map((qk) => {
    queryClient.invalidateQueries({
      queryKey: qk,
    });
    queryClient.refetchQueries({
      queryKey: qk,
    });
  });
};

export const useGetChatCredentials = (isEnabled: boolean) => {
  const query = useQuery<{ userId: string; userName: string; token: string }>(
    ["chat", "chat-credentials"],
    async () => {
      const res = await axios.get("EmployeeChat/credentials");
      return res.data;
    },
    {
      enabled: isEnabled,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useGetChatDetails = (
  isEnabled: boolean,
  dossierLookupIds: string[]
) => {
  const query = useQuery<ChatConversationDetails[]>(
    ["chat", "chat-details", ...dossierLookupIds],
    async () => {
      let url = new URL(
        `${window.location.protocol}/${window.location.host}/api/EmployeeChat/chat-details`
      );

      dossierLookupIds.forEach((id) => {
        url.searchParams.append("dossierLookupIds", id);
      });

      const res = await axios.get(url.toString());
      return res.data;
    },
    {
      enabled: dossierLookupIds.length > 0 && isEnabled,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useGetUsersToAddToChannel = (channelId: string | null) => {
  const query = useQuery<UserToAddToChannel[]>(
    ["chat", "users-to-add-to-chat", channelId],
    async () => {
      const res = await axios.get(
        `EmployeeChat/channels/${channelId}/users-to-add`
      );
      return res.data;
    },
    {
      enabled: channelId != null,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useAddUserToChat = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({
      channelId,
      email,
      userName,
    }: {
      channelId: string;
      email: string;
      userName: string;
    }) => {
      const res = await axios.post(
        `EmployeeChat/channels/${channelId}/add-psychologist`,
        {
          email,
          userName,
        }
      );
      return res.data;
    },
    onSuccess: () => {
      onSuccess();
      refetchChatQueries();
    },
  });

  return mutation;
};

export const useRemoveUserFromChat = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({
      channelId,
      userId,
    }: {
      channelId: string;
      userId: string;
    }) => {
      const res = await axios.delete(
        `EmployeeChat/channels/${channelId}/remove-user/${userId}`
      );
      return res.data;
    },
    onSuccess: () => {
      onSuccess();
      refetchChatQueries();
    },
  });

  return mutation;
};
