import { ColumnDef } from "@tanstack/react-table";
import { ReferredPatient } from "../../../types";
import { useTranslation } from "react-i18next";
import { Cake, Copy, Hourglass } from "lucide-react";
import { getSimpleFormattedDate } from "../../../../../utils/dateUtils";

export const PatientColName = "Patient";

export const PatientCol: ColumnDef<ReferredPatient> = {
  id: PatientColName,
  accessorKey: "name",
  size: 175,
  minSize: 100,
  header: () => {
    return <p>Patient</p>;
  },
  cell: ({ row }) => {
    const userName = row.original.name;
    const dateOfBirth = row.original.dateOfBirth;

    const isOnInsurerWaitingList =
      row.original.healthCareInsurer?.concern == "VGZ" ||
      row.original.healthCareInsurer?.concern == "MENZIS";

    return (
      <div>
        <p className="font-bold">{userName}</p>
        {dateOfBirth && (
          <div className="flex items-center space-x-2">
            <Cake className="w-4 h-4" />
            <p className="text-xs">{getSimpleFormattedDate(dateOfBirth)}</p>
          </div>
        )}

        {isOnInsurerWaitingList ? (
          <div className="inline-flex items-center space-x-2 px-2 py-1 font-medium text-red-800 dark:text-red-300 bg-red-100 dark:bg-red-900 rounded text-xs mt-2">
            <div className="h-4 w-4">
              <Hourglass className="h-4 w-4" />
            </div>
            <p className="text-xs">Intake plafond</p>
          </div>
        ) : (
          <></>
        )}

        {row.original.isDuplicate ? (
          <div className="inline-flex items-center space-x-2 px-2 py-1 font-medium text-amber-800 dark:text-amber-300 bg-amber-100 dark:bg-amber-900 rounded text-xs mt-2">
            <div className="h-4 w-4">
              <Copy className="h-4 w-4" />
            </div>
            <p className="text-xs">Possibly duplicate</p>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  },
};
