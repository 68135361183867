import { ColumnDef } from "@tanstack/react-table";
import { ReferredPatient } from "../../../types";
import { useTranslation } from "react-i18next";
import { CalendlyStatus } from "../../../referralDetails/calendly/calendlyStatus";

export const CalendlyInviteColName = "Calendly Invite Sent";

export const CalendlyInviteCol: ColumnDef<ReferredPatient> = {
  id: CalendlyInviteColName,
  accessorKey: "calendlyInviteSent",
  size: 175,
  minSize: 100,
  header: ({ column }) => {
    const { t } = useTranslation();

    return <p>Calendly Status</p>;
  },
  cell: ({ row }) => {
    return (
      <div className="flex items-center gap-2">
        <CalendlyStatus calendlyInvite={row.original.calendlyInvite} />
      </div>
    );
  },
};
