import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../../../components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../components/ui/popover";
import { Button } from "../../../components/ui/button";
import { Skeleton } from "../../../components/ui/skeleton";
import { ChevronsUpDown } from "lucide-react";
import { useQuery } from "react-query";
import axios from "axios";
import { useState } from "react";

export const PsychologistFilter = ({
  selectedPsy,
  setSelectedPsy,
  inline,
  allowClear,
}: {
  selectedPsy: string;
  setSelectedPsy: (newPsy: string) => void;
  inline?: boolean;
  allowClear?: boolean;
}) => {
  const [filterOpen, setFilterOpen] = useState(false);

  const { data: employees, isLoading: isLoadingEmployees } = useQuery<
    { epdId: number; name: string }[]
  >(
    ["calendar-admin", "epd-employees-active"],
    async () => {
      const res = await axios.get(`epdemployees/active`);
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <section
      className={`mt-4 ${inline ? "flex items-center space-x-2" : "block"}`}
    >
      <p className="font-bold pb-1">Psychologist:</p>
      {isLoadingEmployees ? (
        <Skeleton className="h-10 w-1/4 rounded" />
      ) : (
        <Popover open={filterOpen} onOpenChange={setFilterOpen}>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              role="combobox"
              className={`font-normal flex items-center justify-between space-x-2 ${
                inline ? "w-full" : "w-1/4"
              }`}
            >
              {selectedPsy
                ? employees!.find((x) => x.epdId.toString() === selectedPsy)
                    ?.name
                : "Select a psychologist..."}
              <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent
            className="w-[500px] p-0 max-h-[50vh] overflow-y-auto"
            side="bottom"
            align="start"
          >
            <Command
              value={selectedPsy}
              filter={(value, search) => {
                // value contains each entry in the dropdown list -> we find the full model of it:
                const currentPsy = employees!.filter(
                  (p) => p.epdId.toString() === value
                );

                if (currentPsy.length === 0) return 0;

                return currentPsy[0].name
                  .toLowerCase()
                  .includes(search.toLowerCase())
                  ? 1
                  : 0;
              }}
            >
              <CommandInput placeholder="Search for a psychologist..." />
              <CommandEmpty>No psychologists found.</CommandEmpty>

              <CommandGroup>
                {allowClear && (
                  <CommandItem
                    value={"full-team"}
                    onSelect={(x) => {
                      setSelectedPsy("full-team");
                      setFilterOpen(false);
                    }}
                    className="cursor-pointer italic"
                  >
                    Full team
                  </CommandItem>
                )}
                {employees
                  ?.toSorted((a, b) => (a.name < b.name ? -1 : 1))
                  ?.map((employee) => {
                    return (
                      <CommandItem
                        key={employee.epdId}
                        value={employee.epdId.toString()}
                        onSelect={(x) => {
                          setSelectedPsy(x);
                          setFilterOpen(false);
                        }}
                        className="cursor-pointer"
                      >
                        {employee.name}
                      </CommandItem>
                    );
                  })}
              </CommandGroup>
            </Command>
          </PopoverContent>
        </Popover>
      )}
    </section>
  );
};
