import { Input } from '../../../../../components/ui/input';

type EpdPatientIdFilterProps = {
  value: number | null;
  onChange: (value: number | null) => void;
};

export const EpdPatientIdFilter = ({ value, onChange }: EpdPatientIdFilterProps) => {
  return (
    <div className="w-1/4">
      <p className="font-medium">Client's number</p>
      <Input
        placeholder="Specify the client's number..."
        type="number"
        value={value ?? ''}
        onChange={(e) => onChange(e.target.value == '' ? null : parseInt(e.target.value))}
      />
    </div>
  );
};
