import { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { Button } from "../../../../components/ui/button";
import { CalendarIcon, Loader2 } from "lucide-react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "../../../../components/ui/popover";
import { Calendar } from "../../../../components/ui/calendar";
import {} from "../utils";
import { getSimpleFormattedDate } from "../../../../utils/dateUtils";
import { useTranslation } from "react-i18next";
import { useSaveAbsence } from "../hooks";
import { toast } from "sonner";

export const Absence = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { t } = useTranslation();

  const { mutate, isLoading } = useSaveAbsence(() => {
    setStartDate(getDefaultStartDate());
    setEndDate(getDefaultEndDate());
    setIsDialogOpen(false);
    toast(t("case-load-manager-absence-dialog-save-feedback-title"), {
      description: t("case-load-manager-absence-dialog-save-feedback-desc"),
    });
  });

  const getDefaultStartDate = () => {
    const today = new Date();
    const dayOfWeek = today.getDay(); // Sunday: 0, Monday: 1, etc
    const diffToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek; // today is Sunday --> we go backwards to last week's Monday
    const lastMonday = new Date(today);
    lastMonday.setDate(today.getDate() + diffToMonday);
    return lastMonday;
  };

  const getLastSunday = () => {
    const today = new Date();
    const dayOfWeek = today.getDay(); // Sunday: 0, Monday: 1, etc
    const diffToLastSunday = dayOfWeek === 0 ? 0 : -dayOfWeek; // today is Sunday -> OK to keep
    const lastSunday = new Date(today);
    lastSunday.setDate(today.getDate() + diffToLastSunday);
    return lastSunday;
  };

  const getDefaultEndDate = () => {
    return getNextEndDate(getDefaultStartDate());
  };

  const getNextEndDate = (start: Date) => {
    const endDate = new Date(start);
    endDate.setDate(endDate.getDate() + 4);

    return endDate;
  };

  const [startDate, setStartDate] = useState(getDefaultStartDate());
  const [endDate, setEndDate] = useState(getNextEndDate(getDefaultStartDate()));

  useEffect(() => {
    // make sure start is always earlier than end (if not, move end to start date's next Friday)
    if (startDate > endDate) {
      setEndDate(getNextEndDate(startDate));
    }
  }, [startDate]);

  return (
    <>
      <p className="text-xs">
        {t("case-load-manager-absence-call-to-action-text-1")}
        <span
          className="text-link cursor-pointer hover:underline font-bold"
          onClick={() => setIsDialogOpen(true)}
        >
          {t("case-load-manager-absence-call-to-action-link")}
        </span>
        {t("case-load-manager-absence-call-to-action-text-2")}
      </p>
      <Dialog
        open={isDialogOpen}
        onOpenChange={(newVal) => {
          setIsDialogOpen(newVal);
          setStartDate(getDefaultStartDate());
          setEndDate(getDefaultEndDate());
        }}
      >
        <DialogContent className="min-w-[20vw]">
          <DialogHeader>
            <DialogTitle>
              {t("case-load-manager-absence-dialog-title")}
            </DialogTitle>
            <DialogDescription>
              {t("case-load-manager-absence-dialog-desc")}
            </DialogDescription>
          </DialogHeader>
          <div className="flex items-center justify-between space-x-4">
            <div className="space-y-2 w-1/2">
              <p className="font-bold">
                {t("case-load-manager-absence-dialog-start-label")}
              </p>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant={"outline"}
                    className="w-full text-left font-normal"
                  >
                    <span>{getSimpleFormattedDate(startDate)}</span>
                    <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="z-[999999] min-w-fit" align="start">
                  <Calendar
                    mode="single"
                    showWeekNumber
                    fromDate={getLastSunday()}
                    toDate={undefined}
                    selected={startDate}
                    defaultMonth={startDate}
                    onSelect={(_, d) => {
                      setStartDate(d);
                    }}
                    initialFocus
                    disabled={(d) => {
                      // none in past
                      // if (d < new Date()) return true;

                      // Mondays are OK to select
                      if (d.getDay() == 1) return false;

                      // rest is disabled
                      return true;
                    }}
                  />
                </PopoverContent>
              </Popover>
            </div>
            <div className="space-y-2 w-1/2">
              <p className="font-bold">
                {t("case-load-manager-absence-dialog-end-label")}
              </p>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant={"outline"}
                    className="w-full pl-3 text-left font-normal"
                  >
                    <span>{getSimpleFormattedDate(endDate)}</span>
                    <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="z-[999999] min-w-fit" align="start">
                  <Calendar
                    mode="single"
                    showWeekNumber
                    fromDate={startDate}
                    toDate={undefined}
                    selected={endDate}
                    defaultMonth={endDate}
                    onSelect={(_, d) => {
                      setEndDate(d);
                    }}
                    initialFocus
                    disabled={(d) => {
                      // none in past
                      if (d < new Date()) return true;

                      // Fridays are OK to select
                      if (d.getDay() == 5) return false;

                      // rest is disabled
                      return true;
                    }}
                  />
                </PopoverContent>
              </Popover>
            </div>
          </div>

          <DialogFooter className="gap-2">
            <Button
              variant="outline"
              onClick={() => {
                setStartDate(getDefaultStartDate());
                setEndDate(getDefaultEndDate());
                setIsDialogOpen(false);
              }}
              className="w-1/2"
            >
              {t("case-load-manager-absence-dialog-cancel-button")}
            </Button>
            <Button
              onClick={async () => await mutate({ startDate, endDate })}
              className="w-1/2 flex items-center space-x-2"
            >
              {isLoading && <Loader2 className="w-4 h-4 animate-spin" />}
              <p>{t("case-load-manager-absence-dialog-save-button")}</p>
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
