import { Button } from '../../../../components/ui/button';
import { Fragment, useState } from 'react';
import { z } from 'zod';
import { cn } from '../../../../lib/utils';
import { CircleAlert, Loader2, Star } from 'lucide-react';

type Props = {
  handleSubmitFeedback: (feedback: {
    note?: string;
    rating: number;
    reason: string;
  }) => Promise<void>;
};

const feedbackSchema = z.object({
  note: z.string().optional(),
  rating: z.coerce.number(),
  reason: z.string(),
});

export function FeedbackForm({ handleSubmitFeedback }: Props) {
  const [rating, setRating] = useState<number | undefined>();
  const [hoverRating, setHoverRating] = useState<number | undefined>();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);

    // @ts-ignore - same in Toon & Maurice's repo
    const feedback = Object.fromEntries(formData);

    const parsedFeedback = feedbackSchema.safeParse(feedback);

    try {
      if (!parsedFeedback.success) {
        // @ts-ignore - same in Toon & Maurice's repo
        console.error(parsedFeedback.error);
        throw new Error('Please fill out all fields');
      }

      setLoading(true);

      // @ts-ignore - same in Toon & Maurice's repo
      await handleSubmitFeedback(parsedFeedback.data);
      setSuccess(true);
    } catch (error) {
      const e = error as Error;
      setError(e.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className="text-openup-dark-blue" onSubmit={onSubmit}>
      <div className="flex gap-1">
        <input className="hidden" min={1} name="rating" type="number" value={(rating ?? 0) + 1} />

        {Array.from({ length: 5 })
          .fill(0)
          .map((_, index) => (
            <Fragment key={index}>
              <label
                onClick={() => setRating(index)}
                onMouseEnter={() => setHoverRating(index)}
                onMouseLeave={() => setHoverRating(undefined)}
              >
                <Star
                  className={cn(
                    'hover:scale h-8 w-8 transition-all duration-150 hover:scale-125 hover:fill-current',
                    {
                      'fill-current stroke-1': index <= ((hoverRating || rating) ?? -1),
                    }
                  )}
                />
              </label>
            </Fragment>
          ))}
      </div>

      <div className="mt-8 flex flex-col gap-1">
        <label htmlFor="reason">Reason for rating</label>

        <select className="border-openup-gray rounded-lg border p-2" id="reason" name="reason">
          <option value="hallucination">Hallucination (unsafe)</option>

          <option value="not_helpful">Not helpful</option>

          <option value="tone_of_voice">Tone of voice</option>

          <option value="helpful">Helpful</option>

          <option value="other">Other</option>
        </select>
      </div>

      <div className="mt-8 flex flex-col gap-1">
        <label htmlFor="feedback">Additional Feedback</label>

        <textarea
          className="border-openup-gray text-openup-dark-blue/90 flex-grow rounded border p-2"
          id="feedback"
          name="note"
        />
      </div>

      {success && (
        <p className="bg-openup-success mt-4 rounded-full p-1 text-center text-sm font-medium text-white/90 lg:p-4">
          Thank you for your feedback!
        </p>
      )}

      {error && (
        <div className="bg-openup-dark-blue text-openup-offwhite mt-4 flex items-center gap-2 rounded-lg px-3 py-1 text-sm italic">
          <CircleAlert className="h-6 w-6" />

          <p>{error}</p>
        </div>
      )}

      <Button className="mt-8" disabled={loading || success} type="submit">
        {loading ? <Loader2 className="mx-auto h-8 w-8" /> : 'Submit feedback'}
      </Button>
    </form>
  );
}
