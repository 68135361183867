import { styled } from "@mui/system";
import {
  colors,
  Card,
  CardHeader,
  Divider,
  Button,
  CardContent,
} from "@mui/material";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import listPlugin from "@fullcalendar/list";
import AddIcon from "@mui/icons-material/Add";
import useStores from "../../useStores";
import ConsultAddEditModal from "./ConsultAddEditModal";

const StyledNotes = styled("div")(() => ({
  "white-space": "pre-line",
}));

const StyledCardContentRoot = styled(CardContent)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  "& .fc-unthemed td": {
    borderColor: theme.palette.divider,
  },
  "& .fc-widget-header": {
    backgroundColor: colors.grey[50],
  },
  "& .fc-axis": {
    ...theme.typography.body2,
  },
  "& .fc-list-item": {
    cursor: "pointer",
  },
  "& .fc-list-item-time": {
    ...theme.typography.body2,
  },
  "& .fc-list-item-title": {
    ...theme.typography.body1,
  },
  "& .fc-list-heading-main": {
    ...theme.typography.h6,
  },
  "& .fc-list-heading-alt": {
    ...theme.typography.h6,
  },
  "& .fc th": {
    borderColor: theme.palette.divider,
  },
  "& .fc-day-header": {
    ...theme.typography.subtitle2,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    padding: theme.spacing(1),
    backgroundColor: colors.grey[50],
  },
  "& .fc-day-top": {
    ...theme.typography.body2,
  },
  "& .fc-highlight": {
    backgroundColor: colors.blueGrey[50],
  },
  "& .fc-list-empty": {
    ...theme.typography.subtitle1,
  },
}));

const StyledAddIcon = styled(AddIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const Consults = () => {
  const { consultsStore, patientStore } = useStores();
  const [selectedConsult, setSelectedConsult] = useState(null);
  const [openAddConsultModal, setOpenAddConsultModal] = useState(null);

  useEffect(() => {
    consultsStore.fetchConsults(patientStore.dossier.id);
  }, [consultsStore, patientStore.dossier.id]);

  const handleConsultClick = (info) => {
    const selected = consultsStore.consults.find(
      (consult) => consult.id === parseInt(info.event.id)
    );
    setSelectedConsult(selected);
  };

  const handleModalClose = () => {
    setSelectedConsult(null);
    setOpenAddConsultModal(false);
  };

  const fullCalendarView = {
    allTime: {
      type: "list",
      visibleRange: {
        start: "2018-01-01",
        end: "2100-01-01",
      },
    },
  };

  function renderEventContent(eventInfo) {
    return (
      <div>
        <b>{eventInfo.event.title}</b>
        <br />
        Medewerker: {eventInfo.event.extendedProps.employeeName}
        <br />
        <StyledNotes>
          Notities: {eventInfo.event.extendedProps.notes}
        </StyledNotes>
      </div>
    );
  }

  return (
    <Card>
      <CardHeader
        title="Consults"
        action={
          <Button
            color="primary"
            size="small"
            onClick={() => setOpenAddConsultModal(true)}
          >
            <StyledAddIcon />
            Toevoegen
          </Button>
        }
      />
      <Divider />
      <StyledCardContentRoot>
        <FullCalendar
          views={fullCalendarView}
          initialView="allTime"
          plugins={[listPlugin]}
          eventClick={handleConsultClick}
          events={consultsStore.consults}
          headerToolbar={false}
          height="auto"
          rerenderDelay={10}
          selectable
          locale="nl"
          eventContent={renderEventContent}
          noEventsContent="Geen afspraken gevonden."
        />
        <ConsultAddEditModal
          consult={selectedConsult}
          isFirstConsult={consultsStore.consults.length === 0}
          isInEditMode={!!selectedConsult}
          isInAddMode={openAddConsultModal}
          onClose={handleModalClose}
        />
      </StyledCardContentRoot>
    </Card>
  );
};

export default observer(Consults);
