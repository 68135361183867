import { TFunction } from "i18next";
import { OnboardPatientMainListEntry, OnboardPatientDetails } from "./types";
import { KopModelStatus } from "../types";

export const getReadableKopModelStatus = (
  t: TFunction<"translation", undefined>,
  patient: OnboardPatientMainListEntry
) => {
  switch (patient.kopModelStatus) {
    case KopModelStatus.Sent:
      return t("case-load-manager-onboarding-kop-model-status-sent");

    case KopModelStatus.Draft:
      return getDidIntakeHappen(patient)
        ? t("case-load-manager-onboarding-kop-model-status-not-relevant")
        : t("case-load-manager-onboarding-kop-model-status-draft");

    case KopModelStatus.NotStarted:
      return getDidIntakeHappen(patient)
        ? t("case-load-manager-onboarding-kop-model-status-not-relevant")
        : t("case-load-manager-onboarding-kop-model-status-not-started");
  }
};

export const getDidIntakeHappen = (
  onboardingPatient: OnboardPatientMainListEntry | OnboardPatientDetails
) =>
  onboardingPatient.intakeOn != null &&
  new Date(onboardingPatient.intakeOn!) <= new Date();
