import { Dialog, DialogContent } from "../../../components/ui/dialog";
import { Eye, FileQuestion } from "lucide-react";
import { useGetReferralLetter } from "../hooks";

export const ViewReferralLetter = ({
  referralId,
  documentId,
  open,
  onOpenChange,
  fileName,
}: {
  referralId: string;
  documentId: string;
  open: boolean;
  onOpenChange: (newValue: boolean) => void;
  fileName: string;
}) => {
  const { data: document } = useGetReferralLetter(referralId, documentId, open);

  return (
    <Dialog
      open={open}
      onOpenChange={(newValue) => {
        onOpenChange(newValue);
      }}
    >
      {fileName ? (
        <div
          className="inline-flex items-center space-x-2 text-link cursor-pointer font-medium hover:underline"
          onClick={() => {
            onOpenChange(true);
          }}
        >
          <div className="w-6 h-6">
            <Eye className="h-6 w-6" />
          </div>
          <p className="hover:underline">{fileName}</p>
        </div>
      ) : (
        <div className="flex items-center space-x-2 opacity-70">
          <div className="w-6 h-6">
            <FileQuestion className="w-6 h-6" />
          </div>
          <p>No referral letter present.</p>
        </div>
      )}

      <DialogContent className="min-w-[75vw] h-[97vh] p-10">
        <iframe
          src={`${document}`} // todo? #toolbar=0
          width="100%"
          height="100%"
        />
      </DialogContent>
    </Dialog>
  );
};
