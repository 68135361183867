import { createContext } from "react";
import { DefaultGenerics, StreamChat } from "stream-chat";

interface ChatContextProps {
  selectedConversationChannelId: string | null;
  setSelectedConversationChannelId: (newValue: string | null) => void;
  chatClient: StreamChat<DefaultGenerics> | null;
}

export const ChatContext = createContext<ChatContextProps>({
  selectedConversationChannelId: null,
  setSelectedConversationChannelId: () => {},
  chatClient: null,
});
