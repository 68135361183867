import { ConversationQuickDetails } from "../../conversation/quickDetails";
import { ChevronDown, ChevronUp, Folder, FolderPen, Trash } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { FolderDetails } from "../types";
import { sortConversation } from "../../../streamChat/utils";
import invariant from "tiny-invariant";
import { dropTargetForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { ChatConversationDetails } from "../../../streamChat/types";
import { ActionMenu } from "./actionMenu";

type SubFolderProps = {
  parentFolder: FolderDetails;
  subFolder: FolderDetails;
  conversations: ChatConversationDetails[];
};

export const SubFolder = ({
  parentFolder,
  subFolder,
  conversations,
}: SubFolderProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const unreadMessageCount = conversations.filter(
    (c) => c.numberOfUnreadMessages > 0
  ).length;
  const ref = useRef(null);
  const [isDraggedOver, setIsDraggedOver] = useState(false);

  useEffect(() => {
    const folderEl = ref.current;
    invariant(folderEl); // Ensure the column element exists

    // Set up the drop target for the column element
    return dropTargetForElements({
      element: folderEl,
      onDragStart: () => setIsDraggedOver(true),
      onDragEnter: () => setIsDraggedOver(true),
      onDragLeave: () => setIsDraggedOver(false),
      onDrop: () => setIsDraggedOver(false),
      getData: () => ({ type: "sub-folder", subFolderId: subFolder.id }),
      getIsSticky: () => false,
    });
  }, [subFolder.id]);

  return (
    <div
      className={`pl-2 border-l-4 ${isDraggedOver ? "bg-blue-100" : ""}`}
      ref={ref}
      onClick={(e) => {
        e.stopPropagation();
        setIsExpanded(!isExpanded);
      }}
    >
      <div className="flex items-center justify-between cursor-pointer">
        <div className="flex items-center space-x-2">
          <div className="w-4 h-4">
            {unreadMessageCount > 0 ? (
              <FolderPen className="w-4 h-4" />
            ) : (
              <Folder className="w-4 h-4" />
            )}
          </div>

          <ActionMenu
            folderId={parentFolder.id}
            subFolderId={subFolder.id}
            currentName={subFolder.name}
            isExpanded={isExpanded}
          />

          {unreadMessageCount > 0 ? (
            <div className="bg-ipractice-blue px-1 py-px rounded-lg text-white flex items-center justify-center text-xs">
              {unreadMessageCount}
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="w-4 h-4 opacity-80 hover:opacity-100">
          {isExpanded ? (
            <ChevronUp className="w-4 h-4" />
          ) : (
            <ChevronDown className="w-4 h-4" />
          )}
        </div>
      </div>
      {isExpanded ? (
        <>
          {conversations.length == 0 ? (
            <p className="text-xs opacity-75 pt-1 pl-6">
              Empty sub-folder, drag a conversation here
            </p>
          ) : (
            <div className="px-2 mt-2 bg-slate-50 rounded divide-y">
              {conversations.toSorted(sortConversation).map((c) => {
                return (
                  <ConversationQuickDetails
                    key={c.channelId}
                    conversation={c}
                    parentFolderId={parentFolder.id}
                    parentSubFolderId={subFolder.id}
                  />
                );
              })}
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
