import React, { useEffect } from "react";
import { styled } from "@mui/system";
import { observer } from "mobx-react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import { Formik } from "formik";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { trackPromise } from "react-promise-tracker";
import { useSnackbar } from "notistack";
import PostAddIcon from "@mui/icons-material/PostAdd";
import { colors } from "@mui/material";
import moment, { now } from "moment";
import clsx from "clsx";
import Loader from "../../../shared/Loader";
import areas from "../../../utils/constants/areas";
import useStores from "../../../useStores";

const StyledCard = styled(Card)(() => ({
  borderColor: colors.red[600],
  borderWidth: 4,
  borderStyle: "solid",
}));

const Wire = () => {
  const { patientStore, wireUsersStore, onboardingStore } = useStores();
  const { patient } = patientStore;
  const { unassignedWireUsers } = wireUsersStore;
  const { enqueueSnackbar } = useSnackbar();

  const wireConversationReport = () => {
    trackPromise(patientStore.callWireConversationReport(), areas.WIRE_CARD)
      .then(() =>
        enqueueSnackbar("De wire conversatie staat nu in Praktijkdata", {
          variant: "success",
        })
      )
      .catch(() =>
        enqueueSnackbar("Exporteren mislukt.", { variant: "error" })
      );
  };

  useEffect(() => {
    wireUsersStore.fetchUnassignedWireUsers();
  }, [wireUsersStore]);

  const highlight = () => {
    return (
      patientStore.patient.wireUser === null &&
      onboardingStore.onboarding.intakeConsult !== null &&
      moment(onboardingStore.onboarding.intakeConsult.start).isBefore(now())
    );
  };

  const HighlightedCard = ({ children, shouldHighlight }) => {
    if (shouldHighlight) {
      return <StyledCard>{children}</StyledCard>;
    }

    return <Card>{children}</Card>;
  };

  return (
    <Loader area={areas.WIRE_CARD}>
      <HighlightedCard shouldHighlight={highlight()}>
        <Formik
          enableReinitialize
          initialValues={{
            wireUserId: patient.wireUser ? patient.wireUser.id : "",
          }}
          onSubmit={(values) => {
            wireUsersStore
              .patchWireUser(values.wireUserId, {
                patientId: patient.id,
              })
              .then(() => patientStore.fetchPatient(patient.id));
          }}
        >
          {(props) => {
            const {
              values,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              dirty,
            } = props;
            return (
              <form autoComplete="off" noValidate>
                <CardHeader title="Wire" />
                <Divider />
                <CardContent>
                  <Box p={1} mb={2}>
                    Username:
                    {patient.userProvidedWireUsername ? (
                      <span>
                        {" "}
                        <strong>{patient.userProvidedWireUsername}</strong>
                      </span>
                    ) : (
                      <span> onbekend</span>
                    )}
                  </Box>
                  <TextField
                    fullWidth
                    select
                    margin="dense"
                    name="wireUserId"
                    onChange={handleChange}
                    required
                    label="Wire handle"
                    value={values.wireUserId}
                    variant="outlined"
                    onBlur={handleBlur}
                    InputLabelProps={{ shrink: !!values.wireUserId }}
                  >
                    {unassignedWireUsers.map((unassignedWireUser) => (
                      <MenuItem
                        key={unassignedWireUser.id}
                        value={unassignedWireUser.id}
                      >
                        {unassignedWireUser.handle} -{unassignedWireUser.name} -
                        {unassignedWireUser.id}
                      </MenuItem>
                    ))}
                    {patient.wireUser && (
                      <MenuItem
                        key={patient.wireUser.id}
                        value={patient.wireUser.id}
                      >
                        {patient.wireUser.handle} -{patient.wireUser.name} -
                        {patient.wireUser.id}
                      </MenuItem>
                    )}
                  </TextField>
                </CardContent>
                <CardActions>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting || !dirty}
                    onClick={handleSubmit}
                  >
                    Opslaan
                  </Button>
                  <Button
                    type="button"
                    className="outline"
                    onClick={handleReset}
                    disabled={isSubmitting || !dirty}
                  >
                    Reset
                  </Button>
                </CardActions>

                <Divider />

                <CardActions>
                  <Button
                    onClick={wireConversationReport}
                    disabled={patient.wireUser === null}
                  >
                    <PostAddIcon />
                    Exporteer Wire chat naar Praktijkdata
                  </Button>
                </CardActions>
              </form>
            );
          }}
        </Formik>
      </HighlightedCard>
    </Loader>
  );
};

export default observer(Wire);
