import { ReferralTableSortingColumns } from "../../types";
import { PatientColName } from "./../columns/distinctCols/patientCol";
import { CalendlyInviteColName } from "./distinctCols/calendlyInviteSentCol";
import { IntakeOnColName } from "./../columns/distinctCols/intakeOnCol";
import { PreIntakeOnColName } from "./../columns/distinctCols/preIntakeOnCol";
import { DateOfReferralColName } from "./distinctCols/referralDateCol";
import { SortingState } from "@tanstack/react-table";

export const getSortingData = (
  state: SortingState
): {
  column: ReferralTableSortingColumns;
  isAsc: boolean;
} => {
  const defaultSorting = {
    column: ReferralTableSortingColumns.Patient,
    isAsc: true,
  };
  if (!state || state.length === 0) {
    return defaultSorting;
  }

  switch (state[0].id) {
    case PatientColName:
      return {
        column: ReferralTableSortingColumns.Patient,
        isAsc: !state[0].desc,
      };

    case CalendlyInviteColName:
      return {
        column: ReferralTableSortingColumns.Status,
        isAsc: !state[0].desc,
      };

    case IntakeOnColName:
      return {
        column: ReferralTableSortingColumns.IntakeOn,
        isAsc: !state[0].desc,
      };

    case PreIntakeOnColName:
      return {
        column: ReferralTableSortingColumns.PreIntakeOn,
        isAsc: !state[0].desc,
      };

    case DateOfReferralColName:
      return {
        column: ReferralTableSortingColumns.LastRegularConsultOn,
        isAsc: !state[0].desc,
      };

    default:
      return defaultSorting;
  }
};
