import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import axios from "axios";
import onboardingStatusEnum from "../utils/constants/onboardingStatusEnum";
import patchDataFactory from "../utils/patchDataFactory";
import { getCookie, setCookie } from "../utils/cookiesHelper";

const selectedStatusesCookie = "selectedStatuses";
const selectedPageSizeCookie = "pageSize";
const onboardingPath = "/onboarding";

class OnboardingsStore {
  dossiers = [];
  pageSize = 10;
  currentFetchPageSize = 10;

  selectedStatuses = [];

  constructor() {
    makeObservable(this, {
      dossiers: observable,
      selectedStatuses: observable,
      reset: action,

      setSelectedStatuses: action,
      fetchDossiers: action,
      patchOnboarding: action,
      updateOnboardingWireUserName: action,

      uploadReferralLetter: action,
      downloadReferralLetter: action,
      validateReferralLetter: action,
    });

    const pageSizeCookie = getCookie(selectedPageSizeCookie);
    if (pageSizeCookie != undefined) {
      this.pageSize = pageSizeCookie;
      this.currentFetchPageSize = pageSizeCookie;
    }
  }

  async setPageSize(newSize) {
    this.pageSize = newSize;
    this.currentFetchPageSize = newSize;

    setCookie(selectedPageSizeCookie, newSize, {
      path: onboardingPath,
    });

    await this.fetchDossiers();
  }

  async fetchMoreData() {
    this.currentFetchPageSize += this.pageSize;
    await this.fetchDossiers();
  }

  reset() {
    this.dossiers = [];
  }

  async setSelectedStatuses(selectedStatuses) {
    this.selectedStatuses = selectedStatuses;
    setCookie(selectedStatusesCookie, selectedStatuses, {
      path: onboardingPath,
    });

    await this.fetchDossiers();
  }

  async fetchDossiers() {
    const cookie = getCookie(selectedStatusesCookie);

    let url = new URL(
      `${window.location.protocol}//${window.location.host}/api/onboarding`
    );
    url.searchParams.append("pageSize", this.currentFetchPageSize);

    if (cookie !== undefined) {
      cookie.forEach((status) => {
        url.searchParams.append("status", status);
      });
    }

    const res = await axios.get(url);

    if (Array.isArray(res.data)) {
      runInAction(() => {
        this.dossiers = res.data;

        if (cookie === undefined) {
          setCookie(
            selectedStatusesCookie,
            Object.values(onboardingStatusEnum),
            { path: onboardingPath }
          );
          this.selectedStatuses = Object.values(onboardingStatusEnum);
        } else {
          this.selectedStatuses = cookie;
        }
      });
    }
  }

  async updateOnboardingWireUserName(dossierId, value) {
    const res = await axios.put(
      `dossiers/${dossierId}/onboarding/wire-user-name`,
      {
        value: value,
      }
    );

    const dossierIndexToReplace = this.dossiers.findIndex(
      (dos) => dos.id === dossierId
    );

    runInAction(() => {
      this.dossiers[dossierIndexToReplace] = res.data;
    });

    return res.data;
  }

  async patchOnboarding(dossierId, replacements) {
    const res = await axios.patch(
      `dossiers/${dossierId}/onboarding`,
      patchDataFactory(replacements)
    );

    const dossierIndexToReplace = this.dossiers.findIndex(
      (dos) => dos.id === dossierId
    );

    runInAction(() => {
      this.dossiers[dossierIndexToReplace] = res.data;
    });

    return res.data;
  }

  // Note: these are duplicated in OnboardingStore!
  async uploadReferralLetter(dossierId, data) {
    const res = await axios.post(`dossiers/${dossierId}/referral-letter`, data);

    const dossierIndexToReplace = this.dossiers.findIndex(
      (dos) => dos.id === dossierId
    );

    runInAction(() => {
      if (dossierIndexToReplace !== -1) {
        this.dossiers[dossierIndexToReplace] = res.data;
      }
    });

    return res.data;
  }

  async downloadReferralLetter(dossierId) {
    axios
      .get(`dossiers/${dossierId}/referral-letter`, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: response.headers["content-type"] })
        );
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.click();
      });
  }

  async validateReferralLetter(dossierId) {
    const res = await axios.post(
      `dossiers/${dossierId}/validate-referral-letter`,
      {}
    );

    const dossierIndexToReplace = this.dossiers.findIndex(
      (dos) => dos.id === dossierId
    );

    runInAction(() => {
      this.dossiers[dossierIndexToReplace] = res.data;
    });

    return res.data;
  }
}

export default OnboardingsStore;
