import axios from "axios";
import { useQuery } from "react-query";

export const useGetAuthorizations = () =>
  useQuery<{
    userName: string;
    epdId: number | null;
    bigRegistrationCode: string | null;
    roles: string[];
    position: string;
  }>(
    ["authorization"],
    async () => {
      const res = await axios.get(`authorization`);
      return res.data;
    },
    {
      retry: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
