import React from "react";
import { styled } from "@mui/system";
import { observer } from "mobx-react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Formik } from "formik";
import TextField from "@mui/material/TextField";
import * as Yup from "yup";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import {
  Container,
  FormControl,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
} from "@mui/material";
import ButtonsContainer from "../../../shared/ButtonsContainer";
import useStores from "../../../useStores";
import PayStatus from "../../../shared/PayStatus";

const StyledCardActions = styled(CardActions)(() => ({
  flexDirection: "column",
  alignItems: "flex-start",
}));

const StyledTextareaAutosize = styled(TextareaAutosize)(() => ({
  width: "100%",
}));

const Billing = () => {
  const { patientStore } = useStores();
  const { dossier, billing } = patientStore;

  const isBillingChangeDisabled = () => {
    return (
      billing.billingAgreedAt !== null ||
      dossier.completedAt !== null ||
      billing.closedAt !== null
    );
  };

  return (
    <Card>
      <Formik
        enableReinitialize
        initialValues={{
          paymentPercentage: billing.chargebeePaymentPercentage
            ? billing.chargebeePaymentPercentage.toString()
            : "100",
          note: billing.note ? billing.note : "",
          isInsuredHealthCare: dossier.isInsuredHealthCare,
          isPayedByThirdParty: billing.$type === "ThirdPartyBilling",
          isContractedHealthCare:
            billing.$type === "ContractedHealthCareBilling",
        }}
        onSubmit={(values) => {
          values.lookupGuid = billing.lookupGuid;
          patientStore.putBilling(values);
        }}
        validationSchema={Yup.object().shape({
          paymentPercentage: Yup.number().min(50).max(100).required(),
          isInsuredHealthCare: Yup.boolean().required(),
          isContractedHealthCare: Yup.boolean().required(),
        })}
      >
        {(props) => {
          const {
            values,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            dirty,
          } = props;
          return (
            <form autoComplete="off" noValidate>
              <CardHeader title="Financiële informatie" />
              <Divider />
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Status betalingsovereenkomst</TableCell>
                    <TableCell>
                      <PayStatus billing={billing} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={8}>
                    <TextField
                      fullWidth
                      label="Type zorg"
                      select
                      value={values.isInsuredHealthCare}
                      onChange={(e) => {
                        if (e.target.value === false) {
                          values.isContractedHealthCare = false;
                          values.paymentPercentage = "100";
                          values.isPayedByThirdParty = false;
                        } else {
                          values.isContractedHealthCare = true;
                          values.isPayedByThirdParty = false;
                        }
                        handleChange(e);
                      }}
                      margin="dense"
                      name="isInsuredHealthCare"
                      variant="outlined"
                      onBlur={handleBlur}
                      disabled={isBillingChangeDisabled()}
                      helperText={
                        billing.billingAgreedAt !== null &&
                        "Niet aanpasbaar nadat de betalingsovereenkomst is getekend. Gebruik het 'Opmerkingen: voor facturatie' veld in Praktijkdata en/of neem contact op met facturen@ipractice.nl voor aanpassingen op een later moment."
                      }
                    >
                      <MenuItem value>Verzekerde zorg</MenuItem>
                      <MenuItem value={false}>Onverzekerde zorg (B2C)</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={8}>
                    {values.isInsuredHealthCare ? (
                      <TextField
                        fullWidth
                        label="Betaalwijze"
                        select
                        value={values.isContractedHealthCare}
                        onChange={(e) => {
                          if (e.target.value === true) {
                            values.paymentPercentage = "100";
                            values.isPayedByThirdParty = false;
                          }
                          handleChange(e);
                        }}
                        margin="dense"
                        name="isContractedHealthCare"
                        variant="outlined"
                        onBlur={handleBlur}
                        disabled={isBillingChangeDisabled()}
                      >
                        <MenuItem value>Gecontracteerd</MenuItem>
                        <MenuItem value={false}>Ongecontracteerd</MenuItem>
                      </TextField>
                    ) : (
                      <TextField
                        fullWidth
                        label="Betaalwijze"
                        select
                        value={values.isPayedByThirdParty}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        margin="dense"
                        name="isPayedByThirdParty"
                        variant="outlined"
                        onBlur={handleBlur}
                        disabled={isBillingChangeDisabled()}
                      >
                        <MenuItem value={false}>Automatisch incasso</MenuItem>
                        <MenuItem value>Derde partij</MenuItem>
                      </TextField>
                    )}
                  </Grid>

                  <Grid item xs={8}>
                    {values.isInsuredHealthCare &&
                      !values.isContractedHealthCare && (
                        <FormControl
                          component="fieldset"
                          disabled={isBillingChangeDisabled()}
                        >
                          <FormLabel component="legend">
                            Te betalen percentage van iPractice tarief:
                          </FormLabel>
                          <RadioGroup
                            aria-label="paymentPercentage"
                            name="paymentPercentage"
                            value={values.paymentPercentage}
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              value="100"
                              control={<Radio />}
                              label="100%"
                            />
                            <FormControlLabel
                              value="85"
                              control={<Radio />}
                              label="85%"
                            />
                            <FormControlLabel
                              value="70"
                              control={<Radio />}
                              label="70%"
                            />
                          </RadioGroup>
                        </FormControl>
                      )}
                  </Grid>
                </Grid>
              </CardContent>
              <Container>
                Betaal notitie
                <StyledTextareaAutosize
                  minRows={5}
                  onChange={handleChange}
                  name="note"
                  value={values.note}
                />
              </Container>

              <Divider />

              <StyledCardActions>
                <ButtonsContainer>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={isSubmitting || !dirty}
                    onClick={handleSubmit}
                  >
                    Opslaan
                  </Button>
                  <Button
                    type="button"
                    className="outline"
                    onClick={handleReset}
                    disabled={isSubmitting || !dirty}
                  >
                    Reset
                  </Button>
                </ButtonsContainer>
              </StyledCardActions>
            </form>
          );
        }}
      </Formik>
    </Card>
  );
};

export default observer(Billing);
