import { Switch } from "../../../../components/ui/switch";

type ActiveAcceptingClientsStatusOnlyProps = {
  isChecked: boolean;
  onCheckedChange: (newValue: boolean) => void;
};

export const ActiveAcceptingClientsStatusOnly = ({
  isChecked,
  onCheckedChange,
}: ActiveAcceptingClientsStatusOnlyProps) => {
  return (
    <div className="flex items-center space-x-2">
      <Switch
        checked={isChecked}
        onCheckedChange={(x) => onCheckedChange(x)}
        id="active-accepting-clients-only"
      />
      <label htmlFor="active-accepting-clients-only">
        Show 'Active - accepting new clients' only
      </label>
    </div>
  );
};
