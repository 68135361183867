import React from "react";
import { styled } from "@mui/system";
import { Typography, colors } from "@mui/material";

const StyledTypography = styled(Typography)(
  ({ theme, rounded, mimicContained, customColor }) => ({
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 0,
    flexShrink: 0,
    borderRadius: 2,
    lineHeight: "10px",
    fontSize: "10px",
    height: 20,
    minWidth: 20,
    whiteSpace: "nowrap",
    padding: rounded ? theme.spacing(0.5) : theme.spacing(0.5, 1),
    borderRadius: rounded ? 10 : 0,
    backgroundColor: mimicContained ? customColor : "transparent",
    color: mimicContained ? "white" : customColor,
  })
);

function Label({ className, variant, color, shape, children, style }) {
  return (
    <StyledTypography
      rounded={shape === "rounded"}
      mimicContained={variant === "contained"}
      customColor={color}
      variant="overline"
    >
      {children}
    </StyledTypography>
  );
}

Label.defaultProps = {
  style: {},
  color: colors.grey[600],
  variant: "contained",
  shape: "square",
};

export default Label;
