import {
  DropdownMenuItem,
  DropdownMenuSubTrigger,
  DropdownMenuPortal,
  DropdownMenuSubContent,
  DropdownMenuSub,
} from '../../../../components/ui/dropdown-menu';
import { NotebookTabs, Settings } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export const CrmSubMenu = () => {
  const navigate = useNavigate();

  return (
    <DropdownMenuSub>
      <DropdownMenuSubTrigger className="cursor-pointer flex justify-between">
        <div className="flex space-x-2 items-center">
          <div className="w-4 h-4">
            <NotebookTabs className="h-4 w-4" />
          </div>
          <p>
            <span className="font-bold">CRM</span> Patient Referrals Administration
          </p>
        </div>
      </DropdownMenuSubTrigger>

      <DropdownMenuPortal>
        <DropdownMenuSubContent className="z-[99999999]">
          <DropdownMenuItem
            className="cursor-pointer space-x-2 flex items-center"
            onClick={(e) => {
              navigate('/admin/patient-referrals/config');
            }}
          >
            <div className="w-4 h-4">
              <Settings className="h-4 w-4" />
            </div>
            <p>Configuration</p>
          </DropdownMenuItem>
        </DropdownMenuSubContent>
      </DropdownMenuPortal>
    </DropdownMenuSub>
  );
};
