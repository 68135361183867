import { Button } from "../../../../../components/ui/button";
import { CalendarSearch } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../../components/ui/popover";
import { Calendar } from "../../../../../components/ui/calendar";
import { useState } from "react";

export const CalendarFilter = ({
  date,
  setDate,
}: {
  date: Date;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Popover open={isOpen} onOpenChange={setIsOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            className="flex items-center space-x-2 px-4"
            onClick={() => setIsOpen(true)}
          >
            <div className="w-4 h-4">
              <CalendarSearch className="w-4 h-4" />
            </div>
            <p>Date</p>
          </Button>
        </PopoverTrigger>
        <PopoverContent className="z-[999999]" align="start">
          <Calendar
            mode="single"
            toDate={undefined}
            selected={date}
            defaultMonth={date}
            onSelect={(_, d) => {
              setDate(d);
              setIsOpen(false);
            }}
            initialFocus
            disabled={(d) => d > new Date()}
          />
        </PopoverContent>
      </Popover>
    </>
  );
};
