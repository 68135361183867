import { Loader2 } from "lucide-react";
import { useTranslation } from "react-i18next";

export const Loading = () => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center space-x-1 p-12">
      <Loader2 className="w-4 h-4 animate-spin" />
      <p>{t("case-load-manager-loading")}</p>
    </div>
  );
};
