import { SelectablePatient } from "../types";
import { Mail } from "lucide-react";

type PopulatedResultProps = {
  patients: SelectablePatient[] | undefined;
  setSelectedPatient: (patient: SelectablePatient) => void;
};

export const PopulatedResult = ({
  patients,
  setSelectedPatient,
}: PopulatedResultProps) => {
  return (
    <div className="w-full pt-2">
      {(patients ?? []).length > 0 && (
        <>
          {patients!.map((patient) => (
            <div
              className="flex flex-col px-4 py-2 cursor-pointer text-muted-foreground hover:bg-muted"
              key={patient.patientId}
              onClick={() => setSelectedPatient(patient)}
            >
              <p className="font-bold">{patient.name}</p>
              <div className="flex items-center space-x-2 text-sm">
                <Mail className="w-4 h-4" />
                <p>{patient.email}</p>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};
