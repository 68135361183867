import { colors } from "@mui/material";
import React, { Fragment } from "react";
import { observer } from "mobx-react";
import Label from "./Label";

const StatusLabelBoolean = ({ boolean, positive, negative }) => {
    const statusColors = {
        positive: colors.green[600],
        negative: colors.orange[600]
    };

    return (
      <>
        {
                boolean
                    ? (
                      <Label color={statusColors.positive}>
                        {positive}
                      </Label>
)
                    : (
                      <Label color={statusColors.negative}>
                        {negative}
                      </Label>
)

            }
      </>
    );
};

export default observer(StatusLabelBoolean);
