import { ResourceSuggestion } from "../types";
import { cn } from "../../../../../../lib/utils";
import { ResourceSuggestionCard } from "./resource-suggestion-card";

type Props = {
  loadingResourceSuggestions: boolean;
  onSuggestedResourceClick: (suggestion: ResourceSuggestion) => void;
  resourceSuggestions: ResourceSuggestion[];
};

export function ResourceSuggestions({
  loadingResourceSuggestions,
  onSuggestedResourceClick,
  resourceSuggestions,
}: Props) {
  if (loadingResourceSuggestions) {
    return (
      <div className="mx-auto h-1/3">
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div>
      <div className="mb-3 flex flex-col space-y-2">
        <span className="text-openup-dark-blue tracking[-0.2px] font-semibold leading-5">
          Suggested resources to share
        </span>

        <span className="text-openup-dark-blue text-xs leading-4 text-opacity-70">
          Click to copy the resource to the chat. You will still be able to
          adjust before sending.
        </span>
      </div>

      {resourceSuggestions?.length > 0 ? (
        <ul className="flex flex-col gap-3">
          {resourceSuggestions.map((suggestion) => {
            return (
              <ResourceSuggestionCard
                description={suggestion.description}
                key={suggestion.link}
                onSuggestedResourceClick={() =>
                  onSuggestedResourceClick(suggestion)
                }
                title={suggestion.title}
                url={suggestion.link}
              />
            );
          })}
        </ul>
      ) : (
        <div className="text-openup-dark-blue flex flex-col items-center justify-center text-center text-xs font-medium leading-4">
          <p className="">No resources yet...</p>

          <p>They will generate after the second user message.</p>
        </div>
      )}
    </div>
  );
}

export function LoadingIndicator({ className }: { className?: string }) {
  return (
    <svg
      className={cn("animate-spin", className)}
      fill="none"
      height="40"
      viewBox={`0 0 40 40`}
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx={20}
        cy={20}
        opacity="0.3"
        r={18}
        stroke="#152361"
        strokeWidth={4}
      />

      <path
        d="M2.01433 20.7181C1.86186 16.8992 2.92889 13.1309 5.06091 9.9589C7.19293 6.7869 10.2793 4.37585 13.8729 3.0749"
        stroke="#152361"
        strokeWidth={4}
      />
    </svg>
  );
}
