import { ReferralDetails } from "../../types";
import { Separator } from "../../../../components/ui/separator";
import { useState } from "react";
import { SchedulePreIntakeButton } from "./schedulePreIntakeButton";
import { getSimpleFormattedDateTime } from "../../../../utils/dateUtils";
import { CancelPreIntakeButton } from "./cancelPreIntakeButton";
import { AppointmentCreationFailure } from "./appointmentCreationFailure";
import { CrmPatientEntryPatientRegistrationStepTracker } from "../registrationStatusTracker/crmPatientEntryPatientRegistrationStepTracker";

const title = "Schedule pre-intake";

export const SchedulePreIntakeRow = ({
  referral,
  stepNumber,
}: {
  referral: ReferralDetails;
  stepNumber: number;
}) => {
  // has intake, but we can still modify it
  if (
    referral.preIntakeOn &&
    (referral.status === "AwaitingIWelcomeEmailSend" ||
      referral.status === "AwaitingPatientApproval" ||
      referral.status === "Closed" ||
      referral.status === "RegisterPatientAndIntake")
  ) {
    return <ReschedulePreIntake referral={referral} stepNumber={stepNumber} />;
  }

  return <ScheduleFirstPreIntake referral={referral} stepNumber={stepNumber} />;
};

const ReschedulePreIntake = ({
  referral,
  stepNumber,
}: {
  referral: ReferralDetails;
  stepNumber: number;
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <div>
      <div className="flex items-center space-x-2">
        <CrmPatientEntryPatientRegistrationStepTracker
          referralId={referral.id}
          step="schedule-pre-intake"
        />
        <p className="text-lg font-medium leading-none">
          {stepNumber}. {title}
        </p>
      </div>

      <Separator className="my-1" />

      <div className="w-full">
        <p className="text-xs pb-2">
          A pre-intake has been scheduled for{" "}
          <span className="font-bold">
            {getSimpleFormattedDateTime(referral.preIntakeOn!)} with{" "}
            {referral.preIntakeByPsychologistName}
          </span>
          .
        </p>

        {[
          "RegisterPatientAndIntake",
          "AwaitingPatientApproval",
          "AwaitingIWelcomeEmailSend",
        ].includes(referral.status) && (
          <>
            <div className="pt-2 flex items-center space-x-2">
              <SchedulePreIntakeButton
                referral={referral}
                isDialogOpen={isDialogOpen}
                setIsDialogOpen={setIsDialogOpen}
              />
              {referral.preIntakeOn && (
                <CancelPreIntakeButton
                  referralId={referral.id}
                  isPreIntakeInPraktijkData={Boolean(
                    referral.preIntakePraktijkDataEpdId
                  )}
                />
              )}
            </div>
          </>
        )}
      </div>

      {referral.preIntakeCouldNotBeScheduledInPraktijkData && (
        <AppointmentCreationFailure referral={referral} area="pre-intake" />
      )}
    </div>
  );
};

const ScheduleFirstPreIntake = ({
  referral,
  stepNumber,
}: {
  referral: ReferralDetails;
  stepNumber: number;
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <div>
      <div className="flex items-center space-x-2">
        <CrmPatientEntryPatientRegistrationStepTracker
          referralId={referral.id}
          step="schedule-pre-intake"
        />
        <p className="text-lg font-medium leading-none">
          {stepNumber}. {title}
        </p>
      </div>
      <Separator className="my-1" />

      <div className="w-full pt-1">
        <SchedulePreIntakeButton
          referral={referral}
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
        />
      </div>

      {referral.preIntakeCouldNotBeScheduledInPraktijkData && (
        <AppointmentCreationFailure referral={referral} area="pre-intake" />
      )}
    </div>
  );
};
