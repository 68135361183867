import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";
import { useGetCaseLoadWeekProgressChart } from "../../hooks";
import { ceilToNearest500 } from "../../utils";
import { Skeleton } from "../../../../../components/ui/skeleton";
import { useDarkMode } from "../../../../../theme/useDarkMode";

export const CurrentWeekProgressChart = ({ date }: { date: Date }) => {
  const { data, isLoading } = useGetCaseLoadWeekProgressChart(date);
  const [theme] = useDarkMode();

  return (
    <div className="max-h-[50vh] w-full">
      <h2 className="text-2xl font-bold -mt-6 pb-4">Current week's progress</h2>
      {isLoading ? (
        <Skeleton className="h-[40vh] w-full rounded" />
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <AreaChart
            width={700}
            height={350}
            data={data!.days.map((d) => ({
              day: d.day,
              excludedPatients: d.count
                ? data!.totalNumberOfExcludedPatients
                : null,
              count: d.count
                ? [
                    data!.totalNumberOfExcludedPatients,
                    d.count + data!.totalNumberOfExcludedPatients,
                  ]
                : [],
            }))}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid
              strokeDasharray="3 3"
              stroke={theme === "light" ? "#cbd5e1" : "#1e293b"}
              fill={theme === "light" ? "white" : "#020617"}
            />
            <XAxis dataKey="day" />
            <YAxis
              type="number"
              domain={[0, ceilToNearest500(data!.totalNumberOfPatients)]}
              tickCount={
                ceilToNearest500(data!.totalNumberOfPatients) / 500 + 1
              }
            />
            <Legend />
            {theme === "dark" ? (
              <Tooltip
                contentStyle={{ backgroundColor: "#020617" }}
                itemStyle={{ color: "#64748b" }}
                cursor={{ fill: "transparent" }}
              />
            ) : (
              <Tooltip cursor={{ fill: "transparent" }} />
            )}
            <ReferenceLine
              y={data!.totalNumberOfPatients}
              stroke={theme == "light" ? "#34d399" : "#059669"}
              strokeWidth={3}
              label={{
                value: `Target (${data!.totalNumberOfPatients})`,
                fill: theme === "light" ? "#059669" : "#34d399",
                index: 99999999,
                position: "insideTopRight",
              }}
            />
            <Area
              type="monotone"
              dataKey="excludedPatients"
              name="Excluded patients"
              stroke="#dc2626"
              fill="#ef4444"
            />
            <Area
              type="monotone"
              dataKey="count"
              name="Supported patients"
              stroke="#16a34a"
              fill="#22c55e"
            />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};
