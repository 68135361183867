import axios from 'axios';
import { useMutation, useQuery } from 'react-query';
import {
  OnboardPatientDetails,
  OnboardPatientMainListEntry,
  PraktijkdataTreatmentInfo,
} from './types';
import { KopModelFields } from '../types';
import { queryClient } from '../../../react-query/provider';

const refetchOnboardingPatients = () => {
  queryClient.invalidateQueries(['case-load-manager', 'onboarding']);
  queryClient.refetchQueries(['case-load-manager', 'onboarding']);
};

export const useGetPersonalizedKopTemplates = (onSuccess: (data: KopModelFields[]) => void) => {
  const query = useQuery<KopModelFields[]>(
    ['my-kop-templates'],
    async () => {
      const res = await axios.get(`caseloadmanageronboardingpatient/my-kop-model-template`);
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onSuccess: onSuccess,
    }
  );

  return query;
};

export const useUpdatePersonalizedKopTemplate = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({
      nlTemplate,
      enTemplate,
    }: {
      nlTemplate: {
        complaints: string;
        circumstances: string;
        personalStyle: string;
        requestForHelp: string;
        questionsForPreIntake: string;
        myCommentsForDiagnosisAndTreatment: string;
      };
      enTemplate: {
        complaints: string;
        circumstances: string;
        personalStyle: string;
        requestForHelp: string;
        questionsForPreIntake: string;
        myCommentsForDiagnosisAndTreatment: string;
      };
    }) => {
      const res = await axios.post(`caseloadmanageronboardingpatient/my-kop-model-template`, {
        nlTemplate,
        enTemplate,
      });
      return res.data;
    },
    onSuccess: () => {
      onSuccess();
      refetchOnboardingPatients();
      queryClient.invalidateQueries(['my-kop-template']);
      queryClient.refetchQueries(['my-kop-template']);
    },
  });

  return mutation;
};

export const useGetOnboardingPatients = () => {
  const query = useQuery<{
    data: OnboardPatientMainListEntry[];
    pagination: {
      currentPage: number;
      total: number;
      limit: number;
    };
  }>(
    ['case-load-manager', 'onboarding', 'all-patients'],
    async () => {
      const res = await axios.get('caseloadmanageronboardingpatient');
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useGetOnboardingPatientDetails = (
  id: string,
  onSuccess?: (data: OnboardPatientDetails) => void
) => {
  const query = useQuery<OnboardPatientDetails>(
    ['case-load-manager', 'onboarding', 'patient-details', id],
    async () => {
      const res = await axios.get(`caseloadmanageronboardingpatient/${id}`);
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onSuccess: onSuccess,
    }
  );

  return query;
};

export const useDeleteOnboardingPatient = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      const res = await axios.delete(`caseloadmanageronboardingpatient/${id}`);
      return res.data;
    },
    onSuccess: () => {
      refetchOnboardingPatients();
      onSuccess();
    },
  });

  return mutation;
};

export const useGetWireHandleOfOnboardingPatient = (id: string) => {
  const query = useQuery<{
    wireHandle: string;
    userProvidedWireHandle: string;
  }>(
    ['case-load-manager', 'onboarding', 'wire-handle', id],
    async () => {
      const res = await axios.get(`caseloadmanageronboardingpatient/${id}/wire-handle`);
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useGetPraktijkdataTreatmentInfo = (epdPatientId: number, epdFileId: number) => {
  const query = useQuery<PraktijkdataTreatmentInfo>(
    ['case-load-manager', 'onboarding', 'praktijkdata-treatment-info', epdPatientId, epdFileId],
    async () => {
      const res = await axios.get(
        `caseloadmanageronboardingpatient/${epdPatientId}/${epdFileId}/praktijkdata-treatment-info`
      );
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  return query;
};

export const useUpdateKopModel = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({
      id,
      complaints,
      circumstances,
      personalStyle,
      requestForHelp,
      questionsForPreIntake,
      myCommentsForDiagnosisAndTreatment,
    }: {
      id: string;
      complaints: string;
      circumstances: string;
      personalStyle: string;
      requestForHelp: string;
      questionsForPreIntake: string;
      myCommentsForDiagnosisAndTreatment: string;
    }) => {
      const res = await axios.post(`caseloadmanageronboardingpatient/${id}/kop-model`, {
        complaints,
        circumstances,
        personalStyle,
        requestForHelp,
        questionsForPreIntake,
        myCommentsForDiagnosisAndTreatment,
      });
      return res.data;
    },
    onSuccess: () => {
      refetchOnboardingPatients();
      onSuccess();
    },
  });

  return mutation;
};

export const useSaveWireHandle = (onSuccess: () => void, onError: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({ id, wireHandle }: { id: string; wireHandle: string }) => {
      const res = await axios.post(`caseloadmanageronboardingpatient/${id}/wire-handle`, {
        wireHandle,
      });
      return res.data;
    },
    onSuccess: () => {
      refetchOnboardingPatients();

      onSuccess();
    },
    onError: onError,
  });

  return mutation;
};

export const useUpdatePatientLanguage = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({ id, languageCode }: { id: string; languageCode: 'en-US' | 'nl-NL' }) => {
      const res = await axios.post(`caseloadmanageronboardingpatient/${id}/language`, {
        languageCode,
      });
      return res.data;
    },
    onSuccess: () => {
      refetchOnboardingPatients();

      onSuccess();
    },
  });

  return mutation;
};

export const usePublishKopModel = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({
      id,
      complaints,
      circumstances,
      personalStyle,
      requestForHelp,
      questionsForPreIntake,
      myCommentsForDiagnosisAndTreatment,
    }: {
      id: string;
      complaints: string;
      circumstances: string;
      personalStyle: string;
      requestForHelp: string;
      questionsForPreIntake: string;
      myCommentsForDiagnosisAndTreatment: string;
    }) => {
      const res = await axios.post(`caseloadmanageronboardingpatient/${id}/publish-kop-model`, {
        complaints,
        circumstances,
        personalStyle,
        requestForHelp,
        questionsForPreIntake,
        myCommentsForDiagnosisAndTreatment,
      });
      return res.data;
    },
    onSuccess: () => {
      refetchOnboardingPatients();
      onSuccess();
    },
  });

  return mutation;
};

export const useSaveWireHandleAndPublishKopModel = (onSuccess: () => void, onError: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({
      id,
      wireHandle,
      complaints,
      circumstances,
      personalStyle,
      requestForHelp,
      questionsForPreIntake,
      myCommentsForDiagnosisAndTreatment,
    }: {
      id: string;
      wireHandle: string;
      complaints: string;
      circumstances: string;
      personalStyle: string;
      requestForHelp: string;
      questionsForPreIntake: string;
      myCommentsForDiagnosisAndTreatment: string;
    }) => {
      const res = await axios.post(
        `caseloadmanageronboardingpatient/${id}/save-wire-handle-and-publish-kop-model`,
        {
          wireHandle,
          complaints,
          circumstances,
          personalStyle,
          requestForHelp,
          questionsForPreIntake,
          myCommentsForDiagnosisAndTreatment,
        }
      );
      return res.data;
    },
    onSuccess: () => {
      refetchOnboardingPatients();
      onSuccess();
    },
    onError: onError,
  });

  return mutation;
};

export const useCustomizeWelcomeMessage = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({
      id,
      personalizedWelcomeMessage,
    }: {
      id: string;
      personalizedWelcomeMessage: string;
    }) => {
      const res = await axios.post(
        `caseloadmanageronboardingpatient/${id}/customize-welcome-message`,
        {
          personalizedWelcomeMessage,
        }
      );
      return res.data;
    },
    onSuccess: () => {
      refetchOnboardingPatients();
      onSuccess();
    },
  });

  return mutation;
};

export const useSendTreatmentStartMessage = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      const res = await axios.post(
        `caseloadmanageronboardingpatient/${id}/send-treatment-start-message`,
        {}
      );
      return res.data;
    },
    onSuccess: () => {
      refetchOnboardingPatients();
      onSuccess();
    },
  });

  return mutation;
};
