import { Separator } from "../../../components/ui/separator";
import { DocumentMetadata, DocumentTypeKeys } from "../types";
import { UploadReferralLetter } from "./uploadReferralLetter";
import { ViewReferralLetter } from "./viewReferralLetter";

export const ReferralLetter = ({
  referralId,
  isReadOnly,
  documentMetadata,
  uploadReferralLetterOpen,
  setUploadReferralLetterOpen,
  uploadReferralAttachmentOpen,
  setUploadReferralAttachmentOpen,
  viewReferralLetterOpen,
  setViewReferralLetterOpen,
}: {
  referralId: string;
  isReadOnly: boolean;
  documentMetadata: DocumentMetadata[];
  uploadReferralLetterOpen: boolean;
  setUploadReferralLetterOpen: (newOpen: boolean) => void;
  uploadReferralAttachmentOpen: boolean;
  setUploadReferralAttachmentOpen: (newOpen: boolean) => void;
  viewReferralLetterOpen: boolean;
  setViewReferralLetterOpen: (newOpen: boolean) => void;
}) => {
  return (
    <div>
      <p className="text-lg font-medium leading-none">Referral letter</p>
      <Separator className="my-1" />
      <div className="flex flex-col justify-start items-start mt-2 space-y-2">
        <div className="flex flex-col items-start">
          <ViewReferralLetter
            referralId={referralId}
            open={viewReferralLetterOpen}
            onOpenChange={setViewReferralLetterOpen}
            documentId={
              documentMetadata.filter(
                (metadata) =>
                  metadata.documentType === DocumentTypeKeys.ReferralLetter
              )[0]?.id
            }
            fileName={
              documentMetadata.filter(
                (metadata) =>
                  metadata.documentType === DocumentTypeKeys.ReferralLetter
              )[0]?.fileName
            }
          />
        </div>
        {isReadOnly ? (
          <></>
        ) : (
          <UploadReferralLetter
            hasAlreadyReferral={
              documentMetadata.length > 0 &&
              documentMetadata.some(
                (metadata) =>
                  metadata.documentType === DocumentTypeKeys.ReferralLetter
              )
            }
            referralId={referralId}
            documentType={DocumentTypeKeys.ReferralLetter}
            open={uploadReferralLetterOpen}
            onOpenChange={setUploadReferralLetterOpen}
          />
        )}
      </div>

      <p className="text-lg font-medium leading-none mt-4">Attachment(s)</p>

      <Separator className="my-1" />

      <div className="flex flex-col justify-start items-start mt-2">
        <div className="flex flex-col items-start">
          {documentMetadata
            .filter(
              (metadata) =>
                metadata.documentType === DocumentTypeKeys.ReferralAttachment
            )
            .map((metadata) => (
              <ViewReferralLetter
                referralId={referralId}
                open={viewReferralLetterOpen}
                onOpenChange={(isOpen) => setViewReferralLetterOpen(isOpen)}
                documentId={metadata.id}
                fileName={metadata.fileName}
              />
            ))}
        </div>
      </div>
      {isReadOnly ? (
        <></>
      ) : (
        <UploadReferralLetter
          hasAlreadyReferral={
            documentMetadata.length > 0 &&
            documentMetadata.some(
              (metadata) =>
                metadata.documentType === DocumentTypeKeys.ReferralAttachment
            )
          }
          referralId={referralId}
          documentType={DocumentTypeKeys.ReferralAttachment}
          open={uploadReferralAttachmentOpen}
          onOpenChange={setUploadReferralAttachmentOpen}
        />
      )}
    </div>
  );
};
