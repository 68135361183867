import React from "react";
import { observer } from "mobx-react";
import { Grid, Button, Tooltip } from "@mui/material";
import moment from "moment";
import { CalendarZoom } from "./CalendarZoom";
import { CalendarInfoForIntakeDeviations } from "./CalendarInfoForIntakeDeviations";

function CalendarToolbar({
  date,
  onDatePrev,
  onDateNext,
  onDateToday,
  calendarHeight,
  onChangeCalendarHeight,
  className,
  ...rest
}) {
  const currentDate = moment(date);
  const weeksDifference = currentDate.diff(moment().startOf("week"), "weeks");
  const isNoMoreDataAvailable = weeksDifference >= 16;

  return (
    <div {...rest}>
      <Grid
        alignItems="flex-end"
        container
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item>
          <Button
            variant="outlined"
            onClick={onDatePrev}
            sx={{ marginRight: 1 }}
          >
            Vorige
          </Button>
          <Button
            variant="outlined"
            onClick={onDateToday}
            sx={{ marginRight: 1 }}
          >
            Nu
          </Button>
          <Tooltip
            title={
              isNoMoreDataAvailable ? "Geen gegevens voor deze periode" : ""
            }
          >
            <span>
              <Button
                variant="outlined"
                disabled={isNoMoreDataAvailable}
                onClick={onDateNext}
              >
                Volgende
              </Button>
            </span>
          </Tooltip>
        </Grid>
        <Grid item>
          <CalendarZoom
            calendarHeight={calendarHeight}
            onChangeCalendarHeight={onChangeCalendarHeight}
          />
        </Grid>
      </Grid>
      <CalendarInfoForIntakeDeviations date={date} />
    </div>
  );
}

export default observer(CalendarToolbar);
