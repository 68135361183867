import React, { useState } from "react";
import { observer } from "mobx-react";
import { styled } from "@mui/system";
import {
  Button,
  Collapse,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Checkbox,
  ListItemText,
  Grid,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import useStores from "../../useStores";
import onboardingStatusEnum from "../../utils/constants/onboardingStatusEnum";
import StyledRoot from "../../shared/StyledRootDiv";

const OnboardingToolbar = () => {
  const { onboardingsStore } = useStores();
  const [filtersOpen, setFiltersOpen] = useState(true);

  return (
    <StyledRoot>
      <Grid
        item
        alignItems="flex-end"
        container
        direction="column"
        justifyContent="center"
        spacing={3}
      >
        <Button onClick={() => setFiltersOpen(!filtersOpen)}>
          <FilterListIcon />
          Filters
          {filtersOpen ? <ExpandLess /> : <ExpandMore />}
        </Button>
        <Collapse in={filtersOpen} timeout="auto" unmountOnExit>
          <div className="flex space-x-4">
            <FormControl>
              <p className="text-xs">Paginaformaat</p>
              <Select
                id="pagesize-dropdown"
                value={onboardingsStore.pageSize}
                onChange={(e) => onboardingsStore.setPageSize(e.target.value)}
                input={<Input />}
                autoWidth
              >
                {[10, 20, 50, 100].map((pageSize) => (
                  <MenuItem key={pageSize} value={pageSize}>
                    {pageSize}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <p className="text-xs">Selecteer status</p>
              <Select
                id="status-checkbox"
                multiple
                value={onboardingsStore.selectedStatuses}
                onChange={(e) =>
                  onboardingsStore.setSelectedStatuses(e.target.value)
                }
                input={<Input />}
                renderValue={(selected) => selected.join(", ")}
                autoWidth
              >
                {Object.keys(onboardingStatusEnum).map((key) => (
                  <MenuItem
                    key={onboardingStatusEnum[key]}
                    value={onboardingStatusEnum[key]}
                  >
                    <Checkbox
                      checked={
                        onboardingsStore.selectedStatuses.indexOf(
                          onboardingStatusEnum[key]
                        ) > -1
                      }
                    />
                    <ListItemText primary={onboardingStatusEnum[key]} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </Collapse>
      </Grid>
    </StyledRoot>
  );
};

export default observer(OnboardingToolbar);
