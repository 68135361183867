import { colors } from "@mui/material";

export const darkMode = {
  mode: "dark",
  primary: {
    contrastText: "#fff",
    main: "#325175",
    light: colors.indigo[100],
  },
  secondary: {
    contrastText: "#fff",
    main: colors.blue.A400,
  },
  success: {
    contrastText: "#fff",
    main: colors.green[600],
  },
  info: {
    contrastText: "#fff",
    main: colors.blue[600],
  },
  warning: {
    contrastText: "#fff",
    main: colors.orange[600],
  },
  error: {
    contrastText: "#fff",
    main: colors.red[600],
  },
  text: {
    primary: colors.blueGrey[500],
    secondary: colors.blueGrey[400],
    link: colors.blue[400],
  },
  background: {
    default: "#020617",
    paper: "#020617",
  },
  icon: "#94a3b8",
  divider: "#0f172a",
};

export const lightMode = {
  mode: "light",
  primary: {
    contrastText: "#fff",
    main: "#6F99CB",
    light: colors.indigo[100],
  },
  secondary: {
    contrastText: "#fff",
    main: colors.blue.A400,
  },
  success: {
    contrastText: "#fff",
    main: colors.green[600],
  },
  info: {
    contrastText: "#fff",
    main: colors.blue[600],
  },
  warning: {
    contrastText: "#fff",
    main: colors.orange[600],
  },
  error: {
    contrastText: "#fff",
    main: colors.red[600],
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600],
  },
  background: {
    default: "#F4F6F8",
    paper: "#fff",
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
};
