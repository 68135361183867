import { ReferralDetails } from "../../types";
import { Button } from "../../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { useState } from "react";
import { useSendForgottenRejectionEmail } from "../../hooks";
import { toast } from "sonner";
import { Textarea } from "../../../../components/ui/textarea";
import { PreviewRejectionMail } from "./previewRejectionMail";
import { Separator } from "../../../../components/ui/separator";
import {
  RadioGroup,
  RadioGroupItem,
} from "../../../../components/ui/radio-group";

export const ForgotToSendRejectionEmail = ({
  referral,
}: {
  referral: ReferralDetails;
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [emailType, setEmailType] = useState<"standard" | "custom">("standard");

  const [rejectionEmailUserInput, setRejectionEmailUserInput] = useState("");

  const { mutate: sendForgottenRejectionEmail, isLoading: isSendingEmail } =
    useSendForgottenRejectionEmail(() => {
      toast("Email sent", {
        description: "The rejection email has been sent to the patient",
      });

      setDialogOpen(false);
    });

  const isVisible =
    referral.status === "Rejected" &&
    !referral.rejectedByClient &&
    !referral.rejectionEmailSent;

  if (!isVisible) {
    return null;
  }

  return (
    <div className="mb-8">
      <p className="text-lg font-medium leading-none">
        Forgot to send rejection email?
      </p>
      <Separator className="my-1" />
      <p className="pt-1">
        The referral was rejected by iPractice, however, no rejection email was
        sent to the client.
      </p>

      <Button className="mt-2" onClick={() => setDialogOpen(true)}>
        Send rejection email
      </Button>

      <Dialog
        open={dialogOpen}
        onOpenChange={(newValue) => {
          setDialogOpen(newValue);
        }}
      >
        <DialogContent className="max-w-[60vh] p-8">
          <DialogHeader>
            <DialogTitle className="text-2xl">Send rejection email</DialogTitle>

            <RadioGroup
              onValueChange={(e: "standard" | "custom") => {
                setEmailType(e);
              }}
              className="flex flex-col"
            >
              <div className="flex items-center space-x-2">
                <RadioGroupItem
                  checked={emailType === "standard"}
                  value="standard"
                  id="standard"
                />
                <label htmlFor="standard">
                  Send standard rejection email to the patient
                </label>
              </div>

              <div className="flex items-center space-x-2">
                <RadioGroupItem
                  checked={emailType === "custom"}
                  value="custom"
                  id="custom"
                />
                <label htmlFor="custom">
                  Send fully customized rejection email to the patient
                </label>
              </div>
            </RadioGroup>

            <div>
              <p className="font-bold pb-1 pt-4">
                {emailType === "standard"
                  ? "Additional comments for the rejection email (optional)"
                  : "Specify the text of the email (mandatory)"}
              </p>
              <Textarea
                value={rejectionEmailUserInput || ""}
                onChange={(e) => setRejectionEmailUserInput(e.target.value)}
                rows={2}
              />
            </div>

            <PreviewRejectionMail
              referralLanguage={referral.language ?? "nl-NL"}
              patientName={`${referral.firstName} ${referral.lastName}`}
              comments={rejectionEmailUserInput}
              isCustom={emailType === "custom"}
            />

            <DialogFooter className="space-x-2 pt-6">
              <Button
                variant="outline"
                className="w-1/2"
                onClick={() => {
                  setDialogOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                className="w-1/2"
                variant="destructive"
                disabled={
                  isSendingEmail ||
                  (emailType === "custom" && !rejectionEmailUserInput)
                }
                onClick={async () =>
                  await sendForgottenRejectionEmail({
                    referralId: referral.id,
                    sendFullyCustomEmailTemplate: emailType === "custom",
                    userInput: rejectionEmailUserInput,
                  })
                }
              >
                {isSendingEmail ? "Sending email..." : "Send email"}
              </Button>
            </DialogFooter>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </div>
  );
};
