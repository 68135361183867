import { ColumnDef } from "@tanstack/react-table";
import { MainListPatient } from "../../types";
import { Checkbox } from "../../../../../components/ui/checkbox";
import { useLocation } from "react-router-dom";
import { isDateInPastWeeks } from "../../../../../utils/dateUtils";

export const RowSelectorColName = "Select";

export const RowSelectorCol: ColumnDef<MainListPatient> = {
  id: RowSelectorColName,
  enableResizing: false,
  size: 40,
  minSize: 40,
  maxSize: 40,
  header: ({ table }) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const fromUrl = searchParams.get("period");
    const date = fromUrl ? new Date(fromUrl!) : new Date();

    if (isDateInPastWeeks(date)) return <></>;

    return (
      <div className="p-3">
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && "indeterminate")
          }
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      </div>
    );
  },
  cell: ({ row }) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const fromUrl = searchParams.get("period");
    const date = fromUrl ? new Date(fromUrl!) : new Date();

    return (
      <div
        className={`min-h-20 w-[40px] flex justify-center items-center py-2 bg-slate-50`}
      >
        <Checkbox
          disabled={isDateInPastWeeks(date)}
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      </div>
    );
  },
};
