import React from 'react';
import { Grid } from "@mui/material";
import { observer } from "mobx-react";
import EditPatient from "../../../shared/EditPatient";
import EditDossierEpd from "../../../shared/EditDossierEpd";
import UserAgreements from "./UserAgreements";
import CommunicationEntries from "./CommunicationEntries";

const DetailsTab = () => {
    return (
      <Grid container spacing={4}>
        <Grid item xs={6} xl={4}>
          <EditPatient />
        </Grid>
        <Grid item xs={6} xl={4}>
          <EditDossierEpd />
        </Grid>
        <Grid item xs={6} xl={4}>
          <UserAgreements />
        </Grid>

        <Grid item xs={12} xl={6}>
          <CommunicationEntries />
        </Grid>
      </Grid>
    );
};

export default observer(DetailsTab);
