import { TableCell, TableRow } from '../../../../components/ui/table';

type RowProps = {
  label: string;
  values: number[];
};

export const Row = ({ label, values }: RowProps) => {
  return (
    <TableRow>
      <TableCell>{label}</TableCell>
      <TableCell>{values[0]}</TableCell>
      <TableCell>{values[1]}</TableCell>
      <TableCell>{values[2]}</TableCell>
      <TableCell>{values[3]}</TableCell>
      <TableCell>{values[4]}</TableCell>
      <TableCell>{values[5]}</TableCell>
      <TableCell>{values[6]}</TableCell>
      <TableCell>{values[7]}</TableCell>
      <TableCell>{values[8]}</TableCell>
    </TableRow>
  );
};
