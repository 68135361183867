import type { ComponentProps } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

type Props = ComponentProps<typeof ReactMarkdown>;

export function ChatMessageMarkdown({ children, components, ...props }: Props) {
  return (
    <div className="text-sm font-normal">
      <ReactMarkdown
        {...props}
        components={{
          ...components,
          a: ({ children, href, node, ...props }) => (
            <a href={href} rel="noopener noreferrer" target="_blank" {...props}>
              {children}
            </a>
          ),
          table: ({ children, ...props }) => (
            <table className="w-full table-auto rounded-lg border border-slate-200">
              {children}
            </table>
          ),
          td: ({ children, ...props }) => (
            <td className="border-r border-slate-200 p-2 text-left text-slate-700">{children}</td>
          ),
          th: ({ children, ...props }) => (
            <th className="border-r border-slate-200 p-2 text-left font-bold text-slate-700">
              {children}
            </th>
          ),
          tr: ({ children, ...props }) => (
            <tr className="border-b border-slate-200 odd:bg-white even:bg-gray-100">{children}</tr>
          ),
        }}
        remarkPlugins={[remarkGfm]}
      >
        {children}
      </ReactMarkdown>
    </div>
  );
}
