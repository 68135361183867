import { CalendarSearch } from "lucide-react";
import { Button } from "../../../../components/ui/button";
import { ReferralDetails } from "../../types";
import { useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../components/ui/dropdown-menu";
import { ScheduleIntakeViaCalendarDialog } from "./scheduleIntakeViaCalendarDialog";
import { ScheduleIntakeManuallyDialog } from "./scheduleIntakeManuallyDialog";

export const ScheduleIntakeButton = ({
  referral,
  enabled,
}: {
  referral: ReferralDetails;
  enabled: boolean;
}) => {
  const [isCalendarDialogOpen, setIsCalendarDialogOpen] = useState(false);
  const [isManualScheduleDialogOpen, setIsManualScheduleDialogOpen] =
    useState(false);

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            className="flex items-center space-x-2 px-4 w-fit"
            disabled={!enabled}
          >
            <div className="w-4 h-4">
              <CalendarSearch className="w-4 h-4" />
            </div>
            <p>
              {referral.intakeOn ? "Re-schedule intake" : "Schedule intake"}
            </p>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="end"
          side="bottom"
          className="bg-slate-900 text-slate-200 dark:text-slate-300"
        >
          <DropdownMenuGroup>
            <DropdownMenuItem
              className="cursor-pointer"
              onClick={() => setIsCalendarDialogOpen(true)}
            >
              Schedule via the Calendar
            </DropdownMenuItem>
            <DropdownMenuItem
              className="cursor-pointer"
              onClick={() => setIsManualScheduleDialogOpen(true)}
            >
              Schedule manually
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>

      <ScheduleIntakeViaCalendarDialog
        isDialogOpen={isCalendarDialogOpen}
        setIsDialogOpen={setIsCalendarDialogOpen}
        referral={referral}
      />

      <ScheduleIntakeManuallyDialog
        isDialogOpen={isManualScheduleDialogOpen}
        setIsDialogOpen={setIsManualScheduleDialogOpen}
        referral={referral}
      />
    </>
  );
};
