import { ReferralDetails } from "../../types";
import { Button } from "../../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { useState } from "react";
import { useUndoRejection } from "../../hooks";
import { toast } from "sonner";
import { Separator } from "../../../../components/ui/separator";

export const ReopenRejectedReferral = ({
  referral,
}: {
  referral: ReferralDetails;
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const { mutate: undoRejection, isLoading } = useUndoRejection(() => {
    toast("Successfully re-opened", {
      description:
        "The rejection has been undone and the referral has been re-opened",
    });

    setDialogOpen(false);
  });

  const isVisible = referral.status === "Rejected";

  if (!isVisible) {
    return null;
  }

  return (
    <div>
      <p className="text-lg font-medium leading-none">Undo rejection</p>
      <Separator className="my-1" />
      <p className="pt-1">
        If the referral needs to be reopened, you can undo the rejection.
      </p>

      <Button className="mt-2" onClick={() => setDialogOpen(true)}>
        Undo rejection
      </Button>

      <Dialog
        open={dialogOpen}
        onOpenChange={(newValue) => {
          setDialogOpen(newValue);
        }}
      >
        <DialogContent className="max-w-[60vh] p-8">
          <DialogHeader>
            <DialogTitle className="text-2xl">Undo rejection</DialogTitle>

            <p>
              Are you sure you want to undo the rejection? This will re-open the
              referral and place it in its previous state, before it was
              rejected.
            </p>

            <DialogFooter className="space-x-2 pt-6">
              <Button
                variant="outline"
                className="w-1/2"
                onClick={() => {
                  setDialogOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                className="w-1/2"
                variant="destructive"
                disabled={isLoading}
                onClick={async () =>
                  await undoRejection({
                    referralId: referral.id,
                  })
                }
              >
                {isLoading ? "Re-opening referral..." : "Re-open referral"}
              </Button>
            </DialogFooter>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </div>
  );
};
