import { ColumnDef } from "@tanstack/react-table";
import { ReferredPatient } from "../../../types";
import { useTranslation } from "react-i18next";
import { FileText } from "lucide-react";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../../../../../components/ui/hover-card";

export const HealthCareInsurerColName = "Healthcare Insurer";

export const HealthCareInsurerCol: ColumnDef<ReferredPatient> = {
  id: HealthCareInsurerColName,
  accessorKey: "healthCareInsurer",
  minSize: 200,
  maxSize: 200,
  header: ({ column }) => {
    const { t } = useTranslation();

    return <p>{"Healthcare Insurer"}</p>;
  },
  cell: ({ row }) => {
    const healthCareInsurer = row.original.healthCareInsurer;

    if (!healthCareInsurer) {
      return <></>;
    }

    return (
      <HoverCard openDelay={200}>
        <HoverCardTrigger asChild>
          <div className="inline-flex items-center space-x-1">
            <p>{healthCareInsurer.name}</p>
            <div
              className={`h-4 w-4 pb-1 ${
                healthCareInsurer.isContracted ? "visible" : "hidden"
              }`}
            >
              <FileText className="h-4 w-4 opacity-50 hover:opacity-100" />
            </div>
          </div>
        </HoverCardTrigger>
        <HoverCardContent
          align="center"
          side="right"
          style={{ zIndex: 99999 }}
          className="w-fit"
        >
          {healthCareInsurer.isContracted ? "Contracted" : "Non-contracted"}
        </HoverCardContent>
      </HoverCard>
    );
  },
};
