import React, { useEffect } from "react";
import { Grid, Box } from "@mui/material";
import { observer } from "mobx-react";
import useStores from "../../useStores";
import TransferIntake from "./TransferIntake";
import Loading from "../../shared/Loading";
import Header from "../../shared/Header";
import EditPatient from "../../shared/EditPatient";
import EditDossierEpd from "../../shared/EditDossierEpd";
import dossierType from "../../utils/constants/dossierType";
import StyledRoot from "../../shared/StyledRootDiv";
import { useParams } from "react-router-dom";

const DossierTransferPage = () => {
  const { patientStore, onboardingStore } = useStores();
  const { patientId, dossierId } = useParams();

  useEffect(() => {
    patientStore.fetchPatientInformation(patientId, parseInt(dossierId));
    onboardingStore.fetchOnboarding(dossierType.TRANSFER, dossierId);
    return function cleanup() {
      onboardingStore.reset();
      patientStore.reset();
    };
  }, [patientId, dossierId, patientStore, onboardingStore]);

  if (
    patientStore.patient == null ||
    typeof patientStore.patient === "undefined" ||
    patientStore.dossier == null ||
    onboardingStore.onboarding == null
  ) {
    return <Loading />;
  }

  return (
    <StyledRoot>
      <Header
        pageTitle="cliënt"
        title={`${patientStore.patient.firstName} ${patientStore.patient.lastName} (${patientStore.fullEpdId})`}
      />

      <Grid container spacing={4}>
        <Grid item xs={6} xl={4}>
          <Box mb={4}>
            <EditPatient />
          </Box>
          <EditDossierEpd />
        </Grid>

        <Grid item xs={6} xl={4}>
          <TransferIntake />
        </Grid>
      </Grid>
    </StyledRoot>
  );
};

export default observer(DossierTransferPage);