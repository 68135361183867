import { useState } from "react";
import { useDebounce } from "use-debounce";
import { Filters } from "./filters";
import { Sq48RemindersResults } from "./sq48RemindersResults";

export default function SQ48ResultPdfsToChatAdmin() {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);

  return (
    <main className="p-24">
      <div className="flex item-start justify-between w-full">
        <section>
          <h1 className="font-bold text-4xl">
            Reminders to complete SQ48 questionnaires
          </h1>

          <p className="pt-2">
            View and re-trigger SQ48 reminders towards clients to complete their
            questionnaires, sent via chat.
          </p>
        </section>
      </div>

      <div className="mt-6">
        <Filters searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        <Sq48RemindersResults searchTerm={debouncedSearchTerm} />
      </div>
    </main>
  );
}
