import { ColumnDef } from "@tanstack/react-table";
import { ReferredPatient } from "../../../types";
import { getSimpleFormattedDateTime } from "../../../../../utils/dateUtils";
import { useTranslation } from "react-i18next";

export const IntakeOnColName = "Intake Date";

export const IntakeOnCol: ColumnDef<ReferredPatient> = {
  id: IntakeOnColName,
  accessorKey: "intakeOn",
  size: 175,
  minSize: 100,
  header: ({ column }) => {
    const { t } = useTranslation();
    return <p>Intake Date</p>;
  },
  cell: ({ getValue }) => {
    const dateAsString = getValue()?.toString();

    if (!dateAsString) {
      return <></>;
    }

    return <p>{getSimpleFormattedDateTime(dateAsString)}</p>;
  },
};
