import { useTranslation } from "react-i18next";
import { OnboardPatientMainListEntry } from "../types";
import { getReadableKopModelStatus } from "../utils";
import { KopModelStatus } from "../../types";

type KopStateColumnProps = {
  patient: OnboardPatientMainListEntry;
};

export const KopStateColumn = ({ patient }: KopStateColumnProps) => {
  const { t } = useTranslation();

  const getBackgroundColor = () => {
    switch (patient.kopModelStatus) {
      case KopModelStatus.NotStarted:
        return "bg-slate-100";
      case KopModelStatus.Draft:
        return "bg-yellow-100";
      case KopModelStatus.Sent:
        return "bg-green-100";
    }
  };

  const getTextColor = () => {
    switch (patient.kopModelStatus) {
      case KopModelStatus.NotStarted:
        return "text-slate-600";
      case KopModelStatus.Draft:
        return "text-yellow-600";
      case KopModelStatus.Sent:
        return "text-green-600";
    }
  };

  const getBorderColor = () => {
    switch (patient.kopModelStatus) {
      case KopModelStatus.NotStarted:
        return "border-slate-400";
      case KopModelStatus.Draft:
        return "border-yellow-400";
      case KopModelStatus.Sent:
        return "border-green-400";
    }
  };

  return (
    <div
      className={`inline-block px-3 py-1 rounded-full text-xs font-bold border ${getBorderColor()} ${getBackgroundColor()} ${getTextColor()}`}
    >
      {getReadableKopModelStatus(t, patient)}
    </div>
  );
};
