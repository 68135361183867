import { WysiwygEditor } from '../../../../../components/ui/wysiwyg-editor';
import { PraktijkdataTreatmentInfo } from '../praktijkdataTreatmentInfo/praktijkdataTreatmentInfo';
import { useEffect, useState } from 'react';
import { KopModelStatus } from '../../../types';
import { SaveDraftKopModelButton } from './saveDraftButton';
import { PublishButton } from './publishButton';
import { FileCheck2 } from 'lucide-react';
import { MyKopModelTemplateButton } from './myKopModelTemplateButton';
import { useTranslation } from 'react-i18next';
import { useGetOnboardingPatientDetails, useGetWireHandleOfOnboardingPatient } from '../../hooks';
import { KopModelTextBoxes } from './kopModelTextBoxes';
import { Textarea } from '../../../../../components/ui/textarea';
import { useIsEnabled } from '../../../../../feature-management/useIsEnabled';

type KopModelInputProps = {
  onboardingPatientId: string;
};

export const KopModelInput = ({ onboardingPatientId }: KopModelInputProps) => {
  const { t } = useTranslation();

  const { data: isWysiwygEnabled } = useIsEnabled('EnableWysiwygKopModel');

  const { data: wireUserNameData } = useGetWireHandleOfOnboardingPatient(onboardingPatientId);

  const {
    data: onboardingPatient,
    isLoading,
    isFetching,
  } = useGetOnboardingPatientDetails(onboardingPatientId, (data) => {
    // after KOP model template is updated, we want to refetch the onboarding patient details
    // (in case it had no KOP model yet, we gota reload to get the latest template applied into local state here)
    setPreIntakeQuestions(data.kopModel.questionsForPreIntake ?? '');
    setComplains(data.kopModel.complaints ?? '');
    setCircumstances(data.kopModel.circumstances ?? '');
    setPersonalStyle(data.kopModel.personalStyle ?? '');
    setRequestForHelp(data.kopModel.requestForHelp ?? '');
    setMyCommentsForDiagnosisAndTreatment(data.kopModel.myCommentsForDiagnosisAndTreatment ?? '');
  });

  const [preIntakeQuestions, setPreIntakeQuestions] = useState(
    onboardingPatient?.kopModel.questionsForPreIntake ?? ''
  );
  const [complaints, setComplains] = useState(onboardingPatient?.kopModel.complaints ?? '');
  const [circumstances, setCircumstances] = useState(
    onboardingPatient?.kopModel.circumstances ?? ''
  );
  const [personalStyle, setPersonalStyle] = useState(
    onboardingPatient?.kopModel.personalStyle ?? ''
  );
  const [myCommentsForDiagnosisAndTreatment, setMyCommentsForDiagnosisAndTreatment] = useState(
    onboardingPatient?.kopModel.myCommentsForDiagnosisAndTreatment ?? ''
  );
  const [requestForHelp, setRequestForHelp] = useState(
    onboardingPatient?.kopModel.requestForHelp ?? ''
  );

  const isNullOrEmpty = (value: string | null | undefined) => {
    return value === null || value === undefined || value.trim() === '';
  };

  const isPublishKopModelDisabled =
    isNullOrEmpty(complaints) ||
    isNullOrEmpty(circumstances) ||
    isNullOrEmpty(personalStyle) ||
    isNullOrEmpty(requestForHelp) ||
    isNullOrEmpty(wireUserNameData?.wireHandle);

  const isSaveDraftKopModelDisabled =
    onboardingPatient != null &&
    (preIntakeQuestions ?? '') == (onboardingPatient.kopModel.questionsForPreIntake ?? '') &&
    (complaints ?? '') == (onboardingPatient.kopModel.complaints ?? '') &&
    (circumstances ?? '') == (onboardingPatient.kopModel.circumstances ?? '') &&
    (personalStyle ?? '') == (onboardingPatient.kopModel.personalStyle ?? '') &&
    (myCommentsForDiagnosisAndTreatment ?? '') ==
      (onboardingPatient.kopModel.myCommentsForDiagnosisAndTreatment ?? '') &&
    (requestForHelp ?? '') == (onboardingPatient.kopModel.requestForHelp ?? '');

  const hasChanges =
    preIntakeQuestions !== onboardingPatient?.kopModel.questionsForPreIntake ||
    complaints !== onboardingPatient?.kopModel.complaints ||
    circumstances !== onboardingPatient?.kopModel.circumstances ||
    personalStyle !== onboardingPatient?.kopModel.personalStyle ||
    requestForHelp !== onboardingPatient?.kopModel.requestForHelp ||
    myCommentsForDiagnosisAndTreatment !==
      onboardingPatient?.kopModel.myCommentsForDiagnosisAndTreatment;

  window.onbeforeunload = (event) => {
    const changes = sessionStorage.getItem('hasUnsavedKopModelChanges');
    if (changes && changes === 'true') {
      const message = 'You have unsaved changes. Are you sure you want to leave?';
      event.returnValue = message;
      return message;
    }
  };

  useEffect(() => {
    if (hasChanges) {
      sessionStorage.setItem('hasUnsavedKopModelChanges', 'true');
    }
  }, [hasChanges]);

  if (isLoading) return <></>;

  return (
    <div>
      <PraktijkdataTreatmentInfo onboardingPatient={onboardingPatient!} />
      <div className="mt-6">
        <p className="font-bold">
          {t('case-load-manager-onboarding-kop-model-caption-preintake-questions')}
        </p>

        {isWysiwygEnabled ? (
          <WysiwygEditor
            isDisabled={isFetching}
            value={preIntakeQuestions}
            setValue={setPreIntakeQuestions}
            minHeight="100px"
          />
        ) : (
          <>
            {isFetching ? (
              <p className="border rounded p-2 mt-1 min-h-[400px]">{complaints}</p>
            ) : (
              <Textarea
                className="border-1-4 mt-1 bg-gray-50/50 dark:bg-slate-800"
                value={preIntakeQuestions}
                onChange={(e) => setPreIntakeQuestions(e.target.value)}
                rows={3}
              />
            )}
          </>
        )}
      </div>

      <KopModelTextBoxes
        isDisabled={isFetching}
        complaints={complaints}
        circumstances={circumstances}
        personalStyle={personalStyle}
        requestForHelp={requestForHelp}
        myCommentsForDiagnosisAndTreatment={myCommentsForDiagnosisAndTreatment}
        setComplains={setComplains}
        setCircumstances={setCircumstances}
        setPersonalStyle={setPersonalStyle}
        setRequestForHelp={setRequestForHelp}
        setMyCommentsForDiagnosisAndTreatment={setMyCommentsForDiagnosisAndTreatment}
      />

      {onboardingPatient!.kopModel.status === KopModelStatus.Sent && (
        <div>
          <div className="inline-flex items-center space-x-2 bg-green-100 text-green-600 rounded-lg px-2 py-1 mt-4">
            <div className="h-4 w-4">
              <FileCheck2 className="h-4 w-4" />
            </div>
            <p>{t('case-load-manager-onboarding-kop-model-already-in-pd-title')}</p>
          </div>
          <div className="border-l-[3px] border-green-100 pl-4 ml-px -mt-2">
            <p className="pt-4 pb-2 w-2/3 opacity-75">
              {t('case-load-manager-onboarding-kop-model-already-in-pd-desc')}
            </p>
          </div>
        </div>
      )}

      <div className="mt-6 w-full flex items-center justify-between">
        <MyKopModelTemplateButton />
        <div className="flex items-center space-x-2">
          <PublishButton
            isDisabled={isPublishKopModelDisabled}
            onboardingPatient={onboardingPatient!}
            complaints={complaints}
            circumstances={circumstances}
            personalStyle={personalStyle}
            requestForHelp={requestForHelp}
            questionsForPreIntake={preIntakeQuestions}
            myCommentsForDiagnosisAndTreatment={myCommentsForDiagnosisAndTreatment}
          />
          <SaveDraftKopModelButton
            isDisabled={isSaveDraftKopModelDisabled}
            onboardingPatient={onboardingPatient!}
            complaints={complaints}
            circumstances={circumstances}
            personalStyle={personalStyle}
            requestForHelp={requestForHelp}
            questionsForPreIntake={preIntakeQuestions}
            myCommentsForDiagnosisAndTreatment={myCommentsForDiagnosisAndTreatment}
          />
        </div>
      </div>
    </div>
  );
};
