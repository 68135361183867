import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import { PsychologistIntakeAssignment, Row } from "../types";
import { IntakeLimitRow } from "./intakeLimitRow";
import { hasDeviation } from "../utils";
import { FiltersButton } from "../filters/filtersButton";
import { useGetIntakeDeviationsForAdmin } from "../hooks";
import { Loader2 } from "lucide-react";
import { getEmployeeLastName } from "../../../../utils/getEmployeeLastName";
import { LocationWithTeamTag } from "../../../../shared/hooks/useGetLocationWithTeamTags";
import { PsychologistFilter } from "../filters/psychologistFilter";
import { LocationAndTeamFilter } from "../filters/locationAndTeamFilter";

export const IntakeLimitsTable = () => {
  const [showOnlyDeviations, setShowOnlyDeviations] = useState(false);
  const [showOnlyActiveAcceptingClients, setShowOnlyActiveAcceptingClients] =
    useState(false);
  const [psychologistFilter, setPsychologistFilter] = useState("");
  const [selectedLocationAndTeam, setSelectedLocationAndTeam] =
    useState<LocationWithTeamTag | null>(null);

  const { data, isLoading } = useGetIntakeDeviationsForAdmin();

  function groupAndTransform(
    assignments: PsychologistIntakeAssignment[]
  ): Row[] {
    const grouped: { [key: string]: Row } = {};

    for (const assignment of assignments) {
      if (!grouped[assignment.epdEmployeeId]) {
        grouped[assignment.epdEmployeeId] = {
          epdEmployeeId: assignment.epdEmployeeId,
          employeeName: assignment.employeeName,
          status: assignment.status,
          teams: assignment.teams,
          locations: assignment.locations,
          currentWeekNumberOfIntakes: 3,
          isCurrentWeekPartOfIndefinite: assignment.isCurrentWeekIndefinite,
          futureWeeks: [],
          comments: assignment.comments,
        };
      }

      if (assignment.isCurrentWeek) {
        grouped[assignment.epdEmployeeId].currentWeekNumberOfIntakes =
          assignment.numberOfIntakes;

        if (assignment.isIndefinite) {
          grouped[assignment.epdEmployeeId].futureWeeks.push({
            start: assignment.start,
            end: assignment.end,
            isIndefinite: assignment.isIndefinite,
            numberOfIntakes: assignment.numberOfIntakes,
          });
        }
      } else {
        grouped[assignment.epdEmployeeId].futureWeeks.push({
          start: assignment.start,
          end: assignment.end,
          isIndefinite: assignment.isIndefinite,
          numberOfIntakes: assignment.numberOfIntakes,
        });
      }
    }

    let result = Object.values(grouped);

    if (psychologistFilter) {
      result = result.filter((psy) =>
        psy.employeeName
          .toLowerCase()
          .includes(psychologistFilter.toLowerCase())
      );
    }

    if (showOnlyDeviations) {
      result = result.filter((psy) => hasDeviation(psy));
    }

    if (showOnlyActiveAcceptingClients) {
      result = result.filter(
        (psy) => psy.status === "Active - accepting new clients"
      );
    }

    if (
      selectedLocationAndTeam &&
      selectedLocationAndTeam.locationName !== "all"
    ) {
      result = result.filter(
        (psy) =>
          psy.locations.includes(selectedLocationAndTeam.locationName) &&
          psy.teams.includes(selectedLocationAndTeam.teamId.toString())
      );
    }

    return result.sort((a, b) =>
      getEmployeeLastName(a.employeeName).localeCompare(
        getEmployeeLastName(b.employeeName)
      )
    );
  }

  return (
    <div className="mt-4">
      <section className="w-full space-y-4">
        <div className="inline-block p-2 bg-slate-100 rounded text-slate-500">
          <h2>
            After updating the deviations, the Dashboard will automatically
            re-run the calendar algorithm.
          </h2>
          <p className="text-xs">
            This process typically takes 3-5 minutes. Your modifications will
            appear on the calendar once the algorithm finishes.
          </p>
        </div>

        <div className="w-full flex justify-between items-center">
          <div className="flex items-center space-x-2 w-2/3">
            <PsychologistFilter
              value={psychologistFilter}
              setValue={setPsychologistFilter}
            />
            <LocationAndTeamFilter
              selectedLocationAndTeam={selectedLocationAndTeam}
              setSelectedLocationAndTeam={setSelectedLocationAndTeam}
            />
          </div>

          <FiltersButton
            showOnlyDeviations={showOnlyDeviations}
            setShowOnlyDeviations={setShowOnlyDeviations}
            showOnlyActiveAcceptingClients={showOnlyActiveAcceptingClients}
            setShowOnlyActiveAcceptingClients={
              setShowOnlyActiveAcceptingClients
            }
          />
        </div>
      </section>

      <div className="border rounded mt-6">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[23%]">Psychologist</TableHead>
              <TableHead className="w-[20%]">Location & Team</TableHead>
              <TableHead className="w-[15%]">Current Week</TableHead>
              <TableHead className="w-[22%]">Future Weeks</TableHead>
              <TableHead className="w-[20%]">Comments</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <div className="p-4 flex items-center space-x-2">
                    <Loader2 className="w-4 h-4 animate-spin" />
                    <p>Loading...</p>
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {groupAndTransform(data!).length == 0 ? (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <div className="p-4 flex items-center space-x-2">
                        No entries found.
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  groupAndTransform(data!).map((psy) => (
                    <IntakeLimitRow data={psy} />
                  ))
                )}
              </>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
