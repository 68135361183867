function displayDays(notation) {
    let displayableString = '';
    notation = notation.split(' ');

    const days = [
        'Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'
    ];

    const daysIndex = notation.length - 1;

    let dayIndexes = [];
    if (notation[daysIndex].includes("-")) {
        for (let i = notation[daysIndex].charAt(0); i <= notation[daysIndex].charAt(notation[daysIndex].length - 1); i++) {
            dayIndexes.push(i);
        }
    } else {
        dayIndexes = notation[daysIndex].split(',');
    }

    dayIndexes.map((item, index) => {
        displayableString += days[item] + (index !== dayIndexes.length - 1 ? ', ' : ' ');

        return displayableString;
    });

    return displayableString;
}

function getDays(notation) {
    notation = notation.split(' ');

    const daysIndex = notation.length - 1;

    return notation[daysIndex].split(',');
}

function getHour(notation) {
    notation = notation.split(' ');

    const hourIndex = notation.length === 6 ? 2 : 1;

    return parseInt(notation[hourIndex]);
}

function getMinute(notation) {
    notation = notation.split(' ');

    const minuteIndex = (notation.length === 6 ? 2 : 1) - 1;

    return notation[minuteIndex] !== '*' ? notation[minuteIndex] : 0;
}

class CronNotation {
    notation = ['0', '0', '*', '*', '*'];

    toString() {
        return this.notation.join(' ');
    }

    onDays(days = []) {
        this.notation[this.notation.length - 1] = days.join(',');

        return this;
    }

    atHour(hour = 0) {
        this.notation[1] = hour;

        return this;
    }

    atMinute(minute = 0) {
        this.notation[0] = minute;

        return this;
    }
}

function displayLocalCronJob(notation) {
    let displayableString = '';
    notation = notation.split(' ');

    const daysIndex = notation.length - 1;
    const hourIndex = notation.length === 6 ? 2 : 1;
    const minuteIndex = hourIndex - 1;

    if (notation[daysIndex] !== '*') {
        displayableString += `${displayDays(notation.join(' '))}om `;
    } else if (notation[hourIndex + 1] === '*') {
        displayableString += 'Elke dag om ';
    }

    const timezoneDifferenceInHours = new Date().getTimezoneOffset() / 60;
    const hours = parseInt(notation[hourIndex]) - timezoneDifferenceInHours;

    displayableString += hours < 10 ? `0${hours}` : hours;

    if (notation[minuteIndex] !== '*') {
        displayableString += `:${notation[minuteIndex] < 10 ? `0${notation[minuteIndex]}` : notation[minuteIndex]}`;

        return displayableString;
    }

    return `${displayableString}:00`;
}

export {
 displayLocalCronJob, displayDays, getDays, getHour, getMinute, CronNotation
};
