import { Input } from "../../../../components/ui/input";

type PsychologistFilterProps = {
  value: string;
  setValue: (newValue: string) => void;
};

export const PsychologistFilter = ({
  value,
  setValue,
}: PsychologistFilterProps) => {
  return (
    <Input
      placeholder="Search for a psychologist..."
      className="w-1/3"
      autoFocus
      value={value}
      onChange={(event) => {
        setValue(event.target.value);
      }}
    />
  );
};
