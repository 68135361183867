import { useGetWorkingViewReport } from "../../hooks";
import { Skeleton } from "../../../../../components/ui/skeleton";
import { CalendarFilter } from "./calendarFilter";
import { WorkingViewReportChart } from "./chart";
import { useState } from "react";
import { getSimpleFormattedDate } from "../../../../../utils/dateUtils";
import moment from "moment";
import { RegenerateButton } from "./regenerateButton";
import { CalendarDays } from "lucide-react";

export const WorkingViewReport = () => {
  const today = new Date();

  const getPreviousDate = (date: Date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
  };

  const [date, setDate] = useState(getPreviousDate(today));
  const { data, isLoading } = useGetWorkingViewReport(
    moment(date).utc(true).toISOString()
  );

  const renderBody = () => {
    if (isLoading) {
      return <Skeleton className="h-[450px] w-full rounded" />;
    }

    if (!data)
      return (
        <div className="w-full py-12 bg-red-100 text-red-600 text-lg rounded text-center">
          Something went wrong.
        </div>
      );

    if (
      data.cities.every(
        (c) =>
          c.closedFromCalendlyViewCount == 0 &&
          c.closedFromWorkingViewCount == 0 &&
          c.menzisCount == 0 &&
          c.vgzCount == 0 &&
          c.notYetCalledCount == 0 &&
          c.calledButWithoutResponseCount == 0 &&
          c.calledAndActionNeededCount == 0 &&
          c.notCalledDueToNoActionNeedCount == 0 &&
          c.calledAndNoActionNeededCount == 0
      )
    ) {
      return (
        <div className="w-full py-12 bg-slate-100 text-lg rounded text-center">
          No data is available for the selected day.
        </div>
      );
    }

    return <WorkingViewReportChart data={data!} />;
  };

  return (
    <main className="pt-12">
      <section className="flex items-center justify-between">
        <div className="w-2/3">
          <h1 className="text-2xl font-bold">
            Working View report{" "}
            <span className="font-medium">- CRM / Patient Referral</span>
          </h1>
          <p>The number referrals in the working view.</p>
          <div className="mt-2 inline-flex items-center space-x-2 bg-blue-50 p-2 rounded">
            <div className="w-4 h-4">
              <CalendarDays className="w-4 h-4" />
            </div>
            <p>
              Period:{" "}
              <span className="font-bold">{getSimpleFormattedDate(date)}</span>
              's data.
            </p>
          </div>
        </div>

        <div className="w-1/3 flex items-center justify-end space-x-2">
          <RegenerateButton />
          <CalendarFilter date={date} setDate={setDate} />
        </div>
      </section>

      <section className="pt-6">{renderBody()}</section>
    </main>
  );
};
