import { useTranslation } from "react-i18next";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../../components/ui/table";
import { Switch } from "../../../../../components/ui/switch";
import { useGetCaseLoadDetailedDataFull } from "../../hooks";
import { Skeleton } from "../../../../../components/ui/skeleton";
import { CheckCircle, CircleOff } from "lucide-react";
import { useState } from "react";

export const DetailsTable = ({ date }: { date: Date }) => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetCaseLoadDetailedDataFull(date);

  const [
    showOnlyChatAppointmentMismatches,
    setShowOnlyChatAppointmentMismatches,
  ] = useState(false);

  const Header = () => {
    return (
      <div className="flex items-start justify-between">
        <div>
          <h2 className="text-2xl font-bold">All caseloads</h2>
          <p>
            Find here each psyhologist's full caseload, without any aggregation.
          </p>
        </div>

        <div className="flex items-center space-x-2">
          <Switch
            id="show-only-chat-appointment-mismatches"
            checked={showOnlyChatAppointmentMismatches}
            onCheckedChange={(x) => setShowOnlyChatAppointmentMismatches(x)}
          />
          <label htmlFor="show-only-chat-appointment-mismatches">
            {t(
              "case-load-manager-admin-panel-kpi-details-table-show-only-chat-appointment-mismatches"
            )}
          </label>
        </div>
      </div>
    );
  };

  const getFilteredData = () => {
    if (showOnlyChatAppointmentMismatches) {
      // no pagination on filter
      return data!.filter(
        (x) => x.hasChattedWith && !x.hasCreatedChatAppointment
      );
    }

    return data!;
  };

  if (isLoading) {
    return (
      <div>
        <Header />
        <Skeleton className="h-12 w-full rounded mt-12" />
        <Skeleton className="h-8 w-full rounded mt-1" />
        <Skeleton className="h-8 w-full rounded mt-1" />
        <Skeleton className="h-8 w-full rounded mt-1" />
        <Skeleton className="h-8 w-full rounded mt-1" />
        <Skeleton className="h-8 w-full rounded mt-1" />
        <Skeleton className="h-8 w-full rounded mt-1" />
        <Skeleton className="h-8 w-full rounded mt-1" />
      </div>
    );
  }

  return (
    <>
      <Header />
      <div className="mt-6">
        <Table limitHeight className="border">
          <TableHeader>
            <TableRow>
              <TableHead className="w-1/5">
                {t(
                  "case-load-manager-admin-panel-kpi-details-table-col-psychologist"
                )}
              </TableHead>
              <TableHead className="w-1/5">
                {t(
                  "case-load-manager-admin-panel-kpi-details-table-col-patient"
                )}
              </TableHead>
              <TableHead className="w-1/5">
                {t(
                  "case-load-manager-admin-panel-kpi-details-table-col-needs-checkin"
                )}
              </TableHead>
              <TableHead className="w-1/5">
                {t(
                  "case-load-manager-admin-panel-kpi-details-table-col-reason-for-no-checkin"
                )}
              </TableHead>
              <TableHead className="text-center min-w-[150px]">
                {t(
                  "case-load-manager-admin-panel-kpi-details-table-col-status"
                )}
              </TableHead>
              <TableHead className="text-center min-w-[200px]">
                {t(
                  "case-load-manager-admin-panel-kpi-details-table-col-has-issued-chat-appointment"
                )}
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {getFilteredData().map((x, i) => (
              <TableRow key={i}>
                <TableCell className="py-1 w-1/5">{x.psychologist}</TableCell>
                <TableCell className="py-1 w-1/5">{x.patient}</TableCell>
                <TableCell className="py-1 w-1/5">
                  {x.needsCheckin
                    ? t("case-load-manager-admin-panel-kpi-details-table-yes")
                    : t("case-load-manager-admin-panel-kpi-details-table-no")}
                </TableCell>
                <TableCell className="py-1 w-1/5">
                  {x.reasonForNoCheckin}
                </TableCell>
                <TableCell className="py-1 min-w-[150px]">
                  <div className="flex justify-center items-center">
                    {x.hasChattedWith ? (
                      <div className="w-4 h-4">
                        <CheckCircle className="w-4 h-4" />
                      </div>
                    ) : (
                      <div className="w-4 h-4 text-red-400">
                        <CircleOff className="w-4 h-4" />
                      </div>
                    )}
                  </div>
                </TableCell>
                <TableCell
                  className={`py-1 min-w-[200px] ${
                    x.hasChattedWith && !x.hasCreatedChatAppointment
                      ? "bg-red-100 dark:bg-red-900 text-red-500 dark:text-red-600"
                      : ""
                  }`}
                >
                  <div className="flex justify-center items-center">
                    {x.hasCreatedChatAppointment ? (
                      <div className="w-4 h-4">
                        <CheckCircle className="w-4 h-4" />
                      </div>
                    ) : (
                      <div className="w-4 h-4">
                        <CircleOff className="w-4 h-4" />
                      </div>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
};
