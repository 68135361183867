import { useTranslation } from "react-i18next";
import { Input } from "../../../components/ui/input";

export const SearchTermInput = ({
  searchTerm,
  setSearchTerm,
}: {
  searchTerm: string;
  setSearchTerm: (newSearchTerm: string) => void;
}) => {
  const { t } = useTranslation();

  return (
    <Input
      className="min-w-[12rem]"
      placeholder={"Search..."}
      value={searchTerm}
      onChange={(event) => setSearchTerm(event.target.value)}
    />
  );
};
