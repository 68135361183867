import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import {
  Command,
  CommandInput,
  CommandList,
} from "../../../../components/ui/command";
import { Button } from "../../../../components/ui/button";
import { Plus, Save } from "lucide-react";
import { toast } from "sonner";
import {
  useAddPatientManually,
  useSearchPatients,
  useGetPatientRemarks,
} from "../hooks";
import { useState } from "react";
import { useDebounce } from "use-debounce";
import { Loader } from "./loader";
import { EmptyResult } from "./emptyResult";
import { PopulatedResult } from "./populatedResult";
import { SelectablePatient } from "../types";
import { PatientRemarks } from "./remarks";
import { useTranslation } from "react-i18next";

type AddClientButtonProps = {
  open: boolean;
  onOpenChange: (newValue: boolean) => void;
  isInPast: boolean;
};

export const AddClientButton = ({
  open,
  onOpenChange,
  isInPast,
}: AddClientButtonProps) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 500);
  const [selectedPatient, setSelectedPatient] =
    useState<SelectablePatient | null>(null);

  const { data: patients, isLoading } = useSearchPatients(debouncedSearchTerm);
  const { data: patientRemarks, isLoading: isLoadingPatientRemarks } =
    useGetPatientRemarks(selectedPatient?.patientId);

  const { mutate: save, isLoading: isSaving } = useAddPatientManually();

  return (
    <Dialog
      open={open}
      onOpenChange={(newValue) => {
        setSelectedPatient(null);
        setSearchTerm("");
        onOpenChange(newValue);
      }}
    >
      <Button
        className="flex items-center gap-2 min-w-40"
        onClick={() => {
          if (!isInPast) {
            onOpenChange(true);
          }
        }}
        disabled={isInPast}
      >
        <Plus className="w-5 h-5" />
        <p>{t("case-load-manager-add-new-patient-button-label")}</p>
      </Button>
      <DialogContent className="p-8">
        <DialogHeader>
          <DialogTitle className="text-2xl">
            {t("case-load-manager-add-new-patient-button-label")}
          </DialogTitle>
          <DialogDescription>
            {t("case-load-manager-add-new-patient-dialog-desc")}
          </DialogDescription>
        </DialogHeader>
        <Command>
          <CommandInput
            placeholder={t(
              "case-load-manager-add-new-patient-search-placeholder"
            )}
            value={searchTerm}
            onValueChange={(newValue) => setSearchTerm(newValue)}
            autoFocus
          />
          <CommandList className="bg-slate-50/50 min-h-[20rem]">
            <Loader isLoading={isLoading} />
            <EmptyResult
              isLoading={isLoading}
              searchTerm={searchTerm}
              patients={patients}
            />
            <PopulatedResult
              patients={patients}
              setSelectedPatient={(patient) => {
                setSelectedPatient(patient);
                setSearchTerm("");
              }}
            />
          </CommandList>
        </Command>
        {selectedPatient && (
          <div className="text-xs">
            <p>
              {t("case-load-manager-add-new-patient-selection-title")}{" "}
              <span className="font-bold">{selectedPatient.name}</span>
            </p>
            <PatientRemarks
              isLoading={isLoadingPatientRemarks}
              remarks={patientRemarks ?? []}
            />
          </div>
        )}
        <Button
          className="w-full mt-2 flex items-center gap-2"
          disabled={
            selectedPatient === null || isSaving || isLoadingPatientRemarks
          }
          onClick={async () => {
            onOpenChange(false);

            await save({ patientId: selectedPatient!.patientId });

            toast(
              t("case-load-manager-add-new-patient-confirmation-toast-title"),
              {
                description: t(
                  "case-load-manager-add-new-patient-confirmation-toast-desc",
                  {
                    name: selectedPatient?.name,
                  }
                ),
              }
            );

            setSelectedPatient(null);
          }}
        >
          {isSaving ? (
            <div>{t("case-load-manager-saving")}</div>
          ) : (
            <>
              <Save className="w-4 h-4" />
              <p>{t("case-load-manager-save")}</p>
            </>
          )}
        </Button>
      </DialogContent>
    </Dialog>
  );
};
