import { getSimpleFormattedDate } from '../../../../utils/dateUtils';
import { Explanation } from './types';

type ExplanationsProps = {
  data: Explanation[];
};

export const Explanations = ({ data }: ExplanationsProps) => {
  // Group explanations by periodNumber
  const groupedData = data.reduce((acc, curr) => {
    const period = curr.periodNumber || 0; // fallback to 0 if undefined
    if (!acc[period]) {
      acc[period] = [];
    }
    acc[period].push(curr);
    return acc;
  }, {} as Record<number, Explanation[]>);

  // Sort periods and sort items within each period
  const sortedPeriods = Object.entries(groupedData)
    .sort(([a], [b]) => Number(a) - Number(b))
    .map(([period, items]) => ({
      period: Number(period),
      items: items.sort((a, b) => (a.eventSequence || 0) - (b.eventSequence || 0)),
    }));

  return (
    <div className="mt-6 pt-6 border-t">
      <h1 className="text-xl font-bold">Explanation</h1>

      <div className="mt-2 max-h-[500px] overflow-y-scroll space-y-4 border rounded p-3">
        {sortedPeriods.map(({ period, items }) => (
          <div key={period} className="space-y-2">
            <div className="bg-slate-100 p-2 rounded">
              <h2 className="text-lg">
                <span className="font-bold">Period {period + 1}</span> (
                {getSimpleFormattedDate(items[0].periodStartDateTime)} -{' '}
                {getSimpleFormattedDate(items[0].periodEndDateTime)})
              </h2>
            </div>
            <table className="w-full text-xs">
              <tbody>
                {items.map((d) => (
                  <tr key={d.id}>
                    <td className={`pl-4 ${d.isMilestone ? 'font-semibold' : ''}`}>{d.content}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </div>
  );
};
