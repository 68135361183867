import { useState } from "react";
import { MainListPatient } from "../types";
import { Info } from "lucide-react";
import { AiSummary } from "./aiSummary";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
} from "../../../../components/ui/dialog";
import { Button } from "../../../../components/ui/button";
import { Switch } from "../../../../components/ui/switch";
import { Label } from "../../../../components/ui/label";

type ViewPraktijkDataTreatmentPlanProps = {
  data: MainListPatient;
};

export const ViewPraktijkDataTreatmentPlan = ({
  data,
}: ViewPraktijkDataTreatmentPlanProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showMarkdown, setShowMarkdown] = useState(
    data?.epdTreatment?.aiTreatmentPlanSummary !== null
  );

  return (
    <>
      <div
        className={`${
          data?.epdTreatment?.treatmentPlan ? "visible" : "hidden"
        } pt-2 inline-flex items-start space-x-1 text-link cursor-pointer`}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <div className="h-4 w-4">
          <Info className="h-4 w-4" />
        </div>
        <p className="hover:underline -mt-[2px]">PraktijkData Treatment Plan</p>
      </div>

      <Dialog
        open={isOpen}
        onOpenChange={(newValue) => {
          setIsOpen(newValue);
        }}
      >
        <DialogContent className="p-8 min-w-[50vw] max-h-[80vh] overflow-y-auto">
          <DialogHeader>
            <DialogDescription>
              {showMarkdown ? (
                <div>
                  <AiSummary data={data} />
                </div>
              ) : (
                <div>
                  <h1 className="text-lg font-bold">Full Praktijkdata Plan</h1>

                  <p className="whitespace-pre-wrap pt-4">
                    {data?.epdTreatment?.treatmentPlan}
                  </p>
                </div>
              )}
              <div className="flex items-center space-x-2 pt-4 pd-4">
                <Switch
                  checked={showMarkdown}
                  onCheckedChange={setShowMarkdown}
                />
                <Label>Show Ai Summary</Label>
              </div>
            </DialogDescription>
            <div className="flex items-center justify-center w-full pt-4">
              <Button
                variant="outline"
                onClick={(e) => setIsOpen(false)}
                className="px-12"
              >
                Close
              </Button>
            </div>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </>
  );
};
