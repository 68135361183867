import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../../components/ui/table";
import { getSimpleFormattedDateTime } from "../../../../../utils/dateUtils";
import { CrmInsurerWaitListEmailReport } from "./types";

type WaitListTableProps = {
  data: CrmInsurerWaitListEmailReport[] | undefined;
  isLoading: boolean;
};

export const WaitListTable = ({ data, isLoading }: WaitListTableProps) => {
  const renderTableBody = () => {
    if (isLoading) {
      return (
        <TableRow>
          <TableCell colSpan={3}>Loading...</TableCell>
        </TableRow>
      );
    }

    if (!data || data.length == 0) {
      return (
        <TableRow>
          <TableCell colSpan={3}>No results found.</TableCell>
        </TableRow>
      );
    }

    return (
      <>
        {data!.map((d) => (
          <TableRow key={d.referralDate}>
            <TableCell>{getSimpleFormattedDateTime(d.referralDate)}</TableCell>
            <TableCell>{d.email}</TableCell>
            <TableCell>{d.insurer}</TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <Table className="border mt-6" limitHeight>
      <TableHeader>
        <TableRow>
          <TableHead>Referral Date</TableHead>
          <TableHead>Email</TableHead>
          <TableHead>Insurer</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>{renderTableBody()}</TableBody>
    </Table>
  );
};
