import { Dialog, DialogContent } from "../../../components/ui/dialog";
import { useEffect, useState } from "react";
import "stream-chat-react/dist/css/v2/index.css";
import {
  Call,
  StreamCall,
  StreamVideo,
  StreamVideoClient,
  User,
  StreamTheme,
} from "@stream-io/video-react-sdk";
import "@stream-io/video-react-sdk/dist/css/styles.css";
import { useGetChatCredentials } from "../hooks";
import { useIsEnabled } from "../../../feature-management/useIsEnabled";
import { ChatConversationDetails } from "../types";
import { initiateRingCall } from "./initiateRingCall";
import { CustomLayout } from "./customLayout";
import { InitializingCall } from "./initializingCall";

export const VideoCall = ({
  isFetchingChatDetails,
  chatDetails,
  isOpen,
  setIsOpen,
}: {
  isFetchingChatDetails: boolean;
  chatDetails: ChatConversationDetails;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { data: isCaseLoadManagerWireReplacementEnabled } = useIsEnabled(
    "EnableCaseLoadManagerWireReplacement"
  );
  const { data: chatCredentials } = useGetChatCredentials(
    isCaseLoadManagerWireReplacementEnabled ?? false
  );

  const apiKey =
    process.env.REACT_APP_STREAM_CHAT_API_KEY ?? "should-not-happen";

  const [client, setClient] = useState<StreamVideoClient | null>(null);
  const [call, setCall] = useState<Call | null>(null);

  const callId = "csb-" + Math.random().toString(16).substring(2);

  const useInitiateRingCall = initiateRingCall(
    chatDetails,
    client,
    callId,
    setCall
  );

  const initVideo = async () => {
    if (call) return;

    const user: User = {
      id: chatCredentials!.userId,
      name: chatCredentials!.userName,
    };

    setClient(
      new StreamVideoClient({
        apiKey,
        user,
        token: chatCredentials!.token,
        options: { logLevel: "debug" },
      })
    );
  };

  useEffect(() => {
    if (isFetchingChatDetails) return;
    if (!chatCredentials) return;

    // avoid screen flickering
    setTimeout(() => {
      initVideo();
    }, 1200);

    return () => {
      if (client) {
        client.disconnectUser();
        setClient(null);
      }
    };
  }, [chatCredentials, isFetchingChatDetails]);

  useEffect(() => {
    if (client != null && call == null) {
      useInitiateRingCall();
    }
  }, [client]);

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(newValue) => {
        setIsOpen(newValue);
      }}
    >
      <DialogContent
        hideClose
        onInteractOutside={(e) => {
          // do not accidentally close by clicking outside
          e.preventDefault();
        }}
        className="bg-slate-900 border-none ring-1 ring-slate-700 text-slate-500 p-8 min-w-[95vw] h-[95vh]"
      >
        <StreamTheme>
          {client == null || call == null ? (
            <InitializingCall
              call={call}
              onClose={() => {
                setCall(null);
                setIsOpen(false);
              }}
            />
          ) : (
            <StreamVideo client={client}>
              <StreamCall call={call}>
                <CustomLayout closeDialog={() => setIsOpen(false)} />
              </StreamCall>
            </StreamVideo>
          )}
        </StreamTheme>
      </DialogContent>
    </Dialog>
  );
};
