import { getReadableAlertText } from "../../../utils";
import { useResolveAlerts } from "../../../hooks";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../../components/ui/button";
import { CircleAlert, Loader2 } from "lucide-react";
import { PatientDetails } from "../../../types";

type SingleAlertProps = {
  data: PatientDetails;
};

export const SingleAlert = ({ data }: SingleAlertProps) => {
  const { t } = useTranslation();
  const alertText = getReadableAlertText(t, data.alerts[0].code);

  const { mutate: resolveAlerts, isLoading: isResolvingAlerts } =
    useResolveAlerts(() => {
      toast(t("case-load-manager-resolved-alert-success-toast-title"), {
        description: t("case-load-manager-resolved-alert-success-toast-desc"),
      });
    });

  return (
    <div className="mt-2 p-2 border border-dashed rounded-lg border-red-400 text-red-600 bg-red-50">
      <div className="flex items-center space-x-2 w-full">
        <div className="w-4 h-4">
          <CircleAlert className="w-4 h-4" />
        </div>
        <div className="flex items-center w-full justify-between space-x-2">
          <p>{alertText}</p>
          <Button
            variant="outline"
            className="flex items-center space-x- 2 px-10"
            disabled={isResolvingAlerts}
            onClick={async () =>
              await resolveAlerts({ caseLoadEntryId: data.id })
            }
          >
            {isResolvingAlerts && (
              <div className="w-4 h-4">
                <Loader2 className="w-4 h-4 animate-spin" />
              </div>
            )}
            <p>Resolve alert</p>
          </Button>
        </div>
      </div>
    </div>
  );
};
