const areas = {
  CREATE_DOSSIER_CARD: "CREATE_DOSSIER_CARD",
  EDIT_DOSSIER_CARD: "EDIT_DOSSIER_CARD",
  WIRE_CARD: "WIRE_CARD",
  TRANSACTIONS: "TRANSACTIONS",
  CALENDAR: "CALENDAR",
  ONBOARDING: "ONBOARDING",
  ONBOARDING_OVERVIEW: "ONBOARDING_OVERVIEW",
  ADMIN_TABLE: "ADMIN_TABLE",
  PSYCHOLOGIST_TABLE: "PSYCHOLOGIST_TABLE",
};
export default areas;
