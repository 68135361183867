import { Row } from "../../types";
import { TableCell } from "../../../../../components/ui/table";
import { useEditCurrentWeekIntakeDeviation } from "../../hooks";
import { IntakeValueSelector } from "../../intakeValueSelector";

type CurrentDeviationCellProps = {
  data: Row;
};
export const CurrentDeviationCell = ({ data }: CurrentDeviationCellProps) => {
  const { mutate: editCurrentWeekDeviation } =
    useEditCurrentWeekIntakeDeviation();

  if (data.status === "Active - not accepting new clients") {
    return <TableCell>0</TableCell>;
  }

  return (
    <TableCell>
      {data.isCurrentWeekPartOfIndefinite ||
      data.futureWeeks.some(
        (f) => new Date(f.start) < new Date() && f.isIndefinite
      ) ? (
        <div>
          <p>
            {data.isCurrentWeekPartOfIndefinite
              ? data.currentWeekNumberOfIntakes
              : data.futureWeeks.filter(
                  (f) => new Date(f.start) < new Date() && f.isIndefinite
                )[0].numberOfIntakes}
          </p>
          <p className="pt-1 text-xs opacity-80">
            Ongoing indefinite deviation.
          </p>
        </div>
      ) : (
        <IntakeValueSelector
          value={data.currentWeekNumberOfIntakes}
          onChange={async (newValue) => {
            await editCurrentWeekDeviation({
              epdEmployeeId: data.epdEmployeeId,
              numberOfIntakes: newValue,
            });
          }}
        />
      )}
    </TableCell>
  );
};
