import { Button } from "../../../../components/ui/button";
import { Textarea } from "../../../../components/ui/textarea";
import { Checkbox } from "../../../../components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { useState } from "react";
import { useUpdateOffboardingPatient } from "../hooks";
import { toast } from "sonner";
import { Loader2, Pencil } from "lucide-react";
import { useTranslation } from "react-i18next";

type UpdateOffboardingProps = {
  id: string;
  originalTreatmentSummary: string;
  originalSendRequestToGoogleReviewToPatient: boolean;
};

export const UpdateOffboarding = ({
  id,
  originalTreatmentSummary,
  originalSendRequestToGoogleReviewToPatient,
}: UpdateOffboardingProps) => {
  const { t } = useTranslation();

  const [treatmentSummary, setTreatmentSummary] = useState(
    originalTreatmentSummary
  );

  const [
    sendRequestToGoogleReviewToPatient,
    setSendRequestToGoogleReviewToPatient,
  ] = useState(originalSendRequestToGoogleReviewToPatient);

  const [isOpen, setIsOpen] = useState(false);

  const { mutate: save, isLoading: isSaving } = useUpdateOffboardingPatient(
    () => {
      toast("Saved successfully", {
        description: "You have updated the patient.",
      });
    }
  );

  return (
    <>
      <div
        className="inline-flex items-center space-x-2 pt-2 text-link cursor-pointer hover:underline text-xs"
        onClick={() => setIsOpen(true)}
      >
        <div className="h-3 w-3">
          <Pencil className="h-3 w-3" />
        </div>
        <p>{t("case-load-manager-offboarding-view-edit-treatment-summary")}</p>
      </div>
      <Dialog
        open={isOpen}
        onOpenChange={(newValue) => {
          setIsOpen(newValue);
        }}
      >
        <DialogContent className="p-8">
          <DialogHeader>
            <DialogTitle className="text-xl">
              {t(
                "case-load-manager-offboarding-treatment-summary-dialog-title"
              )}
            </DialogTitle>
          </DialogHeader>

          <Textarea
            rows={5}
            value={treatmentSummary}
            onChange={(e) => setTreatmentSummary(e.currentTarget.value)}
          />

          <div className="mt-2 flex items-center space-x-2">
            <Checkbox
              id="sendReviewLinks"
              checked={sendRequestToGoogleReviewToPatient}
              onCheckedChange={() =>
                setSendRequestToGoogleReviewToPatient(
                  !sendRequestToGoogleReviewToPatient
                )
              }
              disabled={isSaving}
            />
            <label
              htmlFor="sendReviewLinks"
              className="leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              {t("case-load-manager-offboarding-create-dialog-review-checkbox")}
            </label>
          </div>

          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => {
                setTreatmentSummary(originalTreatmentSummary);
                setIsOpen(false);
              }}
              className="w-1/2"
            >
              {t(
                "case-load-manager-offboarding-treatment-summary-dialog-cancel"
              )}
            </Button>
            <Button
              onClick={async (e) => {
                e.preventDefault();
                await save({
                  id,
                  treatmentSummary,
                  sendRequestToGoogleReviewToPatient,
                });
              }}
              className="w-1/2 flex items-center space-x-2"
            >
              {isSaving && <Loader2 className="w-4 h-4 animate-spin" />}
              <p>
                {t(
                  "case-load-manager-offboarding-treatment-summary-dialog-save"
                )}
              </p>
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
