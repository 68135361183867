import { ReferralDetails } from "../../types";

export const ReachoutCounter = ({
  referral,
}: {
  referral: ReferralDetails;
}) => {
  const successContent = "Successfully reached patient";
  const failedContent = "Could not reach patient";

  const reachOutAttemps = referral.milestones.filter((x) =>
    [successContent, failedContent].includes(x.content)
  );

  if (reachOutAttemps.length == 0) {
    return <></>;
  }

  const lastMissedReachOut = reachOutAttemps.toSorted((a, b) =>
    a.date > b.date ? -1 : 1
  )[0];

  const successCount = reachOutAttemps.filter(
    (x) => x.content == successContent
  ).length;

  const failedCount = reachOutAttemps.filter(
    (x) => x.content == failedContent
  ).length;

  const lastAttemptOn = new Date(lastMissedReachOut.date).toLocaleString([], {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <div className="w-full">
      <div className="w-full flex items-center space-x-2">
        <div className="w-1/2 flex items-center space-x-1 border-l-4 border-green-200 dark:border-green-900 bg-green-50 dark:bg-slate-800 rounded">
          <h1 className="pl-3 flex items-center justify-center p-1 text-lg font-bold ">
            {successCount}
          </h1>
          <p>Successful attempt(s)</p>
        </div>
        <div className="w-1/2 flex items-center space-x-1 border-l-4 border-red-200 dark:border-red-900 bg-red-50 dark:bg-slate-800 rounded">
          <h1 className="pl-3 flex items-center justify-center p-1 text-lg font-bold ">
            {failedCount}
          </h1>
          <p>Unsuccessful attempt(s)</p>
        </div>
      </div>
      <p className="mt-2 pt-2 border-t text-sm">
        Most recent reach-out attempt on {lastAttemptOn}
      </p>
    </div>
  );
};
