import { ColumnDef } from "@tanstack/react-table";
import { AutomaticBillHistory } from "../types";
import { getSimpleFormattedDateTime } from "../../../../utils/dateUtils";

export const columns: ColumnDef<AutomaticBillHistory>[] = [
  {
    accessorKey: "psychologist",
    header: "Psychologist",
    cell: ({ getValue }) => {
      return <>{getValue()}</>;
    },
  },
  {
    accessorKey: "patient",
    header: "Patient",
    cell: ({ getValue }) => {
      return <>{getValue()}</>;
    },
  },
  {
    accessorKey: "period",
    header: "Period",
    cell: ({ row }) => {
      return (
        <>
          {row.original.period.year}, week {row.original.period.week}
        </>
      );
    },
  },
  {
    accessorKey: "billCreatedOn",
    header: "Bill created on",
    cell: ({ getValue }) => {
      return <>{getSimpleFormattedDateTime(getValue() as string)}</>;
    },
  },
  {
    accessorKey: "appointmentOn",
    header: "Appointment on",
    cell: ({ getValue }) => {
      return <>{getSimpleFormattedDateTime(getValue() as string)}</>;
    },
  },
  {
    accessorKey: "createdBy",
    header: "Created by",
    cell: ({ getValue }) => {
      return <>{getValue()}</>;
    },
  },
];
