import { SchedulePreIntakeViaCaseLoadData } from "../../../../../customerRelationshipManager/referralDetails/praktijkdata/schedulePreIntakeViaCaseLoadData";

export const PreIntakeReportTable = () => {
  return (
    <SchedulePreIntakeViaCaseLoadData
      setSelectedPsychologistEpdId={() => {}}
      defaultLocationFilter={null}
      defaultTeamFilter={null}
      asCard={false}
    />
  );
};
