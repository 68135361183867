import { Input } from "../../../../../components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectGroup,
  SelectValue,
} from "../../../../../components/ui/select";

export const OngoingText = "Einer nach dem anderen";

export const Treatment = ({
  price,
  setPrice,
  numberOfSessions,
  isOngoingSessions,
  setNumberOfSessions,
}: {
  price: string | null;
  setPrice: (newValue: string) => void;
  numberOfSessions: number | null;
  isOngoingSessions: boolean;
  setNumberOfSessions: (
    newNumber: number | null,
    isOngoingSessions: boolean
  ) => void;
}) => {
  return (
    <div className="mt-4 w-full flex items-center space-x-8">
      <div className="w-1/2">
        <label className="block pb-1" htmlFor="price">
          Preis (€)
        </label>
        <Input
          id="price"
          value={price ?? ""}
          onChange={(event) => setPrice(event.target.value)}
        />
      </div>
      <div className="w-1/2">
        <p className="pb-1">Anzahl der Sitzungen</p>
        <Select
          value={isOngoingSessions ? OngoingText : numberOfSessions?.toString()}
          onValueChange={(value) => {
            const num = value === OngoingText ? null : parseInt(value);
            const isOngoing = value === OngoingText;
            setNumberOfSessions(num, isOngoing);
          }}
        >
          <SelectTrigger className="w-full font-bold">
            <SelectValue />
          </SelectTrigger>
          <SelectContent className="z-[99999999] max-h-[12rem] overflow-y-auto">
            <SelectGroup>
              {[
                OngoingText,
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
                "20",
              ].map((x) => {
                return <SelectItem value={x.toString()}>{x}</SelectItem>;
              })}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
    </div>
  );
};
