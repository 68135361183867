import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useGetMyCaseLoadWeekByWeekProgress } from "./../hooks";
import { useDarkMode } from "../../../../theme/useDarkMode";
import { Skeleton } from "../../../../components/ui/skeleton";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Info, XCircle } from "lucide-react";
import { WeekSelector } from "./weekSelector";

export const ProgressChart = () => {
  const [theme] = useDarkMode();
  const { t } = useTranslation();

  const [start, setStart] = useState<Date | undefined>(undefined);
  const [end, setEnd] = useState<Date | undefined>(undefined);

  const { data, isLoading, isError } = useGetMyCaseLoadWeekByWeekProgress(
    start,
    end
  );

  if (isError) {
    return (
      <div className="w-full p-20 bg-red-100 dark:bg-red-950 text-red-600 dark:text-red-300 flex flex-col items-center">
        <XCircle className="opacity-20 w-32 h-32" />
        <h1 className="text-2xl font-bold pt-4">
          {t("case-load-manager-something-went-wrong")}
        </h1>
        <p className="text-center">
          {t("case-load-manager-statistics-error-desc")}
          <br />
          {t("case-load-manager-generic-error-try-again-later")}
        </p>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col space-y-6">
      {isLoading ? (
        <div>
          <div className="w-full flex justify-between items-center">
            <div>
              <Skeleton className="w-[375px] h-10" />
              <Skeleton className="w-[500px] h-4 mt-1" />
            </div>
            <div className="flex space-x-2 items-center">
              <Skeleton className="w-[100px] h-6" />
              <Skeleton className="w-[135px] h-10" />
              <Skeleton className="w-[135px] h-10" />
            </div>
          </div>
          <Skeleton className="w-full h-[450px] mt-4" />
        </div>
      ) : (
        <div className="w-full flex flex-col items-end justify-end">
          <div className="w-full flex justify-between">
            <div>
              <h1 className="text-3xl font-bold">
                {t("case-load-manager-statistics-chart-title")}
              </h1>
              <p>{t("case-load-manager-statistics-chart-desc")}</p>
            </div>
            <WeekSelector
              start={start}
              setStart={setStart}
              end={end}
              setEnd={setEnd}
            />
          </div>

          <ResponsiveContainer width="100%" height={600}>
            <ComposedChart
              width={500}
              height={400}
              data={data?.map((d) => {
                return {
                  ...d,
                  totalNumberOfPatients:
                    d.totalNumberOfPatients -
                    d.totalNumberOfSupportedPatients -
                    d.totalNumberOfExcludedPatients,
                };
              })}
              margin={{
                top: 40,
                right: 0,
                bottom: 0,
                left: -20,
              }}
            >
              <CartesianGrid
                stroke={theme === "light" ? "#cbd5e1" : "#1e293b"}
                fill={theme === "light" ? "white" : "#020617"}
                strokeDasharray="3 3"
              />
              <XAxis dataKey="period" />
              <YAxis />
              {theme === "dark" ? (
                <Tooltip
                  contentStyle={{ backgroundColor: "#020617" }}
                  itemStyle={{ color: "#64748b" }}
                  cursor={{ fill: "transparent" }}
                />
              ) : (
                <Tooltip cursor={{ fill: "transparent" }} />
              )}

              <Legend />

              <Bar
                type="monotone"
                dataKey="totalNumberOfSupportedPatients"
                stackId="a"
                stroke="#16a34a"
                fill="#22c55e"
                name={t(
                  "case-load-manager-statistics-chart-supported-patients-label"
                )}
                opacity={0.7}
              />
              <Bar
                type="monotone"
                dataKey="totalNumberOfExcludedPatients"
                stackId="a"
                stroke="#ca8a04"
                fill="#eab308"
                name={t(
                  "case-load-manager-statistics-chart-excluded-patients-label"
                )}
                opacity={0.7}
              />
              <Bar
                type="monotone"
                dataKey="totalNumberOfPatients"
                stackId="a"
                stroke="#6b7280"
                fill="#9ca3af"
                name={t(
                  "case-load-manager-statistics-chart-non-supported-patients-label"
                )}
                opacity={0.3}
              />

              <Line
                type="monotone"
                dataKey="praktijkdataAppointments"
                name={t(
                  "case-load-manager-statistics-chart-praktijkdata-appointments-label"
                )}
                stroke={theme == "dark" ? "#f3f4f6" : "#4b5563"}
                strokeWidth={4}
              />
            </ComposedChart>
          </ResponsiveContainer>

          <div className="flex items-center justify-center space-x-1 pt-6 w-full">
            <div className="w-4 h-4">
              <Info className="w-4 h-4" />
            </div>
            <p className="text-sm">
              {t("case-load-manager-statistics-chart-praktijkdata-desc")}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
