import { action, makeObservable, observable, runInAction } from "mobx";
import axios from "axios";
import moment from "moment";
import patchDataFactory from "../utils/patchDataFactory";

class SubscriptionsStore {
  subscriptions = [];

  constructor() {
    makeObservable(this, {
      subscriptions: observable,
      fetchSubscriptions: action,
      editSubscription: action,
      deleteSubscription: action,
      createSubscription: action,
      finishSubscription: action,
    });
  }

  async fetchSubscriptions(dossierId) {
    const res = await axios.get(`dossiers/${dossierId}/subscriptions`);

    runInAction(() => {
      this.subscriptions = res.data;
    });
  }

  async deleteSubscription(subscription) {
    await axios
      .delete(`/subscriptions/${subscription.id}`)
      .then(() => this.fetchSubscriptions(subscription.dossierId));
  }

  async editSubscription(subscription) {
    const replacements = {
      Type: subscription.type,
      EmployeeId: subscription.employeeId,
      StartedAt: subscription.startedAt,
      FinishedAt: subscription.finishedAt,
    };

    await axios
      .patch(
        `/subscriptions/${subscription.id}`,
        patchDataFactory(replacements)
      )
      .then(() => this.fetchSubscriptions(subscription.dossierId));
  }

  async finishSubscription(subscription) {
    runInAction(() => {
      subscription.finishedAt = moment();
    });

    await this.editSubscription(subscription);
  }

  async createSubscription(subscription) {
    await axios
      .post(`dossiers/${subscription.dossierId}/subscriptions/`, {
        Type: subscription.type,
        EmployeeId: subscription.employeeId,
        StartedAt: subscription.startedAt,
        FinishedAt: subscription.finishedAt,
      })
      .then(() => this.fetchSubscriptions(subscription.dossierId));
  }
}

export default SubscriptionsStore;
