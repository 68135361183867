import { Button } from "../../../../components/ui/button";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../../../components/ui/alert-dialog";
import { useState } from "react";
import { useRegenerateCaseLoadData } from "../hooks";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";

export const GenerateCaseLoadData = () => {
  const { t } = useTranslation();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const { mutate, isLoading } = useRegenerateCaseLoadData(() => {
    setIsConfirmDialogOpen(false);
    toast(t("case-load-manager-admin-data-gen-save-success-toast-title"), {
      description: t(
        "case-load-manager-admin-data-gen-save-success-toast-desc"
      ),
    });
  });

  return (
    <div className="mt-12 p-8 border-t-2 border-red-300 dark:border-red-950 bg-red-50 dark:bg-slate-900">
      <h1 className="font-bold text-xl">
        {t("case-load-manager-admin-data-gen-title")}
      </h1>
      <p>{t("case-load-manager-admin-data-gen-desc")}</p>

      <AlertDialog
        open={isConfirmDialogOpen}
        onOpenChange={setIsConfirmDialogOpen}
      >
        <AlertDialogTrigger asChild>
          <Button variant="destructive" className="mt-6">
            {t("case-load-manager-admin-data-gen-regenerate-button")}
          </Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {t("case-load-manager-admin-data-gen-confirm-dialog-title")}
            </AlertDialogTitle>
            <AlertDialogDescription>
              <p>
                {t("case-load-manager-admin-data-gen-confirm-dialog-desc-1")}
              </p>
              <p className="pt-2">
                {t("case-load-manager-admin-data-gen-confirm-dialog-desc-2")}
              </p>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>
              {t(
                "case-load-manager-admin-data-gen-confirm-dialog-cancel-button"
              )}
            </AlertDialogCancel>
            <Button variant="destructive" onClick={async () => await mutate()}>
              {isLoading
                ? t(
                    "case-load-manager-admin-data-gen-confirm-dialog-saving-button"
                  )
                : t(
                    "case-load-manager-admin-data-gen-confirm-dialog-save-button"
                  )}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};
