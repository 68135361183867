import React, { useState, useRef, useEffect } from "react";
import { observer } from "mobx-react";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import { styled } from "@mui/system";
import { Card, CardContent, colors } from "@mui/material";
import { trackPromise } from "react-promise-tracker";
import CalendarToolbar from "./CalendarToolbar";
import CalendarHeader from "./CalendarHeader";
import CalendarFilter from "./CalendarFilter";
import useStores from "../../useStores";
import Loader from "../../shared/Loader";
import areas from "../../utils/constants/areas";
import { getCookie } from "../../utils/cookiesHelper";
import psychologistNameEnum from "../../utils/constants/psychologistNameEnum";
import { CalendarEvent, addHoverCardToEvents } from "./CalendarEvent";

const StyledRoot = styled("div")(({ theme }) => ({
  paddingTop: theme.spacing(0),
  paddingBottom: theme.spacing(0),
  "& .fc-timegrid-slot": {
    backgroundColor: "#44444466",
  },
  "& .fc-unthemed td": {
    borderColor: theme.palette.divider,
  },
  "& .fc-widget-header": {
    backgroundColor: colors.grey[50],
  },
  "& .fc-axis": {
    ...theme.typography.body2,
  },
  "& .fc-list-item-time": {
    ...theme.typography.body2,
  },
  "& .fc-list-item-title": {
    ...theme.typography.body1,
  },
  "& .fc-list-heading-main": {
    ...theme.typography.h6,
  },
  "& .fc-list-heading-alt": {
    ...theme.typography.h6,
  },
  "& .fc th": {
    borderColor: theme.palette.divider,
  },
  "& .fc-day-header": {
    ...theme.typography.subtitle2,
    fontWeight: 500,
    color: theme.palette.text.secondary,
    padding: theme.spacing(1),
    backgroundColor: colors.grey[50],
  },
  "& .fc-day-top": {
    ...theme.typography.body2,
  },
  "& .fc-highlight": {
    backgroundColor: colors.blueGrey[50],
  },
  "& .fc-list-empty": {
    ...theme.typography.subtitle1,
  },
  "& .fc-event": {
    borderWidth: 2,
  },
  "& .fc-event-main": {
    padding: 0,
    marginTop: "-2px",
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

function Calendar({ defaultFilters, onSelectTimeslot, allowEventSelection }) {
  const { calendarStore } = useStores();

  const calendarRef = useRef(null);
  const view = getCookie("calendarView") || "timeGridWeek";
  const [date, setDate] = useState(moment().toDate());
  const [calendarHeight, setCalendarHeight] = useState(
    localStorage.getItem("kalender-calendarHeight") || "1200px"
  );

  const handleCalendarHeightChange = (newVal) => {
    localStorage.setItem("kalender-calendarHeight", newVal);
    setCalendarHeight(newVal);
  };

  const [selectedEmployeeId, setSelectedEmployeeId] = useState(
    psychologistNameEnum.EMPTY
  );

  const [selectedLocations, setSelectedLocations] = useState(
    defaultFilters?.locations ?? []
  );

  useEffect(() => {
    trackPromise(
      Promise.all([
        calendarStore.fetchEmployees(),
        calendarStore.fetchTimeslots(),
        calendarStore.fetchTags(),
      ]),
      areas.CALENDAR
    );
  }, [calendarStore, calendarRef]);

  const handleDateToday = () => {
    const calendarApi = calendarRef.current.getApi();

    calendarApi.today();
    setDate(calendarApi.getDate());
  };

  const handleDatePrev = () => {
    const calendarApi = calendarRef.current.getApi();

    calendarApi.prev();
    setDate(calendarApi.getDate());
  };

  const handleDateNext = () => {
    const calendarApi = calendarRef.current.getApi();

    calendarApi.next();
    setDate(calendarApi.getDate());
  };

  const handleChangeEmployees = (chosenEmployeeId) => {
    if (
      chosenEmployeeId === psychologistNameEnum.EMPTY ||
      chosenEmployeeId === psychologistNameEnum.NONE
    ) {
      setSelectedEmployeeId(psychologistNameEnum.EMPTY);
    } else {
      setSelectedEmployeeId(chosenEmployeeId);

      const firstFreeSlot = calendarStore.getFirstFreeSlot(chosenEmployeeId);

      if (firstFreeSlot) {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.gotoDate(firstFreeSlot);
        setDate(calendarApi.getDate());
      }
    }
  };

  const handleChangeLocations = (chosenLocations) => {
    setSelectedLocations(chosenLocations);
  };

  const getEventsToDisplay = () => {
    let allEvents = calendarStore.selectedTimeslots;

    if (allEvents?.length == 0) {
      return [];
    }

    if (calendarRef.current != null) {
      // rather than rendering all events (which can be up to 16-20k),
      // we can reduce the number, by only rendering the events that are "nearish" to the current date
      // eg. they start at most 5 days before, and end at most 5 days after the current date
      const calendarApi = calendarRef.current.getApi();

      const currentDate = new Date(calendarApi.getDate());

      let startDate = new Date(currentDate);
      startDate.setDate(currentDate.getDate() - 5);

      let endDate = new Date(currentDate);
      endDate.setDate(currentDate.getDate() + 5);

      allEvents = allEvents.filter(
        (x) => new Date(x.start) >= startDate && new Date(x.end) <= endDate
      );
    }

    if (selectedEmployeeId) {
      allEvents = filterBySelectedEmployee(allEvents);
    }

    if (selectedLocations.length > 0) {
      allEvents = filterBySelectedLocations(allEvents);
    }

    return allEvents;
  };

  const filterBySelectedEmployee = (timeslots) => {
    if (!selectedEmployeeId) {
      // no need to filter employee
      return timeslots;
    } else {
      // filter by selected employee ID
      return timeslots.filter(
        (timeslot) => timeslot.employee?.id === selectedEmployeeId
      );
    }
  };

  const filterBySelectedLocations = (timeslots) => {
    if (selectedLocations == undefined || selectedLocations.length === 0) {
      return timeslots;
    }

    return timeslots.filter((timeslot) =>
      selectedLocations.some((location) =>
        timeslot.location?.name?.includes(location)
      )
    );
  };

  return (
    <Loader
      area={areas.CALENDAR}
      className="min-h-[70vh] flex items-center justify-center text-2xl animate-pulse opacity-80"
    >
      <StyledRoot>
        <CalendarHeader date={date} />
        <CalendarFilter
          defaultFilters={defaultFilters}
          handleChangeEmployees={handleChangeEmployees}
          handleChangeLocations={handleChangeLocations}
          getDate={() => {
            return date;
          }}
        />
        <StyledCard>
          <CardContent>
            <CalendarToolbar
              date={date}
              onDateNext={handleDateNext}
              onDatePrev={handleDatePrev}
              onDateToday={handleDateToday}
              view={view}
              calendarHeight={calendarHeight}
              onChangeCalendarHeight={handleCalendarHeightChange}
            />
            <FullCalendar
              height={calendarHeight}
              initialDate={date}
              initialView={view}
              slotMinTime="08:30:00"
              slotMaxTime="21:00:00"
              expandRows
              eventDidMount={(info) => {
                addHoverCardToEvents(calendarStore, info, date);
              }}
              events={getEventsToDisplay()}
              headerToolbar
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                listPlugin,
                interactionPlugin,
              ]}
              eventContent={(eventInfo) => (
                <CalendarEvent
                  allowEventSelection={allowEventSelection}
                  eventInfo={eventInfo}
                  date={date}
                  onSelectTimeslot={onSelectTimeslot}
                />
              )}
              ref={calendarRef}
              rerenderDelay={10}
              selectable
              locale="nl"
              weekends={false}
              firstDay={1} // Monday as the first day of the week
              noEventsContent="Geen afspraak mogelijkheid gevonden."
            />
          </CardContent>
        </StyledCard>
        <p className="font-bold pt-6">
          The timeslots you see above, are the timeslots during which a
          consultation room psychologist (SKP) is available for an intake.
        </p>
        <p className="font-bold pt-2">
          A psychologist is available for an intake in a timeslot if:
        </p>
        <ol className="list-decimal list-inside">
          <li>The timeslot is in their rooster in Praktijkdata</li>
          <li>The timeslot is not blocked by:</li>
          <ul className="pl-4 list-disc list-inside">
            <li>A consultation</li>
            <li>Vacation/leave</li>
            <li>MDO</li>
            <li>Werkbegeleiding </li>
            <li>Management taken</li>
          </ul>
          <li>
            There is not an intake immediately before or after the timeslot
          </li>
          <li>
            The psychologist has not reached their ‘max number of intakes per
            day’*
          </li>
          <li>
            It should be noted that these appointment types are seen as
            available:
          </li>
          <ul className="pl-4 list-disc list-inside">
            <li>Interne afspraken</li>
            <li>
              Reservering lopende cliënten (as the algorithm takes account of
              consults that still need to be scheduled)
            </li>
          </ul>
        </ol>
        <p className="font-bold pt-2">* Max number of intakes per day</p>
        <p className="pl-4">
          This defines how many intakes a psychologist can have per day
        </p>
        <p className="pl-4">The default is 3</p>
        <p className="pl-4">
          Potential deviations to the default are administered by psychologist
          by Matching team leads
        </p>

        <p className="font-bold pt-2">The assumptions of the algorithm:</p>
        <p className="pl-4">Average number of sessions per treatment = 8</p>
        <p className="pl-4">Time between sessions = once every 2 weeks</p>
        <p className="pl-4">
          Probability of intake turning into treatment = 90%
        </p>
        <p className="pl-4">
          Probability of completing treatment if 1 session after an intake has
          taken place = 100%
        </p>
      </StyledRoot>
    </Loader>
  );
}

export default observer(Calendar);
