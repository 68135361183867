import React, { useEffect } from "react";
import moment from "moment";
import { styled } from "@mui/system";
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import { observer } from "mobx-react";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import useStores from "../../useStores";
import TableRowPointer from "../../shared/TableRowPointer";
import getInitials from "../../utils/getInitials";
import dossierType from "../../utils/constants/dossierType";
import getDossierIdentifier from "../../utils/getDossierIdentifier";

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: 0,
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

const StyledCardActions = styled(CardActions)(() => ({
  justifyContent: "flex-end",
}));

const DossierGgzsTable = () => {
  const navigate = useNavigate();
  const { dossiersStore } = useStores();

  useEffect(() => {
    dossiersStore.setType(dossierType.GGZ);
  }, [dossiersStore]);

  const handlePageChange = (event, changedPage) => {
    dossiersStore.setPage(changedPage);
  };

  return (
    <Card>
      <StyledCardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Naam</TableCell>
              <TableCell>Cliënt nr.</TableCell>
              <TableCell>Datum aangemaakt</TableCell>
              <TableCell>Datum afgerond</TableCell>
              <TableCell align="right">Acties</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dossiersStore.dossiers.map((dossier) => (
              <TableRowPointer
                hover
                key={dossier.id}
                onClick={() =>
                  navigate(
                    `/patient/${dossier.patient.id}/${dossierType.GGZ}/${dossier.id}`
                  )
                }
              >
                <TableCell>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <StyledAvatar>
                      {getInitials(
                        `${dossier.patient.firstName} ${dossier.patient.lastName}`
                      )}
                    </StyledAvatar>
                    <p>{`${dossier.patient.firstName} ${dossier.patient.lastName}`}</p>
                  </div>
                </TableCell>
                <TableCell>{getDossierIdentifier(dossier)}</TableCell>
                <TableCell>{moment(dossier.createdAt).format("LLL")}</TableCell>
                <TableCell>
                  {dossier.completedAt &&
                    moment(dossier.completedAt).format("LLL")}
                </TableCell>
                <TableCell align="right">
                  <Button
                    color="primary"
                    component={Link}
                    size="small"
                    to={`/patient/${dossier.patient.id}/${dossierType.GGZ}/${dossier.id}`}
                    variant="outlined"
                  >
                    Bekijk
                  </Button>
                </TableCell>
              </TableRowPointer>
            ))}
          </TableBody>
        </Table>
      </StyledCardContent>
      <StyledCardActions>
        <TablePagination
          component="div"
          count={dossiersStore.count}
          page={dossiersStore.page}
          rowsPerPage={10}
          rowsPerPageOptions={[10]}
          onPageChange={handlePageChange}
        />
      </StyledCardActions>
    </Card>
  );
};

export default observer(DossierGgzsTable);
