import { useTranslation } from "react-i18next";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";

type NoCheckinReasonSelectorProps = {
  selectedJustificationReason: undefined | string;
  setSelectedJustificationReason: (newVal: string) => void;
  isSaving: boolean;
  multiplePatients?: boolean;
};

export const NoCheckinReasonSelector = ({
  selectedJustificationReason,
  setSelectedJustificationReason,
  isSaving,
  multiplePatients,
}: NoCheckinReasonSelectorProps) => {
  const { t } = useTranslation();

  const holidayEntry = multiplePatients
    ? t("case-load-manager-patient-no-checkin-reason-holiday-plural")
    : t("case-load-manager-patient-no-checkin-reason-holiday-singular");

  const longerTimeBetweenConsultationRoomSessionsEntry = t(
    "case-load-manager-patient-no-checkin-reason-longer-time-between-crp-session"
  );
  const noIntakeWithSkpEntry = t(
    "case-load-manager-patient-no-checkin-reason-no-intake-with-skp"
  );
  const preIntakeThisWeekEntry = t(
    "case-load-manager-patient-no-checkin-reason-preintake-this-week"
  );
  const treatmentMayNotStartEntry = t(
    "case-load-manager-patient-no-checkin-reason-treatment-may-not-start"
  );

  const noTreatmentPlanHasBeenSentYetEntry = t(
    "case-load-manager-patient-no-checkin-reason-no-treatment-plan-sent-yet"
  );

  const treatmentAlmostClosedEntry = t(
    "case-load-manager-patient-no-checkin-reason-almost-closed"
  );

  return (
    <Select
      disabled={isSaving}
      value={selectedJustificationReason}
      onValueChange={(newValue) => setSelectedJustificationReason(newValue)}
    >
      <SelectTrigger className="w-full">
        <SelectValue
          placeholder={t(
            "case-load-manager-patient-no-checkin-reason-placeholder"
          )}
        />
      </SelectTrigger>
      <SelectContent className="z-[9999999]">
        <SelectGroup>
          <SelectItem value={holidayEntry}>{holidayEntry}</SelectItem>
          <SelectItem value={longerTimeBetweenConsultationRoomSessionsEntry}>
            {longerTimeBetweenConsultationRoomSessionsEntry}
          </SelectItem>
          <SelectItem value={noIntakeWithSkpEntry}>
            {noIntakeWithSkpEntry}
          </SelectItem>
          <SelectItem value={preIntakeThisWeekEntry}>
            {preIntakeThisWeekEntry}
          </SelectItem>
          <SelectItem value={treatmentMayNotStartEntry}>
            {treatmentMayNotStartEntry}
          </SelectItem>
          <SelectItem value={noTreatmentPlanHasBeenSentYetEntry}>
            {noTreatmentPlanHasBeenSentYetEntry}
          </SelectItem>
          <SelectItem value={treatmentAlmostClosedEntry}>
            {treatmentAlmostClosedEntry}
          </SelectItem>
          <SelectItem value="other">
            {t("case-load-manager-patient-no-checkin-reason-other")}
          </SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
