import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import axios from "axios";
import { getEmployeeLastName } from "../utils/getEmployeeLastName";
import moment from "moment";
import { getPeriodDifference } from "../utils/predictionPeriodHelper";

class CalendarStore {
  timeslots = [];
  employees = [];
  allEmployees = [];
  locations = [];
  selectedTimeslots = [];
  selectedEmployees = [];
  tags = [];
  timeslotsInitialised = false;

  constructor() {
    makeObservable(this, {
      locations: observable,
      locationsSorted: computed,

      employees: observable,
      allEmployees: observable,

      tags: observable,
      updateTags: action,

      timeslots: observable,
      selectedTimeslots: observable,
      timeslotsInitialised: observable,

      initialiseSelectedTimeslots: action,
      setSelectedTimeslots: action,

      fetchTimeslots: action,
      fetchEmployees: action,
      fetchAllEmployees: action,
      fetchTags: action,
    });
  }

  getSortedEmployees(selectedEmployees) {
    // if (!Array.isArray(selectedEmployees)) {
    //   return [];
    // }

    const uniqueEmployees = Array.from(
      new Set(
        selectedEmployees.map((employee) =>
          JSON.stringify({
            id: employee.id,
            employeeId: employee.employeeId,
            epdId: employee.epdId,
            name: employee.name,
            averagePredictedOccupancy: employee.averagePredictedOccupancy,
            predictedOccupancyAtEarliestOpenSlot:
              employee.predictedOccupancyAtEarliestOpenSlot,
            earliestOpenSlot: employee.earliestOpenSlot,
          })
        )
      )
    ).map((employeeStr) => JSON.parse(employeeStr));

    return uniqueEmployees.sort((a, b) =>
      getEmployeeLastName(a?.name)?.localeCompare(getEmployeeLastName(b?.name))
    );
  }

  get locationsSorted() {
    return this.locations.slice().sort((a, b) => a.name.localeCompare(b.name));
  }

  async setSelectedTimeslots(selectedTimeslots) {
    this.selectedTimeslots = selectedTimeslots;
  }

  async setSelectedEmployees(employees) {
    this.selectedEmployees = employees;
  }

  async initialiseSelectedTimeslots() {
    this.selectedTimeslots = this.timeslots;
  }

  async fetchTags() {
    const response = await axios.get(`tags`);

    runInAction(() => {
      this.tags = response.data;
    });
  }

  async updateTags(employeeId, allTags) {
    await axios.post(`tags/${employeeId}`, allTags);
    await this.fetchTags();
    await this.fetchTimeslots();
  }

  async fetchAllEmployees() {
    const res = await axios.get(`epd-employees`);

    runInAction(() => {
      this.allEmployees = res.data;
    });
  }

  async fetchEmployees() {
    const res = await axios.get(`epd-employees-active`);

    runInAction(() => {
      this.employees = res.data;
      this.setSelectedEmployees(res.data);
    });
  }

  getColor(prediction, isForSupervisedIntake) {
    const predictedOccupancy = prediction?.predictedOccupancy;
    let color;

    if (isForSupervisedIntake) {
      color = "#2563eb";
    } else if (predictedOccupancy > 75) {
      color = "#f87171";
    } else if (predictedOccupancy > 50 && predictedOccupancy <= 75) {
      color = "#fbbf24";
    } else if (predictedOccupancy <= 50 || predictedOccupancy == null) {
      color = "#16a34a";
    }
    return color;
  }

  getPrediction(employeeId, periodNumber) {
    return this.employees.find(
      (e) => e.employeeId === employeeId && e.periodNumber === periodNumber
    );
  }

  async fetchTimeslots() {
    this.setSelectedTimeslots([]);
    const res = await axios.get(`timeslots`);

    let content = res.data.map((timeslot) => {
      const timeslotStart = moment(timeslot.start);

      const prediction = this.getPrediction(
        timeslot.employee.id,
        getPeriodDifference(new Date(), timeslotStart.toDate())
      );
      return {
        start: timeslot.start,
        end: timeslot.end,
        employee: timeslot.employee,
        location: timeslot.location,
        remainingAllowedIntakesOfDay: timeslot.remainingAllowedIntakesOfDay,
        isForSupervisedIntake: timeslot.isForSupervisedIntake,
        borderWidth: 10,
        borderColor: this.getColor(prediction, timeslot.isForSupervisedIntake),
        backgroundColor: "white",
      };
    });

    runInAction(() => {
      this.timeslots = content;
      this.selectedTimeslots = content;
      this.setSelectedTimeslots(content);
      this.timeslotsInitialised = true;
    });
  }

  getFirstFreeSlot(employeeId) {
    const now = new Date();
    const timeslotsOfPsy = this.timeslots
      .filter((t) => t.employee.id == employeeId && new Date(t.start) >= now)
      .toSorted((a, b) => new Date(a.start) - new Date(b.start));

    return timeslotsOfPsy.length == 0
      ? new Date()
      : new Date(timeslotsOfPsy[0].start);
  }
}

export default CalendarStore;
