import { NavBar } from "../NavBar/NavBar";
import { NavBarOpenStateContext } from "../NavBar/NavBarOpenStateContext";
import { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";

export const Main = ({ children }: { children: JSX.Element }) => {
  const isDesktop = useMediaQuery("(min-width:1200px)");

  const getDefaultIsSidebarOpen = () => {
    // on mobile devices, the sidebar is hidden by default
    if (!isDesktop) return false;

    // if the user has a preference, use it
    const hasPreference = localStorage.getItem("isSidebarVisible") != undefined;
    if (hasPreference)
      return localStorage.getItem("isSidebarVisible") === "true";

    // if the user has no preference, (and we know we're on a desktop screen by now), show the sidebar
    return true;
  };

  const [isNavBarOpen, setIsNavBarOpen] = useState(getDefaultIsSidebarOpen());

  useEffect(() => {
    setIsNavBarOpen(getDefaultIsSidebarOpen());
  }, [isDesktop]);

  return (
    <NavBarOpenStateContext.Provider value={{ isNavBarOpen, setIsNavBarOpen }}>
      <main
        className={`w-full bg-gradient-to-tl dark:from-gray-950 dark:to-gray-900 from-blue-400 via-blue-200 via-20% to-blue-100 text-foreground-muted grid ${
          isNavBarOpen ? "grid-cols-[18%_auto]" : "grid-cols-[60px_auto]"
        }`}
      >
        <NavBar />

        <div
          className={`flex-grow-1 w-full overflow-x-hidden bg-background rounded-tl-[50px] px-8 relative`}
        >
          <div>{children}</div>
        </div>
      </main>
    </NavBarOpenStateContext.Provider>
  );
};
