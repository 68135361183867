import { CrmNoChangeInDaysReportResult } from "../../../types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../../components/ui/table";

export const NoChangeInDaysReportTable = ({
  today,
  fridayOneWeekAgo,
  fridayTwoWeeksAgo,
  fridayThreeWeeksAgo,
}: {
  today: CrmNoChangeInDaysReportResult;
  fridayOneWeekAgo: CrmNoChangeInDaysReportResult | undefined;
  fridayTwoWeeksAgo: CrmNoChangeInDaysReportResult | undefined;
  fridayThreeWeeksAgo: CrmNoChangeInDaysReportResult | undefined;
}) => {
  return (
    <Table className="border">
      <TableHeader>
        <TableRow>
          <TableHead className="min-w-[200px]">Days without action</TableHead>
          <TableHead># referrals (today)</TableHead>
          {fridayOneWeekAgo && <TableHead># referrals (1 week ago)</TableHead>}
          {fridayTwoWeeksAgo && (
            <TableHead># referrals (2 weeks ago)</TableHead>
          )}
          {fridayThreeWeeksAgo && (
            <TableHead># referrals (3 weeks ago)</TableHead>
          )}
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow>
          <TableCell>0</TableCell>
          <TableCell>{today?.countOfZeroDays ?? 0}</TableCell>
          {fridayOneWeekAgo && (
            <TableCell>{fridayOneWeekAgo.countOfZeroDays}</TableCell>
          )}
          {fridayTwoWeeksAgo && (
            <TableCell>{fridayTwoWeeksAgo.countOfZeroDays}</TableCell>
          )}
          {fridayThreeWeeksAgo && (
            <TableCell>{fridayThreeWeeksAgo.countOfZeroDays}</TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell>1</TableCell>
          <TableCell>{today?.countOfOneDay ?? 0}</TableCell>
          {fridayOneWeekAgo && (
            <TableCell>{fridayOneWeekAgo.countOfOneDay}</TableCell>
          )}
          {fridayTwoWeeksAgo && (
            <TableCell>{fridayTwoWeeksAgo.countOfOneDay}</TableCell>
          )}
          {fridayThreeWeeksAgo && (
            <TableCell>{fridayThreeWeeksAgo.countOfOneDay}</TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell>2-7 (1 week)</TableCell>
          <TableCell>{today?.countOfTwoToSevenDays ?? 0}</TableCell>
          {fridayOneWeekAgo && (
            <TableCell>{fridayOneWeekAgo.countOfTwoToSevenDays}</TableCell>
          )}
          {fridayTwoWeeksAgo && (
            <TableCell>{fridayTwoWeeksAgo.countOfTwoToSevenDays}</TableCell>
          )}
          {fridayThreeWeeksAgo && (
            <TableCell>{fridayThreeWeeksAgo.countOfTwoToSevenDays}</TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell>8-14 (2 weeks)</TableCell>
          <TableCell>{today?.countOfEightToFourteenDays ?? 0}</TableCell>
          {fridayOneWeekAgo && (
            <TableCell>{fridayOneWeekAgo.countOfEightToFourteenDays}</TableCell>
          )}
          {fridayTwoWeeksAgo && (
            <TableCell>
              {fridayTwoWeeksAgo.countOfEightToFourteenDays}
            </TableCell>
          )}
          {fridayThreeWeeksAgo && (
            <TableCell>
              {fridayThreeWeeksAgo.countOfEightToFourteenDays}
            </TableCell>
          )}
        </TableRow>
        <TableRow>
          <TableCell>15+ (more than 2 weeks)</TableCell>
          <TableCell>{today?.countOfMoreThanFourteenDays ?? 0}</TableCell>
          {fridayOneWeekAgo && (
            <TableCell>
              {fridayOneWeekAgo.countOfMoreThanFourteenDays}
            </TableCell>
          )}
          {fridayTwoWeeksAgo && (
            <TableCell>
              {fridayTwoWeeksAgo.countOfMoreThanFourteenDays}
            </TableCell>
          )}
          {fridayThreeWeeksAgo && (
            <TableCell>
              {fridayThreeWeeksAgo.countOfMoreThanFourteenDays}
            </TableCell>
          )}
        </TableRow>
      </TableBody>
    </Table>
  );
};
