import { CrmWorkingViewReportPerCityCounts } from "../../../types";
import {
  getReadableLabelFromDataKey,
  colors,
  crmWorkingViewReportPerCityCounts,
} from "./utils";

export const CustomTooltip = ({
  active,
  payload,
  label,
}: {
  active: boolean;
  label: string;
  payload: {
    name: string;
    dataKey: CrmWorkingViewReportPerCityCounts;
    color: string;
    value: number;
  }[];
}) => {
  if (active && payload && payload.length > 0) {
    return (
      <div className="p-4 rounded shadow text-base min-w-[240px] bg-slate-900 text-slate-300">
        <h2 className="pb-1 font-bold text-slate-200">{label}</h2>

        {crmWorkingViewReportPerCityCounts.map((cp) => {
          return (
            <div className="flex items-center space-x-2" key={cp}>
              {/* tailwind's bg-[**magic value**] doesn't work here */}
              <div
                className="w-2 h-2 rounded-full"
                style={{ backgroundColor: colors[cp] }}
              />
              <p>
                {getReadableLabelFromDataKey(cp)}:{" "}
                <span className="pl-1 font-bold">
                  {payload.find((x: any) => x.dataKey === cp)?.value ?? 0}
                </span>
              </p>
            </div>
          );
        })}
      </div>
    );
  }

  return null;
};
