import { Row } from "../../types";
import { TableCell } from "../../../../../components/ui/table";

type LocationCellProps = {
  data: Row;
};

export const LocationCell = ({ data }: LocationCellProps) => {
  return (
    <TableCell>
      <p>{data.locations.join(", ")}</p>
      <p className="pt-px text-xs">
        Team{data.teams.length > 1 ? "s" : ""}: {data.teams.join(", ")}
      </p>
    </TableCell>
  );
};
