import axios from "axios";
import { useMutation, useQuery } from "react-query";
import {
  SelectableEpdAppointmentType,
  CurrentAppointmentPerCaseLoadAreaConfig,
  AppointmentConfigArea,
  CurrentAppointmentPerDossierTypeConfig,
  AutomaticBillHistory,
  CaseLoadDetailedDataOverview,
  CaseLoadDetailedDataFull,
} from "./types";
import {
  CaseLoadCurrentWeekProgressChart,
  CaseLoadWeekByWeekProgressChart,
} from "../clients/types";
import { OpToCrpPsychologistRatioType } from "../statistics/types";
import { queryClient } from "../../../react-query/provider";
import { refetchCaseLoadEntries } from "../clients/hooks";
import { Pagination } from "../../../shared/pagination";

export const useRegenerateCaseLoadData = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async () => {
      const res = await axios.post(
        `caseloadmanageradmin/run-data-maintenance`,
        {}
      );
      return res.data;
    },
    onSuccess: () => {
      refetchCaseLoadEntries();
      onSuccess();
    },
  });

  return mutation;
};

export const useGetAllEpdAppointmentTypes = () => {
  const query = useQuery<SelectableEpdAppointmentType[]>(
    ["case-load-manager", "admin", "all-epd-appointment-types"],
    async () => {
      const res = await axios.get(
        `caseloadmanageradmin/all-epd-appointment-types`
      );
      return res.data;
    },
    {
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useGetSavedAppointmentTypeToCaseLoadAreaConfig = (
  onSuccess: (data: CurrentAppointmentPerCaseLoadAreaConfig[]) => void
) => {
  const query = useQuery<CurrentAppointmentPerCaseLoadAreaConfig[]>(
    [
      "case-load-manager",
      "admin",
      "saved-appointment-type-to-case-load-area-config",
    ],
    async () => {
      const res = await axios.get(
        `caseloadmanageradmin/saved-appointment-type-to-case-load-area-config`
      );
      return res.data;
    },
    {
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onSuccess: onSuccess,
    }
  );

  return query;
};

export const useSaveAppointmentTypePerCaseLoadAreaConfig = (
  onSuccess: () => void
) => {
  const mutation = useMutation({
    mutationFn: async ({
      data,
    }: {
      data: {
        area: AppointmentConfigArea;
        appointmentTypeIds: string[];
      }[];
    }) => {
      const res = await axios.post(
        `caseloadmanageradmin/appointment-type-to-case-load-area-config`,
        {
          areas: data,
        }
      );
      return res.data;
    },
    onSuccess: () => {
      const queryKey = [
        "case-load-manager",
        "admin",
        "saved-appointment-type-to-case-load-area-config",
      ];
      queryClient.invalidateQueries(queryKey);
      queryClient.refetchQueries(queryKey);

      onSuccess();
    },
  });

  return mutation;
};

export const useGetSavedAppointmentTypeToDossierTypeConfig = (
  onSuccess: (data: CurrentAppointmentPerDossierTypeConfig[]) => void
) => {
  const query = useQuery<CurrentAppointmentPerDossierTypeConfig[]>(
    [
      "case-load-manager",
      "admin",
      "saved-appointment-type-to-dossier-type-config",
    ],
    async () => {
      const res = await axios.get(
        `caseloadmanageradmin/saved-appointment-type-to-dossier-type-config`
      );
      return res.data;
    },
    {
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      onSuccess: onSuccess,
    }
  );

  return query;
};

export const useSaveAppointmentTypePerDossierTypeConfig = (
  onSuccess: () => void
) => {
  const mutation = useMutation({
    mutationFn: async ({
      mapping,
    }: {
      mapping: {
        dossierTypeId: string;
        appointmentTypeId: string | null;
      }[];
    }) => {
      const res = await axios.post(
        `caseloadmanageradmin/appointment-type-to-dossier-type-config`,
        {
          mapping,
        }
      );
      return res.data;
    },
    onSuccess: () => {
      const queryKey = [
        "case-load-manager",
        "admin",
        "saved-appointment-type-to-dossier-type-config",
      ];
      queryClient.invalidateQueries(queryKey);
      queryClient.refetchQueries(queryKey);

      onSuccess();
    },
  });

  return mutation;
};

export const useGetCaseLoadQuickCardKpisTotalNumberOfPatients = (
  date: Date
) => {
  const query = useQuery<number>(
    [
      "case-load-manager",
      "admin",
      "kpis",
      "quick-cards",
      "total-number-of-patients",
      date.toISOString(),
    ],
    async () => {
      const res = await axios.get(
        `caseloadmanageradminreporting/quick-kpi/total-number-of-patients?date=${date.toISOString()}`
      );
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useGetCaseLoadQuickCardKpisTotalNumberOfSupportedPatients = (
  date: Date
) => {
  const query = useQuery<number>(
    [
      "case-load-manager",
      "admin",
      "kpis",
      "quick-cards",
      "total-number-of-supported-patients",
      date.toISOString(),
    ],
    async () => {
      const res = await axios.get(
        `caseloadmanageradminreporting/quick-kpi/total-number-of-supported-patients?date=${date.toISOString()}`
      );
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useGetCaseLoadQuickCardKpisTotalNumberOfExcludedPatients = (
  date: Date
) => {
  const query = useQuery<number>(
    [
      "case-load-manager",
      "admin",
      "kpis",
      "quick-cards",
      "total-number-of-excluded-patients",
      date.toISOString(),
    ],
    async () => {
      const res = await axios.get(
        `caseloadmanageradminreporting/quick-kpi/total-number-of-excluded-patients?date=${date.toISOString()}`
      );
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useGetCaseLoadQuickCardKpisTotalNumberOfChatMessage = (
  date: Date
) => {
  const query = useQuery<number>(
    [
      "case-load-manager",
      "admin",
      "kpis",
      "quick-cards",
      "total-number-of-chat-messages",
      date.toISOString(),
    ],
    async () => {
      const res = await axios.get(
        `caseloadmanageradminreporting/quick-kpi/total-number-of-chat-messages?date=${date.toISOString()}`
      );
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useGetCaseLoadQuickCardKpisTotalNumberOfPraktijkdataBills = (
  date: Date
) => {
  const query = useQuery<number>(
    [
      "case-load-manager",
      "admin",
      "kpis",
      "quick-cards",
      "total-number-of-praktijkdata-bills",
      date.toISOString(),
    ],
    async () => {
      const res = await axios.get(
        `caseloadmanageradminreporting/quick-kpi/total-number-of-praktijkdata-bills?date=${date.toISOString()}`
      );
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useGetCaseLoadWeekProgressChart = (date: Date) => {
  const query = useQuery<CaseLoadCurrentWeekProgressChart>(
    [
      "case-load-manager",
      "admin",
      "kpis",
      "charts",
      "current-week-progress",
      date.toISOString(),
    ],
    async () => {
      const res = await axios.get(
        `caseloadmanageradminreporting/chart/current-week-progress?date=${date.toISOString()}`
      );
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useGetCaseLoadWeekByWeekProgressChart = (
  employeeEpdId: number | null
) => {
  const query = useQuery<CaseLoadWeekByWeekProgressChart[]>(
    [
      "case-load-manager",
      "admin",
      "kpis",
      "charts",
      "week-by-week-progress",
      employeeEpdId ?? "full-team",
    ],
    async () => {
      const url = employeeEpdId
        ? `caseloadmanageradminreporting/chart/week-by-week-progress?employeeEpdId=${employeeEpdId}`
        : `caseloadmanageradminreporting/chart/week-by-week-progress`;

      const res = await axios.get(url);
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useGetCaseLoadWeekByWeekProgressChartEmployeeFilters = () => {
  const query = useQuery<{ employeeEpdId: number; name: string }[]>(
    [
      "case-load-manager",
      "admin",
      "kpis",
      "charts",
      "week-by-week-progress",
      "employee-filters",
    ],
    async () => {
      const res = await axios.get(
        `caseloadmanageradminreporting/chart/week-by-week-progress/employees`
      );
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useGetCaseLoadDetailedDataOverview = (date: Date) => {
  const query = useQuery<CaseLoadDetailedDataOverview[]>(
    [
      "case-load-manager",
      "admin",
      "kpis",
      "detailed-data",
      "overview",
      date.toISOString(),
    ],
    async () => {
      const res = await axios.get(
        `caseloadmanageradminreporting/detailed-data/overview?date=${date.toISOString()}`
      );
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useGetCaseLoadDetailedDataFull = (date: Date) => {
  const query = useQuery<CaseLoadDetailedDataFull[]>(
    [
      "case-load-manager",
      "admin",
      "kpis",
      "detailed-data",
      "full",
      date.toISOString(),
    ],
    async () => {
      const res = await axios.get(
        `caseloadmanageradminreporting/detailed-data/full?date=${date.toISOString()}`
      );
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useGetTeamChatToConsultationRoomAppointmentRatio = () => {
  const query = useQuery<OpToCrpPsychologistRatioType[]>(
    ["case-load-manager", "admin", "kpis", "op-to-skp-ratio"],
    async () => {
      const res = await axios.get(
        `caseloadmanageradminreporting/full-team-chat-to-consultation-room-appointment-ratio`
      );
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useGetAutomaticBillHistory = (
  selectedPsy: string | null,
  clientEpdId: number | null,
  skip: number
) => {
  const query = useQuery<{
    data: AutomaticBillHistory[];
    pagination: Pagination;
  }>(
    [
      "case-load-manager",
      "admin",
      "automatic-bill-history",
      selectedPsy ?? "full-team",
      clientEpdId ?? "no-client-filter",
      skip.toString(),
    ],
    async () => {
      let url = new URL(
        `${window.location.protocol}//${window.location.host}/api/automaticbillingadminreporting/history`
      );
      url.searchParams.append("skip", skip?.toString());

      if (selectedPsy)
        url.searchParams.append("employeeEpdId", selectedPsy.toString());

      if (clientEpdId)
        url.searchParams.append("clientEpdId", clientEpdId.toString());

      const res = await axios.get(url.toString());
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};
