import { Loader2 } from "lucide-react";
import { GetWeeklyPatientReferralStatisticsQueryResponse } from "../../../../types";

export const OpenClientsCard = ({
  data,
  isLoading,
}: {
  data: GetWeeklyPatientReferralStatisticsQueryResponse | undefined;
  isLoading: boolean;
}) => {
  return (
    <div className="rounded-lg border p-4 inline-block w-1/5">
      <p className="text-xs">Open clients</p>
      <h1 className="font-bold text-2xl">
        {isLoading ? (
          <Loader2 className="w-6 h-6 mt-1 opacity-70 animate-spin" />
        ) : (
          data?.openClients || 0
        )}
      </h1>
    </div>
  );
};
