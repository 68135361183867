import { Dispatch, SetStateAction } from "react";
import { Input } from "../../../../components/ui/input";

export const Filters = ({
  searchTerm,
  setSearchTerm,
}: {
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
}) => {
  return (
    <div className="w-full flex items-center space-x-6">
      <Input
        className="w-96"
        placeholder="Search by client numbers..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
    </div>
  );
};
