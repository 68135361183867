import { Plus } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import { useState } from "react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandList,
  CommandItem,
} from "../../../components/ui/command";
import { ChatConversationDetails } from "../../streamChat/types";
import { useCreateFolder } from "./hooks";
import { toast } from "sonner";

type CreateNewFolderProps = {
  conversations: ChatConversationDetails[];
};

export const CreateNewFolder = ({ conversations }: CreateNewFolderProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [selectedConversationIds, setSelectedConversationIds] = useState<
    string[]
  >([]);

  const { mutate: createFolder, isLoading: isCreatingFolder } = useCreateFolder(
    () => {
      toast("Folder created", {
        description: "Successfully created a new folder.",
      });

      setFolderName("");
      setSelectedConversationIds([]);

      setIsDialogOpen(false);
    }
  );

  return (
    <>
      <div
        className="mt-4 inline-flex items-center space-x-2 text-link cursor-pointer"
        onClick={() => setIsDialogOpen(true)}
      >
        <div className="w-4 h-4">
          <Plus className="w-4 h-4" />
        </div>
        <p className="hover:underline">Create new folder</p>
      </div>
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="p-8">
          <DialogHeader>
            <DialogTitle className="text-xl">Create new folder</DialogTitle>

            <div className="mt-6">
              <label
                htmlFor="folder-name"
                className="block text-sm font-medium"
              >
                Folder name:
              </label>
              <Input
                id="folder-name"
                autoFocus
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
                placeholder="Folder name..."
              />
            </div>

            <div className="pt-6">
              <p className="font-medium">
                Add clients to the folder (optional):
              </p>
              <div className="flex items-center justify-between w-full">
                <Command
                  filter={(value, search) => {
                    return value.toLowerCase().includes(search.toLowerCase())
                      ? 1
                      : 0;
                  }}
                >
                  <CommandInput placeholder="Search for clients..." />

                  <CommandList className="max-h-[240px]">
                    <CommandEmpty className="italic p-2 opacity-50">
                      No clients found.
                    </CommandEmpty>

                    <CommandGroup className="bg-slate-50/50 min-h-fit max-h-[12rem] overflow-y-scroll z-[99999999]">
                      {conversations.map((c) => (
                        <CommandItem
                          key={c.channelId}
                          className={`cursor-pointer text-muted-foreground hover:bg-muted flex items-center space-x-2 ${
                            selectedConversationIds.includes(c.channelId!)
                              ? "bg-blue-50 font-bold hover:bg-blue-50"
                              : ""
                          }`}
                          onSelect={(currentValue) => {
                            const clientName = currentValue.split(" (")[0];
                            console.log(clientName);
                            if (
                              selectedConversationIds.includes(c.channelId!)
                            ) {
                              setSelectedConversationIds(
                                selectedConversationIds.filter(
                                  (id) => id !== c.channelId!
                                )
                              );
                            } else {
                              setSelectedConversationIds([
                                ...selectedConversationIds,
                                c.channelId!,
                              ]);
                            }
                          }}
                        >
                          <p>
                            {c.clientName} ({c.epdPatientId}.{c.epdFileId})
                          </p>
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </div>
            </div>

            <div className="flex items-center justify-end w-full pt-4 space-x-2">
              <Button
                className="w-56"
                variant="outline"
                onClick={() => setIsDialogOpen(false)}
              >
                Cancel
              </Button>
              <Button
                disabled={!folderName || isCreatingFolder}
                className="w-56"
                onClick={async () => {
                  await createFolder({
                    name: folderName,
                    channelIds: selectedConversationIds,
                  });
                }}
              >
                Create
              </Button>
            </div>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </>
  );
};
