import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../components/ui/popover";
import { Button } from "../components/ui/button";
import { CalendarSearch } from "lucide-react";
import { Calendar } from "../components/ui/calendar";

export const WeekSelector = ({
  thisMonday,
  selectedDate,
  setSelectedDate,
  fullWidth,
}: {
  thisMonday: Date;
  selectedDate: Date;
  setSelectedDate: (newDate: Date) => void;
  fullWidth?: boolean;
}) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          className={`flex items-center gap-2 px-4 ${
            fullWidth ? "w-full" : "w-fit"
          }`}
          variant="outline"
        >
          <CalendarSearch className="w-5 h-5" />
          <p>Select week</p>
        </Button>
      </PopoverTrigger>
      <PopoverContent align="center" side="left" className="w-fit">
        <Calendar
          mode="single"
          showWeekNumber
          toDate={thisMonday}
          selected={selectedDate}
          onSelect={(_, selectedDay) => {
            setSelectedDate(selectedDay);
          }}
          disabled={(d) => {
            // mondays are OK to select
            if (d.getDay() == 1) return false;

            // rest is disabled
            return true;
          }}
        />
      </PopoverContent>
    </Popover>
  );
};
