import {
  flexRender,
  useReactTable,
  ColumnDef,
} from "@tanstack/react-table";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  DraggableCol,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import {
  ReferredPatient,
} from "../types";
import { TablePagination } from "../../../components/ui/table-pagination";
import { Pagination } from "../../../shared/pagination";
import { Error } from "../tableStates/error";
import { Loading } from "../tableStates/loading";
import { Fetching } from "../tableStates/fetching";

interface ReferralsTabProps {
  isError: boolean;
  isFetching: boolean;
  isLoading: boolean;
  onRowClick: (id: string) => void;
  mutableColumns: ColumnDef<ReferredPatient>[];
  onSetMutableColumns: (newColumns: ColumnDef<ReferredPatient>[]) => void;
  renderNoResults: () => JSX.Element;
  table: ReturnType<typeof useReactTable<ReferredPatient>>;
  pagination: Pagination | undefined;
  pageSize: number;
  skip: number;
  setSkip: (newSkip: number) => void;
}

export function ReferralsTab({
  isError,
  isFetching,
  isLoading,
  onRowClick,
  mutableColumns,
  onSetMutableColumns,
  renderNoResults,
  table,
  pagination,
  pageSize,
  skip,
  setSkip,
}: ReferralsTabProps) {
  return (
    <div className="rounded-md border rounded-lg">
      {isError ? (
        <Error />
      ) : (
        <div className="relative w-full">
          {isFetching && !isLoading && (
            <div className="absolute right-0 top-0 min-h-full mt-4 pr-2 z-50">
              <Fetching />{" "}
            </div>
          )}
          <DndProvider backend={HTML5Backend}>
            <Table style={{ width: table.getTotalSize() }}>
              <TableHeader>
                {table.getHeaderGroups().map((headerGroup) => {
                  const columnOrder = headerGroup.headers.map((header, index) => ({
                    id: header.column.id,
                    index,
                  }));

                  return (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        return (
                          <DraggableCol
                            key={header.id}
                            header={header}
                            headerGroup={headerGroup}
                            col={header.column}
                            columnOrder={columnOrder}
                            columns={mutableColumns}
                            setColumns={onSetMutableColumns}
                          />
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableHeader>
              <TableBody>
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    {table.getRowModel().rows?.length
                      ? table.getRowModel().rows.map((row) => {
                          return (
                            <TableRow
                              key={row.id}
                              data-state={row.getIsSelected() && "selected"}
                              onClick={() => onRowClick(row.original.id)}
                              className="cursor-pointer"
                            >
                              {row.getVisibleCells().map((cell) => (
                                <TableCell key={cell.id} size={cell.column.getSize()}>
                                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </TableCell>
                              ))}
                            </TableRow>
                          );
                        })
                      : renderNoResults()}
                  </>
                )}
              </TableBody>
            </Table>
          </DndProvider>
        </div>
      )}
      {!isError && (
        <TablePagination
          pagination={pagination}
          pageSize={pageSize}
          skip={skip}
          setSkip={setSkip}
          table={table}
        />
      )}
    </div>
  );
}
