import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import { observer } from "mobx-react";
import { trackPromise } from "react-promise-tracker";
import useStores from "../../useStores";
import areas from "../../utils/constants/areas";
import Loader from "../../shared/Loader";
import PsychologistsTableRow from "./PsychologistsTableRow";
import { EpdUser, UserTags } from "./types";

const PsychologistsTable = () => {
  const { calendarStore } = useStores();

  const tags = calendarStore.tags as UserTags[];

  useEffect(() => {
    trackPromise(
      Promise.all([
        calendarStore.fetchAllEmployees(),
        calendarStore.fetchTags(),
      ]),
      areas.PSYCHOLOGIST_TABLE
    );
  }, [calendarStore]);

  const onTagsSave = async (id: unknown, roles: unknown) => {
    await calendarStore.updateTags(id, roles);
  };

  const getEmployeeTags = (employee: EpdUser): UserTags => {
    const foundEntry = tags.filter((tag) => tag.employee.id === employee.id);

    if (foundEntry.length == 0) {
      return {
        employee,
        assignedTags: [],
      };
    }

    return foundEntry[0];
  };

  return (
    <Loader area={areas.PSYCHOLOGIST_TABLE}>
      <div className="border rounded mt-6">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead>Epd ID</TableHead>
              <TableHead className="text-right">Acties</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {calendarStore.allEmployees
              .toSorted((psychologistOne, psychologistTwo) =>
                psychologistOne.name > psychologistTwo.name
                  ? 1
                  : psychologistTwo.name > psychologistOne.name
                  ? -1
                  : 0
              )
              .map((employee) => (
                <PsychologistsTableRow
                  key={employee.epdId}
                  employee={employee}
                  employeeTags={getEmployeeTags(employee)}
                  saveTags={onTagsSave}
                />
              ))}
          </TableBody>
        </Table>
      </div>
    </Loader>
  );
};

export default observer(PsychologistsTable);
