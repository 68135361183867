export enum KopModelStatus {
  NotStarted = "NotYetStarted",
  Draft = "Draft",
  Sent = "Sent",
}

export enum KopModelLanguage {
  Dutch = "Dutch",
  English = "English",
}

export type KopModelFields = {
  language: KopModelLanguage;
  complaints: string | null;
  circumstances: string | null;
  personalStyle: string | null;
  requestForHelp: string | null;
  questionsForPreIntake: string | null;
  myCommentsForDiagnosisAndTreatment: string | null;
};
