import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";

export const IntakeValueSelector = ({
  value,
  onChange,
}: {
  value: number;
  onChange: (newVal: number) => void;
}) => {
  return (
    <Select
      value={value.toString()}
      onValueChange={(newValue) => onChange(Number(newValue))}
    >
      <SelectTrigger
        className={`w-[60px] h-[30px] ${
          value !== 3 &&
          "bg-yellow-200 dark:bg-yellow-950 text-yellow-700 dark:text-yellow-500 font-bold"
        }`}
      >
        <SelectValue />
      </SelectTrigger>
      <SelectContent className="z-[9999999]">
        <SelectGroup>
          <SelectItem value="0">0</SelectItem>
          <SelectItem value="1">1</SelectItem>
          <SelectItem value="2">2</SelectItem>
          <SelectItem value="3">3</SelectItem>
          <SelectItem value="4">4</SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
