import React from "react";
import MenuItem from "@mui/material/MenuItem";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Formik } from "formik";
import * as Yup from "yup";
import { observer } from "mobx-react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import Tooltip from "@mui/material/Tooltip";
import ConfirmDialog from "./ConfirmDialog";
import languageCodes from "../utils/constants/languageCodes";
import useStores from "../useStores";

const StyledDeleteOutlineIcon = styled(DeleteOutlineIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const EditPatient = () => {
  const [isDeleteModalOpen, setDeleteModalOpen] = React.useState(false);
  const { patientStore } = useStores();
  const { patient } = patientStore;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleDelete = () => {
    patientStore
      .deletePatient()
      .then(() => {
        setDeleteModalOpen(false);

        enqueueSnackbar("Cliënt verwijderd", { variant: "success" });

        navigate("/patients");
      })
      .catch(() => {
        enqueueSnackbar("Cliënt kon niet verwijderd worden", {
          variant: "error",
        });
      });
  };

  return (
    <Card>
      <Formik
        enableReinitialize
        initialValues={{
          firstName: patient.firstName,
          lastName: patient.lastName,
          email: patient.email,
          bsn: patient.bsn ? patient.bsn : "",
          languageCode: patient.language.languageCode,
          mobilePhoneNumber: patient.mobilePhoneNumber
            ? patient.mobilePhoneNumber
            : "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          patientStore.patchPatient(values).catch((error) => {
            enqueueSnackbar(error.response.data, { variant: "error" });
            setSubmitting(false);
          });
          // resetForm(); Don't reset form it gets filled again from the store using Formik enableReinitialize.
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string().email().required(),
          firstName: Yup.string().required(),
          lastName: Yup.string().required(),
          bsn: Yup.string()
            .nullable()
            .min(8, "Het burger service nummer moet minimaal 8 nummers hebben.")
            .max(
              9,
              "Het burger service nummer moet maximaal 9 nummers hebben."
            ),
          languageCode: Yup.string().required(),
          mobilePhoneNumber: Yup.string()
            .nullable()
            .matches(
              /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
              {
                message: "Please enter valid number.",
                excludeEmptyString: false,
              }
            ),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            dirty,
          } = props;
          return (
            <form autoComplete="off" noValidate>
              <CardHeader title="Cliënt bewerken" />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Voornaam"
                      margin="dense"
                      name="firstName"
                      onChange={handleChange}
                      required
                      value={values.firstName}
                      variant="outlined"
                      onBlur={handleBlur}
                      error={errors.firstName && touched.firstName}
                      helperText={
                        errors.firstName &&
                        touched.firstName &&
                        errors.firstName
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Achternaam"
                      margin="dense"
                      name="lastName"
                      onChange={handleChange}
                      required
                      value={values.lastName}
                      variant="outlined"
                      onBlur={handleBlur}
                      error={errors.lastName && touched.lastName}
                      helperText={
                        errors.lastName && touched.lastName && errors.lastName
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="E-mailadres"
                      margin="dense"
                      name="email"
                      onChange={handleChange}
                      required
                      value={values.email}
                      variant="outlined"
                      type="email"
                      onBlur={handleBlur}
                      error={errors.email && touched.email}
                      helperText={errors.email && touched.email && errors.email}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="BSN"
                      margin="dense"
                      name="bsn"
                      onChange={handleChange}
                      value={values.bsn}
                      variant="outlined"
                      onBlur={handleBlur}
                      error={errors.bsn && touched.bsn}
                      helperText={errors.bsn && touched.bsn && errors.bsn}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Mobiel telefoonnummer"
                      margin="dense"
                      name="mobilePhoneNumber"
                      onChange={handleChange}
                      value={values.mobilePhoneNumber}
                      variant="outlined"
                      type="tel"
                      onBlur={handleBlur}
                      error={
                        errors.mobilePhoneNumber && touched.mobilePhoneNumber
                      }
                      helperText={
                        errors.mobilePhoneNumber &&
                        touched.mobilePhoneNumber &&
                        errors.mobilePhoneNumber
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      select
                      label="Taal"
                      margin="dense"
                      name="languageCode"
                      onChange={handleChange}
                      required
                      value={values.languageCode}
                      variant="outlined"
                      onBlur={handleBlur}
                      error={errors.languageCode && touched.languageCode}
                      helperText={
                        errors.languageCode &&
                        touched.languageCode &&
                        errors.languageCode
                      }
                      InputLabelProps={{ shrink: true }}
                    >
                      {Object.keys(languageCodes).map((key) => (
                        <MenuItem
                          key={languageCodes[key]}
                          value={languageCodes[key]}
                        >
                          {languageCodes[key]}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6} />
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting || !dirty}
                  onClick={handleSubmit}
                >
                  Opslaan
                </Button>
                <Button
                  type="button"
                  className="outline"
                  onClick={handleReset}
                  disabled={isSubmitting || !dirty}
                >
                  Reset
                </Button>
              </CardActions>

              <Divider />
              <CardActions>
                <Tooltip
                  title={
                    patient.dossiers.length === 0
                      ? ""
                      : "Het is niet mogelijk een patient met bestaande dossiers te verwijderen."
                  }
                  arrow
                >
                  <span>
                    <Button
                      disabled={patient.dossiers.length > 0}
                      onClick={() => setDeleteModalOpen(true)}
                    >
                      <StyledDeleteOutlineIcon />
                      Verwijderen
                    </Button>
                  </span>
                </Tooltip>
              </CardActions>
            </form>
          );
        }}
      </Formik>

      <ConfirmDialog
        question={`Weet je zeker dat je cliënt ${patient.firstName} ${patient.lastName} wilt verwijderen?`}
        isOpen={isDeleteModalOpen}
        onConfirm={handleDelete}
        onCancel={() => setDeleteModalOpen(false)}
      />
    </Card>
  );
};

export default observer(EditPatient);
