import { ReferralDetails } from "../../types";
import { Separator } from "../../../../components/ui/separator";
import { CircleCheckBig, ExternalLink, TriangleAlert } from "lucide-react";
import { IWelcomeEmailSender } from "../../../dossierGgz/onboardingTab/iWelcome/iWelcomeEmailSender";
import { CrmPatientEntryPatientRegistrationStepTracker } from "../registrationStatusTracker/crmPatientEntryPatientRegistrationStepTracker";

const title = "Send iWelcome";

export const IWelcomeRow = ({
  referral,
  stepNumber,
}: {
  referral: ReferralDetails;
  stepNumber: number;
}) => {
  // awaiting patient approval
  if (
    referral.status !== "AwaitingIWelcomeEmailSend" &&
    referral.status !== "Closed"
  ) {
    return (
      <AwaitingPatientApprovalRow referral={referral} stepNumber={stepNumber} />
    );
  }

  if (
    referral.preIntakePraktijkDataEpdId == null ||
    referral.intakePraktijkDataEpdId == null
  ) {
    return (
      <PraktijkdataAppointmentCreationFailureRow
        referral={referral}
        stepNumber={stepNumber}
      />
    );
  }

  if (referral.status === "AwaitingIWelcomeEmailSend") {
    return <SendIWelcome referral={referral} stepNumber={stepNumber} />;
  }

  return <AlreadySentiWelcomeRow referral={referral} stepNumber={stepNumber} />;
};

const AwaitingPatientApprovalRow = ({
  referral,
  stepNumber,
}: {
  referral: ReferralDetails;
  stepNumber: number;
}) => {
  return (
    <div>
      <div className="flex items-center space-x-2">
        <CrmPatientEntryPatientRegistrationStepTracker
          referralId={referral.id}
          step="send-i-welcome"
        />
        <p className="text-lg font-medium leading-none">
          {stepNumber}. {title}
        </p>
      </div>
      <Separator className="my-1" />

      <p className="opacity-70">
        Please complete the previous steps, before sending out the iWelcome
        mail.
      </p>
    </div>
  );
};

const PraktijkdataAppointmentCreationFailureRow = ({
  referral,
  stepNumber,
}: {
  referral: ReferralDetails;
  stepNumber: number;
}) => {
  return (
    <div>
      <div className="flex items-center space-x-2">
        <CrmPatientEntryPatientRegistrationStepTracker
          referralId={referral.id}
          step="send-i-welcome"
          disabled
        />
        <p className="text-lg font-medium leading-none">
          {stepNumber}. {title}
        </p>
      </div>
      <Separator className="mt-1 mb-2" />

      <div className="bg-red-50 border border-red-100 p-3 rounded">
        <p className="text-red-500 bg-red-50">
          The iWelcome email cannot be sent until the issues with the PreIntake
          and Intake appointments listed above have been resolved.
        </p>
      </div>
    </div>
  );
};

const SendIWelcome = ({
  referral,
  stepNumber,
}: {
  referral: ReferralDetails;
  stepNumber: number;
}) => {
  return (
    <div>
      <div className="flex items-center space-x-2">
        <CrmPatientEntryPatientRegistrationStepTracker
          referralId={referral.id}
          step="send-i-welcome"
        />
        <p className="text-lg font-medium leading-none">
          {stepNumber}. {title}
        </p>
      </div>
      <Separator className="my-1" />

      <div className="w-full flex items-center space-x-2 pt-2">
        <div
          className="w-1/3 flex items-center space-x-1 text-link hover:underline cursor-pointer"
          onClick={() =>
            window.open(
              `patient/${referral.patientId}/DossierGgz/${referral.dossierId}/onboarding`,
              "_blank"
            )
          }
        >
          <div className="h-4 w-4">
            <ExternalLink className="w-4 h-4" />
          </div>
          <p>Dashboard dossier</p>
        </div>
        <div
          className="w-1/3 flex items-center space-x-1 text-link hover:underline cursor-pointer"
          onClick={() =>
            window.open(
              `https://start.praktijkdata.nl/app/clienten/${referral.epdPatientId}/dossiers/${referral.epdFileId}/aanmelding`,
              "_blank"
            )
          }
        >
          <div className="h-4 w-4">
            <ExternalLink className="w-4 h-4" />
          </div>
          <p>Praktijkdata dossier</p>
        </div>
        <div className="w-1/3 flex justify-end">
          <IWelcomeEmailSender
            disabled={!referral.email}
            text="Send"
            dossierId={referral.dossierId!}
            dossierType="DossierGgz"
            dossierHasReferralLetterAlready // true by definition, CRM's whole idea is to start from a referral letter
            crmReferral={referral}
          />
        </div>
      </div>
      <div
        className={`${
          referral.email
            ? "hidden"
            : "visible mt-4 rounded p-2 flex items-center space-x-2 bg-slate-100 text-slate-600"
        }`}
      >
        <div className="h-5 w-5">
          <TriangleAlert className="h-5 w-5" />
        </div>
        <p>
          Please specify the <span className="font-bold">email</span> in the
          'Personal details' tab, to be able to send the iWelcome emails. Go to
          the
        </p>
      </div>
    </div>
  );
};

const AlreadySentiWelcomeRow = ({
  referral,
  stepNumber,
}: {
  referral: ReferralDetails;
  stepNumber: number;
}) => {
  return (
    <div>
      <div className="flex items-center space-x-2">
        <CrmPatientEntryPatientRegistrationStepTracker
          referralId={referral.id}
          step="send-i-welcome"
        />
        <p className="text-lg font-medium leading-none">
          {stepNumber}. {title}
        </p>
      </div>
      <Separator className="my-1" />

      <div className="flex items-center space-x-2 opacity-70 text-xs">
        <div className="h-4 w-4">
          <CircleCheckBig className="h-4 w-4" />
        </div>
        <p>The iWelcome has been succesfully sent.</p>
      </div>

      <div className="w-full flex items-center space-x-2 pt-2">
        <div
          className="w-1/3 flex items-center space-x-1 text-link hover:underline cursor-pointer"
          onClick={() =>
            window.open(
              `patient/${referral.patientId}/DossierGgz/${referral.dossierId}/onboarding`,
              "_blank"
            )
          }
        >
          <div className="h-4 w-4">
            <ExternalLink className="w-4 h-4" />
          </div>
          <p>Dashboard dossier</p>
        </div>
        <div
          className="w-1/3 flex items-center space-x-1 text-link hover:underline cursor-pointer"
          onClick={() =>
            window.open(
              `https://start.praktijkdata.nl/app/clienten/${referral.epdPatientId}/dossiers/${referral.epdFileId}/aanmelding`,
              "_blank"
            )
          }
        >
          <div className="h-4 w-4">
            <ExternalLink className="w-4 h-4" />
          </div>
          <p>Praktijkdata dossier</p>
        </div>
        <div className="w-1/3 flex justify-end">
          <IWelcomeEmailSender
            text="Send again"
            dossierId={referral.dossierId!}
            dossierType="DossierGgz"
            dossierHasReferralLetterAlready // true by definition, CRM's whole idea is to start from a referral letter
            crmReferral={referral}
          />
        </div>
      </div>
    </div>
  );
};
