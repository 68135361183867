import axios from "axios";
import { useMutation } from "react-query";
import { queryClient } from "../../../react-query/provider";

export const refetchCaseLoadEntries = () => {
  [
    ["case-load-manager", "my-case-load"],
    ["case-load-manager", "my-progress"],
  ].map((qk) => {
    queryClient.invalidateQueries({
      queryKey: qk,
    });
    queryClient.refetchQueries({
      queryKey: qk,
    });
  });
};

export const useMarkNextConsultAsLast = (
  onSuccess: () => void,
  onError: () => void
) => {
  const mutation = useMutation({
    mutationFn: async ({
      currentWeekCaseLoadEntryId,
      treatmentSummary,
      sendRequestToGoogleReviewToPatient,
    }: {
      currentWeekCaseLoadEntryId: string;
      treatmentSummary: string;
      sendRequestToGoogleReviewToPatient: boolean;
    }) => {
      const res = await axios.post(
        `caseloadmanageroffboardingpatient/from-caseload/${currentWeekCaseLoadEntryId}`,
        {
          treatmentSummary,
          sendRequestToGoogleReviewToPatient,
        }
      );
      return res.data;
    },
    onSuccess: () => {
      refetchCaseLoadEntries();
      onSuccess();
    },
    onError: () => onError(),
  });

  return mutation;
};

export const useUpdateOffboardingPatient = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({
      id,
      treatmentSummary,
      sendRequestToGoogleReviewToPatient,
    }: {
      id: string;
      treatmentSummary: string;
      sendRequestToGoogleReviewToPatient: boolean;
    }) => {
      const res = await axios.post(`caseloadmanageroffboardingpatient/${id}`, {
        treatmentSummary,
        sendRequestToGoogleReviewToPatient,
      });
      return res.data;
    },
    onSuccess: () => {
      refetchCaseLoadEntries();
      onSuccess();
    },
  });

  return mutation;
};

export const useDeleteOffboardingData = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      const res = await axios.delete(`caseloadmanageroffboardingpatient/${id}`);
      return res.data;
    },
    onSuccess: () => {
      refetchCaseLoadEntries();
      onSuccess();
    },
  });

  return mutation;
};
