import { ChatConversationDetails } from "./types";

export const sortConversation = (
  a: ChatConversationDetails,
  b: ChatConversationDetails
) => {
  if (a.numberOfUnreadMessages == 0 && b.numberOfUnreadMessages > 0) {
    return 1;
  } else if (a.numberOfUnreadMessages > 0 && b.numberOfUnreadMessages == 0) {
    return -1;
  } else {
    return a.lastMessage!.sentOn < b.lastMessage!.sentOn ? 1 : -1;
  }
};

export const getReadableRole = (role: string) => {
  if (role === "client") return "Client";
  if (role === "online_psychologist") return "Online Psychologist";
  if (role === "consultation_room_psychologist")
    return "Consultation Room Psychologist";

  return "Psychologist";
};
