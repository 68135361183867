import { UnsavedGermanClient, GermanClientStatus } from "./types";

export const Status = ({ client }: { client: UnsavedGermanClient }) => {
  const getBackgroundColor = () => {
    switch (client.status) {
      case "New":
        return "bg-blue-100";
      case "AwaitingResponse":
        return "bg-yellow-100";
      case "Accepted":
        return "bg-green-100";
    }
  };

  const getForegroundColor = () => {
    switch (client.status) {
      case "New":
        return "text-blue-600";
      case "AwaitingResponse":
        return "text-yellow-600";
      case "Accepted":
        return "text-green-600";
    }
  };

  const getReadableStatus = () => {
    switch (client.status) {
      case "New":
        return "New";
      case "AwaitingResponse":
        return "Awaiting Response";
      case "Accepted":
        return "Accepted";
    }
  };

  return (
    <div
      className={`${getBackgroundColor()} ${getForegroundColor()} w-fit font-medium text-center rounded-full text-xs border px-4 py-px`}
    >
      <p>{getReadableStatus()}</p>
    </div>
  );
};
