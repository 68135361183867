import { MainListPatient } from "../types";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../../../components/ui/alert-dialog";
import { Button } from "../../../../components/ui/button";
import { useRemoveCaseLoadEntry } from "../hooks";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";

type RemoveClientDialogProps = {
  isOpen: boolean;
  setIsOpen: (newValue: boolean) => void;
  patient: MainListPatient;
  caseLoadEntryId: string;
  onRemoval: () => void;
};

export const RemoveClientDialog = ({
  isOpen,
  setIsOpen,
  patient,
  caseLoadEntryId,
  onRemoval,
}: RemoveClientDialogProps) => {
  const { t } = useTranslation();

  const { mutate: removeCaseLoadEntry, isLoading: isRemoving } =
    useRemoveCaseLoadEntry(() => {
      onRemoval();
      setIsOpen(false);

      toast(t("case-load-manager-remove-patient-confirmation-toast-title"), {
        description: t(
          "case-load-manager-remove-patient-confirmation-toast-desc",
          {
            name: patient.name,
          }
        ),
      });
    });

  return (
    <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            {t("case-load-manager-remove-single-patient-dialog-title")}{" "}
          </AlertDialogTitle>
          <AlertDialogDescription>
            {t("case-load-manager-remove-single-patient-dialog-desc")}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <Button
            variant="outline"
            className="w-1/2"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            {t("case-load-manager-remove-single-patient-cancel-button")}
          </Button>

          <Button
            variant="destructive"
            className="w-1/2"
            onClick={async () => {
              await removeCaseLoadEntry({ caseLoadId: caseLoadEntryId });
            }}
            disabled={isRemoving}
          >
            {isRemoving
              ? t(
                  "case-load-manager-remove-single-patient-deleting-patient-button"
                )
              : t(
                  "case-load-manager-remove-single-patient-delete-patient-button"
                )}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
