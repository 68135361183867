import { useGetNoChangeInDaysReport } from "../../hooks";
import { Skeleton } from "../../../../../components/ui/skeleton";
import { CalendarFilter } from "./calendarFilter";
import { NoChangeInDaysReportChart } from "./chart";
import { NoChangeInDaysReportTable } from "./table";
import { useState } from "react";
import { getSimpleFormattedDate } from "../../../../../utils/dateUtils";
import moment from "moment";
import { RegenerateButton } from "./regenerateButton";
import { CalendarDays } from "lucide-react";
import { CrmNoChangeInDaysReportResult } from "../../../types";

export const NoChangeInDaysReport = () => {
  const today = new Date();

  const getThreeWeeksAgoDate = (date: Date) => {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - 3 * 7
    );
  };

  const [fromDate, setFromDate] = useState(getThreeWeeksAgoDate(today));
  const { data: data, isLoading } = useGetNoChangeInDaysReport(
    moment(fromDate).utc(true).toISOString()
  );

  const renderBody = () => {
    if (isLoading) {
      return (
        <div className="w-full flex items-center space-x-10">
          <Skeleton className="h-[300px] w-1/2 rounded" />
          <Skeleton className="h-[300px] w-1/2 rounded" />
        </div>
      );
    }

    if (data == undefined)
      return (
        <div className="w-full py-12 bg-red-100 text-red-600 text-lg rounded text-center">
          Something went wrong.
        </div>
      );

    if (data.length == 0) {
      return (
        <div className="w-full py-12 bg-slate-100 text-lg rounded text-center">
          No data is available for the selected period.
        </div>
      );
    }

    const getFridayOfWeek = () => {
      const now = new Date();

      const currentDay = now.getDay(); // (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
      const daysUntilFriday = (5 - currentDay + 7) % 7; // calculate how many days until Friday (Friday is day 5)

      const friday = new Date(now);
      friday.setDate(now.getDate() + daysUntilFriday);
      return friday;
    };

    const getDate = (daysToSubtract: number) => {
      const result = getFridayOfWeek();
      result.setDate(today.getDate() - daysToSubtract);

      return result;
    };

    const getHistoricData = (
      date: Date
    ): CrmNoChangeInDaysReportResult | undefined => {
      const foundData = data.filter(
        (x) => new Date(x.date).getDate() == date.getDate()
      );

      if (foundData.length == 0) return undefined;

      return foundData[0];
    };

    return (
      <div className="w-full flex items-start space-x-10">
        <div className="w-1/2 -ml-4">
          <NoChangeInDaysReportChart data={data} />
        </div>
        <div className="w-1/2">
          <NoChangeInDaysReportTable
            today={data[data.length - 1]}
            fridayOneWeekAgo={getHistoricData(getDate(7))}
            fridayTwoWeeksAgo={getHistoricData(getDate(14))}
            fridayThreeWeeksAgo={getHistoricData(getDate(21))}
          />
        </div>
      </div>
    );
  };

  return (
    <main className="pt-12">
      <section className="flex items-center justify-between">
        <div className="w-2/3">
          <h1 className="text-2xl font-bold">
            Working View report{" "}
            <span className="font-medium">- Referrals without action</span>
          </h1>
          <p>The number referrals without any action done by MPs.</p>
          <div className="mt-2 inline-flex items-center space-x-2 bg-blue-50 p-2 rounded">
            <div className="w-4 h-4">
              <CalendarDays className="w-4 h-4" />
            </div>
            <p>
              From:{" "}
              <span className="font-bold">
                {getSimpleFormattedDate(fromDate)}
              </span>{" "}
              - until today.
            </p>
          </div>
        </div>

        <div className="w-1/3 flex items-center justify-end space-x-2">
          <RegenerateButton />
          <CalendarFilter date={fromDate} setDate={setFromDate} />
        </div>
      </section>

      <section className="pt-6">{renderBody()}</section>
    </main>
  );
};
