export const customReactionOptions = [
  {
    own: false,
    type: "thumbs_up",
    Component: () => <>👍</>,
  },
  {
    own: false,
    type: "thumbs_down",
    Component: () => <>👎</>,
  },
  {
    own: false,
    type: "checkmark",
    Component: () => <>✅</>,
  },
];
