import React from "react";
import {
  MenuItem,
  CardHeader,
  Divider,
  CardContent,
  Grid,
  Button,
  TextField,
  Card,
  CardActions,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import useStores from "../../useStores";
import languageCodes from "../../utils/constants/languageCodes";

const CreatePatient = () => {
  const { patientsStore } = useStores();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Card>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          bsn: "",
          languageCode: languageCodes.NL,
          mobilePhoneNumber: "",
        }}
        onSubmit={(values, { resetForm, setSubmitting }) => {
          patientsStore.createPatient(values).then(
            (createPatientResponseData) => {
              enqueueSnackbar("Cliënt aangemaakt", { variant: "success" });
              resetForm();
              navigate(`/patient/${createPatientResponseData.id}`);
            },
            (error) => {
              enqueueSnackbar(error.response.data, { variant: "error" });
              setSubmitting(false);
            }
          );
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email()
            .required("Please enter an email address."),
          firstName: Yup.string().required("Please enter a first name."),
          lastName: Yup.string().required("Please enter a last name."),
          bsn: Yup.string()
            .nullable()
            .min(8, "Het burger service nummer moet minimaal 8 nummers hebben.")
            .max(
              9,
              "Het burger service nummer moet maximaal 9 nummers hebben."
            ),
          languageCode: Yup.string().required("Please select a language."),
          mobilePhoneNumber: Yup.string()
            .nullable()
            .matches(
              /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
              {
                message: "Please enter valid number.",
                excludeEmptyString: false,
              }
            ),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <form autoComplete="off" noValidate>
              <CardHeader title="Cliënt aanmaken" />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      autoFocus
                      fullWidth
                      label="Voornaam"
                      margin="dense"
                      name="firstName"
                      onChange={handleChange}
                      required
                      value={values.firstName}
                      variant="outlined"
                      onBlur={handleBlur}
                      error={errors.firstName && touched.firstName}
                      helperText={
                        errors.firstName &&
                        touched.firstName &&
                        errors.firstName
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Achternaam"
                      margin="dense"
                      name="lastName"
                      onChange={handleChange}
                      required
                      value={values.lastName}
                      variant="outlined"
                      onBlur={handleBlur}
                      error={errors.lastName && touched.lastName}
                      helperText={
                        errors.lastName && touched.lastName && errors.lastName
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="BSN"
                      margin="dense"
                      name="bsn"
                      onChange={handleChange}
                      value={values.bsn}
                      variant="outlined"
                      onBlur={handleBlur}
                      error={errors.bsn && touched.bsn}
                      helperText={errors.bsn && touched.bsn && errors.bsn}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="E-mailadres"
                      margin="dense"
                      name="email"
                      onChange={handleChange}
                      required
                      value={values.email}
                      variant="outlined"
                      type="email"
                      onBlur={handleBlur}
                      error={errors.email && touched.email}
                      helperText={errors.email && touched.email && errors.email}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      select
                      label="Taal"
                      margin="dense"
                      name="languageCode"
                      onChange={handleChange}
                      required
                      value={values.languageCode}
                      variant="outlined"
                      onBlur={handleBlur}
                      error={errors.languageCode && touched.languageCode}
                      helperText={
                        errors.languageCode &&
                        touched.languageCode &&
                        errors.languageCode
                      }
                    >
                      {Object.keys(languageCodes).map((key) => (
                        <MenuItem
                          key={languageCodes[key]}
                          value={languageCodes[key]}
                        >
                          {languageCodes[key]}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Telefoonnummer"
                      margin="dense"
                      name="mobilePhoneNumber"
                      onChange={handleChange}
                      value={values.mobilePhoneNumber}
                      variant="outlined"
                      type="tel"
                      onBlur={handleBlur}
                      error={
                        errors.mobilePhoneNumber && touched.mobilePhoneNumber
                      }
                      helperText={
                        errors.mobilePhoneNumber &&
                        touched.mobilePhoneNumber &&
                        errors.mobilePhoneNumber
                      }
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <CardActions>
                <Button
                  data-cy="create"
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting}
                  onClick={handleSubmit}
                >
                  Aanmaken
                </Button>
              </CardActions>
            </form>
          );
        }}
      </Formik>
    </Card>
  );
};

export default CreatePatient;