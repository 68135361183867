import { OverviewTable } from "./tables/overviewTable";
import { DetailsTable } from "./tables/detailsTable";
import { Charts } from "./charts";
import { TotalNumberOfPatientsCard } from "./cards/totalNumberOfPatients";
import { TotalNumberOfSupportedPatientsCard } from "./cards/totalNumberOfSupportedPatients";
import { TotalNumberOfExcludedPatientsCard } from "./cards/totalNumberOfExcludedPatients";
import { TotalNumberOfChatMessagesCard } from "./cards/totalNumberOfChatMessages";
import { TotalNumberOfPraktijkdataBillsCard } from "./cards/totalNumberOfPraktijkdataBills";
import {
  getCurrentMondayAndFriday,
  isDateInPastWeeks,
} from "../../../../utils/dateUtils";
import { useState } from "react";
import { WeekSelector } from "../../../../shared/WeekSelector";
import { Info } from "lucide-react";
import {
  HorizontalTab,
  HorizontalTabHeader,
  HorizontalTabContent,
} from "../../../../components/ui/horizontalTab";
import { OpToCrpPsychologistRatioTable } from "./tables/opToCrpPsychologistRatioTable";
import { PreIntakeReportTable } from "./tables/preIntakeReport";

export const CaseLoadKpis = () => {
  const { monday } = getCurrentMondayAndFriday();
  const [selectedDate, setSelectedDate] = useState<Date>(monday);

  return (
    <div className="p-4">
      <div className="w-full flex justify-between items-center pb-4">
        <h2 className="text-4xl font-bold">Caseload Manager KPIs</h2>
        <div className="w-1/4 flex items-center justify-end space-x-2">
          {isDateInPastWeeks(selectedDate) && (
            <div className="min-w-[300px] flex items-center space-x-2 bg-amber-100 dark:bg-amber-950 rounded-lg p-2 text-amber-800 dark:text-amber-600 mr-2">
              <Info className="w-4 h-4" />
              <p>You are viewing past data</p>
            </div>
          )}
          <WeekSelector
            thisMonday={monday}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        </div>
      </div>
      <section className="flex space-x-4 items-center">
        <TotalNumberOfPatientsCard date={selectedDate} />
        <TotalNumberOfSupportedPatientsCard date={selectedDate} />
        <TotalNumberOfExcludedPatientsCard date={selectedDate} />
        <TotalNumberOfChatMessagesCard date={selectedDate} />
        <TotalNumberOfPraktijkdataBillsCard date={selectedDate} />
      </section>

      <div className="border rounded-lg p-16 bg-slate-50 my-16">
        <Charts date={selectedDate} />
      </div>

      <div>
        <HorizontalTab>
          <div className="w-full my-12">
            <div className="flex items-center ml-3">
              <HorizontalTabHeader index={0}>
                Caseload Overview
              </HorizontalTabHeader>
              <HorizontalTabHeader index={1}>
                View All Caseloads
              </HorizontalTabHeader>
              <HorizontalTabHeader index={2}>
                Pre-Intake Report
              </HorizontalTabHeader>
              <HorizontalTabHeader index={3}>OP:SKP Ratio</HorizontalTabHeader>
            </div>
            <HorizontalTabContent index={0}>
              <OverviewTable date={selectedDate} />
            </HorizontalTabContent>
            <HorizontalTabContent index={1}>
              <DetailsTable date={selectedDate} />
            </HorizontalTabContent>
            <HorizontalTabContent index={2}>
              <PreIntakeReportTable />
            </HorizontalTabContent>
            <HorizontalTabContent index={3}>
              <OpToCrpPsychologistRatioTable />
            </HorizontalTabContent>
          </div>
        </HorizontalTab>
      </div>
    </div>
  );
};
