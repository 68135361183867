import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { cn } from '../../../../lib/utils';

type Section = {
  [key: string]: Array<{
    id: string;
    createdAt: Date;
    messages: Array<{
      createdAt: Date;
      content: Array<{
        text: {
          value: string;
        };
      }>;
    }>;
  }>;
};

type SectionDisplayNames = {
  [key: string]: string;
};

type Props = {
  setThreadId: (threadId: string) => void;
  assistant: {
    id: string;
    platforms: Array<{
      slug: string;
      // ... other platform fields
    }>;
    // ... other assistant fields
  };
  threads: Array<{
    id: string;
    createdAt: Date;
    messages: Array<{
      id: string;
      createdAt: Date;
      content: Array<{
        text: {
          value: string;
        };
      }>;
      // ... other message fields
    }>;
    // ... other thread fields
  }>;
};

export function ChatHistoryList({ assistant, threads, setThreadId }: Props) {
  const sectionOrder = ['today', 'yesterday', 'lastWeek', 'earlier'];

  const sectionDisplayNames: SectionDisplayNames = {
    earlier: 'Earlier',
    lastWeek: 'Last Week',
    today: 'Today',
    yesterday: 'Yesterday',
  };

  const [sections, setSections] = useState<Section>({
    earlier: [],
    lastWeek: [],
    today: [],
    yesterday: [],
  });

  // Separate conversations based on dateTime
  useEffect(() => {
    const now = new Date();

    const yesterdayDate = new Date(now);
    yesterdayDate.setDate(yesterdayDate.getDate() - 1);

    const lastWeekDate = new Date(now);
    lastWeekDate.setDate(lastWeekDate.getDate() - 7);

    const today: typeof threads = [];
    const yesterday: typeof threads = [];
    const lastWeek: typeof threads = [];
    const earlier: typeof threads = [];

    for (const thread of threads) {
      const latestMessageDate = thread.messages[0]?.createdAt
        ? new Date(thread.messages[0]?.createdAt)
        : undefined;

      if (latestMessageDate === undefined) {
        continue;
      }

      if (latestMessageDate.toDateString() === now.toDateString()) {
        today.push(thread);
      } else if (latestMessageDate.toDateString() === yesterdayDate.toDateString()) {
        yesterday.push(thread);
      } else if (latestMessageDate > lastWeekDate && latestMessageDate < now) {
        lastWeek.push(thread);
      } else if (latestMessageDate < lastWeekDate) {
        earlier.push(thread);
      }
    }

    setSections({ earlier, lastWeek, today, yesterday });
  }, [threads]);

  return (
    <div className={cn('h-full w-full space-y-8 overflow-y-auto bg-white px-4 pt-4')} key="sidebar">
      {threads.length === 0 ? (
        <div className="text-center text-xs text-slate-500">No previous conversations.</div>
      ) : (
        <div className="flex flex-col space-y-4">
          {sectionOrder.map((section) => {
            const sectionThreads = sections[section];

            if (!sectionThreads || sectionThreads.length === 0) {
              return null;
            }

            return (
              <div key={section}>
                <div className="font-md w-full text-xs capitalize text-slate-400">
                  <div className="flex w-full flex-row items-center gap-x-1 whitespace-nowrap">
                    <div className="h-[1px] w-full bg-slate-200" />

                    {sectionDisplayNames[section]}

                    <div className="h-[1px] w-full bg-slate-200" />
                  </div>
                </div>

                <div className="flex flex-col space-y-2">
                  {sectionThreads.map((thread) => {
                    const href =
                      `/assistants/${assistant?.platforms[0].slug}/thread/${thread.id}` as const;

                    const truncatedContent = `${format(thread.createdAt, 'dd/MM')}: ${
                      thread.messages?.[0]?.content?.[0]?.text?.value ?? 'No preview available'
                    }`;

                    return (
                      <div key={thread.id} onClick={() => setThreadId(thread.id)}>
                        <div
                          className="block cursor-pointer truncate rounded-md px-3 py-2 text-sm bg-secondary text-secondary-foreground"
                          //   href={href}
                          //   prefetch={false}
                        >
                          {truncatedContent}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
