import { useState } from "react";
import { Textarea } from "../../../components/ui/textarea";
import { Button } from "../../../components/ui/button";
import { Note } from "../types";
import { useAddNewNote } from "../hooks";
import { toast } from "sonner";
import { HelpCircle, Save, Undo } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../components/ui/tooltip";

export const ReferralNotes = ({
  referralId,
  referralNotes,
}: {
  referralId: string;
  referralNotes: Note;
}) => {
  const [value, setValue] = useState(referralNotes?.content ?? "");
  const { mutate: save, isLoading: isSaving } = useAddNewNote(() => {
    toast("Notes saved", {
      description: "The notes have been saved successfully",
    });
  });

  const hasChanged = (referralNotes?.content ?? "") !== value;

  return (
    <div>
      <div className="w-full flex items-center justify-between">
        <p className="text-lg font-medium leading-none">Care Notes</p>
        <TooltipProvider delayDuration={100}>
          <Tooltip>
            <TooltipTrigger>
              <div className="h-4 w-4">
                <HelpCircle className="h-4 w-4" />
              </div>
            </TooltipTrigger>
            <TooltipContent style={{ zIndex: 99999 }} className="max-w-[24rem]">
              <p>
                These notes will be sent to PraktijkData. Please do{" "}
                <span className="underline">not</span> include here
                reach-out/content related remarks.
              </p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <Textarea
        autoFocus
        className="border-1-4 mt-1 bg-gray-50 dark:bg-slate-800"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={isSaving}
        rows={6}
      />

      {hasChanged && (
        <div className="w-full flex justify-end pt-2 space-x-2">
          <Button
            className="flex items-center space-x-2 px-4"
            variant="outline"
            onClick={() => {
              setValue(referralNotes?.content ?? "");
            }}
          >
            <Undo className="h-4 w-4" />
            <p>Undo</p>
          </Button>

          <Button
            className="flex items-center space-x-2 px-4"
            onClick={async () => {
              const data = {
                referralId: referralId,
                note: {
                  date: new Date(),
                  content: value || "",
                },
              };
              await save(data);
            }}
          >
            <Save className="h-4 w-4" />
            <p>{isSaving ? "Saving notes..." : "Save notes"}</p>
          </Button>
        </div>
      )}
    </div>
  );
};
