import { ReferralDetails, RegistrationStatus } from "../../types";
import { getRejectionReason } from "../reject/utils";

export const ReferralProgressInfoRejection = ({
  referral,
}: {
  referral: ReferralDetails;
}) => {
  if (referral.status === RegistrationStatus.Cancelled)
    return (
      <div className="bg-red-100 dark:bg-red-950 px-12 py-1 text-xs text-red-600 -mx-[40px] mb-6">
        Appointment cancelled by the general practitioner. Reason:{" "}{referral.cancellationReason}
      </div>
    );

  if (referral.status !== RegistrationStatus.Rejected) return <></>;

  return (
    <div className="bg-red-100 dark:bg-red-950 px-12 py-1 text-xs text-red-600 -mx-[40px] mb-6">
      Rejected by {referral.rejectedByClient ? "client" : "iPractice"}. Reason:{" "}
      {getRejectionReason(
        referral.status,
        referral.rejectedByClient,
        referral.rejectionReasonClient,
        referral.rejectionReasonIPractice,
        referral.justificationForOtherRejectionReason
      )}
    </div>
  );
};
