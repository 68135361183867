import { colors } from "@mui/material";

export default (status) => {
    switch (status) {
        case "Completed": return colors.green[600];
        case "Overdue": return colors.red[600];
        case "DueSoon": return colors.orange[600];
        case "Open": return colors.grey[600];
        default: return colors.red[600];
    }
};
