import { observer } from "mobx-react";
import {
  ButtonGroup,
  Button,
  CardActions,
  Card,
  CardHeader,
  Divider,
  Grid,
  Typography,
  CardContent,
  colors,
} from "@mui/material";
import React, { useState } from "react";
import moment from "moment";
import { styled } from "@mui/system";
import Label from "../../shared/Label";
import useStores from "../../useStores";
import ConfirmDialog from "../../shared/ConfirmDialog";
import subscriptionTypes from "../../utils/constants/subscriptionTypes";

const StyledCardRoot = styled(Card)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const StyledCardContent = styled(CardContent)(() => ({
  padding: 0,
  "&:last-child": {
    paddingBottom: 0,
  },
}));

const StyledStatusTags = styled("div")(() => ({
  float: "right",
}));

const StyledHeader = styled(Typography)(() => ({
  display: "inline",
}));

const StyledDetails = styled("div")(({ theme }) => ({
  padding: theme.spacing(2, 3),
}));

const statusColors = {
  finished: colors.green[600],
  active: colors.orange[600],
};

const Subscription = ({ subscription, onEdit }) => {
  const isFinished = subscription.finishedAt != null;
  const [confirmDeleteIsOpen, setConfirmDeleteIsOpen] = useState(false);
  const [confirmFinishSubscriptionIsOpen, setConfirmFinishSubscriptionIsOpen] =
    useState(false);
  const { subscriptionStore } = useStores();

  const getSubscriptionLabel = () => {
    return Object.values(subscriptionTypes).find(
      (x) => x.key === subscription.type
    ).label;
  };

  return (
    <StyledCardRoot>
      <CardHeader
        title={
          <div>
            <StyledHeader variant="h5">{getSubscriptionLabel()}</StyledHeader>

            <StyledStatusTags>
              <Label
                color={isFinished ? statusColors.finished : statusColors.active}
              >
                {isFinished ? "Afgerond" : "Actief"}
              </Label>
            </StyledStatusTags>
          </div>
        }
      />
      <StyledCardContent>
        <Divider />
        <StyledDetails>
          <Grid
            alignItems="center"
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography variant="h5">
                {moment(subscription.startedAt).format("DD-MM-YYYY")}
              </Typography>
              <Typography variant="body2">Start</Typography>
            </Grid>
            {isFinished && (
              <Grid item>
                <Typography variant="h5">
                  {moment(subscription.finishedAt).format("DD-MM-YYYY")}
                </Typography>
                <Typography variant="body2">Afgerond</Typography>
              </Grid>
            )}
            <Grid item>
              <Typography variant="h5">{`${subscription.employee.firstName} ${subscription.employee.lastName}`}</Typography>
              <Typography variant="body2">Behandelaar</Typography>
            </Grid>
          </Grid>
        </StyledDetails>
      </StyledCardContent>
      <CardActions>
        <ButtonGroup>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => setConfirmFinishSubscriptionIsOpen(true)}
            disabled={isFinished}
          >
            Afronden
          </Button>
          <ConfirmDialog
            question="Weet je zeker dat je het abonnement wilt afronden?"
            isOpen={confirmFinishSubscriptionIsOpen}
            onConfirm={() => {
              setConfirmFinishSubscriptionIsOpen(false);
              subscriptionStore.finishSubscription(subscription);
            }}
            onCancel={() => setConfirmFinishSubscriptionIsOpen(false)}
          />

          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => onEdit(subscription)}
          >
            Bewerken
          </Button>

          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => setConfirmDeleteIsOpen(true)}
          >
            Verwijderen
          </Button>
          <ConfirmDialog
            question="Weet je zeker dat je het abonnement wilt verwijderen?"
            isOpen={confirmDeleteIsOpen}
            onConfirm={() => subscriptionStore.deleteSubscription(subscription)}
            onCancel={() => setConfirmDeleteIsOpen(false)}
          />
        </ButtonGroup>
      </CardActions>
    </StyledCardRoot>
  );
};

export default observer(Subscription);
