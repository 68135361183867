import { Header } from "./header/header";
import { DefaultGenerics, StreamChat } from "stream-chat";
import { useGetAssignedPatient } from "../hooks";
import { Skeleton } from "../../../../components/ui/skeleton";
import { Tabs } from "./tabs";
import { HorizontalTab } from "../../../../components/ui/horizontalTab";

type CaseLoadClientDetailsProps = {
  clientId: string;
  dossierLookupId: string;
  chatClient: StreamChat<DefaultGenerics> | null;
  couldNotLoadChat: boolean;
  onClose: () => void;
};

export const CaseLoadClientDetails = ({
  clientId,
  dossierLookupId,
  chatClient,
  couldNotLoadChat,
  onClose,
}: CaseLoadClientDetailsProps) => {
  const { isLoading } = useGetAssignedPatient(clientId);

  if (isLoading) {
    return (
      <div className="min-h-[90vh] min-w-full">
        <Skeleton className="h-14 w-full rounded" />
        <div className="mt-6 flex items-center justify-between space-x-6">
          <Skeleton className="h-44 w-1/2 rounded" />
          <Skeleton className="h-44 w-1/2 rounded" />
        </div>
        <Skeleton className="mt-6 h-72 w-full rounded" />
      </div>
    );
  }

  return (
    <main className="-mt-6 min-w-full">
      <Header clientId={clientId} onClose={onClose} />

      <section className="pt-6">
        <HorizontalTab>
          <Tabs
            clientId={clientId}
            dossierLookupId={dossierLookupId}
            chatClient={chatClient}
            couldNotLoadChat={couldNotLoadChat}
          />
        </HorizontalTab>
      </section>
    </main>
  );
};
