import { useState } from "react";
import { useGetNoChangeInDaysReport } from "./hooks";
import { WaitListTable } from "./table";
import { Filters } from "./filters";

export const WaitListReport = () => {
  const { data, isLoading } = useGetNoChangeInDaysReport();

  const [selectedInsurer, setSelectedInsurer] = useState<
    "MENZIS" | "VGZ" | "all"
  >("all");
  const [from, setFrom] = useState(new Date(2024, 1, 1));

  const filteredData = (data ?? []).filter((d) => {
    if (new Date(d.referralDate) < from) return false;

    if (selectedInsurer === "all") return true;

    return selectedInsurer.toLowerCase() === d.insurer.toLowerCase();
  });

  return (
    <main>
      <section className="w-full flex items-center justify-between">
        <div className="w-1/2">
          <h1 className="text-2xl font-bold">Wait List Report</h1>
          <p>View the clients currently in the waiting list.</p>
        </div>
      </section>

      <Filters
        selectedInsurer={selectedInsurer}
        setSelectedInsurer={setSelectedInsurer}
        from={from}
        setFrom={setFrom}
      />

      <WaitListTable data={filteredData} isLoading={isLoading} />
    </main>
  );
};
