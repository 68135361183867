import { useGetAutomaticBillHistory } from "../hooks";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Skeleton } from "../../../../components/ui/skeleton";
import { TablePagination } from "../../../../components/ui/table-pagination";
import { Loader2 } from "lucide-react";
import { columns } from "./columns";

export const HistoryTable = ({
  skip,
  setSkip,
  selectedPsy,
  clientEpdId,
}: {
  skip: number;
  setSkip: (newSkip: number) => void;
  selectedPsy: string;
  clientEpdId: number | null;
}) => {
  const { data, isLoading } = useGetAutomaticBillHistory(
    selectedPsy == "full-team" ? null : selectedPsy,
    clientEpdId,
    skip
  );

  const table = useReactTable({
    data: data?.data || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableColumnResizing: true,
    manualPagination: true,
    manualSorting: true,
  });

  if (isLoading) {
    return (
      <div>
        <Skeleton className="h-12 w-full rounded mt-6" />
        <Skeleton className="h-8 w-full rounded mt-1" />
        <Skeleton className="h-8 w-full rounded mt-1" />
        <Skeleton className="h-8 w-full rounded mt-1" />
        <Skeleton className="h-8 w-full rounded mt-1" />
        <Skeleton className="h-8 w-full rounded mt-1" />
        <Skeleton className="h-8 w-full rounded mt-1" />
        <Skeleton className="h-8 w-full rounded mt-1" />
        <Skeleton className="h-8 w-full rounded mt-1" />
        <Skeleton className="h-8 w-full rounded mt-1" />
        <Skeleton className="h-8 w-full rounded mt-1" />
      </div>
    );
  }

  return (
    <>
      <Table className="mt-6 border">
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    key={header.id}
                    className="relative overflow-auto break-words"
                    style={{
                      width: header.getSize(),
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {isLoading ? (
            <div className="flex items-center space-x-1 p-12">
              <Loader2 className="w-4 h-4 animate-spin" />
              <p>Loading...</p>
            </div>
          ) : (
            <>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => {
                  return (
                    <TableRow
                      key={row.id}
                      data-state={row.getIsSelected() && "selected"}
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <TableCell
                            key={cell.id}
                            style={{
                              width: cell.column.getSize(),
                              minWidth: cell.column.columnDef.minSize,
                            }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    No bills issued, yet.
                  </TableCell>
                </TableRow>
              )}
            </>
          )}
        </TableBody>
      </Table>

      <TablePagination
        pagination={data?.pagination}
        pageSize={10}
        skip={skip}
        setSkip={setSkip}
        table={table}
      />
    </>
  );
};
