import { useState } from "react";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../../../components/ui/alert-dialog";
import { Button } from "../../../../components/ui/button";
import { useDeleteSubFolder, useDeleteFolder } from "../hooks";

type DeleteFolderDialogProps = {
  isOpen: boolean;
  setIsOpen: (newVal: boolean) => void;
  folderId: string;
  subFolderId?: string;
};

export const DeleteFolderDialog = ({
  isOpen,
  setIsOpen,
  folderId,
  subFolderId,
}: DeleteFolderDialogProps) => {
  const { mutate: deleteSubFolder, isLoading: isDeletingSubFolder } =
    useDeleteSubFolder();
  const { mutate: deleteFolder, isLoading: isDeletingFolder } =
    useDeleteFolder();

  return (
    <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription className="space-y-2">
            <p>
              This will permanently delete the folder and all its subfolders.
              Your conversation will NOT be deleted.
            </p>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(false);
            }}
          >
            Cancel
          </AlertDialogCancel>
          <Button
            variant="destructive"
            onClick={async (e) => {
              e.stopPropagation();

              if (isDeletingSubFolder || isDeletingFolder) return;

              if (subFolderId) {
                await deleteSubFolder({ folderId, subFolderId });
              } else {
                await deleteFolder({ folderId });
              }
            }}
          >
            {isDeletingSubFolder || isDeletingFolder ? "Deleting..." : "Delete"}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
