import { useState } from "react";
import { Textarea } from "../../../components/ui/textarea";
import { Button } from "../../../components/ui/button";
import { Loader2, Save, Undo } from "lucide-react";
import { toast } from "sonner";
import { useSaveComment } from "../hooks";

export const EditableOnlinePsyPreIntakeComment = ({
  psychologistEpdId,
  originalComment,
}: {
  psychologistEpdId: number;
  originalComment: string;
}) => {
  const [defaultComment, setDefaultComment] = useState<string>(originalComment);
  const [mutatedComment, setMutatedComment] = useState<string>(defaultComment);

  const hasChanged = (defaultComment ?? "") !== (mutatedComment ?? "");

  const { mutate: save, isLoading: isSaving } = useSaveComment(() => {
    setDefaultComment(mutatedComment);
    toast("Comment saved", {
      description: "The comment has been successfully saved.",
    });
  });

  return (
    <div>
      <Textarea
        value={mutatedComment ?? ""}
        className="text-xs"
        onChange={(e) => setMutatedComment(e.target.value)}
        disabled={isSaving}
        rows={defaultComment || mutatedComment ? 4 : 1}
      />
      {hasChanged && (
        <div className="flex gap-2">
          <Button
            className="py-0 w-full flex items-center gap-2 text-sm h-8 mt-2"
            disabled={!hasChanged}
            onClick={async () =>
              await save({ psychologistEpdId, comment: mutatedComment })
            }
          >
            {isSaving ? (
              <>
                <Loader2 className="w-4 h-4 animate-spin" />
                <p>Saving...</p>
              </>
            ) : (
              <>
                <Save className="w-4 h-4" />
                <p>Save</p>
              </>
            )}
          </Button>
          <Button
            variant="outline"
            className="py-0 w-full flex items-center gap-2 text-sm h-8 mt-2"
            disabled={!hasChanged}
            onClick={() => setMutatedComment(originalComment)}
          >
            <Undo className="w-4 h-4" />
            <p>Undo</p>
          </Button>
        </div>
      )}
    </div>
  );
};
