import { ColumnDef } from "@tanstack/react-table";
import { MainListPatient } from "../../types";
import { SortableCell } from "../../../../../components/ui/table";
import { getSimpleFormattedDateTime } from "../../../../../utils/dateUtils";
import { useTranslation } from "react-i18next";

export const LastRegularConsultOnColName = "Last Regular Consult On";

export const LastRegularConsultOnCol: ColumnDef<MainListPatient> = {
  id: LastRegularConsultOnColName,
  accessorKey: "lastRegularConsultOn",
  size: 150,
  minSize: 10,
  header: ({ column }) => {
    const { t } = useTranslation();
    return (
      <SortableCell
        column={column}
        label={t("case-load-manager-column-last-regular-consult")}
      />
    );
  },
  cell: ({ getValue }) => {
    const dateAsString = getValue()?.toString();

    if (!dateAsString) {
      return <></>;
    }

    return (
      <p className="whitespace-pre-wrap break-all py-2 px-4">
        {getSimpleFormattedDateTime(dateAsString)}
      </p>
    );
  },
};
