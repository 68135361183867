import { useState } from "react";
import { Separator } from "../../../../components/ui/separator";
import { ReferralDetails } from "../../types";
import { ChevronDown, ChevronUp } from "lucide-react";
import { CrmPatientEntryPatientRegistrationStepTracker } from "../registrationStatusTracker/crmPatientEntryPatientRegistrationStepTracker";

export const PraktijkdataActionsRow = ({
  referral,
  stepNumber,
}: {
  referral: ReferralDetails;
  stepNumber: number;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div>
      <div>
        <div className="flex items-center space-x-2">
          <CrmPatientEntryPatientRegistrationStepTracker
            referralId={referral.id}
            step="praktijkdata-actions"
          />
          <p className="font-medium text-lg">
            {stepNumber}. Actions in Praktijkdata after completing CRM flow
          </p>
        </div>

        <Separator className="mb-1" />
        {isExpanded ? (
          <ul className="list-disc list-inside text-sm p-2">
            <li>Enter the first name + 'roepnaam' in PraktijkData</li>
            <li>Add the general practitioner's name</li>
            <li>Send Zorgmail to the general practitioner</li>
            <li>Check insurance</li>
            <li>Choose echelon</li>
            <li>Enter referral date</li>
            <li>Select the referrer</li>
            <li>Record the client's question for care (hulpvraag)</li>
            <li>Note any additional remarks</li>
          </ul>
        ) : (
          <>
            <ul className="list-disc list-inside text-sm pt-2 px-2">
              <li>Enter the first name + 'roepnaam' in PraktijkData</li>
              <li>Add the general practitioner's name</li>
            </ul>
            <p className="pl-6 pt-1 pb-2 text-xs">...and 7 more</p>
          </>
        )}

        <div className="inline-flex items-center space-x-1 text-link cursor-pointer">
          <p
            className="hover:underline"
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? "Hide" : "Show all"} steps
          </p>
          <div className="w-4 h-4">
            {isExpanded ? (
              <ChevronUp className="w-4 h-4" />
            ) : (
              <ChevronDown className="w-4 h-4" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
