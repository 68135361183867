import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { Button } from "../../../../components/ui/button";
import { useStopAbsence } from "../hooks";
import { toast } from "sonner";
import { Loader2 } from "lucide-react";

export const UndoAbsence = () => {
  const { t } = useTranslation();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const { mutate, isLoading } = useStopAbsence(() => {
    setIsConfirmationDialogOpen(false);
    toast(t("case-load-manager-absence-undo-feedback-title"), {
      description: t("case-load-manager-absence-undo-feedback-desc"),
    });
  });

  return (
    <>
      <span
        className="font-bold cursor-pointer hover:underline text-amber-200"
        onClick={() => setIsConfirmationDialogOpen(true)}
      >
        {t("case-load-manager-absence-header-3")}
      </span>
      <Dialog
        open={isConfirmationDialogOpen}
        onOpenChange={setIsConfirmationDialogOpen}
      >
        <DialogContent className="w-1/4">
          <DialogHeader>
            <DialogTitle>
              {t("case-load-manager-absence-undo-dialog-title")}
            </DialogTitle>
            <DialogDescription>
              <p>{t("case-load-manager-absence-undo-dialog-desc")}</p>
            </DialogDescription>
          </DialogHeader>

          <DialogFooter className="w-full flex justify-center items-center">
            <Button
              onClick={() => setIsConfirmationDialogOpen(false)}
              variant="outline"
              className="w-1/2"
            >
              {t("case-load-manager-absence-undo-dialog-cancel-button")}
            </Button>
            <Button
              onClick={async () => await mutate()}
              className="w-1/2 flex items-center space-x-2"
            >
              {isLoading && <Loader2 className="w-4 h-4 animate-spin" />}
              <p>{t("case-load-manager-absence-undo-dialog-undo-button")}</p>
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
