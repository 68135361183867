import React from "react";
import { observer } from "mobx-react";
import { styled } from "@mui/system";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import questionListName from "../../utils/constants/questionListName";
import { getQuestionnaireUrl, getIWelcomeUrl } from "../../utils/urlHelpers";
import useStores from "../../useStores";
import QuestionListDetails from "../../shared/QuestionListDetails";
import ReferralLetterRow from "../../shared/ReferralLetterRow";
import Loader from "../../shared/Loader";
import StatusLabelBoolean from "../../shared/StatusLabelBoolean";
import areas from "../../utils/constants/areas";
import { IWelcomeEmailSender } from "../dossierGgz/onboardingTab/iWelcome/iWelcomeEmailSender";
import { SuccessfullySentEmails } from "../dossierGgz/onboardingTab/iWelcome/successfullySentEmails";
import { UnsuccessfullySentEmails } from "../dossierGgz/onboardingTab/iWelcome/unsuccessfullySentEmails";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const StyledOpenInNewIcon = styled(OpenInNewIcon)(({ theme }) => ({
  float: "right",
  marginLeft: theme.spacing(1),
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(0),
}));

const TransferIntake = () => {
  const { t } = useTranslation();
  const { patientStore, onboardingStore } = useStores();
  const { questionLists, dossier } = patientStore;
  const { onboarding } = onboardingStore;
  const dossierId = onboarding.id;

  const basisGgzQuestionList = questionLists.find((list) =>
    [
      questionListName.BASIS_GGZ_INTAKE_V1,
      questionListName.BASIS_GGZ_INTAKE_V2,
    ].includes(list.name)
  );

  const getBasisGgzTransferClipboardValue = () => {
    if (basisGgzQuestionList == undefined) return "";

    const link = getQuestionnaireUrl(basisGgzQuestionList);
    const name = patientStore.patient.firstName;
    const { languageCode } = patientStore.patient.language;

    return t("mail-content-questionnaire-intake-transfer", {
      replace: { name, link },
      lng: languageCode.substr(0, languageCode.indexOf("-")),
    });
  };

  return (
    <Loader area={areas.ONBOARDING}>
      <Card>
        <CardHeader title="Intakes" />
        <Divider />
        <StyledCardContent>
          {basisGgzQuestionList && (
            <QuestionListDetails
              dossierId={dossier.lookupGuid}
              questionList={basisGgzQuestionList}
              clipBoardValue={getBasisGgzTransferClipboardValue()}
              hideNavigation
              hideCopyToClipboard
            >
              Start met SQ-48 + uitbreiding, vervolgt met uitvragen van klachten
              en persoonlijke situatie. Duurt 45 - 90 minuten.
            </QuestionListDetails>
          )}
          <Divider />
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    verticalAlign: "top",
                    padding: "24px 16px 16px 16px",
                  }}
                >
                  iWelcome
                </TableCell>
                <TableCell>
                  <div className="w-full flex justify-between items-center">
                    <div className="w-1/2 flex items-center space-x-2">
                      <StatusLabelBoolean
                        boolean={
                          onboarding.iWelcomeProgress.status === "Finished"
                        }
                        positive="Voltooid"
                        negative="Open"
                      />
                      <Button
                        size="small"
                        href={getIWelcomeUrl(onboarding.lookupGuid)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <StyledOpenInNewIcon />
                      </Button>
                    </div>
                    <div className="w-2/3 flex flex-col items-end space-x-2">
                      <IWelcomeEmailSender
                        text="Email"
                        dossierId={dossierId}
                        dossierType="DossierTransfer"
                        dossierHasReferralLetterAlready={
                          onboarding.isReferralLetterPresent
                        }
                        crmReferral={null}
                      />
                    </div>
                  </div>
                  <div className="mt-5 pt-3 border-t flex flex-col">
                    <SuccessfullySentEmails
                      activityLogs={onboarding.iWelcomeEmailsActivityLogs}
                    />
                    <UnsuccessfullySentEmails dossierId={dossierId} />
                  </div>
                </TableCell>
              </TableRow>
              <ReferralLetterRow />
            </TableBody>
          </Table>
        </StyledCardContent>
      </Card>
    </Loader>
  );
};

export default observer(TransferIntake);
