import { ColumnDef } from "@tanstack/react-table";
import { relevances } from "../../../constants";
import { ReferredPatient } from "../../../types";
import { useTranslation } from "react-i18next";

export const RelevanceColName = "Relevance";

export const RelevanceCol: ColumnDef<ReferredPatient> = {
  id: RelevanceColName,
  accessorKey: "relevance",
  size: 120,
  minSize: 80,
  header: ({ column }) => {
    const { t } = useTranslation();

    return <p>Relevance</p>;
  },
  cell: ({ row }) => {
    const relevance = relevances.find(
      (relevance) => relevance.value === row.original.relevance
    );

    const proposedRelevance = relevances.find(
      (relevance) => relevance.value === row.original.proposedRelevance
    );

    if (!relevance) {
      return null;
    }

    return (
      <div>
        <div className="flex items-center">
          {relevance.icon && (
            <relevance.icon className="mr-2 h-4 w-4 text-muted-foreground" />
          )}
          <span
            className={relevance.value == "Unspecified" ? "opacity-50" : ""}
          >
            {relevance.label}
          </span>
        </div>

        {relevance.value === "Unspecified" &&
          proposedRelevance &&
          proposedRelevance.value != "Unspecified" && (
            <p className="pt-1">
              Proposal:{" "}
              <span className="font-bold">{proposedRelevance.label}</span>
            </p>
          )}
      </div>
    );
  },
  filterFn: (row, id, value) => {
    return value.includes(row.getValue(id));
  },
};
