import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import { observer } from "mobx-react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import moment from "moment";
import scheduleType from "../../../utils/constants/scheduleType";
import {
  CronNotation,
  displayLocalCronJob,
  getDays,
  getHour,
  getMinute,
} from "../../../utils/cronParser";
import useStores from "../../../useStores";
import SchedulePreviewModal from "./SchedulePreviewModal";
import ButtonsContainer from "../../../shared/ButtonsContainer";

const StyledCardRoot = styled(Card)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  outline: "none",
  boxShadow: theme.shadows[20],
  width: 700,
  maxHeight: "100%",
  overflowY: "auto",
  maxWidth: "100%",
}));

const StyledCardActions = styled(CardActions)(() => ({
  justifyContent: "space-between",
}));

function ScheduleCreateModal({ open, onClose }) {
  const { scheduleDefinitionsStore, patientStore } = useStores();
  const { scheduleDefinitions } = scheduleDefinitionsStore;
  const [selectedLocalTime, setSelectedLocalTime] = useState(moment());
  const [selectedEndDate, setSelectedEndDate] = useState(moment());
  const [selectedScheduleDefinition, setSelectedScheduleDefinition] =
    useState("");
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

  useEffect(() => {
    scheduleDefinitionsStore.fetchScheduleDefinitions();
  }, [scheduleDefinitionsStore]);

  const handleScheduleDefinitionChange = (event) => {
    const scheduleDefinition = event.target.value;
    setSelectedScheduleDefinition(scheduleDefinition);
    setSelectedLocalTime(
      moment()
        .hours(getHour(scheduleDefinition.suggestedScheduleCronNotation))
        .minutes(getMinute(scheduleDefinition.suggestedScheduleCronNotation))
        .subtract(new Date().getTimezoneOffset(), "minutes")
    );
    setSelectedEndDate(
      moment().add(scheduleDefinition.suggestedDaysTillEndDate, "days")
    );
  };

  const handleTimeChange = (dateTime) => {
    setSelectedLocalTime(dateTime);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };

  const handleSubmit = () => {
    const cronNotation = new CronNotation()
      .onDays(getDays(selectedScheduleDefinition.suggestedScheduleCronNotation))
      .atHour(moment.utc(selectedLocalTime).hours())
      .atMinute(moment.utc(selectedLocalTime).minutes());

    const endDate =
      selectedScheduleDefinition.scheduleType === scheduleType.SEQUEL
        ? null
        : selectedEndDate;

    patientStore.createSchedule(
      selectedScheduleDefinition.id,
      cronNotation,
      endDate
    );
    onClose();
  };

  if (!open) {
    return null;
  }

  return (
    <Modal onClose={onClose} open={open}>
      <form autoComplete="off" noValidate>
        <StyledCardRoot>
          <CardHeader title="Bot aanmaken" />
          <Divider />
          <CardContent>
            <Grid>
              <Grid item>
                <TextField
                  fullWidth
                  required
                  select
                  label="Bot"
                  margin="dense"
                  name="scheduleDefinition"
                  onChange={handleScheduleDefinitionChange}
                  value={selectedScheduleDefinition}
                  variant="outlined"
                >
                  {scheduleDefinitions.map((scheduleDefinition) => (
                    <MenuItem
                      key={scheduleDefinition.id}
                      value={scheduleDefinition}
                    >
                      {scheduleDefinition.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">
                  {selectedScheduleDefinition.scheduleType ===
                    scheduleType.LOOP &&
                    "Deze bot stuurt dagelijks een bericht."}
                  {selectedScheduleDefinition.scheduleType ===
                    scheduleType.SEQUEL &&
                    `Deze bot heeft ${
                      selectedScheduleDefinition.messageDefinitions.length
                    } afleveringen en wordt verzonden op ${displayLocalCronJob(
                      selectedScheduleDefinition.suggestedScheduleCronNotation
                    )}`}
                </Typography>
              </Grid>
              <Grid item>
                {selectedScheduleDefinition.scheduleType && (
                  <TimePicker
                    margin="normal"
                    ampm={false}
                    label="Om"
                    variant="inline"
                    value={selectedLocalTime}
                    onChange={handleTimeChange}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                  />
                )}
              </Grid>
              <Grid item>
                {selectedScheduleDefinition.scheduleType ===
                  scheduleType.LOOP && (
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    format="DD/MM/YYYY"
                    margin="normal"
                    label="Einddatum"
                    id="date-picker-inline"
                    value={selectedEndDate}
                    onChange={handleEndDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <StyledCardActions>
            <Button
              disabled={selectedScheduleDefinition === ""}
              onClick={() => setIsPreviewModalOpen(true)}
            >
              Preview
            </Button>
            <ButtonsContainer>
              <Button onClick={onClose}>Cancel</Button>
              <Button
                color="primary"
                variant="contained"
                disabled={selectedScheduleDefinition === ""}
                onClick={handleSubmit}
              >
                Bot aanmaken
              </Button>
            </ButtonsContainer>
          </StyledCardActions>
          <SchedulePreviewModal
            scheduleDefinition={selectedScheduleDefinition}
            open={isPreviewModalOpen}
            onClose={() => setIsPreviewModalOpen(false)}
          />
        </StyledCardRoot>
      </form>
    </Modal>
  );
}

export default observer(ScheduleCreateModal);
