import React from "react";
import { useDebouncedCallback } from "use-debounce";
import { styled } from "@mui/system";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import { observer } from "mobx-react";
import useStores from "../../useStores";
import { InputBase } from "@mui/material";

const StyledRoot = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  maxWidth: 480,
  padding: theme.spacing(2, 0),
}));

const StyledSearchPaper = styled(Paper)(({ theme }) => ({
  flexGrow: 1,
  height: 42,
  padding: theme.spacing(0, 2),
  display: "flex",
  alignItems: "center",
}));

const StyledSearchInput = styled(InputBase)(() => ({
  flexGrow: 1,
}));

const StyledSearchIcon = styled(SearchIcon)(({ theme, isActive }) => ({
  marginRight: theme.spacing(2),
  color: isActive ? theme.palette.primary.main : theme.palette.icon,
}));

const PatientsSearch = () => {
  const { patientsStore } = useStores();

  const onSearchChange = useDebouncedCallback((searchValue) => {
    patientsStore.setSearchValue(searchValue);
  }, 500);

  return (
    <StyledRoot>
      <StyledSearchPaper elevation={1}>
        <StyledSearchIcon isActive={patientsStore.searchValue !== ""} />
        <StyledSearchInput
          autoFocus
          onChange={(event) => onSearchChange(event.target.value)}
          placeholder="Zoek op naam, email of BSN..."
        />
      </StyledSearchPaper>
    </StyledRoot>
  );
};

export default observer(PatientsSearch);
