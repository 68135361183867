import { CrmNoChangeInDaysReportResult } from "../../../types";

export type ChartColors = {
  [key in Exclude<keyof CrmNoChangeInDaysReportResult, "date">]: string;
};

export const colors: ChartColors = {
  countOfMoreThanFourteenDays: "#ef4444",
  countOfEightToFourteenDays: "#f87171",
  countOfTwoToSevenDays: "#FFBB28",
  countOfOneDay: "#84cc16",
  countOfZeroDays: "#10b981",
};

export const getReadableLabelFromDataKey = (
  dataKey: Exclude<keyof CrmNoChangeInDaysReportResult, "date">
) => {
  switch (dataKey) {
    case "countOfMoreThanFourteenDays":
      return "15+ (more than 2 weeks)";
    case "countOfEightToFourteenDays":
      return "8-14 (2 weeks)";
    case "countOfTwoToSevenDays":
      return "2-7 (1 week)";
    case "countOfOneDay":
      return "1";
    case "countOfZeroDays":
      return "0";
    default:
      return "Unknown";
  }
};
