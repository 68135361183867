import { Input } from "../../../../../components/ui/input";

export const Email = ({
  value,
  setValue,
}: {
  value: string;
  setValue: (newValue: string) => void;
}) => {
  return (
    <>
      <label className="mt-4 block pb-1" htmlFor="email">
        Email
      </label>
      <Input
        id="email"
        className="text-lg"
        value={value}
        onChange={(event) => setValue(event.target.value)}
      />
    </>
  );
};
