import React, { Fragment } from "react";
import { styled } from "@mui/system";
import { Popover, Typography } from "@mui/material";

const StyledContentSpan = styled("span")(() => ({
  cursor: "pointer",
}));

const StyledPopover = styled(Popover)(() => ({
  pointerEvents: "none",
}));

function CopyToClipboardWithPopover({ children, value }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <StyledContentSpan
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={() => {
          navigator.clipboard.writeText(value);
        }}
      >
        {children}
      </StyledContentSpan>

      <StyledPopover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>Click to copy</Typography>
      </StyledPopover>
    </>
  );
}

export default CopyToClipboardWithPopover;
