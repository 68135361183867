import { Phone } from "lucide-react";
import { useState } from "react";
import { VideoCall } from "./videocall";
import { Button } from "../../../components/ui/button";
import { ChatConversationDetails } from "../types";

export const VideoCallButton = ({
  isFetchingChatDetails,
  chatDetails,
}: {
  isFetchingChatDetails: boolean;
  chatDetails: ChatConversationDetails;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setIsOpen(true)}>
        <div className="h-5 w-5">
          <Phone className="h-5 w-5" />
        </div>
      </Button>
      {isOpen && (
        <VideoCall
          isFetchingChatDetails={isFetchingChatDetails}
          chatDetails={chatDetails}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}
    </>
  );
};
