import React from 'react';
import { Grid } from "@mui/material";
import { observer } from "mobx-react";
import Billing from "./Billing";
import Transactions from "./Transactions";
import EditDossierEpd from "../../../shared/EditDossierEpd";

const ClosingTab = () => {
    return (
      <Grid container spacing={4}>
        <Grid item xs={6} xl={4}>
          <EditDossierEpd />
        </Grid>

        <Grid item xs={6} xl={4}>
          <Billing />
        </Grid>

        <Grid item xs={6} xl={4}>
          <Transactions />
        </Grid>
      </Grid>
    );
};

export default observer(ClosingTab);
