import { useGetBacklogStatisticsByNextStepReport } from "../../hooks";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../../components/ui/table";
import { Skeleton } from "../../../../../components/ui/skeleton";
import { getReadableNextStep } from "../../../referralDetails/nextStep";
import { NextStep } from "../../../types";

export const BacklogReportByNextStepTable = ({
  locationIds,
}: {
  locationIds: string[];
}) => {
  const { data, isLoading } =
    useGetBacklogStatisticsByNextStepReport(locationIds);

  const Heading = () => {
    return (
      <>
        <h1 className="text-2xl font-bold">
          Backlog report{" "}
          <span className="font-medium">
            - CRM / Patient Referral, by Next Step
          </span>
        </h1>
        <p>
          The number referrals in non-closed statuses, based on their Next Steps
        </p>
      </>
    );
  };

  if (isLoading) {
    return (
      <div className="mt-12">
        <Heading />
        <div className="mt-6">
          <Skeleton className="h-[450px] w-full rounded" />
        </div>
      </div>
    );
  }

  return (
    <div className="mt-12">
      <Heading />
      <div className="mt-6 overflow-x-auto">
        <Table className="border min-w-[1200px] table-auto">
          <TableHeader>
            <TableRow>
              <TableHead className="min-w-[250px]">Location</TableHead>
              <TableHead className="min-w-[125px]">
                {getReadableNextStep(NextStep.DeterminePatientApplicability)}
              </TableHead>
              <TableHead className="min-w-[125px]">
                {getReadableNextStep(NextStep.January)}
              </TableHead>
              <TableHead className="min-w-[125px]">
                {getReadableNextStep(NextStep.ReachOut)}
              </TableHead>
              <TableHead className="min-w-[125px]">
                Calendly Scheduled
              </TableHead>
              <TableHead className="min-w-[125px]">
                {getReadableNextStep(NextStep.WaitForFeedbackReRegistration)}
              </TableHead>
              <TableHead className="min-w-[125px]">
                {getReadableNextStep(NextStep.WaitForFeedbackCaseDiscussion)}
              </TableHead>
              <TableHead className="min-w-[125px]">
                {getReadableNextStep(NextStep.WaitForMissingInformation)}
              </TableHead>
              <TableHead className="min-w-[125px]">
                {getReadableNextStep(
                  NextStep.ReachOutAfterReRegistrationOrCaseDiscussion
                )}
              </TableHead>
              <TableHead className="min-w-[125px]">
                {getReadableNextStep(NextStep.ReachOutAfterAdhdScreening)}
              </TableHead>
              <TableHead className="min-w-[125px]">
                {getReadableNextStep(NextStep.PendingShort)}
              </TableHead>
              <TableHead className="min-w-[125px]">
                {getReadableNextStep(NextStep.PendingLong)}
              </TableHead>
              <TableHead className="min-w-[125px]">
                {getReadableNextStep(NextStep.InsurerWaitList)}
              </TableHead>
              <TableHead className="min-w-[125px]">
                {getReadableNextStep(NextStep.Closed)}
              </TableHead>
              <TableHead className="min-w-[125px] font-bold bg-slate-100 hover:bg-slate-100">
                Total
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {data && data.length > 0 ? (
              <>
                {data.map((d) => {
                  return (
                    <TableRow key={d.location}>
                      <TableCell>{d.location}</TableCell>
                      <TableCell className="w-[150px]">
                        {d.countOfDeterminePatientApplicability}
                      </TableCell>
                      <TableCell className="w-[150px]">
                        {d.countOfJanuary}
                      </TableCell>
                      <TableCell className="w-[150px]">
                        {d.countOfReachOut}
                      </TableCell>
                      <TableCell className="w-[150px]">
                        {d.countOfScheduledCalendlies}
                      </TableCell>
                      <TableCell className="w-[150px]">
                        {d.countOfWaitForFeedbackReRegistration}
                      </TableCell>
                      <TableCell className="w-[150px]">
                        {d.countOfWaitForFeedbackCaseDiscussion}
                      </TableCell>
                      <TableCell className="w-[150px]">
                        {d.countOfWaitForMissingInformation}
                      </TableCell>
                      <TableCell className="w-[150px]">
                        {d.countOfReachOutAfterReRegistrationOrCaseDiscussion}
                      </TableCell>
                      <TableCell className="w-[150px]">
                        {d.countOfReachOutAfterAdhdScreening}
                      </TableCell>
                      <TableCell className="w-[150px]">
                        {d.countOfPendingShort}
                      </TableCell>
                      <TableCell className="w-[150px]">
                        {d.countOfPendingLong}
                      </TableCell>
                      <TableCell className="w-[150px]">
                        {d.countOfInsurerWaitList}
                      </TableCell>
                      <TableCell className="w-[150px]">
                        {d.countOfClosed}
                      </TableCell>

                      <TableCell className="w-[150px] font-bold bg-slate-100 hover:bg-slate-100">
                        {d.countOfDeterminePatientApplicability +
                          d.countOfJanuary +
                          d.countOfReachOut +
                          d.countOfScheduledCalendlies +
                          d.countOfWaitForFeedbackReRegistration +
                          d.countOfWaitForFeedbackCaseDiscussion +
                          d.countOfWaitForMissingInformation +
                          d.countOfReachOutAfterReRegistrationOrCaseDiscussion +
                          d.countOfReachOutAfterAdhdScreening +
                          d.countOfPendingShort +
                          d.countOfPendingLong +
                          d.countOfInsurerWaitList +
                          d.countOfClosed}
                      </TableCell>
                    </TableRow>
                  );
                })}

                <TableRow className="bg-slate-100 hover:bg-slate-100">
                  <TableCell className="font-bold">Total</TableCell>
                  <TableCell className="w-[150px] font-bold">
                    {data.reduce(
                      (a, b) => a + b.countOfDeterminePatientApplicability,
                      0
                    )}
                  </TableCell>
                  <TableCell className="w-[150px] font-bold">
                    {data.reduce((a, b) => a + b.countOfJanuary, 0)}
                  </TableCell>
                  <TableCell className="w-[150px] font-bold">
                    {data.reduce((a, b) => a + b.countOfReachOut, 0)}
                  </TableCell>
                  <TableCell className="w-[150px] font-bold">
                    {data.reduce((a, b) => a + b.countOfScheduledCalendlies, 0)}
                  </TableCell>
                  <TableCell className="w-[150px] font-bold">
                    {data.reduce(
                      (a, b) => a + b.countOfWaitForFeedbackReRegistration,
                      0
                    )}
                  </TableCell>
                  <TableCell className="w-[150px] font-bold">
                    {data.reduce(
                      (a, b) => a + b.countOfWaitForFeedbackCaseDiscussion,
                      0
                    )}
                  </TableCell>
                  <TableCell className="w-[150px] font-bold">
                    {data.reduce(
                      (a, b) => a + b.countOfWaitForMissingInformation,
                      0
                    )}
                  </TableCell>
                  <TableCell className="w-[150px] font-bold">
                    {data.reduce(
                      (a, b) =>
                        a +
                        b.countOfReachOutAfterReRegistrationOrCaseDiscussion,
                      0
                    )}
                  </TableCell>
                  <TableCell className="w-[150px] font-bold">
                    {data.reduce(
                      (a, b) => a + b.countOfReachOutAfterAdhdScreening,
                      0
                    )}
                  </TableCell>
                  <TableCell className="w-[150px] font-bold">
                    {data.reduce((a, b) => a + b.countOfPendingShort, 0)}
                  </TableCell>
                  <TableCell className="w-[150px] font-bold">
                    {data.reduce((a, b) => a + b.countOfPendingLong, 0)}
                  </TableCell>
                  <TableCell className="w-[150px] font-bold">
                    {data.reduce((a, b) => a + b.countOfInsurerWaitList, 0)}
                  </TableCell>{" "}
                  <TableCell className="w-[150px] font-bold">
                    {data.reduce((a, b) => a + b.countOfClosed, 0)}
                  </TableCell>
                  <TableCell className="w-[150px] font-bold">
                    {data.reduce(
                      (a, b) =>
                        a +
                        b.countOfDeterminePatientApplicability +
                        b.countOfJanuary +
                        b.countOfReachOut +
                        b.countOfScheduledCalendlies +
                        b.countOfWaitForFeedbackReRegistration +
                        b.countOfWaitForFeedbackCaseDiscussion +
                        b.countOfWaitForMissingInformation +
                        b.countOfReachOutAfterReRegistrationOrCaseDiscussion +
                        b.countOfReachOutAfterAdhdScreening +
                        b.countOfPendingShort +
                        b.countOfPendingLong +
                        b.countOfInsurerWaitList +
                        b.countOfClosed,
                      0
                    )}
                  </TableCell>
                </TableRow>
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={6} className="py-1">
                  No data found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
