import { IntakeLimitsTable } from "./table/intakeLimitsTable";

export default function IntakeReport() {
  return (
    <main className="p-24">
      <section>
        <h1 className="text-4xl font-bold">SKP Intake Report</h1>
        <p>
          Find detailed information about each SKP and their upcoming intakes.
        </p>
      </section>

      <IntakeLimitsTable />
    </main>
  );
}
