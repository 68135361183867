import React from "react";
import { styled } from "@mui/system";

const StyledRoot = styled("div")(() => ({
  width: 40,
  height: 40,
}));

const StyledCircle1 = styled("path")(() => ({
  stroke: "rgba(0,0,0,0.05)",
  fill: "none",
  strokeWidth: 4,
}));

const StyledCircle2 = styled("path")(({ theme }) => ({
  stroke: theme.palette.primary.main,
  fill: "none",
  strokeWidth: 4,
  animation: `$progress 1s ease-out forwards`,
  "@keyframes progress": {
    "0%": {
      strokeDasharray: "0 100",
    },
  },
}));

function CircularProgress({ value }) {
  return (
    <StyledRoot>
      <svg viewBox="0 0 36 36">
        <StyledCircle1
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          strokeDasharray="100, 100"
        />
        <StyledCircle2
          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          strokeDasharray={`${value}, 100`}
        />
      </svg>
    </StyledRoot>
  );
}

export default CircularProgress;
