import { Frown } from "lucide-react";
import { Call } from "@stream-io/video-react-sdk";
import { LeaveCallButton } from "./leaveCallButton";

export const RejectedByClient = ({
  call,
  closeDialog,
}: {
  call: Call;
  closeDialog: () => void;
}) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-full pt-12">
      <div className="w-48 h-48">
        <Frown className="w-48 h-48" />
      </div>

      <h1 className="text-3xl pt-12">The client has declined your call</h1>
      <p className="pt-2 text-lg w-[400px] text-center mb-12">
        The client did not pick up. Please try again later, or contact the
        client via the chat.
      </p>
      <LeaveCallButton call={call} onClick={() => closeDialog()} />
    </div>
  );
};
