export function getClientBaseUrl() {
  if (process.env.NODE_ENV === "production") {
    return `${window.location.protocol}//${window.location.host.replace(
      "dashboard.",
      "client."
    )}/`;
  }
  return "https://localhost:6001/";
}

export function getQuestionnaireUrl(questionList) {
  return `${getClientBaseUrl()}questionnaire/${questionList.id}`;
}

export function getBillingUrl(lookupGuid) {
  return `${getClientBaseUrl()}billing/${lookupGuid}`;
}

export function getIWelcomeUrl(lookupGuid) {
  return `${getClientBaseUrl()}welcome/${lookupGuid}`;
}

export function getQuestionListResultsUrl(questionList) {
  return `${getClientBaseUrl()}api/questionlist-result?questionlistid=${
    questionList.id
  }`;
}

export function getAgreeUrl(userAgreemeentsId) {
  return `${getClientBaseUrl()}agree/${userAgreemeentsId}`;
}

export function getReferralLetterUrl(lookupGuid) {
  return `${getClientBaseUrl()}referral-letter/${lookupGuid}`;
}

export function getPraktijkdataDossierUrl(dossier) {
  return `https://start.praktijkdata.nl/app/clienten/${dossier.epdPatientId}/dossiers/${dossier.epdFileId}`;
}
