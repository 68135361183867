import {
  CallControlsProps,
  CallingState,
  CancelCallButton,
  ParticipantView,
  SpeakingWhileMutedNotification,
  ToggleAudioPublishingButton,
  ToggleVideoPublishingButton,
  useCall,
  useCallStateHooks,
} from "@stream-io/video-react-sdk";
import { useState } from "react";
import { RejectedByClient } from "./rejectedByClient";
import { RingingView } from "./ringingView";
import { InitializingCall } from "./initializingCall";
import { removeDuplicatesByUserId } from "./utils";

export const CustomLayout = ({ closeDialog }: { closeDialog: () => void }) => {
  const call = useCall();
  const { useCallCallingState, useRemoteParticipants } = useCallStateHooks();

  const callingState = useCallCallingState();
  const remoteParticipants = useRemoteParticipants();

  const [isCallRejected, setIsCallRejected] = useState(false);

  call!.on("call.rejected", () => {
    setIsCallRejected(true);
  });

  // client declines / ringing timed out
  if (isCallRejected) {
    return <RejectedByClient call={call!} closeDialog={closeDialog} />;
  }

  // OP pressed "end call" on the built-in call control
  if (callingState === CallingState.LEFT) {
    call?.endCall();
    closeDialog();
  }

  if (callingState === CallingState.RINGING) {
    return <RingingView call={call!} closeDialog={closeDialog} />;
  }

  // everything's OK but we're still loading
  if (callingState !== CallingState.JOINED) {
    return <InitializingCall call={call!} onClose={() => closeDialog()} />;
  }

  const participants = removeDuplicatesByUserId(remoteParticipants);

  return (
    <main className="h-full flex flex-col justify-between space-y-2">
      <div className="flex gap-4 items-center justify-center h-full">
        {participants.length == 0 ? (
          <div className="text-2xl font-bold">Waiting for participants...</div>
        ) : (
          <>
            {participants.map((participant) => (
              <div className="h-[70vh] w-full flex flex-col items-center">
                <ParticipantView
                  key={participant.sessionId}
                  participant={participant}
                  VideoPlaceholder={() => <></>}
                  ParticipantViewUI={
                    <h1 className="absolute inset-0 w-full text-left text-lg pb-1 font-bold p-2 shadow">
                      {participant.name}
                    </h1>
                  }
                />
              </div>
            ))}
          </>
        )}
      </div>
      <div className="h-[120px] flex justify-center items-end">
        <CallControls />
      </div>
    </main>
  );
};

const CallControls = ({ onLeave }: CallControlsProps) => (
  <div className="str-video__call-controls">
    <SpeakingWhileMutedNotification>
      <ToggleAudioPublishingButton />
    </SpeakingWhileMutedNotification>
    <ToggleVideoPublishingButton />
    <CancelCallButton onLeave={onLeave} />
  </div>
);
