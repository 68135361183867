import {
  useGetWireHandleOfOnboardingPatient,
  usePublishKopModel,
  useSaveWireHandleAndPublishKopModel,
} from '../../hooks';
import { toast } from 'sonner';
import { Loader2, Send } from 'lucide-react';
import { OnboardPatientDetails } from '../../types';
import { KopModelStatus } from '../../../types';
import { Button } from '../../../../../components/ui/button';
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../../../../../components/ui/alert-dialog';
import { Input } from '../../../../../components/ui/input';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const PublishButton = ({
  isDisabled,
  onboardingPatient,
  complaints,
  circumstances,
  personalStyle,
  requestForHelp,
  questionsForPreIntake,
  myCommentsForDiagnosisAndTreatment,
}: {
  isDisabled: boolean;
  onboardingPatient: OnboardPatientDetails;
  complaints: string;
  circumstances: string;
  personalStyle: string;
  requestForHelp: string;
  questionsForPreIntake: string;
  myCommentsForDiagnosisAndTreatment: string;
}) => {
  const { data: wireHandleData, isFetching: isFetchingWireHandle } =
    useGetWireHandleOfOnboardingPatient(onboardingPatient.id);

  const [wireHandle, setWireHandle] = useState(wireHandleData?.wireHandle ?? '');

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isMissingWireHandleDialogOpen, setIsMissingWireHandleDialogOpen] = useState(false);

  const [showWireUserError, setShowWireUserError] = useState(false);

  const { t } = useTranslation();

  const { mutate: publishKopModel, isLoading: isPublishingKopModel } = usePublishKopModel(() => {
    toast(t('case-load-manager-onboarding-kop-model-sent-feedback-title'), {
      description: t('case-load-manager-onboarding-kop-model-sent-feedback-desc'),
    });
    sessionStorage.removeItem('hasUnsavedKopModelChanges');

    setIsConfirmDialogOpen(false);
  });

  const {
    mutate: saveWireHandleAndPublishKopModel,
    isLoading: isSavingWireHandleAndPublishingKopModel,
  } = useSaveWireHandleAndPublishKopModel(
    () => {
      toast(t('case-load-manager-onboarding-kop-model-sent-feedback-title'), {
        description: t('case-load-manager-onboarding-kop-model-sent-feedback-desc'),
      });
      setIsMissingWireHandleDialogOpen(false);
    },
    () => {
      setShowWireUserError(true);
      setTimeout(() => {
        setShowWireUserError(false);
      }, 4000);
    }
  );

  const isRepublishing = onboardingPatient.kopModel.status === KopModelStatus.Sent;

  return (
    <>
      <Button
        className="flex items-center space-x-2 px-6"
        disabled={isDisabled || isFetchingWireHandle || isPublishingKopModel}
        onClick={() => {
          if (wireHandleData?.wireHandle == null || wireHandleData?.wireHandle == '') {
            setIsMissingWireHandleDialogOpen(true);
          } else {
            setIsConfirmDialogOpen(true);
          }
        }}
      >
        <div className="h-4 w-4">
          <Send className="h-4 w-4" />
        </div>
        <p>
          {isRepublishing || onboardingPatient.treatmentStartStandardMessageSent
            ? t('case-load-manager-onboarding-kop-model-send-to-pd')
            : t('case-load-manager-onboarding-kop-model-send-to-pd-and-chat')}
        </p>
      </Button>

      <AlertDialog open={isConfirmDialogOpen} onOpenChange={setIsConfirmDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {t('case-load-manager-onboarding-kop-model-send-confirmation-title')}
            </AlertDialogTitle>
            <AlertDialogDescription>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    isRepublishing || onboardingPatient.treatmentStartStandardMessageSent
                      ? t(
                          'case-load-manager-onboarding-kop-model-send-confirmation-desc-re-publish'
                        )
                      : t('case-load-manager-onboarding-kop-model-send-confirmation-desc-publish'),
                }}
              />
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>
              {t('case-load-manager-onboarding-kop-model-send-confirmation-cancel')}
            </AlertDialogCancel>
            <Button
              onClick={async () =>
                await publishKopModel({
                  id: onboardingPatient.id,
                  complaints,
                  circumstances,
                  personalStyle,
                  requestForHelp,
                  questionsForPreIntake,
                  myCommentsForDiagnosisAndTreatment,
                })
              }
            >
              {isPublishingKopModel ? (
                <div className="p-4 flex items-center space-x-2">
                  <Loader2 className="w-4 h-4 animate-spin" />
                  <p>{t('case-load-manager-onboarding-kop-model-send-confirmation-sending')}</p>
                </div>
              ) : (
                <p>{t('case-load-manager-onboarding-kop-model-send-confirmation-send')}</p>
              )}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog
        open={isMissingWireHandleDialogOpen}
        onOpenChange={setIsMissingWireHandleDialogOpen}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {t('case-load-manager-onboarding-missing-wire-handle-dialog-title')}
            </AlertDialogTitle>
            <AlertDialogDescription>
              <p>{t('case-load-manager-onboarding-missing-wire-handle-dialog-desc')}</p>
            </AlertDialogDescription>

            <p className="pt-1 font-bold">Wire handle</p>
            <Input autoFocus value={wireHandle} onChange={(e) => setWireHandle(e.target.value)} />

            {showWireUserError && (
              <div className="bg-red-100 text-red-600 p-2 rounded my-1">
                <p className="font-bold">
                  {t('case-load-manager-onboarding-missing-wire-handle-error-title')}
                </p>
                <p className="text-sm">
                  {t('case-load-manager-onboarding-missing-wire-handle-error-desc')}
                </p>
              </div>
            )}

            {wireHandleData?.userProvidedWireHandle && (
              <p className="pt-2 opacity-70">
                User provided wire handle: {wireHandleData?.userProvidedWireHandle}
              </p>
            )}
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>
              {t('case-load-manager-onboarding-kop-model-send-confirmation-cancel')}
            </AlertDialogCancel>
            <Button
              className="flex items-center space-x-2 px-4"
              disabled={!wireHandle}
              onClick={async () =>
                await saveWireHandleAndPublishKopModel({
                  id: onboardingPatient.id,
                  wireHandle,
                  complaints,
                  circumstances,
                  personalStyle,
                  requestForHelp,
                  questionsForPreIntake,
                  myCommentsForDiagnosisAndTreatment,
                })
              }
            >
              {isSavingWireHandleAndPublishingKopModel && (
                <Loader2 className="w-4 h-4 animate-spin" />
              )}
              <p>{t('case-load-manager-onboarding-kop-model-send-confirmation-send')}</p>
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
