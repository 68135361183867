import { ColumnDef } from "@tanstack/react-table";
import { ReferredPatient } from "../../../types";
import { useTranslation } from "react-i18next";
import { RefreshCcw } from "lucide-react";

export const ReferralSourceColName = "Referral Source";

export const ReferralSourceCol: ColumnDef<ReferredPatient> = {
  id: ReferralSourceColName,
  accessorKey: "referralSource",
  minSize: 200,
  maxSize: 200,
  header: ({ column }) => {
    const { t } = useTranslation();

    return <p>{"Referral Source"}</p>;
  },
  cell: ({ row }) => {
    const isTransfer = row.original.isTransferReferral;
    const howDidYouHearAboutUs = row.original.howDidYouHearAboutUs;

    if (!howDidYouHearAboutUs && !isTransfer) {
      return <></>;
    }

    return (
      <div>
        <p className={`${howDidYouHearAboutUs ? "visible" : "hidden"}`}>
          {howDidYouHearAboutUs}
        </p>
        <div
          className={`${
            isTransfer ? "visible flex items-center space-x-1" : "hidden"
          }`}
        >
          <div className="h-3 w-3">
            <RefreshCcw className="h-3 w-3" />
          </div>
          <p className="text-xs">Transfer referral</p>
        </div>
      </div>
    );
  },
};
