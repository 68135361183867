import { TFunction } from "i18next";
import { AlertCode, MainListPatient } from "./types";
import { IntakeOnColName } from "./columns/tableColumns/intakeOnCol";
import { IsPatientInsuredColName } from "./columns/tableColumns/isPatientInsuredCol";
import { LastRegularConsultOnColName } from "./columns/tableColumns/lastRegularConsultOnCol";
import { NumberOfRegularConsultsColName } from "./columns/tableColumns/numberOfRegularConsultsCol";
import { RegularConsultPsychologistsColName } from "./columns/tableColumns/regularConsultPsychologistsCol";
import { IsPatientContractedColName } from "./columns/tableColumns/isPatientContractedCol";
import { NextRegularConsultOnColName } from "./columns/tableColumns/nextRegularConsultOnCol";
import { LastChatConsultOnColName } from "./columns/tableColumns/lastChatConsultOnCol";
import { TreatmentColName } from "./columns/tableColumns/treatmentCol";

export const getReadableAlertText = (
  t: TFunction<"translation", undefined>,
  alertCode: AlertCode
) => {
  switch (alertCode) {
    case "NoRegularAppointmentsInLastMonth":
      return t("case-load-manager-alert-no-regular-appointment");
    case "NoChatAppointmentsInLastMonth":
      return t("case-load-manager-alert-no-chat-appointment");
    case "ChatAppointmentCreatedOutsideBusinessHours":
      return t("case-load-manager-alert-chat-appointment-outside-hours");
    default:
      return t("case-load-manager-alert-unknown");
  }
};

export const getDefaultColumnSizingFromLocalStorage = () => {
  const fromLocalStorage = localStorage.getItem("case-load-column-sizing");

  if (!fromLocalStorage || fromLocalStorage == "undefined") {
    return {};
  }

  return JSON.parse(fromLocalStorage);
};

export const getDefaultColumnVisibilityFromLocalStorage = (
  key: "case-load-column-visibility" | "automatic-billing-column-visibility"
) => {
  const fromLocalStorage = localStorage.getItem(key);
  return !fromLocalStorage || fromLocalStorage == "undefined"
    ? {
        [LastRegularConsultOnColName]: false,
        [NextRegularConsultOnColName]: false,
        [LastChatConsultOnColName]: false,
        [NumberOfRegularConsultsColName]: false,
        [RegularConsultPsychologistsColName]: false,
        [IntakeOnColName]: false,
        [IsPatientInsuredColName]: false,
        [IsPatientContractedColName]: false,
        [TreatmentColName]: false,
      } // hide a couple of columns by default
    : JSON.parse(fromLocalStorage);
};

export const getDefaultPageSize = (
  key: "case-load-page-size" | "automatic-billing-page-size"
) => {
  const fromLocalStorage = localStorage.getItem(key);
  return !fromLocalStorage || fromLocalStorage == "undefined"
    ? 100
    : Number(fromLocalStorage);
};

export const getOnlyCaseLoadEntriesWithAlertsFromLocalStorage = () => {
  const val = localStorage.getItem("only-show-case-load-with-alerts");
  return val == null ? false : val === "true";
};

export const getExcludeNoCheckinPatientsFromLocalStorage = () => {
  const val = localStorage.getItem("exclude-no-checkin-patients");
  return val == null ? false : val === "true";
};

export const getExcludeAlreadySupportedPatientsFromLocalStorage = () => {
  const val = localStorage.getItem("exclude-already-supported-patients");
  return val == null ? false : val === "true";
};

export const getOnlyShowSoonOffboardingPatients = () => {
  const val = localStorage.getItem("only-show-soon-offboarding-patients");
  return val == null ? false : val === "true";
};

export const getBackgroundOfRow = (
  data: MainListPatient,
  withHover: boolean
) => {
  if (data.hasChattedWith) {
    if (withHover) {
      return "bg-green-50 dark:bg-[#001202] hover:bg-green-100 dark:hover:bg-[#041f00] data-[state=selected]:bg-green-200";
    } else {
      return "bg-green-50 dark:bg-[#001202]";
    }
  }

  if (withHover) {
    return "bg-slate-50 dark:bg-slate-950 hover:bg-slate-100 dark:hover:bg-slate-900 data-[state=selected]:bg-slate-200";
  } else {
    return "bg-slate-50 dark:bg-slate-950";
  }
};
