import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import MobileStepper from "@mui/material/MobileStepper";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import useStores from "../../../useStores";

const StyledCardRoot = styled(Card)(({ theme }) => ({
  position: "absolute",
  top: "25%",
  left: "50%",
  transform: "translate(-50%, 0)",
  outline: "none",
  boxShadow: theme.shadows[20],
  width: 700,
  maxHeight: "100%",
  overflowY: "auto",
  maxWidth: "100%",
}));

const StyledMobileStepper = styled(MobileStepper)(({ theme }) => ({
  marginTop: theme.spacing(-2),
  marginRight: theme.spacing(-2),
  marginBottom: theme.spacing(2),
  marginLeft: theme.spacing(-2),
}));

const StyledCardActions = styled(CardActions)(({ theme }) => ({
  justifyContent: "flex-end",
}));

function SchedulePreviewModal({ open, onClose, scheduleDefinition }) {
  const [activeStep, setActiveStep] = useState(0);
  const { patientStore } = useStores();
  const { t } = useTranslation();

  useEffect(() => {
    setActiveStep(0);
  }, [scheduleDefinition]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getPreview = () => {
    return scheduleDefinition.messageDefinitions.sort(
      (a, b) => a.order - b.order
    )[activeStep];
  };

  if (!open) {
    return null;
  }

  return (
    <Modal onClose={onClose} open={open}>
      <form autoComplete="off" noValidate>
        <StyledCardRoot>
          <CardHeader title={`${scheduleDefinition.name} preview`} />
          <Divider />
          <CardContent>
            <StyledMobileStepper
              variant="dots"
              steps={scheduleDefinition.messageDefinitions.length}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={
                    activeStep ===
                    scheduleDefinition.messageDefinitions.length - 1
                  }
                >
                  Next
                  <KeyboardArrowRightIcon />
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  <KeyboardArrowLeftIcon />
                  Back
                </Button>
              }
            />
            <Typography>
              {t(getPreview().translationKey, {
                lng: patientStore.patient.languageCode,
              })}
            </Typography>
          </CardContent>
          <Divider />
          <StyledCardActions>
            <Button onClick={onClose}>Sluiten</Button>
          </StyledCardActions>
        </StyledCardRoot>
      </form>
    </Modal>
  );
}

export default SchedulePreviewModal;
