import tagEnums from "../../utils/constants/tags/tagEnums";
import experienceEnum from "../../utils/constants/tags/experienceEnum";
import emdrEnum from "../../utils/constants/tags/emdrEnum";
import schemaTherapyEnum from "../../utils/constants/tags/schemaTherapyEnum";
import locationEnum from "../../utils/constants/tags/locationEnum";

type CreatedTag = {
  TagType: string;
  TagName: string[];
};

export const createLocationTag = (selectedLocations: string[]): CreatedTag => {
  return {
    TagType: tagEnums.LOCATION,
    TagName:
      selectedLocations.length === 0
        ? [locationEnum.ONLINE]
        : selectedLocations,
  };
};

export const createTeamTag = (selectedTeams: string[]): CreatedTag => {
  return {
    TagType: tagEnums.TEAM,
    TagName: selectedTeams,
  };
};

export const createLanguageTag = (selectedLanguages: string[]): CreatedTag => {
  return {
    TagType: tagEnums.LANGUAGE,
    TagName: selectedLanguages,
  };
};

export const createGenderTag = (selectedGenders: string[]): CreatedTag => {
  return {
    TagType: tagEnums.GENDER,
    TagName: selectedGenders,
  };
};

export const createEMDRTag = (isEmdrSelected: boolean): CreatedTag => {
  return {
    TagType: tagEnums.EMDR,
    TagName: [isEmdrSelected ? emdrEnum.YES : emdrEnum.NO],
  };
};

export const createSchemaTherapyExperienceTag = (
  isSchemaTherapySelected: boolean
): CreatedTag => {
  return {
    TagType: tagEnums.SCHEMA_THERAPY_EXPERIENCE,
    TagName: [
      isSchemaTherapySelected ? schemaTherapyEnum.YES : schemaTherapyEnum.NO,
    ],
  };
};

export const createExperienceTag = (
  isExperienceSelected: boolean
): CreatedTag => {
  return {
    TagType: tagEnums.EXPERIENCE,
    TagName: [isExperienceSelected ? experienceEnum.GZ : experienceEnum.BASIS],
  };
};

export const createStatusTag = (selectedStatus: string) => {
  return {
    TagType: tagEnums.STATUS,
    TagName: [selectedStatus],
  };
};
