import { Bot, ChevronDown, ChevronUp } from "lucide-react";
import { ReferralDetails } from "../types";
import { useState } from "react";
import { relevances } from "../constants";

export const AiReferralLetterSummaryAndRelevance = ({
  referral,
  showRelevance,
}: {
  referral: ReferralDetails;
  showRelevance: boolean;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!referral.proposedRelevanceJustification) return <></>;

  if (
    (showRelevance && !referral.proposedRelevance) ||
    referral.proposedRelevance == "Unspecified"
  )
    return <></>;

  return (
    <div className="mt-2 bg-blue-50 dark:bg-slate-900 text-blue-500 dark:text-muted-foreground rounded p-3 text-sm">
      <div
        className="w-full flex items-center justify-between cursor-pointer"
        onClick={() => setIsExpanded((prev) => !prev)}
      >
        <div className="flex items-center space-x-2">
          <div className="h-6 w-6">
            <Bot className="h-6 w-6" />
          </div>

          {showRelevance ? (
            <p>
              AI proposed relevance:{" "}
              <span className="font-bold">
                {
                  relevances.filter(
                    (r) => r.value == referral.proposedRelevance
                  )[0].label
                }
              </span>
            </p>
          ) : (
            <p>View referral letter summary from AI:</p>
          )}
        </div>

        <div className="h-4 w-4 opacity-70 hover:opacity-100">
          {isExpanded ? (
            <ChevronUp className="h-4 w-4" />
          ) : (
            <ChevronDown className="h-4 w-4" />
          )}
        </div>
      </div>

      <div className={`${isExpanded ? "visible ml-6 p-2 text-xs" : "hidden"}`}>
        <p className="pt-2">{referral.proposedRelevanceJustification}</p>
        {referral.proposedRelevanceMissingInformation && (
          <p className="border-t pt-2 mt-2">
            {referral.proposedRelevanceMissingInformation}
          </p>
        )}
      </div>
    </div>
  );
};
