import { colors } from "@mui/material";
import React, { Fragment } from "react";
import { observer } from "mobx-react";
import Label from "./Label";

const PayStatus = ({ billing }) => {
    const payStatusColors = {
        finished: colors.green[600],
        open: colors.orange[600]
    };

    if (billing.$type === "ECurringBilling") {
        if (billing.billingAgreedAt && billing.subscriptionConfirmedAt) {
            return (
                <Label color={payStatusColors.finished}>
                    bevestigd
                </Label>
            );
        }

        if (billing.billingAgreedAt && !billing.subscriptionConfirmedAt) {
            return (
                <Label color={payStatusColors.open}>
                    halverwege
                </Label>
            );
        }
        return (
            <Label color={payStatusColors.open}>
                open
            </Label>
        );
    }
    return (
        <>
            {
                billing.billingAgreedAt
                    ? (
                        <Label color={payStatusColors.finished}>
                            bevestigd
                        </Label>
                    )
                    : (
                        <Label color={payStatusColors.open}>
                            open
                        </Label>
                    )
            }
        </>
    );
};

export default observer(PayStatus);
