import axios from 'axios';
import { useQuery } from 'react-query';
import { UnreadMessageEmailNotificationActivityLog } from './types';

export const useGetUnreadCountOfConversations = (
  searchTerm: string,
  epdPatientId: number | null,
  skip: number
) => {
  const query = useQuery<{
    data: UnreadMessageEmailNotificationActivityLog[];
    pagination: {
      currentPage: number;
      total: number;
      limit: number;
    };
  }>(
    ['admin', 'chat', 'unread-messages-reminders', searchTerm, epdPatientId, skip],
    async () => {
      let url = new URL(
        `${window.location.protocol}//${window.location.host}/api/AdminChat/unread-notification-emails`
      );

      if (searchTerm) url.searchParams.append('searchTerm', searchTerm);

      if (epdPatientId) url.searchParams.append('epdPatientId', epdPatientId.toString());

      if (skip) url.searchParams.append('skip', skip.toString());

      const res = await axios.get(url.toString());
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};
