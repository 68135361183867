import { useSavePersonalInfo, useGetReferralDetails } from "../hooks";
import { useState } from "react";
import { Milestones } from "./milestones";
import { useTranslation } from "react-i18next";
import { ReferralNotes } from "./referralNotes";
import { ActivityLogs } from "./activityLogs";
import { CalendlyDetails } from "./calendly/calendlyDetails";
import { ReferralProgressStepper } from "./progressIndicator/referralProgressStepper";
import { ReferralProgressInfoCompletion } from "./progressIndicator/referralProgressInfoCompletion";
import { ReferralProgressInfoRejection } from "./progressIndicator/referralProgressInfoRejection";
import { IntakeDetails } from "./intakeDetails";
import { ReferralLetter } from "./referralLetter";
import { PraktjikdataSection } from "./praktijkdata/praktijkdataSection";
import {
  HorizontalTab,
  HorizontalTabContent,
  HorizontalTabHeader,
} from "../../../components/ui/horizontalTab";
import { RegistrationStatus } from "../types";
import { DetailsLoadingSkeleton } from "./detailsLoadingSkeleton";
import { DetailsHeader } from "./detailsHeader";
import { PersonalInfo } from "./personalInfo";
import { toast } from "sonner";
import { ForgotToSendRejectionEmail } from "./reject/forgotToSendRejectionEmail";
import { ReopenRejectedReferral } from "./reject/reopenRejectedReferral";
import { Copy, Hourglass } from "lucide-react";
import React from "react";
import { NextStepDetails } from "./nextStep";
import { AiReferralLetterSummaryAndRelevance } from "./aiReferralLetterSummaryAndRelevance";
import { TransferReferralWarning } from "./transferReferralWarning";

export const Details = ({ referralId }: { referralId: string }) => {
  const { t } = useTranslation();
  const [uploadReferralLetterOpen, setUploadReferralLetterOpen] =
    useState(false);
  const [uploadReferralAttachmentOpen, setUploadReferralAttachmentOpen] =
    useState(false);
  const [viewReferralLetterOpen, setViewReferralLetterOpen] = useState(false);

  const { data: referral, isLoading } = useGetReferralDetails(referralId);

  if (isLoading || !referral) {
    return <DetailsLoadingSkeleton />;
  }

  const isReadOnly =
    referral.status === "AwaitingIWelcomeEmailSend" ||
    referral.status === "AwaitingPatientApproval" ||
    referral.status === "Rejected" ||
    referral.status === "Archived" ||
    referral.status === "Cancelled" ||
    referral.status === "Closed";

  const getDuplicateLinks = () => {
    const duplicatedCrmEntryIds = referral.duplicatedPatientReferralEntryIds
      .map((id, i, arr) => (
        <React.Fragment key={id}>
          <a
            className="text-link"
            href={`${window.origin}/patient-referrals?id=${id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            #{i + 1}
          </a>
          {i < arr.length + referral.duplicatedPatientIds.length - 1 && ", "}
        </React.Fragment>
      ))
      .concat(
        referral.duplicatedPatientIds.map((id, i, arr) => (
          <React.Fragment key={id}>
            <a
              className="text-link"
              href={`${window.origin}/patient/${id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              #{i + referral.duplicatedPatientReferralEntryIds.length + 1}
            </a>
            {i < arr.length - 1 && ", "}
          </React.Fragment>
        ))
      );

    return <>{duplicatedCrmEntryIds}</>;
  };

  const isOnInsurerWaitingList =
    referral.healthCareInsurer?.concern == "VGZ" ||
    referral.healthCareInsurer?.concern == "MENZIS";

  const showAsDuplicate =
    referral.duplicatedPatientReferralEntryIds.length > 0 ||
    referral.duplicatedPatientIds.length > 0;

  return (
    <HorizontalTab>
      <div className="w-full relative">
        <TransferReferralWarning referral={referral} />

        {isOnInsurerWaitingList ? (
          <div className="inline-flex space-x-2 items-center text-red-800 dark:text-red-300 bg-red-100 dark:bg-red-900 py-2 px-3 rounded mb-4 mr-2">
            <div className="w-4 h-4">
              <Hourglass className="w-4 h-4" />
            </div>
            <p>Intake plafond</p>
          </div>
        ) : (
          <></>
        )}

        {showAsDuplicate ? (
          <div className="inline-flex space-x-2 items-center text-amber-800 dark:text-amber-300 bg-amber-100 dark:bg-amber-900 py-2 px-3 rounded mb-4">
            <div className="w-4 h-4">
              <Copy className="w-4 h-4" />
            </div>
            <p>
              This might be a duplicate of the following: {getDuplicateLinks()}
            </p>
          </div>
        ) : (
          <></>
        )}

        <DetailsHeader referral={referral} />

        <ReferralProgressStepper
          allSteps={[
            RegistrationStatus.New,
            RegistrationStatus.ReachOut,
            RegistrationStatus.RegisterPatientAndIntake,
            RegistrationStatus.AwaitingIWelcomeEmailSend,
            RegistrationStatus.Closed,
          ]}
          currentStep={referral.status || "New"}
        />
        <ReferralProgressInfoRejection referral={referral} />
        <ReferralProgressInfoCompletion referral={referral} />

        <div className="mt-6 flex items-center ml-3">
          <HorizontalTabHeader index={0}>Intake details</HorizontalTabHeader>
          <HorizontalTabHeader index={1}>Personal details</HorizontalTabHeader>
          <HorizontalTabHeader index={2}>Activity logs</HorizontalTabHeader>
        </div>
        <HorizontalTabContent index={0}>
          <div>
            <div className="flex">
              <div className="flex-1">
                <ForgotToSendRejectionEmail referral={referral} />
                <ReopenRejectedReferral referral={referral} />

                <PraktjikdataSection referral={referral} />

                <CalendlyDetails isReadOnly={isReadOnly} referral={referral} />

                <IntakeDetails referralDetails={referral} />
              </div>
              <div className="flex-1 pl-8">
                <NextStepDetails
                  referral={referral}
                  value={referral.nextStep}
                />
                <ReferralLetter
                  isReadOnly={isReadOnly}
                  referralId={referralId}
                  documentMetadata={referral.documents || []}
                  uploadReferralLetterOpen={uploadReferralLetterOpen}
                  setUploadReferralLetterOpen={setUploadReferralLetterOpen}
                  uploadReferralAttachmentOpen={uploadReferralAttachmentOpen}
                  setUploadReferralAttachmentOpen={
                    setUploadReferralAttachmentOpen
                  }
                  viewReferralLetterOpen={viewReferralLetterOpen}
                  setViewReferralLetterOpen={setViewReferralLetterOpen}
                />
                <AiReferralLetterSummaryAndRelevance
                  showRelevance={false}
                  referral={referral!}
                />

                <div className="mt-8">
                  <ReferralNotes
                    referralId={referralId}
                    referralNotes={referral.referralNotes}
                  />
                </div>
                <div className="mt-12">
                  <Milestones data={referral.milestones || []} />
                </div>
              </div>
            </div>
          </div>
        </HorizontalTabContent>

        <HorizontalTabContent index={1}>
          <PersonalInfo
            isManualReferralInput={false}
            referral={referral}
            saveHook={() =>
              useSavePersonalInfo(() => {
                toast("Patient referral updated", {
                  description:
                    "The patient referral has been updated successfully",
                });
              })
            }
            saveRequestMapper={(data) => {
              return {
                referralId: referral.id,
                personalInformation: data,
              };
            }}
          />
        </HorizontalTabContent>

        <HorizontalTabContent index={2}>
          <ActivityLogs referralId={referralId} />
        </HorizontalTabContent>
      </div>
    </HorizontalTab>
  );
};
