import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../components/ui/select";
import { Calendar } from "../../../../../components/ui/calendar";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "../../../../../components/ui/popover";
import { Button } from "../../../../../components/ui/button";
import { CalendarIcon } from "lucide-react";
import { getSimpleFormattedDate } from "../../../../../utils/dateUtils";

type FiltersProps = {
  selectedInsurer: "MENZIS" | "VGZ" | "all";
  setSelectedInsurer: (newInsurer: "MENZIS" | "VGZ" | "all") => void;
  from: Date;
  setFrom: (newDate: Date) => void;
};

export const Filters = (props: FiltersProps) => {
  return (
    <div className="flex items-center mt-6 space-x-2">
      <div className="w-1/3">
        <Select
          value={props.selectedInsurer.toString()}
          onValueChange={(newValue) =>
            props.setSelectedInsurer(newValue as "MENZIS" | "VGZ" | "all")
          }
        >
          <SelectTrigger>
            <SelectValue />
          </SelectTrigger>
          <SelectContent className="z-[9999999]">
            <SelectGroup>
              <SelectItem value="all">All</SelectItem>
              <SelectItem value="MENZIS">Menzis</SelectItem>
              <SelectItem value="VGZ">VGZ</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>

      <div className="w-1/3">
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant={"outline"}
              className="w-full text-left font-normal"
            >
              <span>{getSimpleFormattedDate(props.from)}</span>
              <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="z-[999999] min-w-fit" align="start">
            <Calendar
              mode="single"
              toDate={undefined}
              selected={props.from}
              defaultMonth={props.from}
              onSelect={(_, d) => {
                props.setFrom(d);
              }}
            />
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
};
