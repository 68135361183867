import React from "react";
import { observer } from "mobx-react";
import moment from "moment";
import { Grid, Typography } from "@mui/material";

function CalendarHeader({ date, ...rest }) {
  return (
    <div {...rest}>
      <Grid
        alignItems="center"
        container
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item container justifyContent="center">
          <Typography variant="h3">
            {moment(date).format("MMMM YYYY")}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default observer(CalendarHeader);
