import { useGetCaseLoadWeekByWeekProgressChartEmployeeFilters } from "../hooks";
import { useState } from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import { HistoryTable } from "./historyTable";
import { Input } from "../../../../components/ui/input";
import { useDebounce } from "use-debounce";

export const AutoBillingKpis = () => {
  const [skip, setSkip] = useState(0);
  const [selectedPsy, setselectedPsy] = useState("full-team");
  const [clientEpdId, setClientEpdId] = useState<number | null>(null);
  const [debouncedClientEpdId] = useDebounce(clientEpdId, 500);

  const { data: employees, isLoading: isLoadingEmployees } =
    useGetCaseLoadWeekByWeekProgressChartEmployeeFilters();

  return (
    <div className="p-4">
      <h2 className="text-4xl font-bold">Automatic Billing KPIs</h2>
      <p className="pt-1">
        View the automatic bills the Caseload Manager issued for Online
        Psychologists.
      </p>

      <div className="pt-6 flex items-center space-x-2 w-full">
        <div className="w-1/4">
          <p className="font-bold pb-1">Psychologist</p>
          <Select
            value={selectedPsy}
            disabled={isLoadingEmployees}
            onValueChange={(x) => {
              setselectedPsy(x);
            }}
          >
            <SelectTrigger>
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectItem value="full-team">Full team</SelectItem>
                {employees?.map((employee) => {
                  return (
                    <SelectItem value={employee.employeeEpdId.toString()}>
                      {employee.name}
                    </SelectItem>
                  );
                })}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        <div className="w-1/4">
          <p>Client No.</p>
          <Input
            type="number"
            autoFocus
            value={clientEpdId ?? undefined}
            onChange={(event) => {
              if (event.target.value === "") {
                setClientEpdId(null);
              } else {
                setClientEpdId(Number(event.target.value));
              }
            }}
          />
        </div>
      </div>
      <section className="pt-2">
        <HistoryTable
          skip={skip}
          setSkip={setSkip}
          selectedPsy={selectedPsy}
          clientEpdId={debouncedClientEpdId}
        />
      </section>
    </div>
  );
};
