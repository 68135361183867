import { ConversationFolder } from "./folder/folder";
import { useGetFolders, useReorderFolders } from "./hooks";
import { Skeleton } from "../../../components/ui/skeleton";

export const Folders = () => {
  const { data, isLoading } = useGetFolders();
  const { mutate: reorderFolders } = useReorderFolders();

  const reorder = (id: string, delta: number) => {
    const orderedIds = data!.map((folder) => folder.id);
    const index = orderedIds.indexOf(id);
    orderedIds.splice(index, 1);
    orderedIds.splice(index + delta, 0, id);

    reorderFolders({
      orderedIds,
    });
  };

  return (
    <div className="space-y-2">
      {isLoading ? (
        <div className="space-y-1">
          <Skeleton className="h-10 w-full rounded" />
          <Skeleton className="h-10 w-full rounded" />
          <Skeleton className="h-10 w-full rounded" />
        </div>
      ) : (
        <>
          {data!.length == 0 ? (
            <p className="italic opacity-75">
              You don't have any folders, yet.
            </p>
          ) : (
            <>
              {data!.map((folder, index) => {
                return (
                  <ConversationFolder
                    key={folder.id}
                    isFirst={index === 0}
                    isLast={index === data!.length - 1}
                    onMoveUp={() => reorder(folder.id, -1)}
                    onMoveDown={() => reorder(folder.id, +1)}
                    folder={{
                      id: folder.id,
                      name: folder.name,
                      channelIds: folder.channelIds,
                      subFolders: folder.subFolders,
                    }}
                  />
                );
              })}
            </>
          )}
        </>
      )}
    </div>
  );
};
