import { Call } from "@stream-io/video-react-sdk";
import { MutatingDots } from "react-loader-spinner";
import { LeaveCallButton } from "./leaveCallButton";

export const InitializingCall = ({
  call,
  onClose,
}: {
  call: Call | null;
  onClose: () => void;
}) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-full pt-12">
      <MutatingDots
        visible={true}
        height="100"
        width="100"
        color="#334155"
        secondaryColor="#334155"
        radius="12.5"
        ariaLabel="mutating-dots-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
      <h1 className="text-3xl pt-12">Setting the call up</h1>
      <p className="pt-2 text-lg w-[400px] text-center mb-12">One moment...</p>
      <LeaveCallButton call={call} onClick={onClose} />
    </div>
  );
};
