import React, { useState } from "react";
import { styled } from "@mui/system";
import { observer } from "mobx-react";
import {
  CardHeader,
  Divider,
  CardContent,
  Button,
  Table,
  TableBody,
  TableRow,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CardActions,
  Collapse,
  Card,
} from "@mui/material";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TableCell from "@mui/material/TableCell/TableCell";
import moment from "moment";
import * as colors from "@mui/material/colors";
import LockOpenIcon from "@mui/icons-material/LockOpenOutlined";
import { trackPromise } from "react-promise-tracker";
import { useSnackbar } from "notistack";
import Label from "../../../shared/Label";
import eCurringSubscriptionStatusEnum from "../../../utils/constants/eCurringSubscriptionStatusEnum";
import ConfirmDialog from "../../../shared/ConfirmDialog";
import areas from "../../../utils/constants/areas";
import Loader from "../../../shared/Loader";
import useStores from "../../../useStores";

const StyledCardContent = styled(CardContent)(() => ({
  padding: 0,
}));

const StyledLockOpenIcon = styled(LockOpenIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textDecoration: "none !important",
}));

const sortByDueDate = (a, b) => {
  if (a.dueDate > b.dueDate) {
    return 1;
  }
  if (b.dueDate > a.dueDate) {
    return -1;
  }
  return 0;
};

const renderPaymentStatus = (billing) => {
  if (billing.closedAt !== null) {
    return <Label color={colors.green[600]}>Voltooid</Label>;
  }

  if (billing.isFinalTransactionScheduled) {
    return (
      <Label color={colors.green[600]}>Laatste transactie ingepland</Label>
    );
  }

  switch (billing.eCurringSubscriptionStatus) {
    case eCurringSubscriptionStatusEnum.UNVERIFIED:
      return <Label color={colors.orange[600]}>iPay open</Label>;
    case eCurringSubscriptionStatusEnum.ACTIVE:
      return <Label color={colors.green[600]}>Abonnement actief</Label>;
    case eCurringSubscriptionStatusEnum.PAUSED:
      return <Label color={colors.green[600]}>Abonnement gepauzeerd</Label>;
    case eCurringSubscriptionStatusEnum.CANCELLED:
      return <Label color={colors.red[600]}>Abonnement geannuleerd</Label>;
    default:
      return <Label color={colors.red[600]}>ERROR: status onbekend</Label>;
  }
};

const Transactions = () => {
  const { patientStore } = useStores();
  const { billing } = patientStore;
  const [confirmManuallyCloseIsOpen, setConfirmManuallyCloseIsOpen] =
    useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const manuallyCloseBilling = () => {
    setConfirmManuallyCloseIsOpen(false);
    trackPromise(
      patientStore.manuallyCloseBilling().then(
        () => {},
        (error) => {
          enqueueSnackbar(error.response.data, { variant: "error" });
        }
      ),
      areas.TRANSACTIONS
    );
  };

  const PAYMENT_REFUNDED = ["Refunded"];
  const PAYMENT_FAILED = [
    "PaymentFailed",
    "Failed",
    "ChargedBack",
    "PaymentReminderScheduled",
    "PaymentReminderSent",
    "PaymentReminderOverdue",
    "PaymentCanceled",
  ];
  const PAYMENT_PLANNED = ["Scheduled", "Rescheduled", "Succeeded"];
  const PAYMENT_RECEIVED = ["Fulfilled"];

  const [paymentsInOpen, setPaymentsInOpen] = useState(false);
  const [paymentsPlannedOpen, setPaymentsPlannedOpen] = useState(false);
  const [paymentsFailedOpen, setPaymentsFailedOpen] = useState(false);
  const [paymentsRefundedOpen, setPaymentsRefundedOpen] = useState(false);

  const handleClickPaymentsIn = () => {
    setPaymentsInOpen(!paymentsInOpen);
  };
  const handleClickPaymentsPlanned = () => {
    setPaymentsPlannedOpen(!paymentsPlannedOpen);
  };
  const handleClickPaymentsFailed = () => {
    setPaymentsFailedOpen(!paymentsFailedOpen);
  };
  const handleClickPaymentsRefunded = () => {
    setPaymentsRefundedOpen(!paymentsRefundedOpen);
  };

  if (!billing || !billing.transactions) {
    return null;
  }

  const contains = (arr, searchValue) => {
    return arr.indexOf(searchValue) > -1;
  };

  const getTotalForStatus = (statuses) => {
    return billing.transactions
      .filter((transaction) => contains(statuses, transaction.status))
      .sort(sortByDueDate)
      .reduce((total, transaction) => {
        return total + transaction.amount;
      }, 0);
  };

  const displayTransactionsWithStatus = (statuses) => {
    return (
      <List component="div" disablePadding>
        {billing.transactions
          .filter((transaction) => contains(statuses, transaction.status))
          .sort(sortByDueDate)
          .map((transaction) => (
            <a
              href={`https://app.ecurring.com/transactions/${transaction.id}`}
              target="_blank"
              rel="noopener noreferrer"
              key={transaction.id}
            >
              <StyledListItem button>
                <ListItemIcon>
                  <ReceiptIcon />
                </ListItemIcon>
                ID: {transaction.id}
                <br />
                Datum: {moment(transaction.dueDate).format("DD-MM-YYYY")}
                <br />
                Bedrag: €{transaction.amount}
                <br />
                Status: {transaction.status}
              </StyledListItem>
            </a>
          ))}
      </List>
    );
  };

  return (
    <Loader area={areas.TRANSACTIONS}>
      <Card>
        <CardHeader title="Betalingen (ecurring)" />

        <StyledCardContent>
          <Divider />
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>eCurring customer ID</TableCell>
                <TableCell>{billing.eCurringCustomerId}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>eCurring subscription ID</TableCell>
                <TableCell>{billing.eCurringSubscriptionId}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Betaal Status</TableCell>
                <TableCell>{renderPaymentStatus(billing)}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Payment percentage</TableCell>
                <TableCell>{billing.paymentPercentage}%</TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <List>
            <ListItem
              button
              onClick={handleClickPaymentsIn}
              key="payments_received"
            >
              <ListItemText
                primary={`Betalingen binnen (€${getTotalForStatus(
                  PAYMENT_RECEIVED
                )} totaal)`}
              />
              {paymentsInOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>

            <Collapse in={paymentsInOpen} timeout="auto" unmountOnExit>
              {displayTransactionsWithStatus(PAYMENT_RECEIVED)}
            </Collapse>

            <ListItem
              button
              onClick={handleClickPaymentsPlanned}
              key="payments_planned"
            >
              <ListItemText
                primary={`Betalingen gepland (€${getTotalForStatus(
                  PAYMENT_PLANNED
                )} totaal)`}
              />
              {paymentsPlannedOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>

            <Collapse in={paymentsPlannedOpen} timeout="auto" unmountOnExit>
              {displayTransactionsWithStatus(PAYMENT_PLANNED)}
            </Collapse>

            <ListItem
              button
              onClick={handleClickPaymentsFailed}
              key="payments_failed"
            >
              <ListItemText
                primary={`Betalingen mislukt (€${getTotalForStatus(
                  PAYMENT_FAILED
                )} totaal)`}
              />
              {paymentsFailedOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>

            <Collapse in={paymentsFailedOpen} timeout="auto" unmountOnExit>
              {displayTransactionsWithStatus(PAYMENT_FAILED)}
            </Collapse>

            <ListItem
              button
              onClick={handleClickPaymentsRefunded}
              key="payments_refunded"
            >
              <ListItemText
                primary={`Betalingen teruggestort (€${getTotalForStatus(
                  PAYMENT_REFUNDED
                )} totaal)`}
              />
              {paymentsFailedOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItem>

            <Collapse in={paymentsRefundedOpen} timeout="auto" unmountOnExit>
              {displayTransactionsWithStatus(PAYMENT_REFUNDED)}
            </Collapse>
          </List>
        </StyledCardContent>

        <Divider />

        <CardActions>
          <Button
            onClick={() => setConfirmManuallyCloseIsOpen(true)}
            disabled={billing.closedAt}
          >
            <StyledLockOpenIcon />
            Handmatig betaling op voltooid zetten
          </Button>
          <ConfirmDialog
            question="Weet je zeker dat je de betaling handmatig op voltooid wil zetten? Dit sluit niet automatisch de subscription in eCurring! Vergeet niet in een notitie te vermelden wat de reden was."
            isOpen={confirmManuallyCloseIsOpen}
            onConfirm={manuallyCloseBilling}
            onCancel={() => setConfirmManuallyCloseIsOpen(false)}
          />
        </CardActions>
      </Card>
    </Loader>
  );
};

export default observer(Transactions);
