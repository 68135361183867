export function IPracticeAvatar({ className }: { className?: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 124 137"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.70835 83.1367C-10.4922 48.1939 6.51366 11.9833 44.8116 2.00555C75.0793 -5.8803 110.566 10.2885 120.502 36.4936C129.961 61.4399 119.284 84.1285 91.4462 98.2372C85.7213 101.139 80.28 104.168 79.3535 104.969C76.9463 107.05 76.0448 106.071 74.1338 99.3005C72.0312 91.8475 71.6158 92.4095 83.8304 86.1995C107.262 74.2878 115.15 57.7308 106.13 39.3956C92.3138 11.3161 48.7733 5.5052 25.6412 28.6544C6.33095 47.9784 11.2102 76.0182 38.262 101.186C46.8044 109.133 49.4602 110.831 48.0993 107.472C39.3183 85.8142 45.822 50.3036 58.5686 50.3036C65.5126 50.3036 67.3886 56.6377 62.6451 64.0748C54.8176 76.3488 57.3875 98.0496 69.5082 122.022C73.5807 130.076 73.7424 131.803 70.7472 135.271C63.1693 144.044 18.9838 108.072 6.70835 83.1367Z"
        fill="white"
      />
      <path
        d="M69.5561 48.1005C60.8141 46.0276 60.1242 34.5448 68.5577 31.479C72.0867 30.1987 76.3699 32.5264 78.1364 35.9218C81.2645 41.9372 75.8671 49.5967 69.5561 48.1005Z"
        fill="white"
      />
    </svg>
  );
}
