import { action, makeObservable, observable, runInAction } from "mobx";
import axios from "axios";

class EmployeesStore {
  employees = [];

  constructor() {
    makeObservable(this, {
      employees: observable,
      fetchEmployees: action,
    });
  }

  async fetchEmployees() {
    const res = await axios.get(`employees`);

    if (Array.isArray(res.data)) {
      runInAction(() => {
        this.employees = res.data;
      });
    }
  }
}

export default EmployeesStore;
