import { Loader2, RefreshCw } from "lucide-react";
import { Button } from "../../../components/ui/button";
import { useRetriggerBigRegistration } from "../hooks";
import { toast } from "sonner";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
import { useState } from "react";

export const TriggerValidation = () => {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const { mutate, isLoading } = useRetriggerBigRegistration(() => {
    setIsConfirmationDialogOpen(false);
    toast("Data refreshed", {
      description:
        "The system re-validated all the data from the BIG Register.",
    });
  });

  return (
    <>
      <Button
        className="flex items-center space-x-2"
        variant="destructive"
        onClick={() => setIsConfirmationDialogOpen(true)}
      >
        <div className="w-4 h-4">
          <RefreshCw className="w-4 h-4" />
        </div>

        <p>Re-run BIG validation</p>
      </Button>

      <Dialog
        open={isConfirmationDialogOpen}
        onOpenChange={(newVal) => {
          setIsConfirmationDialogOpen(newVal);
        }}
      >
        <DialogContent className="min-w-[20vw]">
          <DialogHeader>
            <DialogTitle>Are you sure?</DialogTitle>
            <DialogDescription>
              Revalidating the data may take a while. Are you sure you want to
              continue?
            </DialogDescription>
          </DialogHeader>

          <DialogFooter className="gap-2">
            <Button
              variant="outline"
              onClick={() => setIsConfirmationDialogOpen(false)}
              className="w-1/2"
            >
              No, cancel
            </Button>
            <Button
              onClick={async () => {
                if (isLoading) return;

                await mutate();
              }}
              className="w-1/2 flex items-center space-x-2"
            >
              {isLoading && <Loader2 className="w-4 h-4 animate-spin" />}
              <p>Yes, regenerate data</p>
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
