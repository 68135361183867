import { Call } from "@stream-io/video-react-sdk";
import { Button } from "../../../components/ui/button";
import { PhoneOff } from "lucide-react";

export const LeaveCallButton = ({
  call,
  onClick,
}: {
  call: Call | null;
  onClick: () => void;
}) => {
  return (
    <Button
      variant="destructive"
      className="h-16 w-16 rounded-full flex items-center justify-center"
      onClick={async () => {
        try {
          await call?.leave();
        } catch (error) {
          // do nothing
        }

        onClick();
      }}
    >
      <div className="h-6 w-6">
        <PhoneOff className="h-6 w-6" />
      </div>
    </Button>
  );
};
