import { Trash2 } from "lucide-react";
import { Button } from "../../../../components/ui/button";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogFooter,
} from "../../../../components/ui/dialog";
import { useState } from "react";
import { useCancelPreIntake } from "../../hooks";
import { toast } from "sonner";

export const CancelPreIntakeButton = ({
  referralId,
  isPreIntakeInPraktijkData,
}: {
  referralId: string;
  isPreIntakeInPraktijkData: boolean;
}) => {
  const [open, setOpen] = useState(false);

  const { mutate, isLoading } = useCancelPreIntake(() => {
    setOpen(false);
    toast("Pre-intake cancelled", {
      description: "The pre-intake has been cancelled.",
    });
  });

  return (
    <>
      <Button
        variant="outline"
        className="flex items-center space-x-2"
        onClick={() => setOpen(true)}
      >
        <div className="w-4 h-4">
          <Trash2 className="h-4 w-4" />
        </div>
        <p>Cancel pre-intake</p>
      </Button>

      <Dialog
        open={open}
        onOpenChange={(newValue) => {
          setOpen(newValue);
        }}
      >
        <DialogContent className="min-w-[25vw]">
          <DialogTitle>Are you sure?</DialogTitle>
          <p>
            You are about to cancel the pre-intake.{" "}
            {isPreIntakeInPraktijkData &&
              "This will remove the appointment from Praktijkdata as well."}{" "}
            Are you sure to continue?
          </p>
          <DialogFooter className="flex items-center justify-between space-x-2">
            <Button variant="outline" onClick={() => setOpen(false)}>
              No, keep the pre-intake
            </Button>
            <Button
              variant="destructive"
              onClick={async () => {
                await mutate({ referralId });
              }}
            >
              {isLoading
                ? "Cancelling pre-intake..."
                : "Yes, cancel the pre-intake"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
