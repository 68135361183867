import React from "react";
import { Grid } from "@mui/material";
import UsersTable from "./UsersTable";
import StyledRoot from "../../shared/StyledRootDiv";

const AdminPage = () => {
  return (
    <StyledRoot>
      <Grid container justifyContent="center">
        <Grid item xs={10}>
          <UsersTable />
        </Grid>
      </Grid>
    </StyledRoot>
  );
};

export default AdminPage;
