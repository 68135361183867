import { Button } from "../../../components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandList,
  CommandInput,
  CommandItem,
} from "../../../components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../components/ui/popover";
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "../../../lib/utils";
import { useState } from "react";
import useStores from "../../../useStores";
import psychologistNameEnum from "../../../utils/constants/psychologistNameEnum";

export const PsychologistFilter = ({
  selectedPsychologistId,
  setSelectedPsychologistId,
  selectedPsychologist,
}: {
  selectedPsychologistId: string;
  setSelectedPsychologistId: (id: string) => void;
  selectedPsychologist: any;
}) => {
  const [open, setOpen] = useState(false);
  const { calendarStore } = useStores();
  const psychologists = calendarStore.getSortedEmployees(selectedPsychologist);

  // instead of "John Doe", we show "Doe John" (swap firstnames/lastnames)
  const getDisplayName = (fullName: string) => {
    const splitName = fullName.split(" ");

    const firstName = splitName[0];
    const lastNames = splitName.slice(1).join(" ");

    return `${lastNames} ${firstName}`;
  };

  const PsychologistEntryComponent = ({
    psychologist,
  }: {
    psychologist: any;
  }) => {
    if (!psychologist) return <></>;

    return (
      <p>
        {`${getDisplayName(psychologist.name)} - ${
          psychologist.earliestOpenSlot !== "NULL" &&
          psychologist.earliestOpenSlot !== ""
            ? `${psychologist.earliestOpenSlot} - `
            : ""
        }${psychologist.predictedOccupancyAtEarliestOpenSlot ?? ""}${
          psychologist.predictedOccupancyAtEarliestOpenSlot !== undefined
            ? "%"
            : ""
        }`}
      </p>
    );
  };

  return (
    <Popover open={open} onOpenChange={setOpen} modal={true}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={`w-[300px] border-ipractice-blue/50 hover:border-ipractice-blue justify-between font-normal text-ipractice-blue hover:text-ipractice-blue bg-transparent`}
        >
          {selectedPsychologistId ? (
            <PsychologistEntryComponent
              psychologist={psychologists.find(
                (x) => x.employeeId === selectedPsychologistId
              )}
            />
          ) : (
            "Selecteer een psycholoog..."
          )}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="start"
        side="bottom"
        className="w-[600px] p-0 z-[9999999]"
      >
        <Command
          filter={(value, search) => {
            // value contains each entry in the dropdown list -> we find the full model of it:
            const currentPsy = psychologists.filter(
              (p) => p.employeeId === value
            );

            if (currentPsy.length === 0) return 0;

            // we display "Doe John", but if the user search for 'John D', we should still find the psy
            // for this, we concat the reversed name w/ the original: "John Doe Doe John"
            const nameWithReversedName = `${
              currentPsy[0].name
            } ${getDisplayName(currentPsy[0].name)}`;

            return nameWithReversedName
              .toLowerCase()
              .includes(search.toLowerCase())
              ? 1
              : 0;
          }}
        >
          <CommandInput placeholder="Zoek een psycholoog..." />
          <div className="bg-gray-100 px-4 py-2 opacity-50 text-xs">
            Psychologist - First available date for intake - Occupancy %
          </div>

          <CommandList className="max-h-[24rem]">
            <CommandEmpty>Geen psycholoog gevonden.</CommandEmpty>
            <CommandGroup>
              <CommandItem
                key={""}
                value={""}
                className="hover:bg-accent"
                onSelect={() => {
                  setSelectedPsychologistId(psychologistNameEnum.EMPTY);
                  setOpen(false);
                }}
              >
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    selectedPsychologistId === psychologistNameEnum.EMPTY
                      ? "opacity-100"
                      : "opacity-0"
                  )}
                />
                <p className="italic">Alle psychologen</p>
              </CommandItem>

              {psychologists.map((employee) => {
                return (
                  <CommandItem
                    key={employee.employeeId}
                    value={employee.employeeId}
                    onSelect={(currentValue) => {
                      setSelectedPsychologistId(currentValue);
                      setOpen(false);
                    }}
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        selectedPsychologistId === employee.employeeId
                          ? "opacity-100"
                          : "opacity-0"
                      )}
                    />
                    <PsychologistEntryComponent psychologist={employee} />
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
