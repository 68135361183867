import React from "react";
import { styled } from "@mui/system";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import InfoIcon from "@mui/icons-material/InfoOutlined";

const StyledRoot = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: "6px 16px",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.error.main,
  color: theme.palette.error.contrastText,
  marginBottom: 2,
}));

const StyledMessage = styled("div")(() => ({
  flexGrow: 1,
  display: "flex",
  alignItems: "center",
  padding: "8px 0",
}));

const StyledIconSpan = styled("span")(({ theme }) => ({
  fontSize: 20,
  marginRight: theme.spacing(1),
}));

const StyledIconButton = styled(IconButton)(() => ({
  display: "flex",
  alignItems: "center",
  marginLeft: "auto",
  paddingLeft: 16,
  marginRight: -8,
}));

const Alert = ({ message, onClose }) => {
  return (
    <StyledRoot>
      <StyledIconSpan>
        <InfoIcon />
      </StyledIconSpan>
      <StyledMessage>{message}</StyledMessage>
      {onClose && (
        <StyledIconButton
          color="inherit"
          key="close"
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </StyledIconButton>
      )}
    </StyledRoot>
  );
};

export default Alert;
