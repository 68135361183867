import React, { useEffect } from "react";
import { styled } from "@mui/system";
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { observer } from "mobx-react";
import { trackPromise } from "react-promise-tracker";
import useStores from "../../useStores";
import UserTableRow from "./UsersTableRow";
import areas from "../../utils/constants/areas";
import Loader from "../../shared/Loader";
import { EpdIdsSyncedFromHiBobWarning } from "./epdIdsSyncedFromHiBobWarning";
import { ReSyncHiBobUserDataButton } from "./reSyncHiBobUserDataButton";

const StyledCardContent = styled(CardContent)(() => ({
  padding: 0,
}));

export const EpdIdSynchWarning = () => {};

const UserTable = () => {
  const { adminStore } = useStores();

  useEffect(() => {
    trackPromise(adminStore.getAllUsers(), areas.ADMIN_TABLE);
  }, [adminStore]);

  return (
    <main>
      <h1 className="pt-6 text-4xl font-bold">
        Role Assignments, 3rd party IDs
      </h1>
      <div className="pt-1 pb-4 flex justify-between items-start space-x-4">
        <EpdIdsSyncedFromHiBobWarning />
        <ReSyncHiBobUserDataButton />
      </div>

      <Loader area={areas.ADMIN_TABLE}>
        <Card>
          <StyledCardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>Roles</TableCell>
                  <TableCell align="right">Acties</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {adminStore.users.map((user) => (
                  <UserTableRow key={user.id} user={user} />
                ))}
              </TableBody>
            </Table>
          </StyledCardContent>
        </Card>
      </Loader>
    </main>
  );
};

export default observer(UserTable);
