import axios from "axios";
import { useMutation, useQuery } from "react-query";

export const useGetLocationTags = () => {
  const query = useQuery<string[]>(
    ["calendar", "user-tags", "locations"],
    async () => {
      const res = await axios.get(`calendaradmin/location-tags`);
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useRecalculateEmployeeOccupancyPredictions = (
  onSuccess: () => void
) => {
  return useMutation({
    mutationFn: async () => {
      const res = await axios.post(
        `calendaradmin/recalculate-employee-occupancy-predictions`
      );

      return res.data;
    },
    onSuccess: () => {
      onSuccess();
    },
  });
};
