import { Copy, SendHorizontal } from "lucide-react";
import { cn } from "../../../../../../lib/utils";
import { toast } from "sonner";
import { useIsEnabled } from "../../../../../../feature-management/useIsEnabled";

type Props = {
  loadingReplySuggestions: boolean;
  onSuggestedReplyClick: (suggestion: string) => void;
  replySuggestions: string[];
};

export function ReplySuggestions({
  loadingReplySuggestions,
  onSuggestedReplyClick,
  replySuggestions,
}: Props) {
  const { data: isCaseLoadManagerWireReplacementEnabled } = useIsEnabled(
    "EnableCaseLoadManagerWireReplacement"
  );

  if (loadingReplySuggestions) {
    return (
      <div className="mx-auto h-1/2">
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-col space-y-2">
        <span className="text-openup-dark-blue tracking[-0.2px] font-semibold leading-5">
          These might be appropriate messages to send to the client
        </span>

        <span className="text-openup-dark-blue text-xs leading-4 text-opacity-70">
          Click to copy the message to the chat. You will still be able to
          adjust before sending.
        </span>
      </div>

      {replySuggestions?.length > 0 ? (
        <ul className="mt-4 flex-grow space-y-2">
          {replySuggestions.map((suggestion) => {
            return (
              <div
                className="border bg-blue-50 block rounded-lg p-3 text-left"
                key={suggestion}
              >
                <div>
                  <p>{suggestion}</p>

                  <div className="pt-2 flex items-center space-x-6 text-xs text-link cursor-pointer">
                    <div
                      className="flex items-center space-x-1 hover:underline"
                      onClick={async () => {
                        await navigator.clipboard.writeText(suggestion);
                        toast("Copied!", {
                          description:
                            "The message has been copied to your clipboard",
                        });
                      }}
                    >
                      <div className="w-4 h-4">
                        <Copy className="w-4 h-4" />
                      </div>
                      <p>Copy</p>
                    </div>
                    {isCaseLoadManagerWireReplacementEnabled ? (
                      <div
                        className="flex items-center space-x-1 hover:underline"
                        onClick={() => onSuggestedReplyClick(suggestion)}
                      >
                        <div className="w-4 h-4">
                          <SendHorizontal className="w-4 h-4" />
                        </div>
                        <p>Use in Chat</p>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </ul>
      ) : (
        <div className="text-openup-dark-blue flex flex-grow flex-col items-center justify-center text-center text-xs font-medium leading-4">
          <p className="">No relevant suggestions..</p>

          <p>
            Suggestions will show if the last message was sent by the client and
            the chat has more than 2 messages from the client.
          </p>
        </div>
      )}
    </>
  );
}

export function LoadingIndicator({ className }: { className?: string }) {
  return (
    <svg
      className={cn("animate-spin", className)}
      fill="none"
      height="40"
      viewBox={`0 0 40 40`}
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx={20}
        cy={20}
        opacity="0.3"
        r={18}
        stroke="#152361"
        strokeWidth={4}
      />

      <path
        d="M2.01433 20.7181C1.86186 16.8992 2.92889 13.1309 5.06091 9.9589C7.19293 6.7869 10.2793 4.37585 13.8729 3.0749"
        stroke="#152361"
        strokeWidth={4}
      />
    </svg>
  );
}
