import { action, makeObservable, observable, runInAction } from "mobx";
import axios from "axios";

class AdminStore {
  users = [];

  constructor() {
    makeObservable(this, {
      users: observable,
      getAllUsers: action,
      updateUser: action,
    });
  }

  async getAllUsers() {
    const res = await axios.get("users");

    runInAction(() => {
      this.users = res.data;
    });
  }

  async updateUser(userId, updateData) {
    try {
      await axios.patch(`users/${userId}`, updateData);
      const res = await axios.get("users");
      runInAction(() => {
        this.users = res.data;
      });
    } catch (error) {
      console.error("Failed to update user", error);
    }
  }

  async getEmployeeById(userId) {
    try {
      const res = await axios.get(`api/employees/${userId}`);
      runInAction(() => {
        this.currentEmployee = res.data;
      });
    } catch (error) {
      console.error("Failed to fetch employee", error);
    }
  }
}

export default AdminStore;
