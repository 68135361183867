import { TableCell, TableRow } from "../../../../../../components/ui/table";
import { useTranslation } from "react-i18next";
import { getDetailedRatio } from "../../../../statistics/psychologistRatio/opToCrpPsychologistRatio";
import { OpToCrpPsychologistRatioType } from "../../../../statistics/types";

export const FlattenedTableRows = ({
  data,
}: {
  data: OpToCrpPsychologistRatioType[];
}) => {
  const { t } = useTranslation();

  return (
    <>
      {data.map((x) => (
        <TableRow key={x.psychologist}>
          <TableCell>{x.psychologist}</TableCell>
          <TableCell className="border-l">{x.patient}</TableCell>
          <TableCell className="text-center border-l">
            {x.numberOfChatAppointments}
          </TableCell>
          <TableCell className="text-center border-l">
            {x.numberOfConsultationRoomAppointments}
          </TableCell>
          <TableCell className="text-center border-l">
            {getDetailedRatio(
              x,
              t("case-load-manager-statistics-op-skp-ratio-no-cpr-appointments")
            )}
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};
