import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { CrmPatientEntryPatientRegistrationStepTracker } from "./type";
import { queryClient } from "../../../../react-query/provider";

export const useGetSteps = (
  referralId: string,
  onSuccess: (data: CrmPatientEntryPatientRegistrationStepTracker) => void
) => {
  const query = useQuery<CrmPatientEntryPatientRegistrationStepTracker>(
    ["referral-manager", "register-patient-steps", referralId],
    async () => {
      const res = await axios.get(
        `customerrelationshipmanager/referral/${referralId}/patient-registration-step-tracker`
      );
      return res.data;
    },
    {
      onSuccess: onSuccess,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useUpdateSteps = () => {
  const mutation = useMutation({
    mutationFn: async ({
      data,
    }: {
      data: CrmPatientEntryPatientRegistrationStepTracker;
    }) => {
      const res = await axios.post(
        `customerrelationshipmanager/referral/${data.referralId}/patient-registration-step-tracker`,
        {
          isNameStepCompleted: data.isNameStepCompleted,
          isIntakeSchedulingStepCompleted: data.isIntakeSchedulingStepCompleted,
          isPreIntakeSchedulingStepCompleted:
            data.isPreIntakeSchedulingStepCompleted,
          isRegisterPatientStepCompleted: data.isRegisterPatientStepCompleted,
          isApprovePatientStepCompleted: data.isApprovePatientStepCompleted,
          isSendIWelcomeStepCompleted: data.isSendIWelcomeStepCompleted,
          isPraktijkdataActionsStepCompleted:
            data.isPraktijkdataActionsStepCompleted,
        }
      );
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([
        "referral-manager",
        "register-patient-steps",
      ]);
      queryClient.refetchQueries([
        "referral-manager",
        "register-patient-steps",
      ]);
    },
  });

  return mutation;
};
