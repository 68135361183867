import React from "react";
import { observer } from "mobx-react";
import Label from "./Label";
import getOnboardingStatusColor from "../utils/getOnboardingStatusColor";

const StatusLabelOnboarding = ({ status }) => {
    return (
      <Label color={getOnboardingStatusColor(status)}>
        {status}
      </Label>
    );
};

export default observer(StatusLabelOnboarding);
