import { Loader2 } from "lucide-react";
import { useTranslation } from "react-i18next";

export const Fetching = () => {
  const { t } = useTranslation();

  return (
    <div className="text-xs flex items-center space-x-2">
      <p className="italic">{t("case-load-manager-refreshing")}</p>
      <Loader2 className="w-3 h-3 animate-spin" />
    </div>
  );
};
