import { Trash2 } from 'lucide-react';
import { Button } from '../../../../components/ui/button';

type RemoveClientColumnProps = {
  onClick: () => void;
};

export const RemoveClientColumn = ({ onClick }: RemoveClientColumnProps) => {
  return (
    <Button variant="ghost" className="text-red-500 z-[99999]" onClick={onClick}>
      <Trash2 className="w-4 h-4" />
    </Button>
  );
};
