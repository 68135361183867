import { ColumnDef } from '@tanstack/react-table';
import { MainListPatient } from '../../types';
import { SortableCell } from '../../../../../components/ui/table';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../../../../../components/ui/tooltip';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '../../../../../components/ui/hover-card';
import { ExternalLink, Trash2, Info, CornerDownRight } from 'lucide-react';
import { useContext, useState } from 'react';
import { isPeriodInPastWeeks } from '../../../../../utils/dateUtils';
import { RemoveClientDialog } from '../../removeClient/removeClientDialog';
import { useTranslation } from 'react-i18next';
import { useIsEnabled } from '../../../../../feature-management/useIsEnabled';
import { CaseLoadManagerSelectedPatientContext } from '../../selectedClientContext';
import { useGetChatDetails } from '../../../../streamChat/hooks';

export const PatientColName = 'Patient';

export const PatientCol: ColumnDef<MainListPatient> = {
  id: PatientColName,
  accessorKey: 'name',
  size: 175,
  minSize: 10,
  header: ({ column }) => {
    const { t } = useTranslation();

    return (
      <div className="w-full pl-4">
        <SortableCell column={column} label={t('case-load-manager-column-patient')} />
      </div>
    );
  },
  cell: ({ row, table }) => {
    const { t } = useTranslation();

    const dossierLookupGuids =
      table.getRowModel()?.rows?.map((r) => r.original.dossierLookupGuid) ?? [];

    const { data: isCaseLoadManagerWireReplacementEnabled } = useIsEnabled(
      'EnableCaseLoadManagerWireReplacement'
    );

    const { data: isCaseLoadDetailsEnabled } = useIsEnabled('EnableCaseLoadDetailsView');
    const { data: chatDetails } = useGetChatDetails(
      isCaseLoadManagerWireReplacementEnabled ?? false,
      dossierLookupGuids
    );

    const getUnreadMessagesCount = () => {
      const chatData = chatDetails?.filter(
        (x) => x.dossierLookupGuid === row.original.dossierLookupGuid
      );

      if (chatData == null || chatData.length === 0) return 0;

      return chatData[0].numberOfUnreadMessages;
    };

    const { setSelectedPatientEntry } = useContext(CaseLoadManagerSelectedPatientContext)!;

    const dossier = `${row.original.epdPatientId}.${row.original.epdFileId}`;
    const userName = row.original.name;

    const [isRemovePatientConfirmationOpen, setIsRemovePatientConfirmationOpen] = useState(false);

    const [isViewBillingOfPatientDialogOpen, setIsViewBillingOfPatientDialogOpen] = useState(false);

    const isInPast = isPeriodInPastWeeks(row.original.period);

    return (
      <div className={`min-h-full flex space-x-3 items-center p-2 ml-2 border-r bg-slate-50`}>
        <>
          {isCaseLoadManagerWireReplacementEnabled ? (
            <>
              {getUnreadMessagesCount() > 0 ? (
                <TooltipProvider delayDuration={100}>
                  <Tooltip>
                    <TooltipTrigger onClick={() => setSelectedPatientEntry(row.original)}>
                      <div className="relative h-4 w-4 rounded-full bg-red-400 flex items-center justify-center">
                        <div className="absolute top-0 left-0 h-4 w-4 rounded-full bg-red-400 animate-ping opacity-20"></div>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent
                      align="center"
                      side="bottom"
                      style={{ zIndex: 99999 }}
                      className="p-2 bg-black text-gray-50"
                    >
                      <p>This client has unread messages.</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </>

        <div className="flex flex-col justify-center">
          <p className="text-xs">{dossier}</p>
          <HoverCard openDelay={100}>
            <HoverCardTrigger asChild>
              <p
                className="font-bold cursor-pointer underline hover:text-ipractice-blue"
                onClick={() => {
                  if (isCaseLoadDetailsEnabled) {
                    setSelectedPatientEntry(row.original);
                  }
                }}
              >
                {userName}
              </p>
            </HoverCardTrigger>
            <HoverCardContent align="start" side="bottom" className="text-gray-700 p-2 z-50">
              {isCaseLoadDetailsEnabled && (
                <>
                  <div
                    className="cursor-pointer hover:underline flex gap-2 py-1 items-center"
                    onClick={() => {
                      setSelectedPatientEntry(row.original);
                    }}
                  >
                    <CornerDownRight className="w-4 h-4" />
                    <p>{t('case-load-manager-row-actions-open-details')} </p>
                  </div>
                  <hr className="w-full h-1" />
                </>
              )}

              <div
                className="cursor-pointer hover:underline flex gap-2 py-1 items-center"
                onClick={() => {
                  window.open(
                    `/patient/${row.original.patientId}/DossierGgz/${row.original.dossierId}/treatment`,
                    '_blank'
                  );
                }}
              >
                <ExternalLink className="w-4 h-4" />
                <p>
                  {t('case-load-manager-row-actions-view-patient-in')}{' '}
                  <span className="font-semibold">Dashboard</span>
                </p>
              </div>
              <div
                className="cursor-pointer hover:underline flex gap-2 py-1 items-center"
                onClick={() => {
                  window.open(
                    `https://start.praktijkdata.nl/app/clienten/${row.original.epdPatientId}`,
                    '_blank'
                  );
                }}
              >
                <ExternalLink className="w-4 h-4" />
                <p>
                  {t('case-load-manager-row-actions-view-patient-in')}{' '}
                  <span className="font-semibold">Praktijkdata</span>
                </p>
              </div>
              <hr className="w-full h-1" />
              <div
                className={`py-1 ${
                  isInPast || row.original.hasChattedWith
                    ? 'opacity-50'
                    : 'cursor-pointer hover:underline'
                }`}
                onClick={() => {
                  if (row.original.hasChattedWith || isInPast) return;

                  setIsRemovePatientConfirmationOpen(true);
                }}
              >
                <div
                  className={`${
                    isInPast || row.original.hasChattedWith ? 'text-red-400' : 'text-red-600'
                  } flex items-center justify-between w-full`}
                >
                  <div className="flex items-center gap-2">
                    <Trash2 className="h-4 w-4" />
                    <p>{t('case-load-manager-row-actions-remove-patient')}</p>
                  </div>

                  {row.original.hasChattedWith && (
                    <div className="h-4 w-4">
                      <TooltipProvider delayDuration={100}>
                        <Tooltip>
                          <TooltipTrigger>
                            <Info className="h-4 w-4" />
                          </TooltipTrigger>
                          <TooltipContent className="w-72">
                            {t('case-load-manager-cannot-remove-single-patient-desc-tooltip')}
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </div>
                  )}
                </div>
              </div>
            </HoverCardContent>
          </HoverCard>
        </div>
        <RemoveClientDialog
          isOpen={isRemovePatientConfirmationOpen}
          setIsOpen={setIsRemovePatientConfirmationOpen}
          caseLoadEntryId={row.original.id}
          patient={row.original}
          onRemoval={() => table.resetRowSelection()}
        />
      </div>
    );
  },
};
