import axios from "axios";
import { useQuery } from "react-query";

export type LocationWithTeamTag = {
  locationName: string;
  teamId: number;
};

export const useGetLocationWithTeamTags = (
  handleSuccess?: (data: LocationWithTeamTag[]) => void
) => {
  const query = useQuery<LocationWithTeamTag[]>(
    ["user-tags", "location-with-team-tags"],
    async () => {
      const res = await axios.get(`tags/locations-with-team-tags`);
      return res.data;
    },
    {
      onSuccess: (data) => {
        if (handleSuccess != null) {
          handleSuccess(data);
        }
      },
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};
