import { ArrowDownToLine } from "lucide-react";
import { Button } from "../../../components/ui/button";
import { useExportBigRegistrationToPdf } from "../hooks";

export const ExportToPdf = () => {
  const { mutate, isLoading } = useExportBigRegistrationToPdf((data) => {
    const url = window.URL.createObjectURL(
      new Blob([data], { type: "application/pdf" })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    link.remove();
  });

  return (
    <Button className="space-x-2" onClick={async () => await mutate()}>
      <div className="w-4 h-4">
        <ArrowDownToLine className="w-4 h-4" />
      </div>
      <p>{isLoading ? "Exporting PDF..." : "Export to PDF"}</p>
    </Button>
  );
};
