import React from 'react';
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import { Formik } from "formik";
import * as Yup from 'yup';
import { observer } from "mobx-react";
import useStores from "../../useStores";
import DeleteDossier from "../../shared/DeleteDossier";

const EditDossierOpenUp = () => {
    const { patientStore } = useStores();
    const { dossier } = patientStore;

    return (
      <Card>
        <Formik
          enableReinitialize
          initialValues={{
                    yearOfBirth: dossier.yearOfBirth,
                    company: dossier.company,
                    isAnonymousCare: dossier.isAnonymousCare
                }}
          onSubmit={(values) => {
                    patientStore.patchDossier(values);
                    // resetForm(); Don't reset form it gets filled again from the store using Formik enableReinitialize.
                }}
          validationSchema={Yup.object().shape({
                    yearOfBirth: Yup.number()
                        .positive()
                        .required(),
                    company: Yup.string()
                        .nullable(),
                    isAnonymousCare: Yup.boolean(),
                })}
        >
          {(props) => {
                    const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        handleReset,
                        dirty,
                    } = props;
                    return (
                      <form
                        autoComplete="off"
                        noValidate
                      >
                        <CardHeader title="Dossier bewerken" />
                        <Divider />
                        <CardContent>
                          <Grid container spacing={3}>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                label="Geboorte jaar"
                                margin="dense"
                                name="yearOfBirth"
                                onChange={handleChange}
                                required
                                value={values.yearOfBirth}
                                variant="outlined"
                                type="number"
                                onBlur={handleBlur}
                                error={(errors.yearOfBirth && touched.yearOfBirth)}
                                helperText={(errors.yearOfBirth && touched.yearOfBirth) && errors.yearOfBirth}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                label="Bedrijf"
                                margin="dense"
                                name="company"
                                onChange={handleChange}
                                value={values.company}
                                variant="outlined"
                                type="text"
                                onBlur={handleBlur}
                                error={(errors.company && touched.company)}
                                helperText={(errors.company && touched.company) && errors.company}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <FormControlLabel
                                label="Anonieme zorg"
                                control={(
                                  <Checkbox
                                    checked={values.isAnonymousCare}
                                    onChange={handleChange}
                                    name="isAnonymousCare"
                                  />
                                                            )}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                        <Divider />
                        <CardActions>
                          <Button
                            color="primary"
                            variant="contained"
                            disabled={isSubmitting || !dirty}
                            onClick={handleSubmit}
                          >
                            Opslaan
                          </Button>
                          <Button
                            type="button"
                            className="outline"
                            onClick={handleReset}
                            disabled={isSubmitting || !dirty}
                          >
                            Reset
                          </Button>
                        </CardActions>

                        <Divider />

                        <CardActions>
                          <DeleteDossier />
                        </CardActions>
                      </form>
                    );
                }}
        </Formik>
      </Card>
    );
};

export default observer(EditDossierOpenUp);
