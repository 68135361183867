import { useEffect, useState } from "react";

export const useDarkMode = () => {
  const [theme, setTheme] = useState<"light" | "dark">(
    localStorage.theme || "light"
  );

  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove("light", "dark");
    root.classList.add(theme);

    localStorage.setItem("theme", theme);
  }, [theme]);

  return [theme, setTheme];
};
