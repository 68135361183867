import { ReferralDetails } from "../../types";
import { Input } from "../../../../components/ui/input";
import { useState } from "react";
import { Button } from "../../../../components/ui/button";
import { Loader2, Save, Undo } from "lucide-react";
import { toast } from "sonner";
import { useUpdatePatientName } from "../../hooks";
import { Separator } from "../../../../components/ui/separator";
import { Language } from "./language";
import { CrmPatientEntryPatientRegistrationStepTracker } from "../registrationStatusTracker/crmPatientEntryPatientRegistrationStepTracker";

export const NameAndLanguage = ({
  referral,
  stepNumber,
}: {
  referral: ReferralDetails;
  stepNumber: number;
}) => {
  const [firstName, setFirstName] = useState(referral.firstName);
  const [lastName, setLastName] = useState(referral.lastName);

  const hasChanged =
    (referral.firstName ?? "") !== (firstName ?? "") ||
    (referral.lastName ?? "") !== (lastName ?? "");

  const { mutate: save, isLoading: isSaving } = useUpdatePatientName(() => {
    toast("Name updated", {
      description: "The name of the patient has been successfully updated.",
    });
  });

  return (
    <div>
      <div>
        <div className="flex items-center space-x-2">
          <CrmPatientEntryPatientRegistrationStepTracker
            referralId={referral.id}
            step="name"
          />
          <p className="font-medium text-lg">
            {stepNumber}. Patient's name & language
          </p>
        </div>
        <Separator className="mb-1" />

        <p className="text-xs">
          Please make sure the patient's first and last names are up to date and
          correct.
        </p>
        <section className="flex items-center justify-between space-x-2 mb-2">
          <div className="w-1/2">
            <label className="mt-4 block font-medium pb-1" htmlFor="firstName">
              First Name
            </label>
            <Input
              id="firstName"
              autoFocus
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
            />
          </div>

          <div className="w-1/2">
            <label className="mt-4 block font-medium pb-1" htmlFor="lastName">
              Last Name
            </label>
            <Input
              id="lastName"
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
            />
          </div>
        </section>

        {hasChanged && (
          <div className="flex gap-x-2 -pt-2">
            <Button
              className="py-0 w-full flex items-center gap-2 text-sm h-8"
              disabled={!hasChanged}
              onClick={async () =>
                await save({
                  referralId: referral.id,
                  firstName,
                  lastName,
                })
              }
            >
              {isSaving ? (
                <>
                  <Loader2 className="w-4 h-4 animate-spin" />
                  <p>Saving...</p>
                </>
              ) : (
                <>
                  <Save className="w-4 h-4" />
                  <p>Save</p>
                </>
              )}
            </Button>
            <Button
              variant="outline"
              className="py-0 w-full flex items-center gap-2 text-sm h-8"
              disabled={!hasChanged}
              onClick={() => {
                setFirstName(referral.firstName);
                setLastName(referral.lastName);
              }}
            >
              <Undo className="w-4 h-4" />
              <p>Undo</p>
            </Button>
          </div>
        )}

        <div className="w-1/2">
          <Language
            isReadOnly={false}
            referralId={referral.id}
            value={referral.language}
          />
        </div>
      </div>
    </div>
  );
};
