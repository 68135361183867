import { LocationWithTeamTag } from "./hooks/useGetLocationWithTeamTags";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectGroup,
  SelectValue,
} from "../components/ui/select";

type LocationAndTeamFilterProps = {
  isLoading: boolean;
  selectableLocationAndTeams: LocationWithTeamTag[];
  selectedLocationAndTeam: LocationWithTeamTag | null;
  setSelectedLocationAndTeam: React.Dispatch<
    React.SetStateAction<LocationWithTeamTag | null>
  >;
};

export const LocationAndTeamFilter = ({
  isLoading,
  selectableLocationAndTeams,
  selectedLocationAndTeam,
  setSelectedLocationAndTeam,
}: LocationAndTeamFilterProps) => {
  const getDuplicateStrings = (strings: string[]): string[] => {
    const frequencyMap: Record<string, number> = {};

    for (const str of strings) {
      frequencyMap[str] = (frequencyMap[str] || 0) + 1;
    }

    return strings.filter((str) => frequencyMap[str] > 1);
  };

  const renderSelectableLocationsAndTeams = () => {
    if (!selectableLocationAndTeams) return <></>;

    const locationsWithMultipleTeams = getDuplicateStrings(
      selectableLocationAndTeams?.map((x) => x.locationName)
    );

    return (
      <>
        {selectableLocationAndTeams
          .toSorted((a, b) => a.locationName.localeCompare(b.locationName))
          .map((location) => {
            const key = location.locationName + ";" + location.teamId;
            let label = location.locationName;

            if (locationsWithMultipleTeams?.includes(location.locationName)) {
              label = location.locationName + " " + location.teamId;
            }

            return (
              <SelectItem key={key} value={key}>
                {label}
              </SelectItem>
            );
          })}
      </>
    );
  };

  return (
    <Select
      onValueChange={(value) => {
        const splitValues = value.split(";");
        const location = splitValues[0];
        const team = splitValues[1];

        if (location == "all") {
          setSelectedLocationAndTeam(null);
          return;
        }

        setSelectedLocationAndTeam({
          locationName: location,
          teamId: team ? Number(team) : 0,
        });
      }}
      disabled={isLoading}
      value={
        selectedLocationAndTeam
          ? selectedLocationAndTeam.locationName +
            ";" +
            selectedLocationAndTeam.teamId
          : ""
      }
    >
      <SelectTrigger className="w-full">
        <SelectValue placeholder="Select a location..." />
      </SelectTrigger>
      <SelectContent className={`z-[9999999] max-h-[30vh]`}>
        <SelectGroup>
          <SelectItem value="all" className="italic">
            All locations
          </SelectItem>
          {renderSelectableLocationsAndTeams()}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};
