import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { SavedGermanClient } from "./types";
import { queryClient } from "../../../react-query/provider";

const refetchQueries = () => {
  queryClient.invalidateQueries("iPracticeDE");
  queryClient.refetchQueries("iPracticeDE");
};

export const useGetiPracticeGermanyClients = (searchTerm: string) => {
  const query = useQuery<SavedGermanClient[]>(
    ["iPracticeDE", "clients", searchTerm],
    async () => {
      const res = await axios.get(`ipracticegermany?searchTerm=${searchTerm}`);
      return res.data;
    }
  );

  return query;
};

export const useGetiPracticeGermanyClient = (
  id: string,
  onSuccess: (client: SavedGermanClient) => void
) => {
  const query = useQuery<SavedGermanClient>(
    ["iPracticeDE", "client", id],
    async () => {
      const res = await axios.get(`ipracticegermany/${id}`);
      return res.data;
    },
    {
      onSuccess: onSuccess,
      refetchOnReconnect: false,
    }
  );

  return query;
};

export const useCreateiPracticeGermanyClient = (
  onSuccess: (data: SavedGermanClient) => void
) => {
  const mutation = useMutation({
    mutationFn: async ({
      name,
      email,
      phone,
      numberOfSessions,
      isOngoingSessions,
      price,
    }: {
      name: string;
      email: string;
      phone: string;
      numberOfSessions: number | null;
      isOngoingSessions: boolean;
      price: string | null;
    }) => {
      const res = await axios.post(`ipracticegermany`, {
        name,
        email,
        phone,
        numberOfSessions,
        isOngoingSessions,
        price,
      });
      return res.data;
    },
    onSuccess: (data: SavedGermanClient) => {
      refetchQueries();
      onSuccess(data);
    },
  });

  return mutation;
};

export const useUpdateiPracticeGermanyClient = (onSuccess: () => void) => {
  const mutation = useMutation({
    mutationFn: async ({
      id,
      name,
      email,
      phone,
      numberOfSessions,
      isOngoingSessions,
      price,
    }: {
      id: string;
      name: string;
      email: string;
      phone: string;
      numberOfSessions: number | null;
      isOngoingSessions: boolean;
      price: string | null;
    }) => {
      const res = await axios.put(`ipracticegermany/${id}`, {
        name,
        email,
        phone,
        numberOfSessions,
        isOngoingSessions,
        price,
      });
      return res.data;
    },
    onSuccess: () => {
      refetchQueries();
      onSuccess();
    },
  });

  return mutation;
};

export const useSendiAgreeEmailToiPracticeGermanyClient = (
  onSuccess: () => void
) => {
  const mutation = useMutation({
    mutationFn: async ({ id }: { id: string }) => {
      const res = await axios.post(`ipracticegermany/${id}/resend-iagree`, {});
      return res.data;
    },
    onSuccess: () => {
      refetchQueries();
      onSuccess();
    },
  });

  return mutation;
};
