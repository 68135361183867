import { CaseLoadWeeklyProgress } from "../types";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../../components/ui/tooltip";

type ExcludedClientsBarProps = {
  excludedPercent: number;
  supportedPercent: number;
  data: CaseLoadWeeklyProgress;
};

export const ExcludedClientsBar = ({
  excludedPercent,
  supportedPercent,
  data,
}: ExcludedClientsBarProps) => {
  return (
    <>
      {data.excludedClientCount == 0 ? (
        <></>
      ) : (
        <div
          style={{ width: `${excludedPercent}%` }}
          className={`flex items-center justify-center text-xs h-6 bg-amber-500 ${
            supportedPercent == 0 ? "rounded" : "rounded-tl rounded-bl"
          }`}
        >
          <TooltipProvider>
            <Tooltip delayDuration={100}>
              <TooltipTrigger asChild>
                <p className="text-amber-700 font-bold">
                  {data.excludedClientCount}
                </p>
              </TooltipTrigger>
              <TooltipContent
                className="text-center bg-slate-900 text-slate-300 p-2"
                side="bottom"
                align="center"
              >
                This week, you have marked {data.excludedClientCount} clients as
                excluded.
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      )}
    </>
  );
};
