import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { CrmInsurerWaitListEmailReport } from "./types";

export const useGetNoChangeInDaysReport = () => {
  const query = useQuery<CrmInsurerWaitListEmailReport[]>(
    ["crm-reports", "wait-list-report"],

    async () => {
      const res = await axios.get(
        `customerrelationshipmanagerreport/insurer-wait-list-emails`
      );
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};
