import { Milestone } from "../types";
import { useTranslation } from "react-i18next";
import {
  Calendar,
  CalendarCheck2,
  CalendarClock,
  CalendarX2,
  Check,
  CircleSlash2,
  ArchiveRestore,
  FolderCheck,
  FolderSymlink,
  Mails,
  PhoneCall,
  PhoneOff,
  UserRoundCheck,
  X,
  RotateCcw,
  LucideIcon,
} from "lucide-react";
import { Separator } from "../../../components/ui/separator";
import moment from "moment";
import { ReactElement } from "react";

export const Milestones = ({ data }: { data: Milestone[] }) => {
  const { t } = useTranslation();

  const getActivityOnDate = (entry: Milestone) => {
    if (
      entry.content == "Failed to reach patient" ||
      entry.content == "Successfully reached patient"
    ) {
      return new Date(entry.date).toLocaleDateString();
    }
    return new Date(entry.date).toLocaleString();
  };

  return (
    <div>
      <p className="text-lg font-medium leading-none">Timeline</p>
      <Separator className="mt-1 mb-3" />

      <div className="space-y-2 max-h-[24rem] overflow-y-auto">
        {data.length > 0 ? (
          data
            .toSorted((a, b) => (a.date > b.date ? -1 : 1))
            .map((entry, i) => {
              const uniqueStyle = displayStyles.find(
                (style) => style.content === entry.content
              );

              return (
                <div
                  className={`w-full rounded-lg py-2 px-3 ${
                    uniqueStyle?.variant == "default" ||
                    uniqueStyle == undefined
                      ? "bg-gray-50 dark:bg-slate-900"
                      : uniqueStyle?.variant == "success"
                      ? "bg-green-50 dark:bg-slate-900 dark:border-l-4 dark:border-green-950"
                      : "bg-red-50 dark:bg-slate-900 dark:border-l-4 dark:border-red-950"
                  }`}
                  key={i}
                >
                  <p className="text-xs pb-1">
                    {moment(new Date(entry.date)).fromNow()} by{" "}
                    <span className="underline">{entry.recordedBy}</span>
                    <span className="pl-2 opacity-70">
                      • {getActivityOnDate(entry)}
                    </span>
                  </p>
                  <div className="font-bold pt-px flex items-center space-x-2">
                    {uniqueStyle?.icon}
                    <p>{entry.content}</p>
                  </div>
                  {entry.additionalInformation ? (
                    <p className="pt-1 text-xs opacity-80">
                      {entry.additionalInformation}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              );
            })
        ) : (
          <p className="w-full px-3 italic">Nothing to show, yet.</p>
        )}
      </div>
    </div>
  );
};

const displayStyles: {
  content: string;
  icon: ReactElement<LucideIcon>;
  variant: "default" | "success" | "error";
}[] = [
  {
    content: "Referral created",
    icon: <ArchiveRestore className="w-4 h-4" />,
    variant: "default",
  },
  {
    content: "Rejected the referral",
    icon: <CircleSlash2 className="w-4 h-4" />,
    variant: "error",
  },
  {
    content: "Undid the rejection",
    icon: <RotateCcw className="w-4 h-4" />,
    variant: "default",
  },
  {
    content: "Patient is OK to be reached out to",
    icon: <UserRoundCheck className="w-4 h-4" />,
    variant: "success",
  },
  {
    content: "Calendly invite sent",
    icon: <Calendar className="w-4 h-4" />,
    variant: "default",
  },
  {
    content: "Calendly event re-scheduled",
    icon: <CalendarClock className="w-4 h-4" />,
    variant: "error",
  },
  {
    content: "Calendly event cancelled",
    icon: <CalendarX2 className="w-4 h-4" />,
    variant: "error",
  },
  {
    content: "Calendly invite sent, again",
    icon: <Calendar className="w-4 h-4" />,
    variant: "default",
  },
  {
    content: "Calendly event accepted",
    icon: <CalendarCheck2 className="w-4 h-4" />,
    variant: "success",
  },
  {
    content: "Calendly event created",
    icon: <CalendarCheck2 className="w-4 h-4" />,
    variant: "success",
  },
  {
    content: "Could not reach patient",
    icon: <PhoneOff className="w-4 h-4" />,
    variant: "error",
  },
  {
    content: "Successfully reached patient",
    icon: <PhoneCall className="w-4 h-4" />,
    variant: "success",
  },
  {
    content: "Pre-intake scheduled",
    icon: <Check className="w-4 h-4" />,
    variant: "success",
  },
  {
    content: "Pre-intake re-scheduled",
    icon: <Check className="w-4 h-4" />,
    variant: "success",
  },
  {
    content: "Intake scheduled",
    icon: <Check className="w-4 h-4" />,
    variant: "success",
  },
  {
    content: "Intake re-scheduled",
    icon: <Check className="w-4 h-4" />,
    variant: "success",
  },
  {
    content: "Intake cancelled",
    icon: <X className="w-4 h-4" />,
    variant: "error",
  },
  {
    content: "Pre-Intake cancelled",
    icon: <X className="w-4 h-4" />,
    variant: "error",
  },
  {
    content: "Sent the referral to Praktijkdata",
    icon: <FolderSymlink className="w-4 h-4" />,
    variant: "success",
  },
  {
    content: "Patient confirmed, dossier created",
    icon: <FolderCheck className="w-4 h-4" />,
    variant: "success",
  },
  {
    content: "iWelcome sent",
    icon: <Mails className="w-4 h-4" />,
    variant: "success",
  },
];
