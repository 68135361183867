import { action, makeObservable, observable, runInAction } from "mobx";
import axios from "axios";
import { trackPromise } from "react-promise-tracker";
import patchDataFactory from "../utils/patchDataFactory";
import areas from "../utils/constants/areas";

class OnboardingStore {
  onboarding = null;

  constructor() {
    makeObservable(this, {
      onboarding: observable,
      reset: action,
      fetchOnboarding: action,
      patchOnboarding: action,

      uploadReferralLetter: action,
      downloadReferralLetter: action,
      deleteReferralLetter: action,
      validateReferralLetter: action,
    });
  }

  reset() {
    this.onboarding = null;
  }

  async fetchOnboarding(dossierType, dossierId) {
    await trackPromise(
      axios.get(`/${dossierType}/dossiers/${dossierId}/onboarding/`).then(
        action("fetchSuccess", (res) => {
          this.onboarding = res.data;
        })
      ),
      areas.ONBOARDING
    );
  }

  async patchOnboarding(dossierId, replacements) {
    const res = await axios.patch(
      `dossiers/${dossierId}/onboarding`,
      patchDataFactory(replacements)
    );

    runInAction(() => {
      this.onboarding = res.data;
    });

    return res.data;
  }

  // Note: these are duplicated in OnboardingsStore!
  async uploadReferralLetter(dossierType, dossierId, data) {
    const res = await axios.post(
      `/${dossierType}/dossiers/${dossierId}/referral-letter`,
      data
    );

    runInAction(() => {
      this.onboarding = res.data;
    });

    return res.data;
  }

  async downloadReferralLetter() {
    return axios
      .get(`dossiers/${this.onboarding.id}/referral-letter`, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: response.headers["content-type"] })
        );
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.click();
      });
  }

  async deleteReferralLetter() {
    const res = await axios.delete(
      `/${this.onboarding.dossierType}/dossiers/${this.onboarding.id}/referral-letter`
    );

    runInAction(() => {
      this.onboarding = res.data;
    });

    return res.data;
  }

  async validateReferralLetter() {
    const res = await axios.post(
      `/${this.onboarding.dossierType}/dossiers/${this.onboarding.id}/validate-referral-letter`,
      {}
    );

    runInAction(() => {
      this.onboarding = res.data;
    });

    return res.data;
  }
}

export default OnboardingStore;
