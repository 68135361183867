import { ColumnDef } from "@tanstack/react-table";
import { ReferredPatient } from "../../../types";
import { Mail, Phone } from "lucide-react";
import { useTranslation } from "react-i18next";

export const PatientContactInfoColName = "Contact Info";

export const PatientContactInfoCol: ColumnDef<ReferredPatient> = {
  id: PatientContactInfoColName,
  accessorKey: "name",
  size: 150,
  minSize: 100,
  header: () => {
    const { t } = useTranslation();
    return <p>Contact Information</p>;
  },
  cell: ({ row }) => {
    const email = row.original.email;
    const phone = row.original.phoneNumber;

    return (
      <div>
        <div className="flex items-center space-x-2">
          <Mail className="w-4 h-4" />
          <p className="text-xs">{email}</p>
        </div>

        {phone && (
          <div className="flex items-center space-x-2 pt-1">
            <Phone className="w-4 h-4" />
            <p className="text-xs">{phone}</p>
          </div>
        )}
      </div>
    );
  },
};
