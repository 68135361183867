import React from "react";
import { Grid } from "@mui/material";
import { observer } from "mobx-react";
import ProcessBots from "./ProcessBots";
import Schedules from "./Schedules";
import Wire from "./Wire";
import Questionnaires from "../../../shared/Questionnaires";

const TreatmentTab = () => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={6} xl={4}>
        <Wire />
      </Grid>
      <Grid item xs={6} xl={8} />

      <Grid item xs={12} xl={10}>
        <Questionnaires isTreatmentView />
      </Grid>

      <Grid item xs={12} xl={6}>
        <Schedules />
      </Grid>
      <Grid item xs={12} xl={6}>
        <ProcessBots />
      </Grid>
    </Grid>
  );
};

export default observer(TreatmentTab);
