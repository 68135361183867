import { ColumnDef } from "@tanstack/react-table";
import { ReferredPatient } from "../../../types";
import { useTranslation } from "react-i18next";

export const LocationColName = "Location";

export const LocationCol: ColumnDef<ReferredPatient> = {
  id: LocationColName,
  accessorKey: "location",
  size: 175,
  minSize: 100,
  header: () => {
    const { t } = useTranslation();
    return <p>{"Location"}</p>;
  },
  cell: ({ row }) => {
    if (row.original.location) {
      return <p>{row.original.location?.name}</p>;
    }

    return <p className="opacity-50">Unspecified</p>;
  },
};
