import { CrmWorkingViewReportPerCityCounts } from "../../../types";

export type ChartColors = {
  [key in CrmWorkingViewReportPerCityCounts]: string;
};

export const colors: ChartColors = {
  notYetCalledCount: "#ef4444",
  calledAndActionNeededCount: "#FF8042",
  calledButWithoutResponseCount: "#FFBB28",
  calledAndNoActionNeededCount: "#22d3ee",
  notCalledDueToNoActionNeedCount: "#64748b",
  closedFromWorkingViewCount: "#10b981",
  closedFromCalendlyViewCount: "#3b82f6",
  menzisCount: "#c4b5fd",
  vgzCount: "#f0abfc",
};

export const getReadableLabelFromDataKey = (
  dataKey: CrmWorkingViewReportPerCityCounts
) => {
  switch (dataKey) {
    case "closedFromWorkingViewCount":
      return "Closed (from Working View)";
    case "closedFromCalendlyViewCount":
      return "Closed (from Calendly View)";
    case "notYetCalledCount":
      return "Not yet called";
    case "calledButWithoutResponseCount":
      return "Called, no action";
    case "calledAndActionNeededCount":
      return "Called, action needed";
    case "calledAndNoActionNeededCount":
      return "Called, no action needed";
    case "notCalledDueToNoActionNeedCount":
      return "Not called (no action needed)";
    case "menzisCount":
      return "Menzis";
    case "vgzCount":
      return "VGZ";
    default:
      return "Unknown";
  }
};

export const crmWorkingViewReportPerCityCounts: CrmWorkingViewReportPerCityCounts[] =
  [
    "notYetCalledCount",
    "calledAndActionNeededCount",
    "calledButWithoutResponseCount",
    "calledAndNoActionNeededCount",
    "notCalledDueToNoActionNeedCount",
    "closedFromWorkingViewCount",
    "closedFromCalendlyViewCount",
    "menzisCount",
    "vgzCount",
  ];

export const getParsedCityName = (city: string) => {
  if (city.includes("Amsterdam")) return "Amsterdam"; // remove NHG suffix from Amsterdam's location
  if (city.includes("Online")) return "Online"; // remove the 'met notificatie' suffix from the online location

  return city;
};
