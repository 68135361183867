import { CircleSlash2 } from "lucide-react";
import { Skeleton } from "../../../../components/ui/skeleton";
import { isDateInPastWeeks } from "../../../../utils/dateUtils";
import { useFetchProgress } from "../hooks";
import { useTranslation } from "react-i18next";
import { ExcludedClientsBar } from "./excludedClientsBar";
import { SupportedClientsBar } from "./supportedClientsBar";
import { NeedsSupportClientsBar } from "./needsSupportClientsBar";

type ProgressChartProps = {
  date: Date;
};

export const ProgressChart = ({ date }: ProgressChartProps) => {
  const isInPast = isDateInPastWeeks(date);
  const { t } = useTranslation();

  const { data, isError, isLoading } = useFetchProgress(!isInPast);

  const EmptyChart = () => <div className="h-20" />;

  if (isError) return <EmptyChart />;

  if (isLoading) {
    return (
      <div className="w-1/2 flex flex-col justify-end items-end -mb-1">
        <Skeleton className="h-7 w-52 rounded" />
        <Skeleton className="h-4 w-32 rounded mt-1" />
        <Skeleton className="h-6 w-1/2 rounded mt-3" />
      </div>
    );
  }

  if (isInPast) return <EmptyChart />;

  const needsSupportCount =
    data!.totalClientCount -
    data!.excludedClientCount -
    data!.supportedClientCount;

  const overallPercent = Math.round(
    ((data!.supportedClientCount + data!.excludedClientCount) /
      data!.totalClientCount) *
      100
  );

  const supportedPercent = Math.round(
    (data!.supportedClientCount / data!.totalClientCount) * 100
  );
  const excludedPercent = Math.round(
    (data!.excludedClientCount / data!.totalClientCount) * 100
  );
  const needsSupportPercent = Math.round(
    (needsSupportCount / data!.totalClientCount) * 100
  );

  if (data!.totalClientCount === 0)
    return (
      <div className="flex flex-col items-end space-y-2">
        <CircleSlash2 className="w-8 h-8 opacity-50" />
        <h2>{t("case-load-manager-progress-chart-no-patients")}</h2>
      </div>
    );

  return (
    <div className="w-1/2 flex flex-col justify-end items-end">
      <h1 className="text-xl">
        {t("case-load-manager-progress-chart-title")}{" "}
        <span className="font-bold">{overallPercent}%</span>
      </h1>
      <h2 className="text-sm">
        {t("case-load-manager-progress-chart-desc", {
          done: data!.supportedClientCount,
          total: data!.totalClientCount - data!.excludedClientCount,
        })}
      </h2>

      <div className="w-1/2 h-6 bg-slate-200 rounded flex mt-2">
        <ExcludedClientsBar
          excludedPercent={excludedPercent}
          supportedPercent={supportedPercent}
          data={data!}
        />
        <SupportedClientsBar supportedPercent={supportedPercent} data={data!} />
        <NeedsSupportClientsBar
          needsSupportCount={needsSupportCount}
          needsSupportPercent={needsSupportPercent}
          data={data!}
        />
      </div>
    </div>
  );
};
