import React from "react";
import { styled } from "@mui/system";

const StyledRoot = styled("div")(({ theme }) => ({
  "& > button:not(:first-child)": {
    marginLeft: theme.spacing(1),
  },
}));

function ButtonsContainer({ children }) {
  return <StyledRoot>{children}</StyledRoot>;
}

export default ButtonsContainer;
