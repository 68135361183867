import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../../../components/ui/table';
import { TablePagination } from '../../../../../components/ui/table-pagination';
import { Loader2 } from 'lucide-react';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { columns } from './columns';
import { UnreadMessageEmailNotificationActivityLog } from '../types';

type UnreadMessageRemindersTableProps = {
  isLoading: boolean;
  isError: boolean;
  data: UnreadMessageEmailNotificationActivityLog[];
  skip: number;
  setSkip: (skip: number) => void;
  pagination: { currentPage: number; total: number; limit: number } | undefined;
};

export const UnreadMessageRemindersTable = ({
  isLoading,
  isError,
  data,
  skip,
  setSkip,
  pagination,
}: UnreadMessageRemindersTableProps) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
  });

  const renderBody = () => {
    if (isLoading) {
      return (
        <TableRow>
          <TableCell colSpan={6}>
            <div className="flex items-center space-x-2">
              <Loader2 className="w-4 h-4 animate-spin" />
              <p>Loading...</p>
            </div>
          </TableCell>
        </TableRow>
      );
    }

    if (isError) {
      return (
        <TableRow>
          <TableCell colSpan={6}>
            <div className="flex items-center space-x-2">
              <p>Something went wrong. Please try again later.</p>
            </div>
          </TableCell>
        </TableRow>
      );
    }

    const hasData = table.getRowModel().rows?.length > 0;

    if (!hasData) {
      return (
        <TableRow>
          <TableCell colSpan={6}>
            <div className="flex items-center space-x-2">
              <p>No data found.</p>
            </div>
          </TableCell>
        </TableRow>
      );
    }

    return (
      <>
        {table.getRowModel().rows.map((row) => {
          return (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => {
                return (
                  <TableCell
                    key={cell.id}
                    style={{
                      width: cell.column.getSize(),
                      minWidth: cell.column.columnDef.minSize,
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </>
    );
  };

  return (
    <>
      <Table className="mt-6 border">
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    key={header.id}
                    className="relative overflow-auto break-words"
                    style={{
                      width: header.getSize(),
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>{renderBody()}</TableBody>
      </Table>
      <TablePagination
        pagination={pagination}
        pageSize={10}
        skip={skip}
        setSkip={setSkip}
        table={table}
      />
    </>
  );
};
