import { ScanEye } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { useState } from "react";
import { Button } from "../../../../components/ui/button";
import { useTranslation } from "react-i18next";

export const PreviewRejectionMail = ({
  referralLanguage,
  patientName,
  comments,
  isCustom,
}: {
  referralLanguage: "en-US" | "nl-NL";
  patientName: string;
  comments: string | undefined;
  isCustom: boolean;
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { t } = useTranslation();

  const getTranslation = (key: string) => {
    return t(key, {
      lng: referralLanguage,
    });
  };

  return (
    <>
      <div
        className="inline-flex items-center space-x-2 text-link"
        onClick={() => setIsDialogOpen(true)}
      >
        <div className="w-4 h-4">
          <ScanEye className="w-4 h-4" />
        </div>
        <p className="cursor-pointer hover:underline">Preview email</p>
      </div>

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="min-w-[70vw]">
          <DialogHeader>
            <DialogTitle>
              {getTranslation("crm-rejection-email-subject")}
            </DialogTitle>
            <DialogDescription>
              <p className="pt-2">
                {t("crm-rejection-email-greeting", {
                  fullName: patientName,
                  lng: referralLanguage,
                })}
              </p>
              <p className="pt-2">
                {getTranslation("crm-rejection-email-title")}
              </p>
              {!isCustom && (
                <p className="pt-2">
                  {getTranslation("crm-rejection-email-details")}
                </p>
              )}
              <p className={comments ? "visible pt-2 font-bold" : "hidden"}>
                {comments}
              </p>
              <p className="pt-2">
                {getTranslation("crm-rejection-email-more-info")}
              </p>
              <p className="pt-2">
                {getTranslation("crm-rejection-email-signature")}
              </p>
              <p className="pt-px">
                {getTranslation("crm-rejection-email-team")}
              </p>
            </DialogDescription>
          </DialogHeader>

          <div className="flex items-center justify-center">
            <Button
              onClick={() => setIsDialogOpen(false)}
              variant="outline"
              className="px-12"
            >
              Close
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
