export default {
  team_1: "1",
  team_2: "2",
  team_3: "3",
  team_4: "4",
  team_5: "5",
  team_6: "6",
  team_7: "7",
  team_8: "8",
  team_9: "9",
  team_10: "10",
  team_11: "11",
  team_12: "12",
  team_13: "13",
  team_14: "14",
  team_15: "15",
};
