import { toast } from "sonner";
import { useGetActiveEpdEmployees, useScheduleIntake } from "../../hooks";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { Button } from "../../../../components/ui/button";
import { cn } from "../../../../lib/utils";
import { ReferralDetails } from "../../types";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../../../../components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../components/ui/popover";
import { useEffect, useState } from "react";
import { CalendarIcon, Check, ChevronsUpDown } from "lucide-react";
import { getEmployeeLastName } from "../../../../utils/getEmployeeLastName";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import { getSimpleFormattedDate } from "../../../../utils/dateUtils";
import { Calendar } from "../../../../components/ui/calendar";

export const ScheduleIntakeManuallyDialog = ({
  referral,
  isDialogOpen,
  setIsDialogOpen,
}: {
  referral: ReferralDetails;
  isDialogOpen: boolean;
  setIsDialogOpen: (newValue: boolean) => void;
}) => {
  const getDefaultTime = () => {
    if (!referral.intakeOn) {
      return "09:00";
    }

    let date = new Date(referral.intakeOn);
    let hours = date.getHours().toString().padStart(2, "0");
    let minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const [selectedTime, setSelectedTime] = useState<string>(getDefaultTime());
  const [selectedDate, setSelectedDate] = useState<Date>(
    referral.intakeOn ?? new Date()
  );

  const [isDateSelectorOpen, setIsDateSelectorOpen] = useState(false);

  const onSetTime = (date: Date, hourAndMinutes: string): Date => {
    // add 'value' as hours to 'setSelectedDate':
    const splitValue = hourAndMinutes.split(":");
    const hours = parseInt(splitValue[0]);
    const minutes = parseInt(splitValue[1]);

    // we need to create a new Date object, because we can't mutate the state directly
    const newDate = new Date(date);
    newDate.setHours(hours);
    newDate.setMinutes(minutes);

    setSelectedDate(newDate);

    return newDate;
  };

  useEffect(() => {
    if (referral.intakeOn) {
      setSelectedDate(referral.intakeOn);
    } else {
      onSetTime(new Date(), "09:00");
    }
  }, []);

  const { data: psychologists, isLoading: isLoadingPsychologists } =
    useGetActiveEpdEmployees();

  const [isPsychologistFilterOpen, setIsPsychologistFilterOpen] =
    useState(false);

  const [selectedPsychologistId, setSelectedPsychologistId] = useState<
    string | null
  >(referral?.intakeByPsychologistId);

  const { mutate: scheduleIntake, isLoading: isSchedulingIntake } =
    useScheduleIntake(() => {
      setIsDialogOpen(false);

      toast("Intake scheduled", {
        description: "The intake has been successfully scheduled.",
      });
    });

  // instead of "John Doe", we show "Doe John" (swap firstnames/lastnames)
  const getDisplayName = (fullName: string) => {
    const splitName = fullName.split(" ");

    const firstName = splitName[0];
    const lastNames = splitName.slice(1).join(" ");

    return `${lastNames} ${firstName}`;
  };

  const PsychologistEntryComponent = ({
    psychologist,
  }: {
    psychologist: any;
  }) => {
    if (!psychologist) return <></>;

    return <p>{getDisplayName(psychologist.name)}</p>;
  };

  return (
    <Dialog
      open={isDialogOpen}
      onOpenChange={(e) => {
        setIsDialogOpen(e);
      }}
    >
      <DialogContent className="min-w-[50vw]">
        <DialogHeader>
          <DialogTitle>Schedule intake manually</DialogTitle>
          <DialogDescription>
            Select manually a consultation room psychologist and a timeslot to
            schedule a face-to-face intake for the patient.
          </DialogDescription>

          <section className="pt-2 flex space-x-2">
            <div className="w-1/2">
              <p className="font-medium pb-1">Psychologist</p>
              <Popover
                open={isPsychologistFilterOpen}
                onOpenChange={setIsPsychologistFilterOpen}
              >
                <PopoverTrigger asChild>
                  <Button
                    autoFocus
                    disabled={isLoadingPsychologists}
                    variant="outline"
                    className="w-full justify-between"
                  >
                    {selectedPsychologistId ? (
                      <PsychologistEntryComponent
                        psychologist={psychologists?.find(
                          (x) => x.id === selectedPsychologistId
                        )}
                      />
                    ) : (
                      <p className="font-normal opacity-70">
                        Select a psychologist...
                      </p>
                    )}
                    <div className="w-4 h-4">
                      <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </div>
                  </Button>
                </PopoverTrigger>
                <PopoverContent
                  align="start"
                  side="bottom"
                  className="w-[400px] p-0 z-[9999999]"
                >
                  <Command
                    filter={(value, search) => {
                      // value contains each entry in the dropdown list -> we find the full model of it:
                      const currentPsy = psychologists?.filter(
                        (p) => p.id === value
                      );

                      if (currentPsy?.length === 0) return 0;

                      // we display "Doe John", but if the user search for 'John D', we should still find the psy
                      // for this, we concat the reversed name w/ the original: "John Doe Doe John"
                      const nameWithReversedName = `${
                        currentPsy![0].name
                      } ${getDisplayName(currentPsy![0].name)}`;

                      return nameWithReversedName
                        .toLowerCase()
                        .includes(search.toLowerCase())
                        ? 1
                        : 0;
                    }}
                  >
                    <CommandInput placeholder="Zoek een psycholoog..." />
                    <CommandEmpty>Geen psycholoog gevonden.</CommandEmpty>
                    <CommandGroup className="max-h-[300px] overflow-y-auto">
                      {psychologists
                        ?.toSorted((a, b) =>
                          getEmployeeLastName(a?.name)?.localeCompare(
                            getEmployeeLastName(b?.name)
                          )
                        )
                        ?.map((employee) => {
                          return (
                            <CommandItem
                              key={employee.id}
                              value={employee.id}
                              onSelect={(currentValue) => {
                                setSelectedPsychologistId(currentValue);
                                setIsPsychologistFilterOpen(false);
                              }}
                            >
                              <Check
                                className={cn(
                                  "mr-2 h-4 w-4",
                                  selectedPsychologistId === employee.id
                                    ? "opacity-100"
                                    : "opacity-0"
                                )}
                              />
                              <PsychologistEntryComponent
                                psychologist={employee}
                              />
                            </CommandItem>
                          );
                        })}
                    </CommandGroup>
                  </Command>
                </PopoverContent>
              </Popover>
            </div>

            <div className="w-1/4">
              <p className="font-medium pb-1">Date</p>
              <Popover
                open={isDateSelectorOpen}
                onOpenChange={setIsDateSelectorOpen}
              >
                <PopoverTrigger asChild>
                  <Button className="w-full" variant="outline">
                    <span>{getSimpleFormattedDate(selectedDate)}</span>
                    <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="z-[999999]" align="start">
                  <Calendar
                    mode="single"
                    toDate={undefined}
                    fromDate={new Date()}
                    selected={selectedDate}
                    onSelect={(_, d) => {
                      onSetTime(d, selectedTime);
                      setIsDateSelectorOpen(false);
                    }}
                    initialFocus
                    disabled={(d) => {
                      // none in past
                      if (d < new Date()) return true;

                      // weekends are NOT to select
                      if (d.getDay() == 6 || d.getDay() == 0) return true;

                      // rest is enabled
                      return false;
                    }}
                  />
                </PopoverContent>
              </Popover>
            </div>

            <div className="w-1/4">
              <p className="font-medium pb-1">Time</p>
              <Select
                onValueChange={(value) => {
                  setSelectedTime(value);
                  onSetTime(selectedDate, value);
                }}
                defaultValue={selectedTime}
              >
                <SelectTrigger className="font-bold">
                  <SelectValue placeholder="Select a timeslot..." />
                </SelectTrigger>
                <SelectContent className="z-[9999999]">
                  {[
                    "08:00", // we should use a real time picker here
                    "08:30",
                    "09:00",
                    "09:30",
                    "10:00",
                    "10:30",
                    "11:00",
                    "11:30",
                    "12:00",
                    "12:30",
                    "13:00",
                    "13:30",
                    "14:00",
                    "14:30",
                    "15:00",
                    "15:30",
                    "16:00",
                    "16:30",
                    "17:00",
                    "17:30",
                  ].map((dt, id) => {
                    return (
                      <SelectItem value={dt} key={id}>
                        {dt}
                      </SelectItem>
                    );
                  })}
                </SelectContent>
              </Select>
            </div>
          </section>

          <div className="pt-4 flex justify-end">
            <Button
              className="w-fit px-4"
              disabled={!selectedPsychologistId || isSchedulingIntake}
              onClick={async () => {
                await scheduleIntake({
                  referralId: referral.id,
                  selectedPsychologistEpdId: psychologists!.filter(
                    (p) => p.id === selectedPsychologistId
                  )![0].epdId,
                  appointmentOn: selectedDate,
                });
              }}
            >
              {isSchedulingIntake ? "Confirming intake..." : "Confirm intake"}
            </Button>
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};
