import {
  CrmInflowByLocationQueryResponse,
  CrmInflowByLocationResponseItem,
} from "../../../../types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableFooter,
} from "../../../../../../components/ui/table";

export const ReferralsPerLocationTable = ({
  data,
  isLoading,
}: {
  data: CrmInflowByLocationQueryResponse | undefined;
  isLoading: boolean;
}) => {
  const cities = Array.from(
    new Set(data?.reportItems.map((item) => item.city) || [])
  ).sort((a, b) => (a || "").localeCompare(b || ""));
  const weeks = Array.from(
    new Set(data?.reportItems.map((item) => item.week) || [])
  ).sort((a, b) => b - a);

  return (
    <div className="overflow-x-auto">
      <Table className="border min-w-[1200px] table-auto">
        <TableHeader>
          <TableRow>
            <TableHead className="min-w-[200px] sticky left-0 z-20 border-r">
              City
            </TableHead>
            {weeks.map((week) => (
              <TableHead key={week} className="w-[150px]">
                Week {week}
              </TableHead>
            ))}
            <TableHead className="w-[150px]">Total</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {cities.length > 0 ? (
            cities.map((city) => (
              <TableRow key={city}>
                <TableCell className="py-1 min-w-[250px] sticky left-0 z-10 border-r bg-slate-100">
                  {city ?? "Unknown"}
                </TableCell>
                {weeks.map((week) => {
                  const entry = data?.reportItems.find(
                    (item) => item.city === city && item.week === week
                  );
                  return (
                    <TableCell key={week} className="py-1 min-w-[150px]">
                      {entry ? entry.numberOfEntries : 0}
                    </TableCell>
                  );
                })}
                <TableCell className="py-1 min-w-[150px]">
                  {data?.reportItems
                    .filter((item) => item.city === city)
                    .reduce((sum, item) => sum + item.numberOfEntries, 0)}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={weeks.length + 2} className="py-1">
                {isLoading ? "Loading..." : "No data available"}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell className="py-1 min-w-[250px] sticky left-0 z-10 border-r bg-slate-100">
              Total
            </TableCell>
            {weeks.map((week) => (
              <TableCell key={week} className="py-1 min-w-[150px]">
                {data?.totalCountPerWeek[week] || 0}
              </TableCell>
            ))}
            <TableCell className="py-1 min-w-[150px]">
              {data?.totalCount || 0}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
};
