export const MedebehandelaarsWarning = ({
  intakeBy,
  preIntakeBy,
}: {
  intakeBy: string;
  preIntakeBy: string;
}) => {
  return (
    <>
      <p>
        After you have converted the patient to a dossier, please open the
        Praktijkdata dossier, go to 'Aanmelding', and add to the
        Medebehandelaars field the psychologist selected as the Pre-Intake
        psychologist:
      </p>
      <ul className="list-disc list-inside mt-2 space-y-1 font-bold">
        <li>{preIntakeBy}</li>
      </ul>
    </>
  );
};
