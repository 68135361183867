import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../../../components/ui/alert-dialog";
import { Button } from "../../../../components/ui/button";
import { useResyncPraktijkdataPatients } from "../hooks";
import { toast } from "sonner";
import { useState } from "react";

export const ResynchronizePatients = () => {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const { mutate, isLoading } = useResyncPraktijkdataPatients(() => {
    setIsConfirmDialogOpen(false);
    toast("Resynchronized", {
      description: "The data has been successfully resynchronized.",
    });
  });

  return (
    <div className="mt-12">
      <h1 className="font-bold text-4xl">
        Re-synchronize approved Praktijkdata patients
      </h1>
      <p>
        Manually triggers the synchronization between the Praktijkdata patients
        and the pending patients in the CRM. This will take a few minutes.
      </p>

      <AlertDialog
        open={isConfirmDialogOpen}
        onOpenChange={setIsConfirmDialogOpen}
      >
        <AlertDialogTrigger asChild>
          <Button className="mt-2">Resynchronize</Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription className="space-y-2">
              <p>
                The system will re-synchronize the patients in the CRM from
                Praktijkdata. This will take a few minutes.
              </p>
              <p>If you are sure, please click the "Resynchronize" button.</p>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <Button onClick={async () => await mutate()}>
              {isLoading ? "Resynchronizing..." : "Resynchronize"}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};
