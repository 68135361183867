import { Info, Loader2, Pencil } from "lucide-react";
import { useTranslation } from "react-i18next";
import { OnboardPatientDetails } from "../../types";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../../components/ui/dialog";
import { Textarea } from "../../../../../components/ui/textarea";
import { Button } from "../../../../../components/ui/button";
import { useEffect, useState } from "react";
import { useCustomizeWelcomeMessage } from "../../hooks";
import { toast } from "sonner";
import { getDidIntakeHappen } from "../../utils";

type CustomizeWelcomeMessageProps = {
  onboardingPatient: OnboardPatientDetails;
};

export const CustomizeWelcomeMessage = ({
  onboardingPatient,
}: CustomizeWelcomeMessageProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [personalizedWelcomeMessage, setPersonalizedWelcomeMessage] = useState(
    onboardingPatient.personalizedWelcomeMessage ??
      t("case-load-manager-onboarding-details-welcome-msg", {
        lng: onboardingPatient.language,
      })
  );

  useEffect(() => {
    setPersonalizedWelcomeMessage(
      onboardingPatient.personalizedWelcomeMessage ??
        t("case-load-manager-onboarding-details-welcome-msg", {
          lng: onboardingPatient.language,
        })
    );
  }, [onboardingPatient.language]);

  const { mutate: save, isLoading: isSaving } = useCustomizeWelcomeMessage(
    () => {
      toast(
        t(
          "case-load-manager-onboarding-details-welcome-msg-customize-toast-title"
        ),
        {
          description: t(
            "case-load-manager-onboarding-details-welcome-msg-customize-toast-desc"
          ),
        }
      );

      setIsOpen(false);
    }
  );

  if (
    onboardingPatient.welcomeMessageSent ||
    getDidIntakeHappen(onboardingPatient)
  )
    return <></>;

  const isAlreadyCustomized =
    (onboardingPatient.personalizedWelcomeMessage?.length ?? 0) > 0;

  return (
    <>
      <div className="pt-2 pl-6">
        {isAlreadyCustomized && (
          <div className="inline-flex items-center space-x-2 bg-green-100 text-green-600 rounded-lg px-2 py-1 my-1">
            <div className="w-4 h-4">
              <Info className="w-4 h-4" />
            </div>
            <p>You have customized the treatment start message</p>
          </div>
        )}
        <div
          className={`inline-flex items-center space-x-2 text-link cursor-pointer hover:underline ${
            isAlreadyCustomized ? "ml-2" : "ml-0"
          }`}
          onClick={() => setIsOpen(true)}
        >
          <div className="w-4 h-4">
            <Pencil className="w-4 h-4" />
          </div>
          <p>
            {isAlreadyCustomized
              ? t(
                  "case-load-manager-onboarding-details-welcome-msg-customize-update-button"
                )
              : t(
                  "case-load-manager-onboarding-details-welcome-msg-customize-button"
                )}
          </p>
        </div>
      </div>
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="min-w-[70vw]">
          <DialogHeader>
            <DialogTitle>
              {t(
                "case-load-manager-onboarding-details-welcome-msg-dialog-title"
              )}
            </DialogTitle>
          </DialogHeader>

          <p className="py-2">
            {t(
              "case-load-manager-onboarding-details-welcome-msg-customize-placeholder-warning"
            )}
          </p>

          <Textarea
            value={personalizedWelcomeMessage}
            onChange={(e) => setPersonalizedWelcomeMessage(e.target.value)}
            disabled={isSaving}
            rows={15}
          />

          <DialogFooter className="w-full flex justify-center items-center">
            <Button
              onClick={() => setIsOpen(false)}
              variant="outline"
              className="w-fit px-6"
            >
              {t(
                "case-load-manager-onboarding-details-welcome-msg-dialog-cancel"
              )}
            </Button>
            <Button
              onClick={async () =>
                await save({
                  id: onboardingPatient.id,
                  personalizedWelcomeMessage,
                })
              }
              className="w-fit px-6 flex items-center space-x-2"
            >
              {isSaving && <Loader2 className="w-4 h-4 animate-spin" />}
              <p>
                {t(
                  "case-load-manager-onboarding-details-welcome-msg-dialog-save"
                )}
              </p>
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
