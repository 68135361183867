import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import Divider from "@mui/material/Divider";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import {
  createEMDRTag,
  createExperienceTag,
  createGenderTag,
  createLanguageTag,
  createLocationTag,
  createSchemaTherapyExperienceTag,
  createStatusTag,
  createTeamTag,
} from "./tagHelpers";
import tags from "../../utils/constants/tags/tags";
import {
  Grid,
  FormControlLabel,
  FormGroup,
  Typography,
  Switch,
} from "@mui/material";
import tagEnums from "../../utils/constants/tags/tagEnums";
import emdrEnum from "../../utils/constants/tags/emdrEnum";
import schemaTherapyEnum from "../../utils/constants/tags/schemaTherapyEnum";
import experienceEnum from "../../utils/constants/tags/experienceEnum";
import { EpdUser, UserTags } from "./types";

const StyledCardRoot = styled(Card)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  outline: "none",
  width: 850,
  maxHeight: "100%",
  overflowY: "auto",
  maxWidth: "100%",
}));

const StyledCardActions = styled(CardActions)(() => ({
  justifyContent: "flex-end",
  padding: "24px",
}));

const StyledFormGroupTitles = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

function ChangeTagsModal({
  open,
  onClose,
  onSave,
  employee,
  employeeTags,
}: {
  open: boolean;
  onClose: () => void;
  onSave: (tags: any[]) => void;
  employee: EpdUser;
  employeeTags: UserTags;
}) {
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [selectedTeam, setSelectedTeam] = useState<string[]>([]);
  const [selectedGender, setSelectedGender] = useState<string[]>([]);
  const [isEmdr, setIsEmdr] = useState(false);
  const [isExperience, setIsExperience] = useState(false);
  const [isSchemaTherapy, setIsSchemaTherapy] = useState(false);

  // This is used to activate all the visible tags on the dialog based on the tags of the current employee
  useEffect(() => {
    setIsEmdr(
      employeeTags.assignedTags.some(
        (r) => r.tagType.name === tagEnums.EMDR && r.tag.name == emdrEnum.YES
      )
    );

    setIsSchemaTherapy(
      employeeTags.assignedTags.some(
        (r) =>
          r.tagType.name === tagEnums.SCHEMA_THERAPY_EXPERIENCE &&
          r.tag.name == schemaTherapyEnum.YES
      )
    );

    setIsExperience(
      employeeTags.assignedTags.some(
        (r) =>
          r.tagType.name === tagEnums.EXPERIENCE &&
          r.tag.name == experienceEnum.GZ
      )
    );

    const statusTag = employeeTags.assignedTags.find(
      (r) => r.tagType.name === tagEnums.STATUS
    );

    if (statusTag != null) {
      setSelectedStatus(statusTag.tag.name);
    }

    setSelectedLanguages(
      employeeTags.assignedTags
        .filter((r) => r.tagType.name === tagEnums.LANGUAGE)
        .map((r) => r.tag.name)
    );

    setSelectedLocations(
      employeeTags.assignedTags
        .filter((r) => r.tagType.name === tagEnums.LOCATION)
        .map((r) => r.tag.name)
    );

    setSelectedGender(
      employeeTags.assignedTags
        .filter((r) => r.tagType.name === tagEnums.GENDER)
        .map((r) => r.tag.name)
    );

    setSelectedTeam(
      employeeTags.assignedTags
        .filter((r) => r.tagType.name === tagEnums.TEAM)
        .map((r) => r.tag.name)
    );
  }, [employeeTags]);

  const onSubmit = () => {
    onSave([
      // the following arguments are arrays of strings
      createLanguageTag(selectedLanguages),
      createLocationTag(selectedLocations),
      createTeamTag(selectedTeam),
      createGenderTag(selectedGender),
      // selectedStatus must be a single string
      createStatusTag(selectedStatus),
      //  the following arguments are boolean values
      createEMDRTag(isEmdr),
      createExperienceTag(isExperience),
      createSchemaTherapyExperienceTag(isSchemaTherapy),
    ]);
  };

  if (!open) {
    return null;
  }

  return (
    <Modal onClose={onClose} open={open}>
      <StyledCardRoot>
        <form>
          <h1 className="text-2xl font-bold py-4 px-6 text-gray-700">
            Tags aanpassen - {employee.name}
          </h1>
          <Divider />
          <div className="px-6 py-2 flex flex-col space-y-3 divide-y-[1px]">
            <FormGroup>
              <StyledFormGroupTitles variant="h6">Status</StyledFormGroupTitles>
              <Grid spacing={1} container>
                {tags.STATUS.map((status) => (
                  <Grid key={status} item>
                    <Button
                      variant={
                        selectedStatus === status ? "contained" : "outlined"
                      }
                      onClick={() => setSelectedStatus(status)}
                    >
                      {status}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </FormGroup>
            <FormGroup>
              <StyledFormGroupTitles variant="h6">
                Langauge
              </StyledFormGroupTitles>
              <Grid spacing={1} container>
                {tags.LANGUAGE.map((language) => (
                  <Grid key={language} item>
                    <Button
                      variant={
                        selectedLanguages.indexOf(language) === -1
                          ? "outlined"
                          : "contained"
                      }
                      onClick={() =>
                        selectedLanguages.indexOf(language) === -1
                          ? setSelectedLanguages([
                              ...selectedLanguages,
                              language,
                            ])
                          : setSelectedLanguages(
                              selectedLanguages.filter(
                                (selectedLanguage) =>
                                  selectedLanguage !== language
                              )
                            )
                      }
                    >
                      {language}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </FormGroup>
            <FormGroup>
              <StyledFormGroupTitles variant="h6">
                Location
              </StyledFormGroupTitles>
              <Grid spacing={1} container>
                {tags.LOCATION.map((location) => (
                  <Grid key={location} item>
                    <Button
                      variant={
                        selectedLocations.indexOf(location) === -1
                          ? "outlined"
                          : "contained"
                      }
                      onClick={() =>
                        selectedLocations.indexOf(location) === -1
                          ? setSelectedLocations([
                              ...selectedLocations,
                              location,
                            ])
                          : setSelectedLocations(
                              selectedLocations.filter(
                                (selectedLocation) =>
                                  selectedLocation !== location
                              )
                            )
                      }
                    >
                      {location}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </FormGroup>
            <FormGroup>
              <StyledFormGroupTitles variant="h6">Team</StyledFormGroupTitles>
              <Grid spacing={1} container>
                {tags.TEAM.map((team) => (
                  <Grid key={team} item>
                    <Button
                      variant={
                        selectedTeam.indexOf(team) === -1
                          ? "outlined"
                          : "contained"
                      }
                      onClick={() =>
                        selectedTeam.indexOf(team) === -1
                          ? setSelectedTeam([...selectedTeam, team])
                          : setSelectedTeam(
                              selectedTeam.filter(
                                (selectedLocation) => selectedLocation !== team
                              )
                            )
                      }
                    >
                      {team}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </FormGroup>
            <div className="grid grid-cols-5 gap-2 items-center">
              <div className="col-span-2">
                <StyledFormGroupTitles variant="h6">
                  Gender
                </StyledFormGroupTitles>
                <Grid spacing={1} container>
                  {tags.GENDER.map((gender) => (
                    <Grid key={gender} item>
                      <Button
                        variant={
                          selectedGender.indexOf(gender) === -1
                            ? "outlined"
                            : "contained"
                        }
                        onClick={() => setSelectedGender([gender])}
                      >
                        {gender}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </div>
              <div>
                <FormControlLabel
                  label="EMDR"
                  sx={{ marginTop: "16px" }}
                  control={
                    <Switch
                      checked={isEmdr}
                      onChange={() => setIsEmdr(!isEmdr)}
                      name="emdr_switch"
                    />
                  }
                />
              </div>
              <FormGroup>
                <FormControlLabel
                  label="GZ"
                  sx={{ marginTop: "16px" }}
                  control={
                    <Switch
                      checked={isExperience}
                      onChange={() => setIsExperience(!isExperience)}
                      name="gz_switch"
                    />
                  }
                />
              </FormGroup>
              <FormGroup>
                <FormControlLabel
                  label="Schema Therapy"
                  sx={{ minWidth: "250px", marginTop: "16px" }}
                  control={
                    <Switch
                      checked={isSchemaTherapy}
                      onChange={() => setIsSchemaTherapy(!isSchemaTherapy)}
                      name="schema_therapy_switch"
                    />
                  }
                />
              </FormGroup>
            </div>
          </div>
          <StyledCardActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              color="primary"
              onClick={() => onSubmit()}
              variant="contained"
            >
              Save
            </Button>
          </StyledCardActions>
        </form>
      </StyledCardRoot>
    </Modal>
  );
}

export default ChangeTagsModal;
