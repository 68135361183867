import { useTranslation } from "react-i18next";

type LoaderProps = {
  isLoading: boolean;
};

export const Loader = ({ isLoading }: LoaderProps) => {
  const { t } = useTranslation();

  return (
    <>
      {isLoading && (
        <div className="flex justify-center p-4 opacity-80">
          {t("case-load-manager-loading")}
        </div>
      )}
    </>
  );
};
