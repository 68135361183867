import { Input } from "../../../components/ui/input";

export const FindDossier = ({
  epdPatientId,
  setEpdPatientId,
  epdFileId,
  setEpdFileId,
}: {
  epdPatientId: number | null;
  setEpdPatientId: (value: number | null) => void;
  epdFileId: number;
  setEpdFileId: (value: number) => void;
}) => {
  return (
    <section className="w-full flex items-center space-x-2">
      <div className="w-1/4">
        <label htmlFor="clientNumber" className="font-bold">
          Client nr.
        </label>
        <Input
          type="number"
          id="clientNumber"
          placeholder="The EPD ID of the client"
          autoFocus
          value={epdPatientId?.toString() ?? ""}
          onChange={(event) => {
            setEpdPatientId(
              event.target.value === "" ? null : Number(event.target.value)
            );
          }}
        />
      </div>

      <div className="w-1/4">
        <label htmlFor="fileId" className="font-bold">
          File nr.
        </label>
        <Input
          type="number"
          id="fileId"
          value={epdFileId?.toString() ?? ""}
          onChange={(event) => {
            setEpdFileId(Number(event.target.value));
          }}
        />
      </div>
    </section>
  );
};
