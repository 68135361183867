import { useRetrySchedulePraktijkdataAppointments } from "../../hooks";
import { Button } from "../../../../components/ui/button";
import { ReferralDetails } from "../../types";
import { toast } from "sonner";
import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogFooter,
} from "../../../../components/ui/dialog";

export const AppointmentCreationFailure = ({
  referral,
  area,
}: {
  referral: ReferralDetails;
  area: "intake" | "pre-intake";
}) => {
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);

  const { mutate, isLoading } = useRetrySchedulePraktijkdataAppointments(
    () => {
      toast("Appointments created", {
        description: "The appointments have been created in PraktijkData.",
      });
    },
    () => {
      setErrorDialogOpen(true);
    }
  );

  return (
    <div className="mt-4 bg-red-50 border border-red-100 p-3 rounded">
      <p className="font-bold text-red-500">
        {area == "intake" ? "Intake" : "Pre-intake"} appointment could not be
        scheduled
      </p>

      {area == "intake" ? (
        <p className="pt-1 text-red-500">
          Looks like the Dashboard could not schedule the appointment in
          PraktijkData. Please try again, or contact IT Support.
        </p>
      ) : (
        <>
          <p className="pt-1 text-red-500">
            Are you sure {referral.preIntakeByPsychologistName} is added to the
            Medebehandelaars field in PraktijkData? Please double check by going
            to the{" "}
            <a
              href={`https://start.praktijkdata.nl/app/clienten/${referral.epdPatientId}/dossiers/${referral.epdFileId}/aanmelding`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-link cursor-pointer hover:underline"
            >
              dossier (Aanmelding → Medebehandelaars)
            </a>
            .
          </p>
          <p className="pt-2 text-red-500">
            If you are sure, please press the retry button below.
          </p>
        </>
      )}

      <Button
        variant="outline"
        className="mt-3"
        onClick={async () => {
          await mutate({ referralId: referral.id });
        }}
        disabled={isLoading}
      >
        {isLoading
          ? "Retrying Praktijkdata appointment creation..."
          : "Retry Praktijkdata appointment creation"}
      </Button>

      <Dialog
        open={errorDialogOpen}
        onOpenChange={(newValue) => {
          setErrorDialogOpen(newValue);
        }}
      >
        <DialogContent className="min-w-[25vw]">
          <DialogTitle>Could not create appointments</DialogTitle>
          <p>
            Unfortunately, the system could not create the appointments in
            PraktijkData. Please make sure both{" "}
            {referral.intakeByPsychologistName} and{" "}
            {referral.preIntakeByPsychologistName} are listed in the
            Medebehandelaars field, and try again later.
          </p>
          <DialogFooter>
            <Button variant="outline" onClick={() => setErrorDialogOpen(false)}>
              OK
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};
