import { Row } from "../../types";
import { TableCell } from "../../../../../components/ui/table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../../../components/ui/tooltip";
import { Info } from "lucide-react";

type NameCellProps = {
  data: Row;
};

export const NameCell = ({ data }: NameCellProps) => {
  const getDisplayName = (fullName: string) => {
    const splitName = fullName.split(" ");

    const firstName = splitName[0];
    const lastNames = splitName.slice(1).join(" ");

    return `${lastNames} ${firstName}`;
  };

  return (
    <TableCell>
      <p className="font-bold">{getDisplayName(data.employeeName)}</p>

      <div className="flex items-center space-x-1">
        <p className="pt-px text-xs">{data.status}</p>
        {data.status === "Active - not accepting new clients" ? (
          <TooltipProvider>
            <Tooltip delayDuration={100}>
              <TooltipTrigger asChild>
                <div className="h-3 w-3">
                  <Info className="h-3 w-3" />
                </div>
              </TooltipTrigger>
              <TooltipContent className="w-[320px] text-center bg-slate-900 text-slate-300 p-4">
                <p>
                  Psychologists in 'Active - not accepting new clients' status
                  are excluded from the intake-calendar. To edit the user's
                  tags, please go to the{" "}
                  <a
                    className="cursor-pointer text-link hover:underline"
                    href="https://dashboard.ipractice.nl/admin/users/tags"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Tag Administration
                  </a>
                  .
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ) : (
          <></>
        )}
      </div>
    </TableCell>
  );
};
