import { Loader2 } from "lucide-react";
import { useGetCaseLoadQuickCardKpisTotalNumberOfSupportedPatients } from "../../hooks";

export const TotalNumberOfSupportedPatientsCard = ({
  date,
}: {
  date: Date;
}) => {
  const { data, isLoading } =
    useGetCaseLoadQuickCardKpisTotalNumberOfSupportedPatients(date);

  return (
    <div className="rounded-lg border p-4 inline-block w-1/5">
      <p className="text-xs">Number of supported Patients</p>
      <h1 className="font-bold text-2xl">
        {isLoading ? (
          <Loader2 className="w-6 h-6 mt-1 opacity-70 animate-spin" />
        ) : (
          data!
        )}
      </h1>
    </div>
  );
};
