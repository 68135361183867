import { Info } from "lucide-react";
import { useState } from "react";
import { useGenerateAkwaGgzReport } from "../hooks";
import { toast } from "sonner";
import { Calendar } from "../../../components/ui/calendar";
import { Button } from "../../../components/ui/button";

export const AkwaGgzReportGenerationAdmin = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const { mutate: generateReport, isLoading } = useGenerateAkwaGgzReport(
    startDate,
    endDate,
    () => {
      toast("Report Generated", {
        description:
          "The report has been successfully generated and is available in the storage account.",
      });
    }
  );

  return (
    <main className="p-24">
      <h2 className="text-4xl font-bold">Akwa Ggz Report Generation</h2>
      <p className="pt-2">
        Set the date periods and export the Akwa Ggz report.
      </p>

      <section className="w-fit flex space-x-12 mt-8 border p-12 rounded">
        <section>
          <p className="font-bold border-b text-center pb-1 mb-3 bg-slate-50 pt-2">
            Start Date
          </p>
          <Calendar
            mode="single"
            toDate={undefined}
            selected={startDate}
            defaultMonth={startDate}
            onSelect={(_, d) => {
              setStartDate(d);
            }}
            initialFocus
          />
        </section>

        <section>
          <p className="font-bold border-b text-center pb-1 mb-3 bg-slate-50 pt-2">
            End Date
          </p>
          <Calendar
            mode="single"
            toDate={undefined}
            selected={endDate}
            defaultMonth={endDate}
            onSelect={(_, d) => {
              setEndDate(d);
            }}
          />
        </section>
      </section>

      <Button
        className="mt-8 px-6"
        onClick={() => generateReport({ startDate, endDate })}
      >
        {isLoading ? "Generating report..." : "Genetate report"}
      </Button>
    </main>
  );
};

export default AkwaGgzReportGenerationAdmin;
