import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../react-query/provider";
import moment from "moment";

export const useGenerateAkwaGgzReport = (
  startDate: Date,
  endDate: Date,
  onSuccess: (data: any[]) => void
) => {
  const mutation = useMutation<any[], Error, { startDate: Date; endDate: Date }>(
    async ({ startDate, endDate }) => {
      const res = await axios.post(
        `admin/akwaggz/generate-report`,
        { startDate: moment(startDate).utc(true).toISOString(), endDate: moment(endDate).utc(true).toISOString() }
      );
      return res.data;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("admin");
        onSuccess(data);
      },
    }
  );

  return mutation;
};