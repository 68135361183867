import { IPracticeAvatar } from './iPracticeAvatar';
import { cn } from '../../../../lib/utils';
import { Loader2 } from 'lucide-react';

export function ChatMessageLoadingIndicator() {
  return (
    <div className="flex">
      <div className="flex">
        <div className="relative flex-shrink-0 flex items-center justify-center overflow-hidden rounded-full bg-ipractice-blue/70 h-10 w-10">
          <IPracticeAvatar />
        </div>
      </div>

      <div
        className={cn(
          'mb-8 ml-2 mr-auto flex items-center space-x-2 rounded-2xl rounded-tl-sm border bg-white p-3'
        )}
      >
        <Loader2 className="animate-spin h-4 w-4" />
        <p>Thinking...</p>
      </div>
    </div>
  );
}
