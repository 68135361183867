import { ColumnDef } from "@tanstack/react-table";
import { MainListPatient } from "../../types";
import { Mail, MessageCircleMore, Phone } from "lucide-react";
import { useTranslation } from "react-i18next";

export const PatientContactInfoColName = "ContactInfo";

export const PatientContactInfoCol: ColumnDef<MainListPatient> = {
  id: PatientContactInfoColName,
  accessorKey: "name",
  size: 150,
  minSize: 10,
  header: () => {
    const { t } = useTranslation();

    return <p>{t("case-load-manager-column-contact-information")}</p>;
  },
  cell: ({ row }) => {
    const email = row.original.email;
    const wireUserName = row.original.wireUserName;
    const phone = row.original.phoneNumber;

    return (
      <div className="text-xs py-2 px-4">
        <div className="flex items-center space-x-2">
          <div className="w-4 h-4">
            <Mail className="w-4 h-4" />
          </div>
          <p className="whitespace-pre-wrap break-all">{email}</p>
        </div>

        {phone && (
          <div className="flex items-center space-x-2 pt-1">
            <div className="w-4 h-4">
              <Phone className="w-4 h-4" />
            </div>
            <p className="whitespace-pre-wrap break-all">{phone}</p>
          </div>
        )}

        {wireUserName && (
          <div className="flex items-center space-x-2 pt-1">
            <div className="w-4 h-4">
              <MessageCircleMore className="w-4 h-4" />
            </div>
            <p className="whitespace-pre-wrap break-all">{wireUserName}</p>
          </div>
        )}
      </div>
    );
  },
};
