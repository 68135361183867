import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import * as colors from "@mui/material/colors";

const Loader = (props) => {
  const { area, children, className } = props;

  const { promiseInProgress } = usePromiseTracker({ area });

  if (promiseInProgress) {
    return (
      <p
        className={`p-6 bg-gray-200 dark:bg-slate-800 animate-pulse ${
          className ? className : ""
        }`}
      >
        Loading...
      </p>
    );
  }

  return <>{children}</>;
};

export default Loader;
