import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const configureI18n = (translations) => {
  const resources = {
    nl: {
      translation: translations.nl,
    },
    en: {
      translation: translations.en,
    },
  };

  i18n.use(initReactI18next).init(
    {
      resources,
      lng: localStorage.getItem("preferred-lang") ?? "nl",
      fallbackLng: "en",
      interpolation: {
        escapeValue: false,
      },
      react: {
        wait: true,
        useSuspense: false,
      },
    },
    async () => {
      for (let key in translations) {
        i18n.addResources(key, "translation", translations[key]);
      }
      await i18n.changeLanguage(i18n.language);
    }
  );

  return i18n;
};

export default configureI18n;
export const i18nInstance = i18n;
