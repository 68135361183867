import React from "react";
import { observer } from "mobx-react";
import { styled } from "@mui/system";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  CardActions,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSnackbar } from "notistack";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import TextField from "@mui/material/TextField/TextField";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import StatusLabelBoolean from "../../../shared/StatusLabelBoolean";
import {
  getIWelcomeUrl,
  getReferralLetterUrl,
} from "../../../utils/urlHelpers";
import useStores from "../../../useStores";
import areas from "../../../utils/constants/areas";
import Loader from "../../../shared/Loader";
import onboardingStatusEnum from "../../../utils/constants/onboardingStatusEnum";
import ReferralLetterRow from "../../../shared/ReferralLetterRow";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import dossierType from "../../../utils/constants/dossierType";
import dossierSubType from "../../../utils/constants/dossierSubType";
import healthCareInsurerType from "../../../utils/constants/healthCareInsurerType";
import { IWelcomeEmailSender } from "./iWelcome/iWelcomeEmailSender";
import { SuccessfullySentEmails } from "./iWelcome/successfullySentEmails";
import { UnsuccessfullySentEmails } from "./iWelcome/unsuccessfullySentEmails";
import { NewTreatmentDossierInfo } from "./adhdStartTreatment/newTreatmentDossierInfo";
import { SwitchToTreatmentButton } from "./adhdStartTreatment/switchToTreatmentButton";

const StyledCardContent = styled(CardContent)(() => ({
  padding: 0,
}));

const StyledCheckCircleOutlineIcon = styled(CheckCircleOutlineIcon)(
  ({ theme }) => ({
    marginRight: theme.spacing(1),
  })
);

const StyledDoneAllIcon = styled(DoneAllIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));

const StyledTextareaAutosize = styled(TextareaAutosize)(({ theme }) => ({
  width: "100%",
  border: `1px solid ${theme.palette.grey[400]}`,
  borderRadius: "4px",
  margin: "4px 0",
}));

const StyledOpenInNewIcon = styled(OpenInNewIcon)(({ theme }) => ({
  float: "right",
  marginLeft: theme.spacing(1),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  float: "right",
  marginLeft: theme.spacing(1),
}));

const StyledWireTextField = styled(TextField)(({ theme }) => ({
  marginTop: 0,
  height: 32,
  float: "right",
  marginLeft: theme.spacing(1),
}));

const Onboarding = () => {
  const { t } = useTranslation();
  const { patientStore, onboardingStore } = useStores();
  const { enqueueSnackbar } = useSnackbar();
  const { onboarding } = onboardingStore;
  const { patient } = patientStore;
  const dossierId = onboarding.id;
  const currentDossier = patientStore.patient.dossiers.filter(
    (d) => d.id === dossierId
  )[0];

  const setOnboardingCompletedManually = () => {
    onboardingStore
      .patchOnboarding(dossierId, { isOnboardingCompletedManually: true })
      .then(
        () => enqueueSnackbar("Onboarding afgerond", { variant: "success" }),
        (error) => enqueueSnackbar(error.response.data, { variant: "error" })
      );
  };

  return (
    <Loader area={areas.ONBOARDING}>
      <Card>
        <Formik
          enableReinitialize
          initialValues={{
            note: onboarding.note ? onboarding.note : "",
            userProvidedWireUsername: onboarding.patient
              .userProvidedWireUsername
              ? onboarding.patient.userProvidedWireUsername
              : "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            if (
              values.userProvidedWireUsername !==
              onboarding.patient.userProvidedWireUsername
            ) {
              patientStore
                .patchPatient({
                  userProvidedWireUsername: values.userProvidedWireUsername,
                })
                .then(
                  () => {
                    enqueueSnackbar("Wire username opgeslagen", {
                      variant: "success",
                    });
                    onboardingStore.fetchOnboarding(dossierType.GGZ, dossierId);
                    setSubmitting(false);
                  },
                  (error) => {
                    enqueueSnackbar(error.response.data, { variant: "error" });
                    setSubmitting(false);
                  }
                );
            }
            if (values.note !== onboarding.note) {
              onboardingStore
                .patchOnboarding(dossierId, { note: values.note })
                .then(
                  () => {
                    enqueueSnackbar("Notitie opgeslagen", {
                      variant: "success",
                    });
                    setSubmitting(false);
                  },
                  (error) => {
                    enqueueSnackbar(error.response.data, { variant: "error" });
                    setSubmitting(false);
                  }
                );
            }
            // resetForm(); Don't reset form it gets filled again from the store using Formik enableReinitialize.
          }}
          validationSchema={Yup.object().shape({
            note: Yup.string(),
            userProvidedWireUsername: Yup.string(),
          })}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              dirty,
            } = props;

            return (
              <form autoComplete="off" noValidate>
                <CardHeader title="Onboarding" />
                <Divider />
                <NewTreatmentDossierInfo
                  dossier={currentDossier}
                  patient={patient}
                />
                <StyledCardContent>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            verticalAlign: "top",
                            padding: "24px 16px 16px 16px",
                          }}
                        >
                          iWelcome
                        </TableCell>
                        <TableCell>
                          <div className="w-full flex justify-between items-center">
                            <div className="w-1/2 flex items-center space-x-2">
                              <StatusLabelBoolean
                                boolean={
                                  onboarding.iWelcomeProgress.status ===
                                  "Finished"
                                }
                                positive="Voltooid"
                                negative="Open"
                              />
                              <Button
                                size="small"
                                href={getIWelcomeUrl(onboarding.lookupGuid)}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <StyledOpenInNewIcon />
                              </Button>
                            </div>
                            <div className="w-2/3 flex flex-col items-end space-x-2">
                              <IWelcomeEmailSender
                                text="Email"
                                dossierId={dossierId}
                                dossierType="DossierGgz"
                                dossierHasReferralLetterAlready={
                                  currentDossier.isReferralLetterPresent
                                }
                                crmReferral={null}
                              />
                            </div>
                          </div>
                          <div className="mt-5 pt-3 border-t flex flex-col">
                            <SuccessfullySentEmails
                              activityLogs={
                                onboarding.iWelcomeEmailsActivityLogs
                              }
                            />
                            <UnsuccessfullySentEmails dossierId={dossierId} />
                          </div>
                        </TableCell>
                      </TableRow>
                      <ReferralLetterRow />
                      <TableRow>
                        <TableCell>Wire username</TableCell>
                        <TableCell>
                          <StatusLabelBoolean
                            boolean={
                              onboarding.patient.userProvidedWireUsername
                            }
                            positive="Aanwezig"
                            negative="Ontbreekt"
                          />
                          <StyledWireTextField
                            label=""
                            margin="dense"
                            name="userProvidedWireUsername"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              errors.userProvidedWireUsername &&
                              touched.userProvidedWireUsername
                            }
                            value={values.userProvidedWireUsername}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Intake voorbereid</TableCell>
                        <TableCell>
                          <StatusLabelBoolean
                            boolean={onboarding.isIntakePrepared}
                            positive="Voorbereid"
                            negative="Niet voorbereid"
                          />
                          {!onboarding.isIntakePrepared && (
                            <StyledButton
                              size="small"
                              color="primary"
                              variant="contained"
                              onClick={() =>
                                onboardingStore
                                  .patchOnboarding(onboarding.id, {
                                    isIntakePrepared: true,
                                  })
                                  .catch((error) => {
                                    enqueueSnackbar(error.response.data, {
                                      variant: "error",
                                    });
                                  })
                              }
                            >
                              <StyledCheckCircleOutlineIcon />
                              Gedaan
                            </StyledButton>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={2}>
                          Dossier notitie
                          <StyledTextareaAutosize
                            minRows={5}
                            onChange={handleChange}
                            name="note"
                            value={values.note}
                            variant="outlined"
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </StyledCardContent>
                <Divider />

                <CardActions>
                  <div className="flex w-full justify-between">
                    <div>
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={isSubmitting || !dirty}
                        onClick={handleSubmit}
                      >
                        Opslaan
                      </Button>
                      <Button
                        type="button"
                        className="outline"
                        onClick={handleReset}
                        disabled={isSubmitting || !dirty}
                      >
                        Reset
                      </Button>
                    </div>

                    <SwitchToTreatmentButton
                      dossier={currentDossier}
                      isWelcomeFlowFinished={
                        onboarding.iWelcomeProgress.status == "Finished"
                      }
                    />
                  </div>
                </CardActions>

                <Divider />

                <CardActions>
                  <Button
                    disabled={
                      onboarding.onboardingStatus ===
                      onboardingStatusEnum.COMPLETED
                    }
                    onClick={() => setOnboardingCompletedManually()}
                  >
                    <StyledDoneAllIcon />
                    Handmatig onboarding afronden
                  </Button>
                </CardActions>
              </form>
            );
          }}
        </Formik>
      </Card>
    </Loader>
  );
};

export default observer(Onboarding);
