import axios from "axios";
import { useQuery } from "react-query";

export const useGetTranslations = () =>
  useQuery(
    ["translations"],
    async () => {
      const res = await axios.get('translations', {
        headers: {
          'Skip-Authorization': true
        }
      });
      return res.data;
    },
    {
      retry: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );