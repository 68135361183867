import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Modal from "@mui/material/Modal";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import { observer } from "mobx-react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import moment from "moment";
import scheduleType from "../../../utils/constants/scheduleType";
import {
  CronNotation,
  displayDays,
  getDays,
  getHour,
  getMinute,
} from "../../../utils/cronParser";
import useStores from "../../../useStores";

const StyledCardRoot = styled(Card)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  outline: "none",
  boxShadow: theme.shadows[20],
  width: 700,
  maxHeight: "100%",
  overflowY: "auto",
  maxWidth: "100%",
}));

const StyledCardActions = styled(CardActions)(({ theme }) => ({
  justifyContent: "flex-end",
}));

function ScheduleEditModal({ open, onClose, schedule }) {
  const { patientStore } = useStores();
  const [selectedLocalTime, setSelectedLocalTime] = useState(moment());
  const [selectedEndDate, setSelectedEndDate] = useState(moment());

  useEffect(() => {
    if (schedule) {
      setSelectedLocalTime(
        moment()
          .hours(getHour(schedule.scheduleCronNotation))
          .minutes(getMinute(schedule.scheduleCronNotation))
          .subtract(new Date().getTimezoneOffset(), "minutes")
      );

      setSelectedEndDate(moment(schedule.endDateTime));
    }
  }, [schedule]);

  const handleTimeChange = (dateTime) => {
    setSelectedLocalTime(dateTime);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
  };

  const handleSubmit = () => {
    const cronNotation = new CronNotation()
      .onDays(getDays(schedule.scheduleCronNotation))
      .atHour(moment.utc(selectedLocalTime).hours())
      .atMinute(moment.utc(selectedLocalTime).minutes());

    const endDate =
      schedule.scheduleDefinition.scheduleType === scheduleType.SEQUEL
        ? null
        : selectedEndDate;

    patientStore.editSchedule(schedule.id, cronNotation, endDate);
    onClose();
  };

  if (!open) {
    return null;
  }

  return (
    <Modal onClose={onClose} open={open}>
      <form autoComplete="off" noValidate>
        <StyledCardRoot>
          <CardHeader title="Bot aanmaken" />
          <Divider />
          <CardContent>
            <Grid>
              <Grid item>
                <TextField
                  fullWidth
                  disabled
                  required
                  select
                  label="Bot"
                  margin="dense"
                  name="scheduleDefinition"
                  value={schedule.scheduleDefinition.name}
                  variant="outlined"
                >
                  <MenuItem value={schedule.scheduleDefinition.name}>
                    {schedule.scheduleDefinition.name}
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">
                  {schedule.scheduleDefinition.scheduleType ===
                    scheduleType.LOOP &&
                    "Deze bot stuurt dagelijks een bericht."}
                  {schedule.scheduleDefinition.scheduleType ===
                    scheduleType.SEQUEL &&
                    `Deze bot heeft ${
                      schedule.scheduleDefinition.messageDefinitions.length
                    } afleveringen en wordt verzonden op ${displayDays(
                      schedule.scheduleCronNotation
                    )}`}
                </Typography>
              </Grid>
              <Grid item>
                {schedule.scheduleDefinition.scheduleType && (
                  <TimePicker
                    margin="normal"
                    ampm={false}
                    label="Om"
                    variant="inline"
                    value={selectedLocalTime}
                    onChange={handleTimeChange}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                  />
                )}
              </Grid>
              <Grid item>
                {schedule.scheduleDefinition.scheduleType ===
                  scheduleType.LOOP && (
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    format="DD/MM/YYYY"
                    margin="normal"
                    label="Einddatum"
                    id="date-picker-inline"
                    value={selectedEndDate}
                    onChange={handleEndDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <StyledCardActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              Bot aanpassen
            </Button>
          </StyledCardActions>
        </StyledCardRoot>
      </form>
    </Modal>
  );
}

export default observer(ScheduleEditModal);
