import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '../../../../components/ui/dialog';
import { Button } from '../../../../components/ui/button';
import { OnboardPatientMainListEntry } from '../types';
import { toast } from 'sonner';
import { useDeleteOnboardingPatient } from '../hooks';

type RemoveClientDialogProps = {
  client: OnboardPatientMainListEntry | null;
  setClient: (client: OnboardPatientMainListEntry | null) => void;
};

export const RemoveClientDialog = ({ client, setClient }: RemoveClientDialogProps) => {
  const { mutate, isLoading } = useDeleteOnboardingPatient(() => {
    toast('Client removed', {
      description: 'You have successfully removed the client from your onboarding list.',
    });
    setClient(null);
  });

  return (
    <Dialog open={client != null} onOpenChange={() => setClient(null)}>
      <DialogContent className="p-8 w-[420px]">
        <DialogHeader>
          <DialogTitle className="text-xl">
            Remove {client?.epdPatientId}.{client?.epdFileId}?
          </DialogTitle>
          <DialogDescription>
            Are you sure you would like to remove the client from your onboarding list?
          </DialogDescription>
        </DialogHeader>

        <div className="flex items-center justify-between w-full space-x-2 pt-2">
          <Button
            variant="outline"
            className="w-1/2"
            onClick={() => {
              setClient(null);
            }}
          >
            No, keep client
          </Button>
          <Button
            variant="destructive"
            className="w-1/2"
            onClick={async () => {
              await mutate({ id: client!.id });
            }}
            disabled={isLoading}
          >
            {isLoading ? 'Removing...' : 'Yes, remove client'}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
