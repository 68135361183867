import { AppointmentTypeSelectors } from "./appointmentTypePerCaseLoadArea/appointmentTypeSelectors";
import { useTranslation } from "react-i18next";
import { CaseLoadKpis } from "./kpis/index";
import { AutomaticBillingConfiguration } from "./appointmentTypePerDossierType/automaticBillingConfiguration";
import { GenerateCaseLoadData } from "./dataGeneration/generateCaseLoadData";
import {
  HorizontalTab,
  HorizontalTabHeader,
  HorizontalTabContent,
} from "../../../components/ui/horizontalTab";
import { AutoBillingKpis } from "./autobilling-kpis";

const CaseLoadManagerAdmin = () => {
  const { t } = useTranslation();

  return (
    <main className="w-full mt-16 mb-20 pr-12">
      <HorizontalTab>
        <div className="w-full">
          <div className="flex items-center ml-3">
            <HorizontalTabHeader index={0}>
              {t("case-load-manager-admin-panel-tab-view-caseload-kpis")}
            </HorizontalTabHeader>
            <HorizontalTabHeader index={1}>
              {t("case-load-manager-admin-panel-tab-view-auto-billing-kpis")}
            </HorizontalTabHeader>
            <HorizontalTabHeader index={2}>
              {t("case-load-manager-admin-panel-tab-manage-configs")}
            </HorizontalTabHeader>
          </div>
          <HorizontalTabContent index={0}>
            <CaseLoadKpis />
          </HorizontalTabContent>
          <HorizontalTabContent index={1}>
            <AutoBillingKpis />
          </HorizontalTabContent>
          <HorizontalTabContent index={2}>
            <div className="p-4">
              <AppointmentTypeSelectors />
              <AutomaticBillingConfiguration />
              <GenerateCaseLoadData />
            </div>
          </HorizontalTabContent>
        </div>
      </HorizontalTab>
    </main>
  );
};

export default CaseLoadManagerAdmin;
