import { SlidersHorizontal } from "lucide-react";
import { Button } from "../../components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover";
import { SelectableValue } from "../../components/ui/multiselect";
import { LocationsFilter } from "../calendar/admin/locationsFilter";
import { Switch } from "../../components/ui/switch";
import { PsychologistFilter } from "../calendar/admin/psychologistFilter";

export const FiltersButton = ({
  showEachPsychologist,
  setShowEachPsychologist,
  locationsFilter,
  setLocationsFilter,
  psychologistFilter,
  setPsychologistFilter,
}: {
  showEachPsychologist: boolean;
  setShowEachPsychologist: (newValue: boolean) => void;
  locationsFilter: SelectableValue[];
  setLocationsFilter: React.Dispatch<React.SetStateAction<SelectableValue[]>>;
  psychologistFilter: string;
  setPsychologistFilter: (newValue: string) => void;
}) => {
  const getFilterCount = () => {
    return locationsFilter.length + (psychologistFilter ? 1 : 0);
  };
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" className="flex items-center space-x-2">
          <SlidersHorizontal className="w-4 h-4" />
          <p>Filters</p>
          {getFilterCount() > 0 && (
            <div className="text-xs w-4 h-4 rounded-full bg-ipractice-blue text-center text-white">
              {getFilterCount()}
            </div>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent align="end" side="bottom" className="min-w-[500px]">
        <h2 className="font-bold text-xl">Set filters</h2>
        <div className="flex flex-col space-y-4 mt-6">
          <div className="flex items-center space-x-2">
            <Switch
              checked={showEachPsychologist}
              onCheckedChange={(x) => setShowEachPsychologist(x)}
              id="show-each-psy"
            />
            <label
              htmlFor="show-each-psy"
              className={showEachPsychologist ? "font-bold" : "font-normal"}
            >
              Show each psychologists
            </label>
          </div>

          <LocationsFilter
            selected={locationsFilter}
            onSelectionChange={setLocationsFilter}
          />

          {showEachPsychologist && (
            <PsychologistFilter
              inline
              allowClear
              selectedPsy={psychologistFilter}
              setSelectedPsy={(newValue) => {
                setPsychologistFilter(newValue === "full-team" ? "" : newValue);
              }}
            />
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};
