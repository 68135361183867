export const Header = () => {
  return (
    <>
      <h2 className="text-2xl font-bold">OP:SKP Ratio</h2>
      <p>
        Find here each online psyhologist's ratio to their patients'
        consultation room sessions.
      </p>
    </>
  );
};
