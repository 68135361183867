import { ColumnDef } from "@tanstack/react-table";
import { MainListPatient } from "../types";
import { RowSelectorCol } from "./tableColumns/rowSelectorCol";
import { PatientCol } from "./tableColumns/patientCol";
import { PatientContactInfoCol } from "./tableColumns/patientContactInfoCol";
import { RemarksCol } from "./tableColumns/remarksCol";
import { YourCommentsCol } from "./tableColumns/yourCommentsCol";
import { CheckinNeededCol } from "./tableColumns/checkinNeededCol";
import { HasChattedWithCol } from "./tableColumns/hasChattedWithCol";
import { IntakeOnCol } from "./tableColumns/intakeOnCol";
import { LastRegularConsultOnCol } from "./tableColumns/lastRegularConsultOnCol";
import { NextRegularConsultOnCol } from "./tableColumns/nextRegularConsultOnCol";
import { LastChatConsultOnCol } from "./tableColumns/lastChatConsultOnCol";
import { NumberOfRegularConsultsCol } from "./tableColumns/numberOfRegularConsultsCol";
import { RegularConsultPsychologistsCol } from "./tableColumns/regularConsultPsychologistsCol";
import { IsPatientInsuredCol } from "./tableColumns/isPatientInsuredCol";
import { AlertsCol } from "./tableColumns/alertsCol";
import { IsPatientContractedCol } from "./tableColumns/isPatientContractedCol";
import { TreatmentCol } from "./tableColumns/treatmentCol";
import { OffboardingCol } from "./tableColumns/offboardingCol";

export const getColumns = (isInPast: boolean): ColumnDef<MainListPatient>[] => {
  const result = [
    RowSelectorCol,
    PatientCol,
    PatientContactInfoCol,
    AlertsCol,
    RemarksCol,
    IntakeOnCol,
    LastRegularConsultOnCol,
    LastChatConsultOnCol,
    NextRegularConsultOnCol,
    NumberOfRegularConsultsCol,
    RegularConsultPsychologistsCol,
    IsPatientInsuredCol,
    IsPatientContractedCol,
    TreatmentCol,
    YourCommentsCol,
    CheckinNeededCol,
    HasChattedWithCol,
  ];

  if (!isInPast) {
    result.push(OffboardingCol);
  }

  return result;
};
