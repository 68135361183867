import React from "react";
import { observer } from "mobx-react";
import { styled } from "@mui/system";
import {
  Card,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableContainer,
} from "@mui/material";
import useStores from "../../useStores";
import OnboardingToolbar from "./OnboardingToolbar";
import OnboardingRow from "./OnboardingRow";
import StyledRoot from "../../shared/StyledRootDiv";
import { RefreshCcw } from "lucide-react";

const StyledNotesTableCell = styled(TableCell)(() => ({
  width: 400,
}));

const StyledTableContainer = styled(TableContainer)(() => ({
  maxHeight: "calc(100vh - 260px)",
}));

const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

const OnboardingOverview = () => {
  const { onboardingsStore } = useStores();

  return (
    <StyledRoot>
      <OnboardingToolbar />

      <StyledCard>
        <StyledTableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Naam</TableCell>
                <TableCell>Intake</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>iWelcome</TableCell>
                <TableCell>Wire</TableCell>
                <TableCell>Verwijsbrief</TableCell>
                <TableCell>Voorbereid</TableCell>
                <TableCell>Reminders</TableCell>
                <StyledNotesTableCell>Notities</StyledNotesTableCell>
                <TableCell>Acties</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {onboardingsStore.dossiers.map((dossier) => (
                <OnboardingRow dossier={dossier} key={dossier.id} />
              ))}
            </TableBody>
          </Table>
          {onboardingsStore.dossiers.length > 0 && (
            <div
              className="text-ipractice-blue cursor-pointer hover:underline p-6 flex items-center space-x-1 w-full justify-center"
              onClick={async () => await onboardingsStore.fetchMoreData()}
            >
              <RefreshCcw className="h-4 w-4" />
              <p>Meer gegevens laden</p>
            </div>
          )}
        </StyledTableContainer>
      </StyledCard>
    </StyledRoot>
  );
};

export default observer(OnboardingOverview);
