import Button from "@mui/material/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import axios from "axios";
import { useState } from "react";
import { useMutation } from "react-query";
import { Loader2 } from "lucide-react";
import useStores from "../../../../useStores";
import dossierType from "../../../../utils/constants/dossierType";
import { toast } from "sonner";

export const SwitchToTreatmentButton = ({
  dossier,
  isWelcomeFlowFinished,
}: {
  dossier: {
    id: number;
    patientId: string;
    subType: string;
    healthCareInsurerType: string;
  };
  isWelcomeFlowFinished: boolean;
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { patientStore, onboardingStore } = useStores();

  const { mutate: save, isLoading } = useMutation({
    mutationFn: async () => {
      const res = await axios.post(
        `dossiers/${dossier.id}/proceed-to-treatment`,
        {}
      );
      return res.data;
    },
    onSuccess: (res) => {
      patientStore.fetchPatientInformation(dossier.patientId, dossier.id);
      onboardingStore.fetchOnboarding(dossierType.GGZ, dossier.id);

      toast("Behandeling gestart", {
        description: "De behandeling is gestart voor het dossier.",
      });
      setIsDialogOpen(false);
    },
  });

  const isVisible =
    dossier.subType === "SCREENING" &&
    ["VGZ", "Menzis"].includes(dossier.healthCareInsurerType) &&
    isWelcomeFlowFinished;

  if (!isVisible) {
    return <></>;
  }

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        onClick={() => setIsDialogOpen(true)}
      >
        Behandeling starten
      </Button>
      <Dialog
        open={isDialogOpen}
        onOpenChange={(newVal) => {
          setIsDialogOpen(newVal);
        }}
      >
        <DialogContent className="min-w-[20vw]">
          <DialogHeader>
            <DialogTitle>Ga verder met de behandeling</DialogTitle>
            <DialogDescription>
              U staat op het punt het dossier over te dragen voor behandeling.
              Weet u het zeker?
            </DialogDescription>
          </DialogHeader>

          <DialogFooter className="gap-2">
            <Button
              variant="outlined"
              onClick={() => setIsDialogOpen(false)}
              className="w-1/2"
            >
              Annuleren
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={async () => {
                if (isLoading) return;

                await save();
              }}
              className="w-1/2 flex items-center space-x-2"
            >
              {isLoading && <Loader2 className="w-4 h-4 animate-spin" />}
              <p>Verdergaan</p>
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
