import { startTransition, useEffect, useState } from 'react';

const PLACEHOLDER_IMAGES = ['/content-card-placeholder-1.png', '/content-card-placeholder-2.png'];

function getRandomPlaceholderImage() {
  return PLACEHOLDER_IMAGES[Math.floor(Math.random() * PLACEHOLDER_IMAGES.length)];
}

type Props = {
  delay?: number;
  image?: string;
  tags?: string[];
  title?: string;
  url: string;
};

export function ContentCard({ delay = 0, image, tags, title, url }: Props) {
  const [contentDetails, setContentDetails] = useState<{
    description?: string;
    image?: string;
    loading: boolean;
    title?: string;
  }>({
    description: '',
    image: image ?? getRandomPlaceholderImage(),
    loading: true,
    title: title ?? '(Unknown title)',
  });

  useEffect(() => {
    const getBlogDetails = async () => {
      //   try {
      //     const details = await scrapeBlogURL(url);

      //     setContentDetails((contentDetails) => ({
      //       ...contentDetails,
      //       ...details,
      //       loading: false,
      //     }));
      //   } catch (error) {
      //     console.error(error);

      setContentDetails((contentDetails) => ({
        ...contentDetails,
        loading: false,
      }));
      //   }
    };

    startTransition(() => {
      getBlogDetails();
    });
  }, [url]);

  if (contentDetails.loading) {
    return <div>todo: Loading...</div>;
  }

  return (
    <div
      className="h-fit max-h-[200px] w-[305px]"
      onClick={() => window.open(url, '_blank', 'noopener noreferrer')}
    >
      <div className="mb-2 flex">
        {contentDetails.image && (
          <div className="relative mr-2 h-20 w-20 flex-shrink-0">
            <img
              alt="The hero image for this article"
              className="absolute h-full w-full rounded-lg object-cover"
              src={contentDetails.image}
            />
          </div>
        )}

        {contentDetails.loading ? (
          'Loading...'
        ) : (
          <a
            className="text-openup-dark-blue text line-clamp-3 break-words font-semibold leading-5 antialiased"
            href={url}
            rel="noreferrer"
            target="_blank"
          >
            {contentDetails.title}
          </a>
        )}
      </div>

      <p className="text-openup-dark-blue mb-auto line-clamp-5 text-xs leading-4 tracking-[0.5px] antialiased">
        {contentDetails.description ??
          'Continue your mental health journey. This blog post provides more context.'}
      </p>
    </div>
  );
}
