import { Table } from "@tanstack/react-table";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../../../../components/ui/dropdown-menu";
import { Button } from "../../../../components/ui/button";
import { Columns3 } from "lucide-react";
import { useTranslation } from "react-i18next";
import { MainListPatient } from "../types";
import { RowSelectorColName } from "./tableColumns/rowSelectorCol";
import { CheckinNeededColName } from "./tableColumns/checkinNeededCol";
import { OffboardingColName } from "./tableColumns/offboardingCol";
import { HasChattedWithColName } from "./tableColumns/hasChattedWithCol";
import { IntakeOnColName } from "./tableColumns/intakeOnCol";
import { IsPatientInsuredColName } from "./tableColumns/isPatientInsuredCol";
import { LastRegularConsultOnColName } from "./tableColumns/lastRegularConsultOnCol";
import { NumberOfRegularConsultsColName } from "./tableColumns/numberOfRegularConsultsCol";
import { RegularConsultPsychologistsColName } from "./tableColumns/regularConsultPsychologistsCol";
import { PatientColName } from "./tableColumns/patientCol";
import { PatientContactInfoColName } from "./tableColumns/patientContactInfoCol";
import { RemarksColName } from "./tableColumns/remarksCol";
import { YourCommentsColName } from "./tableColumns/yourCommentsCol";
import { IsPatientContractedColName } from "./tableColumns/isPatientContractedCol";
import { NextRegularConsultOnColName } from "./tableColumns/nextRegularConsultOnCol";
import { LastChatConsultOnColName } from "./tableColumns/lastChatConsultOnCol";
import { TreatmentColName } from "./tableColumns/treatmentCol";
import { AlertsColName } from "./tableColumns/alertsCol";

type ColumnVisibilityPickerProps = {
  table: Table<MainListPatient>;
};

export const ColumnVisibilityPicker = ({
  table,
}: ColumnVisibilityPickerProps) => {
  const { t } = useTranslation();

  const getTranslatedColumnName = (id: string) => {
    switch (id) {
      case CheckinNeededColName:
        return t("case-load-manager-column-needs-checkin");
      case OffboardingColName:
        return "Offboarding";
      case HasChattedWithColName:
        return t("case-load-manager-column-status");
      case IntakeOnColName:
        return t("case-load-manager-column-intake-date");
      case IsPatientInsuredColName:
        return t("case-load-manager-column-is-insured");
      case LastRegularConsultOnColName:
        return t("case-load-manager-column-last-regular-consult");
      case NumberOfRegularConsultsColName:
        return t("case-load-manager-column-number-of-regular-consults");
      case RegularConsultPsychologistsColName:
        return t("case-load-manager-column-regular-consult-psychologists");
      case PatientColName:
        return t("case-load-manager-column-patient");
      case PatientContactInfoColName:
        return t("case-load-manager-column-contact-information");
      case RemarksColName:
        return t("case-load-manager-column-remarks");
      case YourCommentsColName:
        return t("case-load-manager-column-your-comments");
      case IsPatientContractedColName:
        return t("case-load-manager-column-is-contracted");
      case NextRegularConsultOnColName:
        return t("case-load-manager-column-next-regular-consult");
      case LastChatConsultOnColName:
        return t("case-load-manager-column-last-chat-consult");
      case TreatmentColName:
        return t("case-load-manager-column-treatment");
      case AlertsColName:
        return t("case-load-manager-column-alerts");

      default:
        return id;
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          className="ml-auto flex items-center gap-2 w-52"
        >
          <Columns3 className="w-5 h-5" />
          <p>
            {t(
              "case-load-manager-customization-column-visibility-picker-button"
            )}
          </p>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="center"
        side="left"
        className="max-h-[16rem] overflow-y-auto"
      >
        {table
          .getAllColumns()
          .filter(
            (column) => column.getCanHide() && column.id !== RowSelectorColName // "row selection" is not a "real" column, its checkbox/context menu, should be always visible
          )
          .map((column) => {
            return (
              <DropdownMenuCheckboxItem
                key={column.id}
                className="capitalize"
                checked={column.getIsVisible()}
                onCheckedChange={(value) => column.toggleVisibility(!!value)}
                onSelect={(e) => e.preventDefault()}
              >
                {getTranslatedColumnName(column.id)}
              </DropdownMenuCheckboxItem>
            );
          })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
