import React, { useState } from "react";
import { observer } from "mobx-react";
import { styled } from "@mui/system";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { useSnackbar } from "notistack";
import getInitials from "../../utils/getInitials";
import StatusLabelOnboarding from "../../shared/StatusLabelOnboarding";
import StatusLabelBoolean from "../../shared/StatusLabelBoolean";
import useStores from "../../useStores";
import ConfirmDialog from "../../shared/ConfirmDialog";
import onboardingStatusEnum from "../../utils/constants/onboardingStatusEnum";
import ReferralLetterInfo from "./ReferralLetterInfo";
import isConsultOnline from "../../utils/consultHelper";
import Loading from "../../shared/Loading";
import dossierType from "../../utils/constants/dossierType";

const StyledTextareaAutosize = styled(TextareaAutosize)(() => ({
  width: "100%",
}));

const StyledNameContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  minWidth: "180px",
}));

const StyledInTakeContainer = styled(TableCell)(() => ({
  minWidth: "120px",
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  marginRight: theme.spacing(2),
  textDecoration: "none",
}));

const OnboardingRow = ({ dossier }) => {
  const { onboardingsStore } = useStores();
  const { enqueueSnackbar } = useSnackbar();

  const [
    confirmOnboardingCompletedIsOpen,
    setConfirmOnboardingCompletedIsOpen,
  ] = useState(false);
  const [confirmIsIntakePreparedIsOpen, setConfirmIsIntakePreparedIsOpen] =
    useState(false);
  const [wireUsernameIsOpen, setWireUsernameIsOpen] = useState(false);

  const [values, setValues] = useState({
    username: "",
    notes: "",
  });

  const setOnboardingCompletedManually = () => {
    setConfirmOnboardingCompletedIsOpen(false);
    onboardingsStore
      .patchOnboarding(dossier.id, { isOnboardingCompletedManually: true })
      .then(
        () => enqueueSnackbar("Onboarding afgerond.", { variant: "success" }),
        (error) => enqueueSnackbar(error.response.data, { variant: "error" })
      );
  };

  const setIntakeIsPrepared = () => {
    setConfirmIsIntakePreparedIsOpen(false);
    onboardingsStore
      .patchOnboarding(dossier.id, { isIntakePrepared: true })
      .then(
        () => enqueueSnackbar("Intake is voorbereid.", { variant: "success" }),
        (error) => enqueueSnackbar(error.response.data, { variant: "error" })
      );
  };

  const saveWireUsername = () => {
    setWireUsernameIsOpen(false);
    onboardingsStore
      .updateOnboardingWireUserName(dossier.id, values.username)
      .then(
        () =>
          enqueueSnackbar("Wire username is opgeslagen.", {
            variant: "success",
          }),
        (error) => enqueueSnackbar(error.response.data, { variant: "error" })
      );
  };

  const saveNotes = () => {
    onboardingsStore.patchOnboarding(dossier.id, { note: values.notes }).then(
      () => enqueueSnackbar("Notitie is opgeslagen.", { variant: "success" }),
      (error) => enqueueSnackbar(error.response.data, { variant: "error" })
    );
  };

  if (dossier === null) {
    return (
      <TableRow>
        <Loading />
      </TableRow>
    );
  }

  return (
    <TableRow hover key={dossier.id}>
      <TableCell>
        <Link
          to={`/patient/${dossier.patient.id}/${dossierType.GGZ}/${dossier.id}`}
          style={{ color: "inherit", textDecoration: "none" }}
        >
          <StyledNameContainer>
            <StyledAvatar>
              {getInitials(
                `${dossier.patient.firstName} ${dossier.patient.lastName}`
              )}
            </StyledAvatar>
            <span style={{ textDecoration: "underline" }}>
              {`${dossier.patient.firstName} ${dossier.patient.lastName}`} (
              {dossier.fullEpdId})
            </span>
          </StyledNameContainer>
        </Link>
      </TableCell>
      <StyledInTakeContainer>
        {moment(dossier.intakeConsult.start).format("dd L")}
        {isConsultOnline(dossier.intakeConsult) ? " (online)" : ""}
      </StyledInTakeContainer>
      <TableCell>
        <Button
          disabled={dossier.onboardingStatus === onboardingStatusEnum.COMPLETED}
          onClick={() => setConfirmOnboardingCompletedIsOpen(true)}
        >
          <StatusLabelOnboarding status={dossier.onboardingStatus} />
        </Button>

        <ConfirmDialog
          question={`Weet je zeker dat je de onboarding voor client ${dossier.patient.firstName} ${dossier.patient.lastName} wil afronden?`}
          isOpen={confirmOnboardingCompletedIsOpen}
          onConfirm={() => setOnboardingCompletedManually()}
          onCancel={() => setConfirmOnboardingCompletedIsOpen(false)}
        />
      </TableCell>
      <TableCell>
        <StatusLabelBoolean
          boolean={dossier.iWelcomeProgress.status === "Finished"}
          positive={dossier.iWelcomeProgress.status}
          negative={dossier.iWelcomeProgress.status}
        />
      </TableCell>
      <TableCell>
        <Button
          disabled={!!dossier.patient.userProvidedWireUsername}
          onClick={() => setWireUsernameIsOpen(true)}
        >
          <StatusLabelBoolean
            boolean={dossier.patient.userProvidedWireUsername}
            positive={dossier.patient.userProvidedWireUsername}
            negative="missing"
          />
        </Button>

        <Dialog
          open={wireUsernameIsOpen}
          onClose={() => setWireUsernameIsOpen(false)}
          aria-labelledby="alert-dialog-title"
        >
          <DialogTitle id="alert-dialog-title">{`Wire naam voor client ${dossier.patient.firstName} ${dossier.patient.lastName}`}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="wire-username"
              label="Username"
              onChange={(event) =>
                setValues({ ...values, username: event.target.value })
              }
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setWireUsernameIsOpen(false)}
              color="primary"
            >
              Terug
            </Button>
            <Button onClick={() => saveWireUsername()} color="primary">
              Wire naam opslaan
            </Button>
          </DialogActions>
        </Dialog>
      </TableCell>
      <TableCell>
        <ReferralLetterInfo dossier={dossier} />
      </TableCell>
      <TableCell>
        <Button
          disabled={dossier.isIntakePrepared}
          onClick={() => setConfirmIsIntakePreparedIsOpen(true)}
        >
          <StatusLabelBoolean
            boolean={dossier.isIntakePrepared}
            positive="Voorbereid"
            negative="Niet voorbereid"
          />
        </Button>

        <ConfirmDialog
          question={`Weet je zeker dat de intake voor client ${dossier.patient.firstName} ${dossier.patient.lastName} is voorbereid?`}
          isOpen={confirmIsIntakePreparedIsOpen}
          onConfirm={() => setIntakeIsPrepared()}
          onCancel={() => setConfirmIsIntakePreparedIsOpen(false)}
        />
      </TableCell>
      <TableCell>{dossier.sentRemindersCount}</TableCell>
      <TableCell>
        <StyledTextareaAutosize
          minRows={3}
          onChange={(event) =>
            setValues({ ...values, notes: event.target.value })
          }
          defaultValue={dossier.note ? dossier.note : ""}
        />
      </TableCell>
      <TableCell>
        <Button
          color="primary"
          size="small"
          variant="outlined"
          onClick={() => saveNotes()}
        >
          Opslaan
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default observer(OnboardingRow);
