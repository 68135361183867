import React from "react";
import { observer } from "mobx-react";
import { styled } from "@mui/system";
import { TableCell, TableRow } from "@mui/material";
import moment from "moment";
import CopyToClipboard from "./CopyToClipboard";

const BottomBorderlessTableRow = styled(TableRow)(({ withBorder }) => ({
  "& > *": {
    borderBottom: withBorder ? "1" : "unset",
  },
}));

const QuestionnaireRow = ({ row }) => {
  return (
    <>
      <BottomBorderlessTableRow withBorder={row.withBorder}>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="right">{row.statusComponent}</TableCell>
        <TableCell align="right">
          {row.inviteText && <CopyToClipboard value={row.inviteText} />}
        </TableCell>
        <TableCell align="right">
          {moment(row.createdAt).format("DD-MM-YYYY HH:mm")}
        </TableCell>
        <TableCell align="right">
          {row.finishedAt
            ? moment(row.finishedAt).format("DD-MM-YYYY HH:mm")
            : "-"}
        </TableCell>
        <TableCell align="right">{row.communicationText}</TableCell>
      </BottomBorderlessTableRow>
    </>
  );
};

export default observer(QuestionnaireRow);
