import axios from "axios";
import { QueryClient } from "react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: async ({ queryKey: [url] }) => {
        if (typeof url === "string") {
          const { data } = await axios.get(`${url.toLowerCase()}`);
          return data;
        }
        throw new Error("Invalid QueryKey");
      },
      retry: (failureCount, error) => {
        const responseStatus = (error as any)?.status;

        // if we get back unauthenticated or not found -> do not retry
        if ([401, 404].includes(responseStatus)) {
          return false;
        }

        // otherwise, retry up to 3 times
        return failureCount < 3;
      },
    },
  },
});
