import { Popover, PopoverTrigger, PopoverContent } from '../../../../components/ui/popover';
import { Button } from '../../../../components/ui/button';
import { History } from 'lucide-react';
import { ChatHistoryList } from './ChatHistoryList';
import { useState } from 'react';

type Props = {
  setThreadId: (threadId: string) => void;
};

export const ChatHistoryButton = ({ setThreadId }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const onSetThreadId = (threadId: string) => {
    setThreadId(threadId);
    setIsOpen(false);
  };

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <Button variant="outline" className="flex items-center space-x-2 px-4">
          <div className="w-4 h-4">
            <History className="w-4 h-4" />
          </div>
          <p>Chat History</p>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="z-[9999999] p-6 min-w-[30vw] h-[50vh]" side="bottom" align="end">
        <ChatHistoryList
          setThreadId={onSetThreadId}
          threads={[
            {
              id: '1',
              createdAt: new Date(),
              messages: [
                {
                  id: '1',
                  createdAt: new Date(),
                  content: [
                    {
                      text: {
                        value:
                          'Hello this is a text message what will happen now, here we go its pretty long?',
                      },
                    },
                  ],
                },
              ],
            },
            {
              id: '2',
              createdAt: new Date('2024-12-05'),
              messages: [
                {
                  id: '1',
                  createdAt: new Date('2024-12-05'),
                  content: [
                    {
                      text: {
                        value: 'Another test',
                      },
                    },
                  ],
                },
              ],
            },
            {
              id: '3',
              createdAt: new Date('2024-12-04'),
              messages: [
                {
                  id: '1',
                  createdAt: new Date('2024-12-04'),
                  content: [
                    {
                      text: {
                        value: 'Another test 2',
                      },
                    },
                  ],
                },
              ],
            },
            {
              id: '4',
              createdAt: new Date('2024-11-30'),
              messages: [
                {
                  id: '1',
                  createdAt: new Date('2024-11-30'),
                  content: [
                    {
                      text: {
                        value: 'Another test 4',
                      },
                    },
                  ],
                },
              ],
            },
            {
              id: '4',
              createdAt: new Date('2024-11-30'),
              messages: [
                {
                  id: '1',
                  createdAt: new Date('2024-11-30'),
                  content: [
                    {
                      text: {
                        value: 'Another test 4',
                      },
                    },
                  ],
                },
              ],
            },
            {
              id: '4',
              createdAt: new Date('2024-11-30'),
              messages: [
                {
                  id: '1',
                  createdAt: new Date('2024-11-30'),
                  content: [
                    {
                      text: {
                        value: 'Another test 4',
                      },
                    },
                  ],
                },
              ],
            },
            {
              id: '4',
              createdAt: new Date('2024-11-30'),
              messages: [
                {
                  id: '1',
                  createdAt: new Date('2024-11-30'),
                  content: [
                    {
                      text: {
                        value: 'Another test 4',
                      },
                    },
                  ],
                },
              ],
            },
            {
              id: '4',
              createdAt: new Date('2024-11-30'),
              messages: [
                {
                  id: '1',
                  createdAt: new Date('2024-11-30'),
                  content: [
                    {
                      text: {
                        value: 'Another test 4',
                      },
                    },
                  ],
                },
              ],
            },
            {
              id: '4',
              createdAt: new Date('2024-11-30'),
              messages: [
                {
                  id: '1',
                  createdAt: new Date('2024-11-30'),
                  content: [
                    {
                      text: {
                        value: 'Another test 4',
                      },
                    },
                  ],
                },
              ],
            },
            {
              id: '4',
              createdAt: new Date('2024-11-30'),
              messages: [
                {
                  id: '1',
                  createdAt: new Date('2024-11-30'),
                  content: [
                    {
                      text: {
                        value: 'Another test 4',
                      },
                    },
                  ],
                },
              ],
            },
            {
              id: '4',
              createdAt: new Date('2024-11-30'),
              messages: [
                {
                  id: '1',
                  createdAt: new Date('2024-11-30'),
                  content: [
                    {
                      text: {
                        value: 'Another test 4',
                      },
                    },
                  ],
                },
              ],
            },
            {
              id: '4',
              createdAt: new Date('2024-11-30'),
              messages: [
                {
                  id: '1',
                  createdAt: new Date('2024-11-30'),
                  content: [
                    {
                      text: {
                        value: 'Another test 4',
                      },
                    },
                  ],
                },
              ],
            },
            {
              id: '4',
              createdAt: new Date('2024-11-30'),
              messages: [
                {
                  id: '1',
                  createdAt: new Date('2024-11-30'),
                  content: [
                    {
                      text: {
                        value: 'Another test 4',
                      },
                    },
                  ],
                },
              ],
            },
          ]}
          assistant={{
            id: '1',
            platforms: [{ slug: 'test' }, { slug: 'test2' }],
          }}
        />
      </PopoverContent>
    </Popover>
  );
};
