import { useLocation } from "react-router-dom";
import { useIsEnabled } from "../../feature-management/useIsEnabled";
import { ReferralOverview } from "./referralOverview/overview";
import useStores from "../../useStores";
import roleNames from "../../utils/constants/roleNames";
import { useGetAuthorizations } from "../../shared/hooks/useGetAuthorizations";

const CustomerRelationshipManager = () => {
  const { data: authorization } = useGetAuthorizations();

  const { data: isCrmEnabledViaFeatureFlag } = useIsEnabled(
    "EnableCustomerRelationshipManagerApi"
  );

  const isCrmEnabled =
    authorization?.roles.includes(roleNames.ADMINISTRATOR) ||
    authorization?.position == "Matching Psychologist" ||
    isCrmEnabledViaFeatureFlag;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const referralId = queryParams.get("id");

  if (!isCrmEnabled) {
    return <></>;
  }

  return (
    <main className="px-6 py-12">
      <ReferralOverview defaultSelectedReferralId={referralId} />
    </main>
  );
};

export default CustomerRelationshipManager;
