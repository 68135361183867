import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../../components/ui/dialog";
import { Loader2 } from "lucide-react";
import { Button } from "../../../../../components/ui/button";

export const ConfirmSendToPraktijkDataDialog = ({
  isOpen,
  isLoading,
  onRetrigger,
  onCancel,
}: {
  isOpen: boolean;
  isLoading: boolean;
  onRetrigger: () => void;
  onCancel: () => void;
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onCancel}>
      <DialogContent className="min-w-[20vw]">
        <DialogHeader>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogDescription>
            <p>Are you sure you want to trigger the sending?</p>
          </DialogDescription>
        </DialogHeader>

        <DialogFooter className="gap-2">
          <Button variant="outline" onClick={onCancel} className="w-1/2">
            No, cancel
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              onRetrigger();
            }}
            className="w-1/2 flex items-center space-x-2"
          >
            {isLoading ? (
              <div className="w-4 h-4">
                <Loader2 className="w-4 h-4 animate-spin" />
              </div>
            ) : (
              <></>
            )}
            <p>Yes, send the questionnaire</p>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
