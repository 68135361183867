import React from "react";
import Grid from "@mui/material/Grid";
import PatientsSearch from "./PatientsSearch";
import PatientsTable from "./PatientsTable";
import StyledRoot from "../../shared/StyledRootDiv";

const PatientsPage = () => {
  return (
    <StyledRoot>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <PatientsSearch />
          <PatientsTable />
        </Grid>
      </Grid>
    </StyledRoot>
  );
};

export default PatientsPage;
