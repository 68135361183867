import { SlidersHorizontal } from "lucide-react";
import { Button } from "../../../../components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../components/ui/popover";
import { DeviationsOnlyFilter } from "./deviationsOnlyFilter";
import { ActiveAcceptingClientsStatusOnly } from "./activeAcceptingClientsStatusOnly";
import { LocationWithTeamTag } from "../../../../shared/hooks/useGetLocationWithTeamTags";

type FiltersButtonProps = {
  showOnlyDeviations: boolean;
  setShowOnlyDeviations: (newValue: boolean) => void;
  showOnlyActiveAcceptingClients: boolean;
  setShowOnlyActiveAcceptingClients: (newValue: boolean) => void;
};

export const FiltersButton = ({
  showOnlyDeviations,
  setShowOnlyDeviations,
  showOnlyActiveAcceptingClients,
  setShowOnlyActiveAcceptingClients,
}: FiltersButtonProps) => {
  const getNumberOfFiltersInUse = () => {
    return (
      (showOnlyDeviations ? 1 : 0) + (showOnlyActiveAcceptingClients ? 1 : 0)
    );
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" className="flex items-center space-x-2">
          <SlidersHorizontal className="w-4 h-4" />
          <p>Filters</p>
          {getNumberOfFiltersInUse() > 0 && (
            <div className="text-xs w-4 h-4 rounded-full bg-ipractice-blue text-center text-white">
              {getNumberOfFiltersInUse()}
            </div>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent align="end" side="bottom" className="min-w-[400px]">
        <h2 className="font-bold text-xl">Set filters</h2>
        <div className="flex flex-col space-y-4 mt-6">
          <DeviationsOnlyFilter
            isChecked={showOnlyDeviations}
            onCheckedChange={setShowOnlyDeviations}
          />
          <ActiveAcceptingClientsStatusOnly
            isChecked={showOnlyActiveAcceptingClients}
            onCheckedChange={setShowOnlyActiveAcceptingClients}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
};
