import dossierType from "./constants/dossierType";

export default (dossier) => {
    switch (dossier.$type) {
        case dossierType.GGZ:
        case dossierType.ADHD:
        case dossierType.TRANSFER:
            return `${dossier.epdPatientId}.${dossier.epdFileId}`;
        case dossierType.OPEN_UP:
            return `OU-${dossier.id}`;
        default:
            return "unknown";
    }
};
