import { CircleAlert, Loader2 } from "lucide-react";
import { PatientDetails } from "../../../types";
import { getReadableAlertText } from "../../../utils";
import { useResolveAlerts } from "../../../hooks";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../../components/ui/button";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../../../../../../components/ui/hover-card";

type MultipleAlertsProps = {
  data: PatientDetails;
};

export const MultipleAlerts = ({ data }: MultipleAlertsProps) => {
  const { t } = useTranslation();

  const { mutate: resolveAlerts, isLoading: isResolvingAlerts } =
    useResolveAlerts(() => {
      toast(t("case-load-manager-resolved-alert-success-toast-title"), {
        description: t("case-load-manager-resolved-alert-success-toast-desc"),
      });
    });

  return (
    <div className="mt-2 p-2 border border-dashed rounded-lg border-red-400 text-red-600 bg-red-50">
      <div className="flex items-center space-x-2 w-full">
        <div className="w-4 h-4">
          <CircleAlert className="w-4 h-4" />
        </div>
        <div className="flex items-center w-full justify-between space-x-2">
          <p>
            The client has multiple alerts:{" "}
            <HoverCard openDelay={200}>
              <HoverCardTrigger asChild>
                <span className="cursor-pointer font-bold hover:underline">
                  view alerts.
                </span>
              </HoverCardTrigger>
              <HoverCardContent
                className="w-[24rem] bg-slate-900 text-red-100"
                style={{ zIndex: 99999 }}
                side="bottom"
                align="center"
              >
                <ul className="list-inside list-disc p-2 space-y-4">
                  {data.alerts.map((a) => {
                    return (
                      <li key={a.code}>{getReadableAlertText(t, a.code)}</li>
                    );
                  })}
                </ul>
              </HoverCardContent>
            </HoverCard>
          </p>
          <Button
            variant="outline"
            className="flex items-center space-x- 2 px-10"
            disabled={isResolvingAlerts}
            onClick={async () =>
              await resolveAlerts({ caseLoadEntryId: data.id })
            }
          >
            {isResolvingAlerts && (
              <div className="w-4 h-4">
                <Loader2 className="w-4 h-4 animate-spin" />
              </div>
            )}
            <p>Resolve all alerts</p>
          </Button>
        </div>
      </div>
    </div>
  );
};
