import { observer } from 'mobx-react';
import { Button } from '@mui/material';
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import StatusLabelBoolean from '../../shared/StatusLabelBoolean';
import useStores from '../../useStores';
import ConfirmDialog from '../../shared/ConfirmDialog';
import UploadReferralLetter from '../../shared/UploadReferralLetter';

const ReferralLetterInfo = ({ dossier }) => {
  const [referralLetterValidationIsOpen, setReferralLetterValidationIsOpen] = useState(false);
  const { onboardingsStore, patientStore } = useStores();
  const { enqueueSnackbar } = useSnackbar();

  const downloadReferralLetterAndOpenValidationDialog = () => {
    setReferralLetterValidationIsOpen(true);
    onboardingsStore.downloadReferralLetter(dossier.id);
  };

  if (!onboardingsStore.onboarding) {
    return <></>;
  }

  const renderReferralLetterStatus = () => {
    if (!dossier.isReferralLetterRequired) {
      return <StatusLabelBoolean boolean positive="n.v.t. (onverzekerd)" />;
    }

    if (dossier.requiresReferralLetter) {
      return (
        <UploadReferralLetter
          dossierId={dossier.id}
          dossierType={onboardingsStore.onboarding.dossierType}
          onboardingStore={onboardingsStore}
          patientStore={patientStore}
        />
      );
    }

    return (
      <Button
        disabled={dossier.isReferralLetterValidated}
        onClick={() => downloadReferralLetterAndOpenValidationDialog(dossier.id)}
      >
        <StatusLabelBoolean
          boolean={dossier.isReferralLetterValidated}
          positive="Aanwezig"
          negative="Aanwezig, niet gelezen"
        />
      </Button>
    );
  };

  const setReferralLetterIsValidated = () => {
    setReferralLetterValidationIsOpen(false);
    onboardingsStore.validateReferralLetter(dossier.id).then(
      () => enqueueSnackbar('Verwijsbrief goedgekeurd.', { variant: 'success' }),
      (error) => enqueueSnackbar(error.response.data, { variant: 'error' })
    );
  };

  return (
    <div>
      {renderReferralLetterStatus()}
      <ConfirmDialog
        question={`Wil je de verwijsbrief goedkeuren voor client: ${dossier.patient.firstName} ${dossier.patient.lastName}?`}
        isOpen={referralLetterValidationIsOpen}
        onConfirm={() => setReferralLetterIsValidated()}
        onCancel={() => setReferralLetterValidationIsOpen(false)}
      />
    </div>
  );
};

export default observer(ReferralLetterInfo);
