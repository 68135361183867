import { TableCell, TableRow } from "../../../../components/ui/table";
import { hasDeviation } from "../utils";
import { Row } from "../types";
import { NameCell } from "./columns/nameCell";
import { LocationCell } from "./columns/locationCell";
import { CurrentDeviationCell } from "./columns/currentDeviationCell";
import { FutureDeviationsCell } from "./columns/futureDeviationsCell";
import { CommentsCell } from "./columns/commentsCell";

export const IntakeLimitRow = ({ data }: { data: Row }) => {
  const getRowBackgroundColor = (data: Row) => {
    if (hasDeviation(data)) {
      return "bg-amber-50 hover:bg-amber-50";
    }

    if (data.status === "Active - not accepting new clients") {
      return "bg-orange-100 hover:bg-orange-100";
    }

    return "";
  };

  return (
    <TableRow key={data.epdEmployeeId} className={getRowBackgroundColor(data)}>
      <NameCell data={data} />
      <LocationCell data={data} />
      <CurrentDeviationCell data={data} />
      <FutureDeviationsCell data={data} />
      <CommentsCell data={data} />
    </TableRow>
  );
};
