import {
  useGetLocationWithTeamTags,
  LocationWithTeamTag,
} from "../../../../shared/hooks/useGetLocationWithTeamTags";
import { LocationAndTeamFilter as Filter } from "../../../../shared/LocationAndTeamFilter";

type LocationAndTeamFilterProps = {
  selectedLocationAndTeam: LocationWithTeamTag | null;
  setSelectedLocationAndTeam: React.Dispatch<
    React.SetStateAction<LocationWithTeamTag | null>
  >;
};

export const LocationAndTeamFilter = ({
  selectedLocationAndTeam,
  setSelectedLocationAndTeam,
}: LocationAndTeamFilterProps) => {
  const { data, isLoading } = useGetLocationWithTeamTags();

  return (
    <div className="flex items-center space-x-2 w-1/3">
      <Filter
        isLoading={isLoading}
        selectableLocationAndTeams={data ?? []}
        selectedLocationAndTeam={selectedLocationAndTeam}
        setSelectedLocationAndTeam={setSelectedLocationAndTeam}
      />
    </div>
  );
};
