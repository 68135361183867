import { OverviewTable } from "./overviewTable";
import { Switch } from "../../components/ui/switch";
import { useState } from "react";
import { BigRegistrationReport } from "./types";
import { TriggerValidation } from "./triggerValidation/triggerValidation";
import { ExportToPdf } from "./exportToPdf/exportToPdf";
import { useGetBigRegistrationReport } from "./hooks";

const BigRegisterAdmin = () => {
  const { data, isLoading } = useGetBigRegistrationReport((data) => {
    setMutableData(data);
  });

  const [showOnlyRowsWithIssues, setShowOnlyRowsWithIssues] = useState(false);
  const [mutableData, setMutableData] = useState<BigRegistrationReport[]>([]);

  return (
    <main className="p-24">
      <div className="flex item-start justify-between w-full">
        <section>
          <h1 className="font-bold text-4xl">
            Validate BIG Registration Statuses
          </h1>
          <p className="text-lg pt-2">
            Browse our GZ psychologists's BIG registration statuses below.
          </p>
          <p>
            The data is refreshed every night, but you may trigger a refresh
            manually, by pressing the 'Re-run BIG validation' button on the
            bottom of the page.
          </p>
        </section>

        <div className="flex items-center justify-end space-x-2 min-w-[250px]">
          <Switch
            id="show-only-non-active-rows"
            checked={showOnlyRowsWithIssues}
            disabled={isLoading}
            onCheckedChange={(x) => {
              if (x) {
                setMutableData(
                  data?.filter((d) => d.status !== "Active") ?? []
                );
              } else {
                setMutableData(data ?? []);
              }

              setShowOnlyRowsWithIssues(x);
            }}
          />
          <label
            htmlFor="show-only-non-active-rows"
            className={`${
              showOnlyRowsWithIssues ? "font-bold" : "font-normal"
            }`}
          >
            Show only non-Active statuses
          </label>
        </div>
      </div>

      <div className="mt-6">
        <OverviewTable isLoading={isLoading} data={mutableData} />
      </div>

      <div className="mt-6 flex items-center space-x-2">
        <ExportToPdf />
        <TriggerValidation />
      </div>
    </main>
  );
};

export default BigRegisterAdmin;
