import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  flexRender,
  getCoreRowModel,
  VisibilityState,
  useReactTable,
  SortingState,
  ColumnSizingState,
  ColumnDef,
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  ColumnResizer,
} from "../../../components/ui/table";
import { MainListPatient } from "./types";
import { TablePagination } from "../../../components/ui/table-pagination";
import { Pagination } from "../../../shared/pagination";
import { TableHeader as CustomHeader } from "./tableHeader/header";
import { Error } from "./tableStates/error";
import { Loading } from "./tableStates/loading";
import { Fetching } from "./tableStates/fetching";
import {
  getBackgroundOfRow,
  getDefaultColumnSizingFromLocalStorage,
  getDefaultColumnVisibilityFromLocalStorage,
} from "./utils";
import { getColumns } from "./columns/getColumnsToDisplay";
import { RowSelectorColName } from "./columns/tableColumns/rowSelectorCol";
import { PatientColName } from "./columns/tableColumns/patientCol";
import { PastDataWarning } from "./pastWeekData/pastDataWarning";
import { useTranslation } from "react-i18next";
import { CaseLoadClientDetails } from "./details/details";
import { Sheet, SheetContent } from "../../../components/ui/sheet";
import { CaseLoadManagerSelectedPatientContext } from "./selectedClientContext";
import { DefaultGenerics, StreamChat } from "stream-chat";

type CaseLoadTableProps = {
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
  data: MainListPatient[];
  date: Date;
  onChangeDate: (newDate: Date) => void;
  sorting: SortingState;
  setSorting: Dispatch<SetStateAction<SortingState>>;
  pageSize: number;
  setPageSize: (newSize: number) => void;
  pagination: Pagination | undefined;
  skip: number;
  setSkip: (newSkip: number) => void;
  searchTerm: string;
  setSearchTerm: (newSearchTerm: string) => void;
  isInPast: boolean;
  onlyCaseLoadsWithAlert: boolean;
  setOnlyCaseLoadsWithAlert: (newVal: boolean) => void;
  chatClient: StreamChat<DefaultGenerics> | null;
  couldNotLoadChat: boolean;
};

export function CaseLoadTable({
  isLoading,
  isFetching,
  isError,
  data,
  date,
  onChangeDate,
  sorting,
  setSorting,
  pageSize,
  setPageSize,
  pagination,
  skip,
  setSkip,
  searchTerm,
  setSearchTerm,
  isInPast,
  onlyCaseLoadsWithAlert,
  setOnlyCaseLoadsWithAlert,
  chatClient,
  couldNotLoadChat,
}: CaseLoadTableProps) {
  const { t } = useTranslation();
  const [addNewPatientOpen, setAddNewPatientOpen] = useState(false);

  const { selectedPatientEntry, setSelectedPatientEntry } = useContext(
    CaseLoadManagerSelectedPatientContext
  )!;

  const [colSizing, setColSizing] = useState<ColumnSizingState>(
    getDefaultColumnSizingFromLocalStorage()
  );
  const [colVisibility, setColVisibility] = useState<VisibilityState>(
    getDefaultColumnVisibilityFromLocalStorage("case-load-column-visibility")
  );
  const [rowSelection, setRowSelection] = useState({});

  const [columns, setColumns] = useState<ColumnDef<MainListPatient>[]>(
    getColumns(isInPast)
  );

  useEffect(() => {
    setColumns(getColumns(isInPast));
  }, [isInPast]);

  useEffect(() => {
    localStorage.setItem(
      "case-load-column-visibility",
      JSON.stringify(colVisibility)
    );
  }, [colVisibility]);

  useEffect(() => {
    localStorage.setItem("case-load-column-sizing", JSON.stringify(colSizing));
  }, [colSizing]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onColumnVisibilityChange: setColVisibility,
    onColumnSizingChange: setColSizing,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    enableColumnResizing: true,
    columnResizeMode: "onChange",
    manualPagination: true,
    manualSorting: true,
    state: {
      columnVisibility: colVisibility,
      columnSizing: colSizing,
      rowSelection,
      sorting,
    },
  });

  const onCloseSheet = () => {
    setSelectedPatientEntry(null);
  };

  return (
    <div>
      <CustomHeader
        table={table}
        pageSize={pageSize}
        setPageSize={setPageSize}
        setSkip={setSkip}
        date={date}
        onChangeDate={onChangeDate}
        addNewPatientOpen={addNewPatientOpen}
        setAddNewPatientOpen={setAddNewPatientOpen}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        onlyCaseLoadsWithAlert={onlyCaseLoadsWithAlert}
        setOnlyCaseLoadsWithAlert={setOnlyCaseLoadsWithAlert}
      />

      {isInPast && (
        <div className="mt-6">
          <PastDataWarning backToPresent={() => onChangeDate(new Date())} />
        </div>
      )}

      <div className="rounded-md border mt-6">
        {isError ? (
          <Error />
        ) : (
          <div className="relative w-full">
            {isFetching && !isLoading && (
              <div className="absolute right-0 top-0 min-h-full mt-4 pr-2 z-50">
                <Fetching />{" "}
              </div>
            )}

            <Sheet
              open={selectedPatientEntry != null}
              onOpenChange={(newValue) => {
                if (!newValue) {
                  onCloseSheet();
                }
              }}
            >
              <Table
                style={{ width: table.getTotalSize() }}
                className="table-auto"
              >
                <TableHeader>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id}>
                      {headerGroup.headers.map((header) => {
                        if (isLoading && header.id === RowSelectorColName) {
                          return (
                            <TableHead
                              key={header.id}
                              className="min-w-[40px] sticky left-0 z-10"
                            />
                          );
                        }

                        return (
                          <TableHead
                            key={header.id}
                            className={`relative overflow-auto break-words ${
                              header.id === PatientColName
                                ? "min-w-[25px] sticky left-[40px] z-10 p-0"
                                : header.id === RowSelectorColName
                                ? "min-w-[40px] sticky left-0 z-10 p-0"
                                : ""
                            }`}
                            style={{
                              width: header.getSize(),
                            }}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                            <ColumnResizer header={header} />
                          </TableHead>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableHeader>
                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell colSpan={columns.length}>
                        <Loading />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {table.getRowModel().rows?.length ? (
                        table.getRowModel().rows.map((row) => {
                          const hasAlerts =
                            row.original.alerts.filter(
                              (a) => !a.hasMarkedAsCompleted
                            ).length > 0;

                          return (
                            <TableRow
                              key={row.id}
                              data-state={row.getIsSelected() && "selected"}
                              className={getBackgroundOfRow(row.original, true)}
                            >
                              {row.getVisibleCells().map((cell) => {
                                return (
                                  <TableCell
                                    key={cell.id}
                                    style={{
                                      width: cell.column.getSize(),
                                      minWidth: cell.column.columnDef.minSize,
                                    }}
                                    className={`p-0 ${
                                      RowSelectorColName === cell.column.id
                                        ? hasAlerts
                                          ? "bg-slate-50 border-l-2 border-red-200 dark:border-red-800 min-w-[30px] sticky left-0 z-10"
                                          : "bg-slate-50 min-w-[40px] sticky left-0 z-10"
                                        : PatientColName === cell.column.id
                                        ? "bg-slate-50 min-w-[25px] sticky left-[40px] z-10"
                                        : ""
                                    }`}
                                  >
                                    {flexRender(
                                      cell.column.columnDef.cell,
                                      cell.getContext()
                                    )}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell
                            colSpan={columns.length}
                            className="h-24 text-center"
                          >
                            {t("case-load-manager-no-results-found")}
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  )}
                </TableBody>
              </Table>

              <SheetContent
                side={"right"}
                hideCloseButton
                onInteractOutside={() => onCloseSheet()}
                className="min-w-[100vw] xl:min-w-[50vw] overflow-y-auto overflow-x-hidden p-8 flex items-start justify-center bg-white"
              >
                {selectedPatientEntry && (
                  <CaseLoadClientDetails
                    chatClient={chatClient}
                    couldNotLoadChat={couldNotLoadChat}
                    clientId={selectedPatientEntry.id}
                    dossierLookupId={selectedPatientEntry.dossierLookupGuid}
                    onClose={() => onCloseSheet()}
                  />
                )}
              </SheetContent>
            </Sheet>
          </div>
        )}
      </div>
      {!isError && (
        <TablePagination
          pagination={pagination}
          pageSize={pageSize}
          skip={skip}
          setSkip={setSkip}
          table={table}
        />
      )}
    </div>
  );
}
