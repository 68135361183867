import { MainListPatient } from "../types";
import { useEffect, useState } from "react";
import { useUpdatePatientRequiresSupport } from "../hooks";
import { isPeriodInPastWeeks } from "../../../../utils/dateUtils";
import { NoCheckinReasonSelector } from "./noCheckinReasonSelector";
import { Checkbox } from "../../../../components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { Textarea } from "../../../../components/ui/textarea";
import { Button } from "../../../../components/ui/button";
import { useTranslation } from "react-i18next";

type NeedsCheckinCheckboxProps = {
  data: MainListPatient;
  onBlur: () => void;
};

export const NeedsCheckinCheckbox = ({
  data,
  onBlur,
}: NeedsCheckinCheckboxProps) => {
  const { t } = useTranslation();

  const initialValue = data.isCheckinNeeded;
  const [value, setValue] = useState(initialValue);
  const [isJustificationDialogOpen, setIsJustificationDialogOpen] =
    useState(false);

  const [selectedJustificationReason, setSelectedJustificationReason] =
    useState<undefined | string>(undefined);

  const [justificationFreeText, setJustificationFreeText] =
    useState<string>("");

  const { mutate: save, isLoading: isSaving } = useUpdatePatientRequiresSupport(
    () => {
      setSelectedJustificationReason(undefined);
      setJustificationFreeText("");
      setIsJustificationDialogOpen(false);
    }
  );

  const isInPast = isPeriodInPastWeeks(data.period);

  const getJustificationForSave = () => {
    if (!value) return undefined;

    if (selectedJustificationReason === "other") {
      return justificationFreeText;
    }

    return selectedJustificationReason;
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    // when changing from "other" justification reason, we need to clear the free text (its only required when type is 'other')
    if (selectedJustificationReason !== "other") {
      setJustificationFreeText("");
    }
  }, [selectedJustificationReason]);

  return (
    <div>
      <div className="flex items-center space-x-2">
        <Checkbox
          id="needsCheckIn"
          checked={value}
          onCheckedChange={async () => {
            if (value) {
              setIsJustificationDialogOpen(true);
            } else {
              await save({
                caseLoadId: data.id,
                checkInNeeded: true,
                justificationIfNo: undefined,
              });
            }
          }}
          onBlur={onBlur}
          disabled={data.hasChattedWith || isSaving || isInPast}
        />
        <label
          htmlFor="needsCheckIn"
          className={`text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 ${
            value && "font-bold"
          }`}
        >
          {isSaving
            ? t("case-load-manager-saving")
            : t("case-load-manager-column-needs-checkin")}
        </label>
      </div>

      <Dialog
        open={isJustificationDialogOpen}
        onOpenChange={(newValue) => {
          setIsJustificationDialogOpen(newValue);
        }}
      >
        <DialogContent className="p-8 min-w-[300px]">
          <DialogHeader>
            <DialogTitle className="text-2xl">
              {t("case-load-manager-patient-no-checkin-dialog-title")}
            </DialogTitle>
            <DialogDescription>
              {t("case-load-manager-patient-no-checkin-dialog-desc", {
                name: data.name,
              })}
            </DialogDescription>
          </DialogHeader>
          <main>
            <NoCheckinReasonSelector
              selectedJustificationReason={selectedJustificationReason}
              setSelectedJustificationReason={setSelectedJustificationReason}
              isSaving={isSaving}
            />

            {selectedJustificationReason === "other" && (
              <div className="mt-6">
                <p>
                  {t(
                    "case-load-manager-patient-no-checkin-enter-justification-label"
                  )}
                </p>

                <Textarea
                  value={justificationFreeText}
                  onChange={(e) => setJustificationFreeText(e.target.value)}
                  onBlur={onBlur}
                  disabled={isSaving}
                  className="pt-2 min-h-[80px]"
                />
              </div>
            )}

            <Button
              className="w-full mt-8"
              disabled={
                isSaving ||
                !selectedJustificationReason ||
                (selectedJustificationReason === "other" &&
                  !justificationFreeText)
              }
              onClick={async () => {
                await save({
                  caseLoadId: data.id,
                  checkInNeeded: false,
                  justificationIfNo: getJustificationForSave(),
                });
              }}
            >
              {isSaving
                ? t("case-load-manager-saving")
                : t("case-load-manager-save")}
            </Button>
          </main>
        </DialogContent>
      </Dialog>
    </div>
  );
};
