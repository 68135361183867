import { Mails } from "lucide-react";
import { Button } from "../../../../components/ui/button";
import { useState } from "react";
import { ConfirmSendiWelcomeAlert } from "./confirmSendiWelcomeAlert";
import { ReferralDetails } from "../../../customerRelationshipManager/types";

export const IWelcomeEmailSender = ({
  text,
  dossierId,
  dossierType,
  dossierHasReferralLetterAlready,
  disabled,
  crmReferral,
}: {
  text: string;
  dossierId: number;
  dossierType: "DossierGgz" | "DossierTransfer";
  dossierHasReferralLetterAlready: boolean;
  disabled?: boolean;
  crmReferral: ReferralDetails | null;
}) => {
  const [
    isSendAutomaticEmailConfirmationDialogOpen,
    setIsSendAutomaticEmailConfirmationDialogOpen,
  ] = useState(false);

  return (
    <>
      <Button
        disabled={disabled}
        className="px-4 py-0 my-0 h-8 flex items-center space-x-2"
        onClick={(e) => {
          e.preventDefault(); // do not close on click immediately
          setIsSendAutomaticEmailConfirmationDialogOpen(true);
        }}
      >
        <div className="w-4 h-4">
          <Mails className="w-4 h-4 mr-2" />
        </div>
        <p>{text}</p>
      </Button>
      <ConfirmSendiWelcomeAlert
        isOpen={isSendAutomaticEmailConfirmationDialogOpen}
        setIsOpen={setIsSendAutomaticEmailConfirmationDialogOpen}
        dossierId={dossierId}
        dossierType={dossierType}
        dossierHasReferralLetterAlready={dossierHasReferralLetterAlready}
        crmReferral={crmReferral}
      />
    </>
  );
};
