import React from "react";
import { Grid } from "@mui/material";
import CreateDossierGgz from "./CreateDossierGgz";
import StyledRoot from "../../shared/StyledRootDiv";
import { useParams } from "react-router-dom";

const CreateDossierGgzPage = () => {
  const { patientId } = useParams();

  return (
    <StyledRoot>
      <Grid container justifyContent="center">
        <Grid item xs={8}>
          <CreateDossierGgz patientId={patientId} />
        </Grid>
      </Grid>
    </StyledRoot>
  );
};

export default CreateDossierGgzPage;

