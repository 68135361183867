import {
  GetWeeklyPatientReferralStatisticsQueryResponse,
  GetMonthlyInflowAggregationReportResponse,
  CrmBacklogByStatusFullReportResult,
  CrmBacklogByNextStepFullReportResult,
  CrmInflowByLocationQueryResponse,
  CrmWorkingViewReportResult,
  CrmNoChangeInDaysReportResult,
} from "../types";
import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../../react-query/provider";

export const useResyncPraktijkdataPatients = (onSuccess: () => void) => {
  return useMutation({
    mutationFn: async () => {
      const res = await axios.post(
        `customerrelationshipmanageradmin/sync-praktijkdata-dossiers`
      );

      return res.data;
    },
    onSuccess: () => {
      onSuccess();
    },
  });
};

export const useGetWeeklyInflowPerLocationReport = () => {
  const query = useQuery<CrmInflowByLocationQueryResponse>(
    ["referral-manager", "inflow-location-report"],

    async () => {
      let url = new URL(
        `${window.location.protocol}/${window.location.host}/api/customerrelationshipmanagerreport/inflow/by-location`
      );
      const res = await axios.get(url.toString());
      return res.data;
    },
    {
      // refetchOnMount: false,
      // refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useGetWeeklyInflowStatisticsReport = (
  date: Date,
  locationIds: string[],
  registrationStatuses: string[],
  healthcareInsurerConcerns: string[],
  healthcareInsurerContractTypeFilter: string[]
) => {
  const query = useQuery<GetWeeklyPatientReferralStatisticsQueryResponse>(
    [
      "referral-manager",
      "inflow-full-report",
      locationIds,
      registrationStatuses,
      healthcareInsurerConcerns,
      healthcareInsurerContractTypeFilter,
      date,
    ],

    async () => {
      let url = new URL(
        `${window.location.protocol}/${window.location.host}/api/customerrelationshipmanagerreport/inflow/full-report`
      );

      url.searchParams.append("date", date.toISOString());

      locationIds.forEach((loc) => {
        url.searchParams.append("locationIds", loc);
      });

      healthcareInsurerConcerns.forEach((insurer) => {
        url.searchParams.append("healthCareInsurerConcerns", insurer);
      });

      registrationStatuses.forEach((status) => {
        url.searchParams.append("registrationStatuses", status);
      });

      registrationStatuses.forEach((contractType) => {
        url.searchParams.append(
          "healthcareInsurerContractTypeFilters",
          contractType
        );
      });

      const res = await axios.get(url.toString());

      return res.data;
    },
    {
      // refetchOnMount: false,
      // refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useGetMonthlyInflowAggregationReport = () => {
  const query = useQuery<GetMonthlyInflowAggregationReportResponse[]>(
    ["referral-manager", "inflow-monthly-aggregation-report"],

    async () => {
      const res = await axios.get(
        "customerrelationshipmanagerreport/inflow/by-insurer/monthly-aggregation"
      );
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useGetInflowTrend = () => {
  const query = useQuery<{ date: string; count: number }[]>(
    ["referral-manager", "inflow-trend"],
    async () => {
      const res = await axios.get(
        `customerrelationshipmanagerreport/inflow/trend`
      );

      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useGetBacklogStatisticsByStatusReport = (
  locationIds: string[]
) => {
  const query = useQuery<CrmBacklogByStatusFullReportResult[]>(
    ["referral-manager", "backlog-full-report-by-status", locationIds],

    async () => {
      let url = new URL(
        `${window.location.protocol}/${window.location.host}/api/customerrelationshipmanagerreport/backlog-by-status/full-report`
      );

      locationIds.forEach((loc) => {
        url.searchParams.append("locationIds", loc);
      });

      const res = await axios.get(url.toString());

      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useGetBacklogStatisticsByNextStepReport = (
  locationIds: string[]
) => {
  const query = useQuery<CrmBacklogByNextStepFullReportResult[]>(
    ["referral-manager", "backlog-full-report-by-next-step", locationIds],

    async () => {
      let url = new URL(
        `${window.location.protocol}/${window.location.host}/api/customerrelationshipmanagerreport/backlog-by-next-step/full-report`
      );

      locationIds.forEach((loc) => {
        url.searchParams.append("locationIds", loc);
      });

      const res = await axios.get(url.toString());

      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useGetWorkingViewReport = (date: string) => {
  const query = useQuery<CrmWorkingViewReportResult>(
    ["referral-manager", "working-view-report", date],

    async () => {
      const res = await axios.get(
        `customerrelationshipmanagerreport/working-view-report?date=${date}`
      );
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useRegenerateWorkingViewReport = (onSuccess: () => void) => {
  return useMutation({
    mutationFn: async () => {
      const res = await axios.post(
        `customerrelationshipmanagerreport/working-view-report/regenerate`
      );

      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([
        "referral-manager",
        "working-view-report",
      ]);
      queryClient.refetchQueries(["referral-manager", "working-view-report"]);

      onSuccess();
    },
  });
};

export const useGetNoChangeInDaysReport = (fromDate: string) => {
  const query = useQuery<CrmNoChangeInDaysReportResult[]>(
    ["referral-manager", "no-change-in-days-report", fromDate],

    async () => {
      const res = await axios.get(
        `customerrelationshipmanagerreport/no-change-in-days-report?date=${fromDate}`
      );
      return res.data;
    },
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  return query;
};

export const useRegenerateNoChangeInDaysViewReport = (
  onSuccess: () => void
) => {
  return useMutation({
    mutationFn: async () => {
      const res = await axios.post(
        `customerrelationshipmanagerreport/no-change-in-days-report/regenerate`
      );

      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([
        "referral-manager",
        "no-change-in-days-report",
      ]);
      queryClient.refetchQueries([
        "referral-manager",
        "no-change-in-days-report",
      ]);

      onSuccess();
    },
  });
};
