import { CrmNoChangeInDaysReportResult } from "../../../types";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
} from "../../../../../components/ui/chart";
import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from "recharts";
import { colors, getReadableLabelFromDataKey } from "./utils";
import { getSimpleFormattedDate } from "../../../../../utils/dateUtils";
import { CustomTooltip } from "./chartTooltip";

export const NoChangeInDaysReportChart = ({
  data,
}: {
  data: CrmNoChangeInDaysReportResult[];
}) => {
  const generateChartConfig = () => {
    return {
      countOfZeroDays: {
        label: getReadableLabelFromDataKey("countOfZeroDays"),
        color: "hsl(var(--chart-1))",
      },
      countOfOneDay: {
        label: getReadableLabelFromDataKey("countOfOneDay"),
        color: "hsl(var(--chart-2))",
      },
      countOfTwoToSevenDays: {
        label: getReadableLabelFromDataKey("countOfTwoToSevenDays"),
        color: "hsl(var(--chart-3))",
      },
      countOfEightToFourteenDays: {
        label: getReadableLabelFromDataKey("countOfEightToFourteenDays"),
        color: "hsl(var(--chart-4))",
      },
      countOfMoreThanFourteenDays: {
        label: getReadableLabelFromDataKey("countOfMoreThanFourteenDays"),
        color: "hsl(var(--chart-5))",
      },
    } satisfies ChartConfig;
  };

  return (
    <ChartContainer
      width={"100%"}
      height={"100%"}
      aspect={2.5}
      config={generateChartConfig() satisfies ChartConfig}
    >
      <AreaChart
        accessibilityLayer
        data={data}
        margin={{
          left: 12,
          right: 12,
        }}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="date"
          tickLine={false}
          axisLine={false}
          tickFormatter={(value: Date) => getSimpleFormattedDate(value)}
        />
        <YAxis
          tick={{ stroke: "#C7C7C7", strokeOpacity: 0.1, fontSize: 12 }}
          tickLine={true}
          type="number"
        />
        <ChartTooltip
          content={(x) => (
            <CustomTooltip
              label={x.label}
              active={x.active ?? false}
              payload={x.payload as any}
            />
          )}
        />
        <Area
          dataKey="countOfZeroDays"
          type="natural"
          fill={colors.countOfZeroDays}
          stroke={colors.countOfZeroDays}
          fillOpacity={0.4}
          stackId="a"
        />
        <Area
          dataKey="countOfOneDay"
          type="natural"
          fill={colors.countOfOneDay}
          stroke={colors.countOfOneDay}
          fillOpacity={0.4}
          stackId="a"
        />
        <Area
          dataKey="countOfTwoToSevenDays"
          type="natural"
          fill={colors.countOfTwoToSevenDays}
          stroke={colors.countOfTwoToSevenDays}
          fillOpacity={0.4}
          stackId="a"
        />
        <Area
          dataKey="countOfEightToFourteenDays"
          type="natural"
          fill={colors.countOfEightToFourteenDays}
          stroke={colors.countOfEightToFourteenDays}
          fillOpacity={0.4}
          stackId="a"
        />
        <Area
          dataKey="countOfMoreThanFourteenDays"
          type="natural"
          fill={colors.countOfMoreThanFourteenDays}
          stroke={colors.countOfMoreThanFourteenDays}
          fillOpacity={0.4}
          stackId="a"
        />
      </AreaChart>
    </ChartContainer>
  );
};
